import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { useTracking } from '../../../helper/mixpanel';
import { CardLayout } from '../components/CardLayout';
import { BlockScheduleList } from './BlockScheduleList';

export function RanchScheduleList({ ranch, blockId }) {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const history = useHistory();
  const blocks = useSelector((state) => selectors.selectNewBlocksForRanchId(state, ranch.id))
    .filter((d) => d && d.scheduling_enabled);
  const onScheduleItemClick = useCallback(() => {
    dispatch(actions.setSelectedBlocks(blocks.map((b) => b.id)));
    if (tracking) {
      tracking.track('Clicked On Ranch Schedule Calendar Icon',
        { ranchId: ranch.id, blockIds: blocks.map((b) => b.id) });
    }
    history.push('/schedule/calendar');
  }, [blocks, dispatch, history, ranch.id, tracking]);

  function renderBlocks() {
    const filteredBlocks = blockId ? blocks.filter((b) => b.id === blockId) : blocks;
    return filteredBlocks.map((b) => <BlockScheduleList block={b} key={b.id} />);
  }

  return (
    <div>
      <CardLayout>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={onScheduleItemClick}
          className="flex-row padding-bottom-10"
          onClick={onScheduleItemClick}
        >
          <div className="align-self-center">
            <Button
              type="primary"
              icon={<FaRegCalendarAlt size={30} />}
              size="large"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </div>
          <Typography.Text
            style={{
              marginLeft: '8px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: 'unset',
            }}
          >
            {ranch.name}
          </Typography.Text>
        </div>
        {renderBlocks(blocks)}
      </CardLayout>
    </div>
  );
}

RanchScheduleList.propTypes = {
  ranch: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  blockId: PropTypes.number,
};

RanchScheduleList.defaultProps = {
  blockId: null,
};
