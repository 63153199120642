import React from 'react';
import PropTypes from 'prop-types';
import { sensorTitles, SensorAlarmButton, LatLngButton } from 'farmx-web-ui';
import { useTranslation } from 'react-i18next';
import './sensor-detail-header.less';

export function SensorDetailHeader({
  sensorProperties: {
    type,
    location,
    alarmCount,
    identifier,
    // sensorStatus, // needs more information
  },
  showAlarmIcon,
  showSensorType,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex-row margin-top-10 margin-bottom-10 sensor-detail-header">
      <div className="flex-column width-100">
        <div className="flex-row margin-bottom-10">
          {showSensorType ? <div className="sensor-header-type">{t(sensorTitles[type]?.singular || '')}</div> : <div /> }
          {showAlarmIcon
          && (
          <div className="sensor-alarm-status-block">
            <SensorAlarmButton
              isMobileView
              alarmCount={alarmCount}
              identifier={identifier}
              type={type}
            />
          </div>
          )}
        </div>
        <div className="sensor-header-location">
          <LatLngButton
            location={location}
          />
        </div>
      </div>
    </div>
  );
}

SensorDetailHeader.propTypes = {
  sensorProperties: PropTypes.shape({
    identifier: PropTypes.string,
    type: PropTypes.string,
    alarmCount: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
  showAlarmIcon: PropTypes.bool,
  showSensorType: PropTypes.bool,
};

SensorDetailHeader.defaultProps = {
  showAlarmIcon: true,
  showSensorType: true,
};
