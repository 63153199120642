import React, {
  useState, useCallback,
  useMemo
} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { FaDownload } from 'react-icons/fa';
import { Dropdown } from 'antd';
import { IoIosOptions } from 'react-icons/io';
import { selectors } from 'farmx-redux-core';
import { getDownloadUrl } from 'helper/graphHelper';
import NewChartComponent from './NewChartComponent';
import Resizer from './Resizer';
import { useSensorGraphData, usePrepareGraphConfig } from './graphHooks';
import { getSensorTypeShortForm, NO_RENDER } from './constants';

const {
  selectSensor,
} = selectors;

function showText(text){
  return (
    <div className="chart-loading">
      <span className="chart-loading-inner">{`${text}`}</span>
    </div>
  );
}

export default function NewSensorDataChart(props) {
  const {
    sensorName, sensorType, sensorId, sensorIdentifier,
    variables, startDate, endDate, cached, customConfig,
    header, uniformYAxis, yMinMax, renderOption, preparedConfig,
    refresh
  } = props;
  const [height, setHeight] = useState(null);
  const [chartRef, setChartRef] = useState(null);

  const sensorsByPropValues = useSelector((state) => selectSensor(state,
    sensorType, sensorIdentifier));

  // TODO remove after testing
  // const graphRef = useRef();
  // console.log('calling NewSensorDataChart', preparedConfig);
  // console.log(startDate, endDate);

  // const refresh = true;
  const { loading, data, error } = useSensorGraphData(
    sensorType,
    sensorId,
    sensorIdentifier,
    variables,
    startDate,
    endDate,
    cached,
    refresh,
    preparedConfig,
    renderOption
  );

  const config = usePrepareGraphConfig(
    customConfig,
    data,
    startDate,
    endDate,
    uniformYAxis,
    yMinMax,
    chartRef,
    preparedConfig,
    renderOption
  );

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('config changed', config, renderOption);
  // }, [config, renderOption]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('graphRef changed', graphRef.current);
  // }, []);

  const onResize = useCallback((event, chartHeight) => {
    setHeight(chartHeight);
  }, []);

  const getConfig = useCallback(() => {
    if(uniformYAxis && yMinMax){
      // TODO remove after testing
      // console.log('updating yaxis extremes', yMinMax);
      const { min, max} = yMinMax;
      return {...config, yAxis: config?.yAxis?.map((d) => ({...d, min, max})) };
    }
    return config;
  }, [config, uniformYAxis, yMinMax]);

  const getSensorName = useCallback(() => {
    if(sensorName) return sensorName;
    if(sensorsByPropValues?.name) return sensorsByPropValues.name;
    return `${getSensorTypeShortForm(sensorType)}-${sensorIdentifier}`;
  }, [sensorIdentifier, sensorName, sensorType, sensorsByPropValues]);

  const downloadCSV = useCallback((e) => {
    getDownloadUrl(
      startDate,
      endDate,
      {type: sensorType, identifier: sensorIdentifier},
      variables,
      () => null
    );
  },
  [startDate, endDate, sensorType, sensorIdentifier, variables]);

  const isLoading = (loading || (preparedConfig===null));
  const noData = (preparedConfig && !preparedConfig?.series)
  || (config && (!config?.series?.length || !config?.series?.[0].data?.length));

  const headerRightExtras = !isLoading
    && !noData
    && header?.right?.map((d, i) => ({label: d, key: i+1}));
  const items = useMemo(() => {
    const download = {
      label: (
        <div
          key="download"
          role="button"
          onClick={downloadCSV}
          onKeyDown={downloadCSV}
          tabIndex={0}
          style={{alignContent: 'center'}}
        >
          <FaDownload />
          <span style={{ marginLeft: '10px' }}> Download</span>
        </div>

      ),
      key: 0
    };
    if(headerRightExtras?.length) return [download, ...headerRightExtras];
    return [download];
  }, [downloadCSV, headerRightExtras]);

  if(renderOption === NO_RENDER) return null;

  return (
    <div className="chart-component-wrapper">
      {header
        ?(
          <div className="chart-header-container">
            <div className="chart-header-form">
              <div className="chart-header-left">
                <div className="chart-sensor">
                  {getSensorName()}
                  <span
                    className="chart-title"
                  >
                    {header?.title}
                    {header?.units?` (${header.units})`:''}
                  </span>
                </div>
                <div className="chart-header-legend">
                  {!isLoading && !noData
              && header?.left?.map((d) => ((typeof d==='function')?d(config?.series):d))}
                </div>
              </div>
              <div className="chart-header-right">
                <div id="chart-header-options" className="chart-header-options">
                  <Dropdown
                    disabled={isLoading}
                    menu={{ items}}
                    trigger={['click']}
                    getPopupContainer={() => document.getElementById('chart-header-options')}
                  >
                    <a>
                      <IoIosOptions size={20} color="grey" />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        ):null}
      <Resizer onResize={onResize}>
        <div className="chart-component-main" style={{ height }}>
          { error && showText(error.message) }
          { isLoading && showText('Loading...') }
          { !isLoading && noData && showText('No Data') }
          { !isLoading && !noData
              && (
              <NewChartComponent
                config={getConfig()}
                error={null}
                setChartRef={setChartRef}
              />
              )}
        </div>
      </Resizer>
    </div>
  );
}

NewSensorDataChart.propTypes = {
  sensorName: PropTypes.string,
  sensorType: PropTypes.string,
  sensorId: PropTypes.number,
  sensorIdentifier: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.any),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  customConfig: PropTypes.func,
  header: PropTypes.shape({
    title: PropTypes.string,
    units: PropTypes.string,
    left: PropTypes.arrayOf(PropTypes.any),
    right: PropTypes.arrayOf(PropTypes.any),
  }),
  uniformYAxis: PropTypes.bool,
  yMinMax: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  renderOption: PropTypes.string,
  preparedConfig: PropTypes.shape({
    series: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  refresh: PropTypes.number,
};
NewSensorDataChart.defaultProps = {
  sensorName: null,
  sensorType: null,
  sensorId: null,
  sensorIdentifier: null,
  variables: null,
  startDate: null,
  endDate: null,
  cached: null,
  customConfig: null,
  header: null,
  uniformYAxis: false,
  yMinMax: null,
  renderOption: null,
  preparedConfig: undefined,
  refresh: null,
};
