import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import { SchedulerMobile, SchedulerTimeline } from 'farmx-web-ui';
import './styles.css';
import { selectSchedulerTimelineDataRenderedForDesktop, getDateRangeForScheduler } from '../../../helper/scheduleHelper';

const {
  loadBlockIrrigationSchedule,
} = actions;

const IrrigationSchedule = ({
  blocks,
  dates,
  irrigationTypes,
  onDataRetrieval,
  onClickChart,
  callApi,
  colorObj,
  mobileDevice,
}) => {
  const [data, setData] = useState([]);
  const [sections, setSections] = useState([]);
  const apiCalled = useRef(false); // To avoid multiple API calls
  const prevBlocks = useRef([]);
  const isLoadingCompleted = useRef(false);
  const dispatch = useDispatch();

  const dateStart = dates[0];

  // To clear the chartdata when all irrigation types unchecked
  useEffect(() => {
    if (!irrigationTypes.length) {
      setSections([]);
    }
    setData([]);
  }, [irrigationTypes.length]);

  // load schedules for dates
  useEffect(() => {
    async function loadData() {
      for (let blockIndex = 0; blockIndex < blocks.length; blockIndex += 1) {
        if (prevBlocks.current[blockIndex] !== blocks[blockIndex]) {
          dispatch(loadBlockIrrigationSchedule(blocks[blockIndex]));
        }
      }

      // Using new API from schedule slice for 'Scheduled' irrigationType
      if (dateStart && blocks.length) {
        const [dStart, dEnd] = getDateRangeForScheduler(dateStart);
        for (let blockIndex = 0; blockIndex < blocks.length; blockIndex += 1) {
          if (prevBlocks.current[blockIndex] !== blocks[blockIndex] || (callApi.isCall && ['edit', 'refresh'].includes(callApi.type))) {
            dispatch(actions.loadBlockRenderedSchedule({
              blockId: blocks[blockIndex],
              dateStart: dStart,
              dateEnd: dEnd,
            }));
          }
        }
      }
    }

    if ((callApi.isCall || !apiCalled.current) && blocks.length) {
      loadData();
      prevBlocks.current = blocks;
      if (!apiCalled.current) apiCalled.current = true;
      if (callApi.isCall) onDataRetrieval();
    }

    // Compare blocks to trigger API call
    const blk = prevBlocks.current;
    return () => {
      if (JSON.stringify(blk) === JSON.stringify(blocks)) {
        apiCalled.current = true;
        isLoadingCompleted.current = true;
      } else {
        apiCalled.current = false;
        isLoadingCompleted.current = false;
      }
    };
  }, [blocks, callApi, dateStart, dispatch, mobileDevice, onDataRetrieval]);

  // To clear the chartdata when all irrigation types unchecked
  useEffect(() => {
    if (!irrigationTypes.length) {
      setData([]);
      setSections([]);
    }
  }, [irrigationTypes.length]);

  const reduxState = useSelector((state) => state);
  // blkSchLoading, newBlkSchLoading are the loading state of the new and old API's
  const [processedData, processedSections, blkSchLoading] = (
    selectSchedulerTimelineDataRenderedForDesktop(reduxState,
      getDateRangeForScheduler(dateStart), blocks, irrigationTypes, mobileDevice, colorObj)
  );

  useEffect(() => {
    if (!blkSchLoading && apiCalled.current && processedData.length) {
      // This is temporary fix to set state after complete loading
      if (isLoadingCompleted.current) {
        if (JSON.stringify(data) !== JSON.stringify(processedData)) {
          setData(processedData);
          setSections(processedSections);
          apiCalled.current = false;
        }
      } else isLoadingCompleted.current = true;
    }
  }, [blkSchLoading, data, processedData, processedSections]);

  return (
    <div className="chart-container-div">
      {!mobileDevice
        ? (
          <SchedulerTimeline
            blocks={blocks || []}
            dates={dates}
            onClickChart={onClickChart}
            scheduleData={{
              data: (processedData.length && processedData.length > 1)
                ? processedData : data,
              sections: sections?.length ? sections
                : processedSections?.filter((d) => blocks.includes(d?.key)),
            }}
          />
        )
        : (
          <SchedulerMobile
            dates={dates}
            onClickChart={onClickChart}
            colorObj={colorObj}
            scheduleData={data}
          />
        )}
    </div>
  );
};

IrrigationSchedule.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.any),
  dates: PropTypes.arrayOf(PropTypes.object),
  irrigationTypes: PropTypes.arrayOf(PropTypes.any),
  onDataRetrieval: PropTypes.func,
  onClickChart: PropTypes.func,
  callApi: PropTypes.shape({
    type: PropTypes.string,
    isCall: PropTypes.bool,
  }),
  colorObj: PropTypes.shape({}),
  mobileDevice: PropTypes.bool,
};

IrrigationSchedule.defaultProps = {
  blocks: null,
  dates: null,
  irrigationTypes: null,
  onDataRetrieval: () => null,
  onClickChart: null,
  callApi: { type: 'refresh', isCall: false },
  colorObj: {},
  mobileDevice: false,
};

export default IrrigationSchedule;
