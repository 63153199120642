import { RouteComponentProps } from "react-router";
import TeamDetail from "./TeamDetail";
import React, { useEffect } from "react";
import { Modal, message } from "antd";
// @ts-ignore
import { teamApi, teamUserAccessApi, roleApi, ranchApi } from "farmx-api";
import { FarmxWebApiTypes } from "types/api_types";
import useUserRoles from "components/organization/hooks/useUserRoles";
import { useTranslation } from "react-i18next";

/**
 * TeamDetailContainer is a container component that manages the state and data fetching for the TeamDetail component.
 * @param {RouteComponentProps} props - The standard react-router-dom props.
 * @returns {JSX.Element}
 */
const TeamDetailContainer: React.FC<RouteComponentProps> = (props:RouteComponentProps) => {
  const { t } = useTranslation();
  // Destructure history from props
  const { history, match } = props;

  // State of the loading status
  const [loading, setLoading] = React.useState(true);

  // State of the team
  const [team, setTeam] = React.useState<any>(undefined);

  // State of the team's selected ranches
  const [teamRanches, setTeamRanches] = React.useState<any[]>([]);

  // State of the all available ranches for the entity
  const [allEntityRanches, setAllEntityRanches] = React.useState<any[]>([]);

  // State of the paginated data in the TeamUserAccess table
  const [pagedResult, setPagedResult] = React.useState<FarmxWebApiTypes.PaginatedResult<any>>({
    data: [],
    page: 0,
    page_size: 0,
    count: 0,
  });

  // State of the current spec for fetching users
  const [spec, setSpec] = React.useState<FarmxWebApiTypes.TeamUserAccessListQuery>({
    includes: ['user', 'role'],
    page_number: 1,
    page_size: 10,
  });


  /**
   * Method to fetch the team user access, based on the current spec
   * sets the paged result state
   * @returns {void}
   */
  const loadTeamUserAccessForTeam = async () => {
    try {
      var usersResult = await teamUserAccessApi.listTeamUserAccess({
        ...spec, 
        distinct: true,
        team_id: (match.params as {teamId: number}).teamId
      });
      setPagedResult(usersResult.data);
    } catch (error) {
      message.error('Failed to fetch data.');
    }
  };

  /**
   * Side effect to fetch the user membership data when the table spec changes
   * @returns {void}
  */
  useEffect(() => {
    loadTeamUserAccessForTeam();
  }, [spec]);


  /**
   * Method to fetch the team ranches
   * @returns {void}
   */
  const loadRanchesForTeam = async () => {
    try {
      var ranchesResult = await teamApi.listTeamRanches((match.params as {teamId: number}).teamId, {page_size: 100});
      setTeamRanches(ranchesResult.data.data);
    } catch (error) {
      message.error('Failed to fetch team ranches.');
    }
  }

  /**
   * Method to fetch the entity ranches
   * @returns {void}
   */
  const loadEntityRanches = async (entity_id: string) => {
    try {
      var ranchesForEntity = await ranchApi.listRanches({entity_id: entity_id ,page_size: 100});
      setAllEntityRanches(ranchesForEntity.data.data);
    }
    catch (error) {
      message.error('Failed to fetch entity ranches.');
    }
  }
  /**
   * Side effect to fetch the team data from the api when the team id changes
   * @returns {void}
   */
  React.useEffect(() => { 
    const fetchTeam = async () => {
      setLoading(true);
      try {
        var teamResult = await teamApi.retrieveTeam((match.params as {teamId: number}).teamId, {});
        setTeam(teamResult.data.data);
        Promise.all([loadRanchesForTeam(), loadEntityRanches(teamResult.data.data.entity)])
      } catch (error) {
        message.error('Failed to fetch team users data.');
      }
      setLoading(false);
    };
    fetchTeam();
  }, [(match.params as {teamId: number}).teamId]);
  
  /**
   * Method to handle the page change - updates the query spec based on the new page number and page size
   * @param page - The new page number
   * @param pageSize - The new page size
   * @returns {void}
  */
  const handlePageChange = (page: number, pageSize: number) => {
    setSpec((prev: any) => ({
      ...prev,
      page_number: page,
      page_size: pageSize || prev.page_size,
    }));
  };

  /**
   * Method to handle the user access deletion
   * @param userAccess - The user access to delete
   * @returns {void}
  * */
  const handleUserAccessDelete = async (userAccess: any) => {
    // Show a confirmation modal
    // then delete the teamUserAccess
    Modal.confirm({
      title: 'Delete User Access',
      content: 'Are you sure you want to delete this user access?',
      onOk: async () => {
        try {
          await teamUserAccessApi.deleteTeamUserAccess(userAccess.id);
          message.success(t('User access deleted successfully.'));
          // Refresh the user access data
          loadTeamUserAccessForTeam();
        } catch (error) {
          message.error((error as any).response.data.detail);
        }
      },
    });
  }

  /**
   * Method to handle the team update
   * @param updateTeamFormData - The team data to update
   */
  const handleTeamUpdate = async (updateTeamFormData: { name: string; id: number; }) => {
    setLoading(true);
    try {
      var updated = await teamApi.updateTeam(updateTeamFormData.id, {name: updateTeamFormData.name});
      setTeam(updated.data.data);
    } catch (error) {
      message.error((error as any).response.data.detail);
    }
    setLoading(false);
  }
  
  /**
   * Handle the ranch selection update
   * @param {any[]} selectedRanches - The selected ranches
   */
  const handleRanchSelectionUpdate:(selectedRanches: number[]) => Promise<boolean> = async (selectedRanches: any[]) => {
    try {
      // update the team ranches
      var updated = await teamApi.updateRanchList((match.params as {teamId: number}).teamId, {ranches: selectedRanches});
      // reload the team and entity ranches
      await Promise.all([loadRanchesForTeam(), loadEntityRanches(team.entity)])
      message.success(t('Ranches updated successfully.'));
    } catch (error) {
      message.error((error as any).response.data.detail);
      return false;
    }
    return true;
  }

  /**
   * Handle the update of a user access role
   * @param {any} userAccess - The user access to update
   * @param {string} role - The new role
   */
  const handleUpdateUserAccessRole:(userAccess: any, role: string) => Promise<boolean> = async (userAccess: any, role: string) => {
    try {
      // fetch the roles and get the role with the name
      const rolesfound = await roleApi.getRoles();
      // then find the role id of the role selected
      const roleId = rolesfound.data.data.find((r: any) => r.name === role).id;
      // then update the team user access
      var updated = await teamUserAccessApi.updateTeamUserAccess(userAccess.id, {role_id: roleId});
      // show a success message
      message.success(t('Role updated successfully.'));
      // return true
      return true;
    } catch (error) {
      message.error((error as any).response.data.detail);
      return false;
    }
  }

  /**
   * Handle the deletion of a team
   * @param {team: {id: number}} team - The team to delete
   * @returns {void}
   */
  const handleTeamDelete = (team: { id: number; }) => {
    Modal.confirm({
      title: 'Delete Team',
      content: 'Are you sure you want to delete this team?',
      onOk: async () => {
        try {
          await teamApi.deleteTeam(team.id);
          message.success(t('Team deleted successfully.'));
          // Redirect to the teams page
          history.push('/organization/teams');
        } catch (error) {
          message.error((error as any).response.data.detail);
        }
      },
    });
  }

  const roles = useUserRoles((match.params as {teamId: number}).teamId as any);

  return (
    <TeamDetail 
      loading={loading || roles == undefined}
      team={team}
      onInviteUser={function (team: any): void {
        props.history.push(`/organization/teams/${team.id}/invite-user`);
      } }
      onTeamUpdate={handleTeamUpdate}
      onTeamDelete={handleTeamDelete}
      userTeamAccessSpec={spec}
      pagedResult={pagedResult}
      onPageChange={handlePageChange}
      onUserAccessDelete={handleUserAccessDelete}
      teamRanches={teamRanches}
      entityRanches={allEntityRanches}
      onUpdateRanchSelection={handleRanchSelectionUpdate}
      onUserAccessUpdateRole={handleUpdateUserAccessRole}  
      currentUserRoles={roles}  
    />
  );
}

export default TeamDetailContainer;