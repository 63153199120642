import React from 'react';
import ContentLoader from 'react-content-loader';

export default function ImageryLoader() {
  return (
    <>
      <ContentLoader
        speed={2}
        width={350}
        height={80}
        viewBox="0 0 350 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="minus-margin-left-10"
      >
        {/* Five rectangles with same size horizontally */}
        <rect x="0" y="0" rx="3" ry="3" width="60" height="80" />
        <rect x="70" y="0" rx="3" ry="3" width="60" height="80" />
        <rect x="145" y="0" rx="3" ry="3" width="60" height="80" />
        <rect x="215" y="0" rx="3" ry="3" width="60" height="80" />
        <rect x="290" y="0" rx="3" ry="3" width="60" height="80" />
      </ContentLoader>
      <hr className="loader-hr minus-margin-left-10" />

      {/* For the color key */}
      <ContentLoader
        speed={2}
        width={350}
        height={30}
        viewBox="0 0 350 30"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="minus-margin-left-10"
      >
        <rect x="0" y="0" rx="3" ry="3" width="350" height="12" />
        <rect x="0" y="14" rx="3" ry="3" width="60" height="10" />
        <rect x="145" y="14" rx="3" ry="3" width="60" height="10" />
        <rect x="290" y="14" rx="3" ry="3" width="60" height="10" />
      </ContentLoader>
    </>

  );
}
