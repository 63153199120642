import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from 'antd';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { CardLayout } from '../../components/CardLayout';
import {
  getFrequencySummary,
  useStartTimeSummary,
  getRepeatEndsSummary,
} from '../scheduleSummary';
import './block-schedule-info.css';
import RanchBlockTitle from '../../components/RanchBlockTitle';

const dateFormat12Hr = 'ddd, MMM, Do, h:mm a';
const dateFormat24Hr = 'ddd, MMM, Do, HH:mm';


export const BlockScheduleInfo = ({ scheduledEvent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));
  const dateFormat = userTimeFormat === '24_hour' ? dateFormat24Hr : dateFormat12Hr;
  const timeSummary = useStartTimeSummary(scheduledEvent.rootStart, scheduledEvent.rootStop);
  const repeatSummary = getRepeatEndsSummary(scheduledEvent, t);
  const { useRanchNamesForBlocks } = hooks;
  const ranchName = useRanchNamesForBlocks([Number(scheduledEvent.block)]);

  function getScheduleDetail(event) {
    const detail = {};
    const zone = moment.tz.guess();
    const timezone = moment.tz(zone).format('z');
    const duration = moment.duration(moment(event.stop_date).diff(moment(event.start_date)));
    detail.duration = duration.format(`h [${t('hours')}] m [${t('minutes')}]`);
    detail.startTime = `${moment(event.start_date).format(dateFormat)} ${timezone}`;
    detail.endTime = `${moment(event.stop_date).format(dateFormat)} ${timezone}`;
    detail.isRecurring = event.is_recurring;
    return detail;
  }

  const scheduleDetail = getScheduleDetail(scheduledEvent);
  const block = useSelector((state) => selectors.selectNewBlockById(state, scheduledEvent.block));

  useEffect(() => {
    dispatch(actions.loadUserSettings());
  }, [dispatch]);

  const rowInfo = [
    {
      header: `${t('Start')}:`,
      body: scheduleDetail.startTime,
    },
    {
      header: `${t('Stop')}:`,
      body: scheduleDetail.endTime,
    },
    {
      header: `${t('Duration')}:`,
      body: scheduleDetail.duration,
    },
    {
      header: `${t('Repeats')}:`,
      body: scheduleDetail.isRecurring ? getFrequencySummary(scheduledEvent, t) : t('No'),
    },
    scheduledEvent.is_recurring && {
      header: `${t('Ends')}:`,
      body: repeatSummary,
    },
    scheduledEvent.edit_id && {
      header: `${t('Edited')}:`,
      body: `${t('Normally at')} ${timeSummary}`,
    },
  ].filter((item) => item);

  const ranchBlockName = `${ranchName[block.id]} > ${block.name}`;

  return (
    <CardLayout>
      <div className="flex-column">
        <div className="flex-column detail-header">
          <RanchBlockTitle title={ranchBlockName} />
        </div>
        <Divider />
        <div className="flex-column detail-body">
          {rowInfo.map((row) => (
            <div className="flex-row" key={row.header}>
              <Typography.Title level={3}>
                {row.header}
              </Typography.Title>

              <Typography.Text style={{ marginLeft: '6px' }}>
                {row.body}
              </Typography.Text>
            </div>
          ))}
        </div>
      </div>
    </CardLayout>
  );
};

BlockScheduleInfo.propTypes = {
  scheduledEvent: PropTypes.shape().isRequired,
};
