import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Switch } from 'antd';
import { actions } from 'farmx-redux-core';
import { useTracking } from '../../../helper/mixpanel';

const { setMapShowSoilType } = actions;

export default function AnomalyLayers(props) {
  const {
    selectedImageryData, selMapShowSoilType, anomalyDispatch, showPlants,
    isAnomaly,
  } = props;
  const tracking = useTracking();
  const trackPage = { page: 'AnomalyDetail' };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="map-settings-container">
      <div>
        <div className="margin-top-10">
          <Typography.Title level={4}>
            {t('Select Map Overlays')}
          </Typography.Title>
        </div>
        <div className="flex-row map-settings-row">
          <div className="flex-column switch-box">
            <div className="flex-row padding-bottom-10">
              <Switch
                checked={selMapShowSoilType}
                onChange={(v) => {
                  dispatch(setMapShowSoilType(v));
                  if (v) {
                    tracking.track('Checked Soil Type Option', trackPage);
                  } else if (!v) {
                    tracking.track('Unchecked Soil Type Option', trackPage);
                  }
                }}
              />
              <div className="margin-left-10">
                <Typography.Text>
                  {t('Soil Type')}
                </Typography.Text>
              </div>
            </div>
            {!isAnomaly
            && (
            <div className="flex-row padding-bottom-10">
              <Switch
                checked={selectedImageryData.visible}
                onChange={(v) => {
                  anomalyDispatch({
                    type: 'setSelectedImageryData',
                    payload: {
                      visible: v,
                      data: selectedImageryData.data,
                    },
                  });
                  anomalyDispatch({
                    type: 'setSelectedDroneImagery',
                    payload: {
                      visible: v,
                      data: selectedImageryData.data,
                    },
                  });
                  if (v) {
                    tracking.track('Checked Show Imagery Option', trackPage);
                  } else if (!v) {
                    tracking.track('Unchecked Show Imagery Option', trackPage);
                  }
                }}
              />
              <div className="margin-left-10">
                <Typography.Text>
                  {t('Show Imagery')}
                </Typography.Text>
              </div>
            </div>
            )}
            {isAnomaly ? null : (
              <div className="flex-row padding-bottom-10">
                <Switch
                  checked={showPlants}
                  onChange={(v) => {
                    anomalyDispatch({
                      type: 'setShowPlants',
                      payload: v,
                    });
                    if (v) {
                      tracking.track('Checked Show Plants Option', trackPage);
                    } else if (!v) {
                      tracking.track('Unchecked Show Plants Option', trackPage);
                    }
                  }}
                />
                <div className="margin-left-10">
                  <Typography.Text>
                    {t('Show Plants')}
                  </Typography.Text>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

AnomalyLayers.propTypes = {
  selectedImageryData: PropTypes.shape({
    visible: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.any),
  }),
  selMapShowSoilType: PropTypes.string,
  anomalyDispatch: PropTypes.func,
  isAnomaly: PropTypes.bool,
  showPlants: PropTypes.bool,
};

AnomalyLayers.defaultProps = {
  selectedImageryData: null,
  selMapShowSoilType: null,
  anomalyDispatch: null,
  isAnomaly: true,
  showPlants: true,
};
