/* eslint-disable */
/*
 *
 * This file defines an object representing an irrigation block and the sensors contained within
 * There are two major meshes defining this object - the block mesh, which is the entire outline
 * and the cluster mesh, which defines the boundary around a group of (default:all) sensors.
 *
 */


// Declaring for fixing the test issue
global.RanchShape = function(border, sensors) {
  console.log(sensors);
  var clusterBorder = JSON.parse(JSON.stringify(border));
  var clusterGeo = createClusterGeoJson(padSensor(sensors));
  clusterBorder.geometry.coordinates = clusterGeo;

  /*var minValue = -1;
  var maxValue = -1;*/

  for (var i = 0; i < sensors.length; i++) {
    /*for (var j = 0; j < sensors[i].data.length; j++) {
        for (var k = 0; k < sensors[i].data[j].length; k++) {
            var value = sensors[i].data[j][k];
            if (minValue == -1 || value < minValue) minValue = value;
            if (maxValue == -1 || maxValue < value) maxValue = value;
        }
    }*/
    sensors[i].data.unshift(sensors[i].data[0]);
  }

  /*var padPercent = 0;
  this.minValue = minValue + (maxValue - minValue) * padPercent;
  this.maxValue = maxValue - (maxValue - minValue) * padPercent;*/

  this.minValue = 0.0;
  this.maxValue = 0.5;

  console.log(this.minValue, this.maxValue);

  //console.log(clusterBorder);

  this.selectedDepth = null;
  this.displayMode = 'gradient';
  this.sensorDepths = 7;
  this.sensors = sensors;
  this.time = 0;

  this.scale = 1;

  this.shape = createSimpleShape(border);
  this.shapeCluster = createSimpleShape(clusterBorder);

  //var sensorBorder = getSensorBorder(sensors);
  //var sensorShape = createSimpleShape(sensorBorder);
  //var sensorGeometry = new THREE.ShapeGeometry(sensorShape);

  if (sensors.length) {
    this.empty = false;
  } else {
    this.empty = true;
  }

  this.geometry = new THREE.ShapeGeometry(this.shape);

  this.baseMaterial = new THREE.MeshBasicMaterial({
    color: 0xff00ff,
    vertexColors: THREE.VertexColors
  });

  //this.baseMaterial.transparent = true;
  this.baseMaterial.opacity = 1;

  var uniforms = {
    time: {
      value: 1.0
    },
    resolution: {
      value: new THREE.Vector2()
    }
  };

  var shaderMaterial = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: document.getElementById('vertexShader').textContent,
    fragmentShader: document.getElementById('fragment_shader_test').textContent
  });

  this.meshBlock = new THREE.Mesh(this.geometry, this.baseMaterial);
  this.meshBlock.position.z = 0.005;

  this.extrudeDepthBlock = getMeshRadius(this.meshBlock) / (this.sensorDepths - 1) / 4; //0.025;

  var borderGeometry = new THREE.ExtrudeGeometry(this.shape, {
    amount: this.extrudeDepthBlock * this.scale * this.sensorDepths,
    bevelEnabled: false
  });
  var soilEdgesGeo = new THREE.EdgesGeometry(borderGeometry, 80);
  var edgeMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff
  });
  this.soilEdges = new THREE.LineSegments(soilEdgesGeo, edgeMaterial);

  this.soilEdges.material.linewidth = 3;

  this.edgesBlock = new THREE.EdgesHelper(this.meshBlock, 0xffffff);
  this.edgesBlock.material.linewidth = 3;
  this.edgesBlock.transparent = true; //this.edges.depthTest = true;

  var segArr = this.createSoilSegments(this.shape, this.extrudeDepthBlock, 1.0);
  this.soilSegmentsBlock = segArr[0];
  this.segmentEdgesBlock = segArr[1];
  this.sensorObjectsBlock = this.createSensorObjects(getMeshRadius(this.meshBlock) / 100);
  //this.soilSegments.position.z = -0.01;

  this.geometryCluster = new THREE.ShapeGeometry(this.shapeCluster); //this.shapeCluster
  this.meshCluster = new THREE.Mesh(this.geometryCluster, this.baseMaterial); //Cluster

  this.extrudeDepthCluster = getMeshRadius(this.meshCluster) / (this.sensorDepths - 1) / 4; // / 10.0;
  var segArr2 = this.createSoilSegments(this.shapeCluster, this.extrudeDepthCluster, 1);
  this.soilSegmentsCluster = segArr2[0];
  this.segmentEdgesCluster = segArr2[1];
  this.sensorObjectsCluster = this.createSensorObjects(getMeshRadius(this.meshCluster) / 100);

  this.edgesCluster = new THREE.EdgesHelper(this.meshCluster, 0xffffff);
  this.edgesCluster.material.linewidth = 3;
  this.edgesCluster.transparent = true;

  this.segmentEdges = this.segmentEdgesCluster;
  this.sensorObjects = this.sensorObjectsCluster;
  this.soilSegments = this.soilSegmentsCluster;
  this.extrudeDepth = this.extrudeDepthCluster;
  this.edges = this.edgesCluster;

  this.setClusterVisible(false);

  //this.randomizeVertexValues();
  //this.updateVertexColors();

  var shader = this.createSoilShader(this.sensors, 0);
  this.soilShader = shader;

  this.createDataTexture(sensors);
  this.initShader();

  //this.mesh.material = this.interpolateShader;
  /////this.mesh.renderOrder = 2;
  //this.setSelectedDepth(3);
  //this.
};

/*
 * Adds additional points if there are less than 4

 add bounding box of 4 sensors with padding around existing
 min padding

 */
 global.padSensor = function(sensors) {
  if (sensors.length > 3)
    return sensors;

  newSensors = sensors;
  return newSensors;
}

global.getMeshRadius = function(mesh) {
  mesh.geometry.computeBoundingSphere();
  return mesh.geometry.boundingSphere.radius;
}

global.createClusterShape = function(sensors) {
  var geo = createClusterGeometry2(sensors);

  var boundaryPoints = [];
  for (var i = 0; i < geo.vertices.length; i++) {
    var point = geo.vertices[i];
    boundaryPoints.push(new THREE.Vector2(point.x, point.y));
  }

  /*for (var i = 0; i < sensors.length; i++) {
      var coord = sensors[i].position;
      var point = getLocalPointMag(coord);
      //boundaryPoints.push(new THREE.Vector2 (point[0], point[1]));

  }*/
  var geometry = new THREE.Shape(boundaryPoints);
  return geometry;
};

global.createClusterGeoJson = function(sensors) {
  var multiplier = 1000;
  var offsetX = 119;
  var offsetY = -36;
  var newVerts = [];
  console.log(sensors.length, "length");
  for (var i = 0; i < sensors.length; i++) {
    var point = sensors[i].position;
    newVerts.push(new THREE.Vector3((point[1] + offsetX) * multiplier, (point[0] + offsetY) * multiplier, 0));
  }

  var radius = .1;
  if (sensors.length < 4) {
    newVerts.push(new THREE.Vector3(newVerts[0]?.x - radius, newVerts[0]?.y - radius, 0));
    newVerts.push(new THREE.Vector3(newVerts[0]?.x + radius, newVerts[0]?.y + radius, 0));
    newVerts.push(new THREE.Vector3(newVerts[0]?.x - radius, newVerts[0]?.y + radius, 0));
    newVerts.push(new THREE.Vector3(newVerts[0]?.x + radius, newVerts[0]?.y - radius, 0));
  }

  var convexHull = new THREE.ConvexGeometry(newVerts);

  //console.log(convexHull);

  var vertices = convexHull.vertices;
  //console.log(convexHull.getAttribute('position'));
  //console.log(vertices);

  var geometry = [];
  for (var i = 0; i < vertices.length; i++) {
    geometry.push([vertices[i].x / multiplier - offsetX, vertices[i].y / multiplier - offsetY]);
  }
  geometry.push(geometry[0]);

  return [geometry];
}

global.createClusterGeometry = function(sensors) {
  var shape = createClusterShape(sensors);
  return new THREE.ShapeGeometry(shape);
};

global.createClusterGeometry2 = function(sensors) {
  var vertices = [];

  for (var i = 0; i < sensors.length; i++) {
    var coord = sensors[i].position;
    var point = getLocalPoint(coord);
    vertices.push(new THREE.Vector2(point[0], point[1]));
  }

  var newVerts = [];
  for (var i = 0; i < vertices.length; i++) { // skip first vert b/c it is duplicated // edit edit: dont
    var vert = vertices[i];
    newVerts.push(new THREE.Vector3(vert.x, vert.y, 0));
  }
  vertices = newVerts;

  var convexHull = new THREE.ConvexGeometry(vertices);
  convexHull.computeBoundingBox();
  return convexHull;
};

global.getLocalPoint = function(coord) {
  var pos = {
    latitude: coord[0],
    longitude: coord[1]
  };

  return Geometry.getLocalFromPosition(pos);
};

global.featurePointsToArray = function(feature) {
  var points = [];
  var coords = feature.geometry.coordinates[0];
  for (var i = 0; i < coords.length; i++) {
    var coord = [coords[i][1], coords[i][0]];
    var point = getLocalPoint(coord);
    points.push(new THREE.Vector2(point[0], point[1]));
  }
  return points;
};

global.createSimpleShape = function(boundary) {
  var boundaryPoints = featurePointsToArray(boundary);
  var geometry = new THREE.Shape(boundaryPoints);
  return geometry;
};

global.createComplexShape = function(border, points) {
  // triangulated convex hull
  // remove faces that have an edge between two non-adjacent vertices

  var vertices = featurePointsToArray(border);

  for (var i = 0; i < points.length; i++) {
    var point = getLocalPoint(points[i].position);
    var vert = new THREE.Vector2(point[0], point[1]);
    vertices.push(vert);
  }

  var newVerts = [];
  for (var i = 1; i < vertices.length; i++) { // skip first vert b/c it is duplicated
    var vert = vertices[i];
    newVerts.push(new THREE.Vector3(vert.x, vert.y, 0));
  }
  vertices = newVerts;

  //var convexHull = new THREE.ConvexGeometry(vertices);
  //console.log(convexHull);

  var test = new THREE.EarcutGeometry(vertices, [8, 9, 10]);

  return test;
};

global.scaleUV = function(uv, minVal, maxVal) {
  return (uv - minVal) / (maxVal - minVal);
};

global.percentColors2 = [{
    pct: 0.0,
    color: {
      r: 0xff,
      g: 0x00,
      b: 0
    }
  },
  {
    pct: 0.5,
    color: {
      r: 0xff,
      g: 0xff,
      b: 0
    }
  },
  {
    pct: 1.0,
    color: {
      r: 0x00,
      g: 0xff,
      b: 0
    }
  }
];

global.percentColors = [{
    pct: 0.0,
    color: {
      r: 0xff,
      g: 0x44,
      b: 0
    }
  },
  {
    pct: 1,
    color: {
      r: 0xff,
      g: 0xff,
      b: 0
    }
  }
];

global.getColorForPercentage = function(pct) {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  // or output as hex if preferred
};

global.createSoilSegment = function(shape, depth, color, height) {
  var val = (15 - depth) / 15.0;
  var color = new THREE.Color(getColorForPercentage(val));

  var geometry = new THREE.ExtrudeGeometry(shape, {
    amount: height,
    bevelEnabled: false
  });

  var material = new THREE.MeshBasicMaterial({
    color: color
  });
  material.transparent = true;
  //material.opacity = 0.85;
  var mesh = new THREE.Mesh(geometry, material);

  mesh.position.z = -(depth) * height;
  return mesh;
};

global.createSensorMesh = function(sensor, size) {
  var geometry = new THREE.SphereGeometry(size, 10, 10);
  var material = new THREE.MeshBasicMaterial({
    color: 0xffffff
  });
  var sphere = new THREE.Mesh(geometry, material);

  var location = sensor.position;
  var point = getLocalPoint(location);

  sphere.position.x = point[0];
  sphere.position.y = point[1];
  sphere.position.z = 0;

  sphere.scale.z = 4;

  return sphere;
};

RanchShape.prototype = {
  setHeight: function(height) {
    this.mesh.position.z = height + (height / 100);
    this.soilSegments.position.z = height;
    this.sensorObjects.position.z = height;
    this.edges.position.z = height;
    this.soilEdges.position.z = height;

    //this.soilSegmentsCluster.position.z = height;
    //this.sensorObjectsCluster.position.z = height;
  },

  getRadius: function() {
    return getMeshRadius(this.mesh);
  },

  getHeight: function() {
    return this.getRadius() / 4;
  },

  setClusterVisible: function(visible) {
    if (visible == true) {
      this.soilSegments = this.soilSegmentsCluster;
      this.segmentEdges = this.segmentEdgesCluster;
      this.sensorObjects = this.sensorObjectsCluster;
      this.extrudeDepth = this.extrudeDepthCluster;
      this.mesh = this.meshCluster;
      this.edges = this.edgesCluster;
    } else {
      this.soilSegments = this.soilSegmentsBlock;
      this.segmentEdges = this.segmentEdgesBlock;
      this.sensorObjects = this.sensorObjectsBlock;
      this.extrudeDepth = this.extrudeDepthBlock;
      this.mesh = this.meshBlock;
      this.edges = this.edgesBlock;
    }

    this.bbox = new THREE.Box3().setFromObject(this.mesh);
  },


  createDataObject: function(sensors) {

    var data = [];

    for (var i = 0; i < sensors.length; i++) {
      var sensor = sensors[i];
      var value = Math.random();
      var location = sensor.position;
      var point = getLocalPoint(location);

      data.push(point[0]);
      data.push(point[1]);
      data.push(value);
      data.push(value);
    }

    return data;
  },

  createSoilDataObject: function(sensors, depth) {

    var data = [];
    var numSensors = sensors.length;
    var numTimes = 0;

    for (var i = 0; i < numSensors; i++) {
      var numTimes = 0;
      if (sensors[i].data.length && sensors[i].data[0] !== undefined) {
        numTimes = Math.max(length, sensors[i].data[0].length);
      }
    }
    //var numTimes = sensors[0].data[0].length;
    //var time = 0;

    for (var time = 0; time < numTimes; time++) {

      for (var i = 0; i < numSensors; i++) { //sensors.length

        //    for (var time = 0; time < numTimes; time++) {

        var sensor = sensors[i];
        var value1 = sensor.data[depth][time];
        var value2 = sensor.data[depth + 1][time];
        var location = sensor.position;
        var point = getLocalPoint(location);

        data.push(point[0]);
        data.push(point[1]);
        data.push(0.0);
        data.push(value1);

        data.push(point[0]);
        data.push(point[1]);
        data.push(1.0);
        data.push(value2);
      }
    }

    return {
      data: data,
      numTimes: numTimes,
      numValues: 2 * numSensors,
    };
  },

  createDataTexture: function(sensors) {
    var data = this.createDataObject(sensors);
    //var data = new Float32Array([1.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 1.0]);
    data = new Float32Array(data);
    //sensors.length
    this.dataTexture = new THREE.DataTexture(data, 1, sensors.length, THREE.RGBAFormat, THREE.FloatType); // THREE.LinearFilter
    this.dataTexture.needsUpdate = true;

    return this.dataTexture;
  },

  createSoilDataTexture: function(sensors, depth) {
    var dataObject = this.createSoilDataObject(sensors, depth);

    var data = new Float32Array(dataObject.data);
    //sensors.length
    var dataTexture = new THREE.DataTexture(data, dataObject.numValues, dataObject.numTimes, THREE.RGBAFormat, THREE.FloatType); // THREE.LinearFilter
    dataTexture.minFilter = THREE.LinearFilter;
    dataTexture.magFilter = THREE.LinearFilter;
    dataTexture.needsUpdate = true;

    return {
      texture: dataTexture,
      width: dataObject.numValues,
      height: dataObject.numTimes
    };
  },

  initShader: function() {
    var uniforms = {
      sensors: {
        value: this.sensors.length
      },
      times: {
        value: 1
      },
      power: {
        value: 3.0
      },
      time: {
        value: this.time
      },
      data: {
        value: this.dataTexture,
        type: 't'
      },
      //wh:         { value: new THREE.Vector2() },

    };

    this.interpolateShader = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: document.getElementById('vertexShader').textContent, // + '\n#define SIZE ' + this.sensors.length,
      fragmentShader: document.getElementById('interpolate').textContent
    });

    this.interpolateShader.transparent = true;
    //this.interpolateShader.opacity = .1;

  },

  createSoilShader: function(sensors, depth, extrudeDepth) {

    var dataTextureObject = this.createSoilDataTexture(sensors, depth);
    var dataTexture = dataTextureObject.texture;

    var uniforms = {
      sensors: {
        value: dataTextureObject.width
      },
      times: {
        value: dataTextureObject.height
      },
      power: {
        value: 3.0
      },
      time: {
        value: this.time
      },
      data: {
        value: dataTexture,
        type: 't'
      },
      depth: {
        value: extrudeDepth
      },
      minValue: {
        value: this.minValue
      },
      maxValue: {
        value: this.maxValue
      },
      //wh:         { value: new THREE.Vector2() },
    };

    var vertexShader = document.getElementById('vertexShader').textContent;
    var fragmentShader = document.getElementById('interpolate-3d').textContent
    fragmentShader = fragmentShader.replace('SENSOR_DATA_SIZE', dataTextureObject.width.toString());

    var interpolate3dShader = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader, // + '\n#define SIZE ' + dataTextureObject.width,
      fragmentShader: fragmentShader
    });

    interpolate3dShader.transparent = false;
    //interpolate3dShader.depthTest = false;


    //interpolate3dShader = new THREE.MeshBasicMaterial({ color: 0xffff00, blending: THREE.SubtractiveBlending });

    return interpolate3dShader;
  },

  randomizeVertexValues: function() {

    this.vertexValues = {};

    for (var i = 0; i < this.geometry.vertices.length; i++) {
      var value = Math.random();
      var vid = i;
      this.vertexValues[i] = value;
    }

  },

  updateVertexColors: function() {
    var faceIndices = ['a', 'b', 'c'];

    for (var i = 0; i < this.geometry.faces.length; i++) {
      var face = this.geometry.faces[i];
      for (var j = 0; j < 3; j++) {
        var vi = face[faceIndices[j]];
        var value = this.vertexValues[vi];

        var color = new THREE.Color(getColorForPercentage(value));
        face.vertexColors[j] = color;
      }
    }

  },

  createSoilSegments: function(shape, extrudeDepth, mult) {
    var segments = new THREE.Group();
    var edgeGroup = new THREE.Group();

    for (var i = 0; i < this.sensors?.[0]?.data?.length - 1; i++) {
      var color = '0xff0000';
      var segment = createSoilSegment(shape, i + 1, color, extrudeDepth);
      var shader = this.createSoilShader(this.sensors, i, extrudeDepth);
      segment.material = shader;

      //segment.scale.set(mult, 1, mult);
      //segment.geometry.scale.y = mult;

      segments.add(segment);
      //segment.renderOrder = 0;///100 + i;

      var edges = new THREE.EdgesGeometry(segment.geometry, 80);
      var edgeMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000
      });
      var edgeLines = new THREE.LineSegments(edges, edgeMaterial);

      edgeLines.material.linewidth = 3;
      edgeLines.position.z = segment.position.z;
      //edges.material.linewidth = 3;
      //edges.material.transparent = false;
      edges.renderOrder = 0;
      //edges.depthTest = false;
      //edges.material.opacity = 0.5;
      //segments.add(edges);
      edgeGroup.add(edgeLines);
    }
    return [segments, edgeGroup];
  },

  generateUvs: function() {
    this.geometry.faceVertexUvs[0] = [];
    for (var i = 0; i < this.geometry.faces.length; i++) {

      this.geometry.faceVertexUvs[0].push([
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, 1),
        new THREE.Vector2(1, 1),
      ]);
    }
    this.geometry.uvsNeedUpdate = true;
  },

  updateShaders: function() {
    for (var i = 0; i < this.soilSegments.children.length; i++) {
      var segment = this.soilSegments.children[i];

      if (!segment.material.uniforms) continue;
      segment.material.uniforms.time.value = this.time;
      segment.material.uniforms.time.needsUpdate = true;
      segment.material.needsUpdate = true;
    }
  },

  createSensorObjects: function(size) {
    var sensorObjects = new THREE.Group();
    for (var i = 0; i < this.sensors.length; i++) {
      var sensor = this.sensors[i];
      var sensorMesh = createSensorMesh(sensor, size);
      sensorObjects.add(sensorMesh);
    }
    return sensorObjects;
  },

  recalculateUvs: function(minX, maxX, minY, maxY) {
    this.recalculateUvsGeometry(this.geometry, minX, maxX, minY, maxY);
    this.recalculateUvsGeometry(this.geometryCluster, minX, maxX, minY, maxY);
  },

  recalculateUvsGeometry: function(geometry, minX, maxX, minY, maxY) {
    geometry.faceVertexUvs[0] = [];
    for (i = 0; i < geometry.faces.length; i++) {
      var newUVs = [];
      var face = geometry.faces[i];
      var va = geometry.vertices[face.a];
      var vb = geometry.vertices[face.b];
      var vc = geometry.vertices[face.c];
      var verts = [va, vb, vc];

      for (vi = 0; vi < verts.length; vi++) {
        var vert = verts[vi];
        var newUV = new THREE.Vector2(scaleUV(vert.x, minX, maxX), scaleUV(vert.y, minY, maxY));
        newUVs.push(newUV);
      }
      geometry.faceVertexUvs[0].push(newUVs);
    }
    geometry.uvsNeedUpdate = true;
  },

  setSelectedDepth: function(depth) {
    if (depth < 0 || depth >= 15) depth = null;
    this.selectedDepth = depth;
    for (var i = 0; i < this.soilSegments.children.length; i++) {
      var segment = this.soilSegments.children[i];
      var edge = this.segmentEdges.children[i];
      if (depth == null || i == depth) {
        segment.visible = true;
        edge.visible = true;
      } else {
        segment.visible = false;
        edge.visible = false;
      }
    }
  },

  setDisplayMode: function(displayMode) {
    this.displayMode = displayMode;
    if (displayMode == 'gradient') {

    } else if (displayMode == 'depth') {

    } else if (displayMode == 'nearest') {

    }
  },

  setDepth: function(depth) {
    console.log("setting depth");
    var that = this;
    that.soilShader.uniforms.depth.value = that.scale * that.extrudeDepth;
    that.soilSegments.scale.set(1, 1, that.scale);
    var height = that.scale * that.extrudeDepth * that.soilSegments.children.length;
    that.setHeight(height);
    that.soilEdges.scale.set(1, 1, that.scale);
  },

  animateToDepth: function(depth) {
    var properties = {
      'scale': depth
    };
    var tween = createjs.Tween.get(this).to(properties, 1000, createjs.Ease.cubicInOut).addEventListener("change", handleChange);

    var that = this;

    function handleChange(event) {
      that.soilShader.uniforms.depth.value = that.scale * that.extrudeDepth;

      /*for (var i = 0; i < that.soilSegments.children.length; i++) {
          console.log(that.soilSegments.children[i]);
          that.soilSegments.children[i].scale.set (1, 1, that.scale);
          that.soilSegments.children[i].needsUpdate = true;
          that.soilSegments.children[i].position.z = -(i+1) * that.extrudeDepth * that.scale ;//+ that.soilSegments.children.length * that.extrudeDepth * that.scale;
      }*/
      that.soilSegments.scale.set(1, 1, that.scale);
      var height = that.scale * that.extrudeDepth * that.soilSegments.children.length;
      that.setHeight(height);
      that.soilEdges.scale.set(1, 1, that.scale);
    }
  },

  setSelected: function(selected) {
    this.mesh.visible = selected;
    this.soilSegments.visible = selected;
    this.edges.visible = selected;
    this.soilEdges.visible = selected;
    this.sensorObjects.visible = selected;
  },

  setDataRange: function(minValue, maxValue) {
    this.minValue = minValue;
    this.maxValue = maxValue;

    for (var i = 0; i < this.soilSegmentsBlock.children.length; i++) {
      var segment = this.soilSegmentsBlock.children[i];
      this.updateSegmentDataRange(segment);
    }

    for (var i = 0; i < this.soilSegmentsCluster.children.length; i++) {
      var segment = this.soilSegmentsCluster.children[i];
      this.updateSegmentDataRange(segment);
    }
  },

  updateSegmentDataRange: function(segment) {
    if (!segment.material.uniforms) return;
    segment.material.uniforms.minValue.value = this.minValue;
    segment.material.uniforms.minValue.needsUpdate = true;

    segment.material.uniforms.maxValue.value = this.maxValue;
    segment.material.uniforms.maxValue.needsUpdate = true;

    segment.material.needsUpdate = true;
  }

};
