import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './resizer.css';

const Resizer = (props) => {
  const { children, onResize } = props;
  const ref = useRef(null);
  const refBottom = useRef(null);

  useEffect(() => {
    const resizeableEle = ref.current;
    const styles = window.getComputedStyle(resizeableEle);
    let height = parseInt(styles.height, 10);
    let y = 0;

    const onMouseMoveBottomResize = (event) => {
      const dy = event.clientY - y;
      height += dy;
      y = event.clientY;
      resizeableEle.style.height = `${height}px`;
      onResize(event, height);
    };

    const onMouseUpBottomResize = () => {
      document.removeEventListener('mousemove', onMouseMoveBottomResize);
    };

    const onMouseDownBottomResize = (event) => {
      y = event.clientY;
      const currentStyles = window.getComputedStyle(resizeableEle);
      resizeableEle.style.top = currentStyles.top;
      resizeableEle.style.bottom = null;
      document.addEventListener('mousemove', onMouseMoveBottomResize);
      document.addEventListener('mouseup', onMouseUpBottomResize);
    };

    const resizerBottom = refBottom.current;
    resizerBottom.addEventListener('mousedown', onMouseDownBottomResize);

    return () => {
      resizerBottom.removeEventListener('mousedown', onMouseDownBottomResize);
    };
  }, [onResize]);

  return (
    <div className="resizer-container">
      <div ref={ref} className="resizeable">
        {children}
        <div ref={refBottom} className="resizer resizer-b" />
      </div>
    </div>
  );
};

Resizer.propTypes = {
  children: PropTypes.node.isRequired,
  onResize: PropTypes.func,
};

Resizer.defaultProps = {
  onResize: () => { },
};

export default Resizer;
