import React, {
  useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createChartOptions } from 'helper/plantSensorHelper';
import {
  getYminMax
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_CROP_STRESS } from './constants';
import { ChartProps, MinMax, ChartType } from './type';

const { CROP_STRESS } = ChartType;

export default function CropStressChart(props: ChartProps) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();

  const globalYMinMax = yMinMaxMap
    && yMinMaxMap[CROP_STRESS]?yMinMaxMap[CROP_STRESS]:{min: null, max: null};

  const yMinMax : MinMax | null = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);


  const customConfig = useCallback((configObj, seriesData) => {
    if(configObj?.series?.[0]){
      const yMinMax : MinMax | null = getYminMax(seriesData);
      const { multiplier } = configObj?.series?.[0];
      if(yMinMax) updateYminmax(CROP_STRESS, yMinMax.min * multiplier, yMinMax.max * multiplier);
    }
    const newConfig = createChartOptions(seriesData);
    const {yAxis} = configObj;
    return {
      ...newConfig,
      chart: {
        ...newConfig.chart,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 10,
        marginRight: 0,
        height: undefined,
      },
      title: '',
      xAxis: {
        ...configObj.xAxis,
      },
      series: [
        newConfig.series
      ],
      yAxis: [
        {
          ...newConfig.yAxis,
          title: null,
          labels: {
            ...yAxis.labels,
            x: 2,
            y: -2,
            align: 'left',
          }
        }
      ]
    };
  }, [updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_CROP_STRESS}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('TWD'), units: t('μm'), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

CropStressChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
