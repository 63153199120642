import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
  useUnits,
} = hooks;

const {
  selectPressureSummary,
  selectSensorLoadingStatus,
} = selectors;

export function PressureSensorSummary(props) {
  const { list, statusList } = props;
  const summary = useSelector((state) => selectPressureSummary(state, list)) || {};
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  const { t } = useTranslation();
  const getUserUnits = useUnits();

  const {
    status,
    onlineCount,
    names,
    irrigatingCount,
    totalCount,
    waterPressure,
  } = summary;

  const stateTag = useMemo(() => (
    getConnectivityLabel(onlineCount, totalCount, t)
  ), [onlineCount, totalCount, t]);

  const valueUnitInfoType = useMemo(() => (
    stateTag.type === 'online' ? 'primary' : 'disabled'),
  [stateTag.type]);

  useLoadStatus(list, statusList);
  const units = status?.units || {
    waterPressure: 'pounds_per_square_inch',
  };
  const convertedWaterPressure = getUserUnits(waterPressure, units.waterPressure,
    'pressure', { decimalPlaces: 4 });
  const showNoDataInfo = (!loading && !list.length) || isNaN(waterPressure);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="speedometer"
        title={t('Water Pressure')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="speedometer"
          title={t('Water Pressure')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              value={convertedWaterPressure.value}
              unit={convertedWaterPressure.label}
              info={`${list.length} ${t('sensors')}`}
              type={valueUnitInfoType}
            />
            {irrigatingCount === 0 ? (
              <ValueUnitInfo
                type={valueUnitInfoType}
                value={irrigatingCount}
                info={t('irrigating')}
              />
            ) : (
              <ValueUnitInfo
                type={valueUnitInfoType}
                value={irrigatingCount}
                info={`${t('irrigating')}, ${list.length - irrigatingCount} ${t('not')}`}
              />
            )}
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(names?.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

PressureSensorSummary.defaultProps = {
  list: [],
  statusList: [],
};

PressureSensorSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  statusList: PropTypes.arrayOf(PropTypes.object),
};
