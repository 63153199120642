import React, {
  useCallback, useContext, useEffect, useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { SOIL_MOISTURE_ALL_DEPTHS } from 'components/hooks/graph';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { displayConvertedDepth } from 'helper/depthUnitHelper';
import { FaEdit } from 'react-icons/fa';
import {
  computeMinAndMax, cutoffLineLabelStyle, getColors, getDepthVariables,
  getSoilCutoffPoints, getYminMaxForInvidividualDepth,
  preparePlotLines
} from '../../helper/graphHelper';
import {
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
} from '../../utils/colors';
import NewSensorDataChart from './NewSensorDataChart';
import EditSoilSensorProperties from './EditSoilSensorProperties';

import { usePreparedConfigForIndividualDepth } from './graphHooks';
import MultilineDepthChart from './MultilineDepthChart';
import GraphContext from './GraphContext';
import { CALL_CUSTOM_CONFIG_AND_RENDER, NO_RENDER } from './constants';
import MultilineStackedDepthChart from './MultilineStackedDepthChart';

const { useUnits } = hooks;
const { selectUserDepthFormat }= selectors;

export default function SoilMoistureAllDepthsChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis,
    yMinMaxMap, refreshSensorData
  } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  const [edit, setEdit] = useState(false);
  const [preparedConfig, setPreparedConfig] = useState(null);
  const [graphData, setGraphData] = useState(null);
  // modes : all, stacked, individual
  const [mode, setMode] = useState('all');
  const [refresh, setRefresh] = useState(0);

  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensor.type, sensor.identifier),
  );
  const depthFormat = useSelector(selectUserDepthFormat);

  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);
  const { sensorDataRefresh } = useContext(GraphContext);
  const refreshCount = sensorDataRefresh[`${sensor.type}/${sensor.identifier}`];

  // TODO remove after testing
  // console.log('Render SoilMoistureAllDepthsChart', preparedConfig);

  // const {setYAxisMinMax} = useContext(GraphContext);
  const globalYMinMax = yMinMaxMap[SOIL_MOISTURE_ALL_DEPTHS]?yMinMaxMap[SOIL_MOISTURE_ALL_DEPTHS]
    :{min: null, max: null};

  // TODO remove after testing
  // console.log('yMinMaxMap SoilMoistureAllDepthsChart', yMinMaxMap);


  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  // TODO remove after testing
  // console.log('yMinMax SoilMoistureAllDepthsChart', yMinMax);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('Mount SoilMoistureAllDepthsChart');
  // }, []);

  // If prepared config is null it will show loading
  // in new sensor data chart component
  useEffect(() => {
    setPreparedConfig(() => null);
  }, [startDate, endDate]);

  // when refresh count changes refresh graph data
  useEffect(() => {
    setPreparedConfig(() => null);
    if(refreshCount > 0) setRefresh((prev) => prev+1);
  }, [refreshCount]);

  const customConfig = useCallback((configObj, seriesData, chartRef) => {
    // setGraphData(seriesData);
    // TODO remove after testing
    // console.log('calling customConfig', configObj, seriesData);
    const yMinMax = computeMinAndMax(seriesData, sensorStatus);
    if(configObj?.series?.[0]){
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(SOIL_MOISTURE_ALL_DEPTHS, yMinMax.min * multiplier, yMinMax.max * multiplier);
      // TODO remove after testing
      // console.log('yMinMax all depth', yMinMax);
      // console.log('sensorStatus', sensorStatus);
    }
    if (configObj && sensorStatus) {
      // update series data
      const updatedSeries = configObj.series.map((seriesData) => {
        // const cutOffPoints = getSoilCutoffPoints(seriesData, sensorStatus);
        const seriesOption = {...seriesData};
        const seriesOptionsColor = getColor(seriesOption.actualChartKey);
        // TODO remove after testing
        // console.log('seriesOption color', seriesOption, seriesOptionsColor);
        seriesOption.color = seriesOptionsColor;
        return seriesOption;
      });
      // TODO remove after testing
      // console.log('updatedSeries,yAxis', updatedSeries, yAxis);
      const newConfig = { ...configObj, series: updatedSeries, graphData: seriesData};
      setPreparedConfig(newConfig);
      if(!graphData) setGraphData(seriesData);
      // TODO remove after testing
      // console.log('custom config seriesData', newConfig, seriesData);
      return newConfig;
    }
    return configObj;
  }, [getColor, graphData, sensorStatus, updateYminmax]);

  const customConfigForIndividualChart = useCallback((configObj, seriesData) => {
    // TODO remove after testing
    // console.log('calling customConfigForIndividualChart start',
    // seriesData, configObj, sensorStatus);
    const multiplier = 100;
    const updatedyAxis = configObj.series.map((series) => {
      const depthYMinMax = getYminMaxForInvidividualDepth(series, sensorStatus);
      const seriesOption = {...series};
      const cutOffPoints = getSoilCutoffPoints(seriesOption, sensorStatus);
      // TODO remove after testing
      seriesOption.isRootzone = cutOffPoints.isRootzone;
      seriesOption.name = `Soil Moisture ${displayConvertedDepth(cutOffPoints.depth, getUserUnits, depthFormat)} (%)`;
      const linesConfig = [{
        color: colorOver,
        value: cutOffPoints.fieldCapacity * multiplier,
        type: 'upper',
        from: cutOffPoints.fieldCapacity * multiplier,
        to: (cutOffPoints.fieldCapacity * multiplier || 0) + 1 * multiplier,
        className: 'upper-band-line',
        label: {
          x: -110,
          y: 12,
          align: 'right',
          text: `FC (${Number(cutOffPoints.fieldCapacity * multiplier).toFixed(1)})`,
          style: cutoffLineLabelStyle
        }
      },
      {
        color: colorWarning,
        value: cutOffPoints.refillPoint * multiplier,
        type: 'middle',
        label: {
          x: -55,
          y: 12,
          align: 'right',
          text: `RP (${Number(cutOffPoints.refillPoint * multiplier).toFixed(1)})`,
          style: cutoffLineLabelStyle
        }
      },
      {
        color: colorCritical,
        value: cutOffPoints.wiltingPoint * multiplier,
        type: 'lower',
        from: 0,
        to: cutOffPoints.wiltingPoint * multiplier,
        className: 'lower-band-line',
        label: {
          x: 0,
          y: 12,
          align: 'right',
          text: `WP (${Number(cutOffPoints.wiltingPoint * multiplier).toFixed(1)})`,
          style: cutoffLineLabelStyle
        },
      }];
      const plotLinesConfig = preparePlotLines(linesConfig);
      const {plotLines, plotBands} = plotLinesConfig;
      // TODO remove after testing
      // console.log('plotLines', plotLines, plotBands);
      const yAxis = configObj.yAxis.filter((d) => (series.yAxis===d.id));
      const obj = { ...yAxis[0]};
      if(depthYMinMax){
        obj.min = depthYMinMax.min * multiplier;
        obj.max = depthYMinMax.max * multiplier;
        // TODO remove after testing
        // console.log('y min max depth', obj.min, obj.max,
        // seriesOption.actualChartKey);
      }

      // TODO remove after testing
      // console.log('series.yAxis', series.yAxis, yAxis[0]);
      obj.plotLines = plotLines;
      obj.plotBands = plotBands;
      return [seriesOption, obj];
    }).reduce((acc, d) => {
      acc.series.push(d[0]);
      acc.yAxis.push(d[1]);
      return acc;
    }, {series: [], yAxis: []});

    // TODO remove after testing
    // console.log('calling customConfigForIndividualChart', updatedyAxis);
    return { ...configObj, ...updatedyAxis};

  // the disabling done below is needed till getUserUnits in useUnits is
  // used with useCallback hook or similar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthFormat, sensorStatus]);

  const onClickEdit = useCallback(() => {
    setEdit(true);
  });

  const onClickConvertTo = useCallback((value) => {
    setMode(value);
  });

  const onCancel = useCallback(() => {
    setEdit(false);
  });

  const onSave = useCallback(() => {
    // TODO remove after testing
    // console.log('onSave called');
    setEdit(false);
    // setPreparedConfig(() => null);
    // setRefresh((prev) => prev+1);
    refreshSensorData(sensor.type, sensor.identifier);
  });

  const refreshGraphData = useCallback(() => {
    // setPreparedConfig(() => null);
    // setRefresh((prev) => prev+1);
    refreshSensorData(sensor.type, sensor.identifier);
  });

  const EditLink = useMemo(() => (
    <div
      key={`edit-link-${sensor.id}`}
      className="chart-header-edit-link"
      onClick={onClickEdit}
      onKeyDown={onClickEdit}
      role="button"
      tabIndex={0}
    >
      <FaEdit />
      <span style={{marginLeft: '13px'}}>
        Edit
      </span>
    </div>
  ), [onClickEdit, sensor.id]);

  const variables = useMemo(() => getDepthVariables(sensorStatus, 'moisture'), [sensorStatus]);

  const getLinkText = useCallback((value) => {
    if(value==='all') return t('Combined Depths');
    if(value==='stacked') return t('Stacked Depths');
    if(value==='individual') return t('Individual Depths');
    return value;
  }, [t]);

  const getIconClass = useCallback((value) => {
    if(value==='all') return 'multi-line-png';
    if(value==='stacked') return 'stacked-line-png';
    if(value==='individual') return 'chart-png';
    return 'chart.png';
  }, []);

  const icon = useCallback((type) => (
    <Tooltip title={`View ${getLinkText(type)}`}>
      <div className={`chart-header-switch-mode-icons ${getIconClass(type)} ${type===mode?'icon-border':''}`} />
    </Tooltip>
  ), [getIconClass, getLinkText, mode]);

  const ConvertTo = useMemo(() => (value) => (
    <div
      key={`switch-mode-${sensor.id}-${value}`}
      onClick={() => {
        onClickConvertTo(value);
      }}
      onKeyPress={() => {
        onClickConvertTo(value);
      }}
      tabIndex={0}
      role="button"
      onKeyDown={onClickConvertTo}
    >
      {icon(value)}
    </div>
  ), [icon, onClickConvertTo, sensor.id]);

  const renderIsRootzonePill = useCallback((isRootzone) => (isRootzone ? (
    <span
      className="legend-pill legend-pill-root-zone"
      style={{ backgroundColor: colorOk }}
    >
      Rootzone
    </span>
  ) : (
    <span
      className="legend-pill legend-pill-not-root-zone"
      style={{ backgroundColor: 'grey' }}
    >
      Not Rootzone
    </span>
  )), []);

  const renderIndividualChartTitle = (series) => {
    if(series && series.length){
      const {
        sensorName, color, yAxis, name, isRootzone,
      } = series[0];
      try {
        // TODO remove after testing
        // console.log('seriesItem isRootzone', series, isRootzone);
        const depth = yAxis.split('_')[2];
        return (
          <div key={`legend-${yAxis}`} className="individual-chart-header">
            <span
              className="legend-pill"
              style={{ backgroundColor: color, paddingLeft: '0.5em' }}
            >
              {`${displayConvertedDepth(depth, getUserUnits, depthFormat)}`}
            </span>
            {renderIsRootzonePill(isRootzone)}
          </div>
        );
      } catch {
        return `${sensorName} ${name}`;
      }
    }
  };


  const configForIndividual = usePreparedConfigForIndividualDepth(preparedConfig, graphData);
  // TODO remove after testing
  // console.log('configForIndividual', configForIndividual);

  return (
    <>
      {/* Display multiline moisture chart */}
      <NewSensorDataChart
        key={`${sensor.name}-multiline`}
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorIdentifier={sensor.identifier}
        sensorType={sensor.type}
        variables={variables}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: 'Soil Moisture VWC', units: '', right: [EditLink]}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
        renderOption={NO_RENDER}
        refresh={refresh}
      />
      {mode==='stacked'?(
        [<MultilineStackedDepthChart
          key={`${sensor.name}-multiline-depth`}
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          variables={variables}
          cached="true"
          uniformYAxis={uniformYAxis}
          yMinMaxMap={yMinMax}
          preparedConfig={preparedConfig}
          graphData={graphData}
          switchMode={[ConvertTo('all'), ConvertTo('individual'), ConvertTo('stacked')]}
          refreshGrapData={refreshGraphData}
        />]
      ):''}
      {mode==='all'?(
        [<MultilineDepthChart
          key={`${sensor.name}-multiline-depth`}
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          variables={variables}
          cached="true"
          uniformYAxis={uniformYAxis}
          yMinMaxMap={yMinMax}
          preparedConfig={preparedConfig}
          graphData={graphData}
          switchMode={[ConvertTo('all'), ConvertTo('individual'), ConvertTo('stacked')]}
          refreshGrapData={refreshGraphData}
        />]
      ):''}
      <>
        {/* Display individual moisture chart */}
        {configForIndividual.length && mode==='individual'
          ?configForIndividual.map((config) => (
            <div className="soil-moisture-individual-depth">
              <NewSensorDataChart
                // key={`${sensor.name}-${config.series[0].actualChartKey}`}
                sensorName={sensor.name}
                sensorId={sensor.id}
                sensorIdentifier={sensor.identifier}
                sensorType={sensor.type}
                variables={variables}
                startDate={startDate}
                endDate={endDate}
                cached={cached}
                customConfig={customConfigForIndividualChart}
                header={{
                  title: t('Soil Moisture VWC (Individual)'),
                  units: '',
                  left: [renderIndividualChartTitle, ConvertTo('all'), ConvertTo('individual'), ConvertTo('stacked')],
                  right: [EditLink]
                }}
                uniformYAxis={uniformYAxis}
                yMinMax={yMinMax}
                renderOption={CALL_CUSTOM_CONFIG_AND_RENDER}
                preparedConfig={config}
              />
            </div>
          )):null}
      </>
      {edit?(
        <EditSoilSensorProperties
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          onSave={onSave}
          // depthIndex={1}
          chartData={graphData}
        />
      ):null}
    </>
  );
}

SoilMoistureAllDepthsChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
  individual: PropTypes.bool,
  refreshSensorData: PropTypes.func,
};

SoilMoistureAllDepthsChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
  individual: false,
  refreshSensorData: () => null,
};
