import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import centroid from '@turf/centroid';
import isEqual from 'react-fast-compare';
import { interpolateRdYlGn } from 'd3-scale-chromatic';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../../map/components/utils';
import { reDraw } from './reDraw';
import {
  loadBlockAnomalies, getSelectedPresentationMode, normalizeValue, getRange,
} from '../../../helper/mapHelper';

const {
  cleanSensorsFetchState,
  loadAndPrepareMapDataByRanchIds,
} = actions;

const {
  selectMapShowSoilType,
  selectMapShowLabels,
  selectShowOnlyPresentionModeSensors,
  selectMapPresentationModes,
  selectSensorsFeaturesByRanchId,
  selectBlocksFeaturesByRanchId,
  selectRanchesFeatures,
} = selectors;

const { useUnits } = hooks;

export const prepData = async (dispatchMap, dispatch, ranchIds, filterSensor,
  skipApiCall, isAllRanches, type) => {
  try {
    dispatchMap({ type: 'setIsLoading', payload: true });
    dispatch(cleanSensorsFetchState());
    await dispatch(loadAndPrepareMapDataByRanchIds(ranchIds,
      filterSensor, skipApiCall, isAllRanches, type));
  } catch (error) {
    console.log('ERROR in prepData', error);
    notifyError(error.message);
  } finally {
    dispatchMap({ type: 'setIsLoading', payload: false });
  }
};

export const useLoadMapData = (dispatchMap, ranchId, filterSensor,
  skipApiCall, isAllRanches, isBlocksAvailable, isSensorsDataAvailable,
  type, dispatch) => {
  let ranchIds = [];
  if (!ranchId?.length) {
    ranchIds.push(ranchId);
  } else ranchIds = ranchId;
  const ranchIdStr = JSON.stringify(ranchIds);

  useEffect(() => {
    const parsedRanchIds = JSON.parse(ranchIdStr);
    if (!parsedRanchIds?.length) return;
    const allRanchIds = parsedRanchIds.flat();
    if (allRanchIds?.length && allRanchIds?.[0]) {
      prepData(dispatchMap, dispatch, allRanchIds, filterSensor, skipApiCall,
        isAllRanches, type).catch();
    }
  }, [filterSensor, ranchIdStr, dispatch, dispatchMap, skipApiCall, isAllRanches,
    isBlocksAvailable, isSensorsDataAvailable, type]);
};

export const useLoadAnomalyData = (dispatchMap, blockIdsForRanch, presMode,
  blockId, type, allBlockIds, isPlants) => {
  const[localBlock, setLocalBlock] = useState(null);
  const[localBlockForRanch, setLocalBlockForRanch] = useState(null);
  const[localBlockForAllRanches, setLocalBlockForAllRanches] = useState(null);

  useEffect(() => {
    if (isPlants) return;
    if (presMode !== 'anomaly') return;

    if (type === 'block' && !isEqual(localBlock, blockId)
    && !localBlockForRanch?.includes(blockId)) {
      setLocalBlock(blockId);
      loadBlockAnomalies([blockId], (response) => {
        if (response) {
          dispatchMap({
            type: 'setAnomalyGeoJSON',
            payload: response,
          });
        }
      });
    }
  }, [blockId, dispatchMap, isPlants, localBlock, localBlockForRanch, presMode, type]);

  useEffect(() => {
    if (isPlants) return;
    if (presMode !== 'anomaly') return;

    if (type === 'ranch' && JSON.parse(blockIdsForRanch).length
    && !isEqual(JSON.parse(blockIdsForRanch), localBlockForRanch)) {
      setLocalBlockForRanch(JSON.parse(blockIdsForRanch));
      loadBlockAnomalies(JSON.parse(blockIdsForRanch), (response) => {
        if (response) {
          dispatchMap({
            type: 'setAnomalyGeoJSON',
            payload: response,
          });
        }
      });
    }
  }, [blockIdsForRanch, dispatchMap, isPlants, localBlockForRanch, presMode, type]);

  useEffect(() => {
    if (isPlants) return;
    if (presMode !== 'anomaly') return;

    // This will be updated/removed in the future
    if (type === 'All Ranches' && JSON.parse(allBlockIds).length) {
      // loadBlockAnomalies(JSON.parse(allBlockIds), (response) => {
      //   if (response) {
      //     dispatchMap({
      //       type: 'setAnomalyGeoJSON',
      //       payload: response,
      //     });
      //   }
      // });
    }
  }, [allBlockIds, dispatchMap, isPlants, presMode, type]);
};

export const useSensorsByRanchIdGeoJSON = (ranchSensorFeatures) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isEqual(ranchSensorFeatures, data)) {
      setData(ranchSensorFeatures);
    }
  }, [ranchSensorFeatures, data]);

  return data;
};

export const useBlocksGeoJSON = (blockFeatures) => {
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const blocksGeoJSON = {
      type: 'FeatureCollection',
      features: cloneDeep(blockFeatures),
    };
    if (!isEqual(blocksGeoJSON, data)) {
      setData(blocksGeoJSON);
    }
  }, [blockFeatures, data]);

  return data;
};

export const useAllRanchesGeoJSON = (allRanchesFeatures, ranchId) => {
  const [allRanchesFeaturesData, setAllRanchesFeaturesData] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const [allRanchesPointsGeoJSON, setAllRanchesPointsGeoJSON] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [ranchGeoJson, setRanchGeoJson] = useState(undefined);
  useEffect(() => {
    const allRanchesFeaturesGeoJSON = {
      type: 'FeatureCollection',
      features: cloneDeep(allRanchesFeatures),
    };

    let rGeoJSON;

    if (ranchId && (allRanchesFeatures instanceof Array)) {
      const ranch = allRanchesFeatures.find(({ id }) => id === ranchId);
      const ranchGJ = {
        type: 'FeatureCollection',
        features: [],
      };
      ranchGJ.features.push(cloneDeep(ranch));
      rGeoJSON = (ranchGJ);
    }

    if (!isEqual(allRanchesFeaturesGeoJSON, allRanchesFeaturesData)) {
      setAllRanchesFeaturesData(allRanchesFeaturesGeoJSON);

      const ranchesPointsGeoJSON = allRanchesFeatures
        .reduce((acc, feature) => {
          const ranchPoint = centroid(feature);
          ranchPoint.properties = cloneDeep(feature.properties);
          acc.features.push(ranchPoint);
          return acc;
        }, {
          type: 'FeatureCollection',
          features: [],
        });
      setAllRanchesPointsGeoJSON(ranchesPointsGeoJSON);
    }
    if (allRanchesFeaturesData?.features?.length && !isEqual(rGeoJSON, ranchGeoJson)) {
      setRanchGeoJson(rGeoJSON);
    }
    if (rGeoJSON && rGeoJSON?.features?.length) {
      if (rGeoJSON.features[0] && rGeoJSON.features[0].id !== ranchId) {
        setRanchGeoJson(undefined);
      }
    }
    if (!rGeoJSON && ranchGeoJson) {
      setRanchGeoJson(undefined);
    }
  }, [allRanchesFeatures, allRanchesFeaturesData, allRanchesPointsGeoJSON, ranchGeoJson, ranchId]);

  return [ranchGeoJson, allRanchesPointsGeoJSON, allRanchesFeaturesData];
};

// Function to decide whether the reducer state should be update or not
function isStateUpdateNeeded(bottomSheetType) {
  return bottomSheetType !== 'sensor' && bottomSheetType !== 'anomaly';
}

export const useRecenter = (
  blockId,
  ranchId,
  blocksGeoJSON,
  ranchGeoJSON,
  allRanchesFeaturesData,
  dispatchMap,
  selectedObjFromStateType,
  isBottomSheetVisible,
  bottomSheetType,
  isPlants,
) => {
  useEffect(() => {
    if (selectedObjFromStateType === 'block' && blockId && blocksGeoJSON?.features?.length
      && isStateUpdateNeeded(bottomSheetType)) {
      // if block exist set recenter json to blockGeoJSON
      const selBlock = blocksGeoJSON.features.find((item) => item.id === blockId);
      const blockGeoJSON = {
        type: 'FeatureCollection',
        features: selBlock ? [selBlock] : [],
      };

      dispatchMap({ type: 'setRecenterGeoJSON', payload: blockGeoJSON });
      if(!isPlants){
        dispatchMap({
          type: 'setSelectedFeatureWithType',
          payload: {
            selectedFeature: blockGeoJSON,
            type: 'block',
            showBottomSheet: isBottomSheetVisible,
          },
        });
      }
      return;
    }

    if (selectedObjFromStateType === 'ranch' && ranchId && ranchGeoJSON?.features?.length
      && isStateUpdateNeeded(bottomSheetType)) {
      // if ranch exists set recenter json to ranchGeoJSON
      dispatchMap({ type: 'setRecenterGeoJSON', payload: ranchGeoJSON });
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: ranchGeoJSON,
          type: 'ranch',
          showBottomSheet: isBottomSheetVisible,
        },
      });
      return;
    }

    if (allRanchesFeaturesData?.features?.length
      && isStateUpdateNeeded(bottomSheetType)) {
      // if all ranches exist set recenter json to allRanches geoJSON
      dispatchMap({ type: 'setRecenterGeoJSON', payload: allRanchesFeaturesData });
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: allRanchesFeaturesData,
          type: 'all_ranches',
          showBottomSheet: isBottomSheetVisible,
        },
      });
    }
  }, [
    blockId,
    ranchId,
    blocksGeoJSON,
    ranchGeoJSON,
    allRanchesFeaturesData,
    dispatchMap,
    selectedObjFromStateType,
    isBottomSheetVisible,
    bottomSheetType,
    isPlants,
  ]);
};

export const useReDraw = (
  mapFeatureGroupRef,
  dataForRedraw,
  presMode,
  selectedFeature,
  dispatchMap,
  selMapShowLabels,
  selShowAllSensors,
  allRanchesPoints,
  ranchId,
  zoomSensorsVisible,
  isBottomSheetVisible,
  mapFeatureGroupRef1,
  isAnomaly,
  zoomVal,
  isAllRanch,
  sideBySideMapVisible,
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getUserUnits = useUnits();

  useEffect(() => {
    reDraw({
      mapFeatureGroupRef,
      dataForRedraw,
      presentationMode: presMode,
      selectedFeature,
      dispatchMapPage: dispatchMap,
      showLabels: selMapShowLabels,
      selShowOnlyPresentionModeSensors: !selShowAllSensors,
      ranchesPointsGeoJSON: allRanchesPoints,
      ranchId,
      zoomShowSensors: zoomSensorsVisible,
      dispatch,
      history,
      isBottomSheetVisible,
      mapFeatureGroupRef1,
      isAnomaly,
      zoomVal,
      isAllRanch,
      getUserUnits,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForRedraw, allRanchesPoints, zoomSensorsVisible, isBottomSheetVisible, isAnomaly,
    presMode, sideBySideMapVisible, ranchId, zoomVal]);
};

export const useGetDataToRenderMap = (
  ranchId, isAdmin, blockId, blockIds, selectedObjFromState, allRanchIds, dispatchMap,
) => {
  const sensorsByRanchIdGeoJSON = useSensorsByRanchIdGeoJSON(
    useSelector((state) => selectSensorsFeaturesByRanchId(state, ranchId), isEqual),
  );
  const ranchSensors = isAdmin
    ? sensorsByRanchIdGeoJSON
    : sensorsByRanchIdGeoJSON.filter(({ properties }) => properties.type !== 'cavalier');

  const blocksGeoJSON = useBlocksGeoJSON(
    useSelector((state) => selectBlocksFeaturesByRanchId(state, ranchId), isEqual),
  );

  const ranchFeatures = useSelector((state) => selectRanchesFeatures(state));
  const filteredRanchFeatures = ranchFeatures?.filter((d) => allRanchIds.includes(d?.id));

  const [ranchGeoJSON, allRanchesPoints, allRanchesFeaturesData] = useAllRanchesGeoJSON(
    filteredRanchFeatures,
    ranchId,
  );

  const selShowAllSensors = useSelector(
    (state) => !selectShowOnlyPresentionModeSensors(state), isEqual,
  );

  const selMapPresentationModes = useSelector(
    (state) => selectMapPresentationModes(state), isEqual,
  );

  const presMode = getSelectedPresentationMode(selMapPresentationModes);

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  const selMapShowLabels = useSelector(
    (state) => selectMapShowLabels(state), isEqual,
  );

  return {
    ranchSensors,
    ranchGeoJSON,
    allRanchesPoints,
    allRanchesFeaturesData,
    selShowAllSensors,
    presMode,
    selMapShowSoilType,
    selMapShowLabels,
    blocksGeoJSON,
  };
};

export function clearPolygonFill(mapVar, array) {
  if(!mapVar?.getLayer) return;

  array.forEach((_, index) => {
    const sourceId = `block-fill-${index}`;
    const layerLineId = `plant-block-line-${index}`;
    const layerId = `plant-block-fill-${index}`;
    try {
      if (mapVar?.getLayer(layerId)) mapVar.removeLayer(layerId);
      if (mapVar?.getLayer(layerLineId)) mapVar.removeLayer(layerLineId);
      if (mapVar?.getSource(sourceId)) mapVar.removeSource(sourceId);
    } catch (error) {
      console.log(error);
    }
  });
}

export function usePreparePolygonFillColor(selectedValue, blockAnalytics, isBlockPlantView,
  blockIdsToFill, blockIdsLen, mapVar, isPlants) {
  const fillColorObj = {};
  blockIdsToFill.forEach((d) => {
    const filteredBlockAnalytics = blockAnalytics?.filter((b) => b?.imageryType === 'drone' && b.block === d);
    if (filteredBlockAnalytics?.length) {
      const plantValue = Number(filteredBlockAnalytics?.[0]?.[selectedValue]);
      const rangeVal = getRange(plantValue);
      const normalizedColorValue = normalizeValue(plantValue, rangeVal.min, rangeVal.max);
      const fillColor = normalizedColorValue ? interpolateRdYlGn(normalizedColorValue)
        : 'white';

      fillColorObj[d] = fillColor;
    } else fillColorObj[d] = 'white';
  });

  useEffect(() => {
    clearPolygonFill(mapVar, Array(blockIdsLen).fill(null));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockPlantView, isPlants]);
  return fillColorObj;
}

// To render block boundary with fill on map for the plant "block" view
export const useRenderPlantBlockView = (mapFeatureGroupRef, blocksGeoJSON,
  fillColorObj, isBlockPlantView, isPlants) => {
  useEffect(() => {
    const mapVar = mapFeatureGroupRef?.current;
    if (!mapVar || !isBlockPlantView || !isPlants) return;

    if (mapVar?.style?._loaded && blocksGeoJSON?.features?.length) {
      const parsedFillColor = JSON.parse(fillColorObj);
      const blockIds = Object.keys(parsedFillColor)?.map((d) => Number(d));

      clearPolygonFill(mapVar, Array(blocksGeoJSON?.features?.length).fill(null));
      blockIds.forEach((blockId, index) => {
        const filteredBlockData = blocksGeoJSON.features.filter((d) => d?.id === blockId);
        const fillColor = parsedFillColor[blockId];
        const blockData = { type: 'FeatureCollection', features: filteredBlockData };
        const sourceId = `block-fill-${index}`;
        const layerLineId = `plant-block-line-${index}`;
        const layerId = `plant-block-fill-${index}`;

        mapVar.addSource(sourceId, {
          type: 'geojson',
          data: blockData,
        });

        // To all block boundary layer
        mapVar.addLayer({
          id: layerLineId,
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': fillColor,
            'line-width': 2,
          },
          layout: {
            visibility: isBlockPlantView ? 'visible' : 'none',
          },
        });

        // To all block fill layer with custom color
        mapVar.addLayer({
          id: layerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.6,
          },
          layout: {
            visibility: isBlockPlantView ? 'visible' : 'none',
          },
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillColorObj, isBlockPlantView, blocksGeoJSON]);
};

function setLocalReducerState(dispatchMapArg, dispatchType, payload) {
  dispatchMapArg({
    type: dispatchType,
    payload,
  });
}

// To Clear imagery & comparison map in block view and Retain the previous selection on plants view
export const useHandleBlockPlantView = ({
  stateMap,
  stateMap1,
  prevSelection, // To retain the previous selection
  dispatchMap,
  dispatchMap1,
  setActiveImagery,
  updatePrevSelection,
}) => {
  useEffect(() => {
    const {
      selectedImageryData, selectedDroneImagery, sideBySideMapVisible, plantsView, showPlants,
      selectedDroneDataType, selectedSatelliteDataType, layersControl, isImageryPanelVisible
    } = stateMap;
    const {
      selectedImageryData: selectedImageryDataRight,
      selectedDroneImagery: selectedDroneImageryRight,
    } = stateMap1;
    const {
      prevImageryPanelVisible, showImagery, showDroneImagery, prevDroneDataType,
      prevSatelliteDataType
    } = prevSelection.current;

    if (plantsView === 'block' && !showPlants) {
      // when satellite imagery is selected in fullscreen mode
      if (selectedImageryData?.visible) {
        updatePrevSelection({
          ...prevSelection.current,
          showImagery: true,
          prevDroneDataType: selectedDroneDataType,
          prevSatelliteDataType: selectedSatelliteDataType,
          prevImageryPanelVisible: isImageryPanelVisible,
        });
        setLocalReducerState(dispatchMap, 'setSelectedImageryData',
          { ...selectedImageryData, visible: false });
        if (isImageryPanelVisible) setLocalReducerState(dispatchMap, 'setIsImageryPanelVisible', false);
        setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
        setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
      }
      // when satellite imagery right side is selected
      if (selectedImageryDataRight?.visible) {
        updatePrevSelection({
          ...prevSelection.current,
          showImagery: true,
          prevDroneDataType: selectedDroneDataType,
          prevSatelliteDataType: selectedSatelliteDataType,
          prevImageryPanelVisible: isImageryPanelVisible,
        });
        setLocalReducerState(dispatchMap1, 'setSelectedImageryData',
          { ...selectedImageryDataRight, visible: false });
        if (isImageryPanelVisible) setLocalReducerState(dispatchMap1, 'setIsImageryPanelVisible', false);
        setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
        setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
      }
      // When Drone imagery is selected in fullscreen mode
      if (selectedDroneImagery?.visible) {
        updatePrevSelection({
          ...prevSelection.current,
          showDroneImagery: true,
          prevDroneDataType: selectedDroneDataType,
          prevSatelliteDataType: selectedSatelliteDataType,
          prevImageryPanelVisible: isImageryPanelVisible,
        });
        setLocalReducerState(dispatchMap, 'setSelectedDroneImagery',
          { ...selectedDroneImagery, visible: false });
        if (isImageryPanelVisible) setLocalReducerState(dispatchMap, 'setIsImageryPanelVisible', false);
        setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
        setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
      }
      // When Drone imagery right side is selected
      if (selectedDroneImageryRight?.visible) {
        updatePrevSelection({ ...prevSelection.current, showDroneImagery: true });
        setLocalReducerState(dispatchMap1, 'setSelectedDroneImagery',
          { ...selectedDroneImageryRight, visible: false });
        if (isImageryPanelVisible) setLocalReducerState(dispatchMap1, 'setIsImageryPanelVisible', false);
        setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
        setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
      }
      // when split screen view is selected
      if (sideBySideMapVisible) {
        updatePrevSelection({ ...prevSelection.current, prevActiveImagery: 'splitScreen' });
        setActiveImagery('fullScreen');
        setLocalReducerState(dispatchMap, 'setSideBySideMapVisible', false);
        setLocalReducerState(dispatchMap1, 'setSideBySideMapVisible', false);
        setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
        setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
      }
    } else if ((selectedImageryData?.data || showImagery) && !selectedImageryData?.visible){
      // when the satellite imagery visibility is false
      updatePrevSelection({ ...prevSelection.current, showImagery: false });
      setLocalReducerState(dispatchMap, 'setSelectedImageryData',
        { ...selectedImageryData, visible: true });
    } else if ((selectedDroneImagery?.data || showDroneImagery) && !selectedDroneImagery?.visible){
      // Return to the fullscreen mode when switch back to plants view
      // and split screen is selected previously in plants view
      updatePrevSelection({
        ...prevSelection.current,
        showDroneImagery: false,
        showImagery: false
      });
      setLocalReducerState(dispatchMap, 'setLayersControl',
        { ...layersControl, type: 'tab', side: 'left' });
      setLocalReducerState(dispatchMap, 'setSelectedImageryData',
        { ...selectedImageryData, visible: true });
      setLocalReducerState(dispatchMap1, 'setSelectedImageryData',
        { ...selectedImageryDataRight, visible: true });
      setLocalReducerState(dispatchMap, 'setSelectedDroneImagery',
        { ...selectedDroneImagery, visible: true });
      setLocalReducerState(dispatchMap1, 'setSelectedDroneImagery',
        { ...selectedDroneImageryRight, visible: true });
      if (showDroneImagery) {
        setLocalReducerState(dispatchMap, 'setIsImageryPanelVisible', prevImageryPanelVisible);
        setLocalReducerState(dispatchMap1, 'setIsImageryPanelVisible', prevImageryPanelVisible);
      }
      setLocalReducerState(dispatchMap, 'setSelectedDroneDataType', prevDroneDataType);
      setLocalReducerState(dispatchMap, 'setSelectedSatelliteDataType', prevSatelliteDataType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMap, stateMap1]);
};
