import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { ValveSummary } from './ListCards/ValveSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { getLoadingStatusForListOfSensors } from '../../../helper/listHelper';
import ControlValveListItem from '../control/ControlValves/ControlValveListItem';
import { isMobile } from '../../../utils/detectDevice';
import { sortArrayOfObjectsByProperty } from '../../../helper/common';
import { installState } from '../../../constants';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function ValveSensorList(props) {
  const { history } = props;
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const [loading, setLoading] = useState(false);
  const state = useSelector((store) => store);
  const sensorStatus = state.sensorsData.status.entities;
  const { blockIds } = hooks.useRanchBlockSelection();
  const list = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: installState.INSTALLED,
  }))?.valve || [];

  useEffect(() => {
    if (history && history.location && history.location.selectedObj) {
      setSelectedObj(history.location.selectedObj);
    }
  }, [history]);

  useEffect(() => {
    setLoading(getLoadingStatusForListOfSensors(list, sensorStatus));
  }, [list, sensorStatus]);

  const showList = Boolean(!loading && list.length);
  const sortedList = sortArrayOfObjectsByProperty(list);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Valve Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <ValveSummary
            list={sortedList}
          />

          {showList && sortedList.map((v) => {
            const status = sensorStatus[`${v.type}/${v.identifier}`];
            if (status && status.visible !== false) {
              const valve = { ...v, ...status };
              return (
                <Link
                  key={valve.identifier}
                  to={{
                    pathname: `/list/valve-sensor/${valve.type}/${valve.identifier}`,
                    state: { identifier: valve.identifier },
                  }}
                >
                  <ControlValveListItem
                    valve={{
                      blockId: valve.id,
                      valveCurrentState: (valve?.controlStatus?.state) || '',
                      blockName: valve.name,
                      valveName: valve.name ?? valve.identifier,
                      alarmCount: valve.alarmCount,
                      lastUpdated: valve.lastUpdated,
                      latestDate: valve.latestDate,
                      valveOnline: valve.online,
                      pressure: '',
                      sensorList: [],
                      valveLoading: valve.loading,
                    }}
                    isLink
                  />
                </Link>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

ValveSensorList.defaultProps = {
  history: {},
};

ValveSensorList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
