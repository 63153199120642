import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hooks, selectors } from 'farmx-redux-core';
import { truncate } from 'lodash';
import moment from 'moment';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';
import NoDataText from '../NoDataText';

const {
  useLoadStatus,
  useUnits,
} = hooks;

const {
  selectSensorLoadingStatus,
  selectFlowSummary,
} = selectors;

export function FlowMeterSummary(props) {
  const { list } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  const summary = useSelector((state) => selectFlowSummary(state, list)) || {};
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  const {
    status,
    flowRate,
    names,
    totalOnline,
  } = summary;
  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);
  const units = status?.units || {
    flowRate: 'gallons_per_minute',
  };
  const convertedFlowRate = getUserUnits(flowRate, units.flowRate, 'flow_rate');
  const showNoDataInfo = (!loading && !list.length);
  useLoadStatus(list);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="water-meter"
        title={t('Flow Meters')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="water-meter"
          title={t('Flow Meters')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
              {
                isNaN(flowRate) ?
                  <NoDataText />
                  :
                  <ValueUnitInfo
                    value={convertedFlowRate.value}
                    unit={t(convertedFlowRate.label)}
                    info={moment(status?.lastUpdated).fromNow()}
                  />
              }
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(names?.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

FlowMeterSummary.defaultProps = {
  list: [],
};

FlowMeterSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
