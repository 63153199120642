import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TabsContent } from './TabsContent';
import { renderTableWithoutHeader } from '../../../helper/common';
import { usePrepareIrrigationEquationDetailsTableData } from '../../../helper/recommendationHooks';

export function IrrigationEquationDetails({ recommendation, units, ranchId }){
  const [activeTabKey, setActiveTabKey] = useState('1');
  const { t } = useTranslation();

  const {
    availableWater,
    recommendedRuntimeDetails,
    totalHoursToReachTarget,
    additionalHoursToReachTarget,
    predictedDeficit,
    irrigationSystem,
    summary
  } = usePrepareIrrigationEquationDetailsTableData(recommendation,
    units, ranchId, setActiveTabKey, t);

  const items = [
    {
      key: '1',
      label: 'Summary',
      children: renderTableWithoutHeader(summary, true)
    },
    {
      key: '2',
      label: 'Available Water',
      children: renderTableWithoutHeader(availableWater)
    },
    {
      key: '3',
      label: 'ETc',
      children: renderTableWithoutHeader(predictedDeficit)
    },
    {
      key: '4',
      label: 'Irrigation System',
      children: renderTableWithoutHeader(irrigationSystem)
    },
    {
      key: '5',
      label: 'Runtime Details',
      children: (
        <>
          {renderTableWithoutHeader(recommendedRuntimeDetails)}
          <span className="bold-text">Total hours to reach targets</span>
          {renderTableWithoutHeader(totalHoursToReachTarget)}
          <span className="bold-text">Additional hours to reach targets</span>
          {renderTableWithoutHeader(additionalHoursToReachTarget)}
        </>)
    },
  ];

  const onTabsChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <TabsContent activeKey={activeTabKey} renderContent={items} onChange={onTabsChange} />
  );
}

IrrigationEquationDetails.propTypes = {
  renderContent: PropTypes.shape({}),
  recommendation: PropTypes.shape({}),
  units: PropTypes.shape(),
  ranchId: PropTypes.number.isRequired,
};

IrrigationEquationDetails.defaultProps = {
  renderContent: {},
  recommendation: {},
  units: {
    deficit: 'millimeters',
    etcForecast: 'millimeters',
    totalWater: 'millimeters',
  },
};
