import React, { useState } from 'react';
import { Select } from 'antd';

export interface UserRoleSelectProps {
  record: any;
  onUserAccessUpdateRole: (record: any, role: string) => Promise<boolean>;
  disabled: boolean;
}

/**
 * UserRoleSelect is a functional presentational component used to display a select input for updating the role of a user.
 * @param {UserRoleSelectProps.record} record - The TeamUserAccess record to display and update the role.
 * @param {UserRoleSelectProps.onUserAccessUpdateRole} onUserAccessUpdateRole - The method to handle updating the role.
 * @param {UserRoleSelectProps.disabled} disabled - The disabled status of the component.
 * @returns 
 */
const UserRoleSelect:React.FC<UserRoleSelectProps> = ({ record, onUserAccessUpdateRole, disabled }) => {
  // Initialize state with the default role
  const [selectedRole, setSelectedRole] = useState(record.role.name);

  const handleChange = async (value:string) => {
    // Immediately update the component to show the new value
    setSelectedRole(value);
    const success = await onUserAccessUpdateRole(record, value);
    if (!success) {
      // If the update is unsuccessful, revert to the original role
      setSelectedRole(record.role.name);
    }
  };

  return (
    <Select
      value={selectedRole}
      onChange={handleChange}
      style={{width: '100%', maxWidth: 250}}
      disabled={disabled}
    >
      <Select.Option value="Team Manager">Team Manager</Select.Option>
      <Select.Option value="Team Member">Team Member</Select.Option>
    </Select>
  );
};

export default UserRoleSelect;
