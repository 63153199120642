import React, { useRef, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import './graph.less';

NoDataToDisplay(Highcharts);

const defaultOptions = {
  title: null,
  xAxis: {
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 1,
  },
  series: [],
  credits: {
    enabled: false,
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030',
    },
  },
  time: {
    useUTC: false,
  },
  chart: {
    zoomType: 'xy',
    spacing: [1, 1, 1, 1], // for compact style
  },
};

export default function NewChartComponent(props) {
  const {
    config, setChartRef
  } = props;
  const highChartsComponent = useRef(null);

  useEffect(() => {
    // remove after testing
    // console.log('Trying reflow...', config);
    if (highChartsComponent.current) {
      highChartsComponent.current.chart.reflow();
    }
  });


  useEffect(() => {
    if(highChartsComponent) setChartRef(highChartsComponent);
  }, [setChartRef]);

  // remove after testing
  // useEffect(() => {
  //   console.log('loading in core', loading);
  //   const chartObj = highChartsComponent.current?.chart;
  //   if (!highChartsComponent.current || !chartObj) return;

  //   if (!loading && error) chartObj.showLoading(error);
  //   if (loading && !error) chartObj.showLoading();
  //   if (!loading && !error) chartObj.hideLoading();
  // }, [error, loading]);

  const options = useMemo(() => ({
    ...defaultOptions,
    ...config,
    lang: {
      noData: config && !config.series?.length ? 'No Data' : ''
    }
  }), [config]);

  window.chart = highChartsComponent;

  return (
    <HighchartsReact
      constructorType="chart"
      containerProps={{ className: 'chart-container-div-style' }}
      highcharts={Highcharts}
      options={options}
      ref={highChartsComponent}
    />
  );
}

NewChartComponent.propTypes = {
  setChartRef: PropTypes.func,
  config: PropTypes.shape(),
  error: PropTypes.string,
  loading: PropTypes.bool,
};

NewChartComponent.defaultProps = {
  setChartRef: () => null,
  config: null,
  error: null,
  loading: false,
};
