import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TeamsList from './TeamsList';
import { RouteComponentProps } from 'react-router-dom';
import { message } from 'antd';
import { FarmxWebApiTypes } from 'types/api_types';
//@ts-ignore
import { selectors } from 'farmx-redux-core';
//@ts-ignore
import { teamApi } from 'farmx-api';
import useUserRoles from 'components/organization/hooks/useUserRoles';
import useEntities from 'components/organization/hooks/useEntities';

/**
 * TeamsListContainer is a container component that fetches the teams data from the api and passes it to the TeamsList component.
 * @param {RouteComponentProps} props - The standard react-router-dom props.
 * @returns {JSX.Element} 
 */
const TeamsListContainer: React.FC<RouteComponentProps> = (props:RouteComponentProps) => {
  // Destructure history from props
  const { history } = props;
  
  // State of the loading status of API call
  const [loading, setLoading] = useState(true);

  // State of the pagedresult of the teams query
  const [pagedResult, setPagedResult] = useState<FarmxWebApiTypes.PaginatedResult<any>>({
    data: [],
    page: 0,
    page_size: 0,
    count: 0,
  });

  // State of the currently selected entity id from the redux store 
  const { selectedEntity } = useEntities();

  // State of the current spec for fetching teams
  const [spec, setSpec] = useState<FarmxWebApiTypes.ListQuery>({
      includes: ['team_accesses.user', 'ranches'],
      page_number: 1,
      page_size: 10
  });
  
  /**
   * Side effect to fetch the teams data from the api when the spec is changed or initialized
   * @returns {void}
   */
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        setLoading(true);
        var teamsResult = await teamApi.listTeams({...spec, entity_id: selectedEntity?.id});
        setPagedResult(teamsResult.data);
        setLoading(false);
      } catch (error) {
        message.error('Failed to fetch data.');
      }
      setLoading(false);
    };
    loadData();
  }, [spec, selectedEntity?.id]);

  /**
   * Method to handle the page change - updates the query spec based on the new page number and page size
   * @param page - The new page number
   * @param pageSize - The new page size
   * @returns {void}
   */
  const handlePageChange = (page: number, pageSize: number) => {
    setSpec((prev: any) => ({
      ...prev,
      page_number: page,
      page_size: pageSize || prev.page_size,
    }));
  };

  /**
   *  Method to handle the team selection - navigates to the team detail page
   * @param team - The selected team
   * @returns {void}
   */
  const handleTeamSelect = (team: any) => {
    history.push({
      pathname: `/organization/teams/${team.id}`,
      state: { team: team }
    })
  };

  /**
   * Method to handle the team creation - navigates to the team creation page
   * @returns {void}
   */
  const handleCreateTeam = () => {
    history.push('/organization/teams/create');
  };

  const roles = useUserRoles();

  return (
    <TeamsList
      loading={loading}
      pagedResult={pagedResult}
      tableSpec={spec}
      onPageChange={handlePageChange}
      onTeamSelect={handleTeamSelect}
      onCreateTeam={handleCreateTeam}
      currentUserRoles={roles}
    />
  );
};

export default TeamsListContainer;