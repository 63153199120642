import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Typography,
  Select,
  notification,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { blockApi } from 'farmx-api';
import { isMobile } from 'react-device-detect';
import { actions, selectors, hooks } from 'farmx-redux-core';
import RecommendationLoader from '../../contentLoaders/RecommendationContentLoader';
import { PageHeader } from '../components/PageHeader';
import { IrrigationEquation } from '../components/IrrigationEquation';
import { StatusTag } from '../components/StatusTag';
import { CardLayout } from '../components/CardLayout';
import { getTextForIrrigationState, getStateForIrrigationState, getActionText } from './recommendation';
import { useTracking } from '../../../helper/mixpanel';
import { PercentAvailableWaterGraph } from './PercentAvailableWaterGraph';
import { EtcDeficitGraph } from './EtcDeficitGraph';
import './recommendation-detail.css';
import RanchBlockTitle from '../components/RanchBlockTitle';
import { recommendationAnomalyFilter, renderRecommendationDateDetail, checkIsAllRanch } from '../../../helper/common';
import SensorDataChart from '../../graph/SensorDataChart';
import { useNavigateHome } from '../../../helper/recommendationHooks';
import { IrrigationEquationDetails } from '../components/IrrigationEquationDetails';
import { RecommendationGraphs } from './RecommendationGraphs';
import ForecastCard from './ForecastCard';

const {
  selectRecommendationForBlock,
  selectNewBlockById,
  selectSensorsByRanchId,
  selectLoadingRecommendations,
  selectUserLengthFormat,
} = selectors;

const {
  loadRecommendationForBlock,
  updateRecommendation,
  setBlocks,
  loadRecommendations,
} = actions;

const { useRanchBlockSelection, useEntities } = hooks;

export function RecommendationDetail(props) {
  const {
    showMap, onClick, propBlockId, showGraphButtons,
  } = props;
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();
  const tracking = useTracking();
  const { identifier } = useParams();
  const blkId = parseInt(identifier, 10);
  const blockId = blkId || propBlockId;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [infoType, setInfoType] = useState(!showMap ? 'deficit' : 'vwc');
  const [VWCData, setVWCData] = useState(null);
  const [days, setDays] = useState(10);
  const [loading, setLoading] = useState(false);
  // State to display irrigation equation table
  const [tableVisible, setTableVisibile] = useState(false);
  const { useBlockNames, useRanchNamesForBlocks } = hooks;
  const blockName = useBlockNames([Number(blockId)]);
  const ranchName = useRanchNamesForBlocks([Number(blockId)]);
  const { selectedTab } = location.state ?? {};
  const recommendation = useSelector((state) => recommendationAnomalyFilter(
    selectRecommendationForBlock(state, blockId),
  ));
  const loadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const units = recommendation?.units || {
    deficit: 'millimeters',
    etcForecast: 'millimeters',
    totalWater: 'millimeters',
  };
  const { selectedObjFromState } = useRanchBlockSelection();

  const { activeEntityId } = useEntities();

  // Navigate to home when ranch/block is changed
  useNavigateHome();

  const irrigationModel = recommendation?.irrigationModel || 'soil_water_deficit';
  const block = useSelector((state) => selectNewBlockById(state, Number(blockId))) || {};
  const sensors = useSelector((state) => selectSensorsByRanchId(state, block.ranch))
    .filter((d) => d?.block === block.id && (d?.type === 'aquacheck_soil'
    || d?.type === 'pixl_soil'));
  const isAllRanch = checkIsAllRanch(selectedObjFromState?.type);
  const sensor = sensors.length?sensors[0]:{type: null, identifier: null, id: null};
  const userUnitLength = useSelector((state) => selectUserLengthFormat(state));
  const toUnitByLength = userUnitLength === 'feet' ? 'inches' : 'millimeters';

  const rootZoneVWCChart = useMemo(() => (
    <SensorDataChart
      sensor={{
        type: sensor.type,
        identifier: sensor.identifier,
        id: sensor.id,
      }}
      variables={['soil_moisture_rootzone_vwc']}
      startDate={moment().subtract(days, 'days')}
      endDate={moment()}
    />
  ), [sensor.id, sensor.identifier, sensor.type, days]);

  useEffect(() => {
    if (blockId) {
      tracking.track('Loaded Recommendation Detail Page', { blockId });
    }
  }, [tracking, blockId]);

  useEffect(() => {
    if (blockId) {
      dispatch(loadRecommendationForBlock(blockId));
      // Set block in global block selector
      if(blkId){
        dispatch(setBlocks([blockId]));
      }
    }

    // Unmounting set block when ranch is selected
    return () => {
      if(selectedObjFromState.type === 'ranch') {
        dispatch(setBlocks([block?.ranch]));
      }
      // Unmounting block and ranch when Allranches selected
      if(isAllRanch) {
        dispatch(setBlocks([]));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, blockId]);

  // Load recommendations only when there is no data from redux-state
  // Case: Loading page directly by URL
  useEffect(() => {
    if (!recommendation?.id && activeEntityId) {
      dispatch(loadRecommendations(activeEntityId));
    }
  }, [dispatch, recommendation, activeEntityId]);

  // commented code will be removed in the future
  // To get the block rootzone-vwc-graph data points
  // useEffect(() => {
  //   if (infoType === 'vwc') {
  //     const startDate = moment().subtract(days, 'days').toISOString();
  //     const endDate = moment().toISOString();
  //     setLoading(true);
  //     try {
  //       blockApi.getBlockRootzoneVWCGraph({ blockId, startDate, endDate }).then((response) => {
  //         if (response.data) setVWCData(response.data.map((d) => ({ x: d[0], y: d[1] })));
  //         setLoading(false);
  //       }).catch(() => {
  //         notification.error({
  //           message: t('Failed to load rootzone-vwc-graph points'),
  //         });
  //         setLoading(false);
  //       });
  //     } catch {
  //       setLoading(false);
  //     }
  //   }
  // }, [blockId, days, infoType, t]);

  // TODO: loading
  if (!recommendation && !loadingStatus.loading) {
    return (
      <div>Recommendation not found</div>
    );
  }

  // Loader indicator
  if (loadingStatus.loading && !recommendation) {
    return (
      <RecommendationLoader />
    );
  }

  function roundValue(value) {
    return Math.round(value * 100) / 100;
  }

  const dendrometerCount = 0;
  const duration = recommendation ? moment.duration(recommendation.duration).asDays() : null;

  function getSoilMessage() {
    if (recommendation.deficit === null || recommendation.deficit === undefined) {
      return null;
    }
    const { value: deficitConverted, label: depthLabel } = getUserUnits(recommendation.deficit, units.deficit, 'depth', {
      decimalPlaces: 2,
    });
    if (deficitConverted >= 0) {
      return `${t('The root zone is')} ${roundValue(deficitConverted)} ${t(depthLabel)}
       ${t('below the refill point on average')}.`;
    }
    return `${t('The root zone is')} ${-roundValue(deficitConverted)} ${t(depthLabel)}
    ${t('above the refill point on average')}.`;
  }

  function getEtcDeficitMessage() {
    if (recommendation.deficit === null || recommendation.deficit === undefined) {
      return null;
    }
    const { value: deficitConverted, label: depthLabel } = getUserUnits(recommendation.deficit, units.deficit, 'depth', {
      decimalPlaces: 2,
    });
    if (deficitConverted >= 0) {
      return `${t('The applied irrigation is')} ${roundValue(deficitConverted)} ${t(depthLabel)}
       ${t('below the measured ETc this season')}.`;
    }
    return `${t('The applied irrigation is')} ${-roundValue(deficitConverted)} ${t(depthLabel)}
    ${t('above the measured ETc this season')}.`;
  }

  function getDeficitMessage() {
    if (irrigationModel === 'soil_water_deficit') {
      return getSoilMessage();
    }
    if (irrigationModel === 'etc_deficit') {
      return getEtcDeficitMessage();
    }
    return null;
  }

  function getEtcMessage() {
    if (recommendation.etcForecast === null || recommendation.etcForecast === undefined) {
      return null;
    }
    const {
      value: etcForecastConverted,
      label: depthLabel,
    } = getUserUnits(recommendation.etcForecast, units.etcForecast, 'depth', {
      decimalPlaces: 2,
    });
    if (recommendation.deficit < 0) {
      return `${t('The surplus is predicted to decrease by')} ${roundValue(etcForecastConverted)}
       ${t(depthLabel)} ${t('over the next')} ${duration} ${t('days')}
       ${t('due to the local ETc forecast')}.`;
    }
    return `${t('The deficit is predicted to increase by')} ${roundValue(etcForecastConverted)}
     ${t(depthLabel)} ${t('over the next')} ${duration} ${t('days')}
      ${t('due to the local ETc forecast')}.`;
  }

  function getIrrigationDescriptionLong() {
    const messages = [];
    const deficitMessage = getDeficitMessage();
    const etcMessage = getEtcMessage();
    const dendrometerMessage = `${t(recommendation.cropStress)} ${t('is indicated across')}
     ${dendrometerCount} ${t('dendrometers')}`;

    if (deficitMessage) {
      messages.push(deficitMessage);
    }
    if (etcMessage) {
      messages.push(etcMessage);
    }
    if (dendrometerCount && recommendation.cropStress) {
      messages.push(dendrometerMessage);
    }
    return messages.join(' ');
  }

  function renderGraphButtons() {
    return (
      <div style={{ width: '50%' }}>
        {!showMap && (
        <Button
          style={{ width: '60px', padding: '0' }}
          type={infoType === 'deficit' ? 'primary' : 'default'}
          onClick={() => {
            if (infoType !== 'deficit') {
              setInfoType('deficit');
            }
          }}
        >
          {t('Deficit')}
        </Button>
        )}

        {showMap && (
        <Button
          style={{ width: '60px', padding: '0' }}
          type="default"
          onClick={() => {
            if (onClick) onClick('map');
          }}
        >
          {t('Map')}
        </Button>
        )}

        <Button
          style={{ width: '60px', padding: '0' }}
          type={(infoType === 'vwc' || showMap) ? 'primary' : 'default'}
          onClick={() => {
            if (!showMap && infoType !== 'vwc') {
              setInfoType('vwc');
            }
            if (showMap && onClick) onClick('vwc');
          }}
        >
          {t('VWC')}
        </Button>
      </div>
    );
  }

  function renderSoilGraphs() {
    return (
      <div className="recommendation-detail-card2-container">
        <div className="recommendation-detail-card2-top">
          {showGraphButtons && !showMap ? renderGraphButtons() : (
            <div style={{ width: '50%' }}>
              <div className="recommendation-detail-card2-deficit">
                <Typography.Text>{t('Percentage VWC')}</Typography.Text>
              </div>
            </div>
          )}
          {infoType === 'deficit' && (
            <div className="recommendation-detail-card2-deficit">
              <Typography.Text>{t('Percent Available Water')}</Typography.Text>
            </div>
          )}

          {infoType === 'vwc' && (
            <div style={{ display: 'flex' }}>
              <Select defaultValue={10} value={days} onSelect={setDays}>
                <Select.Option value={10}>{`10 ${t('Days')}`}</Select.Option>
                <Select.Option value={20}>{`20 ${t('Days')}`}</Select.Option>
              </Select>
            </div>
          )}
        </div>
        {infoType === 'deficit' && (
          <div style={{ backgroundColor: '#aaa', height: '200px' }}>
            <PercentAvailableWaterGraph blockId={Number(recommendation.block)} />
          </div>
        )}
        {infoType === 'vwc' && showGraphButtons && !showMap && (
          <div className="recommendation-detail-card2-deficit">
            <Typography.Text>{t('Percentage VWC')}</Typography.Text>
          </div>
        )}
        {infoType === 'vwc' && (
          <div style={{ backgroundColor: '#aaa', height: '200px', marginTop: '10px' }}>
            {sensor.identifier?rootZoneVWCChart:null}
            {/* {loading && VWCData && <LoadingOutlined className="absolute-center" />} */}
          </div>
        )}
      </div>
    );
  }

  function renderEtcGraphs() {
    return (
      <div className="recommendation-detail-card2-container">
        <div className="recommendation-detail-card2-top">
          <div className="recommendation-detail-card2-deficit">
            <Typography.Text>{t('Etc Deficit')}</Typography.Text>
          </div>
        </div>
        <div style={{ backgroundColor: '#aaa', height: '200px' }}>
          <EtcDeficitGraph
            irrigationApplied={recommendation.irrigationApplied}
            etcHistoric={recommendation.etcHistoric}
            etcForecast={recommendation.etcForecast}
            irrigationScheduled={recommendation.irrigationScheduled}
            units={recommendation.units}
          />
        </div>
      </div>
    );
  }

  function renderGraphs() {
    if (irrigationModel === 'soil_water_deficit') {
      return renderSoilGraphs();
    }

    if (irrigationModel === 'etc_deficit') {
      return renderEtcGraphs();
    }

    return null;
  }


  const translatedDescription = t(recommendation.description);

  const ranchBlockName = `${ranchName[blockId]} > ${blockName[blockId]}`;

  function getRecommendationMessage(modelType) {
    let modelTypeText = null;
    if (modelType === 'etc_deficit') modelTypeText = 'ETc';
    if (modelType === 'soil_water_deficit') modelTypeText = 'Soil';
    return modelTypeText ? (
      <small>
        <em>
          {t(`This recommendation was generated using the ${modelTypeText} Deficit Model.`)}
        </em>
      </small>
    ) : null;
  }

  // className will be applicable only for desktop view
  // and when irrigation equation details table is visible
  const fixedButtonPosition = !isMobile && tableVisible && 'recommendation-irrigation-action-buttons';

  function renderRecommendationActionButtons() {
    return (
      <div className={`${fixedButtonPosition} action-footer recommendation-detail-actions
       recommendation-buttons padding-bottom-10`}
      >
        {recommendation.action !== 'no_action' ? (
          <Button
            size="large"
            block
            type="primary"
            onClick={() => {
              history.push({
                pathname: `/recommendation/action/${blockId}`,
                state: {
                  from: location.pathname,
                  selectedTab,
                },
              });
              tracking.track('Clicked recommendation action', recommendation);
            }}
          >
            {getActionText(recommendation.action)}
          </Button>
        ) : (
          <Button
            size="large"
            block
            type="primary"
            onClick={() => {
              history.push({
                pathname: '/schedule',
                state: {
                  from: location.pathname,
                  blockId,
                },
              });
              tracking.track('Clicked view schedule', recommendation);
            }}
          >
            {t('View Schedule')}
          </Button>
        )}
        {recommendation.freezeType !== 'action' && (
        <>
          {recommendation.isFrozen && (
          <Button
            size="large"
            block
            onClick={() => {
              dispatch(updateRecommendation({
                id: recommendation.id,
                type: 'irrigation',
                data: {
                  isFrozen: false,
                  dateFrozen: moment().toISOString(),
                },
              }));
              history.push({
                pathname: '/recommendation',
                state: { selectedTab },
              });
              tracking.track('Clicked Undo Dismissed recommendation', recommendation);
            }}
          >
            {t('Undo Dismiss')}
          </Button>
          )}
          {!recommendation.isFrozen && (
          <Button
            size="large"
            block
            onClick={() => {
              dispatch(updateRecommendation({
                id: recommendation.id,
                type: 'irrigation',
                data: {
                  isFrozen: true,
                  dateFrozen: moment().toISOString(),
                  freezeType: 'dismiss',
                },
              }));
              history.push({
                pathname: '/recommendation',
                state: { selectedTab },
              });
              tracking.track('Dismissed recommendation', recommendation);
            }}
          >
            {t('Dismiss')}
          </Button>
          )}
        </>
        )}
      </div>
    );
  }

  return (
    <>
      {!showMap ? (
        <div
          className="page-content page-container margin-10 recommendation-container"
          id="irrigation-recommendation-detail"
        >
          <PageHeader
            showBack
            toPath="/recommendation"
            title={<RanchBlockTitle title={ranchBlockName} />}
            state={{ selectedTab }}
          />
          <CardLayout key="1">
            <div className="recommendation-detail-card1-container">
              <ForecastCard
                recommendation={recommendation}
              />
              <div className="recommendation-text-line-height">
                <Typography.Text>{translatedDescription}</Typography.Text>
                <Typography.Text className="text-margin">
                  {getIrrigationDescriptionLong()}
                </Typography.Text>
              </div>
              <div className="margin-top-10 recommendation-generated-line-height">
                {getRecommendationMessage(irrigationModel)}
                {renderRecommendationDateDetail(recommendation, t)}
              </div>
            </div>
          </CardLayout>
          <CardLayout key="2">
            {/* commented code will be removed in the future */}
            {/* {renderGraphs()} */}
            <RecommendationGraphs
              recommendation={recommendation}
              sensors={sensors}
            />
          </CardLayout>

          <IrrigationEquation
            deficit={Number(recommendation.deficit)}
            etcForecast={Number(recommendation.etcForecast)}
            irrigationDuration={recommendation.irrigationDuration}
            units={units}
            showMoreLink
            visible={tableVisible}
            onClick={() => setTableVisibile(!tableVisible)}
            toUnitByLength={toUnitByLength}
          />
          {tableVisible && (
            // style is props that we have used from card layout component,
            // not a inline style
            <CardLayout key="3" style={{ marginBottom: !isMobile && '45px'}}>
              <IrrigationEquationDetails
                recommendation={recommendation}
                units={units}
                ranchId={block?.ranch}
              />
            </CardLayout>
          )}
          {renderRecommendationActionButtons()}
        </div>
      ) : renderGraphs()}
    </>
  );
}

RecommendationDetail.propTypes = {
  showMap: PropTypes.bool,
  onClick: PropTypes.func,
  propBlockId: PropTypes.number,
  showGraphButtons: PropTypes.bool,
};

RecommendationDetail.defaultProps = {
  showMap: false,
  onClick: null,
  propBlockId: null,
  showGraphButtons: true,
};
