import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { DRIP_COOLING_BLOCK_IDS, SPRINKLER_BLOCK_ID, 
  SPRINKLER_BLOCK_IDS_AUTO, SPRINKLER_BLOCK_IDS_REPL } from '../../../constants';
import RenderIrrigationBlock from './RenderIrrigationBlock';
import RenderAutonomousIrrigationBlock from './RenderAutonomousIrrigationBlock';

const {
  selectNewBlocksForRanchIdAlphabetically,
} = selectors;

const RenderIrrigationRanch = (props) => {
  // previous =  just to support 2024 blocks temporarily
  const { ranch, isAutonomous, previous } = props;
  const blocks = useSelector((state) => (
    selectNewBlocksForRanchIdAlphabetically(state, ranch.id))) || [];

  // Exclude Sprinkler and Drip cooling block
  const filteredBlock = !isAutonomous ? blocks.filter((blk) => ![...SPRINKLER_BLOCK_IDS_REPL]
    .includes(blk.id)): blocks.filter((blk) => ![...SPRINKLER_BLOCK_IDS_AUTO]
    .includes(blk.id));
  const prevSeasonIrrigationBlocks = blocks.filter(
    (blk) => ![SPRINKLER_BLOCK_ID, ...DRIP_COOLING_BLOCK_IDS].includes(blk.id));
  return blocks.length > 0 ? (
    <div className="render-ranch-data">
      <Typography.Text className="ranch-name">{ranch.name}</Typography.Text>
      <div className="padding-10">
        {previous && prevSeasonIrrigationBlocks.map((block) => (
          <RenderIrrigationBlock
            key={block.id}
            block={block}
          />
        ))}
        {!previous && !isAutonomous && filteredBlock.map((block) => (
          <RenderIrrigationBlock
            key={block.id}
            block={block}
          />
        ))}
        {!previous && isAutonomous && filteredBlock.map((block) => (
          <RenderAutonomousIrrigationBlock
            key={block.id}
            block={block}
          />
        ))}
      </div>
    </div>
  ) : null;
};

RenderIrrigationRanch.propTypes = {
  ranch: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  isAutonomous: PropTypes.bool,
  previous: PropTypes.bool,
};

RenderIrrigationRanch.defaultProps = {
  isAutonomous: false,
  previous: false,
};

export default RenderIrrigationRanch;
