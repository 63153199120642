import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

type ActiveTagProps = {
  value: boolean | undefined
};
const getColorForValue = (value:boolean) => {
  if (value) return 'green';
  return 'default';
};

const ActiveTag = ({ value }: ActiveTagProps) => {
  const { t } = useTranslation();

  if (value === undefined) return null;
  return (
    <Tag color={getColorForValue(value)}>
      { value ? t('active') : t('inactive') }
    </Tag>
  );
};

export default ActiveTag;
