import React, { useCallback } from 'react';
import { Select } from 'antd';
import { MdDelete } from 'react-icons/md';
import { SensorSelectProps } from './type';
import { getSensorTypeShortForm } from './constants';

const { Option } = Select;

const SimpleSensorSelect = (
  props: SensorSelectProps
) => {
  const {
    sensors, onChange, onClear, values
  } = props;

  const onClick = useCallback(() => {
    onClear();
  }, [onClear]);

  const filterOption = useCallback(
    (input: any, option: any) => option?.label?.toLowerCase().includes(input.toLowerCase()), []
  );

  const getSensorName = useCallback((sensor) => {
    if(sensor.name) return sensor.name;
    return `${getSensorTypeShortForm(sensor.type)}-${sensor.identifier}`;
  }, []);

  return (
    <div className="graph-page-sensor-select">
      <div className="graph-page-sensor-select-label">Sensors</div>
      <Select
        showSearch
        filterOption={filterOption}
        mode="multiple"
        className="select-component"
        placeholder="Select Sensors"
        value={values?.length?values:undefined}
        onChange={onChange}
        optionLabelProp="label"
        allowClear
        maxTagCount="responsive"
      >
        {
      sensors?.map((sensor) => (
        <Option key={`${sensor.name}-${sensor.identifier}`} value={sensor.id} label={getSensorName(sensor)}>
          <div>
            {getSensorName(sensor)}
          </div>
        </Option>
      ))
    }
      </Select>
      <div
        className="graph-page-sensor-select-clear"
        role="button"
        onClick={onClick}
        tabIndex={0}
        onKeyDown={onClick}
      >
        <MdDelete size="1.5em" title="Clear All Selection" />
      </div>
    </div>
  );
};

export default SimpleSensorSelect;
