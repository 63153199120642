export const irrigationStatus = {
  IRRIGATING: 'IRRIGATING',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
};

export const valveOverrideState = {
  CLOSED: 'closed',
  OPEN: 'open',
  SCHEDULED: 'scheduled',
};

export const pumpOverrideState = {
  CLOSED: 'off',
  OPEN: 'on',
  SCHEDULED: 'scheduled',
};

export const installState = {
  INSTALLED: 'installed',
};

export const capabilities = {
  water_pressure: ['water_pressure'],
  aquacheck_soil: ['soil_moisture_8', 'soil_moisture_16', 'soil_moisture_24', 'soil_moisture_32', 'soil_moisture_40', 'soil_moisture_48'],
  pixl_soil: ['soil_moisture_6', 'soil_moisture_12', 'soil_moisture_18', 'soil_moisture_24', 'soil_moisture_30', 'soil_moisture_36', 'soil_moisture_42', 'soil_moisture_48'],
};

export const ALL_SENSORS_LIST = {
  weather_station: {},
  aquacheck_soil: {},
  pixl_soil: {},
  farmx_dendrometer: {},
  farmx_infrared: {},
  water_pressure: {},
  vfd: {},
  water_flow_analog: {},
  water_flow: {},
  valve: {},
  farmx_frost: {},
  gateway: {},
  sentek_soil: {},
  cavalier: {},
};

export const SENSORS_VISIBLE_TO_CLIENT = {
  weather_station: {},
  aquacheck_soil: {},
  pixl_soil: {},
  farmx_dendrometer: {},
  farmx_infrared: {},
  water_pressure: {},
  vfd: {},
  water_flow_analog: {},
  water_flow: {},
  valve: {},
  farmx_frost: {},
};

/**
 * Special Case: For GEC Project.
 * When to remove/Refactor: Once we start implementing
 * Sensor co-ordination configuration through frontend.
 */
export const GEC_RANCH_ID = Number(process.env.REACT_APP_GEC_RANCH);
export const SPRINKLER_BLOCK_ID = Number(process.env.REACT_APP_SPRINKLER_BLOCK);
export const GEC_ENTITY_ID = Number(process.env.REACT_APP_GEC_ENTITY_ID);
export const GEC_RANCH_WATERMELON_REPL = Number(process.env.REACT_APP_GEC_RANCH_WATERMELON_REPL);
export const GEC_RANCH_WATERMELON_AUTO = Number(process.env.REACT_APP_GEC_RANCH_WATERMELON_AUTO);
export const GEC_RANCH_SP = Number(process.env.REACT_APP_GEC_RANCH);
export const SPRINKLER_BLOCK_IDS_REPL = process.env.REACT_APP_SPRINKLER_BLOCK_IDS_REPL.split(',')
  .map((v) => Number(v));
export const SPRINKLER_BLOCK_IDS_AUTO = process.env.REACT_APP_SPRINKLER_BLOCK_IDS_AUTO.split(',')
  .map((v) => Number(v));
export const DRIP_COOLING_BLOCK_ID = Number(process.env.REACT_APP_DRIP_COOLING_BLOCK);
export const DRIP_COOLING_BLOCK_IDS = process.env.REACT_APP_DRIP_COOLING_BLOCKS.split(',')
  .map((v) => Number(v));

export const SOURCE_RANCH_IDS = process.env?.REACT_APP_SOURCE_RANCHES?.split(',')
  .map((v) => Number(v));
export const GEC_AUTONOMOUS_RANCHES = process.env?.REACT_APP_AUTONOMOUS_RANCHES?.split(',')
  .map((v) => Number(v));

export const RECOMMENDATION_OPTIONS = [
  { label: 'Auto', value: true },
  { label: 'Let me decide', value: false },
];

export const REST_AUTO_OPTIONS = [
  { label: 'Enable', value: true },
  { label: 'Disable', value: false },
];

// Summary bar graph colors as same as mockup
export const SUMMARY_GRAPH_BAR_COLOR = {
  deficit: '#f0b138',
  forecast: '#d63a29',
  totalDeficit: '#d13b3e',
  scheduled: '#4788d8',
  requiredHours: '#68c379',
  precipitationForecast: 'lightblue'
};

// Below rules only for the Form items
export const FORM_RULES = {
  requiredField: [{ required: true }],
  requiredEndDateValidation: [
    { required: true, message: 'Please select an end date!' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        // Update the depending field value below if needed
        const startDate = getFieldValue('starting_time_window');
        if (!value || !startDate || value?.isAfter(startDate)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('End date must be after the start date!')
        );
      },
    }),
  ],
  requiredFieldWithPercentage: [
    { required: true, message: 'Please enter a percentage!' },
    () => ({
      validator(_, value) {
        if (value === undefined || (value >= 0 && value <= 100)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Value must be between 0 and 100'));
      },
    }),
  ],
  requiredNumberField: [
    {
      required: true,
      message: 'This field is required.',
    },
    {
      pattern: /^-?\d*(\.\d+)?$/,
      message: 'Please enter a valid number.',
    },
  ],
  requiredPositiveNumberField: [
    {
      required: true,
      message: 'This field is required.',
    },
    {
      pattern: /^\d*\.?\d+$/,
      message: 'Please enter a positive number.',
    },
  ]
};

export const TEMPERATURE_LABEL = '°C';
