/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Button, Select, DatePicker, Input, Form, notification
} from 'antd';
import {hooks, selectors} from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { displayConvertedDepth } from './depthUnitHelper';
import { TEMPERATURE_LABEL } from '../constants';
import {
  createThresholdConfig,
  updateThresholdConfig
} from './settingsHelper';

const { selectUserDepthFormat } = selectors;

// This will be updated in the future
const defaultRootZoneDepth = 6;
const allWeekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// To maintain the existing selection on edit mode
export const autonomousTypes = {
  unknown: { actionType: 'unknown', subType: 'unknown'},
  irrigation: {
    actionType: 'irrigation',
    subType: 'autonomous_irrigation'
  },
  sprinkler: {
    actionType: 'sprinkler',
    subType: 'autonomous_sprinkler_irrigation'
  },
  cooling: {
    actionType: 'sprinkler',
    subType: 'autonomous_sprinkler_cooling'
  }
};

export const autonomouseKeys = {
  'unknown/unknown': 'unknown',
  'irrigation/autonomous_irrigation': 'irrigation',
  'sprinkler/autonomous_sprinkler_irrigation': 'sprinkler',
  'sprinkler/autonomous_sprinkler_cooling': 'cooling', // for rendering
};

export const useRenderReusableControls = (t: any) => {
  const renderButton = (name: any, type?: any, htmlType?: any,
    onClick?: any, disabled?: any, block?: any, loading?: any) => (
      <Button
        type={type || undefined}
        htmlType={htmlType || undefined}
        onClick={onClick}
        disabled={disabled}
        block={!block}
        loading={loading}
      >
        {t(name)}
      </Button>
  );

  const renderSelect = (options: any, placeholder?: any,
    value?: any, onChange?: any, disabled?: any, loading?: any, className?: any) => (
      <Select
        placeholder={t(placeholder)}
        defaultValue={value}
        options={options}
        onChange={onChange}
        disabled={disabled}
        className={className}
        loading={loading}
        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      />
  );

  const renderDatePicker = (dateValue: any, unitsTime?: any) => (
    <DatePicker
      showTime
      showNow={false}
      format={unitsTime
        ? 'YYYY-MM-DD hh:mm:ss a' : 'YYYY-MM-DD HH:mm:ss'}
      defaultValue={dateValue}
      use12Hours={unitsTime}
      onOk={null}
      className="form-item-width-100"
      getPopupContainer={() => document.body}
    />
  );

  const renderInputFormItem = (name: string, label: any, unitLabel: string,
    className?: any, rule?: any, placeholder?: any) => (
      <Form.Item
        name={name}
        label={label}
        className={className || 'font-bold flex-row-center'}
        rules={rule}
      >
        <Input
          placeholder={t(placeholder)}
          suffix={unitLabel}
        />
      </Form.Item>
  );

  return {
    renderButton, renderSelect, renderDatePicker, renderInputFormItem,
  };
};

export const generateArray = (num: number) => [...Array(num).keys()].map((x) => x + 1);

export const generateSensorDepthOptions = (depthCount: number,
  sensorStatus: any, getUserUnits: any, depthFormat: any) => generateArray(depthCount || 0)
    ?.map((d: number) => {
      const depthValue = sensorStatus?.[`depth${d}`] || (d * defaultRootZoneDepth);
      const convertedValve = displayConvertedDepth(depthValue, getUserUnits, depthFormat);
      const [value] = convertedValve?.split(' ');
      return { value, label: convertedValve };
    });

export const useUpdateFormFields = (autoFillValues: any, form: any, now: any, endDate: any,
  autonomousMethod: any, setUpdatedInitialValues: any, setSelectedBlock: any,
  setSelectedSensor: any, setAutonomousMethod: any) => {
  const getUserUnits = hooks.useUnits();

  useEffect(() => {
    if (autoFillValues) {
      const {
        block_id, action_type: actionType,
        action_subtype: actionSubType,
        schedule,
        controls,
        reference_sensor_id: id,
        reference_sensor_type: type,
        reference_sensor_identifier: identifier
      } = autoFillValues;
      const {
        starting_time_window: startTimeWindow,
        ending_time_window: endTimeWindow,
      } = schedule || {};
      const key = autonomouseKeys?.[`${actionType}/${actionSubType}`];

      const {value: covertedRefillDepth } = getUserUnits(Number(controls?.refill_point_depth),
        'inches', 'depth', {});
      const {value: covertedStoppingDepth } = getUserUnits(Number(controls?.stopping_point_depth),
        'inches', 'depth', {});

      const updatedObj = {
        ...autoFillValues,
        block: block_id,
        action_type: key,
        ...({
          ...controls,
          refill_point_depth: Math.round(covertedRefillDepth),
          stopping_point_depth: Math.round(covertedStoppingDepth)
        }),
        ...({
          ...autoFillValues?.schedule,
          starting_time_window: moment(startTimeWindow),
          ending_time_window: moment(endTimeWindow),
        })
      };
      setUpdatedInitialValues(updatedObj);
      setSelectedBlock(block_id);
      setSelectedSensor({
        reference_sensor_id: id,
        reference_sensor_type: type,
        reference_sensor_identifier: identifier
      });
      setAutonomousMethod(key);
      form.setFieldsValue({ action_type: key });
      form.setFieldsValue({ sensor: { id, type, identifier} });
      form.setFieldsValue(updatedObj);
    } else {
      form.setFieldsValue({ starting_time_window: now });
      form.setFieldsValue({ ending_time_window: endDate });
      form.setFieldsValue({ action_type: autonomousMethod });
      form.setFieldsValue({ extended_time: 0 });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFillValues, form]);
};

// Below funtion is to handle the Form submit of create and edit flow
export const useHandlers = (updatedInitialValues: any, isEdit: any,
  onUpdate: any) => {
  const getUserUnits = hooks.useUnits();
  const depthFormat = useSelector(selectUserDepthFormat);

  const onFinish = (fieldValues: any) => {
    const {
      action_type, block, sensor, schedule,
      refill_point, refill_point_depth, stopping_point, stopping_point_depth,
      extended_time, starting_temperature, stopping_temperature,
    } = fieldValues;

    const sensorControl = {
      reference_sensor_id: sensor?.id,
      reference_sensor_identifier: sensor?.identifier,
      reference_sensor_type: sensor?.type
    };

    // Convert the depth values always in "Inches" before sending to backend API
    const {value: covertedRefillPoint } = getUserUnits(refill_point_depth, depthFormat, undefined, {}, 'inches');
    const {value: covertedStoppingPoint } = getUserUnits(stopping_point_depth, depthFormat, undefined, {}, 'inches');

    const irrigationControls = {
      refill_point: Number(refill_point),
      refill_point_depth: Math.round(covertedRefillPoint),
      stopping_point: Number(stopping_point),
      stopping_point_depth: Math.round(covertedStoppingPoint),
      extended_time: Number(extended_time),
      extended_time_units: updatedInitialValues?.controls?.extended_time_units || 'min',
    };

    const coolingControls = {
      starting_temperature: Number(starting_temperature),
      stopping_temperature: Number(stopping_temperature),
      temperature_units: updatedInitialValues?.controls?.temperature_units || TEMPERATURE_LABEL,
    };

    const finalValue = {
      block_id: block,
      action_type: autonomousTypes?.[action_type]?.actionType,
      action_subtype: autonomousTypes?.[action_type]?.subType,
      ...sensorControl,
      controls: action_type !== 'cooling' ? irrigationControls : coolingControls,
      schedule: {
        ...(schedule?.is_recurring ? schedule : {
          is_recurring: false,
          ...(schedule ? { id: schedule?.id } : {}),
          ...schedule,
        }),
        ...(!schedule && { is_recurring: false }),
        starting_time_window: moment(fieldValues?.starting_time_window).toISOString(),
        ending_time_window: moment(fieldValues?.ending_time_window).toISOString(),
      },
    };

    if (onUpdate && !isEdit) onUpdate({ create: finalValue });
    if (onUpdate && isEdit) onUpdate({ edit: finalValue });
  };

  return { onFinish };
};

// Below hook will update the autonomous type state based on the block irrigation control settings
export const useSetActionType = (allConfig: any, selectedBlock: any, form: any,
  setAutonomousMethod: any) => {
  // Filter the config of the selected block
  const filteredConfig = allConfig?.filter((d: any) => d?.block_id === selectedBlock)
    ?.reduce((a: any, o: any) => {
      const obj = {...a};
      obj[o.block_id]= o.configs;
      return obj;
    }, {});

  // To make the autonomous select component read-only
  const selectedConfig = filteredConfig[selectedBlock];
  const isDripIrrigation = selectedConfig?.irrigation?.vwc_irrigation;
  const isSprinklerCooling = selectedConfig?.cooling?.vwc_cooling;
  const isSprinklerIrrigation = selectedConfig?.sprinkler?.vwc_irrigation;

  useEffect(() => {
    if (isDripIrrigation) {
      setAutonomousMethod('irrigation');
      form.setFieldsValue({ action_type: 'irrigation' });
    }
    if (isSprinklerIrrigation) {
      setAutonomousMethod('sprinkler');
      form.setFieldsValue({ action_type: 'sprinkler' });
    }
    if (isSprinklerCooling) {
      setAutonomousMethod('cooling');
      form.setFieldsValue({ action_type: 'cooling' });
    }
  }, [form, isDripIrrigation, isSprinklerCooling, isSprinklerIrrigation, setAutonomousMethod]);

  return { isDripIrrigation, isSprinklerCooling, isSprinklerIrrigation };
};

export const convertToWeekdayObject = (recurringWeekdays: string, currentWeekday: number) => {
  // Get the current weekday as a 0-based index (Sunday = 0)
  const currentWeekdayIndex = currentWeekday % 7; // Adjust for ISO (Mon = 1)

  // Create an object with all days set to false
  const result = Object.fromEntries(allWeekdays.map((day) => [day, false]));

  if (recurringWeekdays?.length) {
    // Split the input string and update the corresponding keys to true
    recurringWeekdays.split(',').forEach((day) => {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(day)) {
        result[day] = true;
      }
    });
  } else {
    // Set the current weekday to true
    result[allWeekdays[currentWeekdayIndex]] = true;
  }

  return result;
};

export const getRepeatWeekDaysString = (repeatWeekdays: any) => Object.keys(repeatWeekdays)
  .filter((day) => repeatWeekdays[day])
  .join(',');

export const preparethresholdFormObj = (data: any, getUserUnits: any) => {
  console.log('data', data);
  const [sensor] = data?.filter((d: any) => d?.action_type === 'irrigation'
    && ['pixl_soil', 'aquacheck_soil'].includes(d?.main_threshold?.smp_reference_sensor_type));
  const [sprinklerPressureSensor] = data?.filter((d: any) => d?.action_type === 'sprinkler'
    && d?.main_threshold?.pressure_reference_sensor_type === 'water_pressure');

  console.log('sensors', sensor, sprinklerPressureSensor);

  const {
    pressure_low_threshold,
    pressure_high_threshold,
    smp_reference_sensor_id,
    smp_reference_sensor_type,
    pressure_reference_sensor_id,
    pressure_reference_sensor_type,
    smp_reference_sensor_identifier,
    pressure_reference_sensor_identifier,
    depth,
  } = sensor?.main_threshold || {};

  const {value: covertedDepth } = getUserUnits(Number(depth || 0),
    'inches', 'depth', {});

  const {
    pressure_low_threshold: sprinklerPressureLow,
    pressure_high_threshold: sprinklerPressureHigh,
    pressure_reference_sensor_id: sprinklerPressureId,
    pressure_reference_sensor_type: sprinklerPressureType,
    pressure_reference_sensor_identifier: sprinklerPressureIdentifier,
  } = sprinklerPressureSensor?.main_threshold || {};

  const irrigation = {
    reference_sensor_type: smp_reference_sensor_type,
    reference_sensor_id: smp_reference_sensor_id,
    reference_sensor_identifier: smp_reference_sensor_identifier,
  };

  const irrigationPressure = {
    reference_sensor_type: pressure_reference_sensor_type,
    reference_sensor_id: pressure_reference_sensor_id,
    reference_sensor_identifier: pressure_reference_sensor_identifier,
  };

  const sprinklerPressure = {
    reference_sensor_type: sprinklerPressureType,
    reference_sensor_id: sprinklerPressureId,
    reference_sensor_identifier: sprinklerPressureIdentifier,
  };

  const formObj = {
    irrigation,
    depth: Math.round(covertedDepth),
    irrigationPressure,
    irrigation_pressure_high: pressure_high_threshold,
    irrigation_pressure_low: pressure_low_threshold,
    sprinklerPressure,
    sprinkler_pressure_high: sprinklerPressureHigh,
    sprinkler_pressure_low: sprinklerPressureLow,
  };

  return formObj;
};

export const getThresholdConfig = (mainThresholds: any, ranchId: any, actionType: string) => {
  const thresholdConfigs = mainThresholds.length?mainThresholds
    .filter((d: any) => (d?.action_type === actionType
    && d?.ranch === ranchId)):undefined;
  const [thresholdConfig] = thresholdConfigs || [undefined];

  return {config: thresholdConfig, isEdit: !!(ranchId && thresholdConfigs?.length) };
};

function getThresholdAPIHandler(request: any, isEdit: any, messageStrObj: any,
  setLoading: any, t: any) {
  if (isEdit) {
    if (setLoading) setLoading(true);
    updateThresholdConfig(request, (success: boolean, response: any) => {
      if (success) {
        notification.success({
          message: t(`Settings ${messageStrObj?.success} successfully`),
          maxCount: 1
        });
      } else {
        notification.error({
          message: t(`Failed to ${messageStrObj?.error} settings`),
          maxCount: 1
        });
      }
      if (setLoading) setLoading(false);
    });
  } else {
    if (setLoading) setLoading(true);
    createThresholdConfig(request, (success: boolean, response: any) => {
      if (success) {
        notification.success({
          message: t(`Settings ${messageStrObj?.success} successfully`),
          maxCount: 1
        });
      } else {
        notification.error({
          message: t(`Failed to ${messageStrObj?.error} settings`),
          maxCount: 1
        });
      }
      if (setLoading) setLoading(false);
    });
  }
}

function getNoteficationMessageStr(isEdit: any) {
  return {
    success: isEdit ? 'updated' : 'created',
    error: isEdit ? 'update' : 'create'
  };
}

export const useThresholdHandlers = (mainThresholds: any, ranchId: any, t: any) => {
  const getUserUnits = hooks.useUnits();
  const depthFormat = useSelector(selectUserDepthFormat);

  const onFinishIrrigation = (fieldValues: any, isEdit?: boolean, setLoading?: any) => {
    const {
      irrigation, irrigationPressure, irrigation_pressure_high,
      irrigation_pressure_low, depth
    } = fieldValues;

    const actionType = 'irrigation';
    const { config } = getThresholdConfig(mainThresholds, ranchId, actionType);
    const { id } = config || {};

    const {value: covertedDepth } = getUserUnits(Number(depth),
      depthFormat, undefined, {}, 'inches');

    const finalObj = {
      ...(isEdit ? { id } : {}),
      action_type: actionType,
      action_subtype: 'pressure_irrigation',
      main_threshold: {
        depth: Math.round(covertedDepth),
        pressure_low_threshold: Number(irrigation_pressure_low),
        pressure_high_threshold: Number(irrigation_pressure_high),
        smp_reference_sensor_id: irrigation?.reference_sensor_id,
        smp_reference_sensor_type: irrigation?.reference_sensor_type,
        pressure_reference_sensor_id: irrigationPressure?.reference_sensor_id,
        pressure_reference_sensor_type: irrigationPressure?.reference_sensor_type,
        smp_reference_sensor_identifier: irrigation?.reference_sensor_identifier,
        pressure_reference_sensor_identifier: irrigationPressure?.reference_sensor_identifier
      },
      ranch: ranchId,
    };
    getThresholdAPIHandler(finalObj, isEdit, getNoteficationMessageStr(isEdit), setLoading, t);
  };

  const onFinishSprinker = (fieldValues: any, isEdit?: boolean, setLoading?: any) => {
    const {
      sprinklerPressure, sprinkler_pressure_high,
      sprinkler_pressure_low
    } = fieldValues;

    const actionType = 'sprinkler';
    const { config } = getThresholdConfig(mainThresholds, ranchId, actionType);
    const { id } = config || {};

    const finalObj = {
      ...(isEdit ? { id } : {}),
      action_type: actionType,
      action_subtype: 'pressure_sprinkler',
      main_threshold: {
        pressure_low_threshold: Number(sprinkler_pressure_low),
        pressure_high_threshold: Number(sprinkler_pressure_high),
        pressure_reference_sensor_id: sprinklerPressure?.reference_sensor_id,
        pressure_reference_sensor_type: sprinklerPressure?.reference_sensor_type,
        pressure_reference_sensor_identifier: sprinklerPressure?.reference_sensor_identifier
      },
      ranch: ranchId
    };
    getThresholdAPIHandler(finalObj, isEdit, getNoteficationMessageStr(isEdit), setLoading, t);
  };

  return { onFinishIrrigation, onFinishSprinker };
};
