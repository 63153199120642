import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

export const TabsContent = ({ activeKey, renderContent, onChange }) => (
  <div className="tab-content">
    <Tabs
      activeKey={activeKey}
      items={renderContent}
      onChange={onChange}
    />
  </div>
);

TabsContent.propTypes = {
  activeKey: PropTypes.string.isRequired,
  renderContent: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

TabsContent.defaultProps = {
  renderContent: {},
};
