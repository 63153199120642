export enum ChartType {
  CROP_STRESS = 'crop_stress',
}

export type MinMax = {
  min: number,
  max: number
};

type YaxisMinMax = {
  [ChartType.CROP_STRESS]: MinMax
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UpdateYminmax = (type: any, min: number, max: number)=>void;

type Sensor = {
  name?: string,
  id: number,
  type: string,
  identifier: string,
};

export type ChartProps = {
  sensor : Sensor,
  startDate : object,
  endDate : object,
  cached : string,
  updateYminmax : UpdateYminmax,
  uniformYAxis : boolean,
  yMinMaxMap? : YaxisMinMax,
};

export type SensorSelectProps = {
  sensors: Sensor[],
  onChange : any,
  onClear: any,
  onClickCompare: any,
  values: number[ ]
};
