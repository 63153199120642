import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { authService } from "farmx-api";
import { useTranslation } from "react-i18next";
import { MailOutlined, CheckCircleFilled } from "@ant-design/icons";
import FarmXLogo from "../../assets/images/farmx_logo_white.png";
import "./ResetPasswordPage.less";  

const ResetPasswordPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed,setIsFailed] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  async function onFinish(values) {
    const { emailAddress } = values;
    setEmail(emailAddress);
    const success = await authService.validateUserToResetPassword(emailAddress);
    if(!success){
      setIsFailed(true);
    }
    setIsSuccess(success);
  }

  return (
    <div className="container">
      <div className="logo">
        <img className="header-logo" alt="FarmX" src={FarmXLogo} />
      </div>
      <div className={`pass-container ${isSuccess ? "is-flipped" : ""}`}>
        <div className="inner">
          <Form className={`res-pass-form `} onFinish={onFinish}>
            <Typography className="login-message">
              <Typography.Title>{t("Reset Password")}</Typography.Title>
              <Typography.Paragraph>
                {t(
                  "Forgotten your password? Enter your email address below, and we'll send you an email allowing you to reset it"
                )}
              </Typography.Paragraph>
            </Typography>

            <Form.Item
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: t("Please input Email address"),
                  min: 1,
                },
                {
                  type: "email",
                  message: t("The input is not a valid Email address"),
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                placeholder={t("Email address")}
                autoComplete="off"
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="res-pass-button"
                data-test-id="reset-submit"
              >
                {t("Reset my password")}
              </Button>
            </Form.Item>

            <Typography.Text
              type="danger"
              style={{ textAlign: "center" }}
            >
              { isFailed && t("Reset password failed") }
            </Typography.Text>

            <Typography >
              <Typography.Paragraph>
                {t(
                  `If you have any trouble resetting your password, contact us at `
                )}
                <span className="blue"><b>admin@farmx.co</b></span>.
              </Typography.Paragraph>
            </Typography>
          </Form>
          <div className="flip-card-back">
            <CheckCircleFilled style={{ fontSize: "2.5rem", color: "green" }} />
            <Typography.Title>{t("Password link sent")}</Typography.Title>
            <Typography.Paragraph style={{ fontSize: "1.5rem" }}>
              {t(`Please check your inbox ${email}`)}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
