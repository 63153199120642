import React from 'react';
import PropTypes from 'prop-types';
import { colorCritical, colorWarning, colorOver } from '../../../utils/colors';
import { VWCGraph } from './VWCGraph';
import './home.css';

/**
 * @param {*} props - data, fieldCapacity, onSelect, wiltingPoint, refillPoint, editMode, chartType
 * @returns The HighCharts scatter OR line chart with different color points for scatter
 * and defaulr color for line chart
 * Description:
 * data: [{ x: "2022-01-01", y: 0.7 }, { x: "2022-01-02", y: 0.3 }]
 * onSelect: callback function to return value when click on scatter point
 * onChange: callback function to return the plotline onchange value as object
 * fieldCapacity, wiltingPoint, refillPoint: 0-1 range. Ex: 0.2, 0.4, 0.6
 * editMode: array of strings/null to enable the editable feature
 * chartType: 'scatter' OR 'line'
 */
export function VWCMultilineGraph(props) {
  const {
    data, fieldCapacity, onSelect, wiltingPoint, refillPoint, editMode: actualEditMode,
    onChange, chartType, loading, options, optionalId, extraChartOptions, showPlotLineValues,
  } = props;
  const editMode = actualEditMode.map((eMode) => `${eMode}${optionalId}`);

  // To plot 3 colored lines always
  const cutOfPoints = [{ id: `wiltingPoint${optionalId}`, color: colorCritical, value: wiltingPoint },
    { id: `fieldCapacity${optionalId}`, color: colorOver, value: fieldCapacity },
    { id: `refillPoint${optionalId}`, color: colorWarning, value: refillPoint }];
  const draggableIconClass = 'vwc-chart-icon';

  /**
   * It generates 3 plotLine config as a default by using cutOfPoints array
   * @returns the array of plotLine config as object
   */
  function getMultiplePlotLines() {
    const plotLine = cutOfPoints.map((d) => ({
      id: d.id,
      color: d.color,
      width: 2,
      value: d.value * 100,
      zIndex: 100,
      dashStyle: 'dash',
      label: {
        align: 'right',
        y: -12,
        x: 7,
        useHTML: editMode.includes(d.id),
        text: editMode.includes(d.id) ? `<svg id="draggableIcon">
          <g id="node${optionalId}" class=${draggableIconClass}>
          <path style=" stroke:none;fill-rule:nonzero;
          fill:${d.color};fill-opacity:1;" 
          d="M 9.179688 49.894531 L 9.179688 0.09375 L 41.976562 0.09375 L 41.976562 
          49.894531 Z M 9.179688 49.894531 "/>
          <path style=" stroke:none;fill-rule:evenodd;
          fill:rgb(100%,100%,100%);fill-opacity:1;" 
          d="M 33.53125 20.722656 L 17.359375 20.722656 L 25.445312 6.636719 Z M 
          33.53125 20.722656 "/>
          <path style=" stroke:none;fill-rule:evenodd;
          fill:rgb(100%,100%,100%);fill-opacity:1;" 
          d="M 33.433594 29.132812 L 17.722656 29.132812 L 25.578125 42.683594 Z M 
          33.433594 29.132812 "/>
          <path style=" stroke:none;fill-rule:nonzero;
          fill:rgb(100%,100%,100%);fill-opacity:1;" 
          d="M 15.355469 25.492188 L 35.800781 25.492188 L 35.800781 24.507812 L 
          15.355469 24.507812 Z M 15.355469 25.492188 "/>
          </g>
          </svg>` : '',
      },
      onDragStart() {
        return null;
      },
      onDragChange() {
        return null;
      },
      onDragFinish(newValue) {
        if (editMode.includes(d.id)) onChange({ id: d.id, value: newValue });
      },
    }));
    return showPlotLineValues ? [
      ...plotLine,
      /**
       * Since plotlines don't support multiple labels therefore we are
       * duplicating those plotlines to add multiple labels support.
       */
      ...plotLine.map((pl) => ({
        value: pl.value,
        color: pl.color,
        width: pl.width,
        dashStyle: pl.dashStyle,
        zIndex: 100,
        label: {
          align: 'right',
          text: Number(pl.value).toFixed(2),
          x: -40,
        },
      })),
    ] : plotLine;
  }

  /**
   * @param {*} axis - chart yAxis
   * @param {*} plotLineId - unique id that same as plotLine id
   */
  function draggablePlotLine(axis, plotLineId) {
    let clickY;
    const getPlotLine = () => {
      for (let i = 0; i < axis.plotLinesAndBands.length; i += 1) {
        if (axis.plotLinesAndBands[i].id === plotLineId) {
          return axis.plotLinesAndBands[i];
        }
      }
      return null;
    };

    const getValue = () => {
      const plotLine = getPlotLine();
      const translation = plotLine.svgElem.translateY;
      let newValue = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value;
      newValue = Math.max(axis.min, Math.min(axis.max, newValue));
      return newValue;
    };

    // To translate the icon along with the line
    const draggableIcon = (translateValue) => {
      const svgIcon = document.getElementById(`node${optionalId}`);
      if (svgIcon) {
        svgIcon.setAttribute('transform', `translate(0 ${translateValue})`);
      }
    };

    const dragStep = (e) => {
      const plotLine = getPlotLine();
      let newTranslation = e.pageY
        ? e.pageY - clickY
        : e.changedTouches['0'].pageY - clickY;
      let newValue = axis.toValue(newTranslation)
        - axis.toValue(0)
        + plotLine.options.value;
      newValue = Math.max(axis.min, Math.min(axis.max, newValue));
      newTranslation = axis.toPixels(
        newValue + axis.toValue(0) - plotLine.options.value,
      );
      plotLine.svgElem.translate(0, newTranslation);

      if (getPlotLine().label) {
        plotLine.label.translate(0, newTranslation);
      }

      if (plotLine.options.onDragChange) {
        plotLine.options.onDragChange(newValue);
      }

      // To fix the draggable icon movement issue
      draggableIcon(newTranslation);
    };

    let dragStop = null;
    const dragStart = (e) => {
      document.addEventListener('mousemove', dragStep, false);
      document.addEventListener('mouseup', dragStop, false);
      document.addEventListener('touchstart', dragStep, false);
      document.addEventListener('touchmove', dragStep, false);
      document.addEventListener('touchend', dragStop, false);

      const plotLine = getPlotLine();
      clickY = (e.pageY ? e.pageY : e.changedTouches['0'].pageY)
        - plotLine.svgElem.translateY;
      if (plotLine.options.onDragStart) {
        plotLine.options.onDragStart(getValue());
      }
    };

    dragStop = () => {
      document.removeEventListener('mousemove', dragStep, false);
      document.removeEventListener('mouseup', dragStop, false);
      document.removeEventListener('touchstart', dragStep, false);
      document.removeEventListener('touchmove', dragStep, false);
      document.removeEventListener('touchend', dragStop, false);
      const plotLine = getPlotLine();

      if (plotLine) {
        const plotLineOptions = plotLine.options;
        // Remove + Re-insert plot line
        // Otherwise it gets messed up when chart is resized
        if (Object.prototype.hasOwnProperty.call(plotLine.svgElem, 'translateX')) {
          plotLineOptions.value = getValue();
          axis.removePlotLine(plotLineOptions.id);
          axis.addPlotLine(plotLineOptions);

          if (plotLineOptions.onDragFinish) {
            plotLineOptions.onDragFinish(plotLineOptions.value);
          }
        }

        getPlotLine()
          .svgElem.css({ cursor: 'pointer' })
          .translate(0, 0)
          .on('touchstart', dragStart)
          .on('mousedown', dragStart);

        if (getPlotLine().label) {
          getPlotLine()
            .label.css({ cursor: 'pointer' })
            .translate(0, 0)
            .on('touchstart', dragStart)
            .on('mousedown', dragStart);
        }
      }
    };
    dragStop();
  }

  return (
    <VWCGraph
      data={data}
      fieldCapacity={fieldCapacity}
      wiltingPoint={wiltingPoint}
      refillPoint={refillPoint}
      editMode={editMode}
      onSelect={onSelect}
      multiplePlotline={getMultiplePlotLines()}
      draggablePlotLine={draggablePlotLine}
      chartType={chartType}
      loading={loading}
      options={options}
      extraChartOptions={extraChartOptions}
    />
  );
}

VWCMultilineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  fieldCapacity: PropTypes.number,
  wiltingPoint: PropTypes.number,
  refillPoint: PropTypes.number,
  editMode: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  chartType: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.shape({
    yAxis: PropTypes.object,
    chart: PropTypes.object,
  }),
  optionalId: PropTypes.string,
  extraChartOptions: PropTypes.shape({}),
  showPlotLineValues: PropTypes.bool,
};

VWCMultilineGraph.defaultProps = {
  data: [],
  onSelect: null,
  fieldCapacity: 0,
  wiltingPoint: 0,
  refillPoint: 0,
  editMode: null,
  onChange: null,
  chartType: 'scatter',
  loading: false,
  options: {
    yAxis: {},
    chart: {},
  },
  optionalId: '',
  extraChartOptions: {},
  showPlotLineValues: false,
};
