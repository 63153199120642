import React from 'react';
import { Form, Input, Button, Card, Breadcrumb, Skeleton, Select } from 'antd';
import { PageHeader } from 'components/mobile/components/PageHeader';
import { useTranslation } from 'react-i18next';

interface InviteUserProps {
  onInviteUser: (username: string, role: string, selectedTeams: string[]) => void;  
  team: {name: string, id: string}
  teamId: string | undefined;
  loading: boolean; 
  teamsToSelect: [];
}

/**
 * InviteUser is a functional component used to display a form for inviting a user.
 * @param {Function} props.onInviteUser - The method to handle inviting a user.
 * @param {{name: string, id: string, selectedValues: string[]}} props.team - The team to invite the user to.
 * @param {string | undefined} props.teamId - The id of the team to invite the user to.
 * @param {boolean} props.loading - The loading status of the component.
 * @param {[]} props.teamsToSelect - The list of teams to select from.
 * @returns {JSX.Element} The InviteUser component.
 */
export const InviteUser: React.FC<InviteUserProps> = ({
  onInviteUser,
  team,
  teamId,
  loading,
  teamsToSelect
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  /**
   * Method to handle the form submission
   * @param {object} values - Form values
   */
  const onFinish = (values: { username: string, role: string, selectTeam: string[] }) => {
    
    if(teamId != undefined)
      onInviteUser(values.username, values.role, [teamId]);
    else
      onInviteUser(values.username, values.role, values.selectTeam);
  };

  return (
    <div className="settings-notifications-container div-default white-bg padding-20">
      <PageHeader
        showBack={true}
        title={t('Invite User')}
        toPath={teamId == undefined ? "/organization/users" : "/organization/teams/" + teamId}
      />
      <br/>
      {loading ? <Skeleton active /> : 
      (
        <div>
          <Breadcrumb>
          <Breadcrumb.Item>
            {teamId == undefined ? 
              null : 
              <a href={"/organization/teams"}>Teams</a>
            }
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {teamId == undefined ? 
              <a href={"/organization/users"}>Users</a> : 
              <a href={"/organization/teams/" + teamId}>{team?.name}</a>
            }
          </Breadcrumb.Item>
          <Breadcrumb.Item>Invite User</Breadcrumb.Item>
        </Breadcrumb>
          <Card title="Invite User" bordered={false}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ }}
          >
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: 'Please input a role!' }]}
              >
                <Select>
                  <Select.Option value="Team Manager">Team Manager</Select.Option>
                  <Select.Option value="Team Member">Team Member</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Select Team"
                name="selectTeam"
                rules={[{ required: teamId == undefined ? true : false, message: 'Please select at least one option!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Team"
                  allowClear
                  disabled={teamId != undefined ? true : false}
                  defaultValue={teamId != undefined ? [team?.id] : []}
                >
                  {teamsToSelect?.map((team: any) => (
                    <Select.Option key={team.id} value={team.id}>
                      {team.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Email"
                name="username"
                rules={[
                  { 
                    required: true, 
                    message: 'Please input your email!' 
                  },
                  {
                    type: 'string',
                    message: 'Please enter a username or email!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            <Button type="primary" htmlType="submit">
              Invite User
            </Button>
          </Form>
        </Card>
      </div>
      )}
    </div>
  );
};
