/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FeatureGroup, TileLayer } from 'react-leaflet';
import isEqual from 'react-fast-compare';
import { selectors } from 'farmx-redux-core';
import { Button } from 'antd';
import { FaLayerGroup } from 'react-icons/fa';
import MapControlButtons from './MapControlButtons';
import AnomalyControls from './Anomaly/AnomalyControls';
import AnomalyButtons from './Anomaly/AnomalyButtons';
import { isMobile } from '../../../utils/detectDevice';
import { renderSoilLayer, removeSoilLayer, summaryButton } from '../../../helper/mapHelper';
import ColorBar from './ColorMap';

const {
  selectMapShowSoilType,
  selectMapShowTemperature,
  selectMapShowWind,
} = selectors;

export default function MapFeatureGroup(props) {
  const {
    dispatchMapArg, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId, isBottomSheetVisible,
    isImageryPanelVisible, anomalyGeoJSON, createAnomaly, setCreateAnomaly,
    selectedFeature, setModal, sideBySideMapVisible, mapFeatureGroupRef, mapFeatureGroupRef1,
    isLeft, setLayerOption, setMapSettingModal, controlButtons, selectedKey, activeScreen,
    stateMap, stateMap1,
  } = props;
  const colorMapSide = useRef({ left: null, right: null });
  const colorMapSide1 = useRef({ left: null, right: null });
  const isPlants = presMode === 'plants';
  const isAnomaly = presMode === 'anomaly';
  const isDrone = stateMap?.selectedSatelliteDataSource === 'drone';
  const isDroneRight = stateMap1?.selectedSatelliteDataSource === 'drone';
  const anomalyClassName = isAnomaly && 'desktop-controls-right-buttons-for-anomaly';
  const droneKey = stateMap?.selectedDroneDataType;
  const droneKeyRight = stateMap1?.selectedDroneDataType;
  const satelliteKey = stateMapArg?.selectedSatelliteDataType;
  const satelliteKeyRight = stateMap1?.selectedSatelliteDataType;
  const isNotPlantHealthClassification = selectedKey !== 'count';
  const CONDITION_CHECK_1 = isNotPlantHealthClassification && 'not-plant-health-classification';
  const CONDITION_CHECK_2 = isNotPlantHealthClassification
  && 'not-plant-health-classification-left-side';
  const CONDITION_CHECK_3 = isNotPlantHealthClassification && 'not-color-bar-right-side-position';
  const isBlockPlantView = stateMap?.plantsView === 'block';

  let side;
  if (sideBySideMapVisible) {
    if (isLeft) side = 'left';
    else side = 'right';
  }

  // Update keys text on split-screen for drone imagery
  useEffect(() => {
    if (sideBySideMapVisible) {
      if (isLeft && isDrone) colorMapSide.current.left = droneKey;
      if (!isLeft && isDroneRight) colorMapSide.current.right = droneKeyRight;
    } else colorMapSide.current = { left: null, right: null };
  }, [isLeft, isDrone, sideBySideMapVisible, droneKey, droneKeyRight, isDroneRight]);

  // Update keys text on split-screen for satellite imagery
  useEffect(() => {
    if (sideBySideMapVisible) {
      if (isLeft && !isDrone) colorMapSide1.current.left = satelliteKey;
      if (!isLeft && !isDroneRight) colorMapSide1.current.right = satelliteKeyRight;
    } else colorMapSide1.current = { left: null, right: null };
  }, [isLeft, isDrone, sideBySideMapVisible, satelliteKey, satelliteKeyRight, isDroneRight]);

  // Render right side color key when user on split-screen
  // and switching different presentational mode
  useEffect(() => {
    if (!isAnomaly && sideBySideMapVisible) {
      colorMapSide1.current.right = satelliteKeyRight;
      colorMapSide1.current.right = droneKeyRight;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnomaly, sideBySideMapVisible]);

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  const selMapShowTemp = useSelector(
    (state) => selectMapShowTemperature(state), isEqual,
  );

  const selMapShowWind = useSelector(
    (state) => selectMapShowWind(state), isEqual,
  );

  const isOverlays = ((isPlants && sideBySideMapVisible) && (selMapShowTemp || selMapShowWind));

  const layerOptionButton = () => (
    <Button
      size="large"
      type="primary"
      title="Layers"
      icon={<FaLayerGroup />}
      style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white', border: 'none' }}
      onClick={() => {
        setLayerOption(true);
        dispatchMapArg({
          type: 'setIsBottomSheetVisible',
          payload: true,
        });
        if (controlButtons) {
          dispatchMapArg({
            type: 'setSelectedFeatureWithType',
            payload: {
              showBottomSheet: controlButtons.isBottomSheetVisible,
            },
          });
        }
        // Retrieve imagery panel when a feature is selected
        if (selectedFeature) {
          dispatchMapArg({
            type: 'setIsBottomSheetVisible',
            payload: true,
          });
        }
      }}
    />
  );

  function renderFeatureGroupContents() {
    return !isMobile && (
      <div className="controls-box">
        <div className={isPlants ? 'controls-right desktop-controls-right-buttons-for-plants'
          : `controls-right desktop-controls-right-buttons ${anomalyClassName}`}
        >
          {!isBottomSheetVisible
           && summaryButton(dispatchMapArg, selectedObjFromState?.type)}
          {!isAnomalyControlEnabled && !isBlockPlantView && layerOptionButton()}
          {!isAnomalyControlEnabled && (
          <MapControlButtons
            isMobile={isMobile}
            isImageryPanelVisible={stateMapArg.isImageryPanelVisible}
            selectedObjFromState={selectedObjFromState}
            ranchId={ranchId}
            blockId={blockId}
            dispatchMap={dispatchMapArg}
            isBottomSheetVisible={isBottomSheetVisible}
            selectedImageryData={stateMapArg.selectedImageryData}
            anomalyGeoJSON={anomalyGeoJSON}
            presMode={presMode}
            isPlants={isPlants}
            setLayerOption={setLayerOption}
            layerControl={stateMapArg.layerControl}
            sideBySideMapVisible={sideBySideMapVisible}
            activeScreen={activeScreen}
            isBlockPlantView={isBlockPlantView}
          />
          )}
          <AnomalyControls
            presMode={presMode}
            createAnomaly={createAnomaly}
            setCreateAnomaly={setCreateAnomaly}
            selectedFeature={selectedFeature}
            setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
          />
          {(((isPlants && selectedKey) || (sideBySideMapVisible && isPlants)))
          && (
            <div className={isPlants && `color-bar-left-side plant-color-bar-option
              ${isOverlays && 'overlay-left'}`}
            >
              <ColorBar
                colorKey={selectedKey}
                displayControl={selMapShowTemp || selMapShowWind}
                imageryData={{
                  left: stateMap.selectedDroneImagery,
                  right: stateMap1.selectedDroneImagery
                }}
              />
            </div>
          )}

          {!sideBySideMapVisible && stateMapArg.isImageryPanelVisible
          && (
          <div className={!isPlants ? 'color-bar-left-side plants-options-drone'
            : `fullscreen-color-bar-position ${CONDITION_CHECK_1}`}
          >
            <ColorBar
              droneKey={droneKey}
              satelliteKey={satelliteKey}
              side={side}
              displayControl={selMapShowTemp || selMapShowWind}
              dateRangeWithDisplayData={stateMap.dateRangeWithDisplayData}
              isDrone={isDrone}
              imageryData={{
                left: stateMap.selectedDroneImagery,
                right: stateMap1.selectedDroneImagery
              }}
              isAnomaly={isAnomaly}
            />
          </div>
          )}

          {sideBySideMapVisible && (
          <div className={!isPlants && 'not-plants-options'}>
            <div className={`color-bar-left-side color-bar-left-side-position
              ${isOverlays && 'overlay-left'} ${(isPlants && CONDITION_CHECK_2)}`}
            >
              {((((colorMapSide.current.left || colorMapSide1.current.left || isAnomaly) || isLeft)
               || !isLeft)
              && (
              <ColorBar
                droneKey={colorMapSide.current.left || droneKey}
                satelliteKey={colorMapSide1.current.left || satelliteKey}
                side="left"
                displayControl={selMapShowTemp || selMapShowWind}
                dateRangeWithDisplayData={stateMap.dateRangeWithDisplayData}
                isDrone={isDrone}
                imageryData={{
                  left: stateMap.selectedDroneImagery,
                  right: stateMap1.selectedDroneImagery
                }}
                isAnomaly={isAnomaly}
              />
              ))}
            </div>
            <div className={`color-bar-right-side color-bar-right-side-position 
              ${isOverlays && 'overlay-left'} ${(isPlants && CONDITION_CHECK_3)}`}
            >
              {(((colorMapSide?.current?.right || satelliteKeyRight || isAnomaly))
              &&(
              <ColorBar
                droneKey={colorMapSide.current.right || droneKeyRight}
                satelliteKey={satelliteKeyRight}
                side="right"
                displayControl={selMapShowTemp || selMapShowWind}
                isSplitScreen={stateMapArg.sideBySideMapVisible}
                dateRangeWithDisplayData={stateMap1.dateRangeWithDisplayData}
                isDrone={isDroneRight}
                imageryData={{
                  left: stateMap.selectedDroneImagery,
                  right: stateMap1.selectedDroneImagery
                }}
                isAnomaly={isAnomaly}
              />
              ))}
            </div>
          </div>
          )}
        </div>
        {!isMobile && (
        <AnomalyButtons
          presMode={presMode}
          createAnomaly={createAnomaly}
          setCreateAnomaly={setCreateAnomaly}
          stateMapArg={stateMapArg}
          setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
          isImageryPanelVisible={isImageryPanelVisible}
          isBottomSheetVisible={isBottomSheetVisible}
          setModal={setModal}
          sideBySideMapVisible={sideBySideMapVisible}
        />
        )}
      </div>
    );
  }

  if (!selMapShowSoilType && !isAnomaly
    && mapFeatureGroupRef?.current?.style?._loaded) {
    removeSoilLayer(mapFeatureGroupRef);
  }

  if (!selMapShowSoilType && !isAnomaly
    && mapFeatureGroupRef1?.current?.style?._loaded) {
    removeSoilLayer(mapFeatureGroupRef1);
  }

  return !isAnomaly
    ? (
      <div>
        {renderFeatureGroupContents(dispatchMapArg, stateMapArg)}
      </div>
    ) : (
      <FeatureGroup
        ref={(r) => {
          if (r && isLeft && isAnomaly) {
            mapFeatureGroupRef.current = r;
          }
        }}
      >
        {selMapShowSoilType && !sideBySideMapVisible && (
        <TileLayer
          url="https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}"
          name="soil"
          maxZoom={19}
          minZoom={0}
          showOnSelector={false}
          zIndex={5}
        />
        )}
        {renderFeatureGroupContents()}
      </FeatureGroup>
    );
}

MapFeatureGroup.propTypes = {
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  setModal: PropTypes.func,
  isBottomSheetVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  selectedFeature: PropTypes.shape(),
  selectedObjFromState: PropTypes.shape(),
  dispatchMapArg: PropTypes.func,
  isAnomalyControlEnabled: PropTypes.bool,
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  anomalyGeoJSON: PropTypes.shape(),
  sideBySideMapVisible: PropTypes.bool,
  mapFeatureGroupRef: PropTypes.shape(),
  mapFeatureGroupRef1: PropTypes.shape(),
  isLeft: PropTypes.bool,
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
  controlButtons: PropTypes.shape(),
  selectedKey: PropTypes.string,
  activeScreen: PropTypes.func.isRequired,
  stateMap: PropTypes.shape(),
  stateMap1: PropTypes.shape(),
};

MapFeatureGroup.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  isBottomSheetVisible: false,
  isImageryPanelVisible: false,
  selectedFeature: null,
  dispatchMapArg: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  anomalyGeoJSON: null,
  mapFeatureGroupRef: {},
  mapFeatureGroupRef1: {},
  sideBySideMapVisible: false,
  isLeft: true,
  setLayerOption: null,
  setMapSettingModal: null,
  selectedKey: '',
  controlButtons: null,
  stateMap: {},
  stateMap1: {},
};
