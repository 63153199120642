import React from "react";
import { PageHeader } from '../../../mobile/components/PageHeader';
import { Breadcrumb, Button, Select, Skeleton, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { TeamDetailAdminForm } from "./components/TeamDetailAdminForm";
import { RanchSelectionForm } from "./components/RanchSelectionForm";
import { FarmxWebApiTypes } from "types/api_types";
import { InfoCircleFilled } from "@ant-design/icons";
import UserRoleSelect from "./components/UserRoleSelect";
import {isMobile } from 'react-device-detect';

export interface TeamDetailProps {
  loading: boolean;
  team: any;
  userTeamAccessSpec: FarmxWebApiTypes.TeamUserAccessListQuery;
  pagedResult: FarmxWebApiTypes.PaginatedResult<any>;
  onPageChange: (page: number, pageSize: number) => void;
  onInviteUser: (team: any) => void;
  onTeamUpdate: (team: any) => void;
  onTeamDelete: (team: any) => void;
  onUserAccessDelete: (userAccess: any) => void;
  onUserAccessUpdateRole: (userAccess: any, role: string) => Promise<boolean>;
  teamRanches: any[];
  entityRanches: any[];
  onUpdateRanchSelection: (selectedRanches: any[]) => Promise<boolean>;
  currentUserRoles: string[] | undefined;
}

/**
 * TeamDetail is a functional presentational component used to display the details of a Team.
 * @param {boolean} props.loading - The loading status of the component.
 * @param {any} props.team - The team to display - contains the high level team property data - eg, name.
 * @param {FarmxWebApiTypes.TeamUserAccessListQuery} props.userTeamAccessSpec - The spec for fetching the team user access data.
 * @param {FarmxWebApiTypes.PaginatedResult<any>} props.pagedResult - The paged result of the team user access query.
 * @param {(page: number, pageSize: number) => void} props.onPageChange - The method to handle the page change.
 * @param {(team: any) => void} props.onInviteUser - The method to handle initiating a user invite.
 * @param {(updateTeamFormData: {name: string, id: number}) => void} props.onTeamUpdate - The method to handle the team update.
 * @param {(team: {id: number}) => void} props.onTeamDelete - The method to handle the team deletion.
 * @param {(userAccess: any) => void} props.onUserAccessDelete - The method to handle the user access deletion.
 * @param {(userAccess: any, role: string) => Promise<boolean>} props.onUserAccessUpdateRole - The method to handle the user access role update.
 * @param {any[]} props.teamRanches - The ranches associated with the team.
 * @param {any[]} props.entityRanches - The ranches available to associate with the team.
 * @param {(selectedRanches: number[]) => Promise<boolean>} props.onUpdateRanchSelection - The method to handle the ranch selection update.
 * @param {string[] | undefined} props.currentUserRoles - The roles of the current user. This can be used to enable or disable certain fields
 * 
 * @returns {JSX.Element} The TeamDetail component. 
 */
const TeamDetail: React.FC<TeamDetailProps> = ({
  loading,
  team,
  userTeamAccessSpec,
  pagedResult,
  onPageChange,
  onInviteUser,
  onTeamUpdate,
  onTeamDelete,
  onUserAccessDelete,
  onUserAccessUpdateRole,
  teamRanches,
  entityRanches,
  onUpdateRanchSelection,
  currentUserRoles
}) => {
  const { t } = useTranslation();

  // define the columns for the team user access table
  const columns = [
    {
      title: 'User',
      key: 'user',
      dataIndex: ["user","username"],
      width: isMobile ? '25%' : '30%',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: ["role","name"],
      width: isMobile ? '50%' : '60%',
      // render a select dropdown for the role
      render : (_: any, record: { role: { id: number; name: string; }; }) => {
        return <UserRoleSelect record={record} onUserAccessUpdateRole={onUserAccessUpdateRole} disabled={!currentUserRoles?.includes('Entity Admin') && !currentUserRoles?.includes('Team Manager')} />
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: isMobile ? '25%' : '30%',
      render: (_: any, record: { id: number }) => (
        <Button 
          disabled={!currentUserRoles?.includes('Entity Admin') && !currentUserRoles?.includes('Team Manager')}
          type="link" onClick={() => onUserAccessDelete(record)}
        >
          {'Delete'}
        </Button>
      ),
    }
  ]

  return (
    <div className="white-bg padding-20 flex-column-container organization-table">
      <div className="flex-title-container">
        <div className="flex-title-content">
          <PageHeader
            showBack={true}
            title={(team?.name)}
            toPath="/organization/teams"
          />
          <Breadcrumb>
            <Breadcrumb.Item>
                <a href="/organization/teams">Teams</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href={"/organization/teams/" + team?.id}>{team?.name}</a>
              </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="flex-action-button-content">
          <Button 
            type="primary" 
            onClick={() => onInviteUser(team)}
            style={{backgroundColor: !currentUserRoles?.includes('Entity Admin') && !currentUserRoles?.includes('Team Manager') ? 'lightgrey' : ''}}
            disabled={!currentUserRoles?.includes('Entity Admin') && !currentUserRoles?.includes('Team Manager')}
          >
            {'+ Invite'}
          </Button>
        </div>
      </div>
      <div className='scrollable-content'>
        {loading ? <Skeleton active /> : (
          <div>
            <br/>
            <TeamDetailAdminForm team={team} 
              onTeamUpdate={onTeamUpdate} 
              onTeamDelete={onTeamDelete}
              disabled={!currentUserRoles?.includes('Entity Admin')}
            />
            <br/>
            <h2>{t('Ranches')}               
              <Tooltip title="Select the ranches associated with this team.">
                <InfoCircleFilled style={{color: 'lightgrey', paddingLeft: 10}} />
              </Tooltip>
            </h2>
            <RanchSelectionForm 
              selectedRanches={teamRanches} 
              availableRanches={entityRanches} 
              onRanchSelectionUpdate={onUpdateRanchSelection}   
              disabled={!currentUserRoles?.includes('Entity Admin')}
            />
            <br />
            <h2>{t('Users')}               
              <Tooltip title="Select the users with access to this team">
                <InfoCircleFilled style={{color: 'lightgrey', paddingLeft: 10}} />
              </Tooltip>
              </h2>
            <Table
              sticky
              dataSource={pagedResult?.data}
              columns={columns as []}
              rowKey="id"
              pagination={{
                current: userTeamAccessSpec?.page_number,
                pageSize: userTeamAccessSpec?.page_size,
                total: pagedResult?.count,
                onChange: onPageChange,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TeamDetail;