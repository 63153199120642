import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { FaFileExport } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { RanchBlockSelect, RanchBlockSelectMobile } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import './Schedule.less';
import InfiniteScroll from 'react-infinite-scroller';
import { CardLayout } from '../components/CardLayout';
import { useTracking } from '../../../helper/mixpanel';
import ReactContentLoader from '../components/ContentLoader';
import { PageHeader } from '../components/PageHeader';
import { RanchScheduleList } from './RanchScheduleList';
import { CreateScheduleButton } from './CreateScheduleButton';
import { isMobile } from '../../../utils/detectDevice';

const {
  setRanchBlockSelection,
} = actions;

const {
  selectNewBlocksLoadingState,
  selectRanchesForRanchBlockSelection,
  selectLoginUserInfo,
} = selectors;

const { useRanchBlockSelection } = hooks;

export function Schedule() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tracking = useTracking();
  const history = useHistory();
  const location = useLocation();

  const loadingBlockSchedule = useSelector(selectNewBlocksLoadingState);
  const { block, ranch, selectedObjFromState } = useRanchBlockSelection();
  const userInfo = useSelector(selectLoginUserInfo).payload;

  const selectedRanches = useSelector(
    (state) => selectRanchesForRanchBlockSelection(state, selectedObjFromState),
  );

  const [ranchArray, setRanchArray] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 8;
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (selectedRanches && selectedRanches.length && selectedObjFromState.type) {
      const chunks = [];
      for (let i = 0; i < selectedRanches.length; i += chunkSize) {
        chunks.push(selectedRanches.slice(i, i + chunkSize));
      }
      setRanchArray(chunks);
      setItems(chunks[0] || []);
      setHasMoreItems(chunks.length > 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRanches?.length, selectedObjFromState.type, block]);

  useEffect(() => {
    if (!location.search.length) {
      if (ranch && ranch[0]) history.push(`?ranchId=${ranch[0]}`);
      if (block && block[0]) history.push(`?blockId=${block[0]}`);
    }
  }, [location.search, ranch, block, history]);

  useEffect(() => {
    dispatch(actions.loadUserSettings());
  }, [dispatch]);

  useEffect(() => {
    if (tracking) tracking.track('Loaded Schedule Page');
  }, [tracking]);

  function handleSelectedRanchBlock(d) {
    if (d?.value !== undefined) {
      dispatch(setRanchBlockSelection(d, tracking));
      setItems([]);
      setRanchArray([]);
      setCount(0);
      setHasMoreItems(false);
    }
  }

  const fetchMoreData = useCallback(() => {
    if (ranchArray[count + 1]) {
      setItems((prevItems) => [...prevItems, ...ranchArray[count + 1]]);
      setCount(count + 1);
      setHasMoreItems(count + 1 < ranchArray.length - 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, ranchArray]);

  function renderRanches(ranches) {
    return ranches.map((r) => (
      <RanchScheduleList ranch={r} blockId={block?.[0]} key={r.id} />
    ));
  }

  function renderLoaderOrCustomMessage() {
    if (loadingBlockSchedule.loading) {
      return <ReactContentLoader />;
    }
    if (!items.length) {
      return (
        <CardLayout>
          <span className="no-schedule">{t('Nothing Scheduled')}</span>
        </CardLayout>
      );
    }
    return null;
  }

  return (
    <div id="schedule-scrollable-container" className="schedule-scrollable-container">
      <div className="schedule-page-container page-content">
        <div className="inline-page-loader-div justify-content-space-between">
          <div className="flex-row">
            <PageHeader title={t('schedule')} />
            {loadingBlockSchedule.loading && (
              <div className="loader-common">
                <LoadingOutlined />
              </div>
            )}
          </div>
          <Button
            className="schedule-share-export-button"
            icon={<FaFileExport size={20} />}
            onClick={() => setModal(true)}
          >
            {t('Share / Export')}
          </Button>
        </div>
        <Modal
          visible={modal}
          centered
          closable
          onCancel={() => setModal(false)}
          footer={null}
        >
          <div className="schedule-water-usage-container schedule-font-style">
            <div className="schedule-modal-header">Select what you want to share</div>
            <div className="schedule-options-share-modal">
              <Button
                className="lat-lng-select-options schedule-font-style"
                data-testid="share-schedule"
                block
                onClick={() => {
                  if (tracking) {
                    tracking.track('Clicked Schedule Share');
                  }
                  history.push('/schedule/share');
                }}
                type="primary"
                ghost
              >
                {t('Share Schedule')}
              </Button>
              <Button
                className="lat-lng-select-options schedule-font-style"
                data-testid="share-water-usage"
                block
                onClick={() => {
                  setModal(false);
                  tracking.track('Clicked Schedule Share');
                  history.push('/schedule/waterusage/share');
                }}
                type="primary"
                ghost
              >
                {t('Share Water Usage')}
              </Button>
            </div>
          </div>
        </Modal>
        <CreateScheduleButton />
        <div className="div-select-container ranch-block-select-container">
          {isMobile ? (
            <RanchBlockSelectMobile
              getSelectedRanchBlock={handleSelectedRanchBlock}
              selected={selectedObjFromState}
              admin={userInfo?.admin}
            />
          ) : (
            <RanchBlockSelect
              getSelectedRanchBlock={handleSelectedRanchBlock}
              selected={selectedObjFromState}
              admin={userInfo?.admin}
            />
          )}
        </div>
        <InfiniteScroll
          className="item-list"
          initialLoad={false}
          loadMore={fetchMoreData}
          hasMore={hasMoreItems}
          loader={<div className="loader" key="0">Loading...</div>}
          useWindow={false}
          getScrollParent={() => document.getElementById('schedule-scrollable-container')}
        >
          {renderRanches(items)}
        </InfiniteScroll>
        {renderLoaderOrCustomMessage()}
      </div>
    </div>
  );
}
