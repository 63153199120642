import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { authService, constants } from "farmx-api";
import { notification } from "antd";

export function useGoogleLogin(from: string) {
  const history = useHistory();
  const location = useLocation();
  const [isHandled, setIsHandled] = useState(false); // Flag to handle the action only once

  useEffect(() => {
    if (isHandled) return; // Exit early if already handled

    // Parse query parameters from the URL
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    const accessToken = params.get("access");
    const refreshToken = params.get("refresh");

    // Show error in a toast notification if it exists
    if (error) {
      notification.error({
        message: error,
        placement: 'top',
        style: { borderRadius: 5, width: 600 },
        duration: 6,
      });
      setIsHandled(true);
      return;
    }

    // If accessToken and refreshToken exist, handle login logic
    if (accessToken && refreshToken) {
      authService.loginUsingTokens(accessToken, refreshToken);
      setIsHandled(true);
      // Redirect after login
      history.replace(from);
    }
  }, [location, history, from, isHandled]);

  // Function to trigger Google login redirection
  const handleGoogleLogin = () => {
    const googleLoginUrl = `${constants.API_URL}${constants.GOOGLE_AUTH_PATH}`;
    // Redirect to the Google login URL within the current window
    window.location.href = googleLoginUrl;
  };

  // Expose the Google login handler
  return { handleGoogleLogin };
}
