// using cloneDeep to make data more less immutable
import cloneDeep from 'lodash/cloneDeep';

export const initReducerMapMobile = () => ({
  isBottomSheetVisible: true,
  // mobilePaddingBottom: 20,
  recenterGeoJSON: undefined,
  zoomSensorsVisible: false,
  selectedFeature: undefined,
  anomalyGeoJSON: [],
  // bottomSheetType enum:
  // 'settings' 'sensor' 'block' 'ranch' 'all_ranches' undefined
  // currently using 'settings' 'sensor' undefined
  bottomSheetType: undefined,
  isLoading: false,
  isExpandBtnVisible: true,
  ranchesAllGeoJSON: {
    type: 'FeatureCollection',
    features: [],
  },
  ranchesPointsGeoJSON: {
    type: 'FeatureCollection',
    features: [],
  },
  isDataLoading: true,
  showMap3D: false,
  selectedImageryData: {
    visible: false,
    data: undefined,
  },
  selectedImageryDataOpacity: 90,
  isImageryPanelVisible: false,
  sensorsByRanchIdGeoJSON: [],
  latestReload: undefined,
  ranchIds: [],
  anomalyLayerData: null,
  sideBySideMapVisible: false,
  showLayer: {
    soil: { left: { visible: false }, right: { visible: false } },
    side: 'left',
    type: undefined,
    visible: false,
  },
  layersControl: { side: 'left', type: 'tab', layer: { side: 'left' } },
  plantsGeoJSON: null,
  selectedSatelliteDataSource: 'satellite',
  selectedDroneDataType: 'NDVI',
  selectedDroneImagery: {
    visible: true,
    data: undefined,
  },
  plantsOption: 'count',
  plantsView: 'plant',
  zoomVal: 0,
  showPlants: true,
  blockAnalytics: null,
  selectedSatelliteDataType: 'Plant Vigor (NDVI)',
  dateRangeWithDisplayData: undefined,
  isPlantLoading: false,
  selectedSatelliteDate: undefined,
  selectedRanchId: undefined,
  selectedBlockId: undefined,
  satelliteDates: undefined,
  selectedSatelliteDatesFiltered: undefined,
  selectedSatelliteDatesDataRange: undefined,
  availableDroneData: undefined,
  availableDroneDates: undefined,
  selectedDroneData: {
    visible: true,
    data: undefined,
  },
  selectedDroneDate: undefined,
  availableDroneDataTypes: undefined,
  selectedDroneTilesData: undefined,
  is3DMapLoading: false,
});

export function reducerMapMobile(state, action) {
  switch (action.type) {
    case 'setIsExpandBtnVisible': {
      return {
        ...state,
        isExpandBtnVisible: action.payload,
      };
    }
    case 'setShowLayer': {
      return {
        ...state,
        showLayer: action.payload,
      };
    }
    case 'setLayersControl': {
      return {
        ...state,
        layersControl: action.payload,
      };
    }
    case 'setIsLoading': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'setAnomalyLayerData': {
      return {
        ...state,
        anomalyLayerData: action.payload,
      };
    }
    case 'setZoomSensorsVisible': {
      const zoom = action.payload;
      if (zoom < 13 && state.zoomSensorsVisible) {
        return {
          ...state,
          zoomSensorsVisible: false,
          zoomVal: Math.round(zoom),
        };
      }
      if (zoom >= 13 && !state.zoomSensorsVisible) {
        return {
          ...state,
          zoomSensorsVisible: true,
          zoomVal: Math.round(zoom),
        };
      }
      if (Math.round(zoom) !== state.zoomVal) {
        return { ...state, zoomVal: Math.round(zoom) };
      }
      return state;
    }

    case 'setUpdateRecenterGeoJSON': {
      try {
        const r = cloneDeep(state.recenterGeoJSON);
        if (!r?.features?.length) {
          return state;
        }
        // update data to force rerender
        if (r?.features[0]) r.features[0].t = (new Date()).getTime();
        return {
          ...state,
          recenterGeoJSON: r,
        };
      } catch (error) {
        console.log('ERROR in setUpdateRecenterGeoJSON', error);
        return state;
      }
    }

    case 'setRecenterGeoJSON': {
      try {
        const r = cloneDeep(action.payload);
        if (!r?.features?.length) {
          return state;
        }
        // update data to force render
        if (r?.features[0]) r.features[0].t = (new Date()).getTime();
        return {
          ...state,
          recenterGeoJSON: r,
        };
      } catch (error) {
        console.log('Error in setRecenterGeoJSON', error);
        return state;
      }
    }

    case 'setIsBottomSheetVisible': {
      const visible = !!cloneDeep(action.payload);
      return {
        ...state,
        isBottomSheetVisible: visible,
      };
    }

    case 'setSettings': {
      if (!state.isBottomSheetVisible) {
        return {
          ...state,
          isBottomSheetVisible: true,
          bottomSheetType: 'settings',
        };
      }
      if (state.isBottomSheetVisible && state.bottomSheetType !== 'settings') {
        return {
          ...state,
          bottomSheetType: 'settings',
        };
      }
      return {
        ...state,
        isBottomSheetVisible: false,
      };
    }

    case 'setSelectedFeatureWithType': {
      const { feature, type, showBottomSheet } = cloneDeep(action.payload);
      return {
        ...state,
        selectedFeature: feature,
        bottomSheetType: type,
        isBottomSheetVisible: showBottomSheet,
      };
    }
    case 'setRanchIds':
      return {
        ...state,
        ranchIds: action.payload,
      };
    case 'setRanchesAllGeoJSON':
      return {
        ...state,
        ranchesAllGeoJSON: action.payload,
      };
    case 'setRanchesPointsGeoJSON':
      return {
        ...state,
        ranchesPointsGeoJSON: action.payload,
      };
    case 'setLatestReload':
      return {
        ...state,
        latestReload: Date.now(),
      };
    case 'setSensorsByRanchIdGeoJSON':
      return {
        ...state,
        sensorsByRanchIdGeoJSON: action.payload,
        isDataLoading: false,
      };
    case 'setIsImageryPanelVisible':
      return {
        ...state,
        isImageryPanelVisible: action.payload,
      };
    case 'setSelectedImageryData':
      return {
        ...state,
        selectedImageryData: action.payload,
      };
    case 'setShowMap3D':
      return {
        ...state,
        showMap3D: action.payload,
      };
    case 'setAnomalyGeoJSON': {
      return {
        ...state,
        anomalyGeoJSON: action.payload,
      };
    }
    case 'setSideBySideMapVisible':
      return {
        ...state,
        sideBySideMapVisible: action.payload,
      };
    case 'setPlantsData':
      return {
        ...state,
        plantsGeoJSON: action.payload,
      };
    case 'setSelectedSatelliteDataSource':
      return {
        ...state,
        selectedSatelliteDataSource: action.payload,
      };
    case 'setSelectedDroneDataType':
      return {
        ...state,
        selectedDroneDataType: action.payload,
      };
    case 'setSelectedDroneImagery':
      return {
        ...state,
        selectedDroneImagery: action.payload,
      };
    case 'setPlantsOption':
      return {
        ...state,
        plantsOption: action.payload,
      };
    case 'setPlantsView':
      return {
        ...state,
        plantsView: action.payload,
      };
    case 'setShowPlants':
      return {
        ...state,
        showPlants: action.payload,
      };
    case 'setBlockAnalytics':
      return {
        ...state,
        blockAnalytics: action.payload,
      };
    case 'setSelectedSatelliteDataType':
      return {
        ...state,
        selectedSatelliteDataType: action.payload,
      };
    case 'setDateRangeWithDisplayData':
      return {
        ...state,
        dateRangeWithDisplayData: action.payload,
      };
    case 'setIsPlantLoading':
      return {
        ...state,
        isPlantLoading: action.payload,
      };
    case 'setSelectedSatelliteDate':
      return {
        ...state,
        selectedSatelliteDate: action.payload,
      };
    case 'setSelectedSatelliteDatesDataRange':
      return {
        ...state,
        selectedSatelliteDatesDataRange: action.payload,
      };
    case 'setSelectedSatelliteDatesFiltered':
      return {
        ...state,
        selectedSatelliteDatesFiltered: action.payload,
      };
    case 'setSatelliteDates':
      return {
        ...state,
        satelliteDates: action.payload,
      };
    case 'setSelectedRanchId':
      return {
        ...state,
        selectedRanchId: action.payload ? Number(action.payload) : undefined,
      };
    case 'setSelectedBlockId':
      return {
        ...state,
        selectedBlockId: action.payload ? Number(action.payload) : undefined,
      };
    case 'setAvailableDroneData':
      return {
        ...state,
        availableDroneData: action.payload,
      };
    case 'setAvailableDroneDates':
      return {
        ...state,
        availableDroneDates: action.payload,
      };
    case 'setAvailableDroneDataTypes':
      return {
        ...state,
        availableDroneDataTypes: action.payload,
      };
    case 'setSelectedDroneData':
      return {
        ...state,
        selectedDroneData: action.payload,
      };
    case 'setSelectedDroneDate':
      return {
        ...state,
        selectedDroneDate: action.payload,
      };
    case 'setSelectedDroneTilesData':
      return {
        ...state,
        selectedDroneTilesData: action.payload,
      };
    case 'setLoadingData':
      return {
        ...state,
        is3DMapLoading: action.payload,
      };
    case 'reset':
      return initReducerMapMobile();
    default:
      throw new Error('Unkown action type');
  }
}
