import { selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {
  getTextForIrrigationState,
  getStateForIrrigationState,
} from './recommendation';
import { StatusTag } from '../components/StatusTag';
import './BlockRecommendationSummary.less';
import { recommendationAnomalyFilter, getRecommendationIdentifier } from '../../../helper/common';

export function BlockRecommendationSummary({ block }) {
  const recommendation = useSelector(
    (state) => recommendationAnomalyFilter(
      selectors.selectRecommendationForBlock(state, block.id),
    ),
  );

  if (!recommendation || recommendation.state === undefined) {
    return null;
  }

  return (
    <div className="block-recommendation-summary">
      {recommendation?.state !== 'no_sensor' ? (
        <Link to={`/recommendation/detail/${getRecommendationIdentifier(recommendation)}`}>
          <StatusTag
            text={getTextForIrrigationState(recommendation.state)}
            state={getStateForIrrigationState(recommendation.state)}
          />
        </Link>
    ) : null}
    </div>
  );
}

BlockRecommendationSummary.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
