import React, {
  useState, useEffect, useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { SensorSelect, RanchBlockSelectMobile } from 'farmx-web-ui';
import { actions, hooks, selectors } from 'farmx-redux-core';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  // Button,
  PageHeader,
  DatePicker,
  Radio,
  Checkbox,
  Select,
  Button,
} from 'antd';
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { constants } from 'farmx-api';

// import SensorDataChart from './SensorDataChart';
import './graph.less';
import { isMobile } from 'react-device-detect';
import { useGroupSensors } from 'helper/graphHelper';
import { isSameList } from 'helper/common';
import { useUserTimeFormat }from '../../helper/commonHooks';
import {
  useGetAllSensorsByBlockIds,
  useGetSensorsByCategory,
  useGetUniqueSensorData,
  usePrepareChartList,
} from '../hooks/graph';
import { GraphContextProvider } from './GraphContext';
import PresetChartSelect from './PresetChartSelect';
import SimpleSensorSelect from './SimpleSensorSelect';

const {
  loadSensorStatus,
  setRanchBlockSelection,
  loadSensorsForEntity,
} = actions;

const {
  useRanchBlockSelection,
  // useUnits
} = hooks;

const {
  selectLoginUserInfo,
  selectLoadingSensors,
} = selectors;

const graphSizeOptions = [
  { value: 'sm', label: 'Small' },
  { value: 'md', label: 'Medium' },
  { value: 'lg', label: 'Large' },
];

// const loadedSensorStatus = [];


export function MultiGraphPage(props) {
  // TODO remove after testing
  // console.log('Calling MultiGraphPage');
  const [selectedPreset, setSelectedPreset] = useState('soil');
  // To avoid page re-rendering with same block ids
  const [localBlockIds, setLocalBlockIds] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { search, path } = location;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Changing time format based on the user settings
  const userTimeFormat = useUserTimeFormat();
  const { selectedObjFromState, blockIds } = useRanchBlockSelection();
  const [uniformYAxis, setUniformYAxis] = useState(false);
  // this will be reset when preset changes
  const [yAxisMinMax, setYAxisMinMax] = useState({});
  const [sensorDataRefresh, setSensorDataRefresh] = useState({});
  const [sensors, setSensors] = useState([]);
  const [sensorsToCompare, setSensorsToCompare] = useState([]);
  // get sensors for the selected ranch/block
  // Get sensor type map using hook usePrepareSensorMap()

  const [optionalCharts, setOptionalCharts] = useState([]);
  const sensorsFromHook = useGetAllSensorsByBlockIds(localBlockIds);
  const {
    sSensors, pSensors, wsSensors, vSensors, ddSensors
  } = useGetSensorsByCategory(sensorsFromHook);
  // TODO remove after testing
  // console.log('wsSensors', sSensors, pSensors, wsSensors, vSensors, ddSensors);
  const groupedSensorsForBlocks = useGroupSensors({
    sSensors, pSensors, wsSensors, vSensors, ddSensors
  }, localBlockIds);
  // for given blocks get the sensors
  const sensorsNewData = useGetUniqueSensorData(groupedSensorsForBlocks);
  // presetCharts = sensor chart list for given sensor type map using hook usePrepareChartList
  const presetCharts = usePrepareChartList(localBlockIds, selectedPreset,
    optionalCharts, sensors, sensorsToCompare);

  const [graphObj, setGraphObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const loadedSensorStatus = useRef([]);

  useEffect(() => {
    // set only for the first time
    // console.log('sensorsNewData', sensorsNewData);
    setSensors(sensorsNewData);
  }, [sensorsNewData]);

  // clear all on sensor select
  const onClearSensors = useCallback(() => {
    setSensors([]);
  }, [setSensors]);

  // TODO remove after testing
  // console.log('presetCharts', presetCharts, 'hasMoreItems', hasMoreItems);
  // console.log('sensors', sensors);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('graphObj changed', graphObj);
  // }, [graphObj]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('filteredItems changed', filteredItems);
  // }, [filteredItems]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('sensorTypeMap changed', sensorTypeMap);
  // }, [sensorTypeMap]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('presetCharts changed', presetCharts);
  // }, [presetCharts]);


  // const contextValue = { setYAxisMinMax };
  // TODO remove after testing
  // console.log('yAxisMinMax', yAxisMinMax);

  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo?.admin;

  const { showBack } = props;

  const now = moment();
  const defaultDateRange = [now.startOf('day').clone().subtract(2, 'weeks'), now.endOf('day')];

  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [graphSize, setGraphSize] = useState('md'); // To maintain the height of the rendered graph
  // To handle the more sensors graph
  const chunkSize = (graphSize==='sm')? 6 : 4;

  /**
   * Way to handle refreshing data for a sensor
   * and for all graphs related to the sensor
   * @constructor
   * @param {string} type - Sensor type
   * @param {string} identifier - Sensor identifier
   */
  const refreshSensorData = useCallback((type, identifier) => {
    // TODO remove after testing
    // console.log('refreshSensorData', type, identifier);
    dispatch(loadSensorStatus({
      type,
      identifier,
    }));
    setSensorDataRefresh((prevState) => ({
      ...prevState,
      [`${type}/${identifier}`]: (prevState[`${type}/${identifier}`] || 0)+1,
    }));
  }, [dispatch]);

  const updateYminmax = useCallback((type, min, max) => {
    // TODO remove after testing
    // console.log('type, min, max', type, min, max);
    setYAxisMinMax((prevState) => ({
      ...prevState,
      [type]: {
        min: prevState[type]?Math.min(prevState[type]?.min, min):(min || 999),
        max: prevState[type]?Math.max(prevState[type]?.max, max):(max || -999)
      },
    }));
  }, []);


  // To set and use the blockIds from local state to avoid re-rendering
  useEffect(() => {
    // TODO remove after testing
    // console.log('blockIds changed', blockIds, localBlockIds,
    // isSameList(blockIds, localBlockIds));
    if (!isSameList(blockIds, localBlockIds)) {
      setLocalBlockIds(blockIds);
    }
  }, [blockIds, localBlockIds]);


  const { type, value } = selectedObjFromState || {};
  // TODO remove after testing
  // console.log('selectedObjFromState', type, value);
  const ranchId = type === 'ranch' ? Number(value) : null;
  const blockId = type === 'block' ? Number(value) : null;
  const entityId = type === 'entity' ? Number(value) : null;

  // TODO to improve this to load sensors for given ranch
  useEffect(() => {
    if(entityId){
      dispatch(loadSensorsForEntity(entityId));
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const startDate = urlParams.get('startDate');
    const endDate = urlParams.get('endDate');
    if (startDate && endDate) {
      setDateRange([moment(startDate), moment(endDate)]);
    }
  }, [history, search, path]);

  // Clear graphs state when blockId/ranchId changes
  useEffect(() => {
    // TODO remove after testing
    // console.log('ranch/block changed');
    if (blockId || ranchId) {
      setYAxisMinMax(() => ({}));
      setLocalBlockIds([]);
      setDateRange((prev) => ([moment(prev[0]), moment(prev[1])]));
      setFilteredItems([]);
      setCount(0);
      loadedSensorStatus.current = [];
      setHasMoreItems(false);
      setSensors([]);
    }
  }, [blockId, ranchId]);

  useEffect(() => {
    if(selectedPreset || dateRange){
      setYAxisMinMax(() => ({}));
    }
  }, [selectedPreset, dateRange]);

  function onSizeChange(e) {
    setGraphSize(e.target.value);
  }

  function renderSizeSelect() {
    return (
      <Radio.Group
        options={graphSizeOptions}
        onChange={(d) => onSizeChange(d)}
        value={graphSize}
        optionType="button"
      />
    );
  }

  const toggleUniformYAxis = () => {
    setUniformYAxis(!uniformYAxis);
  };

  function renderChartContainer(chart) {
    // TODO remove after testing
    // console.log('chart sensor', chart);
    return (
      <div key={chart.graph?.key} className={`chart-container chart-${graphSize}`}>
        <div className="chart-body">
          {/* This will render the chart set */}
          <chart.graph.component
            {...chart.graph.props}
            startDate={dateRange?.length ? dateRange[0] : null}
            endDate={dateRange?.length ? dateRange[1] : null}
            cached="true"
            updateYminmax={updateYminmax}
            uniformYAxis={uniformYAxis}
            yMinMaxMap={yAxisMinMax}
            refreshSensorData={refreshSensorData}
            // individual={optionalCharts.includes('individual')}
          />
        </div>
      </div>
    );
  }

  const onPresetChange = (e) => {
    setSelectedPreset(e);
    setOptionalCharts([]);
  };

  const onOptionalChartSelect = useCallback((value) => {
    // TODO remove after testing
    // console.log('onOptionalChartSelect', value);
    setOptionalCharts(value);
  }, []);

  // Split array into small chunks and store to local state with key
  useEffect(() => {
    const graphData = presetCharts;
    setHasMoreItems(false);
    setCount(0);
    if (graphData) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < graphData.length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(graphData[i]);
      }
      setGraphObj(obj);
      setFilteredItems(obj[0]);
      if (graphData.length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [chunkSize, presetCharts]);

  useEffect(() => {
    if (filteredItems && filteredItems.length) {
      filteredItems.forEach((chart) => {
        // Load sensor status only for the newly added items in the state
        const sensor = chart?.graph?.props?.sensor;
        if (sensor && !loadedSensorStatus.current.includes(sensor.identifier)) {
          loadedSensorStatus.current.push(sensor.identifier);
          // TODO remove after testing
          // console.log('Calling sensor status for ', chart.graph.props.sensor.name);
          dispatch(loadSensorStatus({
            type: sensor.type,
            identifier: sensor.identifier,
          }));
        }
      });
    }
  }, [dispatch, filteredItems, loadedSensorStatus]);

  const fetchMoreData = useCallback(() => {
    // TODO remove after testing
    // console.log('fetchMoreData...', count);
    function handleItems(configArr) {
      const prevItems = filteredItems;
      const newItems = prevItems.concat(configArr);
      return newItems;
    }

    if (graphObj[count + 1]) {
      setHasMoreItems(true);
      setFilteredItems(handleItems(graphObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, filteredItems, graphObj]);

  const onPreviousClick = () => {
    const [startDate, endDate] = dateRange;
    const difference = endDate.diff(startDate, 'days');

    setDateRange([
      moment(startDate.subtract(difference, 'days')),
      moment(endDate.subtract(difference, 'days'))
    ]);
  };

  const onNextClick = () => {
    const [startDate, endDate] = dateRange;
    const difference = endDate.diff(startDate, 'days');

    setDateRange([
      moment(startDate.add(difference, 'days')),
      moment(endDate.add(difference, 'days'))
    ]);
  };

  const onTodayClick = () => {
    setDateRange([
      moment().startOf('day'),
      moment().startOf('day').add(1, 'day')
    ]);
  };

  const onWeekClick = () => {
    setDateRange([
      moment().startOf('day').subtract(6, 'days'),
      moment().startOf('day').add(1, 'day'),
    ]);
  };

  const onMonthClick = () => {
    setDateRange([
      moment().startOf('day').subtract(30, 'days'),
      moment().startOf('day').add(1, 'day'),
    ]);
  };

  const onSelectSensors = useCallback((list) => {
    setSensors((prev) => {
      // TODO remove after testing
      // console.log('list', typeof list, list, 'prev', prev, sensorsNewData);
      let newlySelected = prev;
      if(list.length !== prev.length) {
        newlySelected = sensorsNewData?.filter((sensor) => list.includes(sensor.id));
      }
      // TODO remove after testing
      // console.log('newlySelected', newlySelected);
      return newlySelected;
    });
  }, [sensorsNewData]);

  const onSensorSelectToCompare = useCallback((list) => {
    // TODO remove after testing
    console.log('selected sensors', list);
    setSensorsToCompare(() => [...list]);
  }, []);

  const renderSimpleSensorSelect = useMemo(() => (
    <SimpleSensorSelect
      sensors={sensorsNewData}
      onChange={onSelectSensors}
      onClear={onClearSensors}
      values={sensors.map((sensor) => sensor.id)}
    />
  ),
  [sensorsNewData, onSelectSensors, onClearSensors, sensors]);

  const renderSensorSelect = useMemo(() => {
    // use pre applied filter
    const selectedFilters = [
      {
        type: 'type',
        value: 'cavalier',
        op: 'NOT',
        noEdit: true,
        noDelete: true,
        hide: true
      },
      {
        type: 'type',
        value: 'gateway',
        op: 'NOT',
        noEdit: true,
        noDelete: true,
        hide: true
      },
      {
        type: 'install_state',
        value: 'unknown',
        op: 'NOT',
        noEdit: true,
        noDelete: true,
        hide: true
      },
      {
        type: 'install_state',
        value: 'planned',
        op: 'NOT',
        noEdit: true,
        noDelete: true,
        hide: true
      },
      {
        type: 'install_state',
        value: 'inventory',
        op: 'NOT',
        noEdit: true,
        noDelete: true,
        hide: true
      },
      // To keep the selected ranch/block as default filter
      {
        type: ranchId ? 'ranch' : 'block',
        value: ranchId || blockId,
        op: 'AND',
        noEdit: true,
        noDelete: true,
        hide: true
      }];
    return (
      <div className="sensor-compare">
        <span>Compare With</span>
        <div className="sensor-select-compare">
          <SensorSelect
            mode="multiple"
            onChange={onSensorSelectToCompare}
            selectedFilters={selectedFilters}
            applyFilter
            hideFilterOptions={{
              installStates: ['unknown', 'planned', 'inventory'],
              sensorTypes: ['cavalier', 'gateway']
            }}
            dropdownStyle={{ minWidth: '500px' }} // Minimum width of 500px for the dropdown
          />
        </div>
      </div>
    );
  },
  [onSensorSelectToCompare, ranchId, blockId]);

  return (
    <>
      <GraphContextProvider value={{yAxisMinMax, sensorDataRefresh}}>
        <div className="graph-page multi-graph-page" id="graph-scrollable-container">
          <Helmet>
            <title>Graph</title>
          </Helmet>
          <PageHeader
            title="Sensor Graph"
            onBack={() => history.goBack()}
            backIcon={
              showBack
                ? <ArrowLeftOutlined /> : false
            }
            extra={renderSizeSelect()}
          />
          {isMobile && (
          <div className="graph-page-ranch-block-selector">
            <RanchBlockSelectMobile
              getSelectedRanchBlock={(d) => {
                dispatch(setRanchBlockSelection(d));
              }}
              selected={selectedObjFromState}
              admin={isAdmin}
            />
          </div>
          )}
          <div className="page-body padded graph-row-gap">
            <div className="sensor-selectors">
              {renderSimpleSensorSelect}
              {renderSensorSelect}
            </div>
            <div className="header-container">
              {/* New Preset Selector */}
              <div className="preset-container">
                <div id="presetSelect" className="preset-select">
                  <span className="preset-select-label">Select Preset</span>
                  <Select
                    placeholder={() => ('Select Preset')}
                    options={[
                      { value: 'soil', label: t('Soil') },
                      { value: 'control', label: t('Control') },
                      { value: 'irrigation', label: t('Irrigation') },
                      { value: 'weather', label: t('Weather') },
                      { value: 'plant', label: t('Plant') }
                    ]}
                    onChange={(d) => onPresetChange(d)}
                    value={selectedPreset}
                    getPopupContainer={() => document.getElementById('presetSelect')}
                    popupClassName="preset-options"
                  />
                </div>
                <div className="uniform-y-axis-checkbox">
                  <Checkbox onChange={() => toggleUniformYAxis()} checked={uniformYAxis}>
                    {t('Uniform Y-Axis')}
                  </Checkbox>
                </div>
              </div>
              <div className="charts-dropdown">
                <PresetChartSelect onSelectChange={onOptionalChartSelect} preset={selectedPreset} />
              </div>
              <div className="graph-date-range-picker">
                <DatePicker.RangePicker
                  showTime
                  onChange={(d) => setDateRange(d)}
                  value={dateRange}
                  format={userTimeFormat.unitsTime
                    ? 'YYYY-MM-DD hh:mm:ss a' : 'YYYY-MM-DD HH:mm:ss'}
                  defaultValue={dateRange}
                  use12Hours={userTimeFormat.unitsTime}
                  onOk={null}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
                <div className="date-range-shortcuts">
                  <Button
                    className="date-range-shortcut-button today-button"
                    onClick={onTodayClick}
                  >
                    {t('Today')}
                  </Button>
                  <Button
                    className="date-range-shortcut-button week-button"
                    onClick={onWeekClick}
                  >
                    {t('Week')}
                  </Button>
                  <Button
                    className="date-range-shortcut-button month-button"
                    onClick={onMonthClick}
                  >
                    {t('Month')}
                  </Button>
                  <Button
                    className="date-range-shortcut-button previous-button"
                    onClick={onPreviousClick}
                  >
                    <LeftOutlined />
                    {isMobile?'':t('Previous')}
                  </Button>
                  <Button
                    className="date-range-shortcut-button next-button"
                    onClick={onNextClick}
                  >
                    {isMobile?'':t('Next')}
                    <RightOutlined />
                  </Button>
                </div>
              </div>
            </div>
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => fetchMoreData()}
              hasMore={hasMoreItems}
              useWindow={false}
              threshold={1}
              getScrollParent={() => document.getElementById('graph-scrollable-container')}
            >
              {
            filteredItems?(
              <div className="graph-list">
                {filteredItems?.map((chart) => renderChartContainer(chart))}
              </div>
            ):''
            }
            </InfiniteScroll>
            { hasMoreItems
              ?(
                <div className="load-more-button">
                  <Button
                    onClick={() => fetchMoreData()}
                  >
                    {t('Load More...')}
                  </Button>
                </div>
              )
              : ''}
          </div>
        </div>
      </GraphContextProvider>
    </>
  );
}

MultiGraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  showBack: PropTypes.bool,
};

MultiGraphPage.defaultProps = {
  history: {
    goBack: () => { },
    push: () => { },
  },
  showBack: false,
};
