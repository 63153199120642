import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Typography, List, Skeleton, message } from "antd";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { followBlockApi } from "farmx-api";
//@ts-ignore
import { selectors } from 'farmx-redux-core';
import './RanchBlockSettings.css';

export interface RanchBlockSettingsProps {
  ranch: any;
}

export interface FollowBlock {
  id: number;
  user_id: number;
  target_id: number;
}

const debounce = (func: any, wait: number) => {
  let timeout: any;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const RanchBlockSettings: React.FC<RanchBlockSettingsProps> = ({ ranch }) => {
  const { t } = useTranslation();
  const blocks = useSelector((state) => selectors.selectBlocksForRanchIdAlphabetically(state, Number(ranch.id)));
  const userInfo = useSelector(state => selectors.selectLoginUserInfo(state))?.payload;
  const [followBlocks, setFollowBlocks] = useState<FollowBlock[]>([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [disableAllBlockButtons, setDisableAllBlockButtons] = useState(false);
  const [disableBlockButtons, setDisableBlockButtons] = useState<{ [key: number]: boolean }>({});

  const fetchRanchFollowBlocksForRanch = useCallback(debounce(async (optimistic = false) => {
    if (!optimistic) setLoading(true);
    try {
      const response = await followBlockApi.listFollowBlocks({ ranch_id: ranch.id, user_id: userInfo?.user_id, page_size: 100 });
      setFollowBlocks(response.data.data);
    } catch (error) {
      message.error(t("Failed to fetch follow blocks"));
    }
    if (!optimistic) setLoading(false);
    setPending(false);
    setDisableAllBlockButtons(false);
    setDisableBlockButtons({});
  }, 300), [ranch.id, userInfo?.user_id]);

  useEffect(() => {
    if (userInfo?.user_id) {
      fetchRanchFollowBlocksForRanch(false);
    }
  }, [ranch.id, userInfo?.user_id, fetchRanchFollowBlocksForRanch]);

  const updatedBlocks = useMemo(() =>
    blocks.map((block: any) => ({
      ...block,
      isFollowed: followBlocks.some((fb: FollowBlock) => fb.target_id === block.id)
    })), [blocks, followBlocks]);

  const handleFollowAll = async () => {
    await handleFollowUnfollowAll(true);
  };

  const handleUnfollowAll = async () => {
    await handleFollowUnfollowAll(false);
  };

  const handleFollowUnfollowAll = async (isFollow: boolean) => {
    setPending(true);
    setDisableAllBlockButtons(true);
    const previousFollowBlocks = [...followBlocks];

    if (isFollow) {
      setFollowBlocks(blocks.map((block: any) => ({ user_id: userInfo?.user_id, target_id: block.id })));
    } else {
      setFollowBlocks([]);
    }

    try {
      if (isFollow) {
        await followBlockApi.followRanch(ranch.id, userInfo?.user_id);
      } else {
        await followBlockApi.unfollowRanch(ranch.id, userInfo?.user_id);
      }
      fetchRanchFollowBlocksForRanch(true);
    } catch (error) {
      message.error(isFollow ? t("Failed to follow all blocks") : t("Failed to unfollow all blocks"));
      setFollowBlocks(previousFollowBlocks);
      setPending(false);
      setDisableAllBlockButtons(false);
    }
  };

  const handleFollowUnfollow = async (blockId: number, isFollow: boolean) => {
    setPending(true);
    setDisableBlockButtons(prev => ({ ...prev, [blockId]: true }));

    const followBlock = { user_id: userInfo?.user_id, target_id: blockId };
    const previousFollowBlocks = [...followBlocks];

    if (isFollow) {
      setFollowBlocks((prev:any) => [...prev, followBlock]);
    } else {
      setFollowBlocks(prev => prev.filter(fb => fb.target_id !== blockId));
    }

    try {
      if (isFollow) {
        await followBlockApi.createFollowBlock({ user_id: userInfo?.user_id, block_id: blockId });
      } else {
        const blockToUnfollow = followBlocks.find(fb => fb.target_id === blockId);
        if (blockToUnfollow) {
          await followBlockApi.deleteFollowBlock(blockToUnfollow.id);
        }
      }
      fetchRanchFollowBlocksForRanch(true);
    } catch (error) {
      message.error(isFollow ? t("Failed to follow block") : t("Failed to unfollow block"));
      setFollowBlocks(previousFollowBlocks);
      setPending(false);
      setDisableBlockButtons(prev => ({ ...prev, [blockId]: false }));
    }
  };

  return (
    <div className="ranch-block-settings">
      <div className="header">
        <Typography.Title level={4} className="ranch-title">{ranch.name}</Typography.Title>
        {!loading && (
          <div className="header-buttons">
            <Button
              disabled={updatedBlocks.every((block: any) => block.isFollowed) || pending}
              className="follow-all-button" type="primary" onClick={handleFollowAll}>{t("Follow All")}</Button>
            <Button
              disabled={updatedBlocks.every((block: any) => !block.isFollowed) || pending}
              className="unfollow-all-button" type="default" danger onClick={handleUnfollowAll}>{t("Unfollow All")}</Button>
          </div>
        )}
      </div>
      {loading ? <Skeleton active /> : (
        <List
          dataSource={updatedBlocks}
          renderItem={(block: any) => (
            <List.Item
              key={block.id}
              className="block-item"
              actions={[
                block.isFollowed ? (
                  <Button 
                    className="block-button" 
                    type="default" 
                    danger 
                    onClick={() => handleFollowUnfollow(block.id, false)} 
                    disabled={disableAllBlockButtons || disableBlockButtons[block.id]}
                  >
                    {t("- Unfollow")}
                  </Button>
                ) : (
                  <Button 
                    className="block-button" 
                    type="primary" 
                    onClick={() => handleFollowUnfollow(block.id, true)} 
                    disabled={disableAllBlockButtons || disableBlockButtons[block.id]}
                  >
                    {t("+ Follow")}
                  </Button>
                )
              ]}
            >
              <List.Item.Meta title={block.name} />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
