import React from 'react';
import { RouteComponentProps } from 'react-router';
import { InviteUser } from './InviteUser';
//@ts-ignore
import { teamApi, teamUserAccessApi, roleApi} from 'farmx-api'
import { message } from 'antd';
import { useSelector } from 'react-redux';
//@ts-ignore
import { selectors } from 'farmx-redux-core';
import useEntities from 'components/organization/hooks/useEntities';

/**
 * The InviteUserContainer is a container component that manages the state and form submission for the InviteUser component.
 * @param {RouteComponentProps} props - The standard react-router-dom props.
 * @returns {JSX.Element}
 */
const InviteUserContainer: React.FC<RouteComponentProps> = (props:RouteComponentProps) => {
  // Destructure match from props
  const { match } = props;

  // The loading state of the invite user component
  const [loading, setLoading] = React.useState<boolean>(false);

  // The team id from the route params
  const [teamId, setTeamId] = React.useState<string | undefined>((match.params as {teamId: string | undefined}).teamId);

  const [team, setTeam] = React.useState<any>(undefined);

  const [teamsToSelect, setTeamsToSelect] = React.useState<any>([]);
  
  // State of the currently selected entity id from the redux store 
  const { selectedEntity } = useEntities();

  // Side effect to fetch the team data from the api
  React.useEffect(() => {
    const loadTeam = async () => {
      try {
        setLoading(true);
        const team = await teamApi.retrieveTeam(teamId, {});
        setTeam(team.data.data);
        setLoading(false);
      } catch (error) {
        message.error('Failed to fetch team data.');
      }
    }
    if(teamId != undefined)
      loadTeam();
  }, [teamId]);

  // if teamId is undefined we need to fetch the list of teams for the entity to select from
  React.useEffect(() => {
    const loadTeams = async () => {
      try {
        const teams = await teamApi.listTeams({
          entity_id: selectedEntity.id,
          page_size: 100,
          page_number: 1
        });
        setTeamsToSelect(teams.data.data);
      } catch (error) {
        message.error('Failed to fetch teams data.');
      }
    }
    // if((match.params as {teamId: string | undefined}).teamId == undefined)
    if(selectedEntity.id != undefined)
      loadTeams();
  }, [selectedEntity]);

 /**
   * Method to handle the form submission of inviting a user to a team.
   * @param {string} email - The email of the user to invite.
   * @param {string} role - The role assigned to the user.
   * @param {string[]} selectedTeams - The teams to which the user is being invited.
   */
  const onInviteUserToTeam = async (email: string, role: string, selectedTeams: string[]): Promise<void> => {
    setLoading(true);
    
    try {
      const roles = await roleApi.getRoles();
      const roleId = roles.data.data.find((r: { name: string, id: string }) => r.name === role)?.id;

      if (!roleId) {
        throw new Error(`Role ${role} not found.`);
      }

      const promises = selectedTeams.map((teamId: string) =>
        teamUserAccessApi.createTeamUserAccess({
          username: email,
          team_id: teamId,
          role_id: roleId
        })
      );

      await Promise.all(promises);
      message.success(`User invited to ${selectedTeams.length} ${selectedTeams.length === 1 ? 'team' : 'teams'} successfully.`);
      
      const redirectionPath = teamId ? `/organization/teams/${teamId}` : '/organization/users';
      props.history.push(redirectionPath);
    } catch (error) {
      const axiosError = error as any;
      console.error('Failed to invite user to team:', error);
      const errorMessage = axiosError.response?.data?.detail || 'An unexpected error occurred.';
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  }

  return (
    <InviteUser 
      onInviteUser={onInviteUserToTeam} 
      teamId={teamId}
      team={team}
      loading={loading}
      teamsToSelect={teamsToSelect}
    />
  )

}

export default InviteUserContainer;