import React from "react"
import { useSelector } from 'react-redux';
//@ts-ignore
import { selectors } from 'farmx-redux-core' 
import { RanchBlockSettings } from "./components/RanchBlockSettings";

interface FollowBlockSettingsProps {}

/**
 * This component is responsible for managing the Follow Block Settings layout. Each ranch has 
 * a group of blocks that are associated with it.
 * @param FollowBlockSettingsProps
 * @returns JSX.Element
 */
export const FollowBlockSettings: React.FC<FollowBlockSettingsProps> = ({}) => {  
  // Get all ranches from the redux store
  const ranches = useSelector(selectors.selectAllRanches);

  // Render the RanchBlockSettings component for each ranch
  return (
    <div>
      {(ranches as []).map((ranch) => (
        <RanchBlockSettings ranch={ranch} />
      ))}
    </div>
  )
}