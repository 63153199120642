import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Typography, Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks } from 'farmx-redux-core';
import './anomaly.less';
import { AnomalyCommentForm } from './AnomalyCommentForm';
import { AnomalyComment } from './AnomalyComment';
import { useTracking } from '../../../../helper/mixpanel';

/**
 * This is the controller component for anomaly comments feature.
 *  - It renders an "Add comment" button and modal (modal appears on button click) to allow
 *    user to add a new comment.
 *  - It renders list of all the the comments (latest comment on the top of the list)
 *  - It's the container for add, edit and delete functionality of a comment.
 *  - It displays Alert for important operations status like add, update or delete.
 */
export const AnomalyCommentsSection = ({ anomalyId }) => {
  const {
    list: comments,
    loading,
    error,
    addComment,
    editComment,
    deleteComment,
  } = hooks.useCommentList(anomalyId);
  const { t } = useTranslation();
  const tracking = useTracking();
  // visible holds the display information of add/edit modal.
  const [visible, setVisible] = useState(false);
  // showDeleteModal determines whether to display the modal for delete confirmation.
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  // comment holds the information of the comment to be updated/deleted.
  const [comment, setComment] = useState();
  const reversedList = useMemo(() => (
    comments && comments.length && [...comments].reverse()
  ), [comments]);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setVisible(false);
    setComment(null);
  }, []);

  const displayAlert = useCallback((message) => {
    setShowAlert(true);
    setAlertMessage(message);
  }, []);

  const afterAlertClose = useCallback(() => {
    setAlertType('success');
    setShowAlert(false);
  }, []);

  const onDeleteSuccess = useCallback(() => {
    setShowAlert(true);
    setAlertMessage(t('Successfully deleted comment'));
    setComment(null);
    setShowDeleteModal(false);
  }, [t]);

  const onDeleteFailure = useCallback(() => {
    setShowAlert(true);
    setAlertMessage(t('Failed to delete comment'));
    setAlertType('error');
  }, [t]);

  const onDeleteButtonClick = useCallback((commentObj) => {
    setComment(commentObj);
    setShowDeleteModal(true);
  }, []);

  const confirmDelete = useCallback(() => {
    if (comment?.id) {
      tracking.track('Delete anomaly comment', { commentId: comment.id });
      deleteComment(comment.id, t, onDeleteSuccess, onDeleteFailure);
    }
  }, [comment, deleteComment, onDeleteFailure, onDeleteSuccess, t, tracking]);

  const cancelDelete = useCallback(() => {
    setShowDeleteModal(false);
    setComment(null);
  }, []);

  const onEditButtonClick = useCallback((commentObj) => {
    setComment(commentObj);
    showModal();
  }, [showModal]);

  useEffect(() => {
    tracking.track('Viewing anomaly comment', { anomalyId });
  }, [anomalyId, tracking]);

  const modalTitle = comment ? t('Update Comment') : t('Add a Comment');

  return (
    <div className="anomaly-comments-section">
      <Button type="primary" onClick={showModal} block>
        {t('Add Comment')}
      </Button>
      <div className="comment-horizontal-line" />
      {anomalyId ? (
        <>
          {loading && (
            <div className="margin-top-10"><LoadingOutlined /></div>
          )}

          <Modal
            centered
            closable={false}
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            footer={null}
          >
            <div>
              <Typography.Title level={3}>{modalTitle}</Typography.Title>
              <AnomalyCommentForm
                anomalyId={anomalyId}
                hideModal={hideModal}
                addComment={addComment}
                displayAlert={displayAlert}
                comment={comment}
                editComment={editComment}
              />
            </div>
          </Modal>
          {showAlert && (
            <div className="padding-10">
              <Alert
                closable
                message={alertMessage}
                type={alertType || 'success'}
                showIcon
                afterClose={afterAlertClose}
              />
            </div>
          )}
          {(comments && comments.length > 0) && (
            <div className="anomaly-comment-list">
              {reversedList.map((singleComment) => (
                <AnomalyComment
                  key={singleComment.id}
                  comment={singleComment}
                  deleteComment={onDeleteButtonClick}
                  editComment={onEditButtonClick}
                />
              ))}
            </div>
          )}
          {!loading && !(comments && comments.length > 0) && (
            <div className="margin-top-10">
              <Typography.Text className="flex-row justify-content-center">
                {t('No comments yet')}
              </Typography.Text>
            </div>
          )}
          <Modal
            title={t('Confirm delete')}
            centered
            closable
            visible={showDeleteModal}
            onOk={confirmDelete}
            onCancel={cancelDelete}
          >
            {t('Are you sure you want to delete comment?')}
          </Modal>
        </>
      ) : null}
    </div>
  );
};

AnomalyCommentsSection.propTypes = {
  anomalyId: PropTypes.number.isRequired,
};
