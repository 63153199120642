export const unitsData = {
  timeOptions: [
    {
      label: '12 hour',
      id: '12_hour',
      value: '12_hour',
      name: 'units_time',
    },
    {
      label: '24 hour',
      id: '24hour',
      value: '24_hour',
      name: 'units_time',
    },
  ],
  lengthOptions: [
    {
      label: 'Feet',
      id: 'feet',
      value: 'feet',
      name: 'units_length',
    },
    {
      label: 'Meters',
      id: 'meters',
      value: 'meters',
      name: 'units_length',
    },
  ],
  volumeOptions: [
    {
      label: 'Gallons',
      id: 'gallons',
      value: 'gallons',
      name: 'units_volume',
    },
    {
      label: 'Liters',
      id: 'liters',
      value: 'liters',
      name: 'units_volume',
    },
    {
      label: 'Acre Inches',
      id: 'acre_inches',
      value: 'acre_inches',
      name: 'units_volume',
    },
    {
      label: 'Acre Feet',
      id: 'acre_feet',
      value: 'acre_feet',
      name: 'units_volume',
    },
  ],
  areaOptions: [
    {
      label: 'Acres',
      id: 'acres',
      value: 'acres',
      name: 'units_area',
    },
    {
      label: 'Hectares',
      id: 'hectares',
      value: 'hectares',
      name: 'units_area',
    },
    {
      label: 'Sq. Miles',
      id: 'square_miles',
      value: 'square_miles',
      name: 'units_area',
    },
    {
      label: 'Sq. Km',
      id: 'square_kilometers',
      value: 'square_kilometers',
      name: 'units_area',
    },
  ],
  flowRateOptions: [
    {
      label: 'GPM',
      id: 'gallons_per_minute',
      value: 'gallons_per_minute',
      name: 'units_flow_rate',
    },
    {
      label: 'LPM',
      id: 'liters_per_minute',
      value: 'liters_per_minute',
      name: 'units_flow_rate',
    },
    {
      label: 'GPH',
      id: 'gallons_per_hour',
      value: 'gallons_per_hour',
      name: 'units_flow_rate',
    },
    {
      label: 'LPH',
      id: 'liters_per_hour',
      value: 'liters_per_hour',
      name: 'units_flow_rate',
    },
  ],
  powerOptions: [
    {
      label: 'Horsepower',
      id: 'horsepower',
      value: 'horsepower',
      name: 'units_power',
    },
    {
      label: 'Kilowatts',
      id: 'kilowatts',
      value: 'kilowatts',
      name: 'units_power',
    },
  ],
  temperatureOptions: [
    {
      label: 'Fahrenheit',
      id: 'degrees_fahrenheit',
      value: 'degrees_fahrenheit',
      name: 'units_temperature',
    },
    {
      label: 'Celsius',
      id: 'degrees_celsius',
      value: 'degrees_celsius',
      name: 'units_temperature',
    },
  ],
  pressureOptions: [
    {
      label: 'PSI',
      id: 'pounds_per_square_inch',
      value: 'pounds_per_square_inch',
      name: 'units_pressure',
    },
    {
      label: 'Mega Pascal',
      id: 'mega_pascal',
      value: 'mega_pascal',
      name: 'units_pressure',
    },
  ],
  imperialSelection: {
    units_time: '12_hour',
    units_length: 'feet',
    units_volume: 'gallons',
    units_area: 'acres',
    units_flow_rate: 'gallons_per_minute',
    units_power: 'horsepower',
    units_temperature: 'degrees_fahrenheit',
    units_pressure: 'pounds_per_square_inch',
  },
  metricSelection: {
    units_time: '24_hour',
    units_length: 'meters',
    units_volume: 'liters',
    units_area: 'hectares',
    units_flow_rate: 'liters_per_minute',
    units_power: 'kilowatts',
    units_temperature: 'degrees_celsius',
    units_pressure: 'mega_pascal',
  },
};
