import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import {
  InputNumber, Radio, Switch, Typography,
} from 'antd';
import { VWCMultilineGraph } from 'components/mobile/home/VWCMultilineGraph';
import { getLimitedData } from 'helper/graphHelper';
import { displayConvertedDepth } from 'helper/depthUnitHelper';
import {
  colorOk,
} from '../../utils/colors';
import { calculateRefillPercent, calculateRefillPoint } from '../mobile/settings/settingsHelper';

const { useUnits } = hooks;
const { selectUserDepthFormat }= selectors;

const renderIsRootzonePill = (isRootzone) => (isRootzone ? (
  <span
    className="legend-pill legend-pill-root-zone"
    style={{ backgroundColor: colorOk}}
  >
    Rootzone
  </span>
) : (
  <span
    className="legend-pill legend-pill-not-root-zone"
    style={{ backgroundColor: 'grey' }}
  >
    Not Rootzone
  </span>
));

export default function EditSoilMoistureCutoffPoints({
  graphData,
  startDate,
  endDate,
  soilSensorPropertiesData,
  depthIndex,
  depthValue,
  onChange
}){
  // remove after testing
  // console.log('depthIndex', depthIndex, depthValue);
  // console.log('graphData', graphData);
  const getUserUnits = useUnits();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState('fieldCapacity');
  const soilMoistureData = graphData[`soil_moisture_${depthValue}`][0];
  const limitedDataSet = getLimitedData(soilMoistureData.data);
  const [formattedData] = useState(() => (limitedDataSet.map((d) => ({ x: d[0], y: d[1] }))));
  const [fieldCapacity, setFieldCapacity] = useState(soilMoistureData?.fieldCapacity);
  const [wiltingPoint, setWiltingPoint] = useState(soilMoistureData?.wiltingPoint);
  const [refillPoint, setRefillPoint] = useState(soilMoistureData?.refillPoint);
  const [refillPercent, setRefillPercent] = useState(soilSensorPropertiesData[`refillPercent${depthIndex}`]);
  const [isRootzone, setIsRootzone] = useState(soilMoistureData?.isRootzone);

  const refillPercentInputRef = useRef();
  const refillPointInputRef = useRef();
  const fieldCapacityInputRef = useRef();
  const wiltingPointInputRef = useRef();
  const depthFormat = useSelector(selectUserDepthFormat);

  const {color} = soilMoistureData;


  function cutOffPointValidation(wp, rp, fc) {
    if (wp < rp && wp < fc && rp < fc) {
      return true;
    }
    return false;
  }

  const onEdit = (event) => {
    const e = {
      ...event,
      id: event.id.split(depthIndex)[0],
    };
    setEditMode(e.id);
    const value = Number(e.value) / 100 || 0;
    if (e && e.id === 'fieldCapacity') {
      setFieldCapacity(value);
      adjustRefillPoint(value, Number(wiltingPoint));
    }
    if (e && e.id === 'refillPoint') {
      setRefillPoint(value);
      setRefillPercent(
        calculateRefillPercent(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'refillPercent') {
      setRefillPercent(value);
      setRefillPoint(
        calculateRefillPoint(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'wiltingPoint') {
      setWiltingPoint(value);
      adjustRefillPoint(Number(fieldCapacity), value);
    }
  };
  // remove after testing
  // console.log('Depth cutoff points', depthIndex, fieldCapacity, refillPoint, wiltingPoint);

  useEffect(() => {
    if (onChange) {
      const validPoints = cutOffPointValidation(wiltingPoint, refillPoint, fieldCapacity);
      if (validPoints) {
        const sensorProperties = {
          [`fieldCapacity${depthIndex}`]: Number(fieldCapacity).toFixed(3),
          [`refillPercent${depthIndex}`]: Number(refillPercent).toFixed(3),
          [`wiltingPoint${depthIndex}`]: Number(wiltingPoint).toFixed(3),
          [`isRootzone${depthIndex}`]: isRootzone,
        };
        onChange(sensorProperties);
      }else{
        console.log(`Invalid points set for depthIndex ${depthIndex}`);
      }
    }
  }, [depthIndex, fieldCapacity, isRootzone, onChange, refillPercent,
    refillPoint, soilSensorPropertiesData, wiltingPoint]);

  const adjustRefillPoint = (fc, wp) => {
    const midpoint = (fc + wp) / 2;
    setRefillPoint(midpoint);
    setRefillPercent(calculateRefillPercent(Number(midpoint), Number(wp), Number(fc)));
  };

  // This will be removed after testing
  // if(graphData){
  //   console.log('fieldCapacity', soilMoistureData?.fieldCapacity);
  //   console.log('wiltingPoint', soilMoistureData?.wiltingPoint);
  //   console.log('refillPoint', soilMoistureData?.refillPoint);
  // }

  const onRadioChange = (e) => {
    setEditMode(e.target.value);
  };

  const toggleIsRootzone = useCallback(() => {
    setIsRootzone((currentValue) => !currentValue);
  }, []);

  const legendColor = graphData[`soil_moisture_${depthValue}`]
  && graphData[`soil_moisture_${depthValue}`][0]
    ?graphData[`soil_moisture_${depthValue}`][0].color:null;

  // This will be removed after testing
  // console.log('formattedData', formattedData);

  return (
    <div className="edit-soil-moisture-cutoff-points">
      <div
        className="nested-individual-chart-header"
      >
        <div className="individual-chart-header">
          <span className="sensor-name">{`${soilMoistureData.sensor_name}`}</span>
          <span className="chart-header-text">Soil Moisture VWC</span>
          <span
            className="legend-pill"
            style={{ backgroundColor: legendColor || 'grey', paddingLeft: '0.5em' }}
          >
            {`${displayConvertedDepth(depthValue, getUserUnits, depthFormat)}`}
          </span>
          {renderIsRootzonePill(isRootzone)}
        </div>
      </div>
      <div
        className="edit-soil-moisture-cutoff-points-header"
      >
        <span className="edit-soil-moisture-cutoff-points-rootzone-info">
          <Switch
            style={{width: '20px'}}
            checked={isRootzone}
            onChange={toggleIsRootzone}
          />
          <Typography.Text>{t('Is Rootzone')}</Typography.Text>
        </span>
        <Radio.Group className="cutoff-points-radio" onChange={onRadioChange} value={editMode} defaultValue="fieldCapacity">
          <Radio.Button value="fieldCapacity">{t('Field Capacity')}</Radio.Button>
          <Radio.Button value="refillPoint">{t('Refill Point')}</Radio.Button>
          <Radio.Button value="wiltingPoint">{t('Wilting Point')}</Radio.Button>
        </Radio.Group>
      </div>
      <div
        style={{ backgroundColor: '#aaa', height: '200px', marginTop: '10px' }}
      >
        <VWCMultilineGraph
          showPlotLineValues
          fieldCapacity={fieldCapacity}
          wiltingPoint={wiltingPoint}
          refillPoint={refillPoint}
          editMode={[editMode]}
          onChange={onEdit}
          data={formattedData}
          chartType="line"
          options={{
            color,
            yAxis: {
              tickInterval: null,
              labels: {
                x: 0,
                y: -2,
                align: 'left',
              }
            },
            xAxis: {
              type: 'datetime',
              ordinal: false,
              gridLineWidth: 1,
              labels: {
                y: 10,
                x: 1,
                align: 'left',
              },
              alternateGridColor: 'rgba(200,200,200,0.1)',
              min: startDate?.valueOf(),
              max: endDate?.valueOf(),
              tickInterval: null,
              dateTimeLabelFormats: {},
            },
            chart: {
              marginTop: 40,
            },
          }}
        />
      </div>
      <div
        className="cutoff-points-inputs flex-row justify-content-space-between"
      >
        <div key={`fieldCapacity${depthIndex}`} className="field-capacity">
          <span>Field Capacity</span>
          <InputNumber
            size="small"
            ref={fieldCapacityInputRef}
            min={0}
            max={100}
            precision={2}
            onChange={(v) => onEdit({ id: `fieldCapacity${depthIndex}`, value: v })}
            value={`${(fieldCapacity * 100).toFixed(2)}`}
          />
          <span>
            {`% (${t('vwc')})`}
          </span>
        </div>
        <div key={`refillPercent${depthIndex}`} className="refill-percent">
          <span>Refill Percent (Point)</span>
          <div className="refill-values">
            <InputNumber
              ref={refillPercentInputRef}
              size="small"
              min={0}
              max={100}
              precision={2}
              onChange={(v) => onEdit({ id: `refillPercent${depthIndex}`, value: v })}
              value={`${(refillPercent * 100).toFixed(2)}`}
            />
            <span>
              % (
            </span>
            <InputNumber
              size="small"
              ref={refillPointInputRef}
              min={0}
              max={100}
              precision={2}
              onChange={(v) => onEdit({ id: `refillPoint${depthIndex}`, value: v })}
              value={`${(refillPoint * 100).toFixed(2)}`}
            />
            <span>
              {`%) (${t('vwc')})`}
            </span>
          </div>
        </div>
        <div key={`wiltingPoint${depthIndex}`} className="wilting-point">
          <div>{t('Wilting Point')}</div>
          <InputNumber
            size="small"
            ref={wiltingPointInputRef}
            min={0}
            max={100}
            precision={2}
            onChange={(v) => onEdit({ id: `wiltingPoint${depthIndex}`, value: v })}
            value={`${(wiltingPoint * 100).toFixed(2)}`}
          />
          <span>
            {`% (${t('vwc')})`}
          </span>
        </div>
      </div>
    </div>
  );
}

EditSoilMoistureCutoffPoints.propTypes = {
  graphData: PropTypes.shape({
    fieldCapacity: PropTypes.number,
    wiltingPoint: PropTypes.number,
    refillPoint: PropTypes.number,
  }),
  startDate: PropTypes.PropTypes.shape({}),
  endDate: PropTypes.PropTypes.shape({}),
  soilSensorPropertiesData: PropTypes.shape({}),
  depthIndex: PropTypes.number,
  depthValue: PropTypes.number,
  onChange: PropTypes.func,
};

EditSoilMoistureCutoffPoints.defaultProps = {
  graphData: {},
  startDate: null,
  endDate: null,
  soilSensorPropertiesData: null,
  depthIndex: null,
  depthValue: null,
  onChange: () => null,
};
