import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getCropStress, getStress } from '../../../../helper/plantSensorHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
} = hooks;

const {
  selectPlantSummary,
  selectSensorLoadingStatus,
} = selectors;

export function PlantSensorSummary(props) {
  const { list } = props;
  const { t } = useTranslation();
  const summary = useSelector((store) => selectPlantSummary(store, list)) || {};
  const loading = useSelector((store) => selectSensorLoadingStatus(store, list));


  const {
    cropStressSet,
    lastUpdated,
    twdAvg,
    stationNames,
    totalOnline,
  } = summary;
  const cropStress = getCropStress(cropStressSet);
  const stressValue = getStress(cropStress);
  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);

  useLoadStatus(list);

  const showNoDataInfo = (!loading && !list.length);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="leaf"
        title={t('Plant Sensors')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="leaf"
          title={t('Plant Sensors')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              value={t(stressValue.label)}
              type={stressValue.type}
            />
            <ValueUnitInfo
              value={twdAvg}
              unit="μm TWD"
              info={moment(lastUpdated).fromNow()}
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(stationNames?.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

PlantSensorSummary.defaultProps = {
  list: [],
};

PlantSensorSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
