import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sensorApi } from 'farmx-api';
import { selectors } from 'farmx-redux-core';
import { ValueUnitInfo } from './ValueUnitInfo';
import ConnectivityStateTag from '../components/ConnectivityStateTag';
import { IconTitle } from './IconTitle';
import { PlantSensorChart } from './PlantSensorChart';
import { getStress, getTwdAvg } from '../../../helper/plantSensorHelper';
import { CardLayout } from '../components/CardLayout';
import LastReportedDate from './LastReportedDate';

const { loadSensorData } = sensorApi;

export function PlantSensorListItem(props) {
  const { sensorDetails: { name, type, identifier } } = props;
  const { t } = useTranslation();
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const sensorStatus = useSelector((state) => (
    selectors.selectSensorStatus(state, type, identifier)
  ));

  if (sensorStatus === undefined) {
    console.log(' type, identifier', { type, identifier });
  }

  const {
    cropStress, id, online, twdMax, twdMin, lastUpdated, loading: sensorLoading, visible,
  } = sensorStatus || {};

  useEffect(() => {
    async function fetchGraphData() {
      const endDate = moment();
      const startDate = moment().subtract(7, 'd');
      setLoading(true);

      try {
        const res = await loadSensorData(type, id, ['twd', 'crop_stress'], startDate, endDate, true);
        const { data } = res;

        setResponse(data);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }

    if (id && !dataFetched) {
      fetchGraphData();
      setDataFetched(true);
    }
  }, [dataFetched, id, type]);

  const stressValue = getStress(cropStress);
  const avg = getTwdAvg(twdMin, twdMax);

  return (sensorLoading && visible !== false) ? null : (
    <CardLayout>
      <div className="flex-row space-between list-item-header">
        <IconTitle
          iconName="leaf"
          title={name || t('No Name')}
        />
        <ConnectivityStateTag
          isOnline={online}
        />
      </div>
      <div className="flex-row space-between margin-top-20">
        <ValueUnitInfo
          value={t(stressValue.label)}
          type={stressValue.type}
        />
        {avg && (
          <ValueUnitInfo
            value={avg}
            unit="μm TWD"
            info={moment(lastUpdated).fromNow()}
          />
        )}
      </div>
      {!sensorStatus?.online && <LastReportedDate latestDate={sensorStatus?.latestDate} /> }
      {loading && <LoadingOutlined className="absolute-center" />}
      <PlantSensorChart
        data={response}
      />
    </CardLayout>
  );
}

PlantSensorListItem.propTypes = {
  sensorDetails: PropTypes.shape({
    identifier: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};
