/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { BlockSelect } from 'farmx-web-ui';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Form } from 'antd';
import {
  useRenderReusableControls, useUpdateFormFields, useHandlers,
  useSetActionType
} from 'helper/reusableControls';
import { isMobile } from 'utils/detectDevice';
import { FORM_RULES } from '../../../constants';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import AutonomousIrrigationControls from './AutonomousIrrigationControls';
import AutonomousSprinklerControls from './AutonomousSprinklerControls';
import AutonomousDefaultControls from './AutonomousDefaultControls';
import RecuringControls from './RecurringControls';
import './ruleSettingsPage.less';

interface AutonomousControlProps {
  isEdit?: boolean;
  blocks: object[];
  renderItems?: string[];
  showDefaultControls?: boolean;
  settings?: any;
  onUpdate?: any;
  formRef?: any;
  onCancel: any;
  loading: boolean;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
};

const autonomousMethods = [
  // { value: 'unknown', label: 'Unknown' }, // This will be enabled in future
  { value: 'irrigation', label: 'Drip Irrigation' },
  { value: 'sprinkler', label: 'Sprinkler Irrigation' },
  { value: 'cooling', label: 'Sprinkler Cooling' },
];

const { selectAllCoordination } = selectors;

const { requiredField } = FORM_RULES;

const AutonomousControl: React.FC<AutonomousControlProps> = (props: any) => {
  const {
    isEdit, renderItems, showDefaultControls, settings, onUpdate, formRef,
    onCancel, blocks, loading,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();

  const [updatedInitialValues, setUpdatedInitialValues] = useState<any>({});
  const [autonomousMethod, setAutonomousMethod] = useState('irrigation');
  const [selectedBlock, setSelectedBlock] = useState<any>(null);
  const [selectedSensor, setSelectedSensor] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const { state } = location || {};
  const now = moment();
  const endDate = moment(now).add(2, 'hours');
  const { onFinish } = useHandlers(updatedInitialValues, isEdit, onUpdate);
  const { renderButton, renderSelect } = useRenderReusableControls(t);

  // This will be removed
  // Get autonomous irrigation settings config
  // const allConfig = useSelector((state) => selectAllCoordination(state)) || [];

  // To set the default autonomous method based on the config
  // const {
  //   isDripIrrigation,
  //   isSprinklerCooling, isSprinklerIrrigation
  // } = useSetActionType(allConfig, selectedBlock, form, setAutonomousMethod);

  // To update the Form with initial value
  const autoFillValues = settings || state;

  useUpdateFormFields(autoFillValues, form, now, endDate, autonomousMethod,
    setUpdatedInitialValues, setSelectedBlock, setSelectedSensor, setAutonomousMethod);

  const onChange = (value: any) => {
    setAutonomousMethod(value);
    form.setFieldsValue({ action_type: value });
    // Reset sensor selection when autonomous menthod changes
    if (form.getFieldValue('sensor')) {
      form.setFieldsValue({ sensor: undefined });
      setSelectedSensor(null);
    }
  };

  const onDelete = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setIsRecurring(updatedInitialValues?.is_recurring);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedInitialValues?.is_recurring]);

  useEffect(() => {
    if (formRef) formRef(form);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onToggleChange = (d: boolean) => { setIsRecurring(d); };

  // const fixedButtonPosition = isRecurring ? 'form-action-buttons' : '';

  return (
    <div className={`rule-setting-form ${isMobile ? 'width-100-percent' : ''} margin-inherit`}>
      <Form
        form={form}
        layout="vertical"
        name="Rule Settings"
        onFinish={(d) => onFinish(d)}
        validateMessages={validateMessages}
        initialValues={updatedInitialValues}
      >
        <Form.Item className="font-bold" name="block" label={t('Block')} rules={requiredField}>
          <BlockSelect onSelect={(d: any) => setSelectedBlock(d)} items={blocks} />
        </Form.Item>
        <Form.Item
          className="font-bold form-item-width-100"
          name="action_type"
          label={t('Autonomous Methods')}
        >
          {renderSelect(autonomousMethods, 'Select Type', autonomousMethod, onChange)}
        </Form.Item>

        {showDefaultControls && <AutonomousDefaultControls />}

        {(autonomousMethod === 'irrigation' || autonomousMethod === 'sprinkler')
         && (
         <AutonomousIrrigationControls
           selectedBlock={selectedBlock}
           initialValues={updatedInitialValues}
           setSelectedSensor={setSelectedSensor}
           selectedSensor={selectedSensor}
           form={form}
           autoFillValues={autoFillValues}
         />
         )}

        {autonomousMethod === 'cooling' && (
        <AutonomousSprinklerControls
          setSelectedSensor={setSelectedSensor}
          selectedSensor={selectedSensor}
          form={form}
          selectedBlock={selectedBlock}
          autoFillValues={autoFillValues}
        />
        )}
        <RecuringControls
          selectedBlock={selectedBlock}
          startDate={form.getFieldsValue()?.starting_time_window}
          endDate={form.getFieldsValue()?.ending_time_window}
          initialValues={updatedInitialValues}
          form={form}
          onChange={onToggleChange}
        />

        {/* Button Controls  */}
        {!isEdit && (
          <div className="flex-space-between margin-15">
            <Form.Item className="font-bold form-item-width-100">
              {renderButton('Save', 'primary', 'submit', undefined, false, undefined, loading)}
            </Form.Item>
            <Form.Item className="font-bold form-item-width-100">
              {renderButton('Cancel', undefined, undefined, onCancel, loading)}
            </Form.Item>
          </div>
        )}
        {isEdit && (
        <div className="flex-space-between margin-15">
          <Form.Item className="font-bold form-item-width-100">
            {renderButton('Update', 'primary', 'submit', undefined, false, undefined, loading)}
          </Form.Item>
          <Form.Item className="font-bold form-item-width-100">
            {renderButton('Delete', undefined, undefined, onDelete, loading)}
          </Form.Item>
        </div>
        )}
      </Form>
      <ConfirmDeleteModal
        scheduleId={settings?.schedule?.id}
        showModal={showModal}
        setShowModal={setShowModal}
        onUpdate={onUpdate}
      />
    </div>
  );
};

AutonomousControl.defaultProps = {
  showDefaultControls: true,
  isEdit: false,
};

export default AutonomousControl;
