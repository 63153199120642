import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { selectors } from 'farmx-redux-core';
import moment from 'moment';
import { SensorDetailBody } from '../map/SensorDetailBody';
import SensorDetailHeader from './SensorDetailHeader';
import SensorDataChart from '../../graph/SensorDataChart';
import { isMobile } from '../../../utils/detectDevice';

const {
} = selectors;

const days = 15;

const SensorDetail = (props) => {
  const {
    type, identifier, location, blockId, id,
  } = props;

  // Memoing for fixing rerender issue for now
  // Need to remove this memo hook after refactoring
  // code from container level
  // refactor_suggestions: this chart component can be sent
  // through props as there may be charts specific to parent
  const waterPressureChart = useMemo(() => (
    <div className="margin-top-10">
      {type === 'water_pressure'
      && (
      <SensorDataChart
        sensor={{
          type,
          identifier,
          id,
        }}
        variables={['water_pressure']}
        startDate={moment().subtract(days, 'days')}
        endDate={moment()}
      />
      )}
    </div>
  ),
  [id, identifier, type]);

  return (
    <div className={`sensor-detail ${isMobile ? 'background-white mobile-list-item padding-10' : 'background-white'}`}>
      <SensorDetailHeader
        type={type}
        location={location}
        blockId={blockId}
        identifier={identifier}
      />
      <SensorDetailBody sensorProperties={{
        type, identifier, blockId, idDb: id,
      }}
      />
      {waterPressureChart}
    </div>
  );
};

SensorDetail.defaultProps = {
  type: '',
  identifier: '',
  location: '',
  id: '',
};

SensorDetail.propTypes = {
  blockId: PropTypes.number.isRequired,
  type: PropTypes.string,
  identifier: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SensorDetail;
