import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { FaEdit, FaTimes } from 'react-icons/fa';
import '../map/map.css';

export const EditableHeader = ({ headerText, onClick, editable }) => (
  <>
    <div
      className="editable-elem-width editable-header"
      onClick={() => onClick()}
      onKeyPress={() => onClick()}
      role="button"
      tabIndex="0"
    >
      <Typography>{headerText}</Typography>
      {editable && <FaEdit className='edit-icon' onClick={onClick} />}
    </div>
  </>
);

EditableHeader.defaultProps = {
  onClick: null,
  headerText: null,
  editable: false,
};

EditableHeader.propTypes = {
  onClick: PropTypes.func,
  headerText: PropTypes.string,
  editable: PropTypes.bool,
};
