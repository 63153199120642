import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { SensorSelect } from 'farmx-web-ui';
import { FORM_RULES } from '../../../constants';
import './ruleSettingsPage.less';

const { requiredField } = FORM_RULES;

interface SensorControlProps {
  sensor: any;
  form: object | any;
  setSelectedSensor: any;
  formName?: any;
  formLabel?: any;
  sensorTypes?: any;
  selectedRanch?: number;
}

const SensorControl: React.FC<SensorControlProps> = (props: any) => {
  const {
    sensor, form, setSelectedSensor, formName, formLabel,
    sensorTypes, selectedRanch,
  } = props;
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t(formLabel || 'Reference Soil Moisture Sensor')}
      name={formName || 'sensor'}
      rules={requiredField}
      className="font-bold width-100"
    >
      <SensorSelect
        sensorTypeConstraint={sensorTypes || ['pixl_soil']}
        selectedFilters={selectedRanch && [{
          type: 'ranch', value: selectedRanch, op: 'AND', noEdit: false, noDelete: false
        }]}
        sensorType={sensor?.reference_sensor_type}
        sensorIdentifier={sensor?.reference_sensor_identifier}
        sensorId={sensor?.reference_sensor_id}
        onChange={(d: any) => {
          const sensorControl = {
            reference_sensor_id: d?.id,
            reference_sensor_type: d?.type,
            reference_sensor_identifier: d?.identifier
          };
          if (formName) {
            form.setFieldsValue({ [formName]: d?.id ? sensorControl : undefined });
          } else {
            // eslint-disable-next-line react/prop-types
            form.setFieldsValue({ sensor: d?.id ? sensorControl : undefined });
          }
          setSelectedSensor(sensorControl);
        }}
      />
    </Form.Item>
  );
};

export default SensorControl;
