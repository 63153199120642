import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { selectors } from 'farmx-redux-core';
import { scheduleApi } from 'farmx-api';
import { irrigationStatus } from '../constants';

const dateStart = moment().toDate();
const dateEnd = moment().add(7, 'days').toDate();

/**
 * It returns status based on following conditions:
 *
 * Irrigation Scheduled: If the events has data.
 *
 * Irrigating now: If the events has such data where current date lies in
 * between start and stop date.
 *
 * Not irrigating: If the events is empty
 */
const getIrrigationDetails = (events, timeFormat, t) => {
  if (events && events.length) {
    const irrigatingEvent = events.find((event) => (
      moment(event.start_date).isSameOrBefore(moment(dateStart))
      && moment(event.stop_date).isAfter(moment(dateStart))
    ));
    if (irrigatingEvent) {
      return {
        description: `${moment(irrigatingEvent.stop_date).fromNow(true)} ${t('remaining')}`,
        status: irrigationStatus.IRRIGATING,
        title: 'Irrigating Now',
      };
    }
    const [firstEvent] = events;
    // Get time from the scheduled event
    const { startDate, stopDate } = firstEvent || {};

    // Get date from the current event
    const sDate = moment(firstEvent.start_date?.split('T')[0]).format('YYYY-MM-DD');
    const eDate = moment(firstEvent.stop_date?.split('T')[0]).format('YYYY-MM-DD');

    return {
      description: `${moment(sDate).format('ddd')}, ${moment(startDate).format(timeFormat)}
       - ${moment(eDate).format('ddd')}, ${moment(stopDate).format(timeFormat)}`,
      status: irrigationStatus.SCHEDULED,
      title: 'Irrigation Scheduled',
    };
  }
  return {
    description: '',
    status: irrigationStatus.NOT_SCHEDULED,
    title: 'Irrigation Not Scheduled',
  };
};
export const useIrrigationStatus = (blockId) => {
  const { t } = useTranslation();
  // Decide the time format by the user settings
  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));
  const timeFormat = userTimeFormat === '24_hour' ? 'HH:mmA' : 'h:mmA';
  const scheduledEvents = useSelector(
    (state) => selectors.selectCurrentBlockScheduledEvents(state, blockId),
  );

  const [statusDetails, setStatusDetails] = useState({
    description: '',
    status: irrigationStatus.NOT_SCHEDULED,
    title: 'Irrigation Not Scheduled',
  });
  const [loading, setLoading] = useState(false);
  const loadSchedule = useCallback(async () => {
    setLoading(true);
    const response = await scheduleApi.retrieveBlockRenderedIrrigationEvents({
      blockId, dateStart, dateEnd,
    });
    // Update response data with scheduled date time
    const { data } = response || {};
    const updatedEvents = data?.map((d) => {
      const filteredEvents = scheduledEvents?.filter((s) => s?.id === d?.id);
      const [firstEvent] = filteredEvents;
      const obj = { ...d };
      if (firstEvent) {
        obj.startDate = firstEvent.start_date;
        obj.stopDate = firstEvent.stop_date;
      }
      return obj;
    });

    setLoading(false);
    const irrigationDetails = getIrrigationDetails(updatedEvents, timeFormat, t);
    setStatusDetails(irrigationDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule]);

  return [statusDetails, loading];
};
