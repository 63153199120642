import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { FaEdit, FaBan, FaUndo } from 'react-icons/fa';

export default function AnomalyControls(props) {
  const {
    presMode, createAnomaly, setCreateAnomaly, selectedFeature, setIsAnomalyControlEnabled,
  } = props;
  const { t } = useTranslation();
  const isAnomaly = presMode === 'anomaly';
  const { type } = createAnomaly || {};

  return (
    <>
      {isAnomaly && selectedFeature && type !== 'edit' ? (
        <Button
          id="new-anomaly"
          size="large"
          type="primary"
          icon={<FaEdit />}
          style={{ width: '40px', marginTop: '10px' }}
          onClick={() => {
            setCreateAnomaly({ visible: true, type: 'edit' });
            setIsAnomalyControlEnabled(true);
          }}
        />
      ) : null}
      {isAnomaly && type === 'show-undo' ? (
        <Button
          id="new-anomaly"
          size="large"
          type="primary"
          icon={<FaUndo />}
          style={{ width: '40px', marginTop: '10px' }}
          onClick={() => setCreateAnomaly({ visible: true, type: 'undo' })}
        />
      ) : null}
      {isAnomaly
        && (type === 'show-cancel' || type === 'show-undo' || type === 'undo') ? (
          <Button
            size="large"
            type="ghost"
            icon={<FaBan />}
            style={{ marginTop: '10px' }}
            onClick={() => {
              setCreateAnomaly({ visible: false, type: 'cancel' });
              setIsAnomalyControlEnabled(false);
            }}
            className="anomaly-drawing-cancel-button"
          >
            {t('Cancel')}
          </Button>
        ) : null}
    </>
  );
}

AnomalyControls.propTypes = {
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  selectedFeature: PropTypes.shape(),
  setIsAnomalyControlEnabled: PropTypes.func,
};

AnomalyControls.defaultProps = {
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  selectedFeature: null,
  setIsAnomalyControlEnabled: null,
};
