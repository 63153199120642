import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Form, Input, Switch, Typography, DatePicker
} from 'antd';
import {
  useRenderReusableControls,
  getRepeatWeekDaysString, convertToWeekdayObject
} from 'helper/reusableControls';
import { WeekDays } from 'components/mobile/schedule/components/WeekDays';
import './ruleSettingsPage.less';

const repeatDayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((d) => ({ value: d, label: d}));

const repeatEndOptions = [{ value: 'never', label: 'Never'},
  { value: 'date', label: 'On'},
  { value: 'count', label: 'After'}];

const getRepeatWeekOptions = (repeatInterval: any) => ([{
  value: 'daily',
  label: repeatInterval === 1
    ? 'day' : 'days'
}, {
  value: 'weekly',
  label: repeatInterval === 1
    ? 'week' : 'weeks'
}]);

const initializeStartDate = () => moment().startOf('day').add(moment().hour() + 1, 'hours');
const startDateTimeAllowed = (min: any) => (new Date((new Date()).getTime() + (min * 60000)));
const disabledDate = ((current: any) => current && current < moment().add('month'));
const minutesAllowedFromNow = 0;
const currentWeekday = moment().isoWeekday();
const futureEndDate = moment().add(1, 'years');

const schedule = {
  recurring_interval: 1,
  recurring_period: 'weekly',
  recurring_ends_on: null,
  recurring_ends_after_counts: null,
  recurring_end_type: false,
  is_recurring: false,
};

interface RecurringControlProps {
  selectedBlock: any;
  startDate: any;
  endDate: any;
  initialValues: any;
  form: any;
  onChange?: any;
}

const RecurringControls: React.FC<RecurringControlProps> = (props: any) => {
  const {
    selectedBlock, startDate, endDate, initialValues, form, onChange
  } = props;
  const { t } = useTranslation();
  const defaultStartDate = startDate || initializeStartDate();

  const defaultValues = {
    starting_time_window: moment(startDate || defaultStartDate).toISOString(),
    ending_time_window: moment(endDate || defaultStartDate.clone().add(2, 'hours')).toISOString(),
    ...schedule,
    ...initialValues?.schedule,
    recurring_ends_on: initialValues?.recurring_ends_on && moment(initialValues?.recurring_ends_on),
  };

  const repeatWeekdays = convertToWeekdayObject(defaultValues?.recurring_weekdays, currentWeekday);
  const { renderSelect } = useRenderReusableControls(t);
  const [isRecurring, setIsRecurring] = useState(defaultValues?.is_recurring || false);
  const [repeatFrequency, setRepeatFrequency] = useState(defaultValues.recurring_period);
  const [weekDays, setWeekDays] = useState(repeatWeekdays);
  const [repeatEndType, setRepeatEndType] = useState(defaultValues.recurring_end_type || 'never');
  const [repeatEndDate, setRepeatEndDate] = useState(defaultValues.recurring_ends_on
    || futureEndDate);
  const [repeatEndCount, setRepeatEndCount] = useState(defaultValues
    .recurring_ends_after_counts || 3);
  const [repeatInterval, setRepeatInterval] = useState(defaultValues.recurring_interval || 1);
  const [isEndDateCalendarOpen, setEndDateIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (defaultValues?.is_recurring) setIsRecurring(defaultValues?.is_recurring);
    if (repeatWeekdays) setWeekDays(repeatWeekdays);
    if (defaultValues.recurring_period) setRepeatFrequency(defaultValues.recurring_period);
    if (defaultValues.recurring_ends_on) {
      setRepeatEndDate(defaultValues.recurring_ends_on);
      setRepeatEndType('date');
    }
    if (defaultValues.recurring_ends_after_counts) {
      setRepeatEndCount(defaultValues.recurring_ends_after_counts);
      setRepeatEndType('count');
    }
    if (defaultValues.recurring_interval) setRepeatInterval(defaultValues.recurring_interval);
    if (defaultValues.recurring_end_type) setRepeatEndType(defaultValues.recurring_end_type);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues?.is_recurring]);

  const onRepeatIntervalChange = (d: any) => {
    setRepeatInterval(d);
  };

  const onRepeatFrequencyChange = (d: any) => {
    setRepeatFrequency(d);
  };

  const onRepeatEndChange = (d: any) => {
    setRepeatEndType(d);
  };

  const onRepeatDateChange = (sDate: any) => {
    if (sDate.toDate().getTime() < startDateTimeAllowed(minutesAllowedFromNow)) {
      setRepeatEndDate(initializeStartDate());
    } else {
      setRepeatEndDate(sDate);
    }
    setEndDateIsCalendarOpen(false);
  };

  useEffect(() => {
    const updatedRecurringControl = {
      ...defaultValues,
      starting_time_window: defaultValues?.starting_time_window,
      ending_time_window: defaultValues?.ending_time_window,
      recurring_period: repeatFrequency,
      recurring_interval: repeatInterval,
      recurring_end_type: repeatEndType,
      ...(repeatFrequency === 'weekly' && {
        recurring_weekdays: getRepeatWeekDaysString(weekDays),
      }),
      ...(repeatEndType === 'date' && {
        recurring_ends_on: moment(repeatEndDate).toISOString(),
      }),
      ...(repeatEndType === 'count' && {
        recurring_ends_after_counts: repeatEndCount,
        recurring_ends_on: null,
      }),
      ...(repeatFrequency === 'daily' && {
        recurring_weekdays: '',
      }),
      ...(repeatEndType === 'never' && {
        recurring_ends_on: null,
        recurring_ends_after_counts: null,
      }),
      is_recurring: isRecurring,
    };

    form.setFieldsValue({ schedule: updatedRecurringControl });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurring, repeatEndCount, repeatEndDate, repeatEndType,
    repeatFrequency, repeatInterval, selectedBlock, weekDays]);

  return (
    <div className={isRecurring ? 'margin-bottom-70' : ''}>
      <Form.Item name="schedule">
        <div>
          <div className="margin-bottom-10 flex-row justify-content-space-between">
            <Typography.Text className="font-bold font-size-16">
              {`${t('Repeat Schedule')}`}
            </Typography.Text>
            <Switch
              className="form-"
              checkedChildren={t('Yes')}
              unCheckedChildren={t('No')}
              onChange={(d: any) => {
                setIsRecurring(d);
                if (onChange) onChange(d);
              }}
              defaultChecked={isRecurring}
              checked={isRecurring}
            />
          </div>
          {isRecurring && (
          <div>
            <div className="margin-top-10">
              <Typography.Text>{`${t('Repeat every')}`}</Typography.Text>
              {renderSelect(repeatDayOptions, undefined, repeatInterval,
                onRepeatIntervalChange, repeatFrequency === 'daily', undefined,
                'repeat-interval margin-left-10')}

              {renderSelect(getRepeatWeekOptions(repeatInterval), undefined,
                repeatFrequency, onRepeatFrequencyChange, undefined, undefined,
                'repeat-frequency margin-left-10')}
            </div>

            {repeatFrequency === 'weekly'
              && <WeekDays weekDays={weekDays} setWeekDays={(d: any) => setWeekDays(d)} t={t} />}

            <div className="margin-top-10 repeat-end-form-items">
              <Typography.Text className="font-bold font-size-16">{t('Ends')}</Typography.Text>
              {renderSelect(repeatEndOptions, undefined, repeatEndType, onRepeatEndChange,
                undefined, undefined, 'repeat-end margin-left-10')}

              {repeatEndType === 'date' && (
              <DatePicker
                format="ddd, MMM D YYYY"
                className="repeat-end-date margin-left-5 "
                size="large"
                allowClear={false}
                inputReadOnly
                value={repeatEndDate}
                open={isEndDateCalendarOpen}
                onClick={() => setEndDateIsCalendarOpen(!isEndDateCalendarOpen)}
                suffixIcon={null}
                disabledDate={disabledDate}
                onChange={onRepeatDateChange}
              />
              )}

              {repeatEndType === 'count' && (
              <Input
                className="margin-left-10 repeat-end-count"
                type="number"
                suffix={t('Occurrences')}
                value={repeatEndCount}
                onChange={({ target: { value } }: any) => setRepeatEndCount(Number(value))}
              />
              )}
            </div>
          </div>
          )}
        </div>
      </Form.Item>
    </div>
  );
};

export default RecurringControls;
