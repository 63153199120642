import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { FaEdit } from 'react-icons/fa';
import '../map/map.css';

export const MapHeader = ({ headerText, onClick, editable }) => (
  <div className="flex-row map-header">
    <Typography className="map-header-tooltip">
      {headerText}
    </Typography>
    {editable && <FaEdit style={{ marginLeft: '10px', marginTop: '5px' }} onClick={onClick} />}
  </div>
);

MapHeader.defaultProps = {
  onClick: null,
  headerText: null,
  editable: false,
};

MapHeader.propTypes = {
  onClick: PropTypes.func,
  headerText: PropTypes.string,
  editable: PropTypes.bool,
};
