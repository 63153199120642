import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import isEqual from 'react-fast-compare';
import { MapSettings } from './MapSettings';
import { isMobile } from '../../../utils/detectDevice';

const { selectMapShowLabels } = selectors;

export default function MapSettingModal(props) {
  const {
    dispatchMap, dispatchMap1, dispatchMapArg, stateMap,
    stateMap1, stateMapArg, layerOption, selMapShowSoilType,
    onChange,
  } = props;
  const selMapShowLabels = useSelector(
    (state) => selectMapShowLabels(state), isEqual,
  );
  const isBlockPlantView = stateMap?.plantsView === 'block';

  function toggleBothImageryPanel(visible) {
    if (stateMap.isImageryPanelVisible && !stateMap1.isImageryPanelVisible && visible) {
      dispatchMap1({
        type: 'setIsImageryPanelVisible',
        payload: visible,
      });
    }
    if (!stateMap.isImageryPanelVisible && stateMap1.isImageryPanelVisible && visible) {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: visible,
      });
    }
  }

  return (
    <MapSettings
      selMapShowSoilType={selMapShowSoilType}
      selMapShowLabels={selMapShowLabels}
      isImageryDataVisible={stateMap.selectedImageryData.visible}
      showSideBySideMap={stateMap.sideBySideMapVisible && stateMap1.sideBySideMapVisible}
      onChange={(v) => {
        if (v?.type) {
          dispatchMap1({
            type: 'setSideBySideMapVisible',
            payload: v?.visible,
          });
          dispatchMap({
            type: 'setSideBySideMapVisible',
            payload: v?.visible,
          });
          dispatchMap1({
            type: 'setSelectedImageryData',
            payload: {
              visible: false,
              data: undefined,
            },
          });
          dispatchMap({
            type: 'setSelectedImageryData',
            payload: {
              visible: false,
              data: undefined,
            },
          });
        } else if (!v) {
          if (onChange) onChange('fullScreen');
          dispatchMap({
            type: 'setIsImageryPanelVisible',
            payload: v,
          });
          dispatchMap1({
            type: 'setIsImageryPanelVisible',
            payload: v,
          });
          dispatchMap1({
            type: 'setSelectedImageryData',
            payload: {
              visible: v,
              data: undefined,
            },
          });
          dispatchMap({
            type: 'setSelectedImageryData',
            payload: {
              visible: v,
              data: undefined,
            },
          });
          dispatchMap1({
            type: 'setSideBySideMapVisible',
            payload: v,
          });
          dispatchMap({
            type: 'setSideBySideMapVisible',
            payload: v,
          });
          dispatchMap({
            type: 'setSelectedDroneImagery',
            payload: {
              visible: false,
              data: undefined,
            },
          });
        } else if (v) {
          dispatchMap({
            type: 'setSelectedImageryData',
            payload: {
              visible: v,
              data: stateMap.selectedImageryData.data,
            },
          });
          dispatchMap1({
            type: 'setSelectedImageryData',
            payload: {
              visible: v,
              data: stateMap1.selectedImageryData.data,
            },
          });
          dispatchMapArg({
            type: 'setIsImageryPanelVisible',
            payload: v,
          });
        }
        if (!stateMap.isBottomSheetVisible) {
          dispatchMap({
            type: 'setIsBottomSheetVisible',
            payload: v,
          });
        }
      }}
      onClick={(d) => {
        dispatchMap({ type: 'setLayersControl', payload: d });
        if (!isMobile) toggleBothImageryPanel(d?.visible);
        if (d?.type === 'soil') {
          dispatchMapArg({
            type: 'setShowLayer',
            payload: {
              ...stateMapArg.showLayer,
              soil: {
                left: {
                  visible: d?.side === 'left'
                    ? d?.visible : stateMapArg.showLayer.soil.left.visible,
                },
                right: {
                  visible: d?.side === 'right'
                    ? d?.visible : stateMapArg.showLayer.soil.right.visible,
                },
              },
            },
          });
        } else {
          dispatchMapArg({
            type: 'setShowLayer',
            payload: { ...stateMapArg.showLayer, ...d },
          });
        }
      }}
      displayLayerOptions={layerOption}
      selected={stateMap.layersControl}
      isBlockPlantView={isBlockPlantView}
    />
  );
}

MapSettingModal.propTypes = {
  dispatchMap: PropTypes.func,
  dispatchMap1: PropTypes.func,
  dispatchMapArg: PropTypes.func,
  stateMap: PropTypes.shape(),
  stateMap1: PropTypes.shape(),
  stateMapArg: PropTypes.shape(),
  layerOption: PropTypes.shape(),
  selMapShowSoilType: PropTypes.bool,
  onChange: PropTypes.func,
};

MapSettingModal.defaultProps = {
  dispatchMap: null,
  dispatchMap1: null,
  dispatchMapArg: null,
  stateMap: {},
  stateMap1: {},
  stateMapArg: {},
  layerOption: {},
  selMapShowSoilType: true,
  onChange: null,
};
