import React from 'react'
import PropTypes from "prop-types";
import { Typography } from 'antd'


const NoDataText = (props) => {
    const { message } = props;
    return (
        <Typography.Text strong type='secondary'>{message}</Typography.Text>
    )
}

export default NoDataText

NoDataText.defaultProps = {
    message: "No data",
};

NoDataText.propTypes = {
    message: PropTypes.string,
};