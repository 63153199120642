import React, {
  useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AIR_HUMIDITY } from 'components/hooks/graph';
import Moment from 'moment';
import {
  getYminMax
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_AIR_HUMIDITY } from './constants';

export default function AirHumidityChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();

  const globalYMinMax = yMinMaxMap && yMinMaxMap[AIR_HUMIDITY]?yMinMaxMap[AIR_HUMIDITY]:{min: null, max: null};

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0) && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const customConfig = useCallback((configObj, seriesData) => {
    if(configObj?.series?.[0]){
      const yMinMax = getYminMax(seriesData);
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(AIR_HUMIDITY, yMinMax.min * multiplier, yMinMax.max * multiplier);
      const seriesOptions = {...configObj?.series?.[0]};
      seriesOptions.name = 'Air Humidity (%)';
      return {...configObj, series: [seriesOptions]};
    }
    return configObj;
  }, [updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_AIR_HUMIDITY}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Air Humidity'), units: t('%'), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

AirHumidityChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
};

AirHumidityChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
