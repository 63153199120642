import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Modal, notification, Rate,
} from 'antd';
import {
  FaGrin,
  FaSmile,
  FaMeh,
  FaFrown,
  FaFrownOpen,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import calculatedHeightForMobile from 'farmx-web-ui/src/components/helper';
import { submitUserFeedback, sentryInit } from '../../../helper/common';
import './Feedback.less';
import { isMobile } from '../../../utils/detectDevice';

const { selectUserSettings, selectLoginUserInfo } = selectors;
const layout = {
  labelCol: {
    span: isMobile ? 8 : 11,
  },
  wrapperCol: {
    span: isMobile ? 16 : 24,
  },
};

const listHeight = calculatedHeightForMobile();

export function Feedback(props) {
  const { onClick } = props;

  const { t } = useTranslation();
  const [modal, setModalState] = useState(true);
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const userSettings = useSelector((state) => selectUserSettings(state));

  // Init sentry
  sentryInit();

  function onFinish(values) {
    const eventId = Sentry.captureEvent(Sentry);

    const feedBackObj = values;
    feedBackObj.event_id = eventId;
    feedBackObj.name = `${userInfo.username}:${userInfo.user_id}`;
    feedBackObj.email = userSettings.email;

    submitUserFeedback(feedBackObj, (response) => {
      if (response.ok) {
        setModalState(false);
        notification.success({
          message: t('Succesfully submitted feedback'),
        });
      } else {
        setModalState(false);
        notification.error({
          message: t(response.statusText || 'Failed to submit feedback'),
        });
      }
    });
    if (onClick) onClick(false);
  }

  function callOnClick(d) {
    if (d) setModalState(false);
    if (onClick) onClick(false);
  }

  // Emoji for rating
  const customIconsForRating = {
    5: <FaGrin />,
    4: <FaSmile />,
    3: <FaMeh />,
    2: <FaFrown />,
    1: <FaFrownOpen />,
  };

  return (
    <div className="flex-column">
      {modal
        ? (
          <Modal
            className={`feedback-modal-fullscreen ${!isMobile ? 'feedback-modal-desktop ' : ''}`}
            style={isMobile ? { height: `${listHeight}px` } : undefined}
            centered
            visible={modal}
            onCancel={callOnClick}
            footer={null}
            closable
            maskClosable={false}
            destroyOnClose
            zIndex={2000}
            title={!isMobile ? (
              <div className="flex-center feedback-modal-header-desktop capitalize-bold">
                {t('Farmx Web App Feedback')}
              </div>
            ) : undefined}
          >
            <Form {...layout} name="nest-messages" onFinish={onFinish}>
              {isMobile && (
              <div className="flex-center feedback-modal-header capitalize-bold">
                {t('Farmx Mobile App Feedback')}
              </div>
              )}
              {/* This will be replaced with proper image */}
              {/* <Form.Item><img src="" alt="image" /></Form.Item> */}
              <Form.Item
                name="score"
                label={t('Please rate your experience')}
                className={isMobile ? 'capitalize-bold feedback-ratings'
                  : 'flex-column capitalize-bold feedback-ratings'}
              >
                <Rate
                  className="rate-emoji"
                  defaultValue={0}
                  character={({ index }) => customIconsForRating[index + 1]}
                />
              </Form.Item>
              <Form.Item
                name="comments"
                label={t('Additional Comments')}
                className={isMobile ? 'capitalize-bold'
                  : 'flex-column capitalize-bold feedback-comments-margin'}
                rules={[
                  {
                    type: 'string',
                    required: true,
                  },
                ]}
              >
                <Input.TextArea placeholder={t('Ex: Good!')} />
              </Form.Item>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="capitalize-bold"
                  block
                >
                  {t('Submit Feedback')}
                </Button>
              </div>
            </Form>
          </Modal>
        )
        : null}
    </div>
  );
}

Feedback.propTypes = {
  onClick: PropTypes.func,
};

Feedback.defaultProps = {
  onClick: () => null,
};
