import { Modal, notification } from 'antd';
import React, {
  useEffect, useState, useRef, useCallback,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { sensorApi } from 'farmx-api';
import { selectors, actions } from 'farmx-redux-core';
import { useDispatch, useSelector } from 'react-redux';
import { getColors, getDepthVariables, getSoilMoistureDepthVariable } from 'helper/graphHelper';
import { isMobile } from 'react-device-detect';
import EditSoilMoistureCutoffPoints from './EditSoilMoistureCutoffPoints';
import EditSoilPropertiesLoader from '../contentLoaders/EditSoilPropertiesLoader';

const { loadSensorData, getSoilSensorProperties } = sensorApi;
const { loadSensorStatus } = actions;

const getSoilCutoff = (responseData, sensorStatus, getColor) => {
  const data = { ...responseData };

  try {
    if(sensorStatus){
      // old logic. TODO remove after testing
      // const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
      // Object.keys(data).forEach((dataKey) => {
      //   // remove after testing
      //   // console.log('getColor[dataKey]', getColor(dataKey));
      //   const depthIndex = dataKey.split('soil_moisture_')[1];
      //   const depthNum = depthIndex / depthSize;
      //   // remove after testing
      //   // console.log('depthNum', depthNum, depthIndex, depthSize);
      //   if(data[dataKey][0]){
      //     data[dataKey][0].fieldCapacity = sensorStatus[`fieldCapacity${depthNum}`];
      //     data[dataKey][0].refillPoint = sensorStatus[`refillPoint${depthNum}`];
      //     data[dataKey][0].wiltingPoint = sensorStatus[`wiltingPoint${depthNum}`];
      //     data[dataKey][0].isRootzone = sensorStatus[`isRootzone${depthNum}`];
      //     data[dataKey][0].depthIndex = Number(depthNum);
      //     data[dataKey][0].color = getColor(dataKey);
      //   }
      // });

      // new logic
      if(sensorStatus.depthCount > 0){
        for(let depthIndex = 1; depthIndex <= sensorStatus.depthCount; depthIndex+=1){
          const depth = sensorStatus[`depth${depthIndex}`];
          const dataKey = `soil_moisture_${depth}`;
          if(data[dataKey][0]){
            data[dataKey][0].fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
            data[dataKey][0].refillPoint = sensorStatus[`refillPoint${depthIndex}`];
            data[dataKey][0].wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
            data[dataKey][0].isRootzone = sensorStatus[`isRootzone${depthIndex}`];
            data[dataKey][0].depthIndex = Number(depthIndex);
            data[dataKey][0].color = getColor(dataKey);
          }
        }
      }
    }
  } catch(e) {
    console.log('Error', e);
    return data;
  }
  return data;
};

export default function EditSoilSensorProperties({
  sensor,
  startDate,
  endDate,
  onCancel,
  depthIndex,
  onSave,
  chartData
}){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState(chartData);
  const [cutOffPoints, setCutOffPoints] = useState(null);
  const [soilSensorPropertiesData, setSoilSensorPropertiesData] = useState(null);
  const { type, id, identifier} = sensor;
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  );
  const [loading, setLoading] = useState(false);
  const editedConfig = useRef(null);

  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);


  const onClickSave = useCallback((data) => {
    // remove after testing
    // console.log('ON SAVE ::: ', data);
    const {
      type, id
    } = sensor || {};
    sensorApi.updateSoilSensorProperties({ data, type, id }).then(() => {
      onCancel();
      // m
      // setLoading(false);
      notification.success({
        message: t('Successfully updated soil-properties'),
      });
      onSave();
    }).catch(() => {
      // onCancel();
      // setLoading(false);
      notification.error({
        message: t('Error in updating soil-properties'),
      });
    });
  });

  const depthVariables = useMemo(() => getDepthVariables(sensorStatus, 'moisture'), [sensorStatus]);

  // remove after testing
  // if(graphData) console.log('graphData entries', Object.entries(graphData));
  // console.log('soilSensorPropertiesData', soilSensorPropertiesData);
  // console.log('sensorStatus', sensorStatus);
  // console.log('cutOffPoints', cutOffPoints);


  useEffect(() => {
    editedConfig.current = soilSensorPropertiesData;
  }, [soilSensorPropertiesData]);

  // load sensor status
  useEffect(() => {
    dispatch(loadSensorStatus({
      type,
      identifier,
    }));
  }, [dispatch, id, identifier, type]);

  // load soil moisture for all depths
  // remove console logs after testing
  useEffect(() => {
    async function fetchGraphData() {
      // remove after testing
      // console.log('calling soil moisture data.....');
      const variable = depthIndex?[getSoilMoistureDepthVariable(depthIndex, sensorStatus)]:depthVariables;
      // remove after testing
      // console.log('variable', variable);
      setLoading(true);
      try {
        const res = await loadSensorData(type, id, variable, startDate,
          endDate, true);
        // remove after testing
        // console.log('soilMoistureResponse', res);
        const { data } = res;
        setGraphData(data);
        setLoading(false);
      } catch(e) {
        console.log('error', e);
        setLoading(false);
      }
    }

    if (id && type && soilSensorPropertiesData && !graphData) {
      fetchGraphData();
    }
  }, [depthIndex, depthVariables, endDate, graphData, id,
    sensorStatus, soilSensorPropertiesData, startDate, type]);

  useEffect(() => {
    if(graphData && sensorStatus){
      const soilCutoffPoints = getSoilCutoff(graphData, sensorStatus, getColor);
      setCutOffPoints(soilCutoffPoints);
    }
  }, [getColor, graphData, sensorStatus]);

  useEffect(() => {
    if (!type || !id) return;
    getSoilSensorProperties({ type, id }).then((response) => {
      if (response.status === 200 || response.status === 204) {
        const { data } = response || { data: {} };
        setSoilSensorPropertiesData(data);
      }
    });
  }, [type, id]);

  function customOnChange(sensorProperties) {
    editedConfig.current = {
      ...editedConfig.current,
      ...sensorProperties
    };
  }

  return (
    <Modal
      title={(
        <div style={{
          textAlign: 'center',
        }}
        >
          {t('Edit Soil Sensor Properties')}
        </div>
      )}
      wrapClassName="edit-cutoff-points-modal"
      centered
      open
      maskClosable={false}
      onCancel={() => {
        // refresh.current = true;
        onCancel();
      }}
      onOk={() => {
        // refresh.current = true;
        // onCancel();
        onClickSave(editedConfig.current);
      }}
      okText={loading?' ':'Save'}
      width={900}
      confirmLoading={loading}
      style={isMobile?{
        marginTop: '60px',
        maxHeight: '100vh',
        overflowY: 'hidden'
      }
        :{
          maxHeight: '100vh',
          overflowY: 'hidden'
        }}
      bodyStyle={isMobile?{
        maxHeight: '80vh',
        overflowY: 'scroll'
      }
        :{
          maxHeight: '85vh',
          overflowY: 'scroll'
        }}
    >
      {/* loop for moisture graph data of each depth  */}
      <div>
        {(cutOffPoints && soilSensorPropertiesData)
          ?(
            Object.entries(cutOffPoints).map(([key, value]) => (
              <div key={`edit-${key}`} className="graph-list">
                {(value[0] && value[0]?.depthIndex)
                  ?(
                    <EditSoilMoistureCutoffPoints
                      graphData={cutOffPoints}
                      startDate={startDate}
                      endDate={endDate}
                      soilSensorPropertiesData={soilSensorPropertiesData}
                      depthIndex={value[0].depthIndex}
                      depthValue={Number(key.split('soil_moisture_')[1])}
                      onChange={customOnChange}
                    />
                  )
                  : null}
              </div>
            ))
          ):(
            <>
              <div key="edit-loader-1" className="graph-list">
                <EditSoilPropertiesLoader />
              </div>
              <div key="edit-loader-2" className="graph-list">
                <EditSoilPropertiesLoader />
              </div>
            </>
          )}
      </div>
    </Modal>
  );
}


EditSoilSensorProperties.defaultProps = {
  sensor: null,
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  depthIndex: null,
  onCancel: () => null,
  onSave: () => null,
  chartData: null
};

EditSoilSensorProperties.propTypes = {
  sensor: PropTypes.shape(),
  startDate: null,
  endDate: null,
  depthIndex: PropTypes.number,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  chartData: PropTypes.shape(),
};
