import React from 'react'
import { Skeleton } from 'antd';
import { CardLayout } from '../components/CardLayout';



const SensorSummaryLoader = () => {
    return (
        <CardLayout>
            <Skeleton paragraph={{rows: 2}} active />
        </CardLayout>
    )
}

export default SensorSummaryLoader