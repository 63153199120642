import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { farmApi } from 'farmx-api';
import { ETC } from 'components/hooks/graph';
import Moment from 'moment';
import {
  getDailyAverage,
  getYminMax
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_ET0 } from './constants';

export default function EtcChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();
  const [kc, setKc] = useState(1);

  useEffect(() => {
    async function getRanchSummary(ranchId){
      const result = await farmApi.getRanchSummary(ranchId);
      return result.data;
    }
    getRanchSummary(sensor.ranch)
      .then((data) => {
        if(data && data.kc){
          setKc(data.kc);
        }
      });
  }, [sensor]);

  const globalYMinMax = yMinMaxMap && yMinMaxMap[ETC]?yMinMaxMap[ETC]:{min: null, max: null};

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) { return { min: globalYMinMax.min, max: globalYMinMax.max}; }
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const customConfig = useCallback((configObj, seriesData) => {
    if(configObj?.series?.[0]){
      const yMinMax = getYminMax(seriesData);
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(ETC, yMinMax.min * multiplier, yMinMax.max * multiplier);
      const dailYAvgData = getDailyAverage(configObj?.series?.[0].data, kc);
      return {
        ...configObj,
        series: [{
          ...configObj?.series?.[0],
          data: dailYAvgData,
          tooltip: {
            valueDecimals: 2
          },
          name: 'ETC (in)'
        }]
      };
    }
    return configObj;
  }, [kc, updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_ET0}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('ETC'), units: t('in'), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

EtcChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
    ranch: PropTypes.number,
  }),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
};

EtcChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
