import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ValueUnitInfo } from '../list/ValueUnitInfo';
import { getConnectivityLabel } from '../../../helper/listHelper';
import { CardLayout } from '../components/CardLayout';
import { useRootzoneValue } from '../list/ListCards/useRootzoneValue';
import { installState } from '../../../constants';

const {
  selectPressureSummary,
  selectAllSensorsForBlockIdsByType,
  selectSoilSummary,
} = selectors;

export function VWCAndPressureDetails(props) {
  const { blockIds } = props;
  const { t } = useTranslation();
  const [sensorsData, setSensorsData] = useState({});

  const stateFromStore = useSelector((store) => store);

  useEffect(() => {
    const sensors = selectAllSensorsForBlockIdsByType(stateFromStore, {
      blockIds,
      installState: installState.INSTALLED,
    });
    setSensorsData(sensors);
  }, [blockIds, stateFromStore]);

  const list = [
    ...(sensorsData.aquacheck_soil || []),
    ...(sensorsData.pixl_soil || []),
  ];
  const pressureData = sensorsData.water_pressure || [];
  const summary = useSelector((store) => selectSoilSummary(store, list)) || {};
  const pressureSummary = useSelector((store) => selectPressureSummary(store, pressureData)) || {};
  const {
    vwcRootzone,
    percentAvailable,
    volumeAvailable,
    onlineCount,
    units,
  } = summary;

  const {
    onlineCount: pressureOnlineCount,
    totalCount,
    waterPressure,
  } = pressureSummary;
  const selectedMetrics = 'vwc';

  const stateTag = useMemo(() => (
    getConnectivityLabel(onlineCount, list.length, t)
  ), [onlineCount, list, t]);

  const pressureStateTag = useMemo(() => (
    getConnectivityLabel(pressureOnlineCount, totalCount, t)
  ), [pressureOnlineCount, t, totalCount]);

  const [rootzoneValue, rootzoneValueLabel] = useRootzoneValue({
    percent: percentAvailable,
    volume: volumeAvailable,
    selectedMetrics,
    vwcRootzone,
    units,
  });

  const valueUnitInfoType = useMemo(() => (
    stateTag.type === 'online' ? 'primary' : 'disabled'),
  [stateTag.type]);

  const pressureValueUnitInfoType = useMemo(() => (
    pressureStateTag.type === 'online' ? 'primary' : 'disabled'),
  [pressureStateTag.type]);

  return (
    <CardLayout>
      <div className="recommendation-detail-card2-top">
        <ValueUnitInfo
          type={valueUnitInfoType}
          value={rootzoneValue}
          unit={rootzoneValueLabel}
          info={t('root zone avg')}
        />
        <ValueUnitInfo
          value={waterPressure}
          unit="PSI"
          info={`${pressureData.length} ${t('sensors')}`}
          type={pressureValueUnitInfoType}
        />
      </div>
    </CardLayout>
  );
}

VWCAndPressureDetails.defaultProps = {
  blockIds: [],
};

VWCAndPressureDetails.propTypes = {
  blockIds: PropTypes.arrayOf(PropTypes.number),
};
