import React from 'react';
import PropTypes from 'prop-types';
import { AnomalyCommentsSection } from './AnomalyCommentsSection';
import './anomaly.less';

export const AnomalyDetails = (props) => {
  const { anomalyId } = props;

  return (
    <div className="anomaly-details">
      <AnomalyCommentsSection anomalyId={anomalyId} />
    </div>
  );
};

AnomalyDetails.propTypes = {
  anomalyId: PropTypes.number.isRequired,
};
