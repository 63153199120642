import React, { useState, useEffect } from "react";
import { Spin, Transfer } from "antd";
import type { TransferItem } from 'antd/lib/transfer';
import { isMobile } from 'react-device-detect';

interface Ranch {
  id: number;
  name: string;
}

interface RanchSelectionFormProps {
  selectedRanches: Ranch[];
  availableRanches: Ranch[];
  onRanchSelectionUpdate: (selectedRanches: number[]) => Promise<boolean>;
  disabled: boolean;
}

/**
 * RanchSelectionForm is a functional presentation component used to display a form for selecting ranches for a team.
 * @param {Ranch[]} selectedRanches - The selected ranches for the team
 * @param {Ranch[]} availableRanches - The available ranches for the entity
 * @param {(selectedRanches: number[]) => Promise<boolean>} onRanchSelectionUpdate - The method to handle the ranch selection update
 * @param {boolean} disabled - The disabled status of the form
 * @returns 
 */
export const RanchSelectionForm: React.FC<RanchSelectionFormProps> = ({
  selectedRanches,
  availableRanches,
  onRanchSelectionUpdate,
  disabled
}) => {
  const [loading, setLoading] = useState(false);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);

  // This effect updates targetKeys whenever selectedRanches changes.
  useEffect(() => {
    setTargetKeys(selectedRanches.map(ranch => ranch.id.toString()));
  }, [selectedRanches]);

  // Create a combined data source for the Transfer component
  const [transferData, setTransferData] = useState([]);

  useEffect(() => {
    if (availableRanches === undefined || selectedRanches === undefined) return;
    
    const combinedData = [...availableRanches, ...selectedRanches];
    const dataMap = new Map<number, TransferItem>();
  
    combinedData.forEach(ranch => {
      dataMap.set(ranch.id, {
        key: ranch.id.toString(),
        title: ranch.name,
        description: ranch.name
      });
    });
    //@ts-ignore
    setTransferData(Array.from(dataMap.values()));
  }, [availableRanches, selectedRanches]);
  

  const onChange = async (newTargetKeys: string[]) => {
    const selectedRanchIds = newTargetKeys.map(key => parseInt(key));
    setLoading(true);
    const success = await onRanchSelectionUpdate(selectedRanchIds);
    if (!success) {
      setTargetKeys(selectedRanches.map(ranch => ranch.id.toString()));  // Revert on failure
    }
    setLoading(false);
  };

  // Custom filter function for case-insensitive search
  const filterOption = (inputValue: string, option: any) => {
    return option.title.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <Transfer
        showSearch
        disabled={disabled}
        dataSource={transferData}
        titles={['Available', 'Selected']}
        targetKeys={targetKeys}
        onChange={onChange}
        render={item => item.title}
        listStyle={isMobile ? {width: '100%'} : { width: '50%', height: 300} }
        filterOption={filterOption} 
        className="custom-transfer"
      />
    </Spin>
  );
}
