import React from 'react';
import PropTypes from 'prop-types';
import { FeatureGroup } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { selectors, actions } from 'farmx-redux-core';
import { Map as MapComponent } from 'farmx-web-ui';
import { notification } from 'antd';
import { isMobile } from '../../../utils/detectDevice';
import {
  getLayerBounds, createAnomalyData, createBlockAnomaly,
  editBlockAnomaly, loadBlockAnomalies,
} from '../../../helper/mapHelper';
import MapFeatureGroup from './MapFeatureGroup';

const {
  setRanchMobile, setRanch,
  setBlockMobile, setBlocks,
} = actions;

const {
  selectUserAreaFormat,
  selectMapShowSoilType,
} = selectors;

export default function MapWrapper(props) {
  const {
    dispatchMap, dispatchMapArg, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId,
    ranchGeoJSON, blocksGeoJSON, createAnomaly, setCreateAnomaly,
    setModal, mapFeatureGroupRef, mapFeatureGroupRef1, isLeft, stateMap, stateMap1,
    setLayerOption, setMapSettingModal, modal, formValues, setIsLoading,
    mapRefElem, controlButtons, activeScreen, dispatchMap1,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const areaFormat = useSelector(selectUserAreaFormat);
  const isPlants = presMode === 'plants';
  const isAnomaly = presMode === 'anomaly';
  const isSatelliteLeft = stateMap.selectedSatelliteDataSource === 'satellite';
  const isSatelliteRight = stateMap1.selectedSatelliteDataSource === 'satellite';
  const colorValue = stateMap.plantsOption;
  const isBlockSelected = selectedObjFromState?.type === 'block';
  // If only entity is selected consider it as all ranch
  const isAllRanch = ['All Ranches', 'entity'].includes(selectedObjFromState?.type);

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );
  const paddingBottomMobile = stateMap.isBottomSheetVisible ? 200 : 140;
  const mapBoxPadding = {
    top: isMobile ? 140 : 240,
    left: isMobile ? 0 : 240,
    bottom: isMobile ? paddingBottomMobile : 240,
    right: isMobile ? 0 : 240,
  };
  const { features } = blocksGeoJSON || {};
  const {
    row_spacing: rowSpacing,
    plant_spacing: plantSpacing,
  } = features?.filter((d) => d?.id === blockId)?.[0]?.properties || {};

  const plantObj = { rowSpacing, plantSpacing };

  function onRanchBlockClick(id, type) {
    if (type === 'block' && !isMobile) dispatch(setBlocks([Number(id)]));
    if (type === 'ranch' && !isMobile) dispatch(setRanch(Number(id)));
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: true,
      },
    });
  }

  function onChangeFinish() {
    dispatchMap({ type: 'setAnomalyLayerData', payload: null });
    setIsLoading(false);
    setModal({ ...modal, visible: false });
    setCreateAnomaly({ ...createAnomaly, visible: false });
    loadBlockAnomalies([blockId], (response) => {
      if (response) {
        dispatchMap({
          type: 'setAnomalyGeoJSON',
          payload: response,
        });
      }
    });
  }

  function onDrawOrEditAnomaly(drawObj) {
    if (drawObj?.type === 'create') {
      const layerObj = drawObj?.layer ? drawObj.layer : stateMap.anomalyLayerData;
      if (layerObj) {
        const formData = createAnomalyData(formValues.current, layerObj);
        if (formData?.block) {
          createBlockAnomaly(formData, (success) => {
            if (success) {
              notification.success({
                message: t('Anomaly Created Successfully!'),
              });
              formValues.current.name = null;
            } else {
              notification.error({
                message: t('Failed to Create anomaly'),
              });
            }
            onChangeFinish();
          });
        }
      }
    } else if (drawObj?.type === 'edit') {
      editBlockAnomaly(drawObj.layer, (success) => {
        if (success) {
          notification.success({
            message: t('Anomaly Edited Successfully!'),
          });
        } else {
          notification.error({
            message: t('Failed to Edit anomaly'),
          });
        }
        onChangeFinish();
      });
    } else if (drawObj?.type === 'show-undo') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
    } else if (drawObj?.type === 'stop-draw') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
      dispatchMap({ type: 'setAnomalyLayerData', payload: drawObj?.layer });
    } else if (drawObj?.type === 'show-cancel') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
    } else {
      onChangeFinish();
    }
  }

  function renderFeatureGroup() {
    return (
      <MapFeatureGroup
        dispatchMapArg={dispatchMap}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        presMode={presMode}
        selectedFeature={isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature}
        createAnomaly={createAnomaly}
        setCreateAnomaly={setCreateAnomaly}
        stateMapArg={stateMapArg}
        setModal={setModal}
        isImageryPanelVisible={stateMap.isImageryPanelVisible}
        isBottomSheetVisible={stateMap.isBottomSheetVisible}
        selectedObjFromState={selectedObjFromState}
        isAnomalyControlEnabled={isAnomalyControlEnabled}
        ranchId={ranchId}
        blockId={blockId}
        anomalyGeoJSON={stateMap.anomalyGeoJSON}
        sideBySideMapVisible={isLeft ? stateMap.sideBySideMapVisible
          : stateMap1.sideBySideMapVisible}
        mapFeatureGroupRef={mapFeatureGroupRef}
        mapFeatureGroupRef1={mapFeatureGroupRef1}
        isLeft={isLeft}
        setLayerOption={setLayerOption}
        setMapSettingModal={setMapSettingModal}
        controlButtons={controlButtons}
        selectedKey={stateMap.plantsOption}
        activeScreen={activeScreen}
        stateMap={stateMap}
        stateMap1={stateMap1}
      />
    );
  }

  return (
    <MapComponent
      mapRefElem={mapRefElem}
      exposeZoomChange={(zoom) => {
        dispatchMap({
          type: 'setZoomSensorsVisible',
          payload: zoom,
        });
        dispatchMap1({
          type: 'setZoomSensorsVisible',
          payload: zoom,
        });
      }}
      ranchesGeoJSON={ranchGeoJSON}
      blocksGeoJSON={blocksGeoJSON}
      recenterGeoJSON={isLeft ? stateMap.recenterGeoJSON : stateMap1.recenterGeoJSON}
      presentationMode={presMode}
      mapBoxPadding={mapBoxPadding}
      onClickRanch={(id) => {
        dispatch(setRanchMobile(Number(id)));
        onRanchBlockClick(id, 'ranch');
      }}
      onClickBlock={(d) => {
        if (d?.type) {
          if (isPlants && d?.type === 'point') {
            dispatchMap({
              type: 'setSelectedFeatureWithType',
              payload: { feature: d, showBottomSheet: true },
            });
          }
          if (d?.data?.id && d?.type === 'block' && !isAnomaly && !isPlants) {
            dispatch(setBlockMobile([Number(d?.data?.id)]));
            onRanchBlockClick(d?.data?.id, 'block');
          }
        }
      }}
      isDataLoading={stateMap.isLoading || stateMap1.isLoading}
      isShowingImagery={{
        side: 'left',
        ...(isSatelliteLeft ? stateMap.selectedImageryData : stateMap.selectedDroneImagery),
        droneDataType: stateMap.selectedDroneDataType,
        otherSide: {
          side: 'right',
          ...(isSatelliteRight ? stateMap1.selectedImageryData : stateMap1.selectedDroneImagery),
          droneDataType: stateMap1.selectedDroneDataType,
        },
        isSatellite: isSatelliteLeft,
        isSatelliteRight,
      }}
      anomalyGeoJSON={isAnomaly ? getLayerBounds(isLeft
        ? stateMap.selectedFeature : stateMap1.selectedFeature)
        : undefined}
      showSideBySideMap={stateMap.sideBySideMapVisible}
      layersControl={{
        ...stateMapArg.showLayer,
        soil: { left: stateMap.showLayer.soil.left, right: stateMap.showLayer.soil.left },
      }}
      createAnomaly={createAnomaly}
      onDrawAnomaly={onDrawOrEditAnomaly}
      userAreaUnit={areaFormat}
      plantsGeoJSON={isBlockSelected && isPlants
        && { ...stateMap.plantsGeoJSON, blockId, plantObj }}
      isBottomSheetVisible={stateMap.isBottomSheetVisible}
      selectedColorValue={colorValue}
      showPlants={stateMap.plantsView !== 'block' ? stateMap.showPlants : false}
      isBlockType={isBlockSelected}
      isAllRanch={isAllRanch}
      isSpinnerForMap={false} // This will be removed once PR#582 gets merged
      selMapShowSoilType={selMapShowSoilType}
    >
      {!isAllRanch ? renderFeatureGroup(dispatchMapArg, stateMapArg) : null}
      {isAllRanch && isAnomaly ? (
        <FeatureGroup
          ref={(r) => {
            if (r && isLeft && isAnomaly) {
              mapFeatureGroupRef.current = r;
            }
          }}
        />
      ) : null}
    </MapComponent>
  );
}

MapWrapper.propTypes = {
  dispatchMapArg: PropTypes.func,
  dispatchMap: PropTypes.func,
  dispatchMap1: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  presMode: PropTypes.string,
  selectedObjFromState: PropTypes.shape(),
  isAnomalyControlEnabled: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  ranchGeoJSON: PropTypes.shape(),
  blocksGeoJSON: PropTypes.shape(),
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setModal: PropTypes.func,
  mapFeatureGroupRef: PropTypes.shape(),
  mapFeatureGroupRef1: PropTypes.shape(),
  isLeft: PropTypes.bool,
  stateMap: PropTypes.shape(),
  stateMap1: PropTypes.shape(),
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
  modal: PropTypes.shape(),
  formValues: PropTypes.shape(),
  setIsLoading: PropTypes.func,
  mapRefElem: PropTypes.func,
  controlButtons: PropTypes.shape(),
  activeScreen: PropTypes.func.isRequired,
};

MapWrapper.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  dispatchMapArg: null,
  dispatchMap: null,
  dispatchMap1: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  ranchGeoJSON: null,
  blocksGeoJSON: null,
  mapFeatureGroupRef: {},
  mapFeatureGroupRef1: {},
  isLeft: true,
  setLayerOption: null,
  setMapSettingModal: null,
  stateMap: {},
  stateMap1: {},
  modal: null,
  formValues: {},
  setIsLoading: null,
  mapRefElem: null,
  controlButtons: null,
};
