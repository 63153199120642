import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../mobile/components/PageHeader';
import { Breadcrumb, Button, Card, Form, Input, Skeleton, Avatar, Table, Space, Checkbox } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FarmxWebApiTypes } from 'types/api_types';

interface UserDetailProps {
  user: any;
  handleViewTeam: (record: any) => void;
  pagedResult: FarmxWebApiTypes.PaginatedResult<any>;
  tableSpec: any;
  onPageChange: (page: number, pageSize: number) => void;
  onChangeEntityAdmin: (e: any) => void;
  isUserEntityAdmin: boolean;
  currentUserRoles: string[] | undefined;
  loading: boolean;
}

/**
 * UserDetail is a functional presentational component used to display the details of a User, including their assigned teams and roles.
 * @param {any} props.user - The user to display - contains the high level user property data - eg, username, first name, last name.
 * @param {(record: any) => void} props.handleViewTeam - The method to handle viewing a team.
 * @param {any} props.pagedResult - The paged result of the team user access query.
 * @param {any} props.tableSpec - The spec for fetching the team user access data.
 * @param {(page: number, pageSize: number) => void} props.onPageChange - The method to handle the page change. 
 * @param {(e: any) => void} props.onChangeEntityAdmin - The method to handle the change of the entity admin checkbox.
 * @param {boolean} props.isUserEntityAdmin - A boolean to determine if the user is an entity admin.
 * @param {string[] | undefined} props.currentUserRoles - The roles of the current user. This can be used to enable or disable the entity admin checkbox.
 * @param {boolean} props.loading - A boolean to determine if the component is loading.
 * @returns {JSX.Element} The UserDetail component.
 */
export const UserDetail: React.FC<UserDetailProps> = ({
    user, 
    handleViewTeam,
    pagedResult,
    tableSpec,
    onPageChange,
    onChangeEntityAdmin,
    isUserEntityAdmin,
    currentUserRoles,
    loading
  }) => {
  const { t } = useTranslation();
  
  const teamTableColumns = [
    {
      title: 'Team Name',
      dataIndex: 'team',
      key: 'team',
      render: (team: any) => team?.name
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: any) => role?.name
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: any) => {
        if(record.role.name === 'Entity Admin') {
          return(
            <div></div>
          )
        }
        return (
          <Space size="middle">
            <Button type="link" onClick={() => {handleViewTeam(record)}}>View</Button>
          </Space>
        )
      },
    },
  ]

  return (
    <div className="white-bg padding-20 flex-column-container organization-table">
      <div className="flex-title-container">
        <div className="flex-title-content">
          <PageHeader
            showBack={true}
            title={t('Edit User')}
            toPath="/organization/users"
          />
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/organization/teams">{t('Users')}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edit</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="scrollable-content">
        {user == undefined || loading ? <Skeleton active /> : (
          <div>
            <Card title="" bordered={false}>
              <Form
                name="update_user"
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '120px', 
                  height: '120px',
                  border: '1px solid #d9d9d9', 
                  borderRadius: '5px', 
                  margin: '10px auto',
                }}>
                  <Avatar size={100} icon={<UserOutlined />} />
                </div>
                <Checkbox 
                  checked={isUserEntityAdmin} 
                  onChange={onChangeEntityAdmin}
                  disabled={currentUserRoles == undefined || !currentUserRoles.includes('Entity Admin')}
                >
                  Organization Admin
                </Checkbox>
                <br/>     
                <br/>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input disabled defaultValue={user.username} placeholder="User Name" />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input disabled defaultValue={user.firstName} placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input disabled defaultValue={user.lastName} placeholder="Last Name" />
                </Form.Item>
                <Form.Item>
                  <Button disabled type="primary" htmlType="submit" className='submit-button'>
                    Save
                  </Button>
                </Form.Item>
              </Form>      
            </Card>
            <hr color='lightgrey' />
            <h3>Teams</h3>
            <Table
              columns={teamTableColumns}
              sticky
              dataSource={pagedResult?.data}
              rowKey="id"
              pagination={{
                current: tableSpec?.page_number,
                pageSize: tableSpec?.page_size,
                total: pagedResult?.count,
                onChange: onPageChange,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
              }}
            />
          </div>      
        )}
      </div>
  </div>
  )
}
