import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, notification } from 'antd';
import { deleteAutonomousControlConfig } from '../../../helper/settingsHelper';
import './ruleSettingsPage.less';

interface ConfirmDeleteModalProps {
  scheduleId: any;
  showModal: boolean;
  onUpdate: any;
  setShowModal: any;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (props: any) => {
  const {
    scheduleId, showModal, onUpdate, setShowModal
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onConfirmDelete = () => {
    setLoading(true);
    deleteAutonomousControlConfig(scheduleId, (success: boolean, response: any) => {
      if (success) {
        setShowModal(false);
        notification.success({
          message: `${t('Deleted Successfully')}!`,
        });
        if (onUpdate) onUpdate({ delete: response });
      } else {
        notification.error({
          message: t('Failed to delete'),
          description: response,
        });
        setShowModal(false);
      }
      setLoading(false);
    });
  };

  return (
    <Modal
      title={t('Confirm')}
      open={showModal}
      onOk={() => onConfirmDelete()}
      onCancel={() => setShowModal(false)}
      okText={t('Delete')}
      cancelText={t('Cancel')}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <p>{`${t('Are you sure you want to delete')}?`}</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
