import React, { useEffect } from 'react';
import { Select, Typography, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions, hooks, selectors } from 'farmx-redux-core';
import { isMobile } from 'utils/detectDevice';
import { useTranslation } from 'react-i18next';

interface EntitySelectProps {
  collapse?: () => void;
}

const {
  setRanchBlockSelection, setRanchBlockData, setUrlLoad
} = actions;

const { useEntities } = hooks;

const { selectAllNewRanchIds } = selectors;

function EntitySelect(props: EntitySelectProps) {
  const { collapse } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    entities, selectedEntity, activeEntityId, loadingState
  } = useEntities();
  const { loading } = loadingState;
  const { t } = useTranslation();

  const options = entities?.map((entity) => ({ label: entity.name, value: entity.id }));

  const allRanchIds: Array<Number> = useSelector(selectAllNewRanchIds);

  // To make entity with single ranch selected by default
  useEffect(() => {
    if (allRanchIds?.length === 1) {
      const [ranchId] = allRanchIds;
      dispatch(setRanchBlockSelection({ type: 'ranch', value: ranchId }));
    }
  }, [dispatch, allRanchIds]);

  const onChange = (entityId: number) => {
    dispatch(setUrlLoad(false));
    dispatch(setRanchBlockData(entityId));
    const obj = { key: entityId, value: entityId, type: 'entity' };
    dispatch(setRanchBlockSelection(obj));


    // In nested path navigate to parent path whenever entity is changed
    const currentPath = window.location.pathname;
    const parentPath = currentPath.split('/')[1];
    if (parentPath !== currentPath) {
      history.push(`/${parentPath}`);
    }

    // Collapse sidebar when new entity is selected (mobile)
    if (isMobile && collapse) {
      collapse();
    }
  };

  // Show loading skeleton when fetching data
  if (loading && !entities?.length) {
    return (
      <div style={{ margin: '5px 7px' }} className="mobile-side-menu-item">
        <Skeleton.Input active />
      </div>
    );
  }
  // Return null if no entities or active entity is available
  if (!entities?.length || !activeEntityId || !selectedEntity) {
    return null;
  }

  // display name, If only one entity is present
  if (entities.length === 1 && selectedEntity) {
    return (
      <Typography.Text
        ellipsis
        strong
        className="mobile-side-menu-item"
        style={{
          padding: isMobile ? '0' : '0 6px 0 24px',
          fontSize: '18px',
          color: '#3c61b4',
        }}
      >
        {selectedEntity.name}
      </Typography.Text>
    );
  }

  // Default entity select dropdown for multiple entities
  return (
    <div style={{ margin: '5px 7px' }}>
      <h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>
        {t('Select Organization')}
        :
      </h4>
      <Select
        showSearch
        size={isMobile ? 'large' : 'middle'}
        placement="bottomLeft"
        style={{ width: '100%' }}
        onChange={onChange}
        optionFilterProp="children"
        value={activeEntityId || undefined}
        options={options}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        virtual={options.length > 9}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div style={{ maxHeight: 200, overflowY: 'hidden' }}>
            {menu}
          </div>
        )}
      />
    </div>
  );
}

export default EntitySelect;
