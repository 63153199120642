import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import './CommonLoader.less';
import { isMobile } from 'utils/detectDevice';

// Calculate the width dynamically for both desktop and mobile view
const screenWidth = window.innerWidth;
const width = isMobile ? screenWidth - 65 : 817;
const height = isMobile ? 300 : 350;
const radioButtonWidth = isMobile ? width * 0.24 : width * 0.14;
const toggleButtonWidth = isMobile ? width * 0.2 : width * 0.09;
const bottomStartAndEnd = isMobile ? width * 0.2 : width * 0.25;
const bottomMiddle = isMobile ? width * 0.3 : width * 0.42;

const EditSoilPropertiesLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* Rect for header section */}
    <rect x="0" y="0" rx="3" ry="3" width={width} height="34" />

    {/* Rect for toggle button */}
    <rect x="0" y="36" rx="3" ry="3" width={toggleButtonWidth} height="32" />

    {/* Rect for radio button section */}
    <rect
      x={radioButtonWidth * (isMobile ? 1.15 : 4)}
      y="36"
      rx="3"
      ry="3"
      width={radioButtonWidth}
      height="34"
    />
    <rect
      x={(radioButtonWidth * (isMobile ? 2.15 : 5)) + (isMobile ? 2 : 8)}
      y="36"
      rx="3"
      ry="3"
      width={radioButtonWidth}
      height="34"
    />
    <rect
      x={(radioButtonWidth * (isMobile ? 3.15 : 6)) + (isMobile ? 4 : 15)}
      y="36"
      rx="3"
      ry="3"
      width={radioButtonWidth}
      height="34"
    />

    {/* Rect for graph section */}
    <rect x="0" y="72" rx="3" ry="3" width={width} height="200" />

    {/* Rect for bottom row */}
    <rect x="0" y="274" rx="3" ry="3" width={bottomStartAndEnd} height="30" />
    <rect
      x={bottomMiddle - (isMobile ? -20 : 97)}
      y="274"
      rx="3"
      ry="3"
      width={bottomMiddle}
      height="30"
    />
    <rect
      x={bottomMiddle * (isMobile ? 2.7 : 1.83)}
      y="274"
      rx="3"
      ry="3"
      width={bottomStartAndEnd}
      height="30"
    />
  </ContentLoader>
);

export default EditSoilPropertiesLoader;
