import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import {
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from 'react-icons/fa';
import { EventLogLevelIconProps } from './types';

const iconSize = 24;

const EventLogLevelIcon = ({ value }: EventLogLevelIconProps) => {
  const { t } = useTranslation();
  if (!value) return null;
  function getIcon() {
    if (value === 'info') {
      return <FaInfoCircle color="grey" size={iconSize} />;
    }
    if (value === 'warning') {
      return <FaExclamationTriangle color="orange" size={iconSize} />;
    }
    if (value === 'error') {
      return <FaTimesCircle color="red" size={iconSize} />;
    }
    return null;
  }

  const icon = getIcon();
  if (!icon) return null;
  return (
    <Tooltip title={value ? t(value) : ''} placement="bottom">
      {icon}
    </Tooltip>
  );
};

export default EventLogLevelIcon;
