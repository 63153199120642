import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

export function IconTitle(props) {
  const { iconName, icon, title } = props;

  function renderIcon() {
    if (typeof iconName === "string") {
      return <i className={`icon ${iconName} icon-${iconName}`} />;
    }
    return icon;
  }

  return (
    <span className="icontitle-container">
      <Typography.Title className="flex-row align-center" level={4}>
        {renderIcon()}
        {title}
      </Typography.Title>
    </span>
  );
}

IconTitle.defaultProps = {
  icon: null,
  iconName: null,
  title: '',
};

IconTitle.propTypes = {
  icon: PropTypes.node,
  iconName: PropTypes.string,
  title: PropTypes.string,
};
