import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, notification } from 'antd';
import AnomalyCreateEditFormElements from './AnomalyCreateEditFormElements';
import { editBlockAnomaly, loadBlockAnomalies } from '../../../../helper/mapHelper';

export default function AnomalyCreateForm(props) {
  const {
    type, setModal, setIsAnomalyControlEnabled, setCreateAnomaly,
    isLoading, formValues, modal, blockId, selectedFeature, dispatchMap,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  function onFormSubmit(obj, formType) {
    setModal({ ...modal, visible: false });
    if (formType !== 'edit') {
      setCreateAnomaly({ visible: true, type: 'create' });
      const newObj = { blockId, ...obj };
      formValues.current = newObj;
    } else {
      const newObj = {
        ...selectedFeature.properties,
        anomaly_severity: obj.anomaly_severity,
      };
      editBlockAnomaly(newObj, (success) => {
        if (success) {
          notification.success({
            message: t('Severity Edited Successfully!'),
          });
        } else {
          notification.error({
            message: t('Failed to Edit Severity'),
          });
        }
        loadBlockAnomalies([blockId], (response) => {
          if (response) {
            dispatchMap({
              type: 'setAnomalyGeoJSON',
              payload: response,
            });
          }
        });
        dispatchMap({
          type: 'setSelectedFeatureWithType',
          payload: {
            selectedFeature: undefined,
            bottomSheetType: undefined,
            showBottomSheet: false,
          },
        });
        formValues.current = { type: 'missing', description: null, anomaly_severity: 'moderate' };
      });
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        type: 'missing',
        description: '',
      }}
      onFinish={(d) => onFormSubmit(d, type)}
      scrollToFirstError
    >
      <AnomalyCreateEditFormElements
        type={type}
        form={form}
        formValues={formValues}
      />
      <div className="flex-row">
        <Form.Item className="form-margin form-button-margin form-button-width">
          <Button
            type="ghost"
            htmlType="submit"
            onClick={() => { setModal({ visible: false }); setIsAnomalyControlEnabled(false); }}
            block
          >
            {t('Cancel')}
          </Button>
        </Form.Item>
        <Form.Item className="form-margin form-button-margin
         form-button-width form-button-right-align"
        >
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            {type === 'edit' ? t('Save') : t('Create')}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

AnomalyCreateForm.propTypes = {
  type: PropTypes.string,
  setModal: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  isLoading: PropTypes.bool,
  formValues: PropTypes.shape(),
  modal: PropTypes.shape(),
  blockId: PropTypes.number,
  selectedFeature: PropTypes.shape(),
  dispatchMap: PropTypes.func,
};

AnomalyCreateForm.defaultProps = {
  type: null,
  setModal: null,
  setCreateAnomaly: null,
  setIsAnomalyControlEnabled: null,
  isLoading: false,
  formValues: {},
  modal: null,
  blockId: null,
  selectedFeature: null,
  dispatchMap: false,
};
