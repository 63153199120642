import React from 'react';
import { matchPath } from 'react-router';
import { Layout, PageHeader, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Link, Switch, Route, Redirect, withRouter, useLocation,
} from 'react-router-dom';
import {
  FaUser,
  FaLock,
  FaPaperPlane,
  FaRuler,
  FaLanguage,
  FaWater,
  FaBroadcastTower,
} from 'react-icons/fa';
import { useWindowSize, AdminRoute } from 'farmx-web-ui';
import './SettingsPage.css';
import { authService } from 'farmx-api';
import { ROUTES } from '../routes';

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

function SettingsPage() {
  const { t } = useTranslation();
  const rootPath = '/settings';
  const location = useLocation();
  const size = useWindowSize();
  function renderMenu() {
    return (
      <Menu
        className="settings-menu"
        theme="light"
        selectedKeys={[getMatchedKey(location)]}
        mode="inline"
        inlineCollapsed={size.width < 800}
      >
        <Menu.Item key={ROUTES.ACCOUNT.key}>
          <Link to={ROUTES.ACCOUNT.path}>
            <FaUser className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Profile')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.CHANGE_PASSWORD.key}>
          <Link to={ROUTES.CHANGE_PASSWORD.path}>
            <FaLock className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Change Password')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.NOTIFICATION.key}>
          <Link to={ROUTES.NOTIFICATION.path}>
            <FaPaperPlane className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Notifications')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.UNITS.key}>
          <Link to={ROUTES.UNITS.path}>
            <FaRuler className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Units Of Measure')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.SENSORS.key}>
          <Link to={ROUTES.SENSORS.path}>
            <FaBroadcastTower className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Sensors')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.IRRIGATION_SETTINGS.key}>
          <Link to={ROUTES.IRRIGATION_SETTINGS.path}>
            <FaWater className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Block Irrigation Settings')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.LANGUAGE.key}>
          <Link to={ROUTES.LANGUAGE.path}>
            <FaLanguage className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Language Settings')}</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Layout className="single-column-page padded">
      <PageHeader title={t('Settings')} />
      <div className="settings-page">
        {renderMenu()}
        <div className="settings-body">
          <Switch>
            <Route exact path={rootPath}>
              <Redirect to={ROUTES.ACCOUNT.path} />
            </Route>
            {Object.values(ROUTES).map(({
              component,
              exact,
              key,
              path,
              adminRequired
            }) => (
              adminRequired ? (
                <AdminRoute
                  authService={authService}
                  component={component}
                  path={path}
                  key={key}
                />
              ) : (
                <Route
                  component={component}
                  exact={exact}
                  key={key}
                  path={path}
                />
              )))}
            <Redirect to={rootPath} />
          </Switch>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(SettingsPage);
