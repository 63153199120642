import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button, Typography, Select, DatePicker,
} from 'antd';
import { FaLayerGroup, FaSlidersH } from 'react-icons/fa';
import { selectors, hooks, actions } from 'farmx-redux-core';
import { getAnomalyIcon } from 'farmx-web-ui';
import isEqual from 'react-fast-compare';
import { DownOutlined } from '@ant-design/icons';
import { PageHeader } from '../components/PageHeader';
import { StatusTag } from '../components/StatusTag';
import { CardLayout } from '../components/CardLayout';
import { useTracking } from '../../../helper/mixpanel';
import './recommendation-detail.css';
import RanchBlockTitle from '../components/RanchBlockTitle';
import {
  getPlantsDataForBlockId,
  getBlockCenterCoordinates,
  getCanopyAreaUnit,
} from '../../../helper/mapHelper';
import { isMobile } from '../../../utils/detectDevice';
import { reducerAnomaly, initReducerAnomaly } from './reducerAnomaly';
import {
  useRequestDroneDate,
  useDroneImageryDataForSelectedDate,
} from '../../../helper/satelliteHooks';
import { RecommendationActionButtons } from './RecommendationActionButtons';
import { getStateForAnomalySeverity } from '../../../helper/anomalyHelper';
import { getHumanReadableAnomalyType, renderRecommendationDateDetail, checkIsAllRanch } from '../../../helper/common';
import { VWCAndPressureDetails } from './VWCAndPressureDetails';
import { AnomalyDetails } from '../map/Anomaly/AnomalyDetails';
import AnomalyMap from './AnomalyMap';
import AnomalyModal from './AnomalyModal';
import { getActionText } from './recommendation';
import DroneAnalyticsTable from './DroneAnalyticsTable';
import { useNavigateHome } from '../../../helper/recommendationHooks';
import RecommendationLoader from '../../contentLoaders/RecommendationContentLoader';
import { getBlockArea } from '../../../helper/block';

const {
  selectRecommendationForBlock,
  selectNewBlockById,
  selectMapShowSoilType,
  selectUserAreaFormat,
  selectUserLengthFormat,
  selectLoadingRecommendations,
} = selectors;

const {
  setBlocks,
  loadRecommendations,
} = actions;

const { useRanchNamesForBlocks, useRanchBlockSelection, useEntities } = hooks;

const droneImageryOptions = [
  {
    value: 'RGB',
    label: 'RGB',
  },
  {
    value: 'NDVI',
    label: 'NDVI',
  },
  {
    value: 'NDRE',
    label: 'NDRE',
  },
  {
    value: 'NDSM',
    label: 'Canopy Height',
  },
];

export function GroundMissionDetailPage({
  propBlockId,
  showMap,
  showActionButton,
}) {
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { identifier } = useParams();
  const { selectedObjFromState } = useRanchBlockSelection();
  const { activeEntityId } = useEntities();

  const blockId = parseInt(identifier, 10);
  const { selectedTab, anomaly, drone } = location.state ?? {};
  const ranchName = useRanchNamesForBlocks([Number(blockId) || propBlockId]);
  const blockObj = useSelector((state) => selectNewBlockById(state, blockId || propBlockId));
  const blockName = blockObj?.name;
  const blockIdStr = blockObj && blockObj.am_block_id;
  const {
    row_spacing: rowSpacing,
    plant_spacing: plantSpacing,
  } = blockObj || {};
  const plantObj = { rowSpacing, plantSpacing };
  const blockArea = getBlockArea(blockObj);
  const areaFormat = useSelector(selectUserAreaFormat);
  const lengthFormat = useSelector(selectUserLengthFormat);
  const canopyAreaUnit = getCanopyAreaUnit(lengthFormat, areaFormat);

  const [modal, setModal] = useState({});
  const [latLngText, setLatLng] = useState(null);
  const [tab, setTab] = useState(showMap ? 'map' : 'vwc');
  const isMapTab = tab === 'map';
  const fixedButtonPosition = !isMobile && 'recommendation-investigate-button';
  const loadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const isAllRanch = checkIsAllRanch(selectedObjFromState?.type);

  const ranchId = blockObj?.ranch;
  const blkId = drone?.block || propBlockId || blockObj?.id;
  const { coordinates } = getBlockCenterCoordinates({
    type: 'Feature',
    geometry: { ...blockObj?.bounds },
  });

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state),
    isEqual,
  );

  // Navigate to home when ranch/block is changed
  useNavigateHome();

  // use local reducer to handle state of the component
  const [stateGroundMission, groundMissionDispatch] = useReducer(
    reducerAnomaly,
    initReducerAnomaly(),
    initReducerAnomaly,
  );

  const recommendation = useSelector((state) => selectRecommendationForBlock(state, blockId))
    .filter((d) => d?.type === 'ground_mission').reduce((_, obj) => obj, undefined);

  const {
    selectedDroneDataType,
    selectedDroneImagery,
    selectedDroneData,
    selectedDroneTilesData,
    plantsGeoJSON,
    showPlants,
    availableDroneDates,
    selectedDroneDate,
  } = stateGroundMission;

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (blkId) {
      getPlantsDataForBlockId(blockId, blockArea, canopyAreaUnit, (response) => {
        if (response) {
          groundMissionDispatch({
            type: 'setPlantsData',
            payload: response,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blkId]);

  useEffect(() => {
    if (selectedDroneTilesData) {
      groundMissionDispatch({
        type: 'setSelectedDroneImagery',
        payload: { visible: true, data: [selectedDroneTilesData] },
      });
    }
  }, [groundMissionDispatch, selectedDroneTilesData]);

  useEffect(() => {
    if (blockId) {
      // Set block in global block selector
      dispatch(setBlocks([Number(blockId)]));
    }

    // Unmounting set block when ranch is selected
    return () => {
      if(selectedObjFromState.type === 'ranch') {
        dispatch(setBlocks([ranchId]));
      }
      // Unmounting block and ranch when AllRanches selected
      if(isAllRanch){
        dispatch(setBlocks([]));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, blockId]);

  // Load recommendations only when there is no data from redux-state
  // Case: Loading page directly by URL
  useEffect(() => {
    if (!recommendation?.id && activeEntityId) {
      dispatch(loadRecommendations(activeEntityId));
    }
  }, [dispatch, recommendation, activeEntityId]);
  useRequestDroneDate(ranchId, false, selectedDroneDate, blockIdStr, groundMissionDispatch, true);
  useDroneImageryDataForSelectedDate(
    ranchId,
    selectedDroneDataType,
    selectedDroneData,
    groundMissionDispatch,
  );

  useEffect(() => {
    if (blockId || propBlockId) {
      tracking.track('Zoomed Anomaly');
      tracking.track('Anomaly Detail Page Loaded', {
        blockId: blockId || propBlockId,
        anomaly,
      });
    }
  }, [tracking, blockId, anomaly, propBlockId]);

  // TODO: loading
  if (!drone && !recommendation && !loadingStatus.loading) {
    // Commented code will be removed after confirmation
    // history.push({ pathname: '/recommendation' });
    return <div>{t('Ground Mission Recommendation not found')}</div>;
  }

  if (loadingStatus.loading && !recommendation) {
    return (
      <RecommendationLoader />
    );
  }

  const layerOptionButton = () => (
    <>
      <Button
        size="large"
        type="primary"
        icon={<FaLayerGroup />}
        className="recommendation-map-button"
        onClick={() => setModal({ visible: true, type: 'layer' })}
      />
    </>
  );

  function onLatLngClick(e) {
    setModal({ visible: true, type: 'action' });
    setLatLng(e.currentTarget.id);
  }

  const ranchBlockName = `${ranchName[blockId]} > ${blockName}`;
  let description = `${drone?.description || recommendation?.description}`;
  // NOTE: special case for demos
  if (blockName === 'A02' || blockName === 'Block 1') {
    description += '\n\nHealthy plant count has decreased by 9% in past month. In the same time period, vegetation index has decreased by 7% and canopy cover has decreased by 339 square meters (18%).';
  }

  return (
    <div
      className={`page-content ${
        showMap ? 'page-container' : ''
      } margin-10 recommendation-container`}
      id="ground-mission-select-drone-imagery"
    >
      <div
        className={
          !isMobile
            ? 'anomaly-details-container'
            : 'anomaly-details-mobile-container anomaly-details-margin'
        }
      >
        {showMap && (
          <PageHeader
            showBack
            toPath="/recommendation"
            title={<RanchBlockTitle title={ranchBlockName} />}
            state={{ selectedTab }}
          />
        )}
        <CardLayout key="1">
          <div className="recommendation-detail-card1-container">
            <div style={{ width: '100%' }}>
              <StatusTag
                width="100%"
                text={`${t(getHumanReadableAnomalyType(drone?.state || recommendation?.state))}`}
                state={getStateForAnomalySeverity(drone?.state || recommendation?.state)}
                tagStyle={{
                  fontSize: '16px',
                  padding: '4px',
                  marginBottom: '10px',
                }}
                icon={getAnomalyIcon(drone?.type || recommendation?.type)}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <Typography.Text>
                  {description}
                </Typography.Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Typography.Text>
                  {renderRecommendationDateDetail(drone || recommendation, t)}
                </Typography.Text>
              </div>
            </div>
          </div>
        </CardLayout>
        <CardLayout key="2">
          <AnomalyMap
            blockId={blkId}
            ranchId={ranchId}
            selectedImageryData={{
              side: 'left',
              ...selectedDroneImagery,
              droneDataType: selectedDroneDataType,
            }}
            anomalyData={{
              properties: {
                center: { coordinates: [coordinates[1], coordinates[0]] },
              },
            }}
            selMapShowSoilType={selMapShowSoilType}
            onLatLngClick={onLatLngClick}
            layerOptionButton={layerOptionButton}
            isMapTab={isMapTab}
            setTab={setTab}
            setIsDisabled={setIsDisabled}
            plantsGeoJSON={{ ...plantsGeoJSON, blockId: blkId, plantObj }}
            isAnomaly={false}
            isDataLoading={!plantsGeoJSON}
            showPlants={showPlants}
          />
          <div className="flex-row justify-content-normal">
            {isMapTab
          && (
          <div className="controls-box ground-mission-select-imagery-type">
            <span className="map-view-select-icon"><FaSlidersH /></span>
            <Select
              options={droneImageryOptions}
              defaultValue={selectedDroneDataType}
              onChange={(value) => {
                groundMissionDispatch({
                  type: 'setSelectedDroneDataType',
                  payload: value,
                });
                groundMissionDispatch({
                  type: 'setSelectedDroneImagery',
                  payload: { visible: false, data: selectedDroneImagery.data },
                });
              }}
              className="map-view-select"
              suffixIcon={<DownOutlined className="ground-mission-select-suffix-icon" />}
              popupClassName="map-select-dropdown-settings"
              getPopupContainer={
                () => document.getElementById('ground-mission-select-drone-imagery')
              }
            />
          </div>
          )}
            {isMapTab
          && (
          <div className="controls-box ground-mission-date-picker">
            <DatePicker
              size="medium"
              className="imagery-date-picker"
              allowClear={false}
              value={selectedDroneDate}
              format="D MMM YY"
              disabledDate={(momentDate) => {
                if (availableDroneDates?.datesObj) {
                  return !availableDroneDates.datesObj[momentDate.format('YYYY-MM-DD')];
                }
                return true;
              }}
              inputReadOnly
              onChange={(momentDate) => {
                groundMissionDispatch({ type: 'setSelectedDroneDate', payload: momentDate });
                const dateKey = momentDate.format('YYYY-MM-DD');
                groundMissionDispatch({
                  type: 'setSelectedDroneData',
                  payload: {
                    visible: true,
                    data: availableDroneDates.datesObj[dateKey],
                  },
                });
                groundMissionDispatch({
                  type: 'setSelectedDroneImagery',
                  payload: { visible: false, data: selectedDroneImagery.data },
                });
              }}
            />
          </div>
          )}
          </div>
        </CardLayout>
        {isMapTab
        && (
        <CardLayout key="3">
          <DroneAnalyticsTable recommendation={[drone || recommendation]} />
        </CardLayout>
        )}
        {!isMapTab && (
          <div className="home-page-pressure-sensor-details">
            <VWCAndPressureDetails blockIds={[Number(blockId)]} />
          </div>
        )}
        <AnomalyModal
          modal={modal}
          setModal={setModal}
          selectedImageryData={selectedDroneImagery}
          selMapShowSoilType={selMapShowSoilType}
          anomalyDispatch={groundMissionDispatch}
          isDisabled={isDisabled}
          latLngText={latLngText}
          showMap={showMap}
          showPlants={showPlants}
          isAnomaly={false}
        />
        {!showMap && (
          <>
            <CardLayout key="3">
              <Button
                type="text"
                onClick={onLatLngClick}
                id={JSON.stringify({
                  lat: coordinates[1],
                  lng: coordinates[0],
                })}
                data-testid="anomaly-map-lat-lng-section"
              >
                {`lat: ${coordinates[1].toFixed(
                  3,
                )}, long: ${coordinates[0].toFixed(3)}`}
              </Button>
            </CardLayout>
          </>
        )}
        <CardLayout key="4">
          {/* TODO: This will be updated once the comments API available */}
          <AnomalyDetails anomalyId={drone?.properties?.id || recommendation?.properties?.id} />
          <div>
            <Typography.Text className="flex-row justify-content-center">
              {t('No comments yet')}
            </Typography.Text>
          </div>
        </CardLayout>
        <div className="action-footer error-report-button">
          <Button
            size="large"
            type="link"
            block
            onClick={() => setModal({ visible: true, type: 'report' })}
          >
            {t('Notice an issue? Click here to report.')}
          </Button>
        </div>
      </div>
      {showActionButton ? (
        <RecommendationActionButtons
          blockId={blockId}
          recommendation={anomaly}
          recommendationType="ground_mission"
        />
      ) : (
        ''
      )}

      <div className={`action-footer recommendation-detail-actions ${fixedButtonPosition}`}>
        <Button
          size="large"
          type="primary"
          block
          onClick={() => {
            if (drone || recommendation) {
              history.push({
                pathname: `/recommendation/ground_mission/action/${recommendation?.block}`,
                state: {
                  from: location.pathname,
                  selectedImageryData: selectedDroneImagery,
                  ranchId,
                  selectedTab,
                },
              });
              tracking.track('Clicked ground mission action', drone);
            }
          }}
          className={!isMobile && 'margin-bottom-15'}
        >
          {t(getActionText(drone?.action || recommendation?.action))}
        </Button>
      </div>
    </div>
  );
}

GroundMissionDetailPage.propTypes = {
  propBlockId: PropTypes.number,
  showMap: PropTypes.bool,
  showActionButton: PropTypes.bool,
};

GroundMissionDetailPage.defaultProps = {
  propBlockId: null,
  showMap: true,
  showActionButton: true,
};
