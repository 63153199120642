import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
} from 'antd';
import { EventCategorySelectProps } from './types';

const mobileScreen = window.innerWidth < 599;

const EventCategorySelect = ({ onChange, value, placeholder }:EventCategorySelectProps) => {
  const { t } = useTranslation();
  const options = [
    {
      value: 'control',
      label: t('Control'),
    },
    {
      value: 'data',
      label: t('Data'),
    },
    {
      value: 'network',
      label: t('Network'),
    },
  ];

  return (
    <Select
      value={value}
      placeholder={placeholder ?? t('Event Category')}
      options={options}
      onChange={onChange}
      allowClear
      dropdownMatchSelectWidth={false}
      getPopupContainer={mobileScreen ? undefined : (trigger) => trigger.parentNode}
    />
  );
};

export default EventCategorySelect;
