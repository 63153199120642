import React from 'react';
import PropTypes from 'prop-types';
import { RWebShare } from 'react-web-share';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { useTracking } from '../../../helper/mixpanel';
import { navigateMap } from '../../../helper/mapHelper';

export default function AnomalyModalContents(props) {
  const {
    actionType, setModal, latLngText, showMap,
  } = props;
  const tracking = useTracking();
  const trackPage = { page: 'AnomalyDetail' };
  const { t } = useTranslation();
  const shareUrl = window.location.href;

  function renderActionContent() {
    return (
      <>
        <RWebShare
          data={{
            text: '',
            url: shareUrl,
            title: t('Imagery Anomaly'),
          }}
          onClick={() => {
            if (tracking) tracking.track('Shared Imagery Anomaly');
          }}
        >
          <Button
            className="lat-lng-select-options schedule-font-style"
            block
            onClick={() => {
              tracking.track('Clicked LatLng Share', trackPage);
              setModal({ visible: false, type: 'action' });
            }}
            type="primary"
          >
            {t('Share LatLng')}
          </Button>
        </RWebShare>
        <Button
          className="lat-lng-select-options schedule-font-style"
          block
          onClick={() => {
            setModal({ visible: false, type: 'action' });
            navigator.clipboard.writeText(latLngText);
            notification.success({
              message: t('Copied to clipboard!'),
            });
            tracking.track('Copied to clipboard', { ...JSON.parse(latLngText), ...trackPage });
          }}
          type="primary"
          data-testid="copy-to-clipboard"
        >
          {t('Copy to Clipboard')}
        </Button>
        <Button
          className="lat-lng-select-options schedule-font-style"
          block
          onClick={() => {
            const latLng = (latLngText && JSON.parse(latLngText)) || {};
            const latLngObj = {
              properties: {
                center: {
                  coordinates: [latLng.lng, latLng.lat],
                },
              },
            };
            navigateMap(latLngObj);
            tracking.track('Clicked Navigate Button', trackPage);
            setModal({ visible: false, type: 'action' });
          }}
          type="primary"
        >
          {t('Navigate')}
        </Button>
      </>
    );
  }

  function renderLearnMoreContents() {
    return (
      <>
        {/* This will be replaced with proper content in future */}
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi consequatur labore a officiis cumque. Error iure sed ducimus, debitis accusantium consequuntur odit, exercitationem delectus qui repudiandae recusandae voluptatum quia? Sunt!
        <Button
          className="schedule-font-style"
          block
          onClick={() => {
            // This will be properly handled in future
            notification.success({
              message: t('Message Sent Successfully!'),
            });
            // The API logic will be added here
            tracking.track('Clicked More Information Button');
          }}
          type="primary"
          ghost
        >
          {t('More Information')}
        </Button>
      </>
    );
  }

  return (
    <div className="schedule-water-usage-container schedule-font-style">
      <div className="schedule-modal-header">{t('Select action')}</div>
      <div className="schedule-options-share-modal">
        {actionType === 'action' && renderActionContent()}
        {actionType === 'learnMore' && renderLearnMoreContents()}
      </div>
    </div>
  );
}

AnomalyModalContents.propTypes = {
  showMap: PropTypes.bool,
  setModal: PropTypes.func,
  latLngText: PropTypes.string,
  actionType: PropTypes.string,
};

AnomalyModalContents.defaultProps = {
  showMap: true,
  setModal: null,
  latLngText: null,
  actionType: null,
};
