import React from 'react';
import PropTypes from 'prop-types';
import { RanchBlockSelect as RBSelect, RanchBlockSelectMobile } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';
import { isMobile } from '../../../utils/detectDevice';

const {
  setRanchBlockSelection,
  setRanch, setBlocks,
} = actions;

const { selectLoginUserInfo } = selectors;

const { useRanchBlockSelection } = hooks;

export function RanchBlockSelect(props) {
  const { selectedObj, setSelectedObj } = props;
  const dispatch = useDispatch();

  const userInfo = useSelector(selectLoginUserInfo).payload;

  const { selectedObjFromState } = useRanchBlockSelection();

  function handleSelect(d) {
    sessionStorage.removeItem('sensorType');
    if (d && d.value !== undefined) {
      if (setSelectedObj) setSelectedObj(d);
      dispatch(setRanchBlockSelection(d));
      if (d?.type === 'block' && !isMobile) dispatch(setBlocks([Number(d?.value)]));
      if (d?.type === 'ranch' && !isMobile) dispatch(setRanch(Number(d?.value)));
    } else if (setSelectedObj) setSelectedObj(null);
  }

  return (
    <div className="div-select-container ranch-block-select-container">
      {!isMobile && (
      <RBSelect
        selected={selectedObjFromState || selectedObj}
        onSelect={handleSelect}
        admin={userInfo && userInfo.admin}
      />
      )}
      {isMobile && (
      <RanchBlockSelectMobile
        selected={selectedObjFromState || selectedObj}
        onSelect={handleSelect}
        admin={userInfo && userInfo.admin}
      />
      )}
    </div>
  );
}

RanchBlockSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedObj: PropTypes.object.isRequired,
  setSelectedObj: PropTypes.func.isRequired,
};
