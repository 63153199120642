import React, {
  useState, useEffect, useReducer, useRef,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { recommendationApi } from 'farmx-api';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  notification,
  Typography,
} from 'antd';
import { FaLayerGroup } from 'react-icons/fa';
import isEqual from 'react-fast-compare';

import { selectors, actions, hooks } from 'farmx-redux-core';
import { getBlockArea } from '../../../helper/block';
import { PageHeader } from '../components/PageHeader';
import { IrrigationEquation } from '../components/IrrigationEquation';

import { CardLayout } from '../components/CardLayout';
import { getActionText } from './recommendation';
import { useTracking } from '../../../helper/mixpanel';
import { reducerAnomaly, initReducerAnomaly } from './reducerAnomaly';
import AnomalyMap from './AnomalyMap';
import AnomalyModal from './AnomalyModal';
import {
  getPlantsDataForBlockId, getBlockCenterCoordinates, deletePropertyByValue,
  getCanopyAreaUnit,
} from '../../../helper/mapHelper';
import './recommendation-action.css';
import '../schedule/Schedule.less';
import { parseDurationStr } from '../../../helper/scheduleHelper';
import {
  useRequestDroneDate, useDroneImageryDataForSelectedDate,
} from '../../../helper/satelliteHooks';
import { recommendationAnomalyFilter, checkIsAllRanch } from '../../../helper/common';
import { getClusterData, getConcaveHull } from './groundMissionHelper';
import { isMobile } from '../../../utils/detectDevice';
import { usePrepareCheckedList, useNavigateHome } from '../../../helper/recommendationHooks';
import CheckboxGroup from './CheckboxGroup';
import RecommendationLoader from '../../contentLoaders/RecommendationContentLoader';

const {
  selectRecommendationForBlock,
  selectMapShowSoilType,
  selectNewBlockById,
  selectUserAreaFormat,
  selectUserLengthFormat,
  selectLoadingRecommendations,
} = selectors;

const {
  setBlocks,
  loadRecommendations,
} = actions;

const { useRanchBlockSelection, useEntities } = hooks;

const options = ['normal', 'undeveloped', 'dead', 'missing'].sort();
const defaultOptions = options.map((d) => ({ key: d, count: 0, selected: 0 }));
const defaultCheckedList = defaultOptions.filter((d) => d?.key !== 'normal');

export function GroundMissionActionPage() {
  const { blockId } = useParams();
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const location = useLocation();
  const [modal, setModal] = useState({});
  const [latLngText, setLatLng] = useState(null);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkedFeatures, setCheckedFeatures] = useState({});
  const previousClusterIds = useRef([]);
  const previousCheckList = useRef(defaultCheckedList);
  const backPath = (location.state && location.state.from) || '/recommendation';
  const [selectedIds, setSelectedIds] = useState([]);
  const [clusterData, setClusterData] = useState({});
  const { coordinates, propPlantsGeoJSON } = location.state ?? {};
  const { activeEntityId } = useEntities();

  const recommendation = useSelector((state) => selectRecommendationForBlock(state, blockId))
    .filter((d) => d?.type === 'ground_mission').reduce((_, obj) => obj, undefined);
  const blockObj = useSelector((state) => selectNewBlockById(state, blockId));
  const { selectedObjFromState } = useRanchBlockSelection();
  const ranchId = blockObj?.ranch;
  const blockName = blockObj?.name;
  const blockIdStr = blockObj && blockObj.am_block_id;
  const {
    row_spacing: rowSpacing,
    plant_spacing: plantSpacing,
  } = blockObj || {};
  const plantObj = { rowSpacing, plantSpacing };
  const blockArea = getBlockArea(blockObj);
  const areaFormat = useSelector(selectUserAreaFormat);
  const lengthFormat = useSelector(selectUserLengthFormat);
  const canopyAreaUnit = getCanopyAreaUnit(lengthFormat, areaFormat);
  const loadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const isAllRanch = checkIsAllRanch(selectedObjFromState?.type);

  const {
    irrigationDuration: irrigationDurationStr,
    scheduledDuration: scheduledDurationStr,
  } = recommendation || {};

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  // use local reducer to handle state of the component
  const [stateGroundMission, groundMissionDispatch] = useReducer(
    reducerAnomaly,
    initReducerAnomaly(),
    initReducerAnomaly,
  );

  // Navigate to home when ranch/block is changed
  useNavigateHome();

  const { coordinates: blockCoords } = getBlockCenterCoordinates({
    type: 'Feature',
    geometry:
   { ...blockObj?.bounds },
  }) || {};
  const coords = coordinates || blockCoords;
  const {
    selectedDroneDataType, selectedDroneData, selectedDroneTilesData,
    selectedDroneImagery, plantsGeoJSON, showPlants,
  } = stateGroundMission;

  const plantsData = (propPlantsGeoJSON || plantsGeoJSON);
  const dataForPolygon = getClusterData(plantsData);

  // To set the selected cluster id initially and when change from checkbox
  useEffect(() => {
    if (showPlants) return;
    const list = checkedList.map((d) => d?.key);
    const ids = previousClusterIds.current;
    const filteredIds = ids.filter((d) => {
      const item = d?.split('-')?.[0];
      if (list.includes(item)) return true;
      return false;
    });

    if (!isEqual(filteredIds, selectedIds)) {
      setSelectedIds(filteredIds);
    }
  }, [checkedList, selectedIds, showPlants]);

  useEffect(() => {
    if (showPlants) return;

    const clusterConcaveData = {
      type: 'FeatureCollection',
      features: [],
    };

    if (dataForPolygon) {
      const ids = [];
      Object.keys(dataForPolygon).forEach((key, i) => {
        const features = dataForPolygon[key];
        const concaveFeatures = getConcaveHull(features, `${key}-${i}`);

        concaveFeatures.forEach((concaveFeature) => {
          if (concaveFeature) clusterConcaveData.features.push(concaveFeature);
          const { id } = concaveFeature?.properties || {};
          if (!ids.includes(id) && id) {
            ids.push(id);
          }
        });
      });
      if (!isEqual(ids, selectedIds)) {
        setSelectedIds(ids);
        previousClusterIds.current = ids;
      }
      if (!isEqual(clusterData, clusterConcaveData)) {
        setClusterData(clusterConcaveData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantsGeoJSON, propPlantsGeoJSON, showPlants]);

  usePrepareCheckedList(plantsData, checkedList, setCheckedList);

  useEffect(() => {
    if (blockId && !propPlantsGeoJSON) {
      getPlantsDataForBlockId(blockId, blockArea, canopyAreaUnit, (response) => {
        if (response) {
          groundMissionDispatch({
            type: 'setPlantsData',
            payload: response,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId]);

  useEffect(() => {
    if (blockId) {
      // Set block in global block selector
      dispatch(setBlocks([Number(blockId)]));
    }

    // Unmounting set block when ranch is selected
    return () => {
      if(selectedObjFromState.type === 'ranch') {
        dispatch(setBlocks([ranchId]));
      }
      // Unmounting block and ranch when AllRanches selected
      if(isAllRanch){
        dispatch(setBlocks([]));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, blockId]);

  // Load recommendations only when there is no data from redux-state
  // Case: Loading page directly by URL
  useEffect(() => {
    if (!recommendation?.id && activeEntityId) {
      dispatch(loadRecommendations(activeEntityId));
    }
  }, [dispatch, recommendation, activeEntityId]);

  useEffect(() => {
    if (selectedDroneTilesData) {
      groundMissionDispatch({
        type: 'setSelectedDroneImagery',
        payload: { visible: true, data: [selectedDroneTilesData] },
      });
    }
  }, [groundMissionDispatch, selectedDroneTilesData]);

  useRequestDroneDate(ranchId, false, false, blockIdStr, groundMissionDispatch, true);
  useDroneImageryDataForSelectedDate(ranchId, selectedDroneDataType, selectedDroneData,
    groundMissionDispatch);


  const scheduledDuration = parseDurationStr(scheduledDurationStr);
  const irrigationDuration = parseDurationStr(irrigationDurationStr);
  const recommendedTotalDuration = scheduledDuration && irrigationDuration ? (
    scheduledDuration.clone().add(irrigationDuration)
  ) : null;

  function getDurationHeader() {
    if (recommendedTotalDuration) {
      if (irrigationDuration.asMinutes() >= 0) {
        return t('Select Area To Investigate');
      }
      if (scheduledDuration.asMinutes() > 0) {
        return t('Reduce Area To Investigate');
      }
    }
    return t('Select area to investigate');
  }

  useEffect(() => {
    if (blockId) tracking.track('Loaded Ground Mission Recommendation Detail Page', { blockId });
  }, [tracking, blockId]);

  // TODO: loading
  if (!recommendation && !loadingStatus.loading) {
    return (
      <div>Ground Mission Recommendation not found</div>
    );
  }

  if (loadingStatus.loading && !recommendation) {
    return (
      <RecommendationLoader />
    );
  }

  // To maintain the checkbox selection state full/intermediate
  function onChangeCheckbox(d) {
    if (!d?.length) setCheckedFeatures({});
    else {
      const filteredCheckedList = checkedList.filter((l) => !d.some((f) => f.key === l.key));
      let updatedObj;
      filteredCheckedList.forEach((l) => {
        updatedObj = deletePropertyByValue(checkedFeatures, l.key);
      });
      if (updatedObj) {
        setCheckedFeatures(updatedObj);
      }
    }
    setCheckedList(d);
  }

  function onPolygonClick(d) {
    // To set the selected classification type by click on cluster polygon
    const list = previousCheckList.current.map((c) => c?.key);
    const item = d?.featureId?.split('-')?.[0];
    if (!list.includes(item)) {
      const newCheckList = [...previousCheckList.current, { key: item, selected: 0, count: 0 }];
      setCheckedList(newCheckList);
      previousCheckList.current = newCheckList;
    } else {
      const filteredCheckedList = checkedList.filter((c) => c?.key !== item);
      setCheckedList(checkedList.filter((c) => c?.key !== item));
      previousCheckList.current = filteredCheckedList;
    }

    // To set the selected cluster ids
    if (selectedIds.includes(d?.featureId)) {
      const filteredIds = selectedIds.filter((id) => id !== d?.featureId);
      setSelectedIds(filteredIds);
    } else {
      const ids = [...selectedIds, d?.featureId];
      setSelectedIds(ids);
    }
  }

  function onLatLngClick(e) {
    setModal({ visible: true, type: 'action' });
    setLatLng(e.currentTarget.id);
  }

  const layerOptionButton = () => (
    <>
      <Button
        size="large"
        type="primary"
        icon={<FaLayerGroup />}
        className="recommendation-map-button"
        onClick={() => setModal({ visible: true, type: 'layer' })}
      />
    </>
  );

  return (
    <div className="page-content page-container margin-10 recommendation-container">
      <div className={!isMobile ? 'anomaly-details-container ground-mission-action'
        : 'anomaly-details-mobile-container anomaly-details-margin ground-mission-action'}
      >
        <PageHeader
          showBack
          toPath={backPath}
          state={location.state}
          title={`${getActionText(recommendation.action || 'investigate')}
          : ${recommendation.blockName || blockName}`}
        />
        {/* This will be uncommented/removed in future */}
        {/* <IrrigationEquation
          deficit={Number(recommendation.deficit)}
          etcForecast={Number(recommendation.etcForecast)}
          irrigationDuration={recommendation.irrigationDuration}
        /> */}
        <CardLayout>
          <div className="ground-mission-header">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${getDurationHeader()}: `}
            </Typography.Text>
          </div>
          <div className="flex-column date-picker-container">
            <div className="flex-col">
              <AnomalyMap
                blockId={blockId}
                ranchId={ranchId}
                selectedImageryData={{
                  side: 'left',
                  ...selectedDroneImagery,
                  droneDataType: selectedDroneDataType,
                }}
                anomalyData={{
                  properties: {
                    center: { coordinates: [coords[1], coords[0]] },
                  },
                }}
                selMapShowSoilType={selMapShowSoilType}
                onLatLngClick={onLatLngClick}
                layerOptionButton={layerOptionButton}
                isMapTab
                setTab="map"
                setIsDisabled
                isAnomaly={false}
                clusterGeoJSON={!showPlants
                  ? { ...clusterData, blockId: Number(blockId) } : undefined}
                selectedIds={selectedIds}
                onPolygonClick={(d) => onPolygonClick(d)}
                isDataLoading={!propPlantsGeoJSON && !plantsGeoJSON}
                plantsGeoJSON={{ ...plantsData, blockId: Number(blockId), plantObj }}
                showPlants={showPlants}
                checkedList={checkedList}
                checkedFeatures={checkedFeatures}
                onChange={(d) => {
                  if (d) {
                    setCheckedFeatures(d);
                  }
                }}
                highlightPlant
              />
            </div>
          </div>
          <CheckboxGroup
            options={defaultOptions}
            checkedList={checkedList}
            checkedFeatures={checkedFeatures}
            onChange={(d) => onChangeCheckbox(d)}
          />
        </CardLayout>
        <AnomalyModal
          modal={modal}
          setModal={setModal}
          selectedImageryData={selectedDroneImagery}
          selMapShowSoilType={selMapShowSoilType}
          anomalyDispatch={groundMissionDispatch}
          isDisabled
          latLngText={latLngText}
          showMap
          showPlants={showPlants}
          isAnomaly={false}
        />
        <div className="recommendation-action-card-btn-container recommendation-buttons">
          <Button
            type="primary"
            size="large"
            block
            onClick={() => {
              // tracking.track('Clicked confirm recommendation action', recommendation);
            }}
          >
            {`${t(getActionText(recommendation.action))}`}
          </Button>
        </div>
      </div>
    </div>
  );
}
