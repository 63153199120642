import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function ValveStateText(props) {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { state, valveName, multipleStates } = props;
  const {
    openCount, closedCount, unknownCount, totalCount
  } = multipleStates;
  const isSingleState = [openCount, closedCount, unknownCount].some((v) => v === totalCount);

  function renderOpenCount(){
    if(!openCount) return null;
    return (
      <span className="pump-on-state">
        {` ${openCount} ${t(' OPEN')}`}
        {!isSingleState && ','}
      </span>
    );
  }

  function renderCloseCount(){
    if(!closedCount) return null;
    return (
      <span>
        {` ${closedCount} ${t(' CLOSED')}`}
        {!isSingleState && ','}
      </span>
    );
  }

  function renderUnknownCount(){
    if(!unknownCount) return null;
    return <span>{` ${unknownCount} ${t(' UNKNOWN')}` }</span>;
  }

  return (
    <>
      {!multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        {valveName ? `${valveName} is` : t('Valve is')}
        <span className={state?.toLowerCase() === 'open' ? 'pump-on-state' : ''}>
          {` ${t(state)}`}
        </span>
      </Title>
      )}
      {multipleStates.isMultiple && (
      <Title className="pump-state-text" level={5}>
        Valves:
          {renderOpenCount()}
          {renderCloseCount()}
          {renderUnknownCount()}
      </Title>
      )}
    </>
  );
}

ValveStateText.propTypes = {
  state: PropTypes.string.isRequired,
  valveName: PropTypes.string,
  multipleStates: PropTypes.shape(),
};

ValveStateText.defaultProps = {
  valveName: null,
  multipleStates: {},
};
