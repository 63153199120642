import React, {
  useCallback, useContext, useEffect, useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { PCT_AVAILABLE_WATER } from 'components/hooks/graph';
import { useSelector } from 'react-redux';
import { calculateRefillPercent } from 'components/mobile/settings/settingsHelper';
import {
  getMultiplierAndData,
  getYminMax,
  preparePlotLines
} from '../../helper/graphHelper';
import {
  colorCritical,
  colorOk,
  colorOver,
  colorWarning,
} from '../../utils/colors';
import NewSensorDataChart from './NewSensorDataChart';
// import GraphContext from './GraphContext';
import { VAR_SOIL_MOISTURE_ROOTZONE_PCT} from './constants';
import EditSoilSensorProperties from './EditSoilSensorProperties';
import GraphContext from './GraphContext';

const { useUnits } = hooks;

const cutoffLineLabelStyle = {
  color: '#666666',
  fontSize: '11px',
  opacity: 0.6
};

export default function PctAvailableWaterChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap, refreshSensorData
  } = props;
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const { sensorDataRefresh } = useContext(GraphContext);
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensor.type, sensor.identifier),
  );
  // TODO remove after testing
  // console.log('sensorStatus', sensorStatus);
  const refreshCount = sensorDataRefresh[`${sensor.type}/${sensor.identifier}`];

  // TODO remove after testing
  // console.log('Render PctAvailableWaterChart');

  // const {setYAxisMinMax} = useContext(GraphContext);
  const globalYMinMax = yMinMaxMap[PCT_AVAILABLE_WATER]
    ?yMinMaxMap[PCT_AVAILABLE_WATER]
    :{min: null, max: null};

  // TODO remove after testing
  // console.log('yMinMaxMap PctAvailableWaterChart', yMinMaxMap);

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  // TODO remove after testing
  // console.log('yMinMax PctAvailableWaterChart', yMinMax);

  // useEffect(() => {
  //   console.log('Mount PctAvailableWaterChart');
  // }, []);

  useEffect(() => {
    if(refreshCount > 0) setRefresh((prev) => prev+1);
  }, [refreshCount]);

  const customConfig = useCallback((configObj, seriesData) => {
    // TODO remove after testing
    // console.log('calling customConfig', configObj, seriesData);
    const yMinMax = getYminMax(seriesData);
    let plotLines = [];
    let plotBands = [];
    const rootZonePctWaterData = seriesData?.[VAR_SOIL_MOISTURE_ROOTZONE_PCT];
    if(configObj?.series?.[0]){
      const { multiplier } = configObj?.series?.[0];
      if(yMinMax) {
        updateYminmax(PCT_AVAILABLE_WATER,
          Math.min(yMinMax.min, 0) * multiplier,
          Math.max(yMinMax.max, 1) * multiplier);
      }
      // TODO remove after testing
      // console.log('local y min max multiplier', yMinMax.min, yMinMax.max, multiplier);
    }
    if (configObj && rootZonePctWaterData) {
      // const refillPercent = calculateRefillPercent(sensorStatus.refillPointRootzone, 0, 1);
      // TODO remove after testing
      // console.log('refillPercent', refillPercent);
      const updatedConfig = Object.entries(seriesData).map(([key, value], dataIndex) => {
        if (!value.length) return undefined;
        const chartData = value[0];
        if (!chartData.data.length) return undefined;
        const { data: variableData } = chartData;
        const { multiplier } = getMultiplierAndData(variableData, useUnits, chartData.units);

        const linesConfig = [{
          color: colorOver,
          value: 100,
          type: 'upper',
          from: 1 * multiplier,
          to: 1 + 2 * multiplier,
          className: 'upper-band-line',
          label: {
            x: -160,
            y: 12,
            align: 'right',
            text: 'FC (100%)',
            style: cutoffLineLabelStyle
          }
        },
        {
          color: colorWarning,
          value: 50,
          type: 'middle',
          label: {
            x: -50,
            y: 12,
            align: 'right',
            text: 'Refill Percent (50%)',
            style: cutoffLineLabelStyle
          }
        },
        {
          color: colorCritical,
          value: 0,
          type: 'lower',
          from: 0,
          to: -(1 * multiplier),
          className: 'lower-band-line',
          label: {
            x: 0,
            y: 12,
            align: 'right',
            text: 'WP (0%)',
            style: cutoffLineLabelStyle
          }
        }];
        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;
        const yAxis = configObj.yAxis.map((d) => {
          const obj = { ...d };
          obj.plotLines =plotLines;
          obj.plotBands = plotBands;
          if(yMinMax){
            obj.min = Math.min(yMinMax.min * multiplier, 0);
            obj.max = Math.max(yMinMax.max * multiplier, 100);
          }
          return obj;
        });
        return {
          ...configObj,
          yAxis,
          plotOptions: { series: { color: colorOk } },
          series: [...configObj.series.filter((d, seriesIndex) => seriesIndex !== dataIndex),
            {
              ...configObj.series[dataIndex],
              name: 'Rootzone Percent Available Water (%)',
            }
          ]
        };
      }).reduce((acc, obj) => {
        const config = { ...acc, ...obj };
        return config;
      }, {});
      return updatedConfig;
    }
    return configObj;
  }, [updateYminmax]);

  const onClickEdit = useCallback(() => {
    setEdit(true);
  });

  const onCancel = useCallback(() => {
    setEdit(false);
  });

  const onSave = useCallback(() => {
    setEdit(false);
    // setRefresh((prev) => prev+1);
    refreshSensorData(sensor.type, sensor.identifier);
  });

  const EditLink = useMemo(() => (
    <div
      key={`edit-link-${sensor.id}`}
      className="chart-header-edit-link"
      onClick={onClickEdit}
      role="button"
      tabIndex={0}
      onKeyPress={null}
    >
      Edit
    </div>
  ), [onClickEdit, sensor.id]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_SOIL_MOISTURE_ROOTZONE_PCT}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Percent Available Water'), units: '', right: [EditLink]}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
        refresh={refresh}
      />
      {edit?(
        <EditSoilSensorProperties
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          onSave={onSave}
        />
      ):null}
    </>
  );
}

PctAvailableWaterChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
  refreshSensorData: PropTypes.func,
};

PctAvailableWaterChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
  refreshSensorData: () => null,
};
