import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { PlantSensorListItem } from './PlantSensorListItem';
import { RanchBlockSelect } from './RanchBlockSelect';
import { PlantSensorSummary } from './ListCards/PlantSensorSummary';
import { installState } from '../../../constants';
import { isMobile } from '../../../utils/detectDevice';
import { compareByName } from '../../../helper/common';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function PlantSensorList() {
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const { blockIds } = hooks.useRanchBlockSelection();
  const sensors = useSelector((state) => (
    selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    })
  )) || {};
  const list = (sensors.farmx_dendrometer || [])?.sort(compareByName);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Plant Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <PlantSensorSummary
            list={list}
          />
          {list.map((item) => (
            <Link key={item.identifier} to={`/list/plant-sensor/${item.type}/${item.identifier}`}>
              <PlantSensorListItem
                key={item.identifier}
                sensorDetails={item}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
