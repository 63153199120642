import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks } from 'farmx-redux-core';
import PressureGauge from './PressureGauge';

const {
  useUnits,
} = hooks;

export default function PressureSummaryList(props) {
  const getUserUnits = useUnits();
  const {
    id, pressure, loading, pressureCutoff, units, blockName,
  } = props;


  const convertedPressure = getUserUnits(pressure, 'pounds_per_square_inch', 'pressure', { decimalPlaces: 4 });
  const displayPressure = `${convertedPressure.value} ${convertedPressure.label}`;

  return (
    <div className="pressure-summary-container">
      {loading ? <LoadingOutlined className="pressure-loader" /> : null}
      <div id="block-name-div" className="multiple-blocks-bgcolor">
        <div className="blocks-list-left">
          <Typography.Link>{blockName}</Typography.Link>
        </div>
        <div className="blocks-list-right">
          <div className="pressure-summary-details">
            <PressureGauge
              id={id}
              pressure={pressure}
              pressureCutoff={pressureCutoff.toString()}
              height={80}
              width={80}
            />
            <Typography.Title level={3} className="display-pressure-value">{displayPressure}</Typography.Title>
          </div>
        </div>
      </div>
    </div>
  );
}

PressureSummaryList.defaultProps = {
  id: '',
  pressure: '',
  loading: false,
  pressureCutoff: [],
  units: {
    pressureCutoff: 'psi',
  },
  blockName: '',
};

PressureSummaryList.propTypes = {
  id: PropTypes.string,
  pressure: PropTypes.string,
  loading: PropTypes.bool,
  pressureCutoff: PropTypes.arrayOf(PropTypes.number),
  units: PropTypes.shape({
    pressureCutoff: PropTypes.string,
  }),
  blockName: PropTypes.string,
};
