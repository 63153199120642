import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import IrrigationStatus from '../../components/IrrigationStatus';
import { ControlPumpDetails } from '../ControlPump/ControlPumpDetails';
import { ControlValveDetail } from '../ControlValves/ControlValveDetail';
import { PageHeader } from '../../components/PageHeader';
import { useTracking } from '../../../../helper/mixpanel';
import RanchBlockTitle from '../../components/RanchBlockTitle';
import PressureSummary from './PressureSummary';
import WaterInformation from './WaterInformation';
import { getDateRangeForScheduler } from '../../../../helper/scheduleHelper';

const { loadBlockSchedule, loadBlockRenderedSchedule, loadBlockControlStatus } = actions;

const {
  selectBlockControlStatus, selectPressureDetails,
  selectCurrentBlockScheduledEvents,
  selectScheduledEventById,
} = selectors;

const { loadSensorStatus } = actions;

const { useBlockNames, useRanchNamesForBlocks } = hooks;

const REFRESH_INTERVAL = 1000 * 30;

/**
 * TODO: Unreachable code: if (!blockDetails) {...}
 */
export function BlockDetails(props) {
  const { match } = props;
  let blockDetails = null;
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const refreshStatusInterval = useRef(null);

  const blockId = Number(match.params.id);
  useEffect(() => {
    if (tracking) tracking.track('Loaded Control Summary Page', { blockId });
  }, [blockId, tracking]);

  const blockName = useBlockNames([blockId]) || {};
  const ranchName = useRanchNamesForBlocks([blockId]) || {};

  const controlStatus = useSelector((state) => selectBlockControlStatus(state, [blockId]));
  const pressureSensors = useSelector((state) => selectPressureDetails(state, blockId)) || {};

  const { pressureSensorsList } = pressureSensors || {};
  const sensorParamsStr = JSON.stringify(pressureSensorsList?.map((d) => (
    { type: d.type, identifier: d.identifier })) || []);

  // To fix the pressure value not displayed issue
  function loadPressureSensorStatus(sensorParams) {
    sensorParams.forEach((sensorParam) => {
      dispatch(loadSensorStatus(sensorParam));
    });
    dispatch(loadBlockControlStatus(blockId));
  }

  // Auto refresh the pressure sensor status in every 30 seconds
  function refreshUntilUnmount() {
    const parsedSensorParams = JSON.parse(sensorParamsStr);
    loadPressureSensorStatus(parsedSensorParams);
    refreshStatusInterval.current = setInterval(() => {
      loadPressureSensorStatus(parsedSensorParams);
    }, REFRESH_INTERVAL);
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  const vStatus = (controlStatus && controlStatus[blockId]?.valveStatus) || {};
  const pStatus = (controlStatus && controlStatus[blockId]?.vfdStatus) || {};
  const scheduledEventIds = useSelector(
    (state) => selectCurrentBlockScheduledEvents(state, blockId),
  )?.map((d) => d?.id);

  const dateRange = getDateRangeForScheduler(moment());
  const [dStart, dEnd] = dateRange.map((d) => moment(d).format());

  useEffect(() => {
    // Loading old API for Actual gallons details
    dispatch(loadBlockSchedule(blockId));
    dispatch(loadBlockRenderedSchedule({
      blockId,
      dateStart: moment(dStart),
      dateEnd: moment(dEnd),
    }));
  }, [blockId, dStart, dEnd, dispatch]);

  // The one month date range is needed to select the scheduled events
  const dates = [moment(), moment().add(1, 'month')];

  // TBD: To get the recently scheduled event Id
  // This fix will be changed in future based on requirement
  const eventId = scheduledEventIds?.[scheduledEventIds.length - 1];
  const scheduledEvent = useSelector((state) => selectScheduledEventById(state, {
    id: eventId,
    blockId,
  }));

  blockDetails = {
    blockId,
    valveCurrentState: (vStatus.controlStatus && vStatus.controlStatus.state) || '',
    blockName: blockName[blockId],
    pumpCurrentState: (pStatus.controlStatus && pStatus.controlStatus.status) || '',
    irrigationStartDate: (vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledOpen)
    || (scheduledEvent && scheduledEvent.start_date),
    irrigationEndDate: (vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledClose)
    || (scheduledEvent && scheduledEvent.stop_date),
    units: vStatus.units || {
      pressureCutoff: 'psi',
      actualVolume: 'gallons',
    },
  };

  // Trigger the refresh interval when component loads
  useEffect(() => {
    refreshUntilUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear the interval when component unmounts
  useEffect(() => () => {
    clearRefreshInterval();
  }, []);

  if (!blockDetails) {
    return (
      <div className="div-default block-details-container">
        <div className="block-details-additional-info mobile-list-item">
          {t('Block details not available')}
        </div>
      </div>
    );
  }

  const ranchBlockName = `${t('Control')} - ${ranchName[blockId]} > ${blockDetails.blockName}`;

  return (
    <div className="div-default block-details-container">
      <PageHeader
        showBack
        title={<RanchBlockTitle title={ranchBlockName} />}
        toPath="/control"
      />
      <div className="block-details-additional-info mobile-list-item">
        <IrrigationStatus
          block={blockDetails}
          type="calendar"
        />
        <WaterInformation
          blockId={blockId}
          units={blockDetails.units}
          dateRange={dates}
          eventId={eventId}
        />
      </div>
      <div className="block-details-additional-info mobile-list-item">
        <Link
          className="link-to-control-sensor"
          to={{
            pathname: `/list/pressure-sensors/${blockId}`,
            state: { prevPath: window.location.pathname },
          }}
        >
          <PressureSummary
            id={`pressure-${blockName[blockId]}`}
            pressure={pressureSensors.pressure}
            valveList={pressureSensors.sensorList}
            loading={pressureSensors.loading}
            pressureCutoff={pressureSensors.pressureCutoff}
            units={blockDetails.units}
          />
        </Link>
      </div>
      <ControlPumpDetails pumpId={blockDetails.blockId} />
      <ControlValveDetail valveId={blockDetails.blockId} showPressureSummary={false} />
    </div>
  );
}

BlockDetails.defaultProps = {
  history: {},
  match: { params: {} },
};

BlockDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};
