import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  PCT_AVAILABLE_WATER,
  RAINFALL,
  ROOT_ZONE_VWC,
  SOIL_MOISTURE_ALL_DEPTHS,
  SOIL_TEMPERATURE,
  WATER_PRESSURE
} from 'components/hooks/graph';

const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'Root zone moisture',
    value: ROOT_ZONE_VWC,
    key: ROOT_ZONE_VWC,
  },
  {
    title: 'Percent Available Water',
    value: PCT_AVAILABLE_WATER,
    key: PCT_AVAILABLE_WATER,
  },
  {
    title: 'Moisture All Depths',
    value: SOIL_MOISTURE_ALL_DEPTHS,
    key: SOIL_MOISTURE_ALL_DEPTHS,
  },
  {
    title: 'Soil Temperature',
    value: SOIL_TEMPERATURE,
    key: SOIL_TEMPERATURE,
  },
  {
    title: 'Water Pressure',
    value: WATER_PRESSURE,
    key: WATER_PRESSURE,
  },
  {
    title: 'Rainfall',
    value: RAINFALL,
    key: RAINFALL,
  },
];

const SoilPresetChartSelect = ({onSelectChange}) => {
  const [value, setValue] = useState([RAINFALL, ROOT_ZONE_VWC]);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    onSelectChange(value);
  }, [onSelectChange, value]);

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Charts',
    className: 'soil-preset-chart-select',
    dropdownStyle: isMobile?{
      position: 'fixed',
      height: '100%',
      padding: '20px'
    }:{},
    getPopupContainer: () => document.getElementById('soilPresetChartSelect'),
  };

  return (
    <div id="soilPresetChartSelect">
      <TreeSelect {...tProps} />
    </div>

  );
};

SoilPresetChartSelect.propTypes = {
  onSelectChange: PropTypes.func,
};

SoilPresetChartSelect.defaultProps = {
  onSelectChange: () => null,
};

export default SoilPresetChartSelect;
