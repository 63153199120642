import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, selectors } from 'farmx-redux-core';
import {
  Typography,
  Switch,
  Button,
} from 'antd';
import './map-settings.css';
import { mapPresentationModes } from 'farmx-web-ui';
import { isMobile } from '../../../utils/detectDevice';

const {
  setMapShowSoilType,
  setMapShowTemperature,
  setMapShowWind,
} = actions;

const {
  selectMapShowTemperature,
  selectMapShowWind,
} = selectors;

export const presModeTitles = {
  irrigationRecommendation: 'Irrigation Recommendation',
  soilstatus: 'Soil Moisture State',
  connectivity: 'Connectivity',
  waterpressure: 'Water Pressure',
  control: 'Control',
  provision: 'Provision',
  anomaly: 'Anomalies',
  cropHealth: mapPresentationModes.cropHealth.title,
  plants: 'Plants',
};

export function MapSettings({
  selMapShowSoilType,
  onChange,
  isImageryDataVisible,
  showSideBySideMap,
  displayLayerOptions,
  onClick,
  selected,
  isBlockPlantView,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectors.selectLoginUserInfo).payload;
  const isAdmin = (userInfo && userInfo.admin);

  const availableModes = [
    'soilstatus',
    'connectivity',
    'waterpressure',
    'control',
    'irrigationRecommendation',
    'anomaly',
    'cropHealth',
    'plants',
  ];

  if (isAdmin) {
    availableModes.push('provision');
  }

  const showTemperatureOverlay = useSelector(selectMapShowTemperature);
  const showWindOverlay = useSelector(selectMapShowWind);

  const toggleTemperatureOverlay = useCallback((e) => {
    dispatch(setMapShowTemperature(e));
  }, [dispatch]);

  const toggleWindOverlay = useCallback((e) => {
    dispatch(setMapShowWind(e));
  }, [dispatch]);

  const COND = !showSideBySideMap && selected?.side === 'right';

  function renderLayersControl(pos) {
    return (
      <div className="flex-row map-settings-row">
        <div className="flex-column switch-box">
          {/* This will be removed after confirmation */}
          {/* <div className="flex-row padding-bottom-10 map-overlay-option-align">
            <Switch
              checked={showSideBySideMap}
              onChange={(v) => {
                if (onChange) onChange({ type: 'sideBySideMap', visible: v });
              }}
              data-testid="side-by-side"
            />
            <div className="margin-left-10">
              <Typography.Text>
                {t('Side by side map')}
              </Typography.Text>
            </div>
          </div> */}

          {selected?.layer?.side === 'left'
          && (
            <div>
              <div className="flex-row padding-bottom-10 map-overlay-option-align ">
                <Switch
                  checked={showTemperatureOverlay}
                  onChange={(d) => {
                    toggleTemperatureOverlay(d);
                    if (onClick) {
                      onClick({
                        ...selected, side: pos, type: 'temperature', visible: d,
                      });
                    }
                  }}
                  data-testid="temp/weather"
                />
                <div className="margin-left-10">
                  <Typography.Text>
                    {`${t('Temperature')}/${t('Weather')}`}
                  </Typography.Text>
                </div>
              </div>
              <div className="flex-row padding-bottom-10 map-overlay-option-align ">
                <Switch
                  checked={showWindOverlay}
                  onChange={(d) => {
                    toggleWindOverlay(d);
                    if (onClick) {
                      onClick({
                        ...selected, side: pos, type: 'wind', visible: d,
                      });
                    }
                  }}
                  data-testid="wind-speed"
                />
                <div className="margin-left-10">
                  <Typography.Text>
                    {t('Wind Speed')}
                  </Typography.Text>
                </div>
              </div>
              <div className="flex-row padding-bottom-10 map-overlay-option-align ">
                <Switch
                  checked={selMapShowSoilType}
                  onChange={(v) => {
                    dispatch(setMapShowSoilType(v));
                    if (onClick) {
                      onClick({
                        ...selected, side: pos, type: 'soil', visible: v,
                      });
                    }
                  }}
                  data-testid="soil-type"
                />
                <div className="margin-left-10">
                  <Typography.Text>
                    {t('Soil Type')}
                  </Typography.Text>
                </div>
              </div>
            </div>
          )}

          {selected?.layer?.side === 'right'
            && (
            <div className="flex-row padding-bottom-10 margin-top-10 map-overlay-option-align ">
              <Switch
                checked={isImageryDataVisible}
                onChange={(v) => {
                  if (onChange) onChange(v);
                  if (onClick) {
                    onClick({
                      ...selected, side: 'left', type: 'imagery', visible: v,
                    });
                  }
                }}
                data-testid="show-imagery"
              />
              <div className="margin-left-10">
                <Typography.Text>
                  {t('Show Imagery')}
                </Typography.Text>
              </div>
            </div>
          )}

          {/* <div className="flex-row padding-bottom-10">
  <Switch
    checked={selMapShowLabels}
    onChange={(v) => {
      dispatch(setMapShowLabels(v));
    }}
  />
  <div className="margin-left-10">
    <Typography.Text>
      {t('Show Labels')}
    </Typography.Text>
  </div>
</div> */}
        </div>
      </div>
    );
  }
  return (
    <div className={isMobile && isImageryDataVisible ? 'map-settings-container margin-top-50'
      : 'map-settings-container'}
    >
      {displayLayerOptions && !isBlockPlantView && (
      <div className={isMobile && 'mobile-layer-options-container'}>
        <div className="parent-container">
          <div className="left-child">
            <div className="flex-row padding-bottom-10">
              <div className="map-modal-section">
                <Button
                  className={selected?.layer?.side === 'left' ? 'active-tab' : ''}
                  onClick={() => onClick({ ...selected, layer: { side: 'left' } })}
                  block
                >
                  {t('Overlays')}

                </Button>
              </div>
            </div>
          </div>

          <div className="right-child">
            <div className="flex-row padding-bottom-10">
              <div className="map-modal-section">
                <Button
                  className={selected?.layer?.side === 'right' ? 'active-tab' : ''}
                  onClick={() => onClick({ ...selected, layer: { side: 'right' } })}
                  block
                >
                  {t('Imagery')}

                </Button>
              </div>
            </div>
          </div>
        </div>
        {renderLayersControl(selected?.layer?.side === 'left' ? 'left' : 'right')}
      </div>
      )}
    </div>
  );
}

MapSettings.propTypes = {
  selMapShowSoilType: PropTypes.bool.isRequired,
  selMapShowLabels: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isImageryDataVisible: PropTypes.bool.isRequired,
  showSideBySideMap: PropTypes.bool.isRequired,
  displayLayerOptions: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  isBlockPlantView: PropTypes.bool.isRequired,
};
