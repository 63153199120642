import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export default function MapTabsControl(props) {
  const {
    dispatchMap, dispatchMap1, layersControl, sideBySideMapVisible, isImageryPanelVisible,
  } = props;
  const { t } = useTranslation();

  return (sideBySideMapVisible && isImageryPanelVisible) && (
    <div className="parent-container tab-control-section">
      <div className="left-child">
        <div className="flex-row">
          <div className="map-modal-section">
            <Button
              className={layersControl?.side === 'left' ? 'active-tab' : ''}
              onClick={() => {
                dispatchMap({ type: 'setLayersControl', payload: { type: 'tab', side: 'left' } });
                dispatchMap({
                  type: 'setIsImageryPanelVisible',
                  payload: true,
                });
              }}
              block
            >
              {t('Left')}
            </Button>
          </div>
        </div>
      </div>

      <div className="right-child">
        <div className="flex-row">
          <div className="map-modal-section">
            <Button
              className={layersControl?.side === 'right' ? 'active-tab' : ''}
              onClick={() => {
                dispatchMap({ type: 'setLayersControl', payload: { type: 'tab', side: 'right' } });
                dispatchMap1({
                  type: 'setIsImageryPanelVisible',
                  payload: true,
                });
              }}
              block
            >
              {t('Right')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

MapTabsControl.propTypes = {
  dispatchMap: PropTypes.func,
  dispatchMap1: PropTypes.func,
  layersControl: PropTypes.shape({
    type: PropTypes.string,
    side: PropTypes.string,
  }),
  sideBySideMapVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
};

MapTabsControl.defaultProps = {
  dispatchMap: null,
  dispatchMap1: null,
  layersControl: null,
  sideBySideMapVisible: null,
  isImageryPanelVisible: null,
};
