import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { SensorDetailHeader as SensorHeader } from '../map/SensorDetailHeader';

const SensorDetailHeader = (props) => {
  const {
    type, location, blockId, identifier,
  } = props;
  const { t } = useTranslation();
  const { name } = useSelector((state) => selectors.selectNewBlockById(state, blockId)) || { name: '' };
  const { alarmCount } = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  ) || { alarmCount: 0 };
  const blockName = `${t('Block')}: ${name}`;

  return (
    <div className="sensor-detail-header-container">
      <SensorHeader sensorProperties={{
        type, location, alarmCount, identifier,
      }}
      />
      <Typography.Text type="secondary">{blockName}</Typography.Text>
    </div>
  );
};

SensorDetailHeader.defaultProps = {
  identifier: '',
  type: '',
  location: {},
};

SensorDetailHeader.propTypes = {
  identifier: PropTypes.string,
  type: PropTypes.string,
  blockId: PropTypes.number.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default SensorDetailHeader;
