import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetail } from './UserDetail';
//@ts-ignore
import { userApi, teamUserAccessApi, roleApi } from 'farmx-api';
import { RouteComponentProps } from 'react-router';
import { FarmxWebApiTypes } from 'types/api_types';
import { message } from 'antd';
import { useSelector } from 'react-redux';
//@ts-ignore
import { selectors } from 'farmx-redux-core';
import useUserRoles from 'components/organization/hooks/useUserRoles';



/**
 * The UserDetailContainer is a container component that fetches the user data from the api and manages the state of the user data.
 * @param {RouteComponentProps} props - The standard react-router-dom props
 * @returns {JSX.Element} 
 */
const UserDetailContainer: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  const { match, history } = props;

  const [loading, setLoading] = React.useState<boolean>(false);

  const [user, setUser] = React.useState<any>(undefined);

  const [isUserEntityAdmin, setIsUserEntityAdmin] = React.useState<boolean>(false);

  const selectedEntityId = useSelector((state: any) => selectors.selectActiveEntity(state));  

  const userInfo = useSelector(state => selectors.selectLoginUserInfo(state)).payload;

  const roles = useUserRoles();

  // Side effect to fetch the user data
  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const userId = (match.params as {userId: number}).userId
        var userFound = await userApi.getUserById(userId);
        const fetchRoles = await roleApi.getRoles();
        const entityAdminRole = fetchRoles.data.data.find((role: any) => role.name === 'Entity Admin');
        const userEntityAdminRoleFound = await teamUserAccessApi.listTeamUserAccess({user_id: userId, role_id: entityAdminRole.id, entity_id: selectedEntityId});
        setIsUserEntityAdmin(userEntityAdminRoleFound.data.data.length > 0);
        setUser(userFound.data);
      } catch (error) {
        console.error('Failed to fetch user data');
      }
    };

    fetchUser();
  }, []);

  // State of the pagedresult of the team accesses query
  const [pagedResult, setPagedResult] = useState<FarmxWebApiTypes.PaginatedResult<any>>({
    data: [],
    page: 0,
    page_size: 0,
    count: 0,
  });
    
  // State of the current spec for fetching users
  const [spec, setSpec] = useState<FarmxWebApiTypes.TeamUserAccessListQuery>({
    includes: ['role', 'team'],
    page_number: 1,
    page_size: 10,
  });

  /**
   * Side effect to fetch the team access data from the api when the spec is changed or initialized
   * @returns {void}
   */
  const loadAccessData = async () => {
    try {
      var usersResult = await teamUserAccessApi.listTeamUserAccess({...spec, user_id: (match.params as {userId: number}).userId, order_by_desc: 'role'});
      setPagedResult(usersResult.data);
    } catch (error) {
      message.error('Failed to fetch data.');
    }
  };
  useEffect(() => {
  loadAccessData();
}, [spec]);


  /**
   * Method to handle the page change - updates the query spec based on the new page number and page size
   * @param page - The new page number
   * @param pageSize - The new page size
   * @returns {void}
   */
    const handlePageChange = (page: number, pageSize:number) => {
      setSpec(prev => ({
        ...prev,
        page_number: page,
        page_size: pageSize || prev.page_size,
      }));
    };

  /**
   * Method to handle the view team action - push to the team detail page
   * @param {any} record - The record of the team
   */
  const handleViewTeam = (record: any) => {
    history.push(`/organization/teams/${record?.team?.id}`);
  }

  const changeEntityAdmin = async (e: any) => {
    setLoading(true);
    if(e.target.checked == false) {
      if(userInfo.user_id == user.id) {
        message.error('You cannot remove the Entity Admin role from yourself');
        setLoading(false);
        return;
      }
      // search for the entity admin role
      const roles = await roleApi.getRoles();
      const entityAdminRole = roles.data.data.find((role: any) => role.name === 'Entity Admin');
      // search for the entity admin role for the current entity and user
      const userAccess = await teamUserAccessApi.listTeamUserAccess({
        user_id: user.id,
        role_id: entityAdminRole.id,
        entity_id: selectedEntityId,
      });
      // if the user has the entity admin role, remove it
      if(userAccess.data.data.length > 0){
        await teamUserAccessApi.deleteTeamUserAccess(userAccess.data.data[0].id);
        setIsUserEntityAdmin(false);
        message.success(t('Entity Admin role removed'));
      }
    }
    else {
      // search for the entity admin role
      const roles = await roleApi.getRoles();
      const entityAdminRole = roles.data.data.find((role: any) => role.name === 'Entity Admin');
      // add the entity admin role for the current entity and user
      await teamUserAccessApi.createTeamUserAccess({
        username: user.username,
        role_id: entityAdminRole.id,
        entity_id: selectedEntityId,
      });
      setIsUserEntityAdmin(true);
      message.success(t('Entity Admin role added'));
    }
    loadAccessData();
    setLoading(false);
  }

  return (
    <UserDetail 
      user={user} 
      handleViewTeam={handleViewTeam} 
      pagedResult={pagedResult} 
      tableSpec={spec} 
      onPageChange={handlePageChange} 
      isUserEntityAdmin={isUserEntityAdmin}
      onChangeEntityAdmin={changeEntityAdmin}
      currentUserRoles={roles}
      loading={loading}
    />
  )
}

export default UserDetailContainer;