import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from 'antd';
import './chillHoursAndPortions.less';

export const CollapseWrapper = ({ title, children }) => (
  <Collapse className="margin-bottom-10" defaultActiveKey={['main']}>
    <Collapse.Panel
      key="main"
      header={(
        <Typography.Title
          className="collapse-header-title"
          level={4}
        >
          {title}
        </Typography.Title>
      )}
    >
      {children}
    </Collapse.Panel>
  </Collapse>
);

CollapseWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
