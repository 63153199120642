import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, SettingFilled } from '@ant-design/icons';
import {
  Radio, Button, Modal, Divider, Typography, Select,
} from 'antd';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { actions, selectors, hooks } from 'farmx-redux-core';
import './home.css';
import InfiniteScroll from 'react-infinite-scroller';
import { compareRecommendations, filterInvalidAnomalyRecommendations } from './recommendation';
import { RecommendationListItem } from './RecommendationListItem';
import { PageHeader } from '../components/PageHeader';
import { useTracking } from '../../../helper/mixpanel';
import { NoDataCard } from '../components/NoDataCard';
import ReactContentLoader from '../components/ContentLoader';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectRecommendationsForBlocks,
  selectLoadingRecommendations,
  selectLoginUserInfo,
  selectAnomaliesForBlocks,
  selectAllNewBlocks,
  selectNewBlocksLoadingState,
} = selectors;

const {
  setRanchBlockSelection,
  setRanch, setBlocks, loadRecommendations,
} = actions;

const { useRanchBlockSelection, useEntities } = hooks;

const filterOptions = [
  { displayName: 'All', type: 'all' },
  { displayName: 'Irrigation', type: 'irrigation' },
  { displayName: 'Crop Stress', type: 'imagery_anomaly' },
  { displayName: 'Plant Health', type: 'ground_mission' },
];

export function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tracking = useTracking();
  const [selectedTab, setSelectedTab] = useState('new');
  const [anomalies, setAnomalies] = useState([]);

  const userInfo = useSelector(selectLoginUserInfo).payload;

  // get selected blocks
  const { selectedObjFromState, blockIds, ranch } = useRanchBlockSelection();

  const { activeEntityId } = useEntities();

  // for InfiniteScroll
  const [recommendObj, setRecommendObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(recommendObj[count]);
  // Flag to determine whether the intial tab has been loaded.
  const [loadTabFlag, setLoadTabFlag] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  // Maintain state for selected filter type
  const [selectedFilter, setSelectedFilter] = useState(
    () => localStorage.getItem('selectedFilter') || 'all'
  );
  const chunkSize = 8;
  const allBlockIds = useSelector((state) => selectAllNewBlocks(state))?.map((d) => d.id);
  // If only entity is selected consider it as all ranch
  const isAllRanch = ['All Ranches', 'entity'].includes(selectedObjFromState?.type);
  // get recommendations from redux
  const recommendations = useSelector((state) => selectRecommendationsForBlocks(state, isAllRanch
    ? allBlockIds : blockIds))
    ?.filter((d) => d);

  /**
   * recommendationListObj contains data in format
   * { new: [], planned: [], dismissed: [] }
   */
  const recommendationListObj = useMemo(() => (
    recommendations.reduce((recommendationsList, currentRecommendation) => {
      if (currentRecommendation?.isFrozen === false) {
        // New tab
        recommendationsList.new.push(currentRecommendation);
      } else if (currentRecommendation?.freezeType === 'action'
      && currentRecommendation.isFrozen) {
        // Planned tab
        recommendationsList.planned.push(currentRecommendation);
      } else if (currentRecommendation) {
        // Dismissed tab
        recommendationsList.dismissed.push(currentRecommendation);
      }
      return recommendationsList;
    }, { new: [], dismissed: [], planned: [] })
  ), [recommendations]);
  const recommendationsFiltered = filterInvalidAnomalyRecommendations(
    recommendationListObj[selectedTab]
  );

  const recommendationsSorted = recommendationsFiltered.sort(compareRecommendations);
  const recommendationLoadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const blockLoadingStatus = useSelector((state) => selectNewBlocksLoadingState(state));

  const isLoading = recommendationLoadingStatus.loading || blockLoadingStatus.loading;

  const tabOptions = [
    { label: t('New'), value: 'new' },
    { label: t('Planned'), value: 'planned' },
    { label: t('Dismissed'), value: 'dismissed' },
  ];
  const [modal, setModal] = useState(false);

  // Filter the recommendations based on the selected type
  const filteredRecommendations = selectedFilter === 'all'
    ? recommendationsSorted
    : recommendationsSorted.filter((item) => item.type === selectedFilter);

  const droneRecommendations = recommendations
    .filter((d) => d?.type === 'ground_mission' || d?.droneBlockImageryAnalytics);
  const anomalyRecommendations = recommendationsSorted
    .filter((d) => d?.type === 'imagery_anomaly');
  const anomalyBlockIds = anomalyRecommendations.map((d) => d?.block)
    .filter((v, i, a) => a.indexOf(v) === i);
  const anomalyIds = anomalyRecommendations.map((d) => d?.imageryAnomaly).filter((id) => id);
  const anomalyForBlocks = useSelector((state) => selectAnomaliesForBlocks(state, anomalyBlockIds));

  // To avoid multiple API call with same data
  // This logic will be moved to helper file in future
  const anomalyData = JSON.stringify(anomalies);
  const anomalyDataFromState = JSON.stringify(anomalyForBlocks);
  const anomalyIdsArr = JSON.stringify(anomalyIds);
  useEffect(() => {
    const anomaliesParsed = JSON.parse(anomalyDataFromState);
    const anomalyIdsParsed = JSON.parse(anomalyIdsArr);
    if (anomaliesParsed?.length) {
      const filteredResponse = anomaliesParsed.filter((d) => anomalyIdsParsed
        .includes(d?.properties?.id));
      const changedAnomalyData = [];
      filteredResponse.forEach((d) => {
        const filteredRecommendation = anomalyRecommendations
          .filter((a) => a?.block === d?.properties?.block).map((a) => ({ ...a, anomaly: d }));
        changedAnomalyData.push(filteredRecommendation[0]);
      });
      if (JSON.stringify(changedAnomalyData) !== anomalyData) {
        setAnomalies(changedAnomalyData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyData, anomalyDataFromState, anomalyIdsArr]);

  useEffect(() => {
    if (activeEntityId){
      dispatch(loadRecommendations(activeEntityId));
    }
  }, [dispatch, activeEntityId]);

  useEffect(() => {
    if (tracking) {
      tracking.track('Loaded Recommendations Page');
    }
  }, [tracking]);


  useEffect(() => {
    if (recommendationsSorted && recommendationsSorted.length && !recommendObj[0].length
      && selectedObjFromState.type) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < recommendationsSorted.length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(recommendationsSorted[i]);
      }
      setRecommendObj(obj);
      setItems(obj[0]);
      if (recommendationsSorted.length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [recommendationsSorted, recommendObj, selectedObjFromState.type]);

  const fetchMoreData = useCallback(() => {
    function handleItems(recommededArr) {
      const prevItems = items;
      const newItems = prevItems.concat(recommededArr);
      return newItems;
    }

    if (recommendObj[count + 1]) {
      setHasMoreItems(true);
      setItems(handleItems(recommendObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, recommendObj, items]);

  function handleSelect(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      if (d?.type === 'block' && !isMobile) dispatch(setBlocks([Number(d?.value)]));
      if (d?.type === 'ranch' && !isMobile) dispatch(setRanch(Number(d?.value)));
      setItems([]);
      setRecommendObj({ 0: [] });
      setCount(0);
      setHasMoreItems(false);
    }
  }

  // Retain tab selection when navigating to detail screen
  const loadInitialTab = useCallback((initialTab = 'new') => {
    // Ensure tab selection doesn't keep on running
    if (!loadTabFlag) {
      setSelectedTab(initialTab);
      setLoadTabFlag(true);
    }
  }, [loadTabFlag]);

  // Retain recommendation type filter selection
  useEffect(() => {
    localStorage.setItem('selectedFilter', selectedFilter);
  }, [selectedFilter]);

  function renderRecommendations(recommendedItems, loading) {
    if (recommendedItems && recommendedItems.length && !loading) {
      return recommendedItems.map((recommendation) => (
        <RecommendationListItem
          key={recommendation.id}
          recommendation={recommendation}
          selectedTab={selectedTab}
          loadInitialTab={loadInitialTab}
          anomalies={anomalies}
          droneRecommendations={droneRecommendations}
        />
      ));
    } if (recommendedItems && !recommendedItems.length && !loading
      && (blockIds?.length || ranch?.length || selectedObjFromState?.type === 'entity')) {
      return <NoDataCard title={t('No recommendations found')} />;
    }

    return <ReactContentLoader />;
  }

  function renderPreferenceModal() {
    return (
      <Modal
        visible={modal}
        centered
        closable
        onCancel={() => setModal(false)}
        footer={null}
      >
        <>
          <div className="flex-column margin-top-10 margin-bottom-10">
            <Typography.Title level={4}>{t('Recommendation Settings')}</Typography.Title>
            <span className="preference-model-sections-header">
              {t('Automatic Scheduling')}
            </span>
            <Link to="/settings/blocks">
              {t('Set Automatic Scheduling preferences here')}
            </Link>
          </div>
          <Divider />
          <div className="margin-top-10">
            <span className="preference-model-sections-header">
              {t('Recommendation Model')}
            </span>
            <p>
              {t(`FarmX provides both Soil + ETc Deficit and ETc Deficit recommendations.
              Soil + ETc Deficit recommendations are used by default.`)}
            </p>
            <p>
              {t('Please contact FarmX at ')}
              <Link
                to="/#"
                onClick={(e) => {
                  window.location.href = 'mailto:support@farmx.co';
                  e.preventDefault();
                }}
              >
                {t('support@farmx.co')}
              </Link>
              {t(' to configure ETc only Irrigation Recommendations.')}
            </p>
          </div>
        </>
      </Modal>
    );
  }

  function renderRecommendationTypeFilter() {
    return(
      <>
        <h4 className="capitalize-bold filter-type-text">
          {t('type')}
        </h4>
        <Select
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e)}
          popupClassName="map-select-dropdown-settings"
          className={isMobile ? 'width-space' :'filter-dropdown-min-width'}
        >
          {filterOptions.map((option) => (
            <option key={option.type} value={option.type}>
              {option.displayName}
            </option>
          ))}
        </Select>
      </>
    );
  }

  return (
    <div id="recommended-scrollable-container" className="recommended-scrollable-container">
      <div className="flex-column margin-10 page-content">
        <div className="inline-page-loader-div justify-content-space-between">
          <div className="flex-row">
            <PageHeader title={t('Recommendations')} />
            {isLoading ? (
              <div className="loader-common">
                <LoadingOutlined />
              </div>
            ) : null}
          </div>
          <Button
            className="preference-button"
            onClick={() => setModal(true)}
            icon={(
              <span className="preference-modal-settings-icon">
                <SettingFilled />
              </span>
            )}
          >
            <strong>{t('Preferences')}</strong>
          </Button>
          {modal && renderPreferenceModal()}
        </div>
        <div className="div-select-container ranch-block-select-container">
          {isMobile && (
          <RanchBlockSelectMobile
            onSelect={handleSelect}
            selected={selectedObjFromState}
            admin={userInfo && userInfo.admin}
          />
          )}
        </div>
        <div className="page-tab-container tabs-space">
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => {
              setSelectedTab(e.target.value);
              if (tracking) {
                tracking.track(`Selected ${e.target.value} notifications`);
              }
            }}
            options={tabOptions}
            optionType="button"
            size="large"
            value={selectedTab}
          />
          <div className="recommendation-filter-type">
            {renderRecommendationTypeFilter()}
          </div>
        </div>
        <div className="home-container">
          {renderRecommendations(filteredRecommendations, recommendations?.length
            ? false : isLoading)}
        </div>
      </div>
    </div>
  );
}
