import React, {
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { generateValveOption } from 'helper/valveChartHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_STATE } from './constants';

export default function ValveStateChart(props) {
  const {
    sensor, startDate, endDate, cached,
  } = props;
  const { t } = useTranslation();

  const customConfig = useCallback((configObj, seriesData) => {
    const newConfig = generateValveOption(seriesData);
    const {yAxis} = newConfig;
    return {
      ...newConfig,
      chart: {
        ...newConfig.chart,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 0,
        marginRight: 0
      },
      title: '',
      xAxis: {
        ...configObj.xAxis,
      },
      yAxis: {
        ...yAxis,
        labels: {
          ...yAxis.labels,
          x: 5,
          y: -5,
          align: 'left',
        }
      }
    };
  }, []);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_STATE}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Valve State'), units: '', right: []}}
      />
    </>
  );
}

ValveStateChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  cached: PropTypes.string,
};

ValveStateChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
};
