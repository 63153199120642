import * as turf from '@turf/turf';

// Function to find the nearest group
function findNearestGroup(point, groups, maxDistance) {
  let minDistance = Number.POSITIVE_INFINITY;
  let nearestGroup = null;
  const keys = Object.keys(groups);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    const clusterCenter = turf.center(groups[key]);
    const distance = turf.distance(point, clusterCenter);

    if (distance < minDistance) {
      minDistance = distance;
      nearestGroup = key;
    }
  }

  if (minDistance <= maxDistance) {
    return nearestGroup;
  }

  return null;
}

export function getClusterData(geojsonData) {
  if (geojsonData?.features?.length) {
    // Group the data by a specific key attribute
    const groupedData = geojsonData.features.reduce((groups, feature) => {
      const key = feature.properties.healthClassification;
      const tempGroup = { ...groups };
      if (!tempGroup[key]) {
        tempGroup[key] = turf.featureCollection([]);
      }
      tempGroup[key].features.push(feature);
      return tempGroup;
    }, {});

    // Create an object to store clusters
    const clusters = {};

    // Iterate through features and create clusters based on the 'key' property
    const keys = Object.keys(groupedData);

    // This maxDistance will be changed in future
    let maxDistance = 1;
    if (keys.length > 1) maxDistance = 0.1;

    // Calculate clusters
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const group = groupedData[key];

      group.features.forEach((feature) => {
        const nearestGroup = findNearestGroup(feature, clusters, maxDistance);

        if (nearestGroup) {
          // Add the feature to the nearest group
          clusters[nearestGroup].features.push(feature);
        } else {
          // Create a new cluster for this feature
          clusters[key] = turf.featureCollection([feature]);
        }
      });
    }

    return clusters;
  }
  return null;
}

export function getConcaveHull(points, featureId) {
  if (points?.features?.length) {
    // Adjust maxEdge as needed to separate the cluster
    const concaveHullOptions = { units: 'kilometers', maxEdge: 0.05 };

    const concaveHull = turf.concave(points, concaveHullOptions);

    const conCaveHullCoords = concaveHull?.geometry?.coordinates || [];

    const concaveHullFeature = conCaveHullCoords.map((coordinates, i) => ({
      type: 'Feature',
      properties: {
        id: `${featureId}-${i}`,
        features: points?.features,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    }));

    return concaveHullFeature;
  }
  return [];
}
