import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Checkbox, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { withUserSettings } from './withUserSettings';
import { isMobile } from '../../../utils/detectDevice';
import { FollowBlockSettings } from '../../settings/components/FollowBlockSettings/FollowBlockSettings';
const {
  patchUserSettings,
} = actions;

function Notifications() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => selectors.selectUserSettings(state));
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (userSettings) {
      const initialSettings = {
        errorAlert: userSettings.log_level_error,
        infoAlert: userSettings.log_level_info,
        warningAlert: userSettings.log_level_warn,
        emailAlert: userSettings.email_notifications,
        textAlert: userSettings.text_notifications,
      };

      setSettings(initialSettings);
    }
  }, [dispatch, userSettings]);

  const udpateNotifications = (e) => {
    const { name, checked, dataKey } = e.target;

    setSettings((oldSettings) => ({
      ...oldSettings,
      [name]: checked,
    }));

    dispatch(patchUserSettings({ [dataKey]: checked }));
  };

  const {
    errorAlert,
    infoAlert,
    warningAlert,
    emailAlert,
    textAlert,
  } = settings;

  return (
    <div className="settings-notifications-container div-default white-bg padding-20">
      <PageHeader
        showBack={!!isMobile}
        title={t('Notifications')}
        toPath="/settings"
      />
      <div className="notifications-content padding-10">
        <div className="checkbox-group">
          <Typography.Title level={4}>{`${t('Notification Types')}:`}</Typography.Title>
          <div className="notification-cb">
            <Checkbox
              checked={errorAlert}
              name="errorAlert"
              onChange={udpateNotifications}
              dataKey="log_level_error"
            />
            <Typography.Text type="secondary">{t('Error')}</Typography.Text>
          </div>
          <div className="notification-cb">
            <Checkbox
              checked={warningAlert}
              name="warningAlert"
              onChange={udpateNotifications}
              dataKey="log_level_warn"
            />
            <Typography.Text type="secondary">{t('Warnings')}</Typography.Text>
          </div>
          <div className="notification-cb">
            <Checkbox
              checked={infoAlert}
              name="infoAlert"
              onChange={udpateNotifications}
              dataKey="log_level_info"
            />
            <Typography.Text type="secondary">{t('Information')}</Typography.Text>
          </div>
        </div>

        <div className="checkbox-group">
          <Typography.Title level={4}>{`${t('Notification Delivery')}:`}</Typography.Title>
          <div className="notification-cb">
            <Checkbox
              checked={emailAlert}
              name="emailAlert"
              onChange={udpateNotifications}
              dataKey="email_notifications"
            />
            <Typography.Text type="secondary">{t('Email Notifications')}</Typography.Text>
          </div>
          <div className="notification-cb">
            <Checkbox
              checked={textAlert}
              name="textAlert"
              onChange={udpateNotifications}
              dataKey="text_notifications"
            />
            <Typography.Text type="secondary">{t('Text Notifications')}</Typography.Text>
          </div>
        </div>
        <FollowBlockSettings />
      </div>
    </div>
  );
}

export const NotificationsSettings = withUserSettings(Notifications);
