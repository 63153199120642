import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Select,
} from 'antd';
import { EventTypeSelectProps } from './types';

const EventTypeSelect = ({ onChange, value, placeholder } : EventTypeSelectProps) => {
  const { t } = useTranslation();
  const options = [
    {
      value: 'anomaly',
      label: t('Anomaly'),
    },
    {
      value: 'battery',
      label: t('Battery'),
    },
    {
      value: 'connectivity',
      label: t('Connectivity'),
    },
    {
      value: 'override',
      label: t('Override'),
    },
    {
      value: 'schedule',
      label: t('Schedule'),
    },
    {
      value: 'status',
      label: t('Status'),
    },
  ];

  return (
    <Select
      value={value}
      placeholder={placeholder || t('Event Type')}
      options={options}
      onChange={onChange}
      allowClear
      dropdownMatchSelectWidth={false}
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};

export default EventTypeSelect;
