import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StatusTag } from '../components/StatusTag';
import { isMobile } from '../../../utils/detectDevice';
import './ForecastCard.less';
import { getTextForIrrigationState, getStateForIrrigationState } from './recommendation';

const statusTag = (text, state, t) => (
  <StatusTag
    width="auto"
    text={t(text)}
    state={state}
    tagStyle={{ fontSize: '16px', padding: '4px', marginBottom: '10px' }}
    isCentered
  />
);

export default function ForecastCard({ recommendation }){
  const { t } = useTranslation();

  const forecastDays = () => (
    <div className="flex-row align-items-center forecast-day-count margin-top-10">
      {t(`${recommendation?.duration?.[0]} Days`)}
    </div>
  );

  // className for available width space
  const widthAvailableSpace = !isMobile && 'width-space';
  return(
    <div className="flex-row justify-content-space-evenly width-space">
      <div className="grid-display width-space ">
        <em className="text-align-center">{t('Current Conditions')}</em>
        {statusTag(getTextForIrrigationState(recommendation?.state),
          getStateForIrrigationState(recommendation?.state), t)}
      </div>
      <div
        className={`${widthAvailableSpace} grid-display forecast-container margin-top-10
        flex-row justify-content-center relative-position right-arrow-padding align-item`}
      >
        {!isMobile
        && (
          <>
            <div
              className="flex-row align-items-center straight-arrow-line"
            />
            {forecastDays()}
            <div
              className="flex-row align-items-center straight-arrow-line"
            />
          </>
        )}
        <RightOutlined className="right-arrow-outline" />
      </div>
      <div className="width-space grid-display forecast-state-text">
        <em className="text-align-center">
          {recommendation?.duration?.[0]}
          {' '}
          {t('Day Forecast')}
        </em>
        {statusTag(getTextForIrrigationState(recommendation?.forecastState),
          getStateForIrrigationState(recommendation?.forecastState), t)}
      </div>
    </div>
  );
}

ForecastCard.propTypes = {
  recommendation: PropTypes.shape({
    state: PropTypes.string,
    duration: PropTypes.number,
    forecastState: PropTypes.string,
  }),
};

ForecastCard.defaultProps = {
  recommendation: null,
};
