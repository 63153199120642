import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { isMobile } from 'react-device-detect';
import {
  AIR_HUMIDITY,
  AIR_TEMPERATURE,
  ET0,
  SOLAR_RADIATION,
  WIND_SPEED,
  WIND_DIRECTION,
} from 'components/hooks/graph';

const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'ETO',
    value: ET0,
    key: ET0,
  },
  {
    title: 'Air Temperature',
    value: AIR_TEMPERATURE,
    key: AIR_TEMPERATURE,
  },
  {
    title: 'Air Humidity',
    value: AIR_HUMIDITY,
    key: AIR_HUMIDITY,
  },
  {
    title: 'Solar Radiation',
    value: SOLAR_RADIATION,
    key: SOLAR_RADIATION,
  },
  {
    title: 'Wind Speed',
    value: WIND_SPEED,
    key: WIND_SPEED,
  },
  {
    title: 'Wind Direction',
    value: WIND_DIRECTION,
    key: WIND_DIRECTION,
  },
];

const SoilPresetChartSelect = ({onSelectChange}:{onSelectChange: Function}) => {
  const [value, setValue] = useState([ET0, AIR_TEMPERATURE, AIR_HUMIDITY]);

  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };

  useEffect(() => {
    onSelectChange(value);
  }, [onSelectChange, value]);

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Charts',
    className: 'soil-preset-chart-select',
    dropdownStyle: isMobile?{
      position: 'fixed',
      height: '100%',
      padding: '20px'
    }:{},
    getPopupContainer: () => document.getElementById('weatherPresetChartSelect'),
  };

  return (
    <div id="weatherPresetChartSelect">
      <TreeSelect {...tProps} />
    </div>

  );
};


SoilPresetChartSelect.defaultProps = {
  onSelectChange: () => null,
};

export default SoilPresetChartSelect;
