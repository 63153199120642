import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Table } from 'antd';

import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { WeatherInfo } from './WeatherInfo';
import Icon from '../components/Icon';
import { CardLayout } from '../components/CardLayout';
import { displayInchesInQuotes } from '../../../helper/listHelper';

const {
  useUnits,
} = hooks;

const { selectSensorLoadingStatus } = selectors;
export function WeatherDetail(props) {
  const {
    weatherForecast,
    weatherHistory,
    fromUnits,
    list,
  } = props;

  const { t } = useTranslation();
  const [showForecast, setShowForecast] = useState(true);
  const [data, setData] = useState([]);
  const getUserUnits = useUnits();
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));

  useEffect(() => {
    const weatherData = showForecast ? weatherForecast : weatherHistory;
    setData(weatherData);
  }, [showForecast, weatherForecast, weatherHistory]);

  const columns = useMemo(() => {
    const getLabel = (label) => ((label !== undefined && label !== null) ? label : '');
    const getPercentLabel = (label) => ((label !== undefined && label !== null) ? Math.round(label * 100) : '');
    const displayTemperature = (airTemperature) => (
      getUserUnits(getLabel(airTemperature), fromUnits.airTemperature, 'temperature').value
    );
    const displayPrecipitation = (precipitation) => {
      const convertedPrecipitation = getUserUnits(
        getLabel(precipitation), fromUnits.precipitation, 'depth', { decimalPlaces: 2 },
      );
      if (convertedPrecipitation.label !== 'in') {
        convertedPrecipitation.label = ` ${convertedPrecipitation.label}`;
      }
      return `${convertedPrecipitation.value}${displayInchesInQuotes(convertedPrecipitation.label)}`;
    };

    const getConvertedET0 = (et0Value) => {
      const unit = { inches_per_day: 'inches' };
      const dataUnit = unit[fromUnits.et0] || 'inches';
      const {value: convertedET0 } = getUserUnits(et0Value, dataUnit, 'depth', {decimalPlaces: 2});
      return convertedET0;
    };

    return [
      {
        title: <Typography.Title level={5}>{t('Date')}</Typography.Title>,
        dataIndex: 'date',
        key: 'date',
        render: (date) => <Moment className="min-width-60" format="ddd D">{date}</Moment>,
      },
      {
        title: <Typography.Title level={5}>{`${t('Hi')}/${t('Lo')}`}</Typography.Title>,
        dataIndex: 'airTemperatureMax',
        key: 'airTemperatureMax',
        width: 100,
        render: (text, record) => (
          <div>
            <span className="fh-temperature">
              <Typography.Text className="max-temp deg" strong>
                {displayTemperature(record.airTemperatureMax)}
              </Typography.Text>
            </span>
            <span className="fh-temperature">
              <Typography.Text className="deg">
                {` / ${displayTemperature(record.airTemperatureMin)}`}
              </Typography.Text>
            </span>
          </div>
        ),
      },
      {
        title: <i className="icon icon-cloud-sun cloud-sun" />,
        dataIndex: 'twcIconCode',
        key: 'twcIconCode',
        render: (twcIconCode) => <Icon name={`${twcIconCode}`} size={20} />,
      },
      {
        title: <i className="primary-color icon icon-waterdrop waterdrop" />,
        dataIndex: 'precipitation',
        key: 'precipitation',
        render: (precipitation) => (
          showForecast
            ? <Typography.Text>{`${getPercentLabel(precipitation)}%`}</Typography.Text>
            : <Typography.Text>{`${displayPrecipitation(precipitation)}`}</Typography.Text>
        ),
      },
      {
        title: <Typography.Title level={5}>{t('ET0')}</Typography.Title>,
        dataIndex: 'et0',
        key: 'et0',
        render: (et0 = '-') => (
          <Typography.Text>{getConvertedET0(et0)}</Typography.Text>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromUnits, t, showForecast]);

  return (
    <CardLayout>
      <div className="status-buttons">
        <button
          className={`button left-border ${showForecast ? 'selected' : ''}`}
          onClick={() => setShowForecast(true)}
          type="button"
        >
          {t('10 Day Forecast')}
        </button>
        <button
          className={`button right-border ${!showForecast ? 'selected' : ''}`}
          onClick={() => setShowForecast(false)}
          type="button"
        >
          {t('10 Day History')}
        </button>
      </div>

      {false && (
        <div className="weather-info-container">
          <div className="flex-row">
            <WeatherInfo
              icon={<i className="icon waterdrop icon-waterdrop" />}
              label={t('Humidity')}
              value="94%"
            />
            <WeatherInfo
              icon={<Icon name="sun" size={14} />}
              label={t('UV Index')}
              value="0 of 10"
            />
          </div>
          <div className="flex-row">
            <WeatherInfo
              icon={<Icon name="moonrise" size={14} />}
              label={t('Moonrise')}
              value="12:47 pm"
            />
            <WeatherInfo
              icon={<Icon name="moonset" size={14} />}
              label={t('Moonset')}
              value="10:50 pm"
            />
          </div>
        </div>
      )}

      <div className="forecast-history-list">
        <Table
          loading={!data.length && loading}
          className="weather-list-table"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </CardLayout>
  );
}

WeatherDetail.defaultProps = {
  fromUnits: {
    airTemperature: 'degrees_fahrenheit',
    precipitation: 'inches',
    et0: 'inches',
  },
  list: [],
};

WeatherDetail.propTypes = {
  fromUnits: PropTypes.shape({
    airTemperature: PropTypes.string,
    precipitation: PropTypes.string,
    et0: PropTypes.string,
  }),
  weatherForecast: PropTypes.arrayOf(PropTypes.object).isRequired,
  weatherHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
};
