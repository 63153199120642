export const initReducerAnomaly = () => ({
  satelliteDates: undefined,
  selectedSatelliteDate: undefined,
  selectedSatelliteDataType: 'NDVI',
  selectedSatelliteDataSource: 'satellite',
  selectedSatelliteDatesFiltered: undefined,
  selectedSatelliteDatesDataRange: undefined,
  dateRangeWithDisplayData: undefined,
  selectedImageryData: {
    visible: true,
    data: undefined,
  },
  deleteAnomaly: { delete: false, save: false, cancel: false },
  selectedDroneDataType: 'RGB',
  selectedDroneData: {
    visible: true,
    data: {},
  },
  selectedDroneImagery: {
    visible: true,
    data: undefined,
  },
  selectedDroneTilesData: undefined,
  plantsGeoJSON: null,
  showPlants: true,
  selectedDroneDate: undefined,
  availableDroneDates: undefined,
  anomalyArea: null,
  anomalyComparison: null,
  isLoading: false,
});

export function reducerAnomaly(state, action) {
  switch (action.type) {
    case 'setSelectedImageryData':
      return {
        ...state,
        selectedImageryData: action.payload,
      };
    case 'setDateRangeWithDisplayData':
      return {
        ...state,
        dateRangeWithDisplayData: action.payload,
      };
    case 'setSelectedSatelliteDatesDataRange':
      return {
        ...state,
        selectedSatelliteDatesDataRange: action.payload,
      };
    case 'setSelectedSatelliteDatesFiltered':
      return {
        ...state,
        selectedSatelliteDatesFiltered: action.payload,
      };
    case 'setSelectedSatelliteDataSource':
      return {
        ...state,
        selectedSatelliteDataSource: action.payload,
      };
    case 'setSelectedSatelliteDataType':
      return {
        ...state,
        selectedSatelliteDataType: action.payload,
      };
    case 'setSelectedSatelliteDate':
      return {
        ...state,
        selectedSatelliteDate: action.payload,
      };
    case 'setSatelliteDates':
      return {
        ...state,
        satelliteDates: action.payload,
      };
    case 'setDeleteAnomaly':
      return {
        ...state,
        deleteAnomaly: action.payload,
      };
    case 'setSelectedDroneImagery':
      return {
        ...state,
        selectedDroneImagery: action.payload,
      };
    case 'setSelectedDroneData':
      return {
        ...state,
        selectedDroneData: action.payload,
      };
    case 'setSelectedDroneDate':
      return {
        ...state,
        selectedDroneDate: action.payload,
      };
    case 'setAvailableDroneDates':
      return {
        ...state,
        availableDroneDates: action.payload,
      };
    case 'setSelectedDroneDataType':
      return {
        ...state,
        selectedDroneDataType: action.payload,
      };
    case 'setSelectedDroneTilesData':
      return {
        ...state,
        selectedDroneTilesData: action.payload,
      };
    case 'setPlantsData':
      return {
        ...state,
        plantsGeoJSON: action.payload,
      };
    case 'setShowPlants':
      return {
        ...state,
        showPlants: action.payload,
      };
    case 'setIsLoading': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'setAnomalyAreaData':
      return {
        ...state,
        anomalyArea: action.payload,
      };
    case 'setAnomalyComparisonData':
      return {
        ...state,
        anomalyComparison: action.payload,
      };
    case 'reset':
      return initReducerAnomaly();
    default:
      throw new Error('Unknown action type');
  }
}
