/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export const TOOLTIP_TITLES = {
  summary: {
    currentDeficit: {
      title:
  <>
    Formula:
    <br />
    Converted "deficit" value based on user length setting
  </>
    },
    etc: {
      title:
  <>
    Formula:
    <br />
    Converted "etcForecast" value based on user length setting
  </>
    },
    scheduled: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "scheduledDuration" from date to hours
  </>
    },
    totalDeficit: {
      title:
  <>
    Formula:
    <br />
    Total Deficit = deficit + etcForecast
  </>
    },
    volumeDeficit: {
      title:
  <>
    Formula:
    <br />
    1. Total Deficit (mm) = Deficit (mm) + ETc Forecast (mm)
    <br />
    2. Convert Total Deficit to Meters: Total Deficit (m) = Total Deficit (mm) / 1000
    <br />
    3. Calculate Root Zone Area Ratio:
    <br />
&nbsp;&nbsp;- Root Zone Area Ratio = (Root Zone Area) / (Plant Area in m²)
    <br />
    4. Calculate Volume Deficit (m³):
    <br />
&nbsp;&nbsp;- Volume Deficit (m³) = Total Deficit (m) × Block Area (m²) × Root Zone Area Ratio
    <br />
    5. Convert Volume Deficit to User Units (e.g., Gallons, liters)
  </>
    },
    applicationRate: {
      title:
  <>
    Formula:
    <br />
    The "application_rate" of the block
  </>
    },
    precipForecast: {
      title:
  <>
    Formula:
    <br />
    The sum of "precipForecastDaily" from the recommendation data.
    <br />
    The value is converted based on the user length settings
  </>
    },
    toatlRuntime: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "irrigationDuration" from date to hours
  </>
    },
  },
  availableWater: {
    rootzoneDepth: {
      title:
  <>
    Formula:
    <br />
    The converted value of the "depthRootzone" from the sensor status
  </>
    },
    rootzoneWater: {
      title:
  <>
    Formula:
    <br />
    1.Get "vwcRootzone" from the sensor status
    <br />
    2.Then multiply with rootzone depth to get rootzone water
  </>
    },
    totalAvailableWater: {
      title:
  <>
    Formula:
    <br />
    Total available water = The sum of "rootzone water" and "deficit"
  </>
    },
    deficit: {
      title:
  <>
    Formula:
    <br />
    Converted "deficit" value based on user length setting
  </>
    },
    targetDeficit: {
      title:
  <>
    Formula:
    <br />
    Converted "deficit" value based on user length setting
  </>
    },
  },
  etc: {
    et0Total: {
      title:
  <>
    Formula:
    <br />
    The sum ET0 value from "et0ForecastDaily" data
  </>
    },
    kc: {
      title:
  <>
    Formula:
    <br />
    The average Kc value from "kcDaily" data
  </>
    },
    etcTotal: {
      title:
  <>
    Formula:
    <br />
    The sum ETc value from "etcForecastDaily" data
  </>
    },
  },
  irrigationSystem: {
    blockArea: {
      title:
  <>
    Formula:
    <br />
    1. Retrieve Block Area
    <br />
    2. Convert Plant Area from Square Feet to Square Meters
    <br />
    3. Convert Block Area based on User Settings
  </>
    },
    plantArea: {
      title:
  <>
    Formula:
    <br />
    Convert Plant Area from Square Feet to Square Meters
  </>
    },
    irrigationArea: {
      title:
  <>
    Formula:
    <br />
    1. Multiply Block Area by Rootzone Area:
    <br />
&nbsp;&nbsp;- TotalRootzoneArea = blockArea * rootzoneArea
    <br />
    2. Divide by Plant Area to get Irrigation Area:
    <br />
&nbsp;&nbsp;- irrigationArea = TotalRootzoneArea / plantArea
  </>
    },
    emitterSpacing: {
      title:
  <>
    Formula:
    <br />
    1. Check for Valid Values:
    <br />
&nbsp;&nbsp;- Ensure that both emitterSpacingInM2 and rowSpacing are numbers.
    <br />
    2. Calculate Emitter Spacing:
    <br />
&nbsp;&nbsp;- emitterSpacing = (emitterSpacingInM2 / rowSpacing) * irrigationLines
    <br />
    Explanation:
    <br />
&nbsp;&nbsp;- Divide the emitter spacing in square meters by the row spacing.
    <br />
    &nbsp;&nbsp;
    - Multiply the result by the number of irrigation lines to get the total emitter spacing.
  </>
    },
    systemRate: {
      title:
  <>
    Formula:
    <br />
    1. Determine System Rate in Gallons per Hour (GPH):
    <br />
&nbsp;&nbsp;- If systemApplicationRate is provided, use that value.
    <br />
&nbsp;&nbsp;- Otherwise, calculate as:
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;
    - systemRateGph = emitterApplicationRateGph * (emitterCount or emitterCountFromEmitterSpacing)
    <br />
    2. Round System Rate to Specified Decimal Places:
    <br />
&nbsp;&nbsp;- Rounded value = roundToDecimalPlaces(systemRateGph)
    <br />
    3. Convert System Rate to User-Preferred Units
  </>
    },
  },
  runtimeDetails: {
    totalRecommended: {
      title:
  <>
    Formula:
    <br />
    Total recommended = Parsed value of the "irrigationDuration" + "scheduledDuration"
  </>
    },
    hoursScheduled: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "scheduledDuration" from date to hours
  </>
    },
    hoursNeeded: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "irrigationDuration" from date to hours
  </>
    },
    totalFieldCapacity: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "fieldCapacityDuration" + "scheduledDuration"
  </>
    },
    totalRefilPoint: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "refillPointDuration" + "scheduledDuration"
  </>
    },
    totalWiltingPoint: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "wiltingPointDuration" + "scheduledDuration"
  </>
    },
    fieldCapacity: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "fieldCapacityDuration"
  </>
    },
    refilPoint: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "refillPointDuration"
  </>
    },
    wiltingPoint: {
      title:
  <>
    Formula:
    <br />
    Parsed value of the "wiltingPointDuration"
  </>
    },
    precipDuration: {
      title:
  <>
    Formula:
    <br />
    1. Get the sum of precipitaion forecast daily data in millimeters then convert to meters.
    <br />
    2. Convert the value from meters to gallons
    <br />
    3. Convert the value from gallons to hours
    <br />
  </>
    }
  }
};
