import React from 'react';
import moment from 'moment';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { capabilities } from '../../../constants';
import { Sensor } from './types';

type EventLinkProps = {
  event:{
    sensor: Sensor | null;
    createdAt: string;
    block: number;
  };
  type: 'view-in-app' | 'view-graph';
};

const EventLink = (props: EventLinkProps) => {
  const { event, type } = props;
  const { block, createdAt, sensor } = event;
  const startDate = moment(createdAt).subtract(12, 'hours').toISOString();
  const endDate = moment(createdAt).add(12, 'hours').toISOString();

  function renderIcon() {
    return <FaExternalLinkAlt style={{ marginLeft: '3px', fontSize: '0.8em' }} />;
  }

  function renderViewInAppLink() {
    const strHref = `https://beta.farmx.co/graph?blockId=${block}&startDate=${startDate}&endDate=${endDate}`;
    return (
      <a target="_blank" rel="noopener noreferrer" href={strHref}>
        View In App
        {renderIcon()}
      </a>
    );
  }

  function renderViewGraphLink() {
    if (!sensor) {
      return null;
    }

    let variable = capabilities.pixl_soil.join(',');
    if (sensor?.type === 'water_pressure') {
      variable = capabilities.water_pressure.join(',');
    } else if (sensor?.type === 'pixl_soil') {
      variable = capabilities.pixl_soil.join(',');
    } else if (sensor?.type === 'aquacheck_soil') {
      variable = capabilities.aquacheck_soil.join(',');
    }

    const sensorType = sensor?.type;
    const sensorIdentifier = sensor?.identifier;

    const strHref = `https://admin.farmx.co/manage/graph?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}&variables=${variable}&startDate=${startDate}&endDate=${endDate}`;
    return (
      <a target="_blank" rel="noopener noreferrer" href={strHref}>
        View Graph
        {renderIcon()}
      </a>
    );
  }

  switch (type) {
    case 'view-in-app':
      return renderViewInAppLink();
    case 'view-graph':
      return renderViewGraphLink();
    default:
      return null;
  }
};

export default EventLink;
