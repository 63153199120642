import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import './DrawerImageryControl.less';
import { isMobile } from '../../../utils/detectDevice';

export default function DrawerImageryControl({
  onClick, activeImagery, selected,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex-row">
        <div
          className={activeImagery === 'fullScreen'
            ? 'fullScreen-selected fullScreen-button-section screen-button-section'
            : 'fullScreen fullScreen-button-section screen-button-section'}
          role="button"
          id="fullScreen"
          tabIndex="0"
          onClick={onClick}
          onKeyPress={onClick}
        />
        <div
          className={activeImagery !== 'splitScreen'
            ? 'splitScreen splitScreen-button-section screen-button-section'
            : 'splitScreen-selected splitScreen-button-section screen-button-section'}
          role="button"
          id="splitScreen"
          tabIndex="0"
          onClick={onClick}
          onKeyPress={onClick}
        />
      </div>

      {activeImagery === 'splitScreen' && (
      <div className="parent-container margin-top-10">
        <div className={isMobile ? 'left-child mobile-left-button' : 'left-child'}>
          <div className="flex-row">
            <div className="map-modal-section">
              <Button
                className={selected?.layersControl?.side === 'left' ? 'active-tab' : ''}
                onClick={() => onClick({ ...selected, type: 'tab', side: 'left' })}
                block
              >
                {t('Left')}
              </Button>
            </div>
          </div>
        </div>

        <div className={isMobile ? 'right-child mobile-right-button' : 'right-child'}>
          <div className="flex-row">
            <div className="map-modal-section splitScreen-split-button">
              <Button
                className={selected?.layersControl?.side === 'right' ? 'active-tab' : ''}
                onClick={() => onClick({ ...selected, type: 'tab', side: 'right' })}
                block
              >
                {t('Right')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

DrawerImageryControl.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeImagery: PropTypes.bool.isRequired,
  selected: PropTypes.shape({}).isRequired,
};
