import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Button, Select, DatePicker, Input, Typography, Switch, notification, Modal,
} from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectors, actions, hooks } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import {
  BlockSelect, Slider as CircularSlider,
} from 'farmx-web-ui';
import './styles.css';
import { useScheduleUpdate } from '../../mobile/schedule/useScheduleUpdate';
import { ScheduleEditOptionsModal } from '../../mobile/schedule/ScheduleEditOptionsModal';
import { WeekDays } from '../../mobile/schedule/components/WeekDays';
import { ScheduleDeleteModal } from '../../mobile/schedule/ScheduleDeleteModal';
import { updateScheduledEvent, getGallonsValue, getClosestMinute } from '../../../helper/scheduleHelper';
import { useUserTimeFormat } from '../../../helper/commonHooks';

const {
  setBlocks,
} = actions;

const { selectBlockSchedule, selectSelectedBlocks, selectNewBlockById } = selectors;
const dateFormat = 'dddd, D MMMM YYYY';
const minutesAllowedFromNow = 0;
const maxDurationHours = 72;
const minStep = 5;

const initializeStartDate = () => moment().startOf('day').add(moment().hour() + 1, 'hours');
const startDateTimeAllowed = (min) => (new Date((new Date()).getTime() + (min * 60000)));
const disabledDate = ((current) => current && current < moment().add('month'));

const ScheduleForm = ({
  onCancel,
  onSubmit,
  onChange,
  dateRange,
  blocks,
  scheduleId,
  initialValues,
  isEditMode,
  scheduledEvent,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // Below codes will be refactored
  // Recurrence changes
  const currentWeekday = moment().isoWeekday();
  const defaultStartDate = (dateRange && dateRange[0]) || initializeStartDate();
  const futureEndDate = moment().add(1, 'years');
  const defaultDuration = dateRange && dateRange.length
    ? moment(dateRange[1]).diff(dateRange[0], 'minutes') : 12 * 60;

  // Changing time format based on the user settings
  const userTimeFormat = useUserTimeFormat();

  const defaultValues = {
    blockIds: blocks || [],
    startDate: defaultStartDate,
    endDate: (dateRange && dateRange[1]) || defaultStartDate.clone().add(12, 'hours'),
    duration: defaultDuration,
    isRecurring: false,
    repeatFrequency: 'weekly',
    repeatInterval: 1,
    repeatEndCount: 3,
    repeatEndDate: futureEndDate,
    repeatEndType: 'never',
    repeatWeekdays: {
      Su: currentWeekday === 7,
      Mo: currentWeekday === 1,
      Tu: currentWeekday === 2,
      We: currentWeekday === 3,
      Th: currentWeekday === 4,
      Fr: currentWeekday === 5,
      Sa: currentWeekday === 6,
    },
    ...initialValues,
  };

  const [block, setBlock] = useState(defaultValues.blockIds);
  const blockNames = hooks.useBlockNames(block);
  const [createScheduleForBlocks] = useScheduleUpdate(blockNames);
  const newBlockIds = block.filter((blockId) => !defaultValues.blockIds.includes(blockId));
  const [btnEnabled, setButtonState] = useState(true);
  const [paramDateRange, setParamDateRange] = useState([]);
  const [validationStatus, setValidationStatus] = useState(false);
  const dispatch = useDispatch();
  const selectedBlocksFromState = useSelector((state) => selectSelectedBlocks(state));
  const blocksFromState = selectedBlocksFromState.payload;

  // Recurrence changes
  const repeatDayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { startDate } = defaultValues;
  const [duration, setDuration] = useState(defaultValues.duration);
  const [isRecurring, setIsRecurring] = useState(defaultValues.isRecurring);
  const [repeatInterval, setRepeatInterval] = useState(defaultValues.repeatInterval);
  const [repeatFrequency, setRepeatFrequency] = useState(defaultValues.repeatFrequency);
  const [weekDays, setWeekDays] = useState(defaultValues.repeatWeekdays);
  const [repeatEndType, setRepeatEndType] = useState(defaultValues.repeatEndType);
  const [repeatEndDate, setRepeatEndDate] = useState(defaultValues.repeatEndDate || futureEndDate);
  const [repeatEndCount, setRepeatEndCount] = useState(defaultValues.repeatEndCount);

  const [isEndDateCalendarOpen, setEndDateIsCalendarOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editEventModalIsVisible, setEditEventModalIsVisible] = useState(false);
  const [parsedEvent, setParsedEvent] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const blockObj = useSelector((state) => selectNewBlockById(state, block[0]));

  // For sequential changes
  const [isSequential, setIsSequential] = useState(false);
  const [overlapMin, setOverlapMin] = useState(0);
  const [modalState, setModalState] = useState(false);

  let blockId;
  if (scheduleId && blocks) {
    const blkid = blocks[0];
    blockId = blkid;
  }
  const schedules = useSelector((state) => selectBlockSchedule(state, blockId));

  useEffect(() => {
    if (blocks && !block.length) {
      setBlock(blocks);
    }
    if (!paramDateRange.length && dateRange) {
      setParamDateRange(dateRange);
    }
    if (schedules) {
      const schedule = schedules.scheduled_events
        && schedules.scheduled_events.filter((d) => d.id === scheduleId);
      if (schedule && schedule.length > 0) {
        setParamDateRange(
          [moment(Date.parse(schedule[0].start_date)),
            moment(Date.parse(schedule[0].stop_date))],
        );
      }
    }
  }, [block, blocks, dateRange, paramDateRange, scheduleId, schedules]);

  function datesEqual(date1, date2) {
    if (!date1 || !date2) {
      return date1 === date2;
    }
    return date1.format(dateFormat) === date2.format(dateFormat);
  }

  // if recurring rules have been modified, user can't update single recurrence
  const repeatRulesAreModified = !(
    initialValues.isRecurring === isRecurring
      && initialValues.repeatInterval === repeatInterval
      && initialValues.repeatEndType === repeatEndType
      && initialValues.repeatFrequency === repeatFrequency
      && (
        initialValues.repeatFrequency !== 'weekly'
        || (
          initialValues.repeatWeekdays.Su === weekDays.Su
          && initialValues.repeatWeekdays.Mo === weekDays.Mo
          && initialValues.repeatWeekdays.Tu === weekDays.Tu
          && initialValues.repeatWeekdays.We === weekDays.We
          && initialValues.repeatWeekdays.Th === weekDays.Th
          && initialValues.repeatWeekdays.Fr === weekDays.Fr
          && initialValues.repeatWeekdays.Sa === weekDays.Sa
        )
      )
      && (
        initialValues.repeatEndType !== 'count'
        || initialValues.repeatEndCount === repeatEndCount
      )
      && (
        initialValues.repeatEndType !== 'date'
        || datesEqual(initialValues.repeatEndDate, repeatEndDate)
      )
  );

  const startDateIsModified = !datesEqual(startDate, initialValues.startDate);

  function onFormEdit(val) {
    if (onChange) onChange(val);
    if (val && val.length > 1) {
      setParamDateRange(val);
      setValidationStatus(false);
      setDuration(moment(val[1]).diff(val[0], 'minutes'));
    } else if (!val) {
      setParamDateRange([val]);
      setValidationStatus(true);
    }
    setButtonState(false);
  }

  const setBlocksToState = (ids) => {
    const isExists = blocksFromState && blocksFromState.filter((d) => d && ids.indexOf(d) >= 0);
    if (isExists && !isExists.length) {
      const newBlocks = [...ids, ...blocksFromState];
      dispatch(setBlocks(newBlocks));
    }
    return true;
  };

  function renderBlock() {
    return (
      <Form.Item
        name="block"
        label={t('Block(s)')}
        rules={[
          {
            required: true,
            message: t('Please select block(s)'),
          },
        ]}
      >
        <BlockSelect
          placeholder={t('Select Blocks To Irrigate')}
          mode="multiple"
          onChange={(d) => { setBlocksToState(d); setBlock(d); setButtonState(false); }}
          defaultOption={block.length > 0 ? block : blocks}
          loadItems={(blocksFromState && blocksFromState.length) || block.length
            ? () => null : undefined}
        />
      </Form.Item>
    );
  }

  function renderDateRangeItems() {
    return (
      <Form.Item
        label={t('Choose Dates')}
        help={validationStatus ? t('Please select Start Date and End Date') : null}
        validateStatus={validationStatus ? 'error' : 'validating'}
        rules={[
          {
            required: true,
            message: t('Please select Start Date and End Date'),
          },
        ]}
      >
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          use12Hours={userTimeFormat?.unitsTime}
          onChange={onFormEdit}
          value={paramDateRange}
          format={userTimeFormat?.unitsTime
            ? 'YYYY-MM-DD hh:mm a' : 'YYYY-MM-DD HH:mm'}
          inputReadOnly
          disabledDate={disabledDate}
        />
      </Form.Item>
    );
  }

  const saveBtnStyle = { background: 'green', borderColor: 'green', color: 'white' };
  function getButton(val) {
    if (val) {
      return (
        <div className="update-form-buttons modify-schedule-buttons">
          {window.innerWidth > 599
            ? <Button htmlType="button" onClick={onCancel}>{t('Cancel')}</Button> : null}
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              setDeleteModalVisible(true);
            }}
            danger
          >
            {t('Delete')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnEnabled}
            style={!btnEnabled ? saveBtnStyle : null}
            loading={isSaving}
          >
            {t('Save')}
          </Button>
        </div>
      );
    } return <Button type="primary" htmlType="submit" loading={isSaving}>{t('Submit')}</Button>;
  }

  function parseScheduledEvent() {
    const stopDate = paramDateRange[0].clone().add(duration, 'minutes');
    const timezone = moment.tz.guess();

    let schedule = {
      start_date: paramDateRange[0].toISOString(),
      stop_date: stopDate.toISOString(),
      // flow: null,
      date_start_local: paramDateRange[0].toISOString(true).slice(0, -6),
      timezone,
      is_recurring: isRecurring,
    };

    if (isRecurring) {
      schedule = {
        ...schedule,
        repeat_interval: repeatInterval,
        repeat_frequency: repeatFrequency,
        // repeat_monthly_type: null,
        repeat_end_type: repeatEndType,
      };

      if (repeatFrequency === 'weekly') {
        schedule = {
          ...schedule,
          repeat_sunday: weekDays.Su,
          repeat_monday: weekDays.Mo,
          repeat_tuesday: weekDays.Tu,
          repeat_wednesday: weekDays.We,
          repeat_thursday: weekDays.Th,
          repeat_friday: weekDays.Fr,
          repeat_saturday: weekDays.Sa,
        };
      }

      if (repeatEndType === 'date') {
        schedule.repeat_end_date = repeatEndDate.toISOString();
      }

      if (repeatEndType === 'count') {
        schedule.repeat_end_count = repeatEndCount;
      }
    }
    return schedule;
  }

  function handleEditSingleEvent(eventData) {
    setIsSaving(true);
    const submitObj = {};
    updateScheduledEvent(eventData, (success) => {
      if (success) {
        notification.success({
          message: t('Schedule modified'),
        });
        setIsSaving(false);
        submitObj.status = 'Created';
        onSubmit(submitObj);
      } else {
        notification.error({
          message: t('Failed to edit schedule'),
        });
        setIsSaving(false);
        submitObj.status = 'Error';
        onSubmit(submitObj);
      }
    });
  }

  async function editSchedule() {
    const eventData = parseScheduledEvent();
    eventData.id = initialValues.id;
    [eventData.block] = initialValues.blockIds;
    if (!initialValues.isRecurring) {
      // edit directly
      handleEditSingleEvent(eventData);
      if (newBlockIds.length) {
        createScheduleForBlocks(eventData, newBlockIds);
      }
    } else {
      setParsedEvent(eventData);
      setEditEventModalIsVisible(true);
    }
  }

  async function createSchedule() {
    const submitObj = {};
    setIsSaving(true);
    const schedule = parseScheduledEvent();
    const success = await createScheduleForBlocks(schedule, block, isSequential, overlapMin);
    setIsSaving(false);
    if (success) {
      submitObj.status = 'Created';
      onSubmit(submitObj);
    } else {
      submitObj.status = 'Error';
      onSubmit(submitObj);
    }
  }

  function onSliderChange(val) {
    const calculatedDurarion = getClosestMinute(val, minStep);
    if ((paramDateRange?.length > 1) || isEditMode) {
      const startTime = paramDateRange && paramDateRange.length
        ? moment(paramDateRange[0]) : defaultValues.startDate;
      const endTime = startTime.clone().add(calculatedDurarion, 'minutes');
      setParamDateRange([startTime, endTime]);
    }
    setDuration(calculatedDurarion);
    if (duration !== calculatedDurarion) setButtonState(false);
  }

  const endDate = startDate.clone().add(duration, 'minutes');
  const totalDuration = moment.duration(endDate.diff(startDate));
  const totalHours = totalDuration.asHours();

  const waterDetails = {
    label: 'gallons',
    value: getGallonsValue((blockObj && blockObj.application_rate) || 0, totalHours),
  };

  function renderCircularSlider() {
    return (
      <Form.Item>
        <div className="duration-slider-container">
          <CircularSlider
            className="circular-slider"
            defaultValue={duration}
            value={duration}
            onChange={onSliderChange}
            strokeDashArray="0,19.4"
            sliderWidth={220}
            trackSize={16}
            hourMax={maxDurationHours}
            hourStep={1}
            minMax={60}
            minStep={minStep}
            knobSize={40}
            waterUnit={block.length === 1 ? waterDetails.label : null}
            waterValue={block.length === 1 ? waterDetails.value : null}
          />
        </div>
      </Form.Item>
    );
  }

  function onWeekDaysClick(d) {
    setWeekDays(d);
    setButtonState(false);
  }

  function renderRecuringControls() {
    return (
      <Form.Item>
        <div>
          <div className="margin-bottom-10 flex-row justify-content-space-between">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${t('Repeat Schedule')}`}
            </Typography.Text>
            <Switch
              className="form-"
              checkedChildren={t('Yes')}
              unCheckedChildren={t('No')}
              onChange={(d) => { setIsRecurring(d); setButtonState(false); }}
              defaultChecked={isRecurring}
              checked={isRecurring}
            />
          </div>
          {isRecurring && (
          <div>
            <div className="margin-top-10">

              <Typography.Text>
                {`${t('Repeat every')}`}
              </Typography.Text>

              <Select
                style={{ marginLeft: '10px', width: 60 }}
                value={repeatInterval}
                onChange={(d) => { setRepeatInterval(d); setButtonState(false); }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                listHeight={200}
              >
                {repeatDayOptions.map((optionValue) => (
                  <Select.Option value={optionValue} key={optionValue}>{optionValue}</Select.Option>
                ))}
              </Select>

              <Select
                style={{ marginLeft: '10px', width: 80 }}
                value={repeatFrequency}
                onChange={(d) => { setRepeatFrequency(d); setButtonState(false); }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="daily">
                  {repeatInterval === 1
                    ? t('day') : t('days')}
                </Select.Option>
                <Select.Option value="weekly">
                  {repeatInterval === 1
                    ? t('week') : t('weeks')}
                </Select.Option>
              </Select>

            </div>

            {repeatFrequency === 'weekly'
          && <WeekDays weekDays={weekDays} setWeekDays={onWeekDaysClick} t={t} />}

            <div className="margin-top-10 repeat-end-form-items">
              <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {t('Ends')}
              </Typography.Text>
              <Select
                style={{ marginLeft: '10px', width: 90, fontSize: '16px' }}
                value={repeatEndType}
                onChange={(d) => { setRepeatEndType(d); setButtonState(false); }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="never">
                  <Typography.Text>
                    {t('Never')}
                  </Typography.Text>
                </Select.Option>
                <Select.Option value="date">
                  <Typography.Text>
                    {t('On')}
                  </Typography.Text>
                </Select.Option>
                <Select.Option value="count">
                  <Typography.Text>
                    {t('After')}
                  </Typography.Text>
                </Select.Option>
              </Select>

              {repeatEndType === 'date' && (
              <DatePicker
                format="ddd, MMM D YYYY"
                style={{ flexGrow: 1, marginTop: 10 }}
                size="large"
                allowClear={false}
                inputReadOnly
                value={repeatEndDate}
                open={isEndDateCalendarOpen}
                onClick={() => {
                  setEndDateIsCalendarOpen(!isEndDateCalendarOpen);
                  setButtonState(false);
                }}
                suffixIcon={null}
                disabledDate={disabledDate}
                onChange={(sDate) => {
                  if (sDate.toDate().getTime() < startDateTimeAllowed(minutesAllowedFromNow)) {
                    setRepeatEndDate(initializeStartDate());
                  } else {
                    setRepeatEndDate(sDate);
                  }
                  setEndDateIsCalendarOpen(false);
                }}
              />
              )}

              {repeatEndType === 'count' && (
              <Input
                style={{ marginLeft: 10 }}
                type="number"
                suffix={t('Occurrences')}
                value={repeatEndCount}
                onChange={({ target: { value } }) => {
                  setRepeatEndCount(Number(value));
                  setButtonState(false);
                }}
              />
              )}
            </div>
          </div>
          )}
        </div>
      </Form.Item>
    );
  }

  function submitClicked() {
    if (isEditMode) {
      editSchedule();
    } else {
      createSchedule();
    }
  }

  function renderScheduleEditModal() {
    if (isEditMode) {
      return (
        <ScheduleEditOptionsModal
          visible={editEventModalIsVisible}
          scheduledEvent={parsedEvent}
          originalEvent={scheduledEvent}
          initialValues={initialValues}
          allowModifySingle={!repeatRulesAreModified}
          allowModifyAll={!startDateIsModified}
          newBlockIds={newBlockIds}
          handleOk={(success) => {
            setEditEventModalIsVisible(false);
            const submitObj = {};
            if (success) {
              submitObj.status = 'Created';
              onSubmit(submitObj);
            } else {
              submitObj.status = 'Error';
              onSubmit(submitObj);
            }
          }}
          handleCancel={() => setEditEventModalIsVisible(false)}
        />
      );
    } return null;
  }

  function renderScheduleDeleteModal() {
    return (
      <ScheduleDeleteModal
        visible={deleteModalVisible}
        scheduledEvent={scheduledEvent}
        handleOk={(success) => {
          setDeleteModalVisible(false);
          const submitObj = {};
          if (success) {
            submitObj.status = 'Created';
            onSubmit(submitObj);
          } else {
            submitObj.status = 'Error';
            onSubmit(submitObj);
          }
        }}
        handleCancel={() => setDeleteModalVisible(false)}
      />
    );
  }

  // For sequential changes
  const overlapOptions = [
    { value: 0, label: '0 Minutes' },
    { value: 5, label: '5 Minutes' },
    { value: 10, label: '10 Minutes' },
    { value: 15, label: '15 Minutes' },
  ];

  function renderSequentialHelpModal() {
    return (
      <Modal
        title={t('Sequential Irrigation')}
        centered
        visible={modalState}
        closable
        onCancel={() => setModalState(false)}
        footer={null}
      >
        <p>
          {t('Sequential Irrigation is the amount of overlap you would like to have on each zone '
           + 'or block. For instance, if you set a 5 minute overlap, the next zone will start '
           + 'irrigating 5 minutes before the current zone ends.'
           + ' This setting is reflected in the schedule.')}
        </p>
      </Modal>
    );
  }

  function renderSequentialControls() {
    return (
      <Form.Item>
        <div className="margin-bottom-10 flex-row justify-content-space-between">
          <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {`${t('Sequential')}`}
          </Typography.Text>
          <Switch
            className="form-"
            checkedChildren={t('Yes')}
            unCheckedChildren={t('No')}
            onChange={setIsSequential}
            defaultChecked={false}
            checked={isSequential}
          />
        </div>
        {isSequential && (
          <div className="margin-top-10 repeat-end-form-item">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${t('Overlap')}`}
            </Typography.Text>
            <Select
              style={{ marginLeft: '10px', width: '125px', fontSize: '16px' }}
              value={overlapMin}
              onChange={setOverlapMin}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {overlapOptions.map((d) => (
                <Select.Option value={d.value}>
                  {t(d.label)}
                </Select.Option>
              ))}
            </Select>
            <QuestionCircleFilled
              style={{ marginLeft: 10, fontSize: 20 }}
              onClick={() => setModalState(true)}
            />
            {modalState && renderSequentialHelpModal()}
          </div>
        )}
      </Form.Item>
    );
  }

  return (
    <div className="form-container" id="form-update">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          method: 'dates',
          chooseDates: dateRange,
          block: blocks,
        }}
        onFinish={submitClicked}
        onChange={onFormEdit}
        scrollToFirstError
      >
        {
          // To select block
          renderBlock()
        }
        {renderDateRangeItems()}
        {renderCircularSlider()}
        {(block.length > 1) && renderSequentialControls()}
        {renderRecuringControls()}
        {renderScheduleEditModal()}
        {renderScheduleDeleteModal()}
        <Form.Item>
          {getButton(scheduleId)}
        </Form.Item>
      </Form>
    </div>
  );
};

ScheduleForm.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  blocks: PropTypes.arrayOf(PropTypes.any),
  scheduleId: PropTypes.number,
  isEditMode: PropTypes.bool,
  initialValues: PropTypes.shape(),
  scheduledEvent: PropTypes.shape(),
};

ScheduleForm.defaultProps = {
  onCancel: () => null,
  onChange: () => null,
  onSubmit: () => null,
  dateRange: null,
  blocks: null,
  scheduleId: null,
  isEditMode: false,
  initialValues: {},
  scheduledEvent: {},
};

export default ScheduleForm;
