/* eslint-disable camelcase */
import React, {
  useState, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { FaLayerGroup, FaTrash } from 'react-icons/fa';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { getAnomalyIcon, stateTagColorCodes } from 'farmx-web-ui';
import isEqual from 'react-fast-compare';
import { PageHeader } from '../components/PageHeader';
import { StatusTag } from '../components/StatusTag';
import { CardLayout } from '../components/CardLayout';
import { useTracking } from '../../../helper/mixpanel';
import './recommendation-detail.css';
import RanchBlockTitle from '../components/RanchBlockTitle';
import { getLayerBounds } from '../../../helper/mapHelper';
import { isMobile } from '../../../utils/detectDevice';
import { reducerAnomaly, initReducerAnomaly } from './reducerAnomaly';
import {
  useSatelliteImagery,
  useSatelliteDataDatesAndTypes,
  useSatelliteDateRangePreparation,
  useDefaultSelectedImageryData,
  useRequestSatelliteData,
} from '../../../helper/satelliteHooks';
import { RecommendationActionButtons } from './RecommendationActionButtons';
import {
  getStateForAnomalySeverity, useAnomalyDataForRecommendation, getAnomalyAreaForAnomalyId,
  getImageryAnomalyComparisonForAnomalyId,
} from '../../../helper/anomalyHelper';
import { getHumanReadableAnomalyType, checkIsAllRanch } from '../../../helper/common';
import { VWCAndPressureDetails } from './VWCAndPressureDetails';
import { AnomalyDetails } from '../map/Anomaly/AnomalyDetails';
import RecommendationLoader from '../../contentLoaders/RecommendationContentLoader';
import AnomalyMap from './AnomalyMap';
import AnomalyModal from './AnomalyModal';
import AnomalyModalButtons from './AnomalyModalButtons';
import { getActionText } from './recommendation';
import { useNavigateHome } from '../../../helper/recommendationHooks';
import AnomalyArea from './AnomalyArea';

const {
  loadAnomaliesForBlock,
  setBlocks,
  loadRecommendations,
} = actions;

const {
  selectNewBlockById,
  selectMapShowSoilType,
  selectRecommendationForBlock,
  selectAnomaliesForBlock,
  selectUserAreaFormat,
  selectLoadingRecommendations,
} = selectors;

const {
  useBlockNames, useRanchNamesForBlocks, useRanchBlockSelection, useEntities
} = hooks;

export function ImageryAnomalyDetailPage({
  propAnomaly, propBlockId, showMap, showActionButton,
}) {
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { identifier } = useParams();
  const [anomalyView, setAnomalyView] = useState('area');
  const isDefaultView = anomalyView === 'default';
  const isComparisonView = anomalyView === 'comparison';
  const[loading, setLoading] = useState(false);
  const blockId = parseInt(identifier, 10);
  const { selectedTab, anomalyId } = location.state ?? {};
  const blockName = useBlockNames([Number(blockId) || propBlockId]);
  const ranchName = useRanchNamesForBlocks([Number(blockId) || propBlockId]);
  const blockObj = useSelector((state) => selectNewBlockById(state, blockId || propBlockId));
  const recommendations = useSelector((state) => selectRecommendationForBlock(state,
    blockId || propBlockId))
    ?.filter((d) => d?.type === 'imagery_anomaly');
  const areaFormat = useSelector(selectUserAreaFormat);
  const areaUnit = areaFormat || 'acres';
  const fixedButtonPosition = !isMobile && 'recommendation-investigate-button';
  const { selectedObjFromState } = useRanchBlockSelection();
  const { activeEntityId } = useEntities();
  const loadingStatus = useSelector((state) => selectLoadingRecommendations(state));
  const isAllRanch = checkIsAllRanch(selectedObjFromState?.type);

  let propAnomalyWithRecommendation;

  const keys = {
    critical: 'Critical',
    major: 'Major',
    moderate: 'Moderate',
    low: 'Low',
    no_stress: 'No Stress',
  };

  const [modal, setModal] = useState({});
  const [latLngText, setLatLng] = useState(null);
  const [tab, setTab] = useState(showMap ? 'map' : 'vwc');
  const isMapTab = tab === 'map';

  const ranchId = blockObj?.ranch;
  const anomalyForBlock = useSelector((state) => selectAnomaliesForBlock(state,
    blockId || propBlockId));
  const anomalyIds = recommendations.map((d) => d?.imageryAnomaly);
  const filteredAnomaly = anomalyForBlock.filter((d) => anomalyIds.includes(d?.properties?.id));
  const [anomaly] = filteredAnomaly;

  const {
    anomalyData,
    anomalyDataForRender, recommendation,
  } = useAnomalyDataForRecommendation(anomaly || propAnomalyWithRecommendation,
    recommendations, anomalyForBlock, propAnomaly);
  const blkId = anomalyData?.properties?.block;

  // navigate to home page when ranch/block is changed
  useNavigateHome();

  const blockIdToGetAnomaly = blockId || propBlockId;
  useEffect(() => {
    if (anomalyId || anomalyIds?.[0]) {
      dispatch(loadAnomaliesForBlock([blockIdToGetAnomaly]));
    }
    // Set block in global block selector
    if(Number(blockId)) {
      dispatch(setBlocks([Number(blockId || propBlockId)]));
    }

    // Unmounting set block when ranch is selected
    return () => {
      if(selectedObjFromState.type === 'ranch') {
        dispatch(setBlocks([ranchId]));
      }
      // Unmounting block and ranch when AllRanch selected
      if(isAllRanch){
        dispatch(setBlocks([]));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyId, anomalyIds?.[0], blockIdToGetAnomaly, dispatch, propBlockId]);

  const { coordinates } = anomalyData?.properties?.center || {};

  // Load recommendations only when there is no data from redux-state
  // Case: Loading page directly by URL
  useEffect(() => {
    if (!recommendations?.length && activeEntityId) {
      dispatch(loadRecommendations(activeEntityId));
    }
  }, [dispatch, recommendations.length, activeEntityId]);

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  // use local reducer to handle state of the component
  const [stateAnomaly, anomalyDispatch] = useReducer(
    reducerAnomaly,
    initReducerAnomaly(),
    initReducerAnomaly,
  );

  const [isDisabled, setIsDisabled] = useState(true);
  const ids = JSON.stringify([anomalyData?.properties?.id]);

  // To get the anomaly area data for anomaly id
  useEffect(() => {
    if (!isDefaultView && !isComparisonView) {
      getAnomalyAreaForAnomalyId(JSON.parse(ids), (response) => {
        if (response?.length) {
          const filteredResponse = response.filter((d) => d?.block === blkId);
          anomalyDispatch({
            type: 'setAnomalyAreaData',
            payload: filteredResponse,
          });
        }
      });
    }

    if (isComparisonView) {
      setLoading(true);
      getImageryAnomalyComparisonForAnomalyId(JSON.parse(ids), (response) => {
        if (response?.length) {
          const filteredResponse = response.filter((d) => d?.id === blkId)?.[0];
          anomalyDispatch({
            type: 'setAnomalyComparisonData',
            payload: filteredResponse,
          });
          setLoading(false);
        }
        if(!response){
          anomalyDispatch({
            type: 'setAnomalyComparisonData',
            payload: null,
          });
          setLoading(false);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, isDefaultView, isComparisonView]);

  // To hide the satellite imagery on anomaly area view
  useEffect(() => {
    if (!isDefaultView && stateAnomaly.selectedImageryData?.visible) {
      anomalyDispatch({
        type: 'setSelectedImageryData',
        payload: {
          visible: isDefaultView,
          data: stateAnomaly.selectedImageryData.data,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultView]);

  // get satellite imagery using local reducer
  useSatelliteImagery(
    ranchId,
    blockId || propBlockId,
    stateAnomaly.latestReload,
    true,
    anomalyDispatch,
  );

  // prepare satellite data dates and types using local reducer
  useSatelliteDataDatesAndTypes(
    stateAnomaly.satelliteDates,
    stateAnomaly.selectedSatelliteDataType,
    stateAnomaly.selectedSatelliteDataSource,
    anomalyDispatch,
  );

  // SatelliteDateRange preparation using local reducer
  useSatelliteDateRangePreparation(
    stateAnomaly.selectedSatelliteDatesFiltered,
    stateAnomaly.selectedSatelliteDate,
    stateAnomaly.selectedSatelliteDatesDataRange,
    stateAnomaly.selectedSatelliteDataType,
    stateAnomaly.selectedSatelliteDataSource,
    ranchId,
    blockId || propBlockId,
    anomalyDispatch,
  );

  // To maintain the selected imagery data on the map
  useDefaultSelectedImageryData(
    stateAnomaly.dateRangeWithDisplayData,
    stateAnomaly.selectedImageryData,
    stateAnomaly.selectedSatelliteDate,
    anomalyDispatch,
  );

  // request satellite imagery data
  useRequestSatelliteData(stateAnomaly.selectedSatelliteDatesDataRange, true,
    true, anomalyDispatch);

  useEffect(() => {
    if (blockId || propBlockId) {
      tracking.track('Zoomed Anomaly');
      tracking.track('Anomaly Detail Page Loaded', { blockId: (blockId || propBlockId), anomaly });
    }
  }, [tracking, blockId, anomaly, propBlockId]);

  // TODO: loading
  if (!anomaly && !propAnomaly && !filteredAnomaly && !loadingStatus.loading) {
    // Commented code will be removed after confirmation
    // history.push({ pathname: '/recommendation' });

    return (
      <div>{t('Anomaly not found')}</div>
    );
  }

  // Show the loader if data is loading and no anomaly data is available.
  if (loadingStatus.loading && !anomaly && !propAnomaly) {
    return (
      <RecommendationLoader />
    );
  }

  const layerOptionButton = () => (
    <>
      <Button
        size="large"
        type="primary"
        icon={<FaLayerGroup />}
        className="recommendation-map-button"
        onClick={() => setModal({ visible: true, type: 'layer' })}
      />
      <Button
        size="large"
        type="primary"
        icon={<FaTrash />}
        className="recommendation-map-button custom-button-margin"
        onClick={() => {
          anomalyDispatch({
            type: 'setDeleteAnomaly',
            payload: { delete: !stateAnomaly.deleteAnomaly.delete, save: false, cancel: false },
          });
        }}
      />
    </>
  );

  function renderRecommendationDateDetail() {
    const imageTaken = stateAnomaly.selectedSatelliteDate
      ? moment(stateAnomaly.selectedSatelliteDate).format('YYYY-MM-DD') : 'unknown';
    const { dateCreated } = anomalyDataForRender?.[0]?.properties || recommendation?.[0] || {};
    const recommendationCreatedAt = dateCreated
      ? moment(dateCreated).format('YYYY-MM-DD') : 'unknown';

    return (
      <small>
        <em>
          {`${t('Recommendation generated')}: ${recommendationCreatedAt}, `}
          {`${t('Imagery taken')}: ${imageTaken}`}
        </em>
      </small>
    );
  }

  function onLatLngClick(e) {
    setModal({ visible: true, type: 'action' });
    setLatLng(e.currentTarget.id);
  }

  // To avoid page braking with invalid GeoJson format
  const updatedAnomalyAreaGeoJSON = blockObj?.bounds ? {
    ...stateAnomaly.anomalyArea?.[0],
    coords: blockObj?.bounds,
  } : undefined;

  const ranchBlockName = `${ranchName[blockId]} > ${blockName[blockId]}`;


  const anomalyLegends = (d) => {
    const conditionCheck = d === 'no_stress' && 'white-text-color';
    return (
      <div className={`anomaly-legends ${conditionCheck}`}>
        {keys[d]}
      </div>
    );
  };

  return (
    <div className={`page-content ${showMap ? 'page-container' : ''} margin-10 recommendation-container`}>
      <div className={!isMobile ? 'anomaly-details-container'
        : 'anomaly-details-mobile-container anomaly-details-margin'}
      >
        {showMap && (
          <PageHeader
            showBack
            toPath="/recommendation"
            title={<RanchBlockTitle title={ranchBlockName} />}
            state={{ selectedTab }}
          />
        )}
        <CardLayout key="1">
          <div className="recommendation-detail-card1-container">
            {anomalyDataForRender?.length ? anomalyDataForRender.map((d, index) => (
              <div style={{ width: '100%' }}>
                <StatusTag
                  width="100%"
                  text={`${t(getHumanReadableAnomalyType(d?.properties?.anomaly_type
                    || d?.properties?.anomalyType
                    || propAnomaly?.properties?.anomaly_type
                    || recommendations?.[0]?.anomalyType))}`}
                  state={
                    getStateForAnomalySeverity((d?.properties && (d?.properties?.anomaly_severity
                      || d?.properties?.state)) || propAnomaly?.properties?.anomaly_severity
                      || recommendations?.[0]?.anomalySeverity)
                  }
                  tagStyle={{ fontSize: '16px', padding: '4px', marginBottom: '10px' }}
                  icon={getAnomalyIcon(d?.properties?.anomaly_type
                    || propAnomaly?.properties?.anomaly_type
                    || recommendations?.[0]?.anomalyType)}
                />
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <Typography.Text>
                    {`${recommendation?.description || recommendations?.[0]?.description
                      || d?.properties?.description || propAnomaly?.properties?.description || ''}`}
                  </Typography.Text>
                  <Typography.Text>{renderRecommendationDateDetail()}</Typography.Text>
                </div>
              </div>
            )) : null}
          </div>
        </CardLayout>
        <CardLayout key="2">
          <AnomalyMap
            blockId={blkId}
            ranchId={ranchId}
            deleteAnomaly={stateAnomaly.deleteAnomaly}
            selectedImageryData={stateAnomaly.selectedImageryData}
            anomalyData={isDefaultView ? (anomalyData || filteredAnomaly[0])
              : updatedAnomalyAreaGeoJSON}
            anomalyGeoJSON={getLayerBounds(anomalyData)}
            selMapShowSoilType={selMapShowSoilType}
            onLatLngClick={onLatLngClick}
            layerOptionButton={layerOptionButton}
            isMapTab={isMapTab}
            setTab={setTab}
            setIsDisabled={setIsDisabled}
          />
          {stateAnomaly.deleteAnomaly.delete
            && (
              <AnomalyModalButtons
                btnText="Delete"
                isDelete={stateAnomaly.deleteAnomaly.delete}
                anomalyDispatch={anomalyDispatch}
                setModal={setModal}
                modal={modal}
                deleteAnomaly={stateAnomaly.deleteAnomaly}
                isDisabled={isDisabled}
              />
            )}
          <div className="flex-row margin-top-10 anomaly-legends-container">
            {Object.keys(keys).map((d) => (
              <div className="flex-auto justify-content-space-evenly">
                <Tag
                  className="anomaly-square"
                  color={stateTagColorCodes[
                    getStateForAnomalySeverity(d)]}
                />
                {isMobile ? (
                  <Tooltip title={keys[d].length > 6 ? keys[d] : ''}>
                    {anomalyLegends(d)}
                  </Tooltip>
                ) : (
                  anomalyLegends(d)
                )}
              </div>
            ))}
          </div>
        </CardLayout>
        <CardLayout key="5">
          <AnomalyArea
            areaUnit={areaUnit}
            anomalyView={anomalyView}
            anomalyArea={stateAnomaly.anomalyArea}
            anomalyComparison={stateAnomaly.anomalyComparison}
            onSelect={(d) => setAnomalyView(d)}
            loading={loading}
          />
        </CardLayout>
        {!isMapTab
          && (
            <div className="home-page-pressure-sensor-details">
              <VWCAndPressureDetails blockIds={[Number(blockId)]} />
            </div>
          )}
        <AnomalyModal
          modal={modal}
          setModal={setModal}
          deleteAnomaly={stateAnomaly.deleteAnomaly}
          selectedImageryData={stateAnomaly.selectedImageryData}
          selMapShowSoilType={selMapShowSoilType}
          anomalyData={anomalyData}
          anomalyDispatch={anomalyDispatch}
          isDisabled={isDisabled}
          latLngText={latLngText}
          showMap={showMap}
        />
        {!showMap && coordinates && (
          <>
            <CardLayout key="3">
              <Button
                type="text"
                onClick={onLatLngClick}
                id={JSON.stringify({ lat: coordinates?.[1], lng: coordinates?.[0] })}
                data-testid="anomaly-map-lat-lng-section"
              >
                {`lat: ${coordinates?.[1]?.toFixed(3)}, long: ${coordinates?.[0]?.toFixed(3)}`}
              </Button>
            </CardLayout>
          </>
        )}
        <CardLayout key="4">
          <AnomalyDetails anomalyId={anomalyData?.properties?.id} />
        </CardLayout>
        <div className="action-footer error-report-button">
          <Button
            size="large"
            type="link"
            block
            onClick={() => setModal({ visible: true, type: 'report' })}
          >
            {t('Notice an issue? Click here to report.')}
          </Button>
        </div>
      </div>
      {showActionButton ? (
        <RecommendationActionButtons
          blockId={blockId}
          recommendation={anomaly}
          actionText={recommendation?.action}
        />
      ) : ''}

      <div className={`action-footer recommendation-detail-actions padding-bottom-10 
      ${fixedButtonPosition}`}
      >
        <Button
          size="large"
          type="primary"
          block
          onClick={() => {
            if (recommendation?.length && recommendation[0]) {
              const { block } = recommendation[0];
              history.push({
                pathname: `/recommendation/anomaly/investigate/${block}`,
                state: {
                  from: location.pathname,
                }
              });
              tracking.track('Clicked anomaly action', anomalyData);
            }
          }}
        >
          {t(getActionText(recommendation?.length && recommendation[0].action))}
        </Button>
      </div>
    </div>
  );
}

ImageryAnomalyDetailPage.propTypes = {
  propAnomaly: PropTypes.shape(),
  propBlockId: PropTypes.number,
  showMap: PropTypes.bool,
  showActionButton: PropTypes.bool,
};

ImageryAnomalyDetailPage.defaultProps = {
  propAnomaly: null,
  propBlockId: null,
  showMap: true,
  showActionButton: true,
};
