import React from 'react';
import PropTypes from 'prop-types';
import './map.css';
import {
  schemeRdYlGn, interpolateInferno, interpolateViridis,
  interpolateSpectral,
} from 'd3-scale-chromatic';
import './ColorMap.less';
import { roundToDecimalPlaces } from '../../../helper/mapHelper';

const plantsOptions = {
  count: 'Plants Health Classification',
  evapotranspirationActual: 'Evapotranspiration Actual',
  cropStress: 'Crop Stress',
  canopySize: 'Canopy Cover',
  irrigation: 'Irrigation Metric',
  standingWater: 'Standing Water',
  NDSM: 'Canopy Height',
};

const count = {
  black: 'No Data',
  white: 'Missing',
  yellow: 'Undeveloped',
  red: 'Dead',
  '#3887be': 'Normal',
};
const RGB = ['red', 'yellow', 'green', 'cyan', 'blue', 'darkblue'];
const ndreKeys = ['#000', '#840000', 'red', '#FF5700', '#FFA300', 'yellow', '#9BFF5B',
  '#28FFCE', '#00DFFB', '#0087FF', '#0000E8', '#000098'];
const rDYlGnKeys = ['NDVI', 'ndvi', 'OSAVI', 'RGB'];
const infernoKeys = ['Thermal'];
const viridisKeys = ['NDSM'];
const maxColorsLength = 100;

const labelFormat = {
  NDSM: 'm',
  Thermal: '°C'
};

function indexValueForType(e) {
  const indexValue = ['0', '1'];
  const type = ['NDRE', 'ndre', 'NDVI', 'ndvi', 'OSAVI'];
  return type.includes(e) && indexValue;
}

function getColorsFromRange(colorFunc, range) {
  return Array.from({ length: range }).map((_, i) => colorFunc(i / (range - 1)));
}

function getColors(key) {
  let colors = Object.keys(count);
  const type = ['NDRE', 'ndre'];
  if (key === 'canopySize') {
    colors = ['#000', ...getColorsFromRange(interpolateSpectral, maxColorsLength)];
  } else if (rDYlGnKeys.includes(key)) {
    colors = ['#000', ...schemeRdYlGn[schemeRdYlGn.length - 1]];
  } else if (infernoKeys.includes(key)) {
    colors = getColorsFromRange(interpolateInferno, maxColorsLength);
  } else if (viridisKeys.includes(key)) {
    colors = getColorsFromRange(interpolateViridis, maxColorsLength);
  } else if (type.includes(key)) colors = ndreKeys;
  return colors;
}

function getRangeLabel(key) {
  const label = ['0', '1'].map((d) => `${d}${labelFormat[key] || ''}`);
  if (rDYlGnKeys.includes(key) || infernoKeys.includes(key)
  || viridisKeys.includes(key));

  return indexValueForType(key) || label;
}

function getColorKeyLabel(data, droneKey) {
  const urlStr = data?.[droneKey];
  if (urlStr) {
    const scaleRange = urlStr.split('rescale=')?.[1]?.split(',');
    const [minRange, maxRange] = (scaleRange || [])?.map((d) => (d?.length > 1
      ? roundToDecimalPlaces(d, 1) : d));
    return [`${minRange}${labelFormat[droneKey] || ''}`, `${maxRange}${labelFormat[droneKey] || ''}`];
  }
  return getRangeLabel(droneKey);
}
const ColorBar = ({
  colorKey, droneKey, side, displayControl, isSplitScreen,
  dateRangeWithDisplayData, isDrone, satelliteKey, imageryData, isAnomaly,
}) => {
  const colors = getColors(colorKey || droneKey);
  const labels = indexValueForType(colorKey)
   || getColorKeyLabel(imageryData?.[side || 'left']?.data?.[0]?.tiles, droneKey);
  const multiplyWith = maxColorsLength / colors.length;
  const svgGradId = `grad${Math.random()}`;
  const isPlantsHealthClassification = colorKey === 'count';
  const plantHealth = isPlantsHealthClassification && 'plant-health-classification';
  const renderSatelliteColorBar = (!isDrone && !dateRangeWithDisplayData)
    ? 'render-color-keys' : 'color-bar';
  const renderColorBar = isAnomaly? 'color-bar-position': 'render-color-bar-position';
  const satelliteDataTypeObj = {
    NDWI_SWIR: 'NDWI',
    'Plant Vigor (NDVI)': 'NDVI',
  };

  function renderDiscreteColors() {
    return (Object.keys(count).map((d) => (
      <div className="plant-health-classification-color-keys">
        <div
          style={{
            backgroundColor: `${d}`,
          }}
          id={`${d}`}
          className="plants-health-color-label"
        />
        <div className="text-align-center">{count[d]}</div>
      </div>
    )));
  }

  function renderSatelliteColorsKeys() {
    return (
      <div className="satellite-color-keys">
        {(() => {
          if (dateRangeWithDisplayData && dateRangeWithDisplayData.length) {
            const dateRangeWithDisplayDataLength = dateRangeWithDisplayData.length;
            for (let i = 0; i < dateRangeWithDisplayDataLength; i += 1) {
              const dateData = dateRangeWithDisplayData[i];
              if (dateData && dateData?.data?.length && dateData?.data[0]?.data_key_url) {
                return (
                  <>
                    <div className="satellite-color-keys-container">
                      {satelliteKey && side ? `${satelliteDataTypeObj[satelliteKey]
                       || satelliteKey} (${side})`
                        : (satelliteDataTypeObj[satelliteKey] || satelliteKey)}
                    </div>
                    <img
                      src={dateData?.data[0]?.data_key_url}
                      alt={dateData?.data[0]?.scene_id}
                      className="satellite-color-image-bar"
                    />
                    <div
                      className="satellite-color-index-container"
                    >
                      <div className="satellite-index-left">
                        0
                      </div>
                      <div className="satellite-index-right">
                        1
                      </div>
                    </div>
                  </>
                );
              }
            }
          }
          return null;
        })()}
      </div>
    );
  }

  return (
    <div
      className={!displayControl
        ? `${renderSatelliteColorBar} ${renderColorBar} ${plantHealth}`
        : `color-bar color-bar-position ${plantHealth}`}
    >
      {isPlantsHealthClassification ? (
        <div className="mobile-legend-container">
          <div className="text-align-center">
            {!droneKey && (plantsOptions[colorKey] || colorKey)?.toUpperCase()}
            {droneKey && side ? `${plantsOptions[droneKey] || droneKey} (${side})`
              : plantsOptions[droneKey] || droneKey}
          </div>
          <div className="flex-row plants-health-label-container mobile-render-discrete-colors">
            {renderDiscreteColors()}
          </div>
        </div>
      )
        : (
          <>
            {!isDrone ? renderSatelliteColorsKeys() : (
              <svg viewBox="100 -5 100 75" width="300" height="60">
                <defs>
                  <linearGradient id={svgGradId} x1="0%" y1="0%" x2="100%" y2="0%">
                    {colors.map((color, index) => (
                      <stop
                        offset={`${index * multiplyWith}%`}
                        stopColor={color}
                      />
                    ))}
                  </linearGradient>
                </defs>
                <rect
                  x="0"
                  y="15"
                  width="300"
                  height="20"
                  stroke="grey"
                  strokeWidth="1"
                  fill={`url(#${svgGradId})`}
                />
                <text
                  x="0"
                  y="50"
                  fill="black"
                  fontFamily="sans-serif"
                  textAnchor="start"
                  fontSize="12pt"
                >
                  {labels[0]}
                </text>
                <text
                  x="300"
                  y="50"
                  fill="black"
                  fontFamily="sans-serif"
                  textAnchor="end"
                  fontSize="12pt"
                >
                  {labels[1]}
                </text>
                <text
                  x="300"
                  y="10"
                  fill="black"
                  fontFamily="sans-serif"
                  textAnchor="end"
                  fontSize="12pt"
                >
                  {!droneKey && (plantsOptions[colorKey] || colorKey)?.toUpperCase()}
                  {droneKey && side ? `${plantsOptions[droneKey] || droneKey} (${side})`
                    : plantsOptions[droneKey] || droneKey}
                </text>
              </svg>
            )}
          </>
        )}
    </div>
  );
};

ColorBar.propTypes = {
  colorKey: PropTypes.string.isRequired,
  droneKey: PropTypes.string,
  side: PropTypes.string,
  displayControl: PropTypes.bool,
  isSplitScreen: PropTypes.bool,
  dateRangeWithDisplayData: PropTypes.arrayOf(
    PropTypes.object,
  ),
  isDrone: PropTypes.bool,
  satelliteKey: PropTypes.string,
  imageryData: PropTypes.shape(),
  isAnomaly: PropTypes.bool,
};

ColorBar.defaultProps = {
  droneKey: null,
  side: null, // for full-screen mode
  displayControl: false,
  isSplitScreen: false,
  dateRangeWithDisplayData: undefined,
  isDrone: true,
  satelliteKey: null,
  imageryData: null,
  isAnomaly: null,
};

export default ColorBar;
