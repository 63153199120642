import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ChartComponent } from 'farmx-web-ui';
import Moment from 'moment';
import { sensorApi, helpers } from 'farmx-api';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { processDataGap } from 'helper/graphHelper';
import {
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
  soilDepthPalette,
  tooltipBgColor,
  tooltipBorderColor,
} from '../../utils/colors';
import Resizer from './Resizer';
import EditSensorSettings from './EditSensorSettings';
import { displayConvertedDepth } from '../../helper/depthUnitHelper';

const { fieldToHeader } = helpers;
const { loadSensorData } = sensorApi;
const { loadSensorDetail } = actions;
const { useUnits } = hooks;

const yAxisLineLabelStyle = {
  color: '#666666',
  fontSize: '11px',
  opacity: 0.6
};

/**
 * This method uses soilDepthPalette to create
 * mapping based on the sensor status.
 * Example color mapping looks like:
 * {'soil_moisture_8': '#00429d'}
 *
 * It returns a function to get a color. This function accepts a string
 * and returns related color.
 */
const getColors = (sensorStatus) => {
  const { depth1, depth2, depthCount } = sensorStatus || {};
  const colorMapping = {};
  if (depth1 && depth2 && depthCount) {
    const depthSize = depth2 - depth1;
    for (let depthNum = 1; depthNum <= depthCount; depthNum += 1) {
      const currentDepth = depthNum * depthSize;
      const key = `soil_moisture_${currentDepth}`;
      const depthRounded = 2 * Math.ceil(currentDepth / 2);
      const paletteIndex = (depthRounded - 4) / 2;
      colorMapping[key] = soilDepthPalette[paletteIndex];
    }
  }
  return (key) => colorMapping[key] || colorOk;
};

/**
 * Dynamically generates the variables required to get api response.
 *
 * Given if the sensorStatus has following data:
 * { depth1: 8, depth2: 16, ... depthCount: 6 }
 *
 * It will return
 * ['soil_moisture_8', 'soil_moisture_16', ... 'soil_moisture_48']
 */
const getDepthVariables = (sensorStatus) => {
  const { depth1, depthCount } = sensorStatus || {};
  if (depth1 && depthCount) {
    const vars = [];
    for (let i = 1; i <= depthCount; i += 1) {
      const depth = sensorStatus[`depth${i}`];
      vars.push(`soil_moisture_${depth}`);
    }
    return vars;
  }
  return [];
};

const getMin = (args) => {
  const params = args.filter((arg) => !isNaN(arg));
  return Math.min(...params);
};
const getMax = (args) => {
  const params = args.filter((arg) => !isNaN(arg));
  return Math.max(...params);
};

/**
 * Compute a common min and max value for the graph.
 * These common values can be then used in yAxis obj as opposed to
 * individual min and max values.
 * It eliminates the chance of overlapping of yAxis labels.
 */
const computeMinAndMax = (data, sensorStatus) => {
  let newMin = Infinity;
  let newMax = -Infinity;

  try {
    let arr = [];
    const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    Object.keys(data).forEach((dataKey) => {
      const dataValue = data[dataKey][0];
      const depthIndex = (dataKey.split('soil_moisture_')[1]) / depthSize;
      const fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
      const refillPoint = sensorStatus[`refillPoint${depthIndex}`];
      const wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
      const dataValueMin = dataValue?.min;
      const dataValueMax = dataValue?.max;
      const zoneLower = dataValue?.moisture_zone_lower;
      arr = [
        ...arr,
        fieldCapacity,
        refillPoint,
        wiltingPoint,
        dataValueMin,
        dataValueMax,
        zoneLower,
      ];
    });
    newMin = getMin(arr);
    newMax = getMax(arr);
  } catch {
    return { min: undefined, max: undefined };
  }
  return { min: newMin, max: newMax };
};

const updateSoilCutoff = (responseData, sensorStatus) => {
  const data = { ...responseData };
  try {
    const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    Object.keys(data).forEach((dataKey) => {
      const depthIndex = dataKey.split('soil_moisture_')[1];
      const depthNum = depthIndex / depthSize;
      data[dataKey][0].fieldCapacity = sensorStatus[`fieldCapacity${depthNum}`];
      data[dataKey][0].refillPoint = sensorStatus[`refillPoint${depthNum}`];
      data[dataKey][0].wiltingPoint = sensorStatus[`wiltingPoint${depthNum}`];
      data[dataKey][0].isRootzone = sensorStatus[`isRootzone${depthNum}`];
      data[dataKey][0].depthIndex = depthNum;
    });
  } catch {
    return data;
  }
  return data;
};

export default function SensorDataChart(props) {
  const {
    sensor: sensorProps,
    variables,
    startDate,
    endDate,
    mergeAxes,
    compact,
    soilChartOption,
    updateChartLoadingMap,
    updateChartLegend,
    showIndividualDepthGraph,
    updateYAxisMinAndMax,
    yAxisMinAndMax,
    makeYAxisSame,
    listIndividualDepths,
    updateEditGraphsList,
  } = props;

  const { type, identifier, id: sensorId } = sensorProps;
  const dispatch = useDispatch();
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  );
  const getUserUnits = useUnits();
  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);
  const chartRef = useRef();
  const individualChartRef = useRef([]);
  const [localChartData, setLocalChartData] = useState(null);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [height, setHeight] = useState(null);
  const [individualGraphHeight, setIndividualGraphHeight] = useState({});
  const [loading, setLoading] = useState({});
  const updateLoading = (key, value) => {
    setLoading((existingState) => ({
      ...existingState,
      [key]: value,
    }));
  };
  const [refillPoints, setRefillPoints] = useState(null);
  const [graphsInEditMode, setGraphsInEditMode] = useState({});
  const [isMainGraphInEditMode, setIsMainGraphInEditMode] = useState(false);
  const yAxisLabels = {
    x: 0,
    y: -2,
    align: 'left',
  };

  const updateGraphsInEditMode = (key, isInEditMode) => {
    setGraphsInEditMode((existingGraphsInEditMode) => ({
      ...existingGraphsInEditMode,
      [key]: isInEditMode,
    }));
  };

  const validInput = !!(
    sensorId
    && type
    && variables
    && variables.length
    && startDate
    && endDate
  );
  // Convert psi unit to user selected unit
  function psiToUserUnit(value, decimalPlaces = 1) {
    if (value === null || value === undefined || value === '') return 0;
    const convertedValue = getUserUnits(value, 'pounds_per_square_inch', 'pressure', { decimalPlaces }).value;
    return convertedValue;
  }

  // Convert temperature unit to user selected unit
  function temperatureFToUserUnit(value, decimalPlaces = 1) {
    if (value === null || value === undefined || value === '') return 0;
    const convertedValue = getUserUnits(value, 'degrees_fahrenheit', 'temperature', { decimalPlaces }).value;
    return convertedValue;
  }

  // load initial data
  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorDetail({ type, identifier }));
    }
  }, [dispatch, type, identifier]);

  useEffect(() => {
    if (!type || !sensorId) return;
    if (sensorProps.type === 'water_pressure') return;
    if (soilChartOption !== 'soilDepth') return;
    sensorApi.getSoilSensorProperties({ type, id: sensorId }).then((response) => {
      if (response.status === 200 || response.status === 204) {
        const { data } = response || { data: {} };
        setRefillPoints(data);
      }
    });
  }, [type, sensorId, sensorProps.type, soilChartOption]);

  // update data when config changes
  // Disabled eslint rule to avoid multiple API calls for same details
  useEffect(() => {
    function refreshData() {
      const chartObj = chartRef.current?.highchartsComponent?.current?.chart;
      /**
       * Update localChartData with dummy data while the chart api is loading.
       * This dummy data ensures the correct dates are displayed on the x-axis
       * when fetching the data.
       */
      if (localChartData?.[sensorId]?.soil_moisture_rootzone_vwc) {
        if (startDate && endDate) {
          const difference = endDate.diff(startDate, 'days');
          /**
           * Create data in the format:
           * [
           *  [1672511400000, 0],
           *  [1675189800000, 0],
           *  ...
           * ]
           */
          const data = new Array(difference + 1).fill(0).map((item, index) => ([
            new Moment(startDate).add('days', index).valueOf(), 0,
          ]));
          setLocalChartData({
            [sensorId]: {
              soil_moisture_rootzone_vwc: [{
                ...localChartData?.[sensorId]?.soil_moisture_rootzone_vwc[0],
                data,
              }],
            },
          });
        }
      }

      if (!validInput) {
        if (chartObj) {
          chartObj.hideLoading();
          updateChartLoadingMap({ [identifier]: false });
          updateLoading(sensorId, false);
        }
        setLocalChartData(null);
        return;
      }
      if (chartObj) {
        chartObj.showLoading();
        updateChartLoadingMap({ [identifier]: true });
        updateLoading(sensorId, true);
      }
      const chartVariables = (soilChartOption === 'soilDepth' && sensorProps.type !== 'water_pressure')
        ? getDepthVariables(sensorStatus)
        : variables;
      loadSensorData(type, sensorId, chartVariables, startDate, endDate)
        .then((response) => {
          if (response && response.status === 200) {
            const { data } = response;
            const computedMinAndMax = computeMinAndMax(data, sensorStatus);
            setMin(computedMinAndMax.min);
            setMax(computedMinAndMax.max);
            updateYAxisMinAndMax(computedMinAndMax.min, computedMinAndMax.max, sensorProps.type);
            setLocalChartData({ [sensorId]: response.data });
            if (soilChartOption === 'soilDepth' && sensorProps.type !== 'water_pressure') {
              const responseDataWithSoilCutoff = updateSoilCutoff(response.data, sensorStatus);
              setLocalChartData({ [sensorId]: responseDataWithSoilCutoff });
            } else {
              setLocalChartData({ [sensorId]: response.data });
            }
          }
          if (chartObj) {
            chartObj.hideLoading();
            updateChartLoadingMap({ [identifier]: false });
            updateLoading(sensorId, false);
          }
        })
        .catch(() => {
          chartObj.hideLoading();
          updateChartLoadingMap({ [identifier]: false });
          updateLoading(sensorId, false);
        });
    }
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validInput, startDate, endDate, soilChartOption]);

  function preparePlotLines(linesConfig) {
    const defaultStyle = {
      zIndex: 5,
      dashStyle: 'dash',
      width: 2,
    };
    const plotLinesArr = linesConfig.map((configObj) => ({ ...configObj, ...defaultStyle }));
    const filteredConfig = linesConfig.filter((d) => d?.type !== 'middle');
    const plotBandConfigArr = filteredConfig.map((d) => ({
      from: d.value ? d.from : null,
      to: d.value ? d.to : null,
      color: d.color,
    }));
    return { plotLines: plotLinesArr, plotBands: plotBandConfigArr };
  }

  const computeMinForLocalBuildConfig = (sensorPropsType, chartData) => {
    if (makeYAxisSame) {
      return sensorPropsType === 'water_pressure'
        ? psiToUserUnit(yAxisMinAndMax.waterPressure.min)
        : yAxisMinAndMax.soilMoisture.min;
    }
    if (sensorPropsType === 'water_pressure') {
      const convertedMin = psiToUserUnit(min);
      return (convertedMin || parseFloat(psiToUserUnit(chartData.min)));
    }
    if (sensorPropsType.includes('soil_temp')) {
      const convertedMin = temperatureFToUserUnit(min);
      return (convertedMin || parseFloat(temperatureFToUserUnit(chartData.min)));
    }
    return (min || Math.min(
      chartData.moisture_zone_lower || parseFloat(chartData.min), parseFloat(chartData.min),
    ));
  };

  const computeMaxForLocalBuildConfig = (sensorPropsType, chartData) => {
    if (makeYAxisSame) {
      return sensorPropsType === 'water_pressure'
        ? psiToUserUnit(yAxisMinAndMax.waterPressure.max, 1)
        : yAxisMinAndMax.soilMoisture.max;
    }
    if (sensorPropsType === 'water_pressure') {
      const convertedMax = psiToUserUnit(max, 4);
      return (convertedMax || parseFloat(psiToUserUnit(chartData.max, 4)));
    }
    if (sensorPropsType.includes('soil_temp')) {
      const convertedMin = temperatureFToUserUnit(max);
      return (convertedMin || parseFloat(temperatureFToUserUnit(chartData.max)));
    }

    return (max || Math.max(
      chartData.moisture_zone_upper || parseFloat(chartData.max), parseFloat(chartData.max),
    ));
  };

  function buildConfig(seriesData) {
    if (!seriesData) return {};

    const yAxisByUnit = {};
    const allSeries = Object.entries(seriesData).map(([key, value]) => {
      if (!value.length) return undefined;
      const chartData = value[0];
      if (!chartData.data.length) return undefined;
      /**
       * If a depth is disabled by user by selecting pill available on graph header
       * we don't render that on graph.
       */
      if (listIndividualDepths[key] === false) return undefined;

      let { data: variableData } = chartData;

      // detect values as strings and convert
      if (typeof variableData[0][1] === 'string') {
        variableData = variableData.map((datum) => [datum[0], parseFloat(datum[1])]);
      }

      let multiplier = 1;
      // fix units of %
      if (chartData.units === '%') {
        variableData = variableData.map((datum) => [datum[0], 100 * datum[1]]);
        multiplier = 100;
      }

      // for psi units
      if (chartData.units === 'psi') {
        variableData = variableData.map((datum) => {
          const convertedPressure = psiToUserUnit(datum[1], 4);
          return [datum[0], convertedPressure];
        });
      }

      // for temperature units
      if (chartData.units === '°F') {
        variableData = variableData.map((datum) => {
          const convertedTemperature = temperatureFToUserUnit(datum[1], 4);
          return [datum[0], convertedTemperature];
        });
      }

      const decimals = 1;
      const fourDecimals = 4;

      const chartUnits = chartData.units || '';
      const axisKey = mergeAxes ? chartUnits : chartUnits + key;
      const regex = /&deg;/g;
      const units = chartUnits.replace(regex, 'º');
      let newUnits = units;
      if (units === 'psi') {
        newUnits = getUserUnits(0, 'pounds_per_square_inch', 'pressure').label;
      } else if (units === '°F') {
        newUnits = getUserUnits(0, 'temperature_fahrenheit', 'temperature').label;
      }
      const unitsStr = ` (${newUnits})`;
      const variableName = fieldToHeader(key);
      const legendTitle = variableName + unitsStr;

      let plotLines = [];
      let plotBands = [];
      const soilData = seriesData.soil_moisture_rootzone_vwc;
      if (chartData && soilData) {
        const linesConfig = [{
          color: colorOver,
          value: chartData.moisture_zone_upper * multiplier,
          type: 'upper',
          from: chartData.moisture_zone_upper * multiplier,
          to: (chartData.moisture_zone_upper * multiplier || 0) + 1 * multiplier,
          className: 'upper-band-line',
        },
        { color: colorWarning, value: chartData.moisture_zone_middle * multiplier, type: 'middle' },
        {
          color: colorCritical,
          value: chartData.moisture_zone_lower * multiplier,
          type: 'lower',
          from: 0,
          to: chartData.moisture_zone_lower * multiplier,
          className: 'lower-band-line',
        }];
        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;
      }
      let yAxis = yAxisByUnit[axisKey];
      if (!yAxis) {
        yAxis = {
          title: null,
          id: axisKey,
          opposite: false,
          min: computeMinForLocalBuildConfig(sensorProps.type, chartData) * multiplier,
          max: computeMaxForLocalBuildConfig(sensorProps.type, chartData) * multiplier,
          plotLines,
          plotBands,
        };
        yAxisByUnit[axisKey] = yAxis;
      } else {
        yAxis.title = null;
        yAxis.min = Math.min(yAxis.min, chartData.min);
        yAxis.max = Math.max(yAxis.max, chartData.max);
        yAxis.plotLines = plotLines;
      }

      if (compact) {
        yAxis.title = null;
        yAxis.labels = yAxisLabels;
      }

      const seriesOptions = {
        name: legendTitle,
        sensorName: chartData.sensor_name,
        isRootzone: chartData.isRootzone,
        actualChartKey: key,
        type: 'line',
        data: processDataGap(variableData),
        yAxis: axisKey,
        tooltip: {
          valueDecimals: newUnits === 'MPa' ? fourDecimals : decimals,
        },
      };
      if (type?.includes('soil')) {
        const seriesOptionsColor = getColor(key);
        updateChartLegend(
          identifier,
          key,
          {
            color: getColor(key),
            isRootzone: chartData.isRootzone,
          },
        );
        seriesOptions.color = seriesOptionsColor;
        seriesOptions.depthIndex = chartData.depthIndex;

        if (soilChartOption === 'soilDepth') {
          seriesOptions.additionalPlotLines = [
            {
              value: chartData.wiltingPoint * multiplier,
              color: colorCritical,
              width: 2,
              id: 'moisture_zone_lower',
              dashStyle: 'dash',
              label: {
                x: -15,
                align: 'right',
                text: `Wilting Point (${Number(chartData.wiltingPoint * multiplier).toFixed(1)})`,
                style: yAxisLineLabelStyle
              },
            },
            {
              value: chartData.refillPoint * multiplier,
              color: colorWarning,
              width: 2,
              id: 'moisture_zone_middle',
              dashStyle: 'dash',
              label: {
                x: -15,
                align: 'right',
                text: `Refill Point (${Number(chartData.refillPoint * multiplier).toFixed(1)})`,
                style: yAxisLineLabelStyle
              },
            },
            {
              value: chartData.fieldCapacity * multiplier,
              color: colorOver,
              width: 2,
              id: 'moisture_zone_upper',
              dashStyle: 'dash',
              label: {
                x: -15,
                align: 'right',
                text: `Field Capacity (${Number(chartData.fieldCapacity * multiplier).toFixed(1)})`,
                style: yAxisLineLabelStyle
              },
            },
          ];
          /**
           * Display dashed cutoff lines when hover over any series.
           */
          seriesOptions.point = {
            events: {
              mouseOver() {
                try {
                  const yAxisRef = chartRef.current?.highchartsComponent?.current?.chart?.yAxis[0];
                  if (yAxisRef) {
                    yAxisRef.addPlotLine({
                      value: chartData.wiltingPoint * multiplier,
                      color: colorCritical,
                      width: 2,
                      id: 'moisture_zone_lower',
                      dashStyle: 'dash',
                    });
                    yAxisRef.addPlotLine({
                      value: chartData.refillPoint * multiplier,
                      color: colorWarning,
                      width: 2,
                      id: 'moisture_zone_middle',
                      dashStyle: 'dash',
                    });
                    yAxisRef.addPlotLine({
                      value: chartData.fieldCapacity * multiplier,
                      color: colorOver,
                      width: 2,
                      id: 'moisture_zone_upper',
                      dashStyle: 'dash',
                    });
                  }
                } catch (error) {
                  console.log('Error in hover', error);
                }
              },
              mouseOut() {
                try {
                  const yAxisRef = chartRef.current?.highchartsComponent?.current?.chart?.yAxis[0];
                  if (yAxisRef) {
                    yAxisRef.removePlotLine('moisture_zone_lower');
                    yAxisRef.removePlotLine('moisture_zone_middle');
                    yAxisRef.removePlotLine('moisture_zone_upper');
                  }
                } catch (error) {
                  console.log('mouseOut error', error);
                }
              },
            },
          };
        }
      }
      return seriesOptions;
    });
    return {
      yAxis: Object.values(yAxisByUnit),
      series: allSeries.filter(Boolean),
    };
  }

  const config = buildConfig(localChartData?.[sensorId]);

  const xAxisLabels = compact
    ? {
      y: 10,
      x: 1,
      align: 'left',
    } : {};

  const spacing = compact
    ? [1, 1, 1, 1] : [10, 10, 15, 10];

  useEffect(() => {
    try {
      chartRef.current.highchartsComponent.current.chart.zoom();
    } catch {
      console.log('Error in resetting zoom');
    }
  }, [startDate, endDate]);

  const renderIsRootzonePill = (isRootzone) => (isRootzone ? (
    <span
      className="legend-pill"
      style={{ backgroundColor: colorOk }}
    >
      Rootzone
    </span>
  ) : (
    <span
      className="legend-pill"
      style={{ backgroundColor: 'grey' }}
    >
      Not Rootzone
    </span>
  ));
  const onEditChartClick = (key, value) => {
    if (key === 'main') {
      setIsMainGraphInEditMode(value);
      if (config && config.series && config.series.length) {
        config.series.forEach((seriesItem) => {
          updateGraphsInEditMode(seriesItem.yAxis, value);
        });
      }
      updateEditGraphsList(identifier, value);
    } else {
      updateGraphsInEditMode(key, value);
    }
  };

  const renderChartEditButtons = (key) => {
    if (sensorProps.type === 'water_pressure') return null;
    if (soilChartOption !== 'soilDepth') return null;
    if (!graphsInEditMode[key]) {
      return (
        <Button type="link" onClick={() => onEditChartClick(key, true)}>Edit</Button>
      );
    }
    if (key === 'main' && graphsInEditMode[key]) {
      return (
        <>
          <Button onClick={() => onEditChartClick(key, false)}>Save</Button>
          <Button onClick={() => onEditChartClick(key, false)}>Cancel</Button>
        </>
      );
    }
    return null;
  };

  const renderIndividualChartTitle = (seriesItem) => {
    const {
      sensorName, color, yAxis, name, isRootzone,
    } = seriesItem || {};
    const depth = yAxis.split('_')[2];
    try {
      return (
        <div className="individual-chart-header">
          <span className="sensor-name">{`${sensorName}`}</span>
          <span>Soil Moisture VWC</span>
          <span
            className="legend-pill"
            style={{ backgroundColor: color }}
          >
            {`${displayConvertedDepth(depth, getUserUnits)}`}
          </span>
          {renderIsRootzonePill(isRootzone)}
        </div>
      );
    } catch {
      return `${sensorName} ${name}`;
    }
  };
  const onResize = useCallback((event, chartHeight) => {
    setHeight(chartHeight);
  }, []);
  const onResizeIndividual = useCallback((chartHeight, key) => {
    setIndividualGraphHeight((existingIndividualGraphHeight) => ({
      ...existingIndividualGraphHeight,
      [key]: chartHeight,
    }));
  }, []);
  const displayIndividualGraph = showIndividualDepthGraph && sensorProps.type !== 'water_pressure';

  /**
   * If the data is loading, we set chart to show loading.
   */
  useEffect(() => {
    const chartObj = chartRef.current?.highchartsComponent?.current?.chart;
    if (!chartObj || !chartObj.showLoading || !chartObj.hideLoading) return;
    if (loading[sensorId]) {
      chartObj.showLoading();
    } else {
      chartObj.hideLoading();
    }
  }, [chartRef, loading, sensorId]);

  /**
   * When user enters into edit mode by clicking main graph,
   * we don't render main graph anymore.
   * When user decides to cancel and exit out of edit mode for that graph,
   * user will have to click cancel on all the individual graphs.
   * Below useEffect listens to that change. If and when all the graphs are cancelled
   * and exited out of edit mode, the main graph will reappear.
   */
  useEffect(() => {
    const isGraphEditCancelledForAllGraphs = Object.keys(graphsInEditMode)
      .every((graphsInEditModeKey) => (
        !graphsInEditMode[graphsInEditModeKey]
      ));
    if (isGraphEditCancelledForAllGraphs && isMainGraphInEditMode) {
      setIsMainGraphInEditMode(false);
      updateEditGraphsList(identifier, false);
    }
  }, [graphsInEditMode, identifier, isMainGraphInEditMode, updateEditGraphsList]);

  const getEditSensorChartData = (seriesItem) => localChartData
    && localChartData?.[sensorId]
    && localChartData?.[sensorId]?.[seriesItem.actualChartKey]?.[0];

  const chartXAxis = {
    type: 'datetime',
    ordinal: false,
    gridLineWidth: 1,
    labels: xAxisLabels,
    alternateGridColor: 'rgba(200,200,200,0.1)',
    min: startDate?.valueOf(),
    max: endDate?.valueOf(),
    tickInterval: null,
    dateTimeLabelFormats: {},
  };

  return (
    <div className="chart-component-wrapper">
      {!(isMainGraphInEditMode || displayIndividualGraph) && (
        <>
          <div className="chart-component-main-edit-buttons">
            {renderChartEditButtons('main')}
          </div>
          <Resizer onResize={onResize}>
            <div className="chart-component-main" style={{ height }}>
              <ChartComponent
                ref={chartRef}
                options={{
                  tooltip: {
                    backgroundColor: tooltipBgColor,
                    borderColor: tooltipBorderColor,
                    style: {
                      color: 'white',
                    },
                  },
                  legend: {
                    enabled: false,
                  },
                  chart: {
                    zoomType: 'xy',
                    spacing,
                  },
                  xAxis: chartXAxis,
                  plotOptions: type?.includes('soil') ? {
                    series: {
                      color: colorOk,
                    },
                  } : {},
                  ...config,
                }}
                loading={loading[sensorId]}
              />
            </div>
          </Resizer>
        </>
      )}
      {(isMainGraphInEditMode || displayIndividualGraph) && (
        <div className="nested-chart-component-wrapper">
          {config && config.series && config.series.map((seriesItem, index) => (
            <div
              className="nested-individual-chart-main"
              key={seriesItem.yAxis}
            >
              <div className="nested-individual-chart-header">
                {renderIndividualChartTitle(seriesItem)}
                {renderChartEditButtons(seriesItem.yAxis)}
              </div>
              {graphsInEditMode[seriesItem.yAxis] ? (
                <EditSensorSettings
                  chartData={getEditSensorChartData(seriesItem)}
                  onCancel={() => updateGraphsInEditMode(seriesItem.yAxis, false)}
                  refillPoints={refillPoints}
                  depthIndex={seriesItem.depthIndex}
                  sensorType={type}
                  sensorId={sensorId}
                  optionalId={seriesItem.yAxis}
                  chartOptions={{
                    color: seriesItem.color,
                    xAxis: chartXAxis,
                    yAxis: { labels: yAxisLabels },
                  }}
                />
              ) : (
                <Resizer
                  onResize={(event, chartHeight) => {
                    onResizeIndividual(chartHeight, seriesItem.yAxis);
                  }}
                >
                  <div className="nested-individual-chart" style={{ height: individualGraphHeight[seriesItem.yAxis] || null }}>
                    <ChartComponent
                      ref={(el) => {
                        individualChartRef.current[index] = el;
                      }}
                      options={{
                        tooltip: {
                          backgroundColor: tooltipBgColor,
                          borderColor: tooltipBorderColor,
                          style: {
                            color: 'white',
                          },
                        },
                        legend: {
                          enabled: false,
                        },
                        chart: {
                          zoomType: 'xy',
                          spacing,
                        },
                        xAxis: {
                          type: 'datetime',
                          ordinal: false,
                          gridLineWidth: 1,
                          labels: xAxisLabels,
                          alternateGridColor: 'rgba(200,200,200,0.1)',
                          min: startDate?.valueOf(),
                          max: endDate?.valueOf(),
                        },
                        plotOptions: type?.includes('soil') ? {
                          series: {
                            color: colorOk,
                          },
                        } : {},
                        series: [seriesItem],
                        yAxis: {
                          ...config.yAxis[index],
                          plotLines: seriesItem.additionalPlotLines,
                        },
                      }}
                      loading={loading[sensorId]}
                    />
                  </div>
                </Resizer>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

SensorDataChart.propTypes = {
  compact: PropTypes.bool,
  variables: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  mergeAxes: PropTypes.bool,
  soilChartOption: PropTypes.string,
  updateChartLoadingMap: PropTypes.func,
  updateChartLegend: PropTypes.func,
  updateEditGraphsList: PropTypes.func,
  showIndividualDepthGraph: PropTypes.bool,
  updateYAxisMinAndMax: PropTypes.func,
  makeYAxisSame: PropTypes.bool,
  yAxisMinAndMax: PropTypes.shape({
    waterPressure: { min: PropTypes.number, max: PropTypes.number },
    soilMoisture: { min: PropTypes.number, max: PropTypes.number },
  }),
  listIndividualDepths: PropTypes.shape({}),
};

SensorDataChart.defaultProps = {
  compact: true,
  variables: [],
  startDate: null,
  endDate: null,
  sensor: null,
  mergeAxes: false,
  soilChartOption: '',
  updateChartLoadingMap: () => { },
  updateChartLegend: () => { },
  updateEditGraphsList: () => { },
  showIndividualDepthGraph: false,
  updateYAxisMinAndMax: () => { },
  yAxisMinAndMax: {
    waterPressure: { min: Infinity, max: -Infinity },
    soilMoisture: { min: Infinity, max: -Infinity },
  },
  makeYAxisSame: false,
  listIndividualDepths: {},
};
