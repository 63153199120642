/* eslint-disable camelcase */
import React, {
  useReducer, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors, actions, hooks } from 'farmx-redux-core';
import {
  useLoadMapData,
  useRecenter,
  useReDraw,
  prepData,
  useGetDataToRenderMap,
  useRenderPlantBlockView,
  usePreparePolygonFillColor,
  useLoadAnomalyData,
  useHandleBlockPlantView,
} from './mapHooks';
import { initReducerMapMobile, reducerMapMobile } from './reducerMapMobile';
import './map.css';
import { presModeTitles } from './MapSettings';
import { reDrawSatelliteImagery } from './reDraw';
import LegendModal from './LegendModal';
import legendConfig from './legendConfig';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import {
  getDataForReDraw, getPlantsData, clearImageryLayers,
  getCanopyAreaUnit, hideMarkersById,
} from '../../../helper/mapHelper';
import { Map3D } from '../../map/components/threed/Map3D';
import { isMobile } from '../../../utils/detectDevice';
import ShowDetails from './ShowDetails';
import MapTabsControl from './MapTabsControl';
import MapSettingModal from './MapSettingsModal';
import AnomalyCreateEditModal from './AnomalyCreateEditModal';
import MapControlBox from './MapControlBox';
import MapWrapper from './MapWrapper';
import DrawerImageryControl from './DrawerImageryControl';
import ColorBar from './ColorMap';
import { getBlockArea } from '../../../helper/block';

const {
  setMapPresentationModes,
} = actions;

const {
  selectLoginUserInfo,
  selectAllNewRanchIds,
  selectNewBlockById,
  selectUserAreaFormat,
  selectUserLengthFormat,
  selectSensorsByRanchId,
} = selectors;

const { useRanchBlockSelection } = hooks;

const REFRESH_INTERVAL = 1000 * 60 * 15;
const filterSensor = 'cavalier';
const modesToAvoidApiCall = ['irrigationRecommendation', 'plants', 'anomaly'];

export function MapView({ ranchId, blockId, selectedObjFromState }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modal, setModal] = useState({});
  const [isAnomalyControlEnabled, setIsAnomalyControlEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sheetRef = useRef();
  const prevSelection = useRef({
    prevActiveImagery: null,
    showImagery: false,
    showDroneImagery: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [mapSettingModal, setMapSettingModal] = useState(false);
  const [layerOption, setLayerOption] = useState(false);
  const [activeImagery, setActiveImagery] = useState('fullScreen');
  const [createAnomaly, setCreateAnomaly] = useState({ visible: false });
  const formValues = useRef({ type: 'missing', description: null, anomaly_severity: 'moderate' });
  const refreshStatusInterval = useRef(null);
  const isRanch = selectedObjFromState?.type === 'ranch';
  const isBlock = selectedObjFromState?.type === 'block';
  // If only entity is selected consider it as all ranch
  const isAllRanch = ['All Ranches', 'entity'].includes(selectedObjFromState?.type);
  const ranchIds = useRef();
  const presModeRef = useRef();
  const plantArea = useRef();
  ranchIds.current = [(isRanch) ? Number(selectedObjFromState.value) : ranchId];
  const allRanchIds = useSelector(selectAllNewRanchIds);
  const userInfo = useSelector(selectLoginUserInfo).payload;
  // Set to 'false' when select "All Ranches" to load map data
  const isAdmin = isAllRanch ? false : (userInfo && userInfo.admin);
  const areaFormat = useSelector(selectUserAreaFormat);
  const lengthFormat = useSelector(selectUserLengthFormat);

  const canopyAreaUnit = getCanopyAreaUnit(lengthFormat, areaFormat);
  const sensorsData = useSelector((state) => selectSensorsByRanchId(state, ranchId));
  const isSensorsDataAvailable = sensorsData?.length > 0;

  // use local reducer to handle state of the component
  const [stateMap, dispatchMap] = useReducer(
    reducerMapMobile,
    initReducerMapMobile(),
    initReducerMapMobile,
  );
  // debugger

  // use local reducer to handle state of the component
  const [stateMap1, dispatchMap1] = useReducer(
    reducerMapMobile,
    initReducerMapMobile(),
    initReducerMapMobile,
  );

  // Patch fix for map settings dropdown selection issue
  useEffect(() => {
    const selectedSettings = JSON.parse(localStorage.getItem('presMode'));
    if (selectedSettings) {
      dispatch(setMapPresentationModes(selectedSettings));
    }
    return () => {
      localStorage.removeItem('presMode');
    };
  }, [dispatch]);

  const isLeft = stateMap?.layersControl?.side === 'left';
  const isSatellite = stateMap.selectedSatelliteDataSource === 'satellite';
  let isPlants = false;
  const colorMapSide = useRef({ left: null, right: null });
  const colorMapSide1 = useRef({ left: null, right: null });
  const isDrone = stateMap?.selectedSatelliteDataSource === 'drone';
  const isDroneRight = stateMap1?.selectedSatelliteDataSource === 'drone';
  const droneKey = stateMap?.selectedDroneDataType;
  const droneKeyRight = stateMap1?.selectedDroneDataType;
  const satelliteKey = stateMap?.selectedSatelliteDataType;
  const satelliteKeyRight = stateMap1?.selectedSatelliteDataType;
  const selectorType = 'ranch';

  const mapFeatureGroupRef = useRef(null);
  const mapFeatureGroupRef1 = useRef(null);

  const { blockIds } = useRanchBlockSelection();
  const blkId = isBlock ? Number(selectedObjFromState.value) : 0;
  const isBlocksAvailable = blockIds?.length > 0;
  const isDataLoading = stateMap.isLoading || stateMap1.isLoading || stateMap.isPlantLoading
  || stateMap.is3DMapLoading;

  const {
    ranchSensors,
    ranchGeoJSON,
    allRanchesPoints,
    allRanchesFeaturesData,
    selShowAllSensors,
    presMode,
    selMapShowSoilType,
    selMapShowLabels,
    blocksGeoJSON,
  } = useGetDataToRenderMap(ranchId, isAdmin, blockId, blockIds, selectedObjFromState,
    allRanchIds, dispatchMap);

  const blockIdsForRanch = JSON.stringify((blocksGeoJSON && blocksGeoJSON.features
      && blocksGeoJSON.features.map((d) => d.id)) || []);

  useLoadAnomalyData(dispatchMap, blockIdsForRanch, presMode,
    blkId, (selectedObjFromState && selectedObjFromState.type), JSON.stringify(blockIds));

  isPlants = presMode === 'plants';
  const isAnomaly = presMode === 'anomaly';
  const skipApiCall = modesToAvoidApiCall.includes(presMode);
  const ranchIdsToLoadMapData = isAllRanch && !isBlock ? allRanchIds : ranchId;
  useLoadMapData(dispatchMap, ranchIdsToLoadMapData, filterSensor, skipApiCall,
    isAllRanch, isBlocksAvailable, isSensorsDataAvailable, selectorType, dispatch);

  const dataForReDraw = getDataForReDraw(ranchSensors, presMode,
    stateMap.anomalyGeoJSON, isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature);

  const isBlockPlantView = stateMap.plantsView === 'block';
  let blockIdsToFill = [blkId];
  if (!isBlock) blockIdsToFill = blockIds;

  const { blockAnalytics, plantsOption: selectedValue } = stateMap;
  const fillColorObj = usePreparePolygonFillColor(selectedValue, blockAnalytics,
    isBlockPlantView, blockIdsToFill, blockIds.length, mapFeatureGroupRef?.current, isPlants);

  const block = useSelector((state) => selectNewBlockById(state, blkId));
  const { row_spacing: rowSpacing, plant_spacing: plantSpacing } = block || {};
  const plantAreaInM2 = Number(rowSpacing || 0) * Number(plantSpacing || 0);

  useEffect(() => {
    if (presMode !== presModeRef.current) presModeRef.current = presMode;
    if (plantAreaInM2 !== plantArea.current) plantArea.current = plantAreaInM2;
  }, [presMode, plantAreaInM2]);

  useRenderPlantBlockView(mapFeatureGroupRef, blocksGeoJSON,
    JSON.stringify(fillColorObj), isBlockPlantView, isPlants);

  useReDraw(
    mapFeatureGroupRef,
    dataForReDraw,
    presMode,
    isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature,
    dispatchMap,
    selMapShowLabels,
    selShowAllSensors,
    allRanchesPoints,
    ranchId,
    stateMap.zoomSensorsVisible,
    isLeft ? stateMap.isBottomSheetVisible : stateMap1.isBottomSheetVisible,
    mapFeatureGroupRef1,
    isAnomaly,
    stateMap.zoomVal,
    isAllRanch,
    stateMap.sideBySideMapVisible,
  );

  useRecenter(
    blkId,
    ranchId,
    blocksGeoJSON,
    ranchGeoJSON,
    allRanchesFeaturesData,
    isLeft ? dispatchMap : dispatchMap1,
    (selectedObjFromState && selectedObjFromState.type),
    isLeft ? stateMap.isBottomSheetVisible : stateMap1.isBottomSheetVisible,
    isLeft ? stateMap.bottomSheetType : stateMap1.bottomSheetType,
    isPlants,
  );

  // Hide the ranch markers for the selected ranch when data loading is completed
  useEffect(() => {
    if (!isDataLoading && ranchId && !isAllRanch) {
      hideMarkersById(ranchId, true);
    }
  }, [isAllRanch, isDataLoading, ranchId]);

  // To hide the map3D view when changing ranch
  useEffect(() => {
    if (!isBlock && stateMap.showMap3D) {
      dispatchMap({
        type: 'setShowMap3D',
        payload: !stateMap.showMap3D
      });
    }
  }, [isBlock, stateMap.showMap3D]);

  // When an entity has no ranch, the zoom level is set to 0.
  useEffect(() => {
    if (allRanchIds?.length === 0 && mapFeatureGroupRef?.current?.style?._loaded) {
      mapFeatureGroupRef.current.setZoom(0);
    }
  }, [allRanchIds]);

  let side;
  if (stateMap.sideBySideMapVisible) {
    if (isLeft) side = 'left';
    else side = 'right';
  }

  // To hide split screen when select "all ranches"
  useEffect(() => {
    if (activeImagery === 'splitScreen' && isAllRanch) {
      setActiveImagery('fullScreen');
      dispatchMap({
        type: 'setSideBySideMapVisible',
        payload: false,
      });
    }
  }, [activeImagery, isAllRanch]);

  function updatePrevSelection(val) {
    prevSelection.current = val;
  }

  useHandleBlockPlantView({
    stateMap,
    stateMap1,
    prevSelection,
    dispatchMap,
    dispatchMap1,
    setActiveImagery,
    updatePrevSelection,
  });

  // Avoid page breaking issue, when selectedFeature is selected
  // and switching different presentation mode
  useEffect(() => {
    const { selectedFeature, isBottomSheetVisible } = isLeft ? stateMap : stateMap1;
    const dispatchMapArg = isLeft ? dispatchMap : dispatchMap1;
    if (selectedFeature) {
      dispatchMapArg({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: undefined,
          bottomSheetType: undefined,
          showBottomSheet: isBottomSheetVisible,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presMode]);

  // Update keys text on split-screen for drone imagery
  useEffect(() => {
    if (stateMap.sideBySideMapVisible) {
      if (isLeft && isDrone) colorMapSide.current.left = droneKey;
      if (!isLeft && isDroneRight) colorMapSide.current.right = droneKeyRight;
    } else colorMapSide.current = { left: null, right: null };
  }, [isLeft, isDrone, droneKey, isDroneRight, stateMap.sideBySideMapVisible, droneKeyRight]);

  // Update keys text on split-screen for satellite imagery
  useEffect(() => {
    if (stateMap.sideBySideMapVisible) {
      if (isLeft && !isDrone) colorMapSide1.current.left = satelliteKey;
      if (!isLeft && !isDroneRight) colorMapSide1.current.right = satelliteKeyRight;
    } else colorMapSide1.current = { left: null, right: null };
  }, [isLeft, isDrone, satelliteKey, satelliteKeyRight, stateMap.sideBySideMapVisible, isDroneRight]);

  // Update keys text on split-screen for drone imagery
  // When user switch to "right" side before "left" side gets loaded
  useEffect(() => {
    if (stateMap.sideBySideMapVisible) {
      if (stateMap.selectedDroneImagery && isDrone) colorMapSide.current.left = droneKey;
    }
  }, [droneKey, isDrone, stateMap.selectedDroneImagery, stateMap.sideBySideMapVisible]);

  // Update keys text on split-screen for satellite imagery
  // When user switch to "right" side before "left" side gets loaded
  useEffect(() => {
    if (stateMap.sideBySideMapVisible) {
      if (stateMap.selectedImageryData && !isDrone) colorMapSide1.current.left = satelliteKey;
    }
  }, [isDrone, satelliteKey, stateMap.selectedImageryData, stateMap.sideBySideMapVisible]);

  useEffect(() => {
    if (blkId && mapFeatureGroupRef?.current?.style?._loaded && !isAnomaly && isLeft) {
      clearImageryLayers(mapFeatureGroupRef, isSatellite);
    }
    if (mapFeatureGroupRef1?.current?.style?._loaded && !isAnomaly && !isLeft) {
      clearImageryLayers(mapFeatureGroupRef1, isSatellite);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnomaly, blkId, isSatellite]);

  useEffect(() => {
    if (isPlants && blkId) {
      getPlantsData(blkId, plantAreaInM2, canopyAreaUnit, dispatchMap);
    } else if (!isPlants && stateMap.plantsGeoJSON) {
      dispatchMap({
        type: 'setPlantsData',
        payload: null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlants, blkId]);

  useEffect(() => {
    if (selMapShowSoilType && !stateMap.showLayer?.soil?.left?.visible && isLeft) {
      dispatchMap({
        type: 'setShowLayer',
        payload: {
          ...stateMap.showLayer,
          soil: {
            left: { visible: selMapShowSoilType },
            right: { visible: stateMap.showLayer.soil.right.visible },
          },
        },
      });
    }
  }, [isLeft, selMapShowSoilType, stateMap.showLayer]);

  function refreshUntilUnmount() {
    refreshStatusInterval.current = setInterval(() => {
      // To reset all the states in every 15 mins
      dispatchMap({ type: 'reset' });
      setActiveImagery('fullScreen');

      // eslint-disable-next-line no-underscore-dangle
      const currZoom = mapFeatureGroupRef?.current?.leafletElement?._map.getZoom();
      dispatchMap({
        type: 'setZoomSensorsVisible',
        payload: presModeRef.current === 'anomaly' ? currZoom : mapFeatureGroupRef?.current?.getZoom(),
      });
      if (ranchIds.current?.length && ranchIds.current?.[0]) {
        prepData(dispatchMap, dispatch, ranchIds.current, filterSensor,
          skipApiCall, selectorType).catch();
      }
      if (isPlants) getPlantsData(blkId, plantArea.current, canopyAreaUnit, dispatchMap);
    }, REFRESH_INTERVAL);
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  // Trigger the refresh interval when component loads
  useEffect(() => {
    refreshUntilUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear the interval when component unmounts
  useEffect(() => () => {
    clearRefreshInterval();
  }, []);

  // To enable to satellite imagery control and show imagery when ranch/block is change
  const customDispatchMap = isLeft ? dispatchMap : dispatchMap1;
  useEffect(() => {
    if ((ranchId || blockId) && (stateMap.selectedImageryData.data?.length
      || stateMap1.selectedImageryData.data?.length) && isMobile) {
      customDispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: true,
      });
      customDispatchMap({
        type: 'setIsBottomSheetVisible',
        payload: true,
      });
    }
    // To avoid multiple useEffect trigger also this will be refactored in future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, ranchId]);

  // To close sidebar when select different ranch/block
  useEffect(() => {
    if (!isMobile && !isPlants) {
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: undefined,
          bottomSheetType: undefined,
          showBottomSheet: !!(blockId || ranchId),
        },
      });
    }
  }, [blockId, isPlants, ranchId]);

  // Change tab selection to "left" to update map
  useEffect(() => {
    if (stateMap?.layersControl?.side !== 'left') {
      dispatchMap({
        type: 'setLayersControl',
        payload: { ...stateMap.layersControl, side: 'left' },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, ranchId]);

  const imageryDataOpacityRef = useRef(stateMap.selectedImageryDataOpacity);
  useEffect(() => {
    if (!stateMap.showMap3D && !stateMap.sideBySideMapVisible) {
      if (mapFeatureGroupRef?.current?.style?._loaded && !isAnomaly) {
        clearImageryLayers(mapFeatureGroupRef);
      }
      reDrawSatelliteImagery({
        mapFeatureGroupRef,
        selectedImageryData: isSatellite ? stateMap.selectedImageryData
          : stateMap.selectedDroneImagery,
        selectedImageryDataOpacity: imageryDataOpacityRef.current,
        isAnomaly,
        isSatellite,
        droneDataType: stateMap.selectedDroneDataType,
        t,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMap.selectedImageryData, isSatellite, stateMap.selectedDroneImagery,
    stateMap.sideBySideMapVisible]);

  const clickedButtonHandler = (e) => {
    if (e?.target) {
      const { id } = e.target;
      setActiveImagery(id);
      dispatchMap1({
        type: 'setSideBySideMapVisible',
        payload: id !== 'fullScreen',
      });
      dispatchMap({
        type: 'setSideBySideMapVisible',
        payload: id !== 'fullScreen',
      });
      if (id === 'fullScreen') {
        dispatchMap({
          type: 'setLayersControl',
          payload: { ...stateMap?.layersControl, type: 'tab', side: 'left' },
        });
      }
    } else if (e?.type) {
      if (e?.side === 'left') {
        dispatchMap({
          type: 'setLayersControl',
          payload: { ...e?.layersControl, type: 'tab', side: 'left' },
        });
      } else if (e?.side === 'right') {
        dispatchMap({
          type: 'setLayersControl',
          payload: { ...e?.layersControl, type: 'tab', side: 'right' },
        });
        dispatchMap1({
          type: 'setIsImageryPanelVisible',
          payload: true,
        });
        // To avoid closing the bottom sheet/sidebar
        // When user clicking on right tab on split-screen
        dispatchMap1({
          type: 'setIsBottomSheetVisible',
          payload: true,
        });
      }
    }
  };

  function renderSatelliteImagery(dispatchMapArg, stateArg) {
    return stateArg.bottomSheetType !== 'settings' && stateArg.isImageryPanelVisible
      && (
        <div className={stateMap.selectedFeature ? 'hide-imagery-control' : ''}>
          <DrawerImageryControl
            activeImagery={activeImagery}
            onClick={clickedButtonHandler}
            selected={stateMap}
          />
          <div className="margin-top-10">
            <SatelliteImageryWrapper
              ranchId={ranchId}
              dispatchMapLeft={dispatchMap}
              dispatchMapRight={dispatchMap1}
              stateArg={stateArg}
              isLeft={isLeft}
            />
          </div>
        </div>
      );
  }

  function renderMap(dispatchMapArg, stateMapArg) {
    return !stateMap.showMap3D ? (
      <MapWrapper
        mapRefElem={(d) => {
          if (d?.left) {
            mapFeatureGroupRef.current = d.left;
          }
          if (d?.right) {
            mapFeatureGroupRef1.current = d.right;
          }
        }}
        setModal={setModal}
        presMode={presMode}
        createAnomaly={createAnomaly}
        setCreateAnomaly={setCreateAnomaly}
        stateMapArg={stateMapArg}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        dispatchMapArg={dispatchMapArg}
        dispatchMap={dispatchMap}
        dispatchMap1={dispatchMap1}
        selectedObjFromState={selectedObjFromState}
        isAnomalyControlEnabled={isAnomalyControlEnabled}
        blockId={blkId || blockId}
        ranchId={ranchId}
        ranchGeoJSON={ranchGeoJSON}
        blocksGeoJSON={blocksGeoJSON}
        mapFeatureGroupRef={mapFeatureGroupRef}
        mapFeatureGroupRef1={mapFeatureGroupRef1}
        isLeft={isLeft}
        setLayerOption={setLayerOption}
        setMapSettingModal={setMapSettingModal}
        stateMap={stateMap}
        stateMap1={stateMap1}
        modal={modal}
        formValues={formValues}
        setIsLoading={setIsLoading}
        controlButtons={stateMap.isBottomSheetVisible}
        activeScreen={setActiveImagery}
      />
    ) : null;
  }

  function renderTabsControlForMobile() {
    return isMobile && (
      <div
        style={{
          position: 'relative',
          bottom: '-5px',
          zIndex: '501',
        }}
      >
        <MapTabsControl
          dispatchMap1={dispatchMap1}
          dispatchMap={dispatchMap}
          layersControl={stateMap.layersControl}
          sideBySideMapVisible={stateMap.sideBySideMapVisible}
          isImageryPanelVisible={isLeft ? stateMap.isImageryPanelVisible
            : stateMap1.isImageryPanelVisible}
        />
      </div>
    );
  }

  function showImagerySection() {
    return (
      <div>
        {!isMobile && !stateMap.showMap3D && (
          <div
            style={{
              width: '360px',
              zIndex: '501',
              marginRight: (stateMap.isImageryPanelVisible && stateMap.isBottomSheetVisible)
              || (stateMap1.isImageryPanelVisible && stateMap1.isBottomSheetVisible)
                ? '400px' : '0',
            }}
          >
            {renderSatelliteImagery(isLeft ? dispatchMap : dispatchMap1,
              isLeft ? stateMap : stateMap1)}
          </div>
        )}
      </div>
    );
  }

  function renderMapSettingModal() {
    return (
      <MapSettingModal
        dispatchMap={dispatchMap}
        dispatchMap1={dispatchMap1}
        dispatchMapArg={isLeft ? dispatchMap : dispatchMap1}
        stateMap={stateMap}
        stateMap1={stateMap1}
        stateMapArg={isLeft ? stateMap : stateMap1}
        layerOption={layerOption}
        selMapShowSoilType={selMapShowSoilType}
        mapSettingModal={mapSettingModal}
        onChange={(d) => setActiveImagery(d)}
      />
    );
  }

  const settingsDetail = layerOption
    ? {
      detail: () => renderMapSettingModal(isLeft
        ? dispatchMap : dispatchMap1, isLeft ? stateMap : stateMap1),
    }
    : {};

  const showImageryComponent = layerOption && stateMap?.layersControl?.layer?.side !== 'left'
    ? {
      detail1: () => showImagerySection(),
    }
    : {};

  function renderShowDetails(dispatchMapArg, stateMapArg) {
    if (!stateMap.showMap3D) {
      return (
        <ShowDetails
          blockId={blockId}
          ranchId={ranchId}
          selectedObjFromState={selectedObjFromState}
          isMobile={isMobile}
          presentationMode={presMode}
          isBottomSheetVisible={stateMap.isBottomSheetVisible}
          selectedFeature={stateMap.selectedFeature}
          dispatchMap={dispatchMap}
          sheetRef={sheetRef}
          bottomSheetType={stateMapArg.bottomSheetType}
          isImageryPanelVisible={stateMap.isImageryPanelVisible}
          selectedImageryData={stateMapArg.selectedImageryData}
          isExpandBtnVisible={stateMap.isExpandBtnVisible}
          expandedGeoJSON={stateMapArg.expandedGeoJSON}
          childComponent={{
            tab: renderTabsControlForMobile(),
            ...settingsDetail,
            ...showImageryComponent,
          }}
          isLeft={isLeft}
          onClick={(d) => {
            if (d?.type === 'close') {
              if (createAnomaly?.type === 'edit') {
                setIsAnomalyControlEnabled(false);
                setCreateAnomaly({ visible: false });
              }
              if (layerOption) {
                // Retain layer option selection for only desktop view
                if(!isMobile) setLayerOption(false);
                dispatchMapArg({
                  type: 'setIsImageryPanelVisible',
                  payload: stateMap.selectedImageryData.visible,
                });
                dispatchMapArg({
                  type: 'setSelectedFeatureWithType',
                  payload: {
                    selectedFeature: undefined,
                    bottomSheetType: undefined,
                    showBottomSheet: stateMap.isBottomSheetVisible,
                  },
                });
              }
            } else {
              formValues.current.anomaly_severity = stateMapArg.selectedFeature
                .properties.anomaly_severity;
              setModal({ visible: true, type: 'edit-severity' });
            }
          }}
          editable={presMode === 'anomaly' && stateMapArg.selectedFeature
            ? stateMapArg.selectedFeature?.properties?.is_user_created : false}
          activeImagery={activeImagery}
          onClickImagery={clickedButtonHandler}
          selected={stateMap}
          layerOption={layerOption}
          isBlockPlantView={isBlockPlantView}
          isSplitScreen={stateMap.sideBySideMapVisible}
          isDrone={(isPlants && stateMap.selectedSatelliteDataSource === 'drone') || undefined}
          dispatchMapLeft={dispatchMap}
          dispatchMapRight={dispatchMap1}
          stateArg={stateMapArg}
          dispatchMap1={dispatchMap1}
          prevSelection={prevSelection.current}
        />
      );
    }
    return null;
  }

  function renderMapControlBox() {
    return (
      <div className="map-component-mobile">
        <MapControlBox
          presMode={presMode}
          createAnomaly={createAnomaly}
          setCreateAnomaly={setCreateAnomaly}
          setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
          setModal={setModal}
          isBottomSheetVisible={stateMap.isBottomSheetVisible}
          isImageryPanelVisible={stateMap.isImageryPanelVisible}
          stateMapArg={isLeft ? stateMap : stateMap1}
          selectedFeature={isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature}
          selectedObjFromState={selectedObjFromState}
          dispatchMap={dispatchMap}
          isAnomalyControlEnabled={isAnomalyControlEnabled}
          blockId={blockId}
          ranchId={ranchId}
          setShowModal={setShowModal}
          setMapSettingModal={setMapSettingModal}
          setLayerOption={setLayerOption}
          anomalyGeoJSON={stateMap.anomalyGeoJSON}
          sideBySideMapVisible={stateMap.sideBySideMapVisible}
          onChange={(d) => { mapFeatureGroupRef.current = d; }}
          controlButtons={stateMap.isBottomSheetVisible}
          isDataLoading={stateMap.isLoading || stateMap1.isLoading || stateMap.isPlantLoading
            || stateMap.is3DMapLoading}
          activeScreen={setActiveImagery}
          isAllRanch={isAllRanch}
          is3DView={stateMap.showMap3D}
        />
        {isLeft ? renderMap(dispatchMap, stateMap) : renderMap(dispatchMap1, stateMap1)}
      </div>
    );
  }

  function renderColorScale() {
    return (
      <div>
        {isPlants && stateMap.plantsOption
        && <ColorBar colorKey={stateMap.plantsOption} />}

        {!stateMap.sideBySideMapVisible && stateMap.isImageryPanelVisible
        && (
        <ColorBar
          droneKey={droneKey}
          satelliteKey={satelliteKey}
          side={side}
          dateRangeWithDisplayData={stateMap.dateRangeWithDisplayData}
          isDrone={isDrone}
          imageryData={{
            left: stateMap.selectedDroneImagery,
            right: stateMap1.selectedDroneImagery
          }}
        />
        )}

        {stateMap.sideBySideMapVisible && (
        <div className={!stateMap.sideBySideMapVisible ? 'mobile-color-scale-section'
          : 'mobile-color-scale-side-by-side'}
        >
          <div className="color-bar-left-side color-bar-left-side-position ">
            {((((colorMapSide.current.left || colorMapSide1.current.left) || isLeft)
               || !isLeft)
              && (
              <ColorBar
                droneKey={colorMapSide.current.left || droneKey}
                satelliteKey={colorMapSide1.current.left || satelliteKey}
                side="left"
                dateRangeWithDisplayData={stateMap.dateRangeWithDisplayData}
                isDrone={isDrone}
                imageryData={{
                  left: stateMap.selectedDroneImagery,
                  right: stateMap1.selectedDroneImagery
                }}
              />
              ))}
          </div>
          <div className="color-bar-right-side color-bar-right-side-position ">
            {((colorMapSide.current.right || colorMapSide1.current.right)
              && (
              <ColorBar
                droneKey={colorMapSide.current.right || droneKeyRight}
                satelliteKey={colorMapSide1.current.right || satelliteKeyRight}
                side="right"
                isSplitScreen={stateMap.sideBySideMapVisible}
                dateRangeWithDisplayData={stateMap1.dateRangeWithDisplayData}
                isDrone={isDroneRight}
                imageryData={{
                  left: stateMap.selectedDroneImagery,
                  right: stateMap1.selectedDroneImagery
                }}
              />
              ))}
          </div>
        </div>
        )}
      </div>
    );
  }

  const CONDITION_CHECK = stateMap.isBottomSheetVisible && !isAllRanch;

  return (
    <div className="map-mobile">
      <div className={!isMobile && CONDITION_CHECK
        ? 'map-desktop-container' : 'map-mobile-container'}
      >
        {renderMapControlBox()}
      </div>

      {modal.visible && (
      <AnomalyCreateEditModal
        modal={modal}
        setModal={setModal}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        isLoading={isLoading}
        formValues={formValues}
        setCreateAnomaly={setCreateAnomaly}
        blockId={blkId}
        selectedFeature={stateMap.selectedFeature}
        dispatchMap={dispatchMap}
      />
      )}

      {/* This will be removed after confirmation */}
      {/* {!isMobile && !stateMap.showMap3D && (
        <div
          style={{
            position: 'absolute',
            bottom: '-2px',
            width: '500px',
            zIndex: '501',
            marginRight: (stateMap.isImageryPanelVisible && stateMap.isBottomSheetVisible)
              || (stateMap1.isImageryPanelVisible && stateMap1.isBottomSheetVisible)
              ? '400px' : '0',
          }}
        >
          <MapTabsControl
            dispatchMap1={dispatchMap1}
            dispatchMap={dispatchMap}
            layersControl={stateMap.layersControl}
            sideBySideMapVisible={stateMap.sideBySideMapVisible}
            isImageryPanelVisible={isLeft ? stateMap.isImageryPanelVisible
              : stateMap1.isImageryPanelVisible}
          />
          {renderSatelliteImagery(isLeft ? dispatchMap : dispatchMap1,
            isLeft ? stateMap : stateMap1)}
        </div>
      )} */}
      <LegendModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        legendDetails={legendConfig[presModeTitles[presMode]]}
        articleId={legendConfig[presModeTitles[presMode]]?.articleId}
        childComponent={() => renderColorScale()}
        className={(isDrone && !isPlants) && 'drone-color-bar'}
      />
      {blkId && stateMap.showMap3D && !isMobile ? (
        <Map3D
          dispatchMapPage={dispatchMap}
          selectedBlockId={blkId}
        />
      ) : null}

      {isLeft ? renderShowDetails(dispatchMap, stateMap)
        : renderShowDetails(dispatchMap1, stateMap1)}
    </div>
  );
}

MapView.propTypes = {
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
};
