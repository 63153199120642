/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { ValveStatus } from 'types/sensor';
import { SoilSensorSummary } from './SoilSensorSummary';
import { FlowMeterSummary } from './FlowMeterSummary';
import { WeatherSummary } from './WeatherSummary';
import { PlantSensorSummary } from './PlantSensorSummary';
import { IRSensorSummary } from './IRSensorSummary';
import { ValveSummary } from './ValveSummary';
import { PressureSensorSummary } from './PressureSensorSummary';
import { FrostSensorSummary } from './FrostSensorSummary';
import { PumpSummary } from './PumpSummary';

export interface ListCardsProps {
  objKey: string;
  sensorsData:any; // for time being
  selectedObj: any; // for time being
}

const ListCards = ({
  objKey, sensorsData, selectedObj,
}) => {
  const {
    aquacheck_soil, pixl_soil, water_flow, water_flow_analog,
  } = sensorsData || {};
  const soilList = [
    ...(aquacheck_soil || []),
    ...(pixl_soil || []),
  ];
  const flowMeterList = [
    ...(water_flow_analog || []),
    ...(water_flow || []),
  ];

  if (objKey === 'aquacheck_soil' || objKey === 'pixl_soil') {
    return (
      <Link
        to={{
          pathname: '/list/soil-sensor',
          selectedObj,
        }}
      >
        <SoilSensorSummary list={soilList} />
      </Link>
    );
  } if (objKey === 'water_flow_analog' || objKey === 'water_flow') {
    return (
      <Link
        to={{
          pathname: '/list/flow-sensor',
          selectedObj,
        }}
      >
        <FlowMeterSummary list={flowMeterList} />
      </Link>
    );
  } if (objKey === 'weather_station') {
    return (
      <Link
        to={{
          pathname: '/list/weather',
          selectedObj,
        }}
      >
        <WeatherSummary list={sensorsData.weather_station} />
      </Link>
    );
  } if (objKey === 'farmx_dendrometer') {
    return (
      <Link
        to={{
          pathname: '/list/plant-sensor',
          selectedObj,
        }}
      >
        <PlantSensorSummary list={sensorsData.farmx_dendrometer} />
      </Link>
    );
  } if (objKey === 'farmx_infrared') {
    return (
      <Link
        to={{
          pathname: '/list/infrared-sensor',
          selectedObj,
        }}
      >
        <IRSensorSummary
          list={sensorsData.farmx_infrared}
        />
      </Link>
    );
  } if (objKey === 'valve') {
    return (
      <Link
        to={{
          pathname: '/list/valve-sensor',
          selectedObj,
        }}
      >
        <ValveSummary list={sensorsData.valve} />
      </Link>
    );
  } if (objKey === 'water_pressure') {
    return (
      <Link
        to={{
          pathname: '/list/pressure-sensor',
          selectedObj,
        }}
      >
        <PressureSensorSummary list={sensorsData.water_pressure} />
      </Link>
    );
  } if (objKey === 'farmx_frost') {
    return <FrostSensorSummary list={sensorsData.farmx_frost} />;
  } if (objKey === 'vfd') {
    return (
      <Link
        to={{
          pathname: '/list/pump',
          selectedObj,
        }}
      >
        <PumpSummary list={sensorsData.vfd} />
      </Link>
    );
  } return null;
};

export default ListCards;

// ListCards.propTypes = {
//   objKey: PropTypes.string.isRequired,
//   sensorsData: PropTypes.shape().isRequired,
//   selectedObj: PropTypes.shape().isRequired,
//   sensorStatusData: PropTypes.shape().isRequired,
// };
