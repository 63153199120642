import React, {
  useCallback, useEffect, useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { displayConvertedDepth } from 'helper/depthUnitHelper';
import { FaEdit } from 'react-icons/fa';
import {
  computeMinAndMax,
  cutoffLineLabelStyle,
  getColors, getSoilCutoffPoints
} from '../../helper/graphHelper';
import {
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
} from '../../utils/colors';
import NewSensorDataChart from './NewSensorDataChart';
import EditSoilSensorProperties from './EditSoilSensorProperties';
import { CALL_CUSTOM_CONFIG_AND_RENDER } from './constants';

const { useUnits } = hooks;
const { selectUserDepthFormat }= selectors;

export default function MultilineDepthChart(props) {
  const {
    sensor, uniformYAxis, yMinMaxMap,
    preparedConfig, switchMode, refreshGrapData,
    graphData, startDate, endDate, variables
  } = props;
  const getUserUnits = useUnits();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [chartLegend, setChartLegend] = useState({});
  const [listDepth, setListDepth] = useState({});

  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensor.type, sensor.identifier),
  );
  const depthFormat = useSelector(selectUserDepthFormat);
  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);

  const updateChartLegend = useCallback((key, value) => {
    const { color, isRootzone } = value;
    if (chartLegend[key]) {
      if (
        chartLegend[key]?.color === color
        && chartLegend[key]?.isRootzone === isRootzone
      ) return;
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color, isRootzone
        },
      }));
    } else {
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color, isRootzone
        },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderChartLegend = (chartLeg, identifier) => {
    if (!chartLeg || !Object.keys(chartLeg).length) return [];
    const filteredLegend = Object.keys(chartLeg).map((chartLegKey) => {
      const chartLegLabel = chartLegKey.split('soil_moisture_')[1];
      if (!chartLegLabel) return null;
      return {
        chartLegLabel,
        chartLegKey,
      };
    });
    return filteredLegend
      .filter((item) => item !== null)
      .sort((a, b) => a.chartLegLabel.localeCompare(b.chartLegLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
      .map(({ chartLegKey, chartLegLabel }) => {
        const individualListDepth = listDepth[chartLegKey];
        const isEnabled = individualListDepth === undefined || individualListDepth;
        return (
          <Button
            key={`legend-pill-${sensor.id}-${chartLegLabel}`}
            className="legend-pill"
            style={{
              backgroundColor: (
                isEnabled ? chartLeg[chartLegKey].color : 'lightgray'
              ),
              border: `3px solid ${chartLeg[chartLegKey].isRootzone ? colorOk : 'grey'}`,
            }}
            onClick={() => onChartLegendClick({
              identifier, chartLegKey,
            })}
          >
            {`${displayConvertedDepth(chartLegLabel, getUserUnits, depthFormat)}`}
          </Button>
        );
      });
  };

  const onChartLegendClick = (params) => {
    const { chartLegKey } = params || {};
    if (!chartLegKey) return;
    if (listDepth) {
      const isEnabled = listDepth[chartLegKey];
      const newVal = isEnabled === undefined ? false : !isEnabled;
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: newVal,
      }));
    } else {
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: false,
      }));
    }
  };

  // TODO remove after testing
  // console.log('chartLegend', chartLegend);
  // console.log('listDepth', listDepth);

  // TODO remove after testing
  // console.log('Render SoilMoistureAllDepthsChart', preparedConfig);

  // TODO remove after testing
  // console.log('yMinMaxMap SoilMoistureAllDepthsChart', yMinMaxMap);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('Mount SoilMoistureAllDepthsChart');
  // }, []);

  const customConfigForMultiline = useCallback((configObj, seriesData, chartRef) => {
    // setGraphData(seriesData);
    // TODO remove after testing
    // console.log('calling customConfig', configObj, graphData);
    // console.log('yMinMax all depth', yMinMax);
    if (configObj && configObj.series?.length) {
      // update y-axis
      const yMinMax = computeMinAndMax(seriesData, sensorStatus);
      const { multiplier } = configObj?.series?.[0];
      // updateYminmax(SOIL_MOISTURE_ALL_DEPTHS, yMinMax.min * multiplier, yMinMax.max * multiplier);
      // TODO remove after testing
      // console.log('yMinMax all depth', yMinMax, seriesData, sensorStatus);
      // console.log('sensorStatus', sensorStatus);
      const yAxis = configObj.yAxis?.map(
        (attr) => (
          {
            ...attr,
            min: yMinMax.min * multiplier,
            max: yMinMax.max * multiplier
          })
      );

      // update series data
      const updatedSeries = configObj.series.map((seriesData, i) => {
        if(chartRef && chartRef.current) {
          if(listDepth[seriesData.actualChartKey] === false) {
            const filteredSeries = chartRef.current.chart?.series?.filter((seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey);
            // TODO remove after testing
            // console.log('chartRef.current.chart.series', filteredSeries);
            if(filteredSeries?.length) filteredSeries[0].hide();
          }else{
            const filteredSeries = chartRef.current.chart?.series?.filter((seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey);
            // TODO remove after testing
            // console.log('chartRef.current.chart.series', filteredSeries);
            if(filteredSeries?.length) filteredSeries[0].show();
          }
        }

        const cutOffPoints = getSoilCutoffPoints(seriesData, sensorStatus);
        const seriesOption = {...seriesData};
        const seriesOptionsColor = getColor(seriesOption.actualChartKey);
        seriesOption.name = `Soil Moisture ${displayConvertedDepth(cutOffPoints.depth, getUserUnits, depthFormat)} (%)`;
        updateChartLegend(
          seriesOption.actualChartKey,
          {
            color: seriesOptionsColor,
            isRootzone: cutOffPoints.isRootzone,
          },
        );
        // TODO remove after testing
        // console.log('seriesOption', seriesOption);
        seriesOption.color = seriesOptionsColor;
        const multiplier = 100;
        seriesOption.point = {
          events: {
            mouseOver() {
              // TODO remove after testing
              // console.log('plottling lines', cutOffPoints);
              try {
                const yAxisRef = chartRef.current?.chart?.yAxis[i];
                // TODO remove after testing
                // console.log('yAxisRef', chartRef.current?.chart);
                if (yAxisRef) {
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.wiltingPoint * multiplier,
                    color: colorCritical,
                    width: 2,
                    id: 'moisture_zone_lower',
                    dashStyle: 'dash',
                    label: {
                      x: 0,
                      y: 12,
                      align: 'right',
                      text: `WP (${Number(cutOffPoints.wiltingPoint * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.refillPoint * multiplier,
                    color: colorWarning,
                    width: 2,
                    id: 'moisture_zone_middle',
                    dashStyle: 'dash',
                    label: {
                      x: -55,
                      y: 12,
                      align: 'right',
                      text: `RP (${Number(cutOffPoints.refillPoint * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.fieldCapacity * multiplier,
                    color: colorOver,
                    width: 2,
                    id: 'moisture_zone_upper',
                    dashStyle: 'dash',
                    label: {
                      x: -110,
                      y: 12,
                      align: 'right',
                      text: `FC  (${Number(cutOffPoints.fieldCapacity * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                }
              } catch (error) {
                console.log('Error in hover', error);
              }
            },
            mouseOut() {
              try {
                const yAxisRef = chartRef.current?.chart?.yAxis[i];
                if (yAxisRef) {
                  yAxisRef.removePlotLine('moisture_zone_lower');
                  yAxisRef.removePlotLine('moisture_zone_middle');
                  yAxisRef.removePlotLine('moisture_zone_upper');
                }
              } catch (error) {
                console.log('mouseOut error', error);
              }
            },
          },
        };
        return seriesOption;
      });
      // TODO remove after testing
      // console.log('updatedSeries,yAxis', updatedSeries);
      const newConfig = { ...configObj, series: updatedSeries, yAxis};
      // TODO remove after testing
      // console.log('custom config seriesData', newConfig, seriesData);
      return newConfig;
    }
    return configObj;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthFormat, getColor, listDepth, sensorStatus, updateChartLegend]);

  const onClickEdit = useCallback(() => {
    setEdit(true);
  });

  const onCancel = useCallback(() => {
    setEdit(false);
  });

  const onSave = useCallback(() => {
    setEdit(false);
    refreshGrapData();
  });

  const EditLink = useMemo(() => (
    <div
      key={`edit-link-${sensor.id}`}
      className="chart-header-edit-link"
      onClick={onClickEdit}
      onKeyDown={onClickEdit}
      role="button"
      tabIndex={0}
    >
      <FaEdit />
      <span style={{marginLeft: '13px'}}>
        Edit
      </span>
    </div>
  ), [onClickEdit, sensor.id]);

  // TODO remove after testing
  // console.log('preparedConfig multiline moisture', preparedConfig);

  return (
    <>
      {/* Display multiline moisture chart */}
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        customConfig={customConfigForMultiline}
        header={{
          title: t('Soil Moisture VWC (Combined)'),
          units: '',
          left: [...renderChartLegend(chartLegend, sensor.identifier), switchMode],
          right: [EditLink]
        }}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMaxMap}
        renderOption={CALL_CUSTOM_CONFIG_AND_RENDER}
        preparedConfig={preparedConfig}
        variables={variables}
        startDate={startDate}
        endDate={endDate}
      />
      {edit?(
        <EditSoilSensorProperties
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          onSave={onSave}
          chartData={graphData}
        />
      ):null}
    </>
  );
}

MultilineDepthChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
  preparedConfig: PropTypes.shape({}),
  graphData: PropTypes.shape({}),
  switchMode: PropTypes.shape({}),
  refreshGrapData: PropTypes.func,
  variables: PropTypes.arrayOf(PropTypes.any),
};

MultilineDepthChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  uniformYAxis: false,
  yMinMaxMap: null,
  preparedConfig: null,
  graphData: null,
  switchMode: null,
  refreshGrapData: () => null,
  variables: null,
};
