import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SOLAR_RADIATION } from 'components/hooks/graph';
import { getYminMax } from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_SOLAR_RADIATION } from './constants';

interface Sensor {
  name?: string;
  id?: number;
  type?: string;
  identifier?: string;
}

interface SolarRadiationChartProps {
  sensor?: Sensor | any;
  startDate?: any;
  endDate?: any;
  cached?: string | any;
  updateYminmax?: () => void;
  uniformYAxis?: boolean;
  yMinMaxMap?: any;
}

const SolarRadiationChart: React.FC<SolarRadiationChartProps> = (props: any) => {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();
  const [label, setLabel] = useState('');

  const globalYMinMax = yMinMaxMap && yMinMaxMap[SOLAR_RADIATION]
    ?yMinMaxMap[SOLAR_RADIATION]:{min: null, max: null};

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) { return { min: globalYMinMax.min, max: globalYMinMax.max}; }
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const customConfig = useCallback((configObj, seriesData) => {
    if(configObj?.series?.[0]){
      const yMinMax = getYminMax(seriesData) || { min: 0, max: 0};
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(SOLAR_RADIATION, yMinMax.min * multiplier, yMinMax.max * multiplier);
      const data = configObj?.series?.[0].data;
      const yMin = yMinMax?.min;
      const yMax = yMinMax?.max;
      const { units } = seriesData?.solar_radiation?.[0];
      const dataUnit = units || 'watts_per_square_meter';
      setLabel(dataUnit);
      return {
        ...configObj,
        series: [{
          ...configObj?.series?.[0],
          data,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ` ${dataUnit}`,
          },
          name: 'Solar Radiation',
        }],
        yAxis: [{
          ...configObj.yAxis[0],
          min: yMin,
          max: yMax
        }],
      };
    }

    return configObj;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_SOLAR_RADIATION}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Solar Radiation'), units: t(label), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
};

SolarRadiationChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};

export default SolarRadiationChart;
