import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ChartComponent } from 'farmx-web-ui';
import { sensorApi } from 'farmx-api';
import { generateValveOption } from '../../../helper/valveChartHelper';
import './valveSensorChart.less';

const { loadSensorData } = sensorApi;

export function ValveSensorChart(props) {
  const { sensorId, type } = props;
  const [chartOptions, setChartOptions] = useState(generateValveOption());
  const chartRef = useRef(null);

  /**
   * Load chart data
   */
  useEffect(() => {
    const chartObj = chartRef?.current?.highchartsComponent?.current?.chart;
    if (chartObj) {
      chartObj.showLoading();
    }
    const now = moment();
    const startDate = now.startOf('day').clone().subtract(2, 'weeks');
    const endDate = now.endOf('day');
    const chartVariables = ['state'];
    loadSensorData(type, sensorId, chartVariables, startDate, endDate)
      .then((res) => {
        if (res && res.data) {
          const { data } = res;
          setChartOptions(generateValveOption(data));
        }
        if (chartObj) {
          chartObj.hideLoading();
        }
      })
      .catch(() => {
        if (chartObj) {
          chartObj.hideLoading();
        }
      });
  }, [sensorId, type]);


  return (
    <div className="valve-sensor-chart-container mobile-list-item">
      <div className="chart-container-wrapper">
        <ChartComponent
          ref={chartRef}
          options={chartOptions}
        />
      </div>
    </div>
  );
}

ValveSensorChart.propTypes = {
  sensorId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

ValveSensorChart.defaultProps = {
  type: 'valve',
};
