import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import './contentLoader.less';

// For the recommendation page
function renderDefault() {
  return (
    <ContentLoader
      height={550}
      width={360}
      speed={2}
    >
      <rect x="10" y="20" rx="3" ry="3" width="360" height="100" />
      <rect x="10" y="140" rx="3" ry="3" width="360" height="100" />
      <rect x="10" y="260" rx="3" ry="3" width="360" height="100" />
      <rect x="10" y="380" rx="3" ry="3" width="360" height="100" />
    </ContentLoader>
  );
}

function renderCropTypeText() {
  return (
    <ContentLoader
      height={50}
      width={120}
      speed={2}
      className="crop-type-loader"
    >
      <rect x="10" y="20" rx="3" ry="3" width="120" height="30" />
    </ContentLoader>
  );
}

const ReactContentLoader = (props) => {
  const { type } = props;
  return (
    <>
      {!type ? renderDefault() : null}
      {type === 'cropTypeText' && renderCropTypeText()}
    </>
  );
};

ReactContentLoader.propTypes = {
  type: PropTypes.string,
};

ReactContentLoader.defaultProps = {
  type: null,
};

export default ReactContentLoader;
