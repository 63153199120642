import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { SOIL_TEMPERATURE } from 'components/hooks/graph';
import { Button } from 'antd';
import { colorOk } from 'utils/colors';
import { displayConvertedDepth } from 'helper/depthUnitHelper';
import {
  computeYMinAndMaxForAllDepths,
  convertToCelsius,
  convertToFahrenheit,
  getColors,
  getDepthVariables,
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';

const { useUnits } = hooks;
const { selectUserDepthFormat } = selectors;

export default function SoilTemperatureChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const [chartLegend, setChartLegend] = useState({});
  const [listDepth, setListDepth] = useState({});
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensor.type, sensor.identifier),
  );
  const depthFormat = useSelector(selectUserDepthFormat);
  const getUserUnits = useUnits();

  const globalYMinMax = yMinMaxMap[SOIL_TEMPERATURE]
    ?yMinMaxMap[SOIL_TEMPERATURE]
    :{min: null, max: null};

  const updateChartLegend = useCallback((key, value) => {
    const { color } = value;
    if (chartLegend[key]) {
      if (
        chartLegend[key]?.color === color
      ) return;
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color
        },
      }));
    } else {
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color
        },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColor = useMemo(() => getColors(sensorStatus, 'temp'), [sensorStatus]);

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
      && globalYMinMax.max) { return { min: globalYMinMax.min, max: globalYMinMax.max}; }
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const renderChartLegend = (chartLeg, identifier) => {
    if (!chartLeg || !Object.keys(chartLeg).length) return [];
    const filteredLegend = Object.keys(chartLeg).map((chartLegKey) => {
      const chartLegLabel = chartLegKey.split('soil_temp_')[1];
      if (!chartLegLabel) return null;
      return {
        chartLegLabel,
        chartLegKey,
      };
    });
    return filteredLegend
      .filter((item) => item !== null)
      .sort((a, b) => a.chartLegLabel.localeCompare(b.chartLegLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
      .map(({ chartLegKey, chartLegLabel }) => {
        const individualListDepth = listDepth[chartLegKey];
        const isEnabled = individualListDepth === undefined || individualListDepth;
        return (
          <Button
            key={`legend-pill-${sensor.id}-${chartLegLabel}`}
            className="legend-pill"
            style={{
              backgroundColor: (
                isEnabled ? chartLeg[chartLegKey].color : 'lightgray'
              ),
              border: `3px solid ${chartLeg[chartLegKey].isRootzone ? colorOk : 'grey'}`,
            }}
            onClick={() => onChartLegendClick({
              identifier, chartLegKey,
            })}
          >
            {`${displayConvertedDepth(chartLegLabel, getUserUnits, depthFormat)}`}
          </Button>
        );
      });
  };

  const onChartLegendClick = (params) => {
    const { chartLegKey } = params || {};
    if (!chartLegKey) return;
    if (listDepth) {
      const isEnabled = listDepth[chartLegKey];
      const newVal = isEnabled === undefined ? false : !isEnabled;
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: newVal,
      }));
    } else {
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: false,
      }));
    }
  };

  const customConfig = useCallback((configObj, seriesData, chartRef) => {
    let yAxis = {};
    let tooltipLabelSuffix = '';
    if(configObj?.series?.[0]){
      const yMinMax = computeYMinAndMaxForAllDepths(seriesData, sensorStatus, 'temp');
      const { multiplier, originalUnit } = configObj?.series?.[0];
      updateYminmax(SOIL_TEMPERATURE, yMinMax.min * multiplier, yMinMax.max * multiplier);
      const celsisus = originalUnit.indexOf('C') > 0;
      const {value: yMin, label} = celsisus? convertToFahrenheit(yMinMax.min * multiplier,
        getUserUnits)
        :convertToCelsius(yMinMax.min * multiplier,
          getUserUnits);
      const {value: yMax} = celsisus? convertToFahrenheit(yMinMax.max * multiplier,
        getUserUnits)
        :convertToCelsius(yMinMax.max * multiplier,
          getUserUnits);
      tooltipLabelSuffix = label;
      setLabel(label);
      yAxis = configObj.yAxis?.map(
        (attr) => (
          {
            ...attr,
            min: yMin,
            max: yMax
          })
      );
    }

    // update series data
    const updatedSeries = configObj.series.map((seriesData, i) => {
      if(chartRef && chartRef.current) {
        if(listDepth[seriesData.actualChartKey] === false) {
          const filteredSeries = chartRef.current.chart?.series?.filter((seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey);
          if(filteredSeries?.length) filteredSeries[0].hide();
        }else{
          const filteredSeries = chartRef.current.chart?.series?.filter((seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey);
          if(filteredSeries?.length) filteredSeries[0].show();
        }
      }

      const seriesOption = {...seriesData};
      const seriesOptionsColor = getColor(seriesOption.actualChartKey);
      const depth = seriesOption.actualChartKey.split('soil_temp_')[1];
      seriesOption.name = `Soil Temperature ${displayConvertedDepth(depth, getUserUnits, depthFormat)}`;
      updateChartLegend(
        seriesOption.actualChartKey,
        {
          color: seriesOptionsColor,
        },
      );
      seriesOption.color = seriesOptionsColor;
      seriesOption.tooltip = {
        valueDecimals: 1,
        valueSuffix: tooltipLabelSuffix
      };
      return seriesOption;
    });

    const newConfig = { ...configObj, series: updatedSeries || configObj.series, yAxis};
    return newConfig;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthFormat, getColor, listDepth, sensorStatus, updateChartLegend, updateYminmax]);

  const tempVariables = useMemo(() => getDepthVariables(sensorStatus, 'temp'), [sensorStatus]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={tempVariables}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{
          title: t('Soil Temperature'), units: t(label), left: [renderChartLegend(chartLegend, sensor.identifier)], right: []
        }}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

SoilTemperatureChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
};

SoilTemperatureChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
