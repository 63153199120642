import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, notification, Radio, Switch, Typography,
} from 'antd';
import { sensorApi } from 'farmx-api';
import { VWCMultilineGraph } from '../mobile/home/VWCMultilineGraph';
import { calculateRefillPercent, calculateRefillPoint } from '../mobile/settings/settingsHelper';
import { getLimitedData } from '../../helper/graphHelper';
import { CutoffPointsInput } from '../mobile/settings/CutoffPointsInput';

export default function EditSensorSettings(props) {
  const {
    chartData,
    onCancel,
    refillPoints,
    depthIndex,
    sensorId,
    sensorType,
    optionalId,
    chartOptions,
  } = props;
  const {
    fieldCapacity: initialFieldCapacity,
    wiltingPoint: initialWiltingPoint,
    refillPoint: InitialRefillPoint,
    data,
  } = chartData || {};
  const limitedDataSet = getLimitedData(data);
  const formattedData = limitedDataSet?.map((d) => ({ x: d[0], y: d[1] })) || [];
  const [fieldCapacity, setFieldCapacity] = useState(initialFieldCapacity);
  const [wiltingPoint, setWiltingPoint] = useState(initialWiltingPoint);
  const [refillPoint, setRefillPoint] = useState(InitialRefillPoint);
  const [refillPercent, setRefillPercent] = useState(refillPoints[`refillPercent${depthIndex}`]);
  const [isRootzone, setIsRootzone] = useState(refillPoints[`isRootzone${depthIndex}`]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState('fieldCapacity');
  const onRadioChange = (e) => {
    setEditMode(e.target.value);
  };
  /**
   * Store refs for input fields so that we can set focus on them if
   * user selects addonText to edit the input field.
   */
  const refillPercentInputRef = useRef();
  const fieldCapacityInputRef = useRef();
  const wiltingPointInputRef = useRef();
  // To render the input fileds
  const cutOffPointEditOptions = [
    {
      id: `fieldCapacity${optionalId}`,
      inputLabel: 'Field Capacity',
      inputValue: fieldCapacity,
      addonText: `(${t('vwc')})`,
      ref: fieldCapacityInputRef,
    },
    {
      id: `refillPercent${optionalId}`,
      inputLabel: 'Refill Percent',
      inputValue: refillPercent,
      addonText: `(${(refillPoint * 100).toFixed(2)}% ${t('vwc')})`,
      ref: refillPercentInputRef,
    },
    {
      id: `wiltingPoint${optionalId}`,
      inputLabel: 'Wilting Point',
      inputValue: wiltingPoint,
      addonText: `(${t('vwc')})`,
      ref: wiltingPointInputRef,
    },
  ];
  // Set focus on input field
  const setFocus = useCallback((ref) => {
    if (ref && ref.current && ref.current.focus) {
      ref.current.focus();
    }
  }, []);

  const adjustRefillPoint = (fc, wp) => {
    const midpoint = (fc + wp) / 2;
    setRefillPoint(midpoint);
    setRefillPercent(calculateRefillPercent(Number(midpoint), Number(wp), Number(fc)));
  };

  const onChange = (event) => {
    const e = {
      ...event,
      id: event.id.split(optionalId)[0],
    };
    setEditMode(e.id);
    const value = Number(e.value) / 100 || 0;
    if (e && e.id === 'fieldCapacity') {
      setFieldCapacity(value);
      adjustRefillPoint(value, Number(wiltingPoint));
    }
    if (e && e.id === 'refillPoint') {
      setRefillPoint(value);
      setRefillPercent(
        calculateRefillPercent(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'refillPercent') {
      setRefillPercent(value);
      setRefillPoint(
        calculateRefillPoint(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'wiltingPoint') {
      setWiltingPoint(value);
      adjustRefillPoint(Number(fieldCapacity), value);
    }
  };

  function cutOffPointValidation(wp, rp, fc) {
    if (wp < rp && wp < fc && rp < fc) {
      return true;
    }
    return false;
  }

  const saveSettings = () => {
    const validPoints = cutOffPointValidation(wiltingPoint, refillPoint, fieldCapacity);
    if (validPoints) {
      setLoading(true);
      setEditMode(null);
      const sensorProperties = {
        ...refillPoints,
        [`fieldCapacity${depthIndex}`]: Number(fieldCapacity).toFixed(3),
        [`refillPercent${depthIndex}`]: Number(refillPercent).toFixed(3),
        [`wiltingPoint${depthIndex}`]: Number(wiltingPoint).toFixed(3),
        [`isRootzone${depthIndex}`]: isRootzone,
      };

      sensorApi.updateSoilSensorProperties({
        data: sensorProperties,
        id: sensorId,
        type: sensorType,
      }).then(() => {
        onCancel();
        setLoading(false);
        notification.success({
          message: t('Successfully updated soil-properties'),
        });
      }).catch(() => {
        onCancel();
        setLoading(false);
        notification.error({
          message: t('Error in updating soil-properties'),
        });
      });
    }
  };

  const toggleIsRootzone = () => {
    setIsRootzone((currentValue) => !currentValue);
  };

  return (
    <div className="edit-sensor-settings">
      <div className="edit-sensor-settings-header">
        <span className="edit-sensor-settings-rootzone-info">
          <Switch
            checked={isRootzone}
            onChange={toggleIsRootzone}
          />
          <Typography.Text>{t('Is Rootzone')}</Typography.Text>
        </span>
        <Radio.Group className="sensor-settings-radio" onChange={onRadioChange} value={editMode} defaultValue="fieldCapacity">
          <Radio.Button value="fieldCapacity">{t('Field Capacity')}</Radio.Button>
          <Radio.Button value="refillPoint">{t('Refill Point')}</Radio.Button>
          <Radio.Button value="wiltingPoint">{t('Wilting Point')}</Radio.Button>
        </Radio.Group>
        <span className="edit-sensor-save-cancel">
          <Button type="primary" onClick={saveSettings} loading={loading}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </span>
      </div>
      <div style={{ backgroundColor: '#aaa', height: '200px', marginTop: '10px' }}>
        <VWCMultilineGraph
          showPlotLineValues
          fieldCapacity={fieldCapacity}
          wiltingPoint={wiltingPoint}
          refillPoint={refillPoint}
          editMode={[editMode]}
          onChange={onChange}
          data={formattedData}
          chartType="line"
          options={{
            chart: {
              marginTop: 40,
            },
            ...chartOptions,
            yAxis: {
              tickInterval: null,
              ...chartOptions.yAxis,
            },
          }}
          optionalId={optionalId}
          extraChartOptions={{
            langOptions: {
              noData: '',
            },
          }}
        />
      </div>
      <div className="cutoff-input-container">
        {cutOffPointEditOptions.map((inputData) => (
          <CutoffPointsInput
            ref={inputData.ref}
            id={inputData.id}
            label={inputData.inputLabel}
            addonText={inputData.addonText}
            onChange={onChange}
            setFocus={setFocus}
            value={inputData.inputValue}
          />
        ))}
      </div>
    </div>
  );
}

EditSensorSettings.propTypes = {
  chartData: PropTypes.shape(Object).isRequired,
  onCancel: PropTypes.func,
  refillPoints: PropTypes.shape(Object).isRequired,
  depthIndex: PropTypes.number.isRequired,
  sensorId: PropTypes.number.isRequired,
  sensorType: PropTypes.string.isRequired,
  optionalId: PropTypes.string,
  chartOptions: PropTypes.shape(Object),
};

EditSensorSettings.defaultProps = {
  onCancel: () => { },
  optionalId: '',
  chartOptions: {},
};
