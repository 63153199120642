import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Typography, Button } from 'antd';
import { isMobile } from '../../../utils/detectDevice';
import { useTracking } from '../../../helper/mixpanel';
import './PageHeader.less';

export function PageHeader({
  title,
  dataTestId,
  extra,
  toPath,
  showBack,
  state,
  trackDetails,
  level,
  customClassName,
}) {
  const history = useHistory();
  const { location } = history;
  const { key, pathname } = location || {};
  const tracking = useTracking();

  const onBackButtonClick = () => {
    if (trackDetails) tracking.track(trackDetails?.message, trackDetails?.details);
    if (toPath && !state?.prevPath) {
      history.push({
        pathname: toPath,
        state,
      });
    } else if (key) {
      history.goBack();
    } else {
      const path = (pathname || '').split('/')[1] || '';
      history.replace(`/${path}`);
    }
  };

  function renderBackButton() {
    if (!showBack) return null;
    return (
      <Button
        className="back-btn"
        shape="circle"
        size="large"
        icon={<FaChevronLeft size={20} />}
        onClick={onBackButtonClick}
      />
    );
  }

  return (
    // Prevent header cutoff for titles exceeding 50 characters on mobile devices
    <div className={`${(isMobile && title?.length > 50) ? 'page-header-length' : 'page-header'}`}>
      <div className={`page-header-main ${showBack && 'back-header'}`}>
        {renderBackButton()}
        <Typography.Title
          level={level}
          datatest-id={dataTestId}
          className={customClassName || ''}
        >
          {title}
        </Typography.Title>
      </div>
      <div className="page-header-extra">
        {extra}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  toPath: PropTypes.string,
  dataTestId: PropTypes.string,
  extra: PropTypes.node,
  showBack: PropTypes.bool,
  // eslint-disable-next-line
  state: PropTypes.object,
  trackDetails: PropTypes.shape(),
  level: PropTypes.number,
  customClassName: PropTypes.string,
};

PageHeader.defaultProps = {
  dataTestId: 'header-title',
  extra: null,
  toPath: null,
  showBack: false,
  state: {},
  trackDetails: null,
  level: 1,
  customClassName: null,
};
