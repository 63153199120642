import React from 'react';
import { Table, Button, Space, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../mobile/components/PageHeader';
import { isMobile } from 'react-device-detect';
import { FarmxWebApiTypes } from 'types/api_types';
import '../../organization.css';

export interface TeamsListProps {
  loading: boolean;
  pagedResult: FarmxWebApiTypes.PaginatedResult<any>;
  tableSpec: FarmxWebApiTypes.ListQuery;
  onPageChange: (page: number, pageSize: number) => void;
  onTeamSelect: (team: any) => void;
  onCreateTeam: () => void;
  currentUserRoles: string[] | undefined;
}

/**
* TeamList is a functional presentational component used to display a table of Teams.
* @param {boolean} props.loading - The loading status of the component.
* @param {FarmxWebApiTypes.PaginatedResult<any>} props.pagedResult - The paged result of the teams query.
* @param {FarmxWebApiTypes.ListQuery} props.tableSpec - The current spec for fetching teams.
* @param {(page: number, pageSize: number) => void} props.onPageChange - The method to handle the page change.
* @param {(team: any) => void} props.onTeamSelect - The method to handle the team selection.
* @param {() => void} props.onCreateTeam - The method to handle the team creation.
* @param {string[] | undefined} props.currentUserRoles - The roles of the current user. 
* @returns {JSX.Element} The TeamsList component.
*/
const TeamsList: React.FC<TeamsListProps> = ({ 
  loading, 
  pagedResult, 
  tableSpec,
  onPageChange, 
  onTeamSelect,
  onCreateTeam,
  currentUserRoles
}) => {
  const { t } = useTranslation();

  // Define the columns for the teams table
  const teamsTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '# Members',
      dataIndex: 'members',
      key: 'members',
      render: (_: any, record: { team_accesses: string | any[]; }) => record?.team_accesses?.length
    },
    {
      title: 'Ranches',
      dataIndex: 'ranchesPreview',
      key: 'ranchesPreview',
      render: (_: any, record: { ranches: any[]; }) => {
        // Display the first 3 ranches in the team then show a tooltip with the full list followed by ellipsis if more than 3
        const ranchesPreview = record.ranches.slice(0, 3).map(ranch => ranch.name).join(', ');
        return (
          <Tooltip title={record.ranches.map(ranch => ranch.name).join(', ')}>
            {ranchesPreview}{record.ranches.length > 3 ? '...' : ''}
          </Tooltip>
        );
      }
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: { id: any; }) => (
        <Space size="middle">
          <Button onClick={() => onTeamSelect(record)}>
            {t('View')}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="white-bg padding-20 flex-column-container organization-table">
        <div className="flex-title-container">
          <div className="flex-title-content">
            <PageHeader showBack={!!isMobile} title={t('Teams')} toPath="/organization" />
          </div>
          <div className="flex-action-button-content">
            <Button 
              disabled={!currentUserRoles?.includes('Entity Admin')}
              type="primary" onClick={onCreateTeam}>
              {'+ Add Team'}
            </Button>
          </div>
        </div>
        <div className="scrollable-content">
          {loading ? ( <Skeleton active /> ) : (
            <Table
              sticky
              dataSource={pagedResult?.data}
              columns={teamsTableColumns as []}
              rowKey="id"
              pagination={{
                current: tableSpec?.page_number,
                pageSize: tableSpec?.page_size,
                total: pagedResult?.count,
                onChange: onPageChange,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
              }}
            />
          )}
        </div>
    </div>
  );
};

export default TeamsList;
