import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Moment from 'react-moment';
import {
  PageHeader,
  Divider,
  List,
  Tooltip,
  Spin,
  Button,
  Select,
  DatePicker,
  Input,
} from 'antd';
import { userApi } from 'farmx-api';
import InfiniteScroll from 'react-infinite-scroller';
import { FaSyncAlt, FaTimes, FaSlidersH } from 'react-icons/fa';
import { BlockSelect, SensorSelect, RanchBlockSelectMobile } from 'farmx-web-ui';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import { CancelTokenSource } from 'axios';

import ActiveTag from './ActiveTag';
import EventTypeTag from './EventTypeTag';
import EventCategoryTag from './EventCategoryTag';
import EventLogLevelIcon from './EventLogLevelIcon';
import LogLevelSelect from './LogLevelSelect';
import EventTypeSelect from './EventTypeSelect';
import EventCategorySelect from './EventCategorySelect';
import RanchBlockTag from './RanchBlockTag';
import EventLink from './EventLink';
import { useTracking } from '../../../helper/mixpanel';

import './EventsPage.css';
import { isMobile } from '../../../utils/detectDevice';
import { NotificationShareButton } from '../../mobile/components/NotificationShareButton';
import { CardLayout } from '../../mobile/components/CardLayout';
import { useUserTimeFormat } from '../../../helper/commonHooks';
import type {
  Sensor, Event, EventCategory, EventLogLevel, EventType
} from './types';

const {
  setRanchBlockSelection,
  setBlocks,
} = actions;

const {
  selectLoginUserInfo,
  selectSensor,
} = selectors;

const { useRanchBlockSelection } = hooks;

const safeAreaTop = Number(getComputedStyle(document.documentElement)
  .getPropertyValue('--sat').split('px')[0]);
const safeAreaBottom = Number(getComputedStyle(document.documentElement)
  .getPropertyValue('--sab').split('px')[0]);
const isApp = matchMedia('(display-mode: standalone)').matches;
const padding = 10;

const { Search } = Input;
export function EventsPage() {
  const location = useLocation();
  const history = useHistory();
  const { search, pathname } = location;
  const tracking = useTracking();
  const dispatch = useDispatch();
  const sheetRef = useRef(null);
  const { selectedObjFromState } = useRanchBlockSelection();
  let blockId: string | null = null;
  let ranchId: string | null = null;
  let entityId: string | null = null;
  if (selectedObjFromState.type === 'ranch') ranchId = selectedObjFromState.value;
  if (selectedObjFromState.type === 'block') blockId = selectedObjFromState.value;
  if (selectedObjFromState.type === 'entity') entityId = selectedObjFromState.value;

  const { t } = useTranslation();

  // loading is used for any data loading
  const [loading, setLoading] = useState(false);
  // refreshing is used for reloading list from start
  const [refreshing, setRefreshing] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [totalEvents, setTotalEvents] = useState<number | null>(null);
  const [nextPage, setNextPage] = useState<string | null>(null);

  // filters
  const [block, setBlock] = useState<number[] | null>(null);
  const [logLevel, setLogLevel] = useState<EventLogLevel>(null);
  const [sensor, setSensor] = useState<Sensor | null>(null);
  const sensorIdentifier = sensor?.identifier;
  const sensorType = sensor?.type;
  const [active, setActive] = useState<boolean | null>(null);
  const [eventCategory, setEventCategory] = useState<EventCategory>(null);
  const [eventType, setEventType] = useState<EventType>(null);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);
  const [dateStart, dateEnd] = dateRange ?? [];
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);

  // Bottom sheet
  const sensorObj = useSelector((state) => selectSensor(state, sensor?.type, sensor?.identifier));
  // TODO: Resolve it once farmx-redux-core is migrated to TS
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo?.admin;
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(true);
  const [searchStr, setSearchStr] = useState('');

  const hasMore = nextPage !== null;
  // Changing time format based on the user settings
  const userTimeFormat = useUserTimeFormat();

  // Check if any sensor is selected
  const CHECK_CONDITION_1 = !sensorType && !sensorIdentifier;
  // Check if any block is selected
  const CHECK_CONDITION_2 = !blockId && !block;

  useEffect(() => {
    if (tracking) {
      tracking.track('Loaded Events Page');
    }
  }, [tracking]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const defaultActive = urlParams.get('active') ? urlParams.get('active') === 'true' : null;
    const defaultBlock = urlParams.get('blockId');
    const defaultBlocks = defaultBlock ? [Number(defaultBlock)] : null;
    const defaultLogLevel = urlParams.get('logLevel');
    const defaultSensorIdentifier = urlParams.get('sensorIdentifier');
    const defaultSensorType = urlParams.get('sensorType');
    const defaultSensor = defaultSensorIdentifier && defaultSensorType && {
      identifier: defaultSensorIdentifier,
      type: defaultSensorType,
    };
    if (defaultActive) {
      setActive(defaultActive);
    }
    if (defaultLogLevel) {
      setLogLevel(defaultLogLevel);
    }
    if (defaultBlocks) {
      setBlock(defaultBlocks);
    }
    if (defaultSensor) {
      setSensor(defaultSensor);
    }
    history.replace(pathname);
  }, [search, history, pathname]);

  const refresh = useCallback(() => {
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);

    setRefreshing(true);
    setLoading(true);
    const params = {
      blockId: CHECK_CONDITION_1 ? block || blockId : null, // if sensor is selected pass null
      sensorType,
      sensorIdentifier,
      logLevel,
      active,
      eventCategory,
      eventType,
      dateStart,
      dateEnd,
      cancelToken: source.token,
      ranchId: CHECK_CONDITION_1 && CHECK_CONDITION_2 ? ranchId : null, // if sensor or block is selected pass null
      entityId,
      q: searchStr,
    };
    userApi.loadUserEvents(params)
      .then((response: any) => {
        const { data } = response;
        const { results, count, next } = data;
        setEvents(results);
        setRefreshing(false);
        setLoading(false);
        setTotalEvents(count);
        if (next) {
          setNextPage(next);
        } else {
          setNextPage(null);
        }
      });
  }, [cancelToken, block, blockId, sensorType, sensorIdentifier, logLevel, active, eventCategory, eventType, dateStart, dateEnd, ranchId, searchStr, entityId]);

  const handleInfiniteOnLoad = useCallback(() => {
    if (!nextPage) return;
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);
    setLoading(true);
    const searchParams = new URLSearchParams(nextPage.split('?')[1]);
    const cursor = searchParams.get('cursor');
    userApi.loadUserEvents({
      cursor,
      blockId: CHECK_CONDITION_1 ? block || blockId : null, // if sensor is selected pass null
      sensorType,
      sensorIdentifier,
      logLevel,
      active,
      eventCategory,
      eventType,
      dateStart,
      dateEnd,
      cancelToken: source.token,
      ranchId: CHECK_CONDITION_1 && CHECK_CONDITION_2 ? ranchId : null, // if sensor or block is selected pass null
      entityId,
      q: searchStr,
    }).then((response: any) => {
      const { data } = response;
      const { results, count, next } = data;
      setEvents(events.concat(results));
      setLoading(false);
      setTotalEvents(count);
      if (next) {
        setNextPage(next);
      } else {
        setNextPage(null);
      }
    });
  }, [nextPage, cancelToken, block, blockId, sensorType, sensorIdentifier, logLevel, active, eventCategory, eventType, dateStart, dateEnd, ranchId, searchStr, events, entityId]);

  // NOTE: this should handle initial load
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, sensorType, sensorIdentifier, logLevel, active,
    eventCategory, eventType, dateStart, dateEnd, searchStr, blockId, ranchId, entityId]);

  // set block to null whenever entity is changed
  useEffect(() => {
    if(selectedObjFromState.type === 'entity'){
      setBlock(null);
    }
  }, [selectedObjFromState.value, selectedObjFromState.type]);

  function renderTotalEvents() {
    if (totalEvents !== null) {
      return `Loaded ${events.length} of ${totalEvents}`;
    }
    return null;
  }

  function getTitle() {
    return (
      <>
        {t('Events')}
        <Button key="refresh" type="link" onClick={refresh} disabled={loading}><FaSyncAlt className={loading ? 'fa-spin' : ''} /></Button>
      </>
    );
  }

  function renderHeaderExtra() {
    return isMobile ? (
      <>
        <span>{renderTotalEvents()}</span>
        <Button
          key="filter"
          type="default"
          icon={<FaSlidersH className="event-filter-icon" />}
          onClick={() => setIsBottomSheetVisible(true)}
        >
          {t('Filter')}
        </Button>
      </>
    )
      : renderTotalEvents();
  }

  function renderFooter() {
    return null;
  }

  function renderAvatar(event:Event) {
    return <EventLogLevelIcon value={event.logLevel} />;
  }

  function renderDate(event:Event) {
    return (
      <Tooltip title={<Moment format="YYYY-MM-DD h:mm:ss A" local>{event.createdAt}</Moment>} placement="bottom">
        <span>
          <Moment fromNow>{event.createdAt}</Moment>
          {' - '}
          <Moment format="YYYY-MM-DD h:mma">{event.createdAt}</Moment>
        </span>
      </Tooltip>
    );
  }

  function renderFilterDetails() {
    return (
      <div className="events-filter-details">
        {eventCategory && (
          <div>
            <span className="list-header">{`${t('Category')}: `}</span>
            <span className=".list-header-value">{eventCategory}</span>
          </div>
        )}
        {logLevel && (
          <div>
            <span className="list-header">{`${t('Severity')}: `}</span>
            <span className=".list-header-value">{logLevel}</span>
          </div>
        )}
        {active && (
          <div>
            <span className="list-header">{`${t('Active')}: `}</span>
            <span className=".list-header-value">{active}</span>
          </div>
        )}
        {sensorObj && (
          <div>
            <span className="list-header">{`${t('Devices')}: `}</span>
            <span className=".list-header-value">{sensorObj?.name}</span>
          </div>
        )}
        {searchStr && (
          <div>
            <span className="list-header">{`${t('Search')}: `}</span>
            <span className=".list-header-value">{searchStr}</span>
          </div>
        )}
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="event-list-filters" id="event-list-section">
        {!isMobile && (
          <BlockSelect
            className="block-select"
            placeholder={t('Block')}
            onChange={(b: React.SetStateAction<number[] | null>) => setBlock(b)}
            value={block}
          />
        )}
        <SensorSelect
          sensorType={sensorType}
          sensorIdentifier={sensorIdentifier}
          onChange={setSensor}
          placeholder={t(isMobile ? 'Devices' : 'Sensors')}
          applyFilter
        />
        <LogLevelSelect
          value={logLevel}
          placeholder={t('Severity')}
          onChange={(value) => setLogLevel(value)}
        />
        <EventCategorySelect
          value={eventCategory}
          placeholder={t('Category')}
          onChange={(value: EventCategory) => setEventCategory(value)}
        />
        <EventTypeSelect
          value={eventType}
          placeholder={t('Type')}
          onChange={(value: EventType) => setEventType(value)}
        />
        <Select
          placeholder={t('Active')}
          options={[{ value: true, label: t('Active') }, { value: false, label: t('Inactive') }]}
          value={active}
          allowClear
          onChange={setActive}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        <Search
          placeholder={t('Search')}
          allowClear
          onSearch={(d) => setSearchStr(d)}
          className="event-list-item-full-width"
        />
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format={userTimeFormat?.unitsTime ? 'YYYY-MM-DD hh:mm a' : 'YYYY-MM-DD HH:mm'}
          onChange={(d) => setDateRange(d)}
          value={dateRange}
          onOk={null}
          className="event-list-item-full-width"
          dropdownClassName="event-page-datepicker"
          // style={{ width: '100%' }}
          getPopupContainer={isMobile ? null : (trigger: HTMLElement) => trigger.parentNode}
          use12Hours={userTimeFormat?.unitsTime}
        />
      </div>
    );
  }

  function renderExtra(event: Event) {
    return (
      <div className="flex-column" style={{ gap: '1rem' }}>
        <div className="flex-row">
          <EventCategoryTag value={event.eventCategory} />
          <EventTypeTag value={event.eventType} />
          <ActiveTag value={event.active} />
          {isMobile && <NotificationShareButton showTitle notification={event} />}
        </div>
        {event.eventType === 'anomaly'
          && (
          <div className="flex-row event-link-container">
            <EventLink event={event} type="view-in-app" />
            { isAdmin && <EventLink event={event} type="view-graph" />}
          </div>
          )}
      </div>
    );
  }

  const onListItemClick = (event: Event) => {
    if (event?.eventCategory === 'recommendation') {
      dispatch(setRanchBlockSelection({
        type: 'block',
        value: event?.block,
      }));
      if (event?.type === 'block' && !isMobile) dispatch(setBlocks([Number(event?.block)]));
      history.push('/recommendation');
    }
  };

  function renderEvent(event:Event) {
    return (
      <CardLayout>
        <List.Item
          className="event-item"
          key={event.id}
          actions={undefined}
          extra={renderExtra(event)}
          onClick={() => onListItemClick(event)}
        >
          <List.Item.Meta
            avatar={renderAvatar(event)}
            title={(
              <div className="event-description">
                <span>{event.description}</span>
                <RanchBlockTag
                  ranchName={event?.ranchDetails?.ranchName}
                  blockName={event?.blockDetails?.blockName}
                />
              </div>
          )}
            description={renderDate(event)}
          />
        </List.Item>
      </CardLayout>

    );
  }

  // handle scroll for loading next chunk of data
  const handleScroll = (event: any) => {
    const container = event.target;
    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      handleInfiniteOnLoad();
    }
  };

  const eventDiv = document.getElementById('event-list-section');
  const eventDivHeight = eventDiv ? eventDiv?.offsetHeight : 0;
  const footerDivElement: HTMLElement | null = document.querySelector('.bottom-tabbar-container');
  const footerHeight = footerDivElement ? footerDivElement?.offsetHeight : 0;

  return (
    <div style={{ width: '100%', overflowY: 'auto' }} onScroll={handleScroll}>
      <div className="single-column-page padded desktop-container">
        <Helmet>
          <title>{t('Events')}</title>
        </Helmet>

        {isMobile && (
        <BottomSheet
          ref={sheetRef}
          open={isBottomSheetVisible}
          blocking={false}
          onDismiss={() => {
            setIsBottomSheetVisible(false);
          }}
          defaultSnap={({ snapPoints }) => snapPoints[-1]}
          snapPoints={({ headerHeight }) => {
            let previewSize = (headerHeight + eventDivHeight + footerHeight + padding);
            if (isApp) {
              previewSize = ((headerHeight * 2) + eventDivHeight + footerHeight
                + safeAreaBottom + safeAreaTop) - padding;
            }
            return previewSize;
          }}
          header={(
            <div className="bottom-sheet-header-container">
              <div className="filters-header">
                {t('Filter Events')}
              </div>
              <div className="bottom-sheet-header">
                <Button
                  shape="circle"
                  icon={<FaTimes />}
                  onClick={() => setIsBottomSheetVisible(false)}
                />
              </div>
            </div>
          )}
          scrollLocking={false}
        >
          {renderFilters()}
        </BottomSheet>
        )}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <PageHeader
            className="event-header"
            title={getTitle()}
            extra={renderHeaderExtra()}
          />
          {isMobile && (
          <RanchBlockSelectMobile
            getSelectedRanchBlock={(d: any) => {
              dispatch(setRanchBlockSelection(d));
            }}
            selected={selectedObjFromState}
            admin={isAdmin}
          />
          )}
          {isMobile && renderFilterDetails()}
          {!isMobile && renderFilters()}
          <Divider />
          <div className="event-list-container">
            <div className="event-list">

              <List
                itemLayout="vertical"
                size="large"
                dataSource={events}
                loading={refreshing}
                footer={renderFooter()}
                renderItem={(event) => renderEvent(event)}
              >
                {loading && hasMore && (
                <div className="loading-container">
                  <Spin />
                </div>
                )}
              </List>
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
