import {
  useState, useCallback, useEffect, useMemo
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { sensorApi } from 'farmx-api';
import {
  buildGraphConfig, chartRuleEngine, getDailyAverage, getMultiplierAndData,
  preparePlotLines, cutoffLineLabelStyle,
} from '../../helper/graphHelper';
import {
  tooltipBgColor,
  tooltipBorderColor,
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
} from '../../utils/colors';
import { roundToDecimalPlaces } from '../../helper/anomalyHelper';

const { loadSensorData } = sensorApi;
const { useUnits } = hooks;

const noonConfig = {
  hour: 12,
  minute: 0,
  second: 0,
  millisecond: 0
};

const useSensorGraphData = (
  sensorType,
  sensorId,
  sensorIdentifier,
  variables,
  startDate,
  endDate,
  cached,
  refresh,
  preparedConfig,
  renderOption
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sensorData, setSensorData] = useState({});
  const { isGraphDataApiCallNeeded } = chartRuleEngine(renderOption, preparedConfig);

  // load sensor graph data
  const getSensorData = useCallback((startDate, endDate) => {
    if(isGraphDataApiCallNeeded && variables?.length){
      // TODO remove after testing
      // console.log('Graph data api calling...', isGraphDataApiCallNeeded, sensorType, sensorId, variables, startDate, endDate);
      setLoading(true);
      loadSensorData(sensorType, sensorId, variables, startDate, endDate)
        .then((response) => {
          // TODO remove after testing
          // console.log('setting sensorData');
          if (response && response.status === 200) {
            const { data } = response;
            setSensorData(data);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(`Graph Data API call Error for sensor id ${sensorId}`, e);
          setLoading(false);
          setError({
            code: -1,
            message: 'Error while retrieving data from server !',
            object: e,
          });
        });
    }
  }, [isGraphDataApiCallNeeded, sensorId, sensorType, variables]);

  useEffect(() => {
    // call graph data api only when there is no preparedConfig
    if(!preparedConfig){
      // TODO remove after testing
      // console.log('Calling getSensorData');
      getSensorData(startDate, endDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, startDate, endDate, preparedConfig, getSensorData]);

  // TODO remove after testing: all useEffect and console log usages below
  // useEffect(() => {
  //   console.log('startDate changed', startDate);
  // }, [startDate]);

  // useEffect(() => {
  //   console.log('sensorId changed', sensorId);
  // }, [sensorId]);

  // useEffect(() => {
  //   console.log('sensorType changed', sensorType);
  // }, [sensorType]);

  // useEffect(() => {
  //   console.log('variables changed', variables);
  // }, [variables]);

  // useEffect(() => {
  //   console.log('endDate changed', endDate);
  // }, [endDate]);

  // useEffect(() => {
  //   console.log('refresh changed', refresh);
  // }, [refresh]);

  // console.log('sensorData in hook', sensorData);

  return { loading, data: sensorData, error };
};

const xAxisLabels = {
  y: 10,
  x: 1,
  align: 'left',
};

const usePrepareGraphConfig = (
  customConfig,
  graphData,
  startDate,
  endDate,
  uniformYAxis,
  yMinMax,
  chartRef,
  preparedConfig,
  renderOption
) => {
  // TODO remove after testing
  // console.log('calling usePrepareGraphConfig', preparedConfig, graphData);
  const [config, setConfig] = useState();
  let type;
  let identifier;
  const getUserUnits = hooks.useUnits();
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  );

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('sensorStatus changed', sensorStatus);
  // }, [sensorStatus]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('customConfig changed', customConfig);
  // }, [customConfig]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('graphData changed', graphData);
  // }, [graphData]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('usePrepareGraphConfig startDate changed', startDate);
  // }, [startDate]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('preparedConfig changed', preparedConfig);
  // }, [preparedConfig]);

  // TODO remove if not useful
  // const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);

  useEffect(() => {
    // prepare config based on rule engine response
    const { prepareTheConfig } = chartRuleEngine(renderOption, preparedConfig);
    // TODO remove after testing
    // console.log('prepareTheConfig', prepareTheConfig);
    if(prepareTheConfig){
      // TODO remove after testing
      // console.log('Creating config...', graphData);
      let graphConfig = Object.keys(graphData).map((chartType) => {
        const chartDataList = graphData[chartType];
        const chartData = chartDataList[0] || {};

        const { sensor_type: sensorType, sensor_id: sensorIdentifier } = chartData;
        type = sensorType;
        identifier = sensorIdentifier;

        const config = buildGraphConfig(graphData, getUserUnits);

        const defaultConfig = {
          tooltip: {
            backgroundColor: tooltipBgColor,
            borderColor: tooltipBorderColor,
            style: {
              color: 'white',
            },
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            type: 'datetime',
            ordinal: false,
            gridLineWidth: 1,
            labels: xAxisLabels,
            alternateGridColor: 'rgba(200,200,200,0.1)',
            min: startDate?.valueOf(),
            max: endDate?.valueOf(),
            tickInterval: null,
            dateTimeLabelFormats: {},
          },
          plotOptions: {},
        };
        // TODO remove after testing
        // console.log('different values', defaultConfig, config);
        return { ...defaultConfig, ...config };
      }).reduce((acc, obj) => {
        const config = { ...acc, ...obj };
        return config;
      }, {});
      // TODO remove after testing
      // console.log('graphConfig', graphConfig);
      // console.log('chartRef', chartRef);

      if(Object.keys(graphConfig).length){
        if (customConfig) {
          graphConfig = customConfig(graphConfig, graphData, chartRef);
        }
        // TODO remove after testing
        // console.log('setting config for hidden chart', graphConfig);
        setConfig(graphConfig);
      }
    }else{
      // TODO need to fine tune this
      // console.log('calling customConfig with preparedConfig...', preparedConfig, chartRef);
      const graphConfig = customConfig(preparedConfig, preparedConfig?.graphData, chartRef);
      // console.log('setting config for main chart');
      setConfig(graphConfig);
    }
  }, [renderOption, preparedConfig, graphData, startDate, endDate, chartRef, customConfig]);
  // TODO remove after testing
  // console.log('Graph hook config', config);
  return config;
};

const usePreparedConfigForIndividualDepth = (
  preparedConfig,
  graphData
) => {
  // TODO remove after testing
  // console.log('usePreparedConfigForIndividualDepth preparedConfig', preparedConfig);
  const updatedConfig = useMemo(() => (
    (preparedConfig && preparedConfig.series)
      ?preparedConfig.series.map(
        (seriesData) => ({
          ...preparedConfig,
          series: [{...seriesData, point: {}}],
          graphData: graphData[seriesData.actualChartKey][0]
        })
      ):[]),
  [graphData, preparedConfig]);
  // TODO remove after testing
  // console.log('updatedConfig for each depth', updatedConfig);
  return updatedConfig;
};

/**
 * Funtion to calculate the VWC forecast value for each dates
 * @param {*} vwcData - Historical VWC data for the soil sensor
 * @param {*} forecastData - Forecaseted dalily data
 *                          "ETc, precipRainfall, scheduledIrrigation, date"
 * @param {*} depthRootzone - current rootzone depth for the sensor from sensor status
 * @returns vwcForecast value for each input dates
 */
function prepareForecastData(vwcData, forecastData, depthRootzone) {
  const dailYAvgData = getDailyAverage(vwcData);
  let previousVWC = dailYAvgData?.[dailYAvgData?.length - 1]?.[1];
  const {
    dateDaily, etcForecastDaily, precipForecastDaily,
    scheduledIrrigationDailyMm
  } = forecastData || {};

  // Convert depthRootzone value from "inches" to "mm"
  // 1 inch equals 25.4 mm
  const rootzoneDepthInMM = (depthRootzone || 0) * 25.4;

  // To filter the previous dates from forecast data
  const currentDate = moment();
  const currNoonDate = currentDate.set(noonConfig);
  const currDate = currNoonDate.valueOf();

  // Calculate the vwcForecast value for the forecastDaily dates
  const updatedForecastData = dateDaily?.map((fDate, i) => {
    const originalDate = moment(fDate);
    // This will display the date centered at noon.
    const noonDate = originalDate.set(noonConfig);
    const forecastDate = noonDate.valueOf();

    if (moment(forecastDate).isSameOrAfter(currDate)) {
      // Convert etcForecast value from "mm" to "percentage" based on the depthRootzone value
      const etc = etcForecastDaily?.[i] ? (etcForecastDaily?.[i] / rootzoneDepthInMM) : 0;

      // Convert precipForecast value from "mm" to "percentage" based on the depthRootzone value
      const precip = precipForecastDaily?.[i] ? (precipForecastDaily?.[i] / rootzoneDepthInMM) : 0;

      // Convert scheduledIrrigationForecast value from "mm" to "percentage"
      const scheduled = scheduledIrrigationDailyMm?.[i]
        ? (scheduledIrrigationDailyMm?.[i] / rootzoneDepthInMM) : 0;

      // Calculate vwcForecast based on previousVWC, etc, precip, and scheduledIrrigation data
      const vwcForecast = previousVWC - etc + precip + scheduled;

      // Update currentVWC for the next iteration
      previousVWC = vwcForecast;

      return [forecastDate, isFinite(vwcForecast) ? vwcForecast : 0];
    }
    return [];
  })?.filter((item) => item?.[0] || item?.[1]);

  return updatedForecastData;
}

// Funtion will prepare and return the horizontal plot lines with custom styles
// for the upper, middle and lower moister zones
function getLinesConfig(moisture_zone_upper, moisture_zone_middle,
  moisture_zone_lower, multiplier) {
  const linesConfig = [{
    color: colorOver,
    value: moisture_zone_upper * multiplier,
    type: 'upper',
    from: moisture_zone_upper * multiplier,
    to: (moisture_zone_upper * multiplier || 0) + 1 * multiplier,
    className: 'upper-band-line',
    label: {
      x: -110,
      y: 12,
      align: 'right',
      text: `FC  (${Number(moisture_zone_upper * multiplier).toFixed(1)})`,
      style: cutoffLineLabelStyle
    }
  },
  {
    color: colorWarning,
    value: moisture_zone_middle * multiplier,
    type: 'middle',
    label: {
      x: -55,
      align: 'right',
      text: `RP (${Number(moisture_zone_middle * multiplier).toFixed(1)})`,
      style: cutoffLineLabelStyle
    }
  },
  {
    color: colorCritical,
    value: moisture_zone_lower * multiplier,
    type: 'lower',
    from: 0,
    to: moisture_zone_lower * multiplier,
    className: 'lower-band-line',
    label: {
      x: 0,
      y: 12,
      align: 'right',
      text: `WP (${Number(moisture_zone_lower * multiplier).toFixed(1)})`,
      style: cutoffLineLabelStyle
    }
  }];

  return linesConfig;
}

const usePrepareVWCForecastGraphConfig = (forecastData, depthRootzone, series2Name) => {
  const customConfig = useCallback((configObj, seriesData) => {
    let plotLines = [];
    let plotBands = [];
    const rootZoneVwcData = seriesData?.soil_moisture_rootzone_vwc;
    const data = rootZoneVwcData?.[0]?.data;

    // Calculate the vwcForecast value for the forecastDaily dates
    const updatedForecastData = prepareForecastData(data, forecastData, depthRootzone);

    // Convert the VWC forcast data values to percentage
    const chartDataWithForecast = updatedForecastData
      ?.map(([cDate, cValue]) => ([cDate, roundToDecimalPlaces(cValue * 100)]));

    // Add the first forecasted date and value to the last recorded VWC data
    const newChartConfig = { ...configObj?.series?.[configObj?.series?.length - 1] };
    if (chartDataWithForecast?.length) {
      newChartConfig.data = [...newChartConfig?.data || [], ...[chartDataWithForecast?.[0]]];
    }
    const dataValues = newChartConfig?.data?.map((d) => d?.[1] || 0) || [0];
    const seriesValues = chartDataWithForecast?.map((d) => d?.[1] || 0) || [0];

    const seriesConfig = {
      data: chartDataWithForecast,
      name: series2Name,
      marker: {
        enabled: true
      },
      dashStyle: 'Dash', // Set the dashed style for the second series
      linkedTo: ':previous' // This will link it to the previous series
    };

    if (configObj && rootZoneVwcData) {
      const updatedConfig = Object.entries(seriesData).map(([key, value]) => {
        if (!value.length) return undefined;
        const chartData = value[0];
        if (!chartData.data.length) return undefined;

        const { data: variableData } = chartData;
        const { multiplier } = getMultiplierAndData(variableData, useUnits, chartData.units);

        const {
          moisture_zone_upper: moistureZoneUpper,
          moisture_zone_middle: moistureZoneMiddle,
          moisture_zone_lower: moistureZoneLower,
        } = chartData || {};

        const linesConfig = getLinesConfig(moistureZoneUpper, moistureZoneMiddle,
          moistureZoneLower, multiplier);

        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;

        // To update yAxis range when data points has 0
        const plotBandValues = plotLinesConfig?.plotLines?.map((d) => d?.value || 0);
        const yAxisMax = Math.max(...dataValues, ...plotBandValues, ...seriesValues);
        const yAxisMin = Math.min(...dataValues, ...plotBandValues, ...seriesValues);

        const yAxis = configObj.yAxis.map((d) => {
          const obj = { ...d };
          obj.plotLines = plotLines;
          obj.plotBands = plotBands;
          if (isFinite(yAxisMin)) obj.min = yAxisMin;
          if (isFinite(yAxisMax)) obj.max = yAxisMax;
          return obj;
        });

        // Change the xAxis max date range to show VWC forecast data
        const xAxisMax = chartDataWithForecast?.[chartDataWithForecast?.length - 1]?.[0];
        let xAxis = { ...configObj.xAxis };
        if (xAxisMax) xAxis = { ...configObj.xAxis, max: xAxisMax};

        return {
          ...configObj,
          series: [
            { // The VWC data
              ...newChartConfig,
            },
            { // The VWC Forecast data
              data: chartDataWithForecast,
              ...seriesConfig,
            }],
          yAxis,
          xAxis,
          plotOptions: {
            series: {
              color: colorOk,
              states: {
                hover: {
                  enabled: true // Enable hover states for both series
                }
              }
            }
          }
        };
      }).reduce((acc, obj) => {
        const config = { ...acc, ...obj };
        return config;
      }, {});

      return updatedConfig;
    }
    return configObj;
  }, [depthRootzone, forecastData, series2Name]);

  return customConfig;
};

export {
  useSensorGraphData, usePrepareGraphConfig, usePreparedConfigForIndividualDepth,
  usePrepareVWCForecastGraphConfig,
};
