import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// import { SoilSensorSummary } from '../list/ListCards/SoilSensorSummary';
import { SensorAlarmButton } from 'farmx-web-ui';
import { ValueUnitInfo } from '../list/ValueUnitInfo';
import ConnectivityStateTag from '../components/ConnectivityStateTag';
import { PlantSensorListItem } from '../list/PlantSensorListItem';
import { ControlValveDetail } from '../control/ControlValves/ControlValveDetail';
import { ControlPumpDetails } from '../control/ControlPump/ControlPumpDetails';
import { getStress } from '../../../helper/plantSensorHelper';
import { SoilMoistureDepthChart } from '../components/SoilMoistureDepthChart';
import './SensorDetailBody.less';
import { isNotNull } from '../../../helper/listHelper';
import { getPercentForFraction } from '../../../helper/common';
import { SensorDetailHeader } from './SensorDetailHeader';
import SensorDataChart from '../../graph/SensorDataChart';
import { ValveSensorChart } from '../list/ValveSensorChart';
import LastReportedDate from '../list/LastReportedDate';

// Placeholder for specific view for sensor
export function SensorDetailBody({ sensorProperties, renderDefault, showGraph }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { identifier, type, idDb } = sensorProperties;
  const status = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  ) || {};
  const getUserUnits = hooks.useUnits();

  const rootZoneVWCChart = useMemo(() => (
    <SensorDataChart
      sensor={{
        type,
        identifier,
        id: idDb,
      }}
      variables={['soil_moisture_rootzone_vwc']}
      startDate={moment().subtract(14, 'days')}
      endDate={moment()}
    />
  ), [idDb, identifier, type]);

  useEffect(() => {
    dispatch(actions.loadSensorStatus({ type, identifier }));
  }, [dispatch, type, identifier]);

  function renderSensorDetailHeader(showIcon, showType) {
    return (
      <div className="sensor-detail-row soil-moisture-chart-container">
        <SensorDetailHeader
          sensorProperties={sensorProperties}
          showAlarmIcon={showIcon}
          showSensorType={showType}
        />
      </div>
    );
  }

  function renderAlarmButton() {
    return (
      <div className="sensor-alarm-status-block">
        <SensorAlarmButton
          isMobileView
          alarmCount={sensorProperties?.alarmCount}
          identifier={identifier}
          type={type}
        />
      </div>
    );
  }

  if (type === 'aquacheck_soil' || type === 'pixl_soil') {
    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          {status && status.vwcRootzone && (
            <ValueUnitInfo
              type={status.online ? 'primary' : 'disabled'}
              value={getPercentForFraction(status.vwcRootzone)}
              unit="% VWC"
              info={t('root zone avg')}
            />
          )}
          <ConnectivityStateTag
            isOnline={status && status.online}
          />
          {!renderDefault && renderAlarmButton()}
        </div>
        {!status.online && <LastReportedDate latestDate={status?.latestDate} />}
        {!renderDefault && renderSensorDetailHeader(false, false)}
        <div className="sensor-detail-row soil-moisture-chart-container">
          <SoilMoistureDepthChart sensor={sensorProperties} />
        </div>
        <hr />
        <h3>Rootzone Average VWC</h3>
        <div className="soil-time-graph">
          {identifier
            ?rootZoneVWCChart
            :''}
        </div>
      </div>
    );
  }
  if (type === 'water_pressure') {
    let units = status?.units || { waterPressure: 'pounds_per_square_inch' };
    if (units.waterPressure === 'psi') {
      units = { ...units, waterPressure: 'pounds_per_square_inch' };
    }
    const convertedUnits = getUserUnits(status.waterPressure,
      units.waterPressure, 'pressure', { decimalPlaces: 4 });
    const date = status?.online ? status?.lastUpdated : status?.latestDate;
    return (
      <div className="flex-column map-sensor-detail">
        <div className="pump-status-container" id="map-drawer-details">
          <ValueUnitInfo
            type={status.online ? 'primary' : 'disabled'}
            value={convertedUnits.value}
            unit={convertedUnits.label}
          />
          <ConnectivityStateTag
            isOnline={status && status.online}
          />
          {!renderDefault && renderAlarmButton()}
        </div>
        <div>
          {status?.online ? (
            <Typography.Text type="secondary">
              {`${t('Updated')} `}
              <Moment fromNow>{date}</Moment>
            </Typography.Text>
          ) : <LastReportedDate latestDate={date} />}
        </div>
        {!renderDefault && renderSensorDetailHeader(false, false)}
        {showGraph ? (
          <>
            <hr />
            <div className="soil-time-graph">
              <SensorDataChart
                sensor={{
                  type,
                  identifier,
                  id: idDb,
                }}
                variables={['water_pressure']}
                startDate={moment().subtract(14, 'days')}
                endDate={moment()}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  }
  if (type === 'farmx_dendrometer') {
    return (
      <>
        {!renderDefault && renderSensorDetailHeader(true, false)}
        <PlantSensorListItem
          sensorDetails={sensorProperties}
          sensorStatus={status}
        />
      </>
    );
  }
  if (type === 'valve') {
    return (
      <>
        <ControlValveDetail
          valveId={sensorProperties.blockId || sensorProperties.block}
          valveIdentifier={sensorProperties.identifier}
        />
        <div className="margin-top-20">
          <ValveSensorChart
            type={type}
            sensorId={idDb}
          />
        </div>
      </>
    );
  }
  if (type === 'vfd') {
    return (
      <ControlPumpDetails
        pumpId={sensorProperties.blockId}
      />
    );
  }
  if (type === 'farmx_infrared') {
    const stressValue = getStress(status.cropStress);
    const cwsiVal = status && status.cwsi;
    const online = status && status.online;
    const valueUnitInfoType = online ? 'primary' : 'disabled';
    const date = status?.online ? status?.lastUpdated : status?.latestDate;

    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          <ValueUnitInfo
            value={t(stressValue.label)}
            type={stressValue.type}
          />
          <div className="flex-column">
            <ValueUnitInfo
              value={cwsiVal || '-/-'}
              unit={t('cwsi').toUpperCase()}
              info={status && status.cwsiDate && moment(status.cwsiDate).fromNow()}
              type={valueUnitInfoType}
            />
            <ConnectivityStateTag
              isOnline={online}
            />
          </div>
        </div>
      </div>
    );
  }
  if (type === 'water_flow' || type === 'water_flow_analog') {
    const flowRate = isNotNull(status.flowRate) ? status.flowRate.toFixed(1) : null;
    const units = status.units || {
      flowRate: 'gallons_per_minute',
    };
    const convertedFlowRate = getUserUnits(flowRate || '', units.flowRate, 'flow_rate');

    return (
      <div className="flex-column map-sensor-detail">
        <div className="sensor-detail-row">
          <ValueUnitInfo
            value={convertedFlowRate.value != null ? convertedFlowRate.value : '?'}
            unit={t(convertedFlowRate.label)}
          />
          <ConnectivityStateTag
            isOnline={status.online}
          />
          {!renderDefault && renderAlarmButton()}
        </div>
        {!status.online && <LastReportedDate latestDate={status?.latestDate} />}
        {!renderDefault && renderSensorDetailHeader(false, false)}
      </div>
    );
  }
  return !renderDefault ? (
    <div className="flex-column map-sensor-detail">
      {renderSensorDetailHeader(true, false)}
    </div>
  ) : null;
}

SensorDetailBody.propTypes = {
  sensorProperties: PropTypes.shape({
    identifier: PropTypes.string,
    type: PropTypes.string,
    alarmCount: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    blockId: PropTypes.number.isRequired,
    block: PropTypes.number.isRequired,
    idDb: PropTypes.string,
  }),
  renderDefault: PropTypes.bool,
  showGraph: PropTypes.bool,
};

SensorDetailBody.defaultProps = {
  sensorProperties: {},
  renderDefault: true,
  showGraph: false,
};
