import { coordinationApi } from 'farmx-api';

const {
  fetchMainThresholds,
  createMainThresholds,
  updateMainThresholds,
  fetchAutonmousConfigs,
  createAutonmousConfigs,
  updateAutonmousConfigs,
  deleteAutonmousConfigs
} = coordinationApi;

function extractErrorMessage(errorResponse: any): string {
  try {
    // Handle the case where the response is a string
    if (typeof errorResponse === 'string') {
    // Extract the message if it matches the ErrorDetail pattern
      const match = errorResponse.match(/string='(.*?)'/);
      return match ? match?.[1] : errorResponse;
    }

    // Handle the case where the response is an object
    if (errorResponse && typeof errorResponse === 'object') {
      const errorMessages: string[] = Object.keys(errorResponse)?.map((key: any) => {
        const value = errorResponse?.[key];
        if (Array.isArray(value) && value?.length > 0) {
        // Return the first message from an array if present
          return value?.[0];
        }
        if (typeof value === 'string') {
        // Check for ErrorDetail pattern in strings
          const match = value.match(/string='(.*?)'/);
          if (match) {
            return match?.[1];
          }
          return value;
        }
        if (typeof value === 'object' && value !== null) {
        // Recursively search nested objects
          return extractErrorMessage(value);
        }
        return null;
      });

      // Return the first valid error message found
      const validMessage = errorMessages?.find((msg) => !!msg);
      return validMessage || 'An unknown error occurred.';
    }

    // Return a default message if no error message is found
    return 'An unknown error occurred.';
  } catch (err) {
    // Return a default message if no error message is found
    return 'An unknown error occurred.';
  }
}

export async function getAutonomousControlConfigList(configParam: any, callback: any) {
  fetchAutonmousConfigs(configParam).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, extractErrorMessage(err?.response?.status));
  });
}

export async function createAutonomousControlConfig(schedule: any, callback: any) {
  createAutonmousConfigs(schedule).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, extractErrorMessage(err?.response?.data));
  });
}

export async function updateAutonomousControlConfig(updateObj: any, callback: any) {
  updateAutonmousConfigs(updateObj).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, extractErrorMessage(err?.response?.data));
  });
}

export async function deleteAutonomousControlConfig(scheduleId: any, callback: any) {
  deleteAutonmousConfigs(scheduleId).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, extractErrorMessage(err?.response?.data));
  });
}

// Below actual API logics for threshold settings
export async function getMainThresholdConfig(callback: any) {
  fetchMainThresholds().then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}

export async function createThresholdConfig(thresholdSettings: any, callback: any) {
  createMainThresholds(thresholdSettings).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}

export async function updateThresholdConfig(thresholdSettings: any, callback: any) {
  updateMainThresholds(thresholdSettings).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}
