import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { SoilTypeTag } from './SoilTypeTag';
import { getSoilState, getColorClassForSoilState } from './soilState';
import './SoilMoistureDepthChart.less';
import { getPercentForFraction } from '../../../helper/common';

export function SoilMoistureDepthChart({ sensor }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { type, identifier } = sensor || {};
  const status = useSelector((state) => selectors.selectSensorStatus(state, type, identifier));

  // get units
  const userLengthUnits = useSelector((state) => selectors.selectUserLengthFormat(state)) || 'feet';
  const getUserUnits = hooks.useUnits();

  useEffect(() => {
    if (type && identifier) {
      dispatch(actions.loadSensorStatus(type, identifier));
    }
  }, [dispatch, type, identifier]);

  function getPercentValue(value) {
    if (value == null) return '-';
    return `${getPercentForFraction(value)}%`;
  }

  function getSoilData() {
    if (!status || !status.depthCount) {
      return [];
    }
    const dataByDepth = [];
    for (let depth = 1; depth <= status.depthCount; depth += 1) {
      dataByDepth.push({
        fieldCapacity: status[`fieldCapacity${depth}`],
        wiltingPoint: status[`wiltingPoint${depth}`],
        refillPoint: status[`refillPoint${depth}`],
        vwc: status[`vwc${depth}`],
        soilType: status[`soilType${depth}`],
        depth: status[`depth${depth}`],
      });
    }
    return dataByDepth;
  }

  const soilData = getSoilData();

  const displayConvertedDepth = (depth) => {
    const { value: depthConverted, label: depthUnitsLabel } = userLengthUnits === 'feet'
      ?getUserUnits(depth, 'inches', 'depth')
      :getUserUnits(depth, 'inches', 'depth', {}, 'centimeters');
    const depthLabel = depthUnitsLabel === 'in' ? 'in.' : t(depthUnitsLabel);
    const convertedDepth = `${Math.round(depthConverted)}`;

    return `${convertedDepth} ${depthLabel}`;
  };

  return (
    <div className="soil-moisture-depth-chart">
      <div className="soil-depth-column">
        <div className="soil-column-header">
          {t('Depth')}
        </div>
        {soilData.map((depthData) => (
          <div className="soil-depth-label soil-data-cell" key={depthData.depth}>
            {displayConvertedDepth(depthData.depth)}
          </div>
        ))}
      </div>
      <div className="soil-types-column">
        <div className="soil-column-header">
          {t('Soil Type')}
        </div>
        {soilData.map((depthData) => (
          <div key={depthData.depth} className="soil-data-cell soil-type-label">
            <SoilTypeTag soilType={depthData.soilType} />
          </div>
        ))}
      </div>
      <div className="soil-moisture-column">
        <div className="soil-column-header">
          {t('VWC')}
        </div>
        {soilData.map((depthData) => (
          <div
            key={depthData.depth}
            className={`${getColorClassForSoilState(getSoilState(
              depthData.vwc,
              depthData.wiltingPoint,
              depthData.fieldCapacity,
              depthData.refillPoint,
            ))} soil-data-cell`}
          >
            {getPercentValue(depthData.vwc)}
          </div>
        ))}
      </div>
    </div>
  );
}

SoilMoistureDepthChart.propTypes = {
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
  }).isRequired,
};
