import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Select, Button, Spin, Radio,
} from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import isEqual from 'react-fast-compare';
import { selectors, actions } from 'farmx-redux-core';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { FaSlidersH, FaLayerGroup } from 'react-icons/fa';
import { presModeTitles } from './MapSettings';
import HelpButton from '../components/HelpButton';
import MapControlButtons from './MapControlButtons';
import AnomalyControls from './Anomaly/AnomalyControls';
import AnomalyButtons from './Anomaly/AnomalyButtons';
import { isMobile } from '../../../utils/detectDevice';
import { getPresentationModes, summaryButton } from '../../../helper/mapHelper';

const {
  setRanchBlockSelection,
  setMapPresentationModes,
  setRanch,
  setBlocks,
} = actions;

const {
  selectLoginUserInfo,
  selectMapPresentationModes,
} = selectors;

const availableModes = [
  'soilstatus',
  'connectivity',
  'waterpressure',
  'control',
  'irrigationRecommendation',
  'anomaly',
  'cropHealth',
  'plants',
];

const plantsOptions = {
  count: 'Plants Health Classification',
  ndvi: 'NDVI',
  ndre: 'NDRE',
  // The commented options will be uncommented in the future
  // cropStress: 'Crop Stress',
  canopySize: 'Canopy Cover',
  // irrigation: 'Irrigation Metric',
  // standingWater: 'Standing Water',
  // evapotranspirationActual: 'ETA',
};

const plantsSettings = {
  plant: 'Plants',
  block: 'Block',
};

const plantsViewOptions = Object.keys(plantsOptions).map((key) => ({
  key,
  label: plantsOptions[key],
  value: key,
}));

const filterPlantOptions = ['ndvi', 'ndre', 'canopySize'];
const plantsOptionForBlock = plantsViewOptions.filter((x) => filterPlantOptions.includes(x.key));

export default function MapControlBox(props) {
  const {
    dispatchMap, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId, isBottomSheetVisible,
    isImageryPanelVisible, anomalyGeoJSON, createAnomaly, setCreateAnomaly,
    selectedFeature, setModal, setShowModal, setLayerOption, setMapSettingModal,
    sideBySideMapVisible, onChange, controlButtons, isDataLoading, activeScreen,
    isAllRanch, is3DView,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo && userInfo.admin;
  const [plantOption, setPlantOption] = useState('count');
  const [plantsView, setPlantsView] = useState('plant');
  const isPlants = presMode === 'plants';
  const isAnomaly = presMode === 'anomaly';
  const mobileControlContainer = (isPlants && !isAllRanch) ? 'controls-all-container not-plant-controls'
    :'controls-all-container';
  const mobileAnomalyContainer = isPlants ? 'controls-right top-10' : 'controls-right top-100';
  const CONDITION_CHECK = isMobile && isPlants && 'mobile-plant-view';
  const isBlockPlantView = plantsView === 'block';

  useEffect(() => {
    dispatchMap({
      type: 'setPlantsOption',
      payload: plantOption,
    });
  }, [dispatchMap, plantOption]);

  // To set plant view
  useEffect(() => {
    dispatchMap({
      type: 'setPlantsView',
      payload: plantsView,
    });
  }, [dispatchMap, plantsView]);

  const selMapPresentationModes = useSelector(
    (state) => selectMapPresentationModes(state), isEqual,
  );
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  function onRanchBlockClick(id, type) {
    if (type === 'block' && !isMobile) dispatch(setBlocks([Number(id)]));
    if (type === 'ranch' && !isMobile) dispatch(setRanch(Number(id)));
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: true,
      },
    });
  }

  const layerOptionButton = () => (
    <Button
      size="large"
      type="primary"
      icon={<FaLayerGroup />}
      style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white', border: 'none' }}
      onClick={() => {
        setLayerOption(true);
        dispatchMap({
          type: 'setIsBottomSheetVisible',
          payload: true,
        });
        if (controlButtons) {
          dispatchMap({
            type: 'setSelectedFeatureWithType',
            payload: {
              showBottomSheet: controlButtons.isBottomSheetVisible,
            },
          });
        }
        // Retrieve imagery panel when a feature is selected
        if (selectedFeature) {
          dispatchMap({
            type: 'setIsBottomSheetVisible',
            payload: true,
          });
        }
      }}
    />
  );

  function handleSelectedRanchBlock(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      onRanchBlockClick();
    }
  }

  const handleClick = (d) => {
    dispatchMap({
      type: 'setIsBottomSheetVisible',
      payload: true,
    });
    dispatchMap({
      type: 'setShowPlants',
      payload: d?.target?.value !== 'block',
    });
    setPlantOption(d?.target?.defaultValue === 'block' ? 'ndre' : 'count');
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: { feature: undefined, showBottomSheet: true },
    });
  };

  const mapViewOptions = useMemo(() => Object
    .keys(getPresentationModes(selMapPresentationModes))
  // exclude modes not yet implemented
    .filter((mode) => availableModes.includes(mode))
    .map((mode) => ({
      key: mode,
      label: presModeTitles[mode],
      value: mode,
    })), [selMapPresentationModes]);

  function renderPlantsSettings() {
    return (
      <div className="controls-box">
        <Select
          className="map-view-select plants-view-options"
          value={t(plantsOptions[plantOption])}
          style={{
            minWidth: 220,
            color: 'white',
            fontSize: 16,
          }}
          onChange={(e) => setPlantOption(e)}
          options={plantsView === 'block' ? plantsOptionForBlock
            : plantsViewOptions}
          suffixIcon={<DownOutlined style={{ color: 'white' }} />}
          popupClassName="map-select-dropdown-settings"
        />
      </div>
    );
  }

  function renderPlantsSettingsTab() {
    return (
      <div className="controls-box">
        <Radio.Group
          onChange={(d) => setPlantsView(d?.target?.value)}
          defaultValue="week"
          value={plantsView}
          className="map-view-select plant-view-radio-buttons flex-row"
        >
          {Object.keys(plantsSettings).map((key) => (
            <Radio.Button
              value={key}
              onClick={handleClick}
              className="flex-center"
            >
              {t(plantsSettings[key])}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    );
  }

  return (
    <div className="map-mobile-screen">
      <div className={!isMobile ? 'controls-all-container map-desktop-control-box'
        : `${mobileControlContainer}`}
      >
        {isMobile && (
        <div className="div-select-container ranch-block-select-container">
          <RanchBlockSelectMobile
            getSelectedRanchBlock={handleSelectedRanchBlock}
            selected={selectedObjFromState}
            admin={isAdmin}
          />
        </div>
        )}
        <div className="controls-box">
          {(!isAnomalyControlEnabled && !isAllRanch) && (
          <>
            <div className="controls-left">
              <div className="map-view-select-wrapper">
                <span className="map-view-select-icon"><FaSlidersH /></span>
                <Select
                  className="map-view-select"
                  value={t(presModeTitles[presMode])}
                  style={{
                    minWidth: 220,
                    color: 'white',
                    fontSize: 16,
                  }}
                  onChange={(e) => {
                    if (e === 'anomaly' && onChange) onChange(null);
                    localStorage.setItem('presMode', JSON.stringify({ [e]: true }));
                    dispatch(setMapPresentationModes({ [e]: true }));
                    if (e !== 'plants') setPlantsView('plant');
                  }}
                  options={mapViewOptions}
                  suffixIcon={<DownOutlined style={{ color: 'white' }} />}
                  popupClassName="map-select-dropdown-settings"
                />
              </div>
            </div>
            <div className="help-icon">
              <HelpButton onClick={() => setShowModal(true)} />
            </div>
            <div>
              {isDataLoading && (
              <div
                className={`spinner-for-map ${CONDITION_CHECK}`}
              >
                <Spin indicator={antIcon} />
              </div>
              )}
            </div>
          </>
          )}
          <div className={isAnomaly ? 'controls-right' : `${mobileAnomalyContainer}`}>
            {isMobile && !isAllRanch && (
            <>
              {!isBottomSheetVisible
               && summaryButton(dispatchMap, selectedObjFromState?.type)}
              {!isAnomalyControlEnabled && layerOptionButton()}
              {!isAnomalyControlEnabled && (
              <MapControlButtons
                isMobile={isMobile}
                isImageryPanelVisible={stateMapArg.isImageryPanelVisible}
                selectedObjFromState={selectedObjFromState}
                ranchId={ranchId}
                blockId={blockId}
                dispatchMap={dispatchMap}
                isBottomSheetVisible={isBottomSheetVisible}
                selectedImageryData={stateMapArg.selectedImageryData}
                anomalyGeoJSON={anomalyGeoJSON}
                presMode={presMode}
                isPlants={isPlants}
                controlButtons={controlButtons}
                setLayerOption={setLayerOption}
                sideBySideMapVisible={sideBySideMapVisible}
                activeScreen={activeScreen}
                isBlockPlantView={isBlockPlantView}
              />
              )}
              <AnomalyControls
                presMode={presMode}
                createAnomaly={createAnomaly}
                setCreateAnomaly={setCreateAnomaly}
                selectedFeature={selectedFeature}
                setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
              />
            </>
            )}
          </div>
          {isMobile && (
          <AnomalyButtons
            presMode={presMode}
            createAnomaly={createAnomaly}
            setCreateAnomaly={setCreateAnomaly}
            stateMapArg={stateMapArg}
            setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
            isImageryPanelVisible={isImageryPanelVisible}
            sideBySideMapVisible={sideBySideMapVisible}
            setModal={setModal}
          />
          )}
        </div>
        {isPlants && !isAllRanch && !is3DView && renderPlantsSettings()}
        {isPlants && !isAllRanch && !is3DView && renderPlantsSettingsTab()}
      </div>
    </div>
  );
}

MapControlBox.propTypes = {
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  setModal: PropTypes.func,
  isBottomSheetVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  selectedFeature: PropTypes.shape(),
  selectedObjFromState: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  isAnomalyControlEnabled: PropTypes.bool,
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  setShowModal: PropTypes.func,
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
  anomalyGeoJSON: PropTypes.shape(),
  sideBySideMapVisible: PropTypes.bool,
  onChange: PropTypes.func,
  controlButtons: PropTypes.shape(),
  isDataLoading: PropTypes.bool,
  activeScreen: PropTypes.func.isRequired,
  isAllRanch: PropTypes.bool,
  is3DView: PropTypes.bool,
};

MapControlBox.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  isBottomSheetVisible: false,
  isImageryPanelVisible: false,
  selectedFeature: null,
  dispatchMap: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  setShowModal: null,
  setLayerOption: null,
  setMapSettingModal: null,
  anomalyGeoJSON: null,
  sideBySideMapVisible: false,
  onChange: null,
  controlButtons: null,
  isDataLoading: false,
  isAllRanch: false,
  is3DView: false,
};
