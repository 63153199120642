import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Alert, Button, Input, Typography, Form, message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { PageHeader } from '../components/PageHeader';
import { withUserSettings } from '../settings/withUserSettings';
import { ChangePasswordSettings } from './ChangePasswordSettings';
import { validateEmail } from '../../../helper/common';
import { isMobile } from '../../../utils/detectDevice';
import './accountSettings.css';
import 'react-phone-number-input/style.css';

const {
  patchUserSettings,
} = actions;

const EMAIL_LENGTH = 30;

function Account() {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => selectors.selectUserSettings(state));
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState();
  const [isPossibleNumber, setIsPossibleNumber] = useState(true);
  const [visible, setVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const onEmailChange = (e) => {
    const val = e.target.value;
    if (val.length > EMAIL_LENGTH) return;
    setEmail(val);
  };

  const onChangePasswordClick = () => {
    changePasswordForm.resetFields();
    setVisible((show) => !show);
  };

  const handleOk = () => {
    setShowAlert(true);
    onChangePasswordClick();
  };

  const closeAlert = () => setShowAlert(false);

  useEffect(() => {
    if (userSettings) {
      const { email: userEmail, phone_number: phoneNumber } = userSettings;

      setEmail(userEmail);
      setNumber(phoneNumber);

      form.setFieldsValue({
        email: userEmail,
        number: phoneNumber,
      });
    }
  }, [dispatch, form, userSettings]);

  const onChangeNumber = (phoneNumber) => {
    setNumber(phoneNumber);
    const isValidNumber = phoneNumber ? isValidPhoneNumber(phoneNumber) : true;
    setIsPossibleNumber(isValidNumber);
  };
  const onUpdateClick = async () => {
    if (!isPossibleNumber) return;

    const payload = { email };
    if (number) {
      payload.phone_number = number;
    }

    const response = await dispatch(patchUserSettings(payload));
    if (response.type.split('/').at(-1) === 'fulfilled') {
      message.success(`${t('Successfully updated')}`);
    } else {
      message.error(`${t('Update failed')}`);
    }
    setIsTouched(false);
  };

  return (
    <div className="div-default white-bg mobile-account-settings-container">
      <PageHeader
        showBack={!!isMobile}
        title={t('Account Settings')}
        toPath="/settings"
      />
      {showAlert && (
        <Alert
          className="mobile-account-settings-alert"
          closable
          message={t('Password changed successfully')}
          type="success"
          onClose={closeAlert}
        />
      )}
      <div className="account-content">
        <Form
          onFinish={onUpdateClick}
          form={form}
          onFieldsChange={() => {
            setIsTouched(true);
          }}
        >
          <Form.Item
            name="email"
            className="mobile-account-settings-input"
            rules={[{
              required: true,
              message: t('Please enter your email'),
            },
            () => ({
              validator(_, value) {
                const isEmailValid = validateEmail(value);

                if (!value || isEmailValid) {
                  return Promise.resolve();
                }
                return Promise.reject(Error('Please enter valid email'));
              },
            })]}
          >
            <Input
              className="mobile-account-email"
              type="email"
              placeholder={t('Email Address')}
              maxLength={30}
              size="large"
              bordered={false}
              onChange={onEmailChange}
              value={email}
              name="email"
              autoComplete="email"
            />
          </Form.Item>
          <Typography.Text className="input-count">
            {`${email?.length}/${EMAIL_LENGTH}`}
          </Typography.Text>

          <Form.Item
            name="number"
            className="mobile-account-settings-input"
            validateStatus={isPossibleNumber ? 'success' : 'error'}
            help={isPossibleNumber ? '' : t('Please enter a valid number')}
          >
            <PhoneInput
              international
              defaultCountry="US"
              countries={['US', 'JP', 'IN']}
              countryCallingCodeEditable={false}
              placeholder={t('Phone Number')}
              value={number}
              onChange={onChangeNumber}
            />
          </Form.Item>
          <Typography.Text type="secondary" className="formatted-number">{number}</Typography.Text>

          <Button
            type="link"
            size="large"
            onClick={onChangePasswordClick}
          >
            {t('Change Password')}
          </Button>
          <div className="mobile-change-password">
            <Button
              className="primary-button mobile-change-password-button"
              type="primary"
              size="large"
              onClick={form.submit}
              disabled={!isTouched}
            >
              {t('Update')}
            </Button>
          </div>
        </Form>
        <ChangePasswordSettings
          visible={visible}
          handleCancel={onChangePasswordClick}
          handleOk={handleOk}
          form={changePasswordForm}
        />
      </div>
    </div>
  );
}

export const AccountSettings = withUserSettings(Account);
