import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import RenderSprinklerRanch from './RenderSprinklerRanch';

const SprinklerControlSection = ({ ranches, season2024 }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Typography.Title className="margin-top-20" level={3}>
        {`${t('Sprinkler Control Automation')}:`}
      </Typography.Title>
      <Typography.Text>
        {t('Enable automation')}
        :
        {t(' Start one sprinkler based on the activation of another.')}
      </Typography.Text>
      <div className="auto-schedule-buttons-wrapper padding-10">
        <span />
      </div>
      {ranches.map((ranch) => (
        <RenderSprinklerRanch key={ranch.id} ranch={ranch} previous={season2024} />
      ))}
    </section>

  );
};

SprinklerControlSection.propTypes = {
  ranches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  season2024: PropTypes.bool
};

SprinklerControlSection.defaultProps = {
  season2024: false
};

export default SprinklerControlSection;
