import { ComponentType } from 'react';
import { TeamsList } from './components/TeamsList';
import { UsersList } from './components/UsersList';
import { TeamDetail } from './components/TeamDetail';
import { UserDetail } from './components/UserDetail';
import { CreateTeam } from './components/CreateTeam';
import { InviteUser } from './components/InviteUser';

// Definition of route keys
export const ROUTE_KEYS = {
  TEAMS_LIST: '/organization/teams',
  CREATE_TEAM: '/organization/teams/create',
  USERS_LIST: '/organization/users',
  TEAM_DETAILS: '/organization/teams/:teamId',
  INVITE_USER: '/organization/teams/:teamId/invite-user',
  INVITE_USER_NO_TEAM: '/organization/users/invite-user',
  USER_DETAILS: '/organization/users/:userId',
};

// Interface to type each route configuration
interface RouteConfig {
  component: ComponentType<any>;
  exact: boolean;
  key: string;
  path: string;
}

// Define the routes with components and their paths
export const ROUTES: Record<string, RouteConfig> = {
  TEAMS_LIST: {
    component: TeamsList,
    exact: true,
    key: ROUTE_KEYS.TEAMS_LIST,
    path: ROUTE_KEYS.TEAMS_LIST,
  },
  USERS_LIST: {
    component: UsersList,
    exact: true,
    key: ROUTE_KEYS.USERS_LIST,
    path: ROUTE_KEYS.USERS_LIST,
  },
  CREATE_TEAM: {
    component: CreateTeam,
    exact: true,
    key: ROUTE_KEYS.TEAMS_LIST, 
    path: ROUTE_KEYS.CREATE_TEAM,
  },
  TEAM_DETAILS: {
    component: TeamDetail,
    exact: true,
    key: ROUTE_KEYS.TEAMS_LIST, 
    path: ROUTE_KEYS.TEAM_DETAILS,
  },
  INVITE_USER: {
    component: InviteUser,
    exact: true,
    key: ROUTE_KEYS.TEAMS_LIST, 
    path: ROUTE_KEYS.INVITE_USER,
  },
  INVITE_USER_NO_TEAM: {
    component: InviteUser,
    exact: true,
    key: ROUTE_KEYS.USERS_LIST,
    path: ROUTE_KEYS.INVITE_USER_NO_TEAM,
  },
  USER_DETAILS: {
    component: UserDetail,
    exact: true,
    key: ROUTE_KEYS.USERS_LIST,
    path: ROUTE_KEYS.USER_DETAILS,
  },
};
