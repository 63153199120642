import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { hooks, actions, selectors } from 'farmx-redux-core';
import {
  Button, notification, Typography, Alert
} from 'antd';
import { FaChevronLeft } from 'react-icons/fa';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import EditableTable from './EditableTable';
import AutonomousControlConfig from './AutonomousControlConfig';
import {
  getAutonomousControlConfigList,
  createAutonomousControlConfig,
  updateAutonomousControlConfig
} from '../../../helper/settingsHelper';
import { isMobile } from '../../../utils/detectDevice';
import Page404 from '../../app/Page404';
import './ruleSettingsPage.less';
import { GEC_RANCH_WATERMELON_AUTO, GEC_ENTITY_ID, GEC_AUTONOMOUS_RANCHES } from '../../../constants';

const { useEntities, useBlockNames, useRanchBlockSelection } = hooks;

const {
  setRanchBlockSelection,
  setRanch, setBlocks,
} = actions;

const {
  selectAllNewBlocks, selectSelectedRanch, selectLoginUserInfo,
  selectSelectedBlock
} = selectors;

const RuleSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showControl, setShowControl] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fetchList, setFetchList] = useState(false);
  const [configList, setConfigList] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(false);
  const { activeEntityId } = useEntities();
  const allBlocks = useSelector(selectAllNewBlocks);
  const [selectedRanchId] = useSelector(selectSelectedRanch)?.payload || [];
  const [selectedBlockId] = useSelector(selectSelectedBlock)?.payload || [];
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const autonomousIrrigationBlocks = allBlocks
    .filter((block: object) => block?.ranch?.id===selectedRanchId);
  const blockIds = autonomousIrrigationBlocks.map((block: object) => block?.id);
  const blockNames = useBlockNames(blockIds) || {};
  const formRef = useRef<any>(null);
  const { selectedObjFromState } = useRanchBlockSelection();
  const { type, value }: any = selectedObjFromState || {};
  const ranchId = type === 'ranch' ? value : autonomousIrrigationBlocks
    ?.filter((d: any) => d?.id === selectedBlockId)?.map((d: any) => d?.ranch?.id)?.[0];
  const blockId = type === 'block' ? value : undefined;
  const isAutonomousRanchSelected = (selectedRanchId === GEC_RANCH_WATERMELON_AUTO
    || ranchId === GEC_RANCH_WATERMELON_AUTO
    || [...GEC_AUTONOMOUS_RANCHES].includes(selectedRanchId)
    || [...GEC_AUTONOMOUS_RANCHES].includes(ranchId));

  function updateRespone(response: any) {
    return response?.map((obj: any) => ({
      ...obj,
      key: `${blockNames[obj?.block_id]}_${obj?.block_id}_${obj?.schedule?.id}`,
      name: blockNames[obj?.block_id],
      updated_at: moment(obj?.updated_at).format('YYYY-MM-DD HH:mm:ss'),
    })) || [];
  }

  useEffect(() => {
    if (!ranchId && !blockId) return;
    getAutonomousControlConfigList({ ranchId, blockId }, (success: boolean, response: any) => {
      if (success) {
        const updatedResponse = updateRespone(response?.data);
        setConfigList(updatedResponse);
        setFetchList(false);
      } else {
        notification.error({
          message: t('Internal Server Error while fetching data!'),
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranchId, blockId, fetchList]);

  // Show 404:Page when GEC not selected
  if (activeEntityId !== GEC_ENTITY_ID) return <Page404 />;

  const methods = {
    irrigation: {
      autonomous_irrigation: 'Drip Irrigation',
    },
    sprinkler: {
      autonomous_sprinkler_irrigation: 'Sprinkler Irrigation',
      autonomous_sprinkler_cooling: 'Sprinkler Cooling',
    }
  };

  // The columns will be updated in the future
  const columns: ColumnsType = [
    {
      title: 'Block',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: 'Autonomous Method',
      dataIndex: 'action_type',
      key: 'action_type',
      render: (_, record) => (
        <>
          {methods[record.action_type][record.action_subtype]}
        </>
      ),
      width: 200
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200
    },
    {
      title: 'Start Date',
      dataIndex: 'schedule.starting_time_window',
      key: 'starting_time_window',
      render: (_, record: any) => (moment(record?.schedule
        ?.starting_time_window).format('YYYY-MM-DD HH:mm:ss')),
      width: 200
    },
    {
      title: 'End Date',
      dataIndex: 'schedule.ending_time_window',
      key: 'ending_time_window',
      render: (_, record: any) => (moment(record?.schedule
        ?.ending_time_window).format('YYYY-MM-DD HH:mm:ss')),
      width: 200
    },
  ];

  const onGoBack = () => {
    if (showControl) setShowControl(false);
    if (isEdit) setIsEdit(false);
    if (selected) setSelected(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (formRef.current) formRef?.current?.resetFields();
  };

  const onEdit = (value: any) => {
    setIsEdit(true);
    setSelected(value);
  };

  const handleFormRef = (value: any) => { formRef.current = value; };

  const handleUpdate = (obj: any) => {
    setLoading(true);
    if (obj?.create) {
      createAutonomousControlConfig(obj?.create, (success: boolean, response: any) => {
        if (success) {
          notification.success({
            message: `${t('Created Successfully')}!`,
          });
          setFetchList(true);
          onGoBack();
        } else {
          notification.error({
            message: t('Failed to create'),
            description: response ? t(response) : t('Something went wrong'),
          });
        }
        setLoading(false);
      });
    }
    if (obj?.edit) {
      const { id } = obj?.edit?.schedule || {};
      const updateObj = { schedule: obj?.edit, scheduleId: id };
      updateAutonomousControlConfig(updateObj, (success: boolean, response: any) => {
        if (success) {
          notification.success({
            message: `${t('Edited Successfully')}!`,
          });
          setFetchList(true);
          onGoBack();
        } else {
          notification.error({
            message: t('Failed to edit'),
            description: response ? t(response) : t('Something went wrong'),
          });
        }
        setLoading(false);
      });
    }
    if (obj?.delete) {
      setFetchList(true);
      onGoBack();
      setLoading(false);
    }
  };

  const handleToggleChange = (record: any) => {
    const { id } = record?.schedule || {};
    setLoadingId(id);
    const updateObj = { schedule: record, scheduleId: id };
    updateAutonomousControlConfig(updateObj, (success: boolean, response: any) => {
      if (success) {
        notification.success({
          message: `${t('Updated Successfully')}!`,
        });
        setFetchList(true);
        onGoBack();
      } else {
        notification.error({
          message: t('Failed to update'),
          description: response ? t(response) : t('Something went wrong'),
        });
      }
      setLoadingId(false);
    });
  };

  function handleSelect(d: any) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      if (d?.type === 'block' && !isMobile) dispatch(setBlocks([Number(d?.value)]));
      if (d?.type === 'ranch' && !isMobile) dispatch(setRanch(Number(d?.value)));
    }
  }

  const title = isEdit ? `${t('Update')} / ${t('Delete Rule')}` : t('Create New Rule');

  return (
    <div className={`single-column-page padded desktop-container desktop-container-config
     ${(showControl || isEdit)? 'background-white height-max' : ''}`}
    >
      {(!showControl && !isEdit) && (
        <>
          <div className="flex-space-between">
            <div>
              <Typography.Title level={3}>{t('Autonomous Rule Settings')}</Typography.Title>
            </div>
            {isAutonomousRanchSelected && (
            <div className="btn-align-end">
              <Button type="primary" htmlType="submit" onClick={() => setShowControl(true)}>
                {t('Create')}
              </Button>
            </div>
            )}
          </div>
          <div className="div-select-container ranch-block-select-container">
            {isMobile && (!showControl && !isEdit) && (
            <RanchBlockSelectMobile
              onSelect={handleSelect}
              selected={selectedObjFromState}
              admin={userInfo && userInfo.admin}
            />
            )}
          </div>
          {isAutonomousRanchSelected && (
          <EditableTable
            data={configList}
            columns={columns}
            onEdit={(d: any) => onEdit(d)}
            onToggleChange={handleToggleChange}
            loadingId={loadingId}
          />
          )}
        </>
      )}
      {(showControl || isEdit) && (
        <>
          <div className="flex-space-between btn-align-start">
            <div className={`${(isMobile && title?.length > 50)
              ? 'page-header-length' : `page-header ${!isMobile ? 'custom-width' : ''}`}`}
            >
              <div className="page-header-main back-header">
                <Button
                  id="goBack"
                  className="back-btn"
                  shape="circle"
                  size="large"
                  icon={<FaChevronLeft size={20} />}
                  onClick={() => onGoBack()}
                />
                <div className="rule-setting-form-title">
                  <Typography.Title level={1}>{title}</Typography.Title>
                </div>
              </div>
            </div>
          </div>
          <AutonomousControlConfig
            key={`config-${ranchId}`}
            isEdit={isEdit}
            blocks={autonomousIrrigationBlocks}
            onUpdate={handleUpdate}
            settings={selected}
            formRef={handleFormRef}
            onCancel={onGoBack}
            loading={loading}
          />
        </>
      )}
      {!isAutonomousRanchSelected && (
        <Alert message={t('Please select an Autonomous Irrigation ranch')} type="warning" />
      )}
    </div>
  );
};

export default RuleSettingsPage;
