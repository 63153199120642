import React, {
  useCallback, useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import moment from 'moment';
import { displayConvertedDepth } from 'helper/depthUnitHelper';
import { FaEdit } from 'react-icons/fa';
import {
  cutoffLineLabelStyle,
  getColors, getSoilCutoffPoints
} from '../../helper/graphHelper';
import {
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
} from '../../utils/colors';
import NewSensorDataChart from './NewSensorDataChart';
import EditSoilSensorProperties from './EditSoilSensorProperties';
import { CALL_CUSTOM_CONFIG_AND_RENDER } from './constants';

const { useUnits } = hooks;
const { selectUserDepthFormat }= selectors;

export default function MultilineStackedDepthChart(props) {
  const {
    sensor, uniformYAxis, yMinMaxMap,
    preparedConfig, switchMode, refreshGrapData,
    graphData, startDate, endDate, variables
  } = props;
  const getUserUnits = useUnits();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [chartLegend, setChartLegend] = useState({});
  const [listDepth, setListDepth] = useState({});

  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensor.type, sensor.identifier),
  );
  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);
  const depthFormat = useSelector(selectUserDepthFormat);

  const updateChartLegend = useCallback((key, value) => {
    const { color, isRootzone } = value;
    if (chartLegend[key]) {
      if (
        chartLegend[key]?.color === color
        && chartLegend[key]?.isRootzone === isRootzone
      ) return;
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color, isRootzone
        },
      }));
    } else {
      setChartLegend((prevLegend) => ({
        ...prevLegend,
        [key]: {
          color, isRootzone
        },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderChartLegend = (chartLeg, identifier) => {
    if (!chartLeg || !Object.keys(chartLeg).length) return [];
    const filteredLegend = Object.keys(chartLeg).map((chartLegKey) => {
      const chartLegLabel = chartLegKey.split('soil_moisture_')[1];
      if (!chartLegLabel) return null;
      return {
        chartLegLabel,
        chartLegKey,
      };
    });
    return filteredLegend
      .filter((item) => item !== null)
      .sort((a, b) => a.chartLegLabel.localeCompare(b.chartLegLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
      .map(({ chartLegKey, chartLegLabel }) => {
        const individualListDepth = listDepth[chartLegKey];
        const isEnabled = individualListDepth === undefined || individualListDepth;
        return (
          <Button
            key={`legend-pill-${sensor.id}-${chartLegLabel}`}
            className="legend-pill"
            style={{
              backgroundColor: (
                isEnabled ? chartLeg[chartLegKey].color : 'lightgray'
              ),
              border: `3px solid ${chartLeg[chartLegKey].isRootzone ? colorOk : 'grey'}`,
            }}
            onClick={() => onChartLegendClick({
              identifier, chartLegKey,
            })}
          >
            {`${displayConvertedDepth(chartLegLabel, getUserUnits, depthFormat)}`}
          </Button>
        );
      });
  };

  const onChartLegendClick = (params) => {
    const { chartLegKey } = params || {};
    if (!chartLegKey) return;
    if (listDepth) {
      const isEnabled = listDepth[chartLegKey];
      const newVal = isEnabled === undefined ? false : !isEnabled;
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: newVal,
      }));
    } else {
      setListDepth((existingListDepth) => ({
        ...existingListDepth,
        [chartLegKey]: false,
      }));
    }
  };

  const getStackedData = (data, sortedDepths, currentDepth) => {
    // for each dataPoint in data
    // newDataPoint = [dataPoint[0], ((currentDepth + dataPoint[1]) * CONSTANT )]
    const newData = data?data.map((dataPoint) => [dataPoint[0],
      Number(currentDepth)+(dataPoint[1]
        * sortedDepths[0]) // initial depth is used as constant
    ]):[];

    return newData;
  };

  const customConfigForMultiline = useCallback((configObj, graphData, chartRef) => {
    const sortedKeys = Object.keys(graphData)
      .map((key) => Number(key.split('soil_moisture_')[1]))
      .sort((a, b) => Number(a) - Number(b));
    if (configObj && configObj.series?.length) {
      const yAxis = configObj.yAxis?.map(
        (attr) => {
          const {value: valueMin, label} = getUserUnits(Math.min(...sortedKeys), 'inches', 'depth');
          const {value: valueMax} = getUserUnits(Math.max(...sortedKeys)+Math.min(...sortedKeys), 'inches', 'depth');
          return {
            ...attr,
            min: label==='mm'?valueMin/10:valueMin,
            max: label==='mm'?valueMax/10:valueMax,
          };
        }
      );

      // update series data
      const updatedSeries = configObj.series.map((seriesData, i) => {
        if(chartRef && chartRef.current) {
          if(listDepth[seriesData.actualChartKey] === false) {
            const filteredSeries = chartRef.current.chart?.series?.filter(
              (seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey
            );
            if(filteredSeries?.length) filteredSeries[0].hide();
          }else{
            const filteredSeries = chartRef.current.chart?.series?.filter(
              (seriesObj) => seriesObj.userOptions.actualChartKey===seriesData.actualChartKey
            );
            if(filteredSeries?.length) filteredSeries[0].show();
          }
        }

        const cutOffPoints = getSoilCutoffPoints(seriesData, sensorStatus);
        const {value: convertedCurrentDepth, label} = getUserUnits(cutOffPoints.depth, 'inches', 'depth');
        const depthValue = label==='mm'?convertedCurrentDepth/10:convertedCurrentDepth;
        const stackedData = getStackedData(
          graphData[seriesData.actualChartKey][0].data,
          sortedKeys,
          depthValue
        );
        const seriesOption = {
          ...seriesData,
          data: stackedData,
          depth: Number(cutOffPoints.depth),
        };
        const seriesOptionsColor = getColor(seriesOption.actualChartKey);
        updateChartLegend(
          seriesOption.actualChartKey,
          {
            color: seriesOptionsColor,
            isRootzone: cutOffPoints.isRootzone,
          },
        );
        seriesOption.color = seriesOptionsColor;
        seriesOption.name = `Soil Moisture ${displayConvertedDepth(cutOffPoints.depth, getUserUnits, depthFormat)} (%)`;
        const multiplier = 100;
        // TODO remove if plot lines are not used
        seriesOption.point1 = {
          events: {
            mouseOver() {
              try {
                const yAxisRef = chartRef.current?.chart?.yAxis[i];
                if (yAxisRef) {
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.wiltingPoint * multiplier,
                    color: colorCritical,
                    width: 2,
                    id: 'moisture_zone_lower',
                    dashStyle: 'dash',
                    label: {
                      x: 0,
                      y: 12,
                      align: 'right',
                      text: `WP (${Number(cutOffPoints.wiltingPoint * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.refillPoint * multiplier,
                    color: colorWarning,
                    width: 2,
                    id: 'moisture_zone_middle',
                    dashStyle: 'dash',
                    label: {
                      x: -55,
                      align: 'right',
                      text: `RP (${Number(cutOffPoints.refillPoint * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                  yAxisRef.addPlotLine({
                    value: cutOffPoints.fieldCapacity * multiplier,
                    color: colorOver,
                    width: 2,
                    id: 'moisture_zone_upper',
                    dashStyle: 'dash',
                    label: {
                      x: -110,
                      y: 12,
                      align: 'right',
                      text: `FC  (${Number(cutOffPoints.fieldCapacity * multiplier).toFixed(1)})`,
                      style: cutoffLineLabelStyle
                    }
                  });
                }
              } catch (error) {
                console.log('Error in hover', error);
              }
            },
            mouseOut() {
              try {
                const yAxisRef = chartRef.current?.chart?.yAxis[i];
                if (yAxisRef) {
                  yAxisRef.removePlotLine('moisture_zone_lower');
                  yAxisRef.removePlotLine('moisture_zone_middle');
                  yAxisRef.removePlotLine('moisture_zone_upper');
                }
              } catch (error) {
                console.log('mouseOut error', error);
              }
            },
          },
        };
        return seriesOption;
      });
      const newConfig = {
        ...configObj,
        series: updatedSeries,
        yAxis,
        tooltip: {
          formatter(){
            /* eslint-disable react/no-this-in-sfc */
            const {value: convertedCurrentDepth, label} = getUserUnits(this.series.userOptions.depth, 'inches', 'depth');
            const depthValue = label==='mm'?convertedCurrentDepth/10:convertedCurrentDepth;
            /* eslint-disable react/no-this-in-sfc */
            return `<br>${moment(this.x).format('dddd, MMM DD, HH:mm:ss')}</br><br>${this.series.userOptions.name} :   
            ${(((this.y-depthValue)/sortedKeys[0]) * 100).toFixed(2)}</br>`;
            /* eslint-enable react/no-this-in-sfc */
          }
        },
      };
      return newConfig;
    }
    return configObj;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthFormat, getColor, listDepth, sensorStatus, updateChartLegend]);

  const onClickEdit = useCallback(() => {
    setEdit(true);
  });

  const onCancel = useCallback(() => {
    setEdit(false);
  });

  const onSave = useCallback(() => {
    setEdit(false);
    refreshGrapData();
  });

  const EditLink = useMemo(() => (
    <div
      key={`edit-link-${sensor.id}`}
      className="chart-header-edit-link"
      onClick={onClickEdit}
      onKeyDown={onClickEdit}
      role="button"
      tabIndex={0}
    >
      <FaEdit />
      <span style={{marginLeft: '13px'}}>
        Edit
      </span>
    </div>
  ), [onClickEdit, sensor.id]);

  return (
    <>
      {/* Display multiline moisture chart with stacked depths */}
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        customConfig={customConfigForMultiline}
        header={{
          title: t('Soil Moisture VWC (Stacked)'),
          units: '',
          left: [...renderChartLegend(chartLegend, sensor.identifier), ...switchMode],
          right: [EditLink]
        }}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMaxMap}
        renderOption={CALL_CUSTOM_CONFIG_AND_RENDER}
        preparedConfig={preparedConfig}
        variables={variables}
        startDate={startDate}
        endDate={endDate}
      />
      {edit?(
        <EditSoilSensorProperties
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          onSave={onSave}
          chartData={graphData}
        />
      ):null}
    </>
  );
}

MultilineStackedDepthChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
  preparedConfig: PropTypes.shape({}),
  graphData: PropTypes.shape({}),
  switchMode: PropTypes.arrayOf(PropTypes.shape({})),
  refreshGrapData: PropTypes.func,
  variables: PropTypes.arrayOf(PropTypes.any),
};

MultilineStackedDepthChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  uniformYAxis: false,
  yMinMaxMap: null,
  preparedConfig: null,
  graphData: null,
  switchMode: [],
  refreshGrapData: () => null,
  variables: null,
};
