import React from "react";
import { RouteComponentProps } from "react-router-dom";
import CreateTeam from "./CreateTeam";
import { useSelector } from "react-redux";
import { message } from "antd";
//@ts-ignore - farmx-api is not typed
import { teamApi } from 'farmx-api';
//@ts-ignore - farmx-redux-core is not typed
import { selectors } from 'farmx-redux-core';

/**
 * CreateTeamContainer is a container component that handles the form submission for creating a team.
 * @param {RouteComponentProps} props - The standard react-router-dom props. 
 * @returns {JSX.Element}
*/
const CreateTeamContainer: React.FC<RouteComponentProps> = (props:RouteComponentProps) => {
  // State of the loading indicator
  const [loading, setLoading] = React.useState(false);

  const selectedEntity = useSelector((state: any) => selectors.selectActiveEntity(state));  
  /**
   * Method to handle the form submission for creating a team
   * @param {Object} data - The team form object
   * @param {string} data.teamName - The name of the team
   * @returns {void}
   */
  const submitCreateTeamForm = async (data: { teamName: string }) => {
    setLoading(true);
    try {
      const teamResult = await teamApi.createTeam({ name: data.teamName, entityId: selectedEntity });
      props.history.push('/organization/teams/' + teamResult.data.data.id);
    } catch (error) {
      const axiosError = error as any;
      if (axiosError && axiosError.response) {
        const statusCode = axiosError.response.status;
        const errorMessage = axiosError.response.data.detail;
        if (statusCode === 401) {
          message.error('You do not have permission to create a team.');
        } else if (statusCode === 400) {
          message.error(errorMessage || 'An error occurred while creating the team.');
        }
      } else {
        // Handle non-Axios errors
        message.error('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  }
  
  return (  
    <CreateTeam 
      loading={loading} 
      submitCreateTeamForm={submitCreateTeamForm} 
    />
  );
}


export default CreateTeamContainer;