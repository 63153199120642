import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
  FaSatellite,
  FaCube,
  FaDownload,
} from 'react-icons/fa';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useWeatherInfo from './useWeatherInfo';
import TemperatureOverlay from './TemperatureOverlay';
import WindOverlay from './WindOverlay';
import { convertToCSV, downloadCSVFile } from '../../../helper/scheduleHelper';
import { flattenAnomalyArrayForCsv } from '../../../helper/mapHelper';

export default function MapControlButtons({
  selectedImageryData,
  dispatchMap,
  isBottomSheetVisible,
  isImageryPanelVisible,
  selectedObjFromState,
  ranchId,
  blockId,
  isMobile,
  anomalyGeoJSON,
  presMode,
  isPlants,
  setLayerOption,
  layerControl,
  sideBySideMapVisible,
  activeScreen,
  isBlockPlantView,
}) {
  const [weatherInfo, isWeatherLoading] = useWeatherInfo();
  const { t } = useTranslation();

  function onClickButton(type) {
    if (type === 'satellite') {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: !isImageryPanelVisible,
      });
      if (!isBottomSheetVisible) {
        dispatchMap({
          type: 'setIsBottomSheetVisible',
          payload: true,
        });
      }
      dispatchMap({
        type: 'setSelectedImageryData',
        payload: {
          visible: !isImageryPanelVisible,
          data: undefined,
        },
      });
      dispatchMap({
        type: 'setSelectedDroneImagery',
        payload: { visible: true, data: undefined },
      });
      setLayerOption(true);
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          showBottomSheet: true,
        },
      });
      dispatchMap({
        type: 'setLayersControl',
        payload: {
          ...layerControl, type: 'imagery', side: 'left', layer: { side: 'right' },
        },
      });
      if (sideBySideMapVisible && isImageryPanelVisible) {
        dispatchMap({
          type: 'setSideBySideMapVisible',
          payload: !sideBySideMapVisible,
        });
      }
      if (isPlants) {
        dispatchMap({
          type: 'setSelectedSatelliteDataSource',
          payload: 'drone',
        });
      }
    }
    if (type === '3d') {
      dispatchMap({ type: 'setShowMap3D', payload: true });
      dispatchMap({
        type: 'setIsBottomSheetVisible',
        payload: false,
      });
    }
    if (type === 'anomaly-csv-download') {
      const fileName = `FarmX-Anomalies-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`;
      const csvData = convertToCSV(flattenAnomalyArrayForCsv(anomalyGeoJSON));
      downloadCSVFile(csvData || t('No data'), fileName);
    }
  }

  return (
    <>
      {!isMobile && blockId && !isPlants && selectedObjFromState.type === 'block' ? (
        <Button
          id="3d"
          title="3d view"
          size="large"
          type="primary"
          ghost={false}
          style={{ width: '40px', marginTop: '10px' }}
          icon={<FaCube style={{ marginTop: '5px' }} />}
          onClick={(d) => onClickButton(d.currentTarget.id)}
        />
      ) : null}
      {presMode === 'anomaly' && anomalyGeoJSON ? (
        <Button
          id="anomaly-csv-download"
          size="large"
          title="Download"
          type="primary"
          ghost={false}
          style={{ width: '40px', marginTop: '10px' }}
          icon={<FaDownload style={{ marginTop: '5px' }} />}
          onClick={(d) => onClickButton(d.currentTarget.id)}
        />
      ) : null}
      {!isBlockPlantView && (
      <Button
        id="satellite"
        size="large"
        title="Imagery"
        disabled={!ranchId}
        type={isImageryPanelVisible ? 'primary' : 'secondary'}
        ghost={false}
        style={{ width: '40px', marginTop: '10px' }}
        icon={<FaSatellite style={{ marginTop: '5px' }} />}
        onClick={(d) => {
          onClickButton(d.currentTarget.id);
          if (isImageryPanelVisible && sideBySideMapVisible) {
            activeScreen('fullScreen');
          }
        }}
      />
      )}
      <TemperatureOverlay
        value={weatherInfo.temperature.value}
        label={weatherInfo.temperature.label}
        isLoading={isWeatherLoading}
      />
      <WindOverlay
        value={weatherInfo.wind}
        windDirection={weatherInfo.windDirection}
        isLoading={isWeatherLoading}
      />
    </>
  );
}

MapControlButtons.propTypes = {
  isMobile: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
  anomalyGeoJSON: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  selectedImageryData: PropTypes.shape(),
  isBottomSheetVisible: PropTypes.bool,
  presMode: PropTypes.string.isRequired,
  isPlants: PropTypes.bool,
  setLayerOption: PropTypes.func,
  layerControl: PropTypes.shape({}).isRequired,
  sideBySideMapVisible: PropTypes.bool,
  activeScreen: PropTypes.func.isRequired,
  isBlockPlantView: PropTypes.bool,
};

MapControlButtons.defaultProps = {
  isMobile: false,
  isImageryPanelVisible: false,
  dispatchMap: null,
  selectedImageryData: null,
  isBottomSheetVisible: false,
  anomalyGeoJSON: {},
  isPlants: false,
  setLayerOption: null,
  sideBySideMapVisible: null,
  isBlockPlantView: null,
};
