import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form } from 'antd';
import { hooks } from 'farmx-redux-core';
import SensorControl from './SensorControl';
import { FORM_RULES, GEC_RANCH_WATERMELON_AUTO, TEMPERATURE_LABEL } from '../../../constants';
import './ruleSettingsPage.less';

const { requiredNumberField } = FORM_RULES;

const { useRanchBlockSelection } = hooks;

interface AutonomousSprinklerControlProps {
  selectedBlock: any;
  setSelectedSensor: any;
  selectedSensor: any;
  form: object | any;
  autoFillValues: any;
}

const AutonomousSprinklerControls: React.FC<AutonomousSprinklerControlProps> = (props: any) => {
  const {
    selectedSensor, form, setSelectedSensor, autoFillValues,
  } = props;
  const { t } = useTranslation();
  const [startingTemperature, setStartingTemperature] = useState(0);
  const [stoppingTemperature, setStoppingTemperature] = useState(0);
  const { temperature_units: temperatureUnit } = autoFillValues?.controls || {};
  const { selectedObjFromState } = useRanchBlockSelection();
  const { type, value }: any = selectedObjFromState || {};
  const ranchId = type === 'ranch' ? Number(value) : undefined;

  useEffect(() => {
    if (autoFillValues) {
      const {
        starting_temperature: startingTemperature,
        stopping_temperature: stoppingTemperature,
      } = autoFillValues?.controls || {};
      if (startingTemperature) setStartingTemperature(startingTemperature);
      if (stoppingTemperature) setStoppingTemperature(stoppingTemperature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFillValues, form]);

  return (
    <>
      <SensorControl
        form={form}
        setSelectedSensor={setSelectedSensor}
        sensor={selectedSensor}
        selectedRanch={ranchId}
        sensorTypes={['temperature_humidity']}
        formLabel="Reference Temperature Sensor"
      />
      <Form.Item
        className="font-bold"
        label={t('Starting Temperature')}
        name="starting_temperature"
        rules={requiredNumberField}
      >
        <div className="flex-space-between">
          <Input
            placeholder="0"
            value={startingTemperature}
            onChange={(d: any) => {
              form.setFieldsValue({ starting_temperature: d?.target?.value });
              setStartingTemperature(d?.target?.value);
            }}
          />
          <div className="margin-left-5">{temperatureUnit || TEMPERATURE_LABEL}</div>
        </div>
      </Form.Item>
      <Form.Item
        className="font-bold"
        label={t('Stopping Temperature')}
        name="stopping_temperature"
        rules={requiredNumberField}
      >
        <div className="flex-space-between">
          <Input
            placeholder="0"
            value={stoppingTemperature}
            onChange={(d: any) => {
              form.setFieldsValue({ stopping_temperature: d?.target?.value });
              setStoppingTemperature(d?.target?.value);
            }}
          />
          <div className="margin-left-5">{temperatureUnit || TEMPERATURE_LABEL}</div>
        </div>
      </Form.Item>
    </>
  );
};

export default AutonomousSprinklerControls;
