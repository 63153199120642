import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InputNumber, Typography,
} from 'antd';
import './CutoffPointsInput.less';

export const CutoffPointsInput = React.forwardRef((props, ref) => {
  const {
    id, label, onChange, value, addonText, setFocus,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="cutoff-points-input flex-row justify-content-space-between" key={id}>
      <div className="row-label">
        {t(label)}
      </div>
      <div className="input-wrap">
        <InputNumber
          ref={ref}
          min={0}
          max={100}
          precision={2}
          parser={(v) => v.replace(/[^0-9]/g, '')}
          formatter={(v) => `${v}%`}
          onChange={(v) => onChange({ id, value: v })}
          value={(value * 100).toFixed(2)}
        />
        <Typography.Text
          onClick={() => setFocus(ref)}
          className="extend-input"
        >
          {addonText}
        </Typography.Text>
      </div>
    </div>
  );
});

CutoffPointsInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number.isRequired,
  addonText: PropTypes.string,
  setFocus: PropTypes.func,
};

CutoffPointsInput.defaultProps = {
  label: '',
  onChange: () => { },
  addonText: '',
  setFocus: () => { },
};
