import React, { useState } from 'react';
import {
  Form, Alert, Modal, Button, Input, Typography,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { userApi } from 'farmx-api';

const { changePassword } = userApi;

export function ChangePasswordSettings(props) {
  const { t } = useTranslation();
  const [alertData, setAlertData] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    form,
    visible,
    handleOk,
    handleCancel,
  } = props;

  const submit = () => {
    // Perform operations like cheking password
    // Once everything is okay, close the modal or maybe call back
    form.submit();
    // handleOk();
  };

  async function onFinish(values) {
    const { currentPassword, newPassword } = values;
    if (currentPassword === newPassword) {
      setAlertData({
        message: t('New password can not be same as the old password.'),
        type: 'error',
      });
      return;
    }
    try {
      setLoading(true);
      const response = await changePassword(currentPassword, newPassword);
      if (response.status === 200) {
        form.resetFields();
        setAlertData(null);

        handleOk();
        setTimeout(() => {
          history.push('/logout');
        }, 1500);
      }
    } catch (error) {
      const { response } = error;
      const { status } = response;
      if (status === 400) {
        setAlertData({
          message: t('Incorrect password'),
          type: 'error',
        });
      } else {
        setAlertData({
          message: t('Failed to change password'),
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function renderAlert() {
    if (!alertData) return null;
    const { message, type } = alertData;
    return (
      <Form.Item
        wrapperCol={{ span: 28 }}
      >
        <Alert
          message={message}
          type={type}
          showIcon
        />
      </Form.Item>
    );
  }

  return (
    <>
      <Modal
        className="override-modal"
        centered
        closable
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            className="override-modal-btn override-modal-dismiss"
            key="back"
            onClick={handleCancel}
          >
            {t('Cancel')}
          </Button>,
          <Button
            className="override-modal-btn override-modal-confirm"
            key="submit"
            type="primary"
            onClick={submit}
          >
            {`${t('Save')}`}
          </Button>,
        ]}
      >
        <div className="override-container mobile-account-change-password">
          <Typography.Title level={3}>{t('Change Password')}</Typography.Title>
          <Form
            className="mobile-change-password-form"
            onFinish={onFinish}
            form={form}
          >
            {renderAlert()}
            <Form.Item
              name="currentPassword"
              className="mobile-account-settings-input"
              rules={[{
                required: true,
                message: t('Please input your current password'),
              }]}
            >
              <Input.Password
                placeholder={t('Current Password')}
                autoComplete="current-password"
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              className="mobile-account-settings-input"
              rules={[{
                required: true,
                message: t('Please input your new password'),
              }]}
            >
              <Input.Password
                placeholder={t('New Password')}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              className="mobile-account-settings-input"
              dependencies={['newPassword']}
              rules={[{
                required: true,
                message: t('Please enter your new password again'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(Error('The two passwords that you entered do not match!'));
                },
              })]}
            >
              <Input.Password
                placeholder={t('Confirm New Password')}
                autoComplete="new-password"
              />
            </Form.Item>
          </Form>
          {loading && <LoadingOutlined />}
        </div>
        <Typography.Text type="warning">{t('Changing password will log you out.')}</Typography.Text>
      </Modal>
    </>
  );
}

ChangePasswordSettings.defaultProps = {
  form: null,
  visible: false,
};

ChangePasswordSettings.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.shape(PropTypes.object),
};
