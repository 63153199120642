import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form } from 'antd';
import { useRenderReusableControls } from 'helper/reusableControls';
import { useUserTimeFormat } from 'helper/commonHooks';
import { FORM_RULES } from '../../../constants';
import './ruleSettingsPage.less';

const { requiredField, requiredEndDateValidation } = FORM_RULES;

interface AutonomousDefaultControlProps {
}

const AutonomousDefaultControls: React.FC<AutonomousDefaultControlProps> = () => {
  const { t } = useTranslation();
  const userTimeFormat = useUserTimeFormat();
  const now = moment();
  const endDate = moment(now).add(2, 'hours');
  const { renderDatePicker } = useRenderReusableControls(t);

  return (
    <>
      <div className="flex-space-between margin-15">
        <Form.Item
          name="starting_time_window"
          label={t('Start')}
          rules={requiredField}
          className="font-bold form-item-width-100"
        >
          {renderDatePicker(now, userTimeFormat.unitsTime)}
        </Form.Item>

        <Form.Item
          name="ending_time_window"
          label={t('End')}
          dependencies={['starting_time_window']}
          rules={requiredEndDateValidation}
          className="font-bold form-item-width-100"
        >
          {renderDatePicker(endDate, userTimeFormat.unitsTime)}
        </Form.Item>
      </div>
    </>
  );
};

export default AutonomousDefaultControls;
