import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { recommendationApi } from 'farmx-api';
import { useTranslation } from 'react-i18next';
import './table.less';
import { isEqual } from 'lodash';

const rowDefinitions = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'NDRE',
    dataIndex: 'ndre',
    key: 'ndre',
  },
  {
    title: 'NDVI',
    dataIndex: 'ndvi',
    key: 'ndvi',
  },
  {
    title: 'Canopy Cover (m²)',
    dataIndex: 'canopySize',
    key: 'canopySize',
  },
  {
    title: 'Missing / Dead',
    dataIndex: 'missingDead',
    key: 'missingDead',
  },
  {
    title: 'Unproductive',
    dataIndex: 'undeveloped',
    key: 'undeveloped',
  },
  {
    title: 'Healthy',
    dataIndex: 'normal',
    key: 'normal',
  },
];

function DroneAnalyticsTable({ recommendation, dispatchMap, isRanchLevel }) {
  const droneBlockImageryAnalyticsStr = JSON.stringify(recommendation
    ?.map((data) => data?.droneBlockImageryAnalytics ?? null));
  const droneBlockImageryAnalyticsPreviousStr = JSON.stringify(recommendation
    ?.map((data) => data?.droneBlockImageryAnalyticsPrevious ?? null));
  const { t } = useTranslation();
  const [blockAnalytics, setBlockAnalytics] = useState([]);
  const [blockAnalyticsPrevious, setBlockAnalyticsPrevious] = useState([]);

  // To set block analytics
  useEffect(() => {
    if (dispatchMap && blockAnalytics) {
      dispatchMap({
        type: 'setBlockAnalytics',
        payload: blockAnalytics,
      });
    }
  }, [blockAnalytics, dispatchMap]);

  useEffect(() => {
    const fetchDataForAnalytics = async () => {
      try {
        const droneBlockImageryAnalytics = JSON.parse(droneBlockImageryAnalyticsStr);
        const droneBlockImageryAnalyticsPrevious = JSON
          .parse(droneBlockImageryAnalyticsPreviousStr);
        if (droneBlockImageryAnalytics) {
          const currentAnalytics = (await Promise.all(
            droneBlockImageryAnalytics
              .map((id) => (id && recommendationApi.retrieveBlockDroneImageryAnalytics(id)))
          )).flatMap((response) => response?.data);

          if (!isEqual(blockAnalytics, currentAnalytics)) {
            setBlockAnalytics(currentAnalytics);
          }
        }
        // previous value
        if (droneBlockImageryAnalyticsPrevious) {
          const prevAnalytics = (await Promise.all(
            droneBlockImageryAnalyticsPrevious
              .map((id) => (id && recommendationApi.retrieveBlockDroneImageryAnalytics(id)))
          )).flatMap((response) => response?.data);

          if (!isEqual(blockAnalyticsPrevious, prevAnalytics)) {
            setBlockAnalyticsPrevious(prevAnalytics);
          }
        }
      } catch (error) {
        console.error('Error fetching analytics:', error);
      }
    };
    fetchDataForAnalytics();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droneBlockImageryAnalyticsStr, droneBlockImageryAnalyticsPreviousStr]);

  function calculateAnalytics(blockDroneAnalytics) {
    if (blockDroneAnalytics?.length) {
      return blockDroneAnalytics.map((item) => {
        if (!item) return null;

        const { plantHealthClassificationCount } = item;
        const { dead, missing } = plantHealthClassificationCount || {};
        return {
          ...item,
          ...plantHealthClassificationCount,
          missingDead: missing,
        };
      });
    } return [];
  }

  function getDataValue(dataSource, dataKey) {
    if (dataSource) {
      if (dataSource === null || (dataSource[dataKey] === undefined
        && dataSource?.plantHealthClassificationCount[dataKey] === undefined)) return 'None';
      if (dataKey === 'date') return <Moment format="YYYY-MM-DD">{dataSource[dataKey]}</Moment>;
      if (dataKey === 'canopySize') {
        const canopySize = parseFloat(dataSource[dataKey]);
        return isNaN(canopySize) ? 'None' : canopySize.toFixed(0);
      }
      if (dataSource[dataKey] === 'NaN') return 'None';
      return dataSource[dataKey] || dataSource?.plantHealthClassificationCount[dataKey] || 'None';
    }
    return 'None';
  }

  const renderCell = (row, column) => {
    let cellValue;
    if (column.dataIndex === 'date') {
      cellValue = row[column.dataIndex] ? (
        <Moment format="YYYY-MM-DD">{row[column.dataIndex]}</Moment>
      ) : 'None';
    } else {
      cellValue = row[column.dataIndex] === 'NaN'
      || !row[column.dataIndex] ? 'None' : row[column.dataIndex];
    }
    return <td key={column.key} align="right">{cellValue}</td>;
  };

  const analytics = calculateAnalytics(blockAnalytics);
  const analyticsPrevious = calculateAnalytics(blockAnalyticsPrevious);

  if (analytics.length > 0) {
    const recommendationMap = new Map(recommendation.map((rec) => [rec.block, rec]));

    analytics.forEach((analytic, index) => {
      const recommendations = recommendationMap.get(analytic.block);
      if (recommendations && recommendations.blockName) {
        analytics[index] = { ...analytic, blockName: recommendations.blockName };
      }
    });
    analytics.sort((a, b) => (a.blockName || '').localeCompare(b.blockName || ''));
  }

  return (
    <>
      <table className="table table-striped">
        <thead>
          {!isRanchLevel ? (
            <tr>
              <th align="left">{t('Block Analytics')}</th>
              <th align="right">{t('Previous')}</th>
              <th align="right">{t('Current')}</th>
            </tr>
          ) : (
            <tr>
              <th>Block</th>
              {rowDefinitions.map((column) => (
                <th
                  key={column.key}
                  align="center"
                  className={`${column.key === 'canopySize'
                  || column.key === 'missingDead' ? 'tableHeader' : ''}`}
                >
                  {t(column.title)}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {!isRanchLevel ? rowDefinitions.map((row) => (
            <tr key={row.key}>
              <td>{row.title}</td>
              <td align="right">{getDataValue(analyticsPrevious?.[0], row.dataIndex)}</td>
              <td align="right">{getDataValue(analytics?.[0], row.dataIndex)}</td>
            </tr>
          )) : null}
          {isRanchLevel && (analytics.length > 0 ? (
            analytics.map((row) => (
              <tr key={row.id}>
                <td>{row.blockName}</td>
                {rowDefinitions.map((column) => (
                  <td
                    key={column.key}
                    align="center"
                    className={`${column.key === 'date' ? 'tableData' : ''}`}
                  >
                    {renderCell(row, column)}
                  </td>
                ))}
              </tr>
            ))) : (
              <tr>
                <td align="center">None</td>
                {rowDefinitions.map((column) => (
                  <td
                    key={column.id}
                    align="center"
                  >
                    None
                  </td>
                ))}
              </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

DroneAnalyticsTable.propTypes = {
  recommendation: PropTypes.arrayOf(PropTypes.shape({
    droneBlockImageryAnalytics: PropTypes.number,
    droneBlockImageryAnalyticsPrevious: PropTypes.number,
  })).isRequired,
  dispatchMap: PropTypes.func,
  isRanchLevel: PropTypes.bool,
};

DroneAnalyticsTable.defaultProps = {
  dispatchMap: null,
  isRanchLevel: false,
};

export default DroneAnalyticsTable;
