import React from 'react';
import ContentLoader from 'react-content-loader';

export default function RecommendationLoader() {
  return (
    <>
      <ContentLoader
        height={1050}
        width={870}
        speed={2}
        className="page-container"
      >
        <circle x="10" y="5" cx="25" cy="25" r="25" />
        <rect x="60" y="8" rx="3" ry="3" width="800" height="30" />
        <rect x="10" y="60" rx="3" ry="3" width="870" height="178" />
        <rect x="10" y="250" rx="3" ry="3" width="870" height="270" />
        <rect x="10" y="550" rx="3" ry="3" width="870" height="72" />
        <rect x="10" y="650" rx="3" ry="3" width="400" height="50" />
        <rect x="450" y="650" rx="3" ry="3" width="420" height="50" />
      </ContentLoader>
    </>
  );
}
