import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const AutoControlButtons = (props) => {
  const {
    options, autoControl, onClick, disabled
  } = props;
  const [selected, setSelected] = useState();
  const onChange = (e) => {
    setSelected(e.target.value);
    onClick(e.target.value);
  };

  useEffect(() => {
    setSelected(autoControl);
  }, [autoControl]);

  return (
    <Radio.Group disabled={disabled} options={options} onChange={onChange} value={selected} />
  );
};

AutoControlButtons.defaultProps = {
  options: [],
  autoControl: false,
  disabled: false,
  onClick: () => { },
};

AutoControlButtons.propTypes = {
  options: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }),
  autoControl: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AutoControlButtons;
