import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
  Button, Tooltip, Typography, Switch, Drawer, Descriptions,
  notification, Select,
} from 'antd';
import { icons, sensorTitles } from 'farmx-web-ui';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import { SensorDetailBody } from './SensorDetailBody';
import { BlockRecommendationSummary } from '../home/BlockRecommendationSummary';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import 'react-spring-bottom-sheet/dist/style.css';
import { getBlockArea } from '../../../helper/block';
import { getHumanReadableAnomalyType } from '../../../helper/common';
import { MapHeader } from '../components/MapHeader';
import { EditableHeader } from '../components/EditableHeader';
import { ImageryAnomalyDetailPage } from '../home/ImageryAnomalyDetailPage';
import { RecommendationActionButtons } from '../home/RecommendationActionButtons';
import { PercentAvailableWaterGraph } from '../home/PercentAvailableWaterGraph';
import { SensorSummaryList } from '../list/SensorSummaryList';
import { IconTitle } from '../list/IconTitle';
import PlantsDetail from './Plants/PlantsDetail';
import DrawerImageryControl from './DrawerImageryControl';
import DroneAnalyticsTable from '../home/DroneAnalyticsTable';
import { setBlockCropType, toTitleCase } from '../../../helper/mapHelper';
import ReactContentLoader from '../components/ContentLoader';
import './map.css';

// This will be replaced with correct logic
const headerHeight = 60;
const isApp = matchMedia('(display-mode: standalone)').matches;
const safeAreaTop = isApp ? 48 : 0;
const safeAreaBottom = isApp ? 34 : 0;
const padding = 20;
const drawerHeight = window.innerHeight - 50;

const cropTypes = {
  almond: 'Almond',
  grape: 'Grape',
  pistachio: 'Pistachio',
  walnut: 'Walnut',
  none: 'None',
};

const {
  selectNewRanchById,
  selectNewBlockById,
  selectNewBlocksForRanchId,
  selectRecommendationsForBlocks,
  selectNewBlocksLoadingState,
  selectPumpControllersForBlockIds,
} = selectors;

const { loadNewBlockList } = actions;

const { useUnits, useEntities } = hooks;

function getTitleForDrawer(selectedFeature, expandedGeoJSON) {
  if (selectedFeature && selectedFeature?.properties) {
    return selectedFeature.properties.name;
  }
  if (expandedGeoJSON?.features && expandedGeoJSON.features.length) {
    return expandedGeoJSON.features[0].properties.name;
  }
  return selectedFeature?.properties || expandedGeoJSON?.features ? 'Details' : '';
}

export default function ShowDetails({
  presentationMode,
  isBottomSheetVisible,
  selectedFeature,
  dispatchMap,
  sheetRef,
  bottomSheetType,
  isImageryPanelVisible,
  selectedObjFromState,
  selectedImageryData,
  isExpandBtnVisible,
  expandedGeoJSON,
  ranchId,
  blockId,
  isMobile,
  onClick,
  editable,
  childComponent,
  isLeft,
  onClickImagery,
  activeImagery,
  selected,
  layerOption,
  isBlockPlantView,
  dispatchMapLeft,
  dispatchMapRight,
  stateArg,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCurrentWaterDataAvailable, setIsCurrentWaterDataAvailable] = useState(true);
  const isRanch = selectedObjFromState.type === 'ranch';
  const ranchBlocks = useSelector((state) => selectNewBlocksForRanchId(state, ranchId));
  const blockIds = isRanch ? ranchBlocks.map((d) => d?.id) : [blockId];
  const ranch = useSelector((state) => selectNewRanchById(state, ranchId));
  const block = useSelector((state) => selectNewBlockById(state, blockId));
  const [localBlockObj, setLocalBlockObj] = useState(block);
  const [cropTypeText, setCropTypeText] = useState('none');
  const [isEditMode, setIsEditMode] = useState(false);

  const pumpList = useSelector((state) => (
    selectPumpControllersForBlockIds(state, blockIds, true)
  ));
  const ranchArea = getBlockArea(ranch);
  const blockArea = getBlockArea(block);
  const getUserUnits = useUnits();
  const { activeEntityId } = useEntities();
  const userRanchArea = getUserUnits(ranchArea, 'square_meters', 'area') || { value: '', label: '' };
  const userBlockArea = getUserUnits(blockArea, 'square_meters', 'area') || { value: '', label: '' };
  const displayRanchArea = `${userRanchArea.value} ${userRanchArea.label}`;
  const displayBlockArea = `${userBlockArea.value} ${userBlockArea.label}`;
  // Declared variables with conditions to avoid repeating codes
  const isSensor = bottomSheetType === 'sensor';
  const isSettings = bottomSheetType === 'settings';
  const isAnomaly = bottomSheetType === 'anomaly';
  const isAnomalyView = presentationMode === 'anomaly';
  const isPlants = presentationMode === 'plants';
  const isAllRanch = ['All Ranches', 'entity'].includes(selectedObjFromState?.type);
  const CONDITION_CHECK_1 = !isSettings && !isAnomaly && !selectedFeature && isBottomSheetVisible
    && (!isImageryPanelVisible || !isMobile);
  const CONDITION_CHECK_2 = !isSettings && !isAnomaly && !isSensor && !selectedFeature
    && !isImageryPanelVisible;
  const CONDITION_CHECK_3 = (isSettings && selectedImageryData.visible);
  const CONDITION_CHECK_4 = !isSettings && isImageryPanelVisible;
  const CONDITION_CHECK_5 = isAnomaly && selectedFeature;
  const CONDITION_CHECK_6 = (!childComponent?.detail || !!selectedFeature?.properties
    || (!!selectedFeature && !!isPlants) || isBlockPlantView);
  const CONDITION_CHECK_7 = (childComponent?.detail && !selectedFeature?.properties
    && (!selectedFeature || !isPlants));
  // To render imagery components conditional for plants view,
  // when imagery panel is visible under mobile view
  const CONDITION_CHECK_8 = isPlants && isImageryPanelVisible && isMobile;
  const CONDITION_CHECK_9 = isPlants && selectedFeature && !isBlockPlantView && !isSensor;
  const minWidth = isBottomSheetVisible ? '400px' : '0px';

  const recommendation = useSelector((state) => selectRecommendationsForBlocks(state, blockIds))
    ?.filter((d) => d && d?.type === 'ground_mission');
  const loadingBlocks = useSelector((state) => selectNewBlocksLoadingState(state));

  // Set edit mode false when block change
  useEffect(() => {
    if (isEditMode && isBottomSheetVisible) {
      setIsEditMode(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, isBottomSheetVisible]);

  const { identifier, type, block: selectedBlockId } = selectedFeature?.properties || {};
  const filteredPump = pumpList.filter((d) => d?.identifier === identifier && d?.type === type)
    ?.reduce((a, b) => {
      const obj = a;
      obj[b.identifier] = b;
      return obj;
    }, {});
  const sensorDetails = useSelector(
    (state) => selectors.selectSensor(state, type, identifier),
  ) || {};
  const updatedSelectedFeature = { ...selectedFeature };
  if (selectedFeature?.properties) {
    updatedSelectedFeature.properties.blockId = filteredPump[selectedFeature
      ?.properties?.identifier]?.blockId;
  }

  if (sensorDetails && !selectedBlockId && updatedSelectedFeature?.properties) {
    updatedSelectedFeature.properties.blockId = sensorDetails?.block;
  }

  function renderRanchInfo(obj) {
    if (!obj) {
      return null;
    }
    return (
      <div className="flex-column margin-top-10 ranch-map-detail sensor-detail-header">
        <IconTitle
          title={t('Summary')}
          iconName="ranch"
        />
        <Descriptions>
          <Descriptions.Item label={t('Crop')}>{obj && obj.crop}</Descriptions.Item>
          <Descriptions.Item label={t('Area')}>{displayRanchArea}</Descriptions.Item>
        </Descriptions>
        <SensorSummaryList />
      </div>
    );
  }

  function onSetCropTypeButtonClick() {
    setBlockCropType({ blockId, crop: cropTypeText }, (success, response) => {
      if (success) {
        setLocalBlockObj(response.data);
        dispatch(loadNewBlockList(activeEntityId));
        notification.success({
          message: t(`Crop type set to "${response?.data?.crop || cropTypeText}" successfully`),
        });
        setCropTypeText('');
        setIsEditMode(false);
      } else {
        notification.error({
          message: t('Failed to set the crop type'),
        });
        setCropTypeText('');
        setIsEditMode(false);
      }
    });
  }

  function renderSetCropTypeControls() {
    return (
      <>
        <Select
          value={cropTypeText}
          onChange={(d) => setCropTypeText(d)}
          options={Object.keys(cropTypes).map((d) => ({ value: d, label: t(cropTypes[d])}))}
          className="editable-elem-width"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          popupClassName="map-anomaly-select-option"
        />
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          disabled={!cropTypeText?.length}
          onClick={() => onSetCropTypeButtonClick()}
          icon={<FaCheck className="button-icon" />}
        />
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          onClick={() => setIsEditMode(false)}
          icon={<FaTimes className="button-icon" />}
        />
      </>
    );
  }

  function onDivClick() {
    setIsEditMode(true);
    if (localBlockObj?.id === block?.id) setCropTypeText(localBlockObj?.crop);
    if (!localBlockObj || localBlockObj?.id !== block?.id) setCropTypeText(block?.crop);
  }

  function getTitleText() {
    let textStr = 'none';
    if (localBlockObj?.id === block?.id) textStr = localBlockObj?.crop;
    if (!localBlockObj || (localBlockObj?.id !== block?.id)) textStr = block?.crop;
    return toTitleCase(textStr);
  }

  function renderBlockInfo(obj) {
    if (obj == null) return null;
    return (
      <>
        <div className="flex-column margin-top-10 block-map-detail">
          <div className="flex-row map-header">
            {!isEditMode && (
              <EditableHeader
                onClick={onDivClick}
                headerText={(!loadingBlocks?.loading || localBlockObj?.crop)
                  ? getTitleText() : <ReactContentLoader type="cropTypeText" />}
                editable={!loadingBlocks?.loading && !isEditMode}
              />
            )}
            {isEditMode ? renderSetCropTypeControls() : null}
          </div>
          <Typography.Text>{displayBlockArea}</Typography.Text>
          <BlockRecommendationSummary block={obj} />
          {obj.id && isCurrentWaterDataAvailable && (
          <div className="percent-available-water-graph-container">
            <div>
              <Typography.Text>{t('Percent Available Water')}</Typography.Text>
            </div>
            <PercentAvailableWaterGraph
              blockId={obj.id}
              chartOptions={{
                pointWidth: 15,
                height: 200,
              }}
              onLoaded={(d) => setIsCurrentWaterDataAvailable(d)}
            />
          </div>
          )}
        </div>
        <div className="flex-column margin-top-10 ranch-map-detail sensor-detail-header">
          <IconTitle
            title={t('Summary')}
            iconName="block"
          />
          <SensorSummaryList />
        </div>
      </>
    );
  }

  function bottomSheetHeaderTooltip(text, subText) {
    return (
      <Tooltip // 28 is maximum text length to display.
        title={text?.length > 28 ? text : ''}
        trigger="click"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <div className="sensor-header-name sensor-header-name-ellipsis">{text}</div>
        <div className="sensor-header-type mobile-sensor-header-align">{t(subText || '')}</div>
      </Tooltip>
    );
  }

  function getAnomalyHeader() {
    if (!selectedFeature?.properties || !selectedFeature.name) {
      return bottomSheetHeaderTooltip('Unknown');
    }
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} -
      ${t(getHumanReadableAnomalyType(selectedFeature.properties.anomaly_type))}
      [${t(selectedFeature.properties.anomaly_severity)}]`
      : null;
    return bottomSheetHeaderTooltip(anomalyHeader);
  }

  function renderImageryToggle() {
    return (
      <div className="satellite-imagery-toggle-switch">
        <Switch
          checked={selectedImageryData.visible}
          onChange={(v) => {
            dispatchMap({
              type: 'setSelectedImageryData',
              payload: {
                visible: v,
                data: selectedImageryData.data,
              },
            });
          }}
        />
        <div className="margin-left-10 text-imagery">
          <Typography.Text>
            {t('Show Imagery')}
          </Typography.Text>
        </div>
      </div>
    );
  }

  const layerOptionsTitle = () => (
    <div className="mobile-layer-options-title">
      <Typography.Title level={3}>
        {t('Map Layers')}
      </Typography.Title>
    </div>
  );

  const plantDetailTitle = () => (
    <div className={isMobile ? 'irrigation-status-title' : 'plants-header'}>
      {t('Plant Detail')}
    </div>
  );

  function renderRanchBlockHeader(type) {
    if (type === 'ranch') {
      return CONDITION_CHECK_2 && ranch && bottomSheetHeaderTooltip(ranch.name);
    }
    if (type === 'block') {
      return (CONDITION_CHECK_2 || isBlockPlantView)
      && block && bottomSheetHeaderTooltip(block.name);
    }
    return null;
  }

  function bottomSheetHeader() {
    const { name, type } = (selectedFeature?.properties) || {};
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} -
      ${t(getHumanReadableAnomalyType(selectedFeature.properties.anomaly_type))}
      [${t(selectedFeature.properties.anomaly_severity)}]`
      : null;

    return (
      <div className={selectedFeature?.type !== 'Feature' || isImageryPanelVisible
        ? 'bottom-sheet-header-container'
        : 'bottom-sheet-header-container bottom-sheet-header-container-sensor-details'}
      >
        <div className="sensor-detail-header">
          <div className="flex-row">
            {isSensor && !!selectedFeature?.properties
              && !isSettings && !isImageryPanelVisible
              && name && type ? (
                <>
                  <div className="sensor-header-icon-block mobile-sensor-icon">
                    <i className={icons[type] ? icons[type] : icons.cavalier} />
                  </div>
                  {bottomSheetHeaderTooltip(name, sensorTitles[type]?.singular)}
                </>
              )
              : (
                <div>
                  {isImageryPanelVisible
                    ? null
                    : (
                      <div className="sensor-header-type">
                        {t(sensorTitles[type]?.singular || '')}
                      </div>
                    )}
                </div>
              )}
            {CONDITION_CHECK_6 || isSensor || isPlants
              ? renderRanchBlockHeader(selectedObjFromState?.type)
              : (
                layerOptionsTitle()
              )}
            {CONDITION_CHECK_5 && !isImageryPanelVisible && (
              // show ellipses when character length is above 35
              <MapHeader
                headerText={anomalyHeader?.length > 35
                  ? <Tooltip title={anomalyHeader}>{anomalyHeader}</Tooltip>
                  : anomalyHeader}
                onClick={onClick}
                editable={editable}
              />
            )}

            {/* {CONDITION_CHECK_4 && renderImageryToggle()} */}
            {(CONDITION_CHECK_4 && !isSensor
              && !isImageryPanelVisible && CONDITION_CHECK_6) && layerOptionsTitle()}

            {CONDITION_CHECK_9 && plantDetailTitle()}
            {CONDITION_CHECK_8 && layerOptionsTitle()}
          </div>
        </div>
        <div className="bottom-sheet-header">
          <Button
            shape="circle"
            icon={<FaTimes />}
            onClick={() => {
              if (onClick) onClick({ type: 'close' });
              dispatchMap({
                type: 'setSelectedFeatureWithType',
                payload: {
                  selectedFeature: undefined,
                  bottomSheetType: undefined,
                  showBottomSheet: !!CONDITION_CHECK_3,
                },
              });
            }}
          />
        </div>
      </div>
    );
  }

  function renderSatelliteImagery() {
    return ((CONDITION_CHECK_4 && (layerOption && selected?.layersControl?.layer?.side !== 'left')))
      && (
        <div className="minus-margin-top-10">
          <DrawerImageryControl
            activeImagery={activeImagery}
            onClick={onClickImagery}
            selected={selected}
          />
          <SatelliteImageryWrapper
            ranchId={ranchId}
            dispatchMapLeft={dispatchMapLeft}
            dispatchMapRight={dispatchMapRight}
            childComponent={childComponent.tab}
            isLeft={isLeft}
            stateArg={stateArg}
          />
        </div>
      );
  }

  function onDismissBottomSheet() {
    dispatchMap({
      type: 'setIsBottomSheetVisible',
      payload: false,
    });
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
    dispatchMap({
      type: 'setIsImageryPanelVisible',
      payload: isImageryPanelVisible,
    });
  }

  function onClosesDrawer() {
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
  }

  function getSnapPoints(maxHeight, height) {
    if (isSettings) {
      return [maxHeight - (headerHeight + safeAreaTop)];
    }
    // 490 min-height for the default view of anomaly details in bottom-sheet drawer
    const anomalyDetail = isAnomaly ? 490 : 310;
    // 310 & 250 min-height for the default view of bottom-sheet drawer
    const layerOptions = childComponent?.detail ? 320 : 250;
    const previewSize = maxHeight - ((maxHeight
      - ((selectedFeature?.type === 'Feature' ? anomalyDetail : layerOptions))) - safeAreaBottom);
    if (height > previewSize && isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: false,
      });
    } else if (height <= previewSize && !isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: true,
      });
    }
    return [
      maxHeight - (headerHeight + safeAreaTop),
      previewSize,
    ];
  }

  function renderRanchBlockInfo(type) {
    if (type === 'ranch') {
      return ((CONDITION_CHECK_1 && !isBlockPlantView) && renderRanchInfo(ranch));
    }
    if (type === 'block') {
      return ((CONDITION_CHECK_1 && !isBlockPlantView && !isSensor)
      && block && renderBlockInfo(block));
    }
    return null;
  }

  const renderDetails = () => (
    <div className={!isMobile ? 'bottomsheet-block desktop-sidebar-details' : ''}>
      {isSensor && (!isImageryPanelVisible || !isMobile)
        && !!selectedFeature?.properties && (
          <SensorDetailBody
            sensorProperties={updatedSelectedFeature?.properties}
            renderDefault={false}
            showGraph
          />
      )}
      {renderRanchBlockInfo(selectedObjFromState?.type)}

      {CONDITION_CHECK_5 && (
        <div className={!isMobile && 'desktop-imagery-anomaly'}>
          <ImageryAnomalyDetailPage
            propAnomaly={selectedFeature}
            propBlockId={blockId || selectedFeature?.properties?.block}
            showMap={false}
            showActionButton={!isMobile}
          />
        </div>
      )}

      {CONDITION_CHECK_9 ? (
        <div className="desktop-imagery-anomaly padding-10">
          <PlantsDetail plantsData={selectedFeature} />
        </div>
      ) : null}

      {/* This will be refactor in future */}
      {(isBlockPlantView && !isSensor)
      && (
        <>
          <div className="percent-available-water-graph-container
              block-analytics-table margin-top-10"
          >
            <DroneAnalyticsTable
              recommendation={recommendation}
              dispatchMap={dispatchMap}
              isRanchLevel={isRanch}
            />
          </div>
        </>
      )}
    </div>
  );

  const renderDrawerTitle = (subText) => (
    <div className={!childComponent?.detail || isBlockPlantView
      ? 'sensor-detail-header map-desktop-sensor-header-detail'
      : 'sidebar-overlays-header sensor-detail-header map-desktop-sensor-header-detail'}
    >
      {CONDITION_CHECK_6
        ? (
          <div className="flex-row">
            {isSensor && !!selectedFeature?.properties
          && !isSettings
          && selectedFeature.properties.name && selectedFeature.properties.type ? (
            <>
              <div className="sensor-header-icon-block">
                <i className={icons[selectedFeature.properties.type]
                  ? icons[selectedFeature.properties.type] : icons.cavalier}
                />
              </div>
              <div className="sensor-header-sub-text">
                <div className="sensor-header-type">{t(subText || '')}</div>
              </div>
            </>
              ) : (
                <div>
                  <div className="sensor-header-type">{t(subText || '')}</div>
                </div>
              )}
            <div className="map-desktop-sensor-header">
              <Typography.Text>
                <strong>
                  {!isAnomaly && selectedFeature && getTitleForDrawer(
                    selectedFeature, expandedGeoJSON,
                  )}
                  <div className="map-desktop-header-title">
                    {CONDITION_CHECK_5 && (
                    <MapHeader
                      headerText={getAnomalyHeader(selectedFeature)}
                      onClick={onClick}
                      editable={editable}
                    />
                    )}

                    {selectedObjFromState.type === 'block' ? isBottomSheetVisible
                  && (CONDITION_CHECK_1 || isBlockPlantView) && block?.name
                      : selectedObjFromState.type === 'ranch' && isBottomSheetVisible
                  && CONDITION_CHECK_1 && ranch?.name}
                  </div>

                  {CONDITION_CHECK_9 && plantDetailTitle()}
                </strong>
              </Typography.Text>
            </div>
          </div>
        )
        : (
          <div>
            <Typography.Title level={3}>
              {t('Map Layers')}
            </Typography.Title>
          </div>
        )}
      <div className="map-desktop-bottom-header-button bottom-sheet-header">
        <Button
          shape="circle"
          icon={<FaTimes />}
          onClick={() => {
            if (onClick) onClick({ type: 'close' });
            onClosesDrawer();
          }}
        />
      </div>
    </div>
  );

  const renderPlantDetail = () => (
    <>
      {CONDITION_CHECK_9 && isImageryPanelVisible ? (
        <div className="padding-10">
          <PlantsDetail plantsData={selectedFeature} />
        </div>
      ) : null}
    </>
  );

  return (
    <>
      {(isMobile && !isAllRanch) ? (
        <BottomSheet
          ref={sheetRef}
          open={isBottomSheetVisible}
          blocking={false}
          onDismiss={() => onDismissBottomSheet()}
          defaultSnap={({ snapPoints }) => snapPoints[-1]}
          snapPoints={({ maxHeight, height }) => getSnapPoints(maxHeight, height)}
          header={bottomSheetHeader()}
          scrollLocking={false}
          onClick={() => {
            if (!isAnomaly && !childComponent?.detail) {
              sheetRef.current
                .snapTo(({ maxHeight }) => maxHeight - (headerHeight + safeAreaTop));
            }
          }}
          footer={isAnomaly && (
          <RecommendationActionButtons
            blockId={blockId}
            recommendation={selectedFeature}
          />
          )}
          className={isAnomaly && 'anomaly-bottom-sheet-height'}
        >
          {!isImageryPanelVisible && (
            <div className={isAnomaly && isMobile ? 'anomaly-bottomsheet-block'
              : 'bottomsheet-block'}
            >
                {CONDITION_CHECK_6 && renderDetails()}
            </div>
          )}
          <>
            {renderPlantDetail()}
            {CONDITION_CHECK_7 && childComponent?.detail()}
            {renderSatelliteImagery()}
          </>
        </BottomSheet>
      ) : null}
      {(!isMobile && !isAllRanch) && (
      <Drawer
        className={isAnomaly ? 'desktop-anomaly-drawer drawer-desktop' : 'drawer-desktop'}
        style={{ marginTop: '50px', maxHeight: `${drawerHeight}vh`, minWidth }}
        title={renderDrawerTitle(sensorTitles[selectedFeature?.properties?.type]?.singular)}
        placement="right"
        closable={false}
        visible={isBottomSheetVisible}
        key="right"
        mask={false}
        width={400}
      >
        {CONDITION_CHECK_6 && renderDetails()}
        {CONDITION_CHECK_7 && childComponent?.detail()}
        {childComponent?.detail1 && childComponent?.detail1()}
      </Drawer>
      )}
    </>
  );
}

ShowDetails.propTypes = {
  isMobile: PropTypes.bool,
  presentationMode: PropTypes.string,
  isBottomSheetVisible: PropTypes.bool,
  selectedFeature: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  sheetRef: PropTypes.shape(),
  bottomSheetType: PropTypes.string,
  isImageryPanelVisible: PropTypes.bool,
  selectedImageryData: PropTypes.shape(),
  isExpandBtnVisible: PropTypes.bool,
  expandedGeoJSON: PropTypes.shape(),
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  childComponent: PropTypes.shape(),
  isLeft: PropTypes.bool,
  onClickImagery: PropTypes.func.isRequired,
  activeImagery: PropTypes.bool.isRequired,
  selected: PropTypes.shape({}).isRequired,
  layerOption: PropTypes.bool.isRequired,
  isBlockPlantView: PropTypes.string,
  dispatchMapLeft: PropTypes.func,
  dispatchMapRight: PropTypes.func,
  prevSelection: PropTypes.shape({}).isRequired,
  stateArg: PropTypes.shape({}),
};

ShowDetails.defaultProps = {
  isMobile: false,
  presentationMode: null,
  isBottomSheetVisible: false,
  selectedFeature: null,
  dispatchMap: null,
  sheetRef: null,
  bottomSheetType: '',
  isImageryPanelVisible: false,
  selectedImageryData: null,
  isExpandBtnVisible: false,
  expandedGeoJSON: null,
  onClick: null,
  editable: false,
  childComponent: null,
  isLeft: true,
  isBlockPlantView: '',
  dispatchMapLeft: null,
  dispatchMapRight: null,
  stateArg: null,
};
