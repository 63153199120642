import { hooks } from 'farmx-redux-core';
import { isMobile } from 'react-device-detect';
import { roundToDecimalPlaces } from '../../../helper/anomalyHelper';
import { getValueByUserUnit, parseDuration, parseWater } from '../../../helper/common';
import { getAverageAndSum } from '../../../helper/recommendationHooks';
import { SUMMARY_GRAPH_BAR_COLOR } from '../../../constants';

// Key for ETc, ET0, and Kc labels
const dataKeyConfig = {
  etcForecastDaily: 'ETc',
  kcDaily: 'Kc',
  et0ForecastDaily: 'ET0',
};

function getBarGraphConfig(chartData, label) {
  const options = {
    title: null,
    chart: {
      type: 'column',
      // 75 and 25 for maintaining margin around x-axis label and chart card container.
      marginBottom: isMobile ? 75 : 25,
      marginTop: 20,
      inverted: false,
    },
    tooltip: {
      formatter: () => false,
    },
    plotOptions: {
      series: {
        borderRadius: '5px',
        groupPadding: 0,
        stacking: 'normal',
      },
      column: {
        dataLabels: {
          enabled: true,
          inside: false,
          color: 'grey',
          format: `{y} ${label}`,
          style: {
            fontWeight: 'bold',
            fontSize: isMobile ? '11px':'14px',
          }
        }
      },
    },
    xAxis: chartData?.xAxis,
    yAxis: chartData?.yAxis,
    series: chartData?.series,
    credits: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    lang: {
      noData: !chartData?.series?.length ? 'No Data' : ''
    }
  };

  return options;
}

function getValidValue(value) {
  let validValue = 0;
  if (value && isFinite(value) && !isNaN(validValue)) validValue = value;
  return validValue;
}

export const prepareSummaryBarGraphConfig = (recommendation, applicationRate,
  toUnit, userVolumeUnit, toUnitByLength, blockAreaInM2, t) => {
  const getUserUnits = hooks.useUnits();
  const {
    scheduledDuration, deficit: deficitValue, etcForecast, units, data,
    irrigationDuration,
  } = recommendation?.recommendation;
  const scheduledString = parseDuration(scheduledDuration, t);
  const durationString = parseDuration(irrigationDuration, t);
  const deficit = parseWater(deficitValue, units.deficit, getUserUnits, toUnitByLength, t);
  const etcForecastString = parseWater(etcForecast,
    units.etcForecast, getUserUnits, toUnitByLength, t);
  const isDepth = toUnit === 'depth';
  const isDuration = toUnit === 'duration';

  const { value: convertedDeficit, label } = !isDepth
    ? getValueByUserUnit(deficitValue / 1000, 'meters', toUnit, userVolumeUnit,
      applicationRate, blockAreaInM2, getUserUnits)
    : { value: deficit };

  const { value: convertedETcForecast } = !isDepth
    ? getValueByUserUnit(etcForecast / 1000, 'meters', toUnit, userVolumeUnit,
      applicationRate, blockAreaInM2, getUserUnits)
    : { value: etcForecastString };

  // Total deficit = deficit + forcast(ETc)
  const convertedTotalDeficit = Number(convertedDeficit) + Number(convertedETcForecast);

  const { value: convertedScheduled } = !isDuration
    ? getValueByUserUnit(scheduledString, 'hours', toUnit, userVolumeUnit,
      applicationRate, blockAreaInM2, getUserUnits, toUnitByLength)
    : { value: scheduledString };

  // Get the precipitation forecast sum
  const precipForecastSum = getAverageAndSum(data?.precipForecastDaily || [])?.sum;

  // Convert the precipitation sum value from "millimeters" to "depth" by default
  const precipForecastInDepth = parseWater(precipForecastSum, units.deficit,
    getUserUnits, toUnitByLength, t);

  // Convert the precipitation sum value based on the user unit settings
  const { value: convertedPrecipitationForecast } = !isDepth
    ? getValueByUserUnit(precipForecastSum / 1000, 'meters', toUnit, userVolumeUnit,
      applicationRate, blockAreaInM2, getUserUnits)
    : { value: precipForecastInDepth };

  const convertedIrrigationDuration = !isDuration
    // If not duration, The required water should be (total deficit - scheduled - precip_forecast)
    ? convertedTotalDeficit - convertedScheduled - convertedPrecipitationForecast
    : durationString; // If duration, The required hours should be "irrigationDuration"

  const requiredHours = Number(convertedIrrigationDuration);

  const valueArr = [getValidValue(convertedDeficit), getValidValue(convertedETcForecast),
    getValidValue(convertedTotalDeficit), getValidValue(convertedScheduled),
    getValidValue(convertedPrecipitationForecast), getValidValue(requiredHours)];

  function buildHighChartsData() {
    const categories = [
      t('Deficit'),
      t('Forecast (ETc)'),
      t('Total Deficit'),
      t('Scheduled'),
      t('Precip Forecast'),
      t('Required'),
    ];

    const xAxis = {
      categories,
      labels: {
        style: {
          // 12px reduces the size of font for mobile view
          // 14px for desktop view
          fontSize: isMobile ? '12px' : '14px',
        },
      },
    };

    const yAxis = {
      title: false,
      min: parseFloat(Math.min(...valueArr)),
      max: parseFloat(Math.max(...valueArr)),
    };

    const series = [
      {
        showInLegend: false,
        data: [{
          y: roundToDecimalPlaces(getValidValue(convertedDeficit)),
          color: SUMMARY_GRAPH_BAR_COLOR.deficit,
        },
        {
          y: roundToDecimalPlaces(getValidValue(convertedETcForecast)),
          color: SUMMARY_GRAPH_BAR_COLOR.forecast,
        },
        {
          y: roundToDecimalPlaces(getValidValue(convertedTotalDeficit)),
          color: SUMMARY_GRAPH_BAR_COLOR.totalDeficit,
        },
        {
          y: roundToDecimalPlaces(getValidValue(convertedScheduled)),
          color: SUMMARY_GRAPH_BAR_COLOR.scheduled,
        },
        {
          y: roundToDecimalPlaces(getValidValue(convertedPrecipitationForecast)),
          color: SUMMARY_GRAPH_BAR_COLOR.precipitationForecast,
        },
        {
          y: roundToDecimalPlaces(getValidValue(requiredHours)),
          color: SUMMARY_GRAPH_BAR_COLOR.requiredHours,
        }
        ],
      },
    ];
    return {
      series,
      xAxis,
      yAxis,
    };
  }

  const chartData = buildHighChartsData(recommendation);
  const { label: depthUnitsLabel } = getUserUnits(0, 'inches',
    undefined, { decimalPlaces: 2 }, toUnitByLength);
  const options = getBarGraphConfig(chartData, !isDepth ? label : depthUnitsLabel);
  return options;
};

export function prepareLineGraphConfig(chartData, chartData1, type1, type2, chartLabel) {
  let chartDataToRender = [];
  if (chartData?.length) {
    chartDataToRender = [{
      showInLegend: false,
      data: chartData,
      name: type1,
    }];
  }
  if (chartData1?.length) {
    chartDataToRender = [...chartDataToRender, {
      showInLegend: false,
      data: chartData1,
      name: type2,
    }];
  }

  const options = {
    title: null,
    chart: {
      type: 'line',
      inverted: false,
    },
    yAxis: {
      title: false,
    },
    tooltip: {
      formatter() {
        const date = new Date(this?.x);
        const options = {
          month: 'short', day: 'numeric'
        };
        return `${date?.toLocaleDateString(undefined, options)}
        <br><b>${`${this?.series?.name} value` || 'Value'}: ${this?.y?.toFixed(2)}
         ${chartLabel}<b></br>`;
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b'
      },
      tickPadding: 10,
      labels: {
        padding: 5
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
      }
    },
    credits: {
      enabled: false,
    },
    series: chartDataToRender?.length ? chartDataToRender : []
  };

  return options;
}

export const prepareETcLineGraphConfig = (data, etLabel, dataKey) => {
  const etDataAndDates = data?.dateDaily?.map((date, index) => {
    const dateValue = new Date(date);
    const dateString = dateValue?.toDateString();
    return [Date.parse(dateString), data?.[dataKey]?.[index] || 0];
  });

  return prepareLineGraphConfig(etDataAndDates, undefined,
    dataKeyConfig[dataKey], undefined, etLabel);
};

export const prepareBarGraphConfig = (data, label) => {
  const xAxis = {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    tickPadding: 10,
    labels: {
      padding: 5
    },
  };

  const dataPoints = data?.map((d) => d?.[1] || 0);
  let yAxis;
  if (dataPoints) {
    yAxis = {
      title: false,
      min: 0,
      max: parseFloat(Math.max(...dataPoints)),
    };
  }

  const updatedData = {
    series: data?.length ? [
      {
        showInLegend: false,
        data,
      }] : null,
    xAxis,
    yAxis,
  };

  return getBarGraphConfig(updatedData, label, true);
};
