import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { FaSave, FaBan } from 'react-icons/fa';
import { isMobile } from '../../../../utils/detectDevice';
import { CreateScheduleButton } from '../../schedule/CreateScheduleButton';

const safeAreaTop = Number(getComputedStyle(document.documentElement)
  .getPropertyValue('--sat').split('px')[0]);
const safeAreaBottom = Number(getComputedStyle(document.documentElement)
  .getPropertyValue('--sab').split('px')[0]);
const padding = 10;
const isApp = matchMedia('(display-mode: standalone)').matches;

function getFooterBarTopValue() {
  let customTop = 0;
  const bottomTabElem = document.querySelector('.bottom-tabbar-container');
  if (bottomTabElem && !isApp) {
    customTop = ((bottomTabElem.offsetTop - (safeAreaBottom + padding))
    - (safeAreaBottom - safeAreaTop));
  } else if (isApp) {
  // 20 is overall padding
    customTop = bottomTabElem.offsetTop - (safeAreaBottom + 20);
  }
  return customTop;
}

function getDrawerTopValue() {
  let customTopDrawer = 0;
  const dropdownElem = document.querySelector('[data-rsbs-overlay="true"]');
  if (dropdownElem && !isApp) {
    customTopDrawer = ((dropdownElem.offsetTop - (safeAreaBottom - safeAreaTop))
    - (safeAreaBottom + safeAreaTop));
  } else if (dropdownElem && isApp) {
  // 70 is footer tab container height and overall padding
    customTopDrawer = dropdownElem.offsetTop - (safeAreaBottom + 70);
  }
  return customTopDrawer;
}

function getTopValue(stateMapArg) {
  return stateMapArg.isBottomSheetVisible ? getDrawerTopValue() : getFooterBarTopValue();
}

export default function AnomalyButtons(props) {
  const {
    presMode, createAnomaly, setCreateAnomaly, stateMapArg, setIsAnomalyControlEnabled,
    isImageryPanelVisible, sideBySideMapVisible, setModal,
  } = props;
  const { t } = useTranslation();
  const { type, visible } = createAnomaly || {};
  const renderButtonStyle = (isMobile
    ? { top: `${getTopValue(stateMapArg)}px`, right: '-2px' } : {});
  const visibleCreateButton = (type === 'create'
    || type === 'show-undo' || type === 'undo'
    || type === 'stop-draw' || type === 'show-cancel');

  function customOnClick() {
    setModal({ visible: true, type: 'create-anomaly' });
    setIsAnomalyControlEnabled(true);
  }
  function onClickCancel() {
    setCreateAnomaly({ visible: false, type: 'cancel' });
    setIsAnomalyControlEnabled(false);
  }

  function onClickSave() {
    setCreateAnomaly({
      visible: false,
      type: 'save',
      isAnomalyCreate: createAnomaly.type === 'stop-draw',
    });
    setIsAnomalyControlEnabled(false);
  }

  function renderCreateAnomalyButton() {
    return (
      <>
        {presMode === 'anomaly' && type !== 'edit' ? (
          <CreateScheduleButton
            className={visibleCreateButton ? 'create-anomaly-button' : ''}
            shape="square"
            icon
            onClick={customOnClick}
            buttonText="Add Anomaly"
          />
        ) : null}
      </>
    );
  }

  function getSaveCancelButtons() {
    const conditionCheckForSideBySideView = sideBySideMapVisible
    && isImageryPanelVisible;

    const commonClassName = !isMobile
      ? 'flex-row form-button-width desktop-anomaly-save-cancel-button'
      : 'flex-row form-button-width';
    const withOutSideBySideView = isImageryPanelVisible && !isMobile
      ? `save-cancel-button-with-side-by-side-view save-cancel-button-right '
      + 'save-cancel-button-bottom ${commonClassName}`
      : `save-cancel-button-bottom save-cancel-button-right ${commonClassName}`;
    const withSideBySideView = !isMobile
      ? `save-cancel-button-without-side-by-side-view save-cancel-button-right '
      + 'save-cancel-button-bottom  ${commonClassName}`
      : `save-cancel-button-bottom save-cancel-button-right ${commonClassName}`;

    return (
      <div
        className={conditionCheckForSideBySideView
          ? withSideBySideView : withOutSideBySideView}
      >
        <Button
          size="large"
          type="ghost"
          icon={<FaBan />}
          style={{ marginTop: '10px' }}
          onClick={() => {
            onClickCancel();
          }}
          className="create-anomaly-cancel-button"
          block
          onTouchEnd={() => {
            onClickCancel();
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="large"
          type="primary"
          icon={<FaSave />}
          style={{ marginTop: '10px' }}
          onClick={() => {
            onClickSave();
          }}
          onTouchEnd={() => {
            onClickSave();
          }}
          className={!isMobile ? 'margin-left-10' : 'anomaly-save-button'}
          block
        >
          {t('Save')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div
        className={isMobile ? 'controls-right' : 'desktop-controls-right flex-column'}
        style={renderButtonStyle}
      >
        {renderCreateAnomalyButton()}
      </div>
      <div
        className="controls-right-save-button"
        style={renderButtonStyle}
      >
        {presMode === 'anomaly' && type === 'edit'
          && visible && getSaveCancelButtons()}
      </div>
      <div
        className="controls-right-create-button flex-row"
        style={renderButtonStyle}
      >
        {type === 'stop-draw' && getSaveCancelButtons()}
      </div>
    </div>
  );
}

AnomalyButtons.propTypes = {
  setModal: PropTypes.func,
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  stateMapArg: PropTypes.shape(),
  setIsAnomalyControlEnabled: PropTypes.func,
  sideBySideMapVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
};

AnomalyButtons.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  sideBySideMapVisible: false,
  isImageryPanelVisible: false,
};
