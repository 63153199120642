import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import {
  Input, Form, Row, Col
} from 'antd';
import { useRenderReusableControls, generateSensorDepthOptions } from 'helper/reusableControls';
import { FORM_RULES, GEC_RANCH_WATERMELON_AUTO } from '../../../constants';
import SensorControl from './SensorControl';
import './ruleSettingsPage.less';

// This will be changed in the future
const defaultDepthCount = 0;

const { requiredField, requiredFieldWithPercentage, requiredPositiveNumberField } = FORM_RULES;

const { selectSensorStatus, selectUserDepthFormat } = selectors;

const { loadSensorStatus } = actions;

const { useUnits, useRanchBlockSelection } = hooks;

interface AutonomousIrrigationControlProps {
  selectedBlock: any;
  initialValues: any;
  setSelectedSensor: any;
  selectedSensor: any;
  form: object | any;
  autoFillValues: any;
}

const AutonomousIrrigationControls: React.FC<AutonomousIrrigationControlProps> = (props: any) => {
  const {
    initialValues, selectedSensor, form, setSelectedSensor, autoFillValues,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getUserUnits = useUnits();
  const { selectedObjFromState } = useRanchBlockSelection();
  const [extendedTime, setExtendedTime] = useState(0);
  const { renderSelect } = useRenderReusableControls(t);
  const depthFormat = useSelector(selectUserDepthFormat);
  const { type: objType, value }: any = selectedObjFromState || {};
  const ranchId = objType === 'ranch' ? Number(value) : undefined;
  // Get the selected sensor both create and edit mode
  const {
    reference_sensor_type: type,
    reference_sensor_identifier: identifier
  } = selectedSensor || autoFillValues || {};

  // Get the sesor status and prepare sensor depth
  const sensorStatus = useSelector((state) => selectSensorStatus(state, type, identifier));
  const { depthCount, loading } = sensorStatus || {};
  const depthOptions = generateSensorDepthOptions(depthCount || defaultDepthCount,
    sensorStatus, getUserUnits, depthFormat);
  const { extended_time_units: extendedTimeUnit } = autoFillValues?.controls || {};

  const {value: covertedRefillDepth } = getUserUnits(Number(initialValues
    ?.controls?.refill_point_depth), 'inches', 'depth', {});

  const {value: covertedStoppingDepth } = getUserUnits(Number(initialValues
    ?.controls?.stopping_point_depth), 'inches', 'depth', {});

  const refillPointDepth = !isNaN(covertedRefillDepth) && covertedRefillDepth
    ? Math.round(covertedRefillDepth)?.toString() : undefined;
  const stoppingPointDepth = !isNaN(covertedStoppingDepth) && covertedStoppingDepth
    ? Math.round(covertedStoppingDepth)?.toString() : undefined;

  // To set the form depth initial values
  useEffect(() => {
    form.setFieldsValue({ refill_point_depth: refillPointDepth });
    form.setFieldsValue({ stopping_point_depth: stoppingPointDepth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, refillPointDepth, stoppingPointDepth]);

  useEffect(() => {
    if (autoFillValues) {
      const { extended_time: extendedTime } = autoFillValues?.controls || {};
      const {
        reference_sensor_id: id,
        reference_sensor_type: type,
        reference_sensor_identifier: identifier,
      } = autoFillValues || {};
      setSelectedSensor({ id, type, identifier});
      form.setFieldsValue({ extended_time: extendedTime || 0 });
      setExtendedTime(Number(extendedTime || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFillValues, form]);

  // Load the sensor status
  useEffect(() => {
    if (!type || !identifier) return;
    dispatch(loadSensorStatus({ type, identifier }));
  }, [type, identifier, dispatch]);

  return (
    <>
      <SensorControl
        form={form}
        selectedRanch={ranchId}
        setSelectedSensor={setSelectedSensor}
        sensor={selectedSensor}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            className="font-bold"
            label={t('Refill Point')}
            name="refill_point"
            rules={requiredFieldWithPercentage}
          >
            <Input placeholder="0" suffix="%" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="font-bold"
            label={t('Refill Depth')}
            name="refill_point_depth"
            rules={requiredField}
          >
            {renderSelect(depthOptions, 'Select Depth', refillPointDepth
              || undefined, undefined, undefined, loading)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            className="font-bold"
            label={t('Stopping Point')}
            name="stopping_point"
            rules={requiredFieldWithPercentage}
          >
            <Input placeholder="0" suffix="%" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="font-bold"
            label={t('Stopping Depth')}
            name="stopping_point_depth"
            rules={requiredField}
          >
            {renderSelect(depthOptions, 'Select Depth', stoppingPointDepth
             || undefined, undefined, undefined, loading)}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        className="font-bold"
        label={t('Extended Time')}
        name="extended_time"
        rules={requiredPositiveNumberField}
      >
        <div className="flex-space-between">
          <Input
            placeholder="0"
            value={extendedTime}
            onChange={(d: any) => {
              form.setFieldsValue({ extended_time: d?.target?.value });
              setExtendedTime(d?.target?.value);
            }}
          />
          <div className="margin-left-5">{extendedTimeUnit || t('min')}</div>
        </div>
      </Form.Item>
    </>
  );
};

export default AutonomousIrrigationControls;
