import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

import { EventTypeTagProps } from './types';

export default function EventTypeTag({ value }: EventTypeTagProps) {
  const { t } = useTranslation();
  if (!value) return null;
  return (
    <Tag color="default">
      { t(value) }
    </Tag>
  );
}

EventTypeTag.propTypes = {
  value: PropTypes.string.isRequired,
};
