import React, { useState, useEffect } from "react";
import { Form, Input, Button, Typography } from "antd";
import { authService } from "farmx-api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LockOutlined, WarningOutlined } from "@ant-design/icons";
import FarmXLogo from "../../assets/images/farmx_logo_white.png";
import "./ConfirmPasswordPage.less";

const ConfirmPasswordPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  async function onFinish(values) {
    const { password } = values;
    const success = await authService.confirmNewPassword(token, password);
    setIsSuccess(!success);
    if (success) history.push("/login");
  }

  useEffect(async () => {
    const valid = await authService.validateToken(token);
    setIsValidToken(!valid);
  }, [token]);

  return (
    <div className="pass-container">
      <div className="logo">
        <img className="header-logo" alt="FarmX" src={FarmXLogo} />
      </div>
      <div className="inner-container">
        <Form className="new-pass-form" layout="vertical" onFinish={onFinish}>
          <Typography className="pass-message">
            <Typography.Title>{t("Set New Password")}</Typography.Title>
          </Typography>

          <Form.Item
            name="password"
            label={t("New Password")}
            rules={[
              { required: true, message: t("Please input your new password") },
            ]}
          >
            <Input.Password
              id="password"
              placeholder="Enter new Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="confirm-password"
            label={t("Repeat New Password")}
            dependencies={["password"]}
            rules={[
              { required: true, message: t("Please input the new password") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  const errorMessage = t(
                    "Oops! It looks like your new passwords don't match."
                  );
                  return Promise.reject(new Error(errorMessage));
                },
              }),
            ]}
          >
            <Input.Password
              id="current-password"
              placeholder="Confirm new Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="res-pass-button"
              data-test-id="reset-submit"
              disabled={isValidToken}
            >
              {t("Confirm")}
            </Button>
          </Form.Item>

          <Typography.Text type="danger" style={{ textAlign: "center" }}>
            {isSuccess && t("Reset password failed")}
          </Typography.Text>
          <Typography.Text
            type="danger"
            style={{ fontWeight: "bold", textAlign: "left !important" }}
          >
            {isValidToken && (
              <>
                {t(`The link you've used has expired.`)}
                <br />
                {t(`To request a fresh link, please `)}
                <a href="/reset-password/">{t("click here")}</a>
              </>
            )}
          </Typography.Text>
          <Typography>
            <Typography.Paragraph>
              {t(
                `If you have any trouble resetting your password, contact us at `
              )}
              <span className="blue">
                <b>admin@farmx.co</b>
              </span>
              .
            </Typography.Paragraph>
          </Typography>
        </Form>
      </div>
    </div>
  );
};

export default ConfirmPasswordPage;
