import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Typography, Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { useTracking } from '../../../../helper/mixpanel';


const { TextArea } = Input;

/**
 * AnomalyCommentForm renders form allowing user to add or edit a comment.
 * It acts as add a comment form by default.
 * If props.comment is passed, then it works as edit comment form.
 * todo:
 *  - Image upload not complete.
 */
export const AnomalyCommentForm = (props) => {
  const {
    hideModal, addComment, displayAlert, comment,
    editComment,
  } = props;
  const [value, setValue] = useState();
  const [image, setImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();
  const tracking = useTracking();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onFileUpload = (info) => {
    setFileList(info?.fileList || []);
    const media = info?.fileList[0]?.originFileObj;
    setImage(media);
  };

  const resetForm = () => {
    setLoading(false);
    setValue('');
    setError('');
    setFileList([]);
    setImage(null);
    hideModal();
  };

  const onSuccess = async () => {
    const alertMessage = comment?.id ? 'Comment updated' : 'Comment uploaded';
    displayAlert(t(alertMessage));
    resetForm();
  };

  const onFailure = async (e) => {
    setError(e);
    setLoading(false);
  };

  const onSubmit = () => {
    const details = {
      text: value,
      image,
    };
    if (comment?.id) {
      details.id = comment.id;
      tracking.track('Edit anomaly comment', { commentId: comment.id });
      editComment(details, t, onSuccess, onFailure);
    } else {
      tracking.track('Add anomaly comment');
      addComment(details, t, onSuccess, onFailure);
    }
  };

  const onCancel = () => {
    resetForm();
  };

  useEffect(() => {
    if (comment && comment.id) {
      const { text, image_path: imagePath } = comment;
      setValue(text);
      if (imagePath) {
        setFileList([{
          uid: '-1',
          name: 'image',
          status: 'done',
          url: comment.image_path,
        }]);
      }
    }
  }, [comment]);

  return (
    <Form className="anomaly-comment-form">
      <Form.Item>
        <TextArea
          rows={3}
          onChange={onChange}
          value={value}
          placeholder={t('Type a comment or upload an image')}
          maxLength={255}
        />
      </Form.Item>
      <Form.Item>
        <Upload
          maxCount={1}
          className="anomaly-comment-upload-field"
          beforeUpload={() => false}
          onChange={onFileUpload}
          fileList={fileList}
          listType="picture"
          type="file"
          accept="image/*"
        >
          {fileList.length >= 1 ? null : (
            <Button
              type="link"
              className="anomaly-comment-upload-button"
              icon={<UploadOutlined />}
            >
              {t('Add Image')}
            </Button>
          )}
        </Upload>
      </Form.Item>
      <div className="anomaly-comment-form-actions">
        <Form.Item>
          <Button className="anomaly-comment-cancel" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} htmlType="submit" onClick={onSubmit} type="primary">
            {t('Post')}
          </Button>
        </Form.Item>
      </div>
      {error && error.message ? (
        <Typography.Text type="danger">{error.message}</Typography.Text>
      ) : null}
    </Form>
  );
};

AnomalyCommentForm.defaultProps = {
  hideModal: () => { },
  addComment: () => { },
  editComment: () => { },
  displayAlert: () => { },
  comment: null,
};

AnomalyCommentForm.propTypes = {
  addComment: PropTypes.func,
  editComment: PropTypes.func,
  hideModal: PropTypes.func,
  displayAlert: PropTypes.func,
  comment: PropTypes.shape(PropTypes.object),
};
