import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hooks, selectors } from 'farmx-redux-core';
import moment from 'moment';
import { truncate } from 'lodash';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getStress, getCropStress } from '../../../../helper/plantSensorHelper';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
} = hooks;

const {
  selectSensorLoadingStatus,
  selectIRSensorSummary,
} = selectors;

export function IRSensorSummary(props) {
  const { list } = props;
  const { t } = useTranslation();
  const summary = useSelector((store) => selectIRSensorSummary(store, list)) || {};
  const loading = useSelector((store) => selectSensorLoadingStatus(store, list));
  const cropStress = getCropStress(summary.cropStressSet);

  useLoadStatus(list);

  const {
    lastUpdated,
    latestDate,
    cwsiVal,
    stationNames,
    totalOnline,
  } = summary;
  const stressValue = getStress(cropStress);
  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);
  const [valueUnitInfoType, date] = useMemo(() => (
    stateTag.type === 'online' ? ['primary', lastUpdated] : ['disabled', latestDate]
  ),
  [lastUpdated, latestDate, stateTag.type]);
  const showNoDataInfo = (!loading && !list.length);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="radio-waves"
        title={t('IR Sensors')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="radio-waves"
          title={t('IR Sensors')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              value={t(stressValue.label)}
              type={stressValue.type}
            />
            <ValueUnitInfo
              value={cwsiVal}
              unit={t('cwsi').toUpperCase()}
              info={moment(date).fromNow()}
              type={valueUnitInfoType}
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(stationNames?.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

IRSensorSummary.defaultProps = {
  list: [],
};

IRSensorSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
