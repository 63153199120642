import React from 'react';
import PropTypes from 'prop-types';
import SoilPresetChartSelect from './SoilPresetChartSelect';
import WeatherPresetChartSelect from './WeatherPresetChartSelect';

const PresetChartSelect = ({onSelectChange, preset}) => {
  const renderSelect = (preset) => {
    switch(preset){
      case 'soil': return <SoilPresetChartSelect onSelectChange={onSelectChange} />;
      case 'weather': return <WeatherPresetChartSelect onSelectChange={onSelectChange} />;
      default: return <></>;
    }
  };

  return (
    <div id="presetChartSelect">
      {renderSelect(preset)}
    </div>

  );
};

PresetChartSelect.propTypes = {
  onSelectChange: PropTypes.func,
  preset: PropTypes.string,
};

PresetChartSelect.defaultProps = {
  onSelectChange: () => null,
  preset: 'soil',
};

export default PresetChartSelect;
