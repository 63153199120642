import React from 'react';
import ContentLoader from 'react-content-loader';
import './CommonLoader.less';

export default function AnomalyTableLoader() {
  return (
    <ContentLoader
      speed={2}
      width={60}
      height={10}
      viewBox="0 0 60 10"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="minus-margin-left-10"
    >
      <rect x="0" y="0" rx="3" ry="3" width="60" height="10" />
    </ContentLoader>
  );
}
