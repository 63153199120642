import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { actions, selectors } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare';
import { FaExpand } from 'react-icons/fa';
import {
  Button,
  Typography,
  notification,
  Switch,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Map as MapComponent } from 'farmx-web-ui';
import { FeatureGroup, TileLayer } from 'react-leaflet';
import { useTracking } from '../../../helper/mixpanel';
import { useBlocksGeoJSON, useAllRanchesGeoJSON } from '../map/mapHooks';
import { isMobile } from '../../../utils/detectDevice';
import { RecommendationDetail } from './RecommendationDetail';
import {
  deleteBlockAnomaly, softDeleteBlockAnomaly,
  renderSoilLayer, removeSoilLayer, getBlockCenterCoordinates,
  getUpdatedAnomalyGeoJson,
} from '../../../helper/mapHelper';
import { reDrawSatelliteImagery } from '../map/reDraw';
import {
  useClearClusterPolygonLayers, useRenderClusterForGeoJson, usePrepareClusterDataToRender,
  useZoomToFitBlock, useRenderPlantsWithSelectAndDeSelectFeature, useHandlePlantsSelection,
} from '../../../helper/recommendationHooks';

const mapBoxPadding = {
  top: isMobile ? 10 : 30,
  left: isMobile ? 10 : 130,
  bottom: isMobile ? 10 : 30,
  right: isMobile ? 10 : 130,
};

// To avoid map zooming when clicking on any button
const anomalyMapBoxPadding = {
  top: isMobile ? 140 : 240,
  left: isMobile ? 0 : 240,
  bottom: isMobile ? 140 : 240,
  right: isMobile ? 0 : 240,
};

const {
  loadAndPrepareMapDataByRanchIds,
} = actions;

// TODO:Reducer check the features for farm
const {
  selectBlocksFeaturesByRanchId,
  selectRanchesFeatures,
  selectLoginUserInfo,
  selectNewBlockById,
} = selectors;

export default function AnomalyMap(props) {
  const {
    blockId, showMap, ranchId, deleteAnomaly, selectedImageryData,
    selMapShowSoilType, anomalyData, onLatLngClick,
    layerOptionButton, isMapTab, setTab, setIsDisabled, isAnomaly, plantsGeoJSON,
    clusterGeoJSON, selectedIds, onPolygonClick, isDataLoading, showPlants,
    checkedList, onChange, checkedFeatures, highlightPlant,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tracking = useTracking();
  const [toggleZoom, setToggleZoom] = useState(false);
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const { coordinates } = anomalyData?.properties?.center || {};
  const isUserCreated = anomalyData && anomalyData.properties
    && anomalyData.properties.is_user_created;
  const desktopMapContainer = !isMobile && 'desktop-recommendation-map-container-height';
  const mapFeatureGroupRef = useRef(null);
  const mapFeatureGroupRef1 = useRef(null);
  const prevCount = useRef(null);
  const [polygonIds, setPolygonIds] = useState([]);
  const [polygons, setPolygons] = useState(null);
  const [polygonObj, setPolygonObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(polygonObj[count]);
  const blockObj = useSelector((state) => selectNewBlockById(state, Number(blockId)));
  const [featureIds, setFeatureIds] = useState([]);

  useEffect(() => {
    if (isAnomaly && !plantsGeoJSON && !clusterGeoJSON && !toggleZoom) {
      setToggleZoom(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantsGeoJSON, clusterGeoJSON]);

  // To zoom and fit the selected block when toggle button click
  useZoomToFitBlock(isAnomaly, mapFeatureGroupRef1?.current, blockObj, toggleZoom);

  useEffect(() => {
    if (!isEqual(polygonIds, selectedIds)) {
      setPolygonIds(selectedIds);
    }
  }, [polygonIds, selectedIds, setPolygonIds]);

  const mapVar = mapFeatureGroupRef1?.current;
  useRenderClusterForGeoJson(mapVar, clusterGeoJSON, toggleZoom,
    selectedIds, polygonIds, items, count, prevCount, onPolygonClick, showPlants);

  // To clear the polygon layers when switching between plants and cluster mode
  useClearClusterPolygonLayers(mapVar, showPlants, selectedIds);

  // To prepare the cluster data based on its id to render the single cluster polygon
  usePrepareClusterDataToRender(clusterGeoJSON, polygons, setPolygons,
    setPolygonObj, setItems);

  // To render plants with select/deselect feature
  useRenderPlantsWithSelectAndDeSelectFeature(mapVar, checkedList, checkedFeatures, featureIds,
    setFeatureIds, onChange, isAnomaly);

  useHandlePlantsSelection(isAnomaly, mapVar, showPlants, checkedList, setFeatureIds);

  useEffect(() => {
    if (showMap) {
      dispatch(loadAndPrepareMapDataByRanchIds([ranchId], true, true));
    }
  }, [dispatch, ranchId, showMap]);

  const blocksGeoJSON = useBlocksGeoJSON(
    useSelector((state) => selectBlocksFeaturesByRanchId(state, ranchId), isEqual),
  );

  const ranchFeatures = useSelector((state) => selectRanchesFeatures(state));
  const [ranchGeoJSON] = useAllRanchesGeoJSON(
    ranchFeatures,
    ranchId,
  );

  const recenterGeoJSON = {
    type: 'FeatureCollection',
    features: blocksGeoJSON?.features?.filter((d) => (d?.id === Number(blockId))),
  };

  const anomalyGeoJSON = getUpdatedAnomalyGeoJson(anomalyData, isAnomaly, blocksGeoJSON,
    toggleZoom, blockId, tracking);

  function renderGraphButtons() {
    return showMap ? (
      <div style={{ width: '50%' }}>
        <Button
          style={{ width: '60px', padding: '0' }}
          type={isMapTab ? 'primary' : 'default'}
          onClick={() => {
            setTab('map');
          }}
        >
          {t('Map')}
        </Button>

        <Button
          style={{ width: '60px', padding: '0' }}
          type={(!isMapTab) ? 'primary' : 'default'}
          onClick={() => {
            setTab('vwc');
          }}
        >
          {t('VWC')}
        </Button>
      </div>
    ) : null;
  }

  function showNotification(isOk) {
    if (isOk) {
      notification.success({
        message: t('Anomaly Deleted Successfully!'),
      });
    } else {
      notification.error({
        message: t('Failed To Delete Anomaly'),
      });
    }
  }

  function softDeleteAnomaly(data) {
    if (data) {
      const coordinatesToDelete = data.map((d) => JSON.parse(d?.properties?.bounds)?.coordinates);
      const deleteObj = {
        bounds: {
          type: 'MultiPolygon',
          coordinates: coordinatesToDelete,
        },
        block: Number(blockId),
        created_by: userInfo && userInfo.user_id,
      };

      const anomalyId = anomalyData && anomalyData.properties.id;
      if (isUserCreated) {
        deleteBlockAnomaly(anomalyId, (isOk) => {
          showNotification(isOk);
        });
      } else {
        softDeleteBlockAnomaly(deleteObj, (isOk) => {
          showNotification(isOk);
        });
      }
    }
  }

  useEffect(() => {
    if (showMap) {
      reDrawSatelliteImagery({
        mapFeatureGroupRef,
        selectedImageryData,
        selectedImageryDataOpacity: 90,
        isAnomaly: true,
      });
    }
  });

  if (!selMapShowSoilType && !isAnomaly
    && mapFeatureGroupRef1?.current?.style?._loaded) {
    removeSoilLayer(mapFeatureGroupRef1);
  }

  // To set center of selected block on map from different place
  function recenterMap() {
    const mapRef = mapFeatureGroupRef1?.current;
    if (mapRef && !isAnomaly) {
      const { zoom } = getBlockCenterCoordinates({
        type: 'Feature',
        geometry: { ...blockObj?.bounds },
      });
      mapVar.setCenter(coordinates);
      // maintain previous zoom level when clicking on recenter button
      if(mapRef?.current) mapRef.current.setZoom(zoom);
    }
  }

  const handlePrevClick = () => {
    prevCount.current = count;
    if (count > 0) {
      setCount(count - 1);
      setItems(polygonObj[count - 1]);
    } else {
      const countVal = Object.keys(polygonObj).length - 1;
      setCount(countVal);
      setItems(polygonObj[countVal]);
    }
  };

  const handleNextClick = () => {
    prevCount.current = count;
    if (count < Object.keys(polygonObj).length - 1) {
      setCount(count + 1);
      setItems(polygonObj[count + 1]);
    } else {
      setCount(0);
      setItems(polygonObj[0]);
    }
  };

  function renderPrevNextControls() {
    return (
      <>
        <div className="left-next-buttons">
          <Button
            type="primary"
            icon={<LeftOutlined />}
            onClick={handlePrevClick}
            className="map-left-arrow"
          />
        </div>
        <div className="right-next-buttons negative-margin-left-10">
          <Button
            type="primary"
            icon={<RightOutlined />}
            onClick={handleNextClick}
            className="map-right-arrow"
          />
        </div>
      </>
    );
  }

  return (
    <>
      {isMapTab && (
      <>
        <div className="recommendation-detail-card2-container">
          { false && (
            <div className="recommendation-detail-card2-top">
              <Button
                type="outline"
                onClick={onLatLngClick}
                id={JSON.stringify({ lat: coordinates?.[1], lng: coordinates?.[0] })}
                className="lan-long-align"
              >
                {`lat: ${coordinates?.[1].toFixed(3)}  long: ${coordinates?.[0].toFixed(3)}`}
              </Button>
              <Switch
                style={{
                  width: '100px',
                  minWidth: '57px',
                  height: '30px',
                }}
                checkedChildren={t('Fit Block')}
                unCheckedChildren={t('Fit Block')}
                data-testid="anomaly-map-zoom-control"
                checked={toggleZoom}
                onChange={(d) => {
                  setToggleZoom(d);
                  if (mapVar) mapVar.setCenter(coordinates);
                }}
              />
            </div>
          )}
          {layerOptionButton ? (
            <div className="controls-box">
              <div className={isAnomaly && !clusterGeoJSON
                ? 'controls-right anomaly-detail-layer'
                : 'controls-right plant-drone-options'}
              >
                {layerOptionButton()}
                {!isAnomaly && (
                <Button
                  size="large"
                  type="primary"
                  icon={<FaExpand />}
                  className="recommendation-map-button ground-mission-recenter-button"
                  onClick={recenterMap}
                />
                )}
              </div>
            </div>
          ) : null}
          <div
            className={isAnomaly || isMobile ? `anomaly-map-height ${desktopMapContainer}`
              : `plant-drone-map-height ${desktopMapContainer}`}
          >
            <MapComponent
              mapRefElem={(d) => {
                if (d?.left) {
                  mapFeatureGroupRef1.current = d.left;
                }
              }}
              ranchesGeoJSON={ranchGeoJSON}
              recenterGeoJSON={recenterGeoJSON?.length ? recenterGeoJSON : undefined}
              blocksGeoJSON={blocksGeoJSON}
              isShowingImagery={selectedImageryData}
              mapBoxPadding={isAnomaly ? anomalyMapBoxPadding : mapBoxPadding}
              presentationMode={isAnomaly ? 'anomaly' : 'plants'}
              anomalyGeoJSON={isAnomaly ? anomalyGeoJSON : undefined}
              deleteAnomaly={deleteAnomaly}
              showHighResBaseMap
              onSelectAnomaly={(d) => {
                setIsDisabled(d.enable);
                softDeleteAnomaly(d?.data);
              }}
              plantsGeoJSON={plantsGeoJSON}
              clusterGeoJSON={clusterGeoJSON}
              selectedColorValue="count"
              isDataLoading={isDataLoading}
              showPlants={showPlants}
              isPlantSelection
              highlightPlant={highlightPlant}
              isGroundMissionPage
            >
              {isAnomaly ? (
                <FeatureGroup
                  ref={(r) => {
                    if (r && !mapFeatureGroupRef.current) {
                      mapFeatureGroupRef.current = r;
                    }
                  }}
                >
                  {selMapShowSoilType && (
                  <TileLayer
                    url="https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}"
                    name="soil"
                    maxZoom={19}
                    minZoom={0}
                    showOnSelector={false}
                    zIndex={1}
                  />
                  )}
                </FeatureGroup>
              ) : (
                <div>
                  {selMapShowSoilType && mapFeatureGroupRef1.current?.isStyleLoaded
                  && mapFeatureGroupRef1.current?.isStyleLoaded()
                   && renderSoilLayer(mapFeatureGroupRef1)}
                  {toggleZoom && !isAnomaly && clusterGeoJSON ? renderPrevNextControls() : null}
                </div>
              )}
            </MapComponent>
          </div>
        </div>
      </>
      )}
      {!isMapTab && (
        <>
          <div className="recommendation-detail-card2-top map-anomaly-graph-buttons">
            {renderGraphButtons()}
          </div>
          <RecommendationDetail
            showMap
            propBlockId={blockId}
            onClick={(d) => setTab(d)}
            showGraphButtons={showMap}
          />
        </>
      )}
    </>
  );
}

AnomalyMap.propTypes = {
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  showMap: PropTypes.bool,
  deleteAnomaly: PropTypes.bool,
  selectedImageryData: PropTypes.shape({}),
  selMapShowSoilType: PropTypes.string,
  anomalyData: PropTypes.shape({
    properties: PropTypes.shape({
      is_user_created: PropTypes.bool,
      id: PropTypes.number,
    }),
  }),
  onLatLngClick: PropTypes.func,
  layerOptionButton: PropTypes.func,
  setTab: PropTypes.func,
  setIsDisabled: PropTypes.func,
  isMapTab: PropTypes.bool,
  isAnomaly: PropTypes.bool,
  plantsGeoJSON: PropTypes.shape({}),
  clusterGeoJSON: PropTypes.shape(),
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  onPolygonClick: PropTypes.func,
  isDataLoading: PropTypes.bool,
  showPlants: PropTypes.bool,
  checkedList: PropTypes.arrayOf(PropTypes.any),
  checkedFeatures: PropTypes.shape(),
  onChange: PropTypes.func,
  highlightPlant: PropTypes.bool,
};

AnomalyMap.defaultProps = {
  blockId: null,
  ranchId: null,
  showMap: true,
  deleteAnomaly: false,
  selectedImageryData: null,
  selMapShowSoilType: null,
  anomalyData: null,
  onLatLngClick: null,
  layerOptionButton: null,
  setTab: null,
  isMapTab: false,
  setIsDisabled: false,
  isAnomaly: true,
  plantsGeoJSON: null,
  clusterGeoJSON: null,
  selectedIds: [],
  onPolygonClick: null,
  isDataLoading: false,
  showPlants: true,
  checkedList: [],
  onChange: null,
  checkedFeatures: {},
  highlightPlant: false,
};
