import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Tooltip,
  // Dropdown,
  // Badge,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  StreamApp,
  InfiniteScrollPaginator,
  NotificationDropdown,
  // NotificationFeed,
  // FeedContext,
  // Feed,
} from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';
import { authService } from 'farmx-api';
import { FaBell, FaCog } from 'react-icons/fa';
// import { SettingFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { isMobile } from '../../../utils/detectDevice';
import { useTracking } from '../../../helper/mixpanel';

import NotificationGroupFlat from './NotificationGroupFlat';
import './UserNotificationDropdown.css';

export function UserNotificationDropdown({ userInfo }) {
  const { REACT_APP_STREAM_API_KEY, REACT_APP_STREAM_APP_ID } = process.env;
  const userStreamToken = authService.useStream();
  const { t } = useTranslation();
  const containerRef = React.createRef();
  const tracking = useTracking();

  if (!userStreamToken) return null;

  const userId = userInfo.user_id;
  const feedGroup = 'notification';
  const iconSize = isMobile ? 30 : 20;

  function hideDropdown() {
    const dropdownSelector = '.notification-dropdown .raf-notification-dropdown__panel.raf-notification-dropdown__panel--open';
    const dropdownContainer = document.querySelector(dropdownSelector);
    if(dropdownContainer) dropdownContainer.classList.remove('raf-notification-dropdown__panel--open');
  }

  function renderHeader() {
    return (
      <div className="notification-dropdown-header">
        <div className="items-left">
          <h4>{t('Notifications')}</h4>
          <Link to="/events">
            <Tooltip
              title={t('View Events')}
              placement="bottom"
              trigger={isMobile ? '' : 'hover'}
            >
              <Button
                onClick={() => {
                  hideDropdown();
                  if (tracking) {
                    tracking.track('Clicked View Events from dropdown');
                  }
                }}
              >
                {t('View all')}
              </Button>
            </Tooltip>
          </Link>
        </div>
        <Link to="/settings/notifications">
          <Tooltip
            title={t('Notification Settings')}
            placement="bottom"
            trigger={isMobile ? '' : 'hover'}
          >
            <Button
              type="link"
              onClick={() => {
                hideDropdown();
                if (tracking) {
                  tracking.track('Clicked Notification Settings from dropdown');
                }
              }}
            >
              <FaCog size={20} />
            </Button>
          </Tooltip>
        </Link>
      </div>
    );
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <StreamApp
        apiKey={REACT_APP_STREAM_API_KEY}
        appId={REACT_APP_STREAM_APP_ID}
        token={userStreamToken}
      >
        <div className="notification-dropdown" ref={containerRef}>
          <NotificationDropdown
            feedGroup={feedGroup}
            userId={userId}
            Icon={<FaBell size={iconSize} title="Notifications" />}
            Paginator={InfiniteScrollPaginator}
            Header={renderHeader()}
            Group={(props) => (
              <NotificationGroupFlat
                {...props}
              />
            )}
            right
          />
        </div>
      </StreamApp>
    </ErrorBoundary>
  );
}
