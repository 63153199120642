import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FaPlus, FaPen } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../../helper/mixpanel';

export function CreateScheduleButton(props) {
  const {
    blockIds, shape, icon, onClick, className, buttonText,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const tracking = useTracking();
  return (
    <Button
      type="primary"
      className={className || 'circle-create-btn'}
      shape={shape}
      size="large"
      icon={icon ? <FaPen size={25} /> : <FaPlus size={35} />}
      onClick={(d) => {
        if (onClick) onClick(d);
        if (!icon) {
          history.push('/schedule/new', {
            from: location.pathname,
            blockIds,
          });
          if (tracking) tracking.track('Clicked On Create Schedule Button');
        }
      }}
    >
      {t(buttonText)}
    </Button>
  );
}

CreateScheduleButton.propTypes = {
  blockIds: PropTypes.arrayOf(PropTypes.any),
  shape: PropTypes.string,
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonText: PropTypes.string,
};

CreateScheduleButton.defaultProps = {
  blockIds: null,
  shape: 'circle',
  icon: false,
  onClick: null,
  className: null,
  buttonText: null,
};
