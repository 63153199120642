import React from 'react';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import { Ranch, Block } from 'types/farm';
import { compareByName } from '../../helper/common';
import './RanchBlockBreadcrumb.less';
import BreadcrumbLoader from '../contentLoaders/BreadCrumbLoader';

type Item = {
  id: string | number;
  name: string;
};

type AllBlockData = Block & {
  ranch: Ranch | undefined
};

const {
  setRanch, setBlocks, setUrlLoad,
} = actions;

const {
  selectAllNewBlocks, selectAllNewRanches,
} = selectors;

const {
  useRanchBlockSelection, useBlockNames, useRanchNamesForBlocks, useRanchNames, useEntities,
} = hooks;

export default function RanchBlockBreadcrumb() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { selectedObjFromState, blockArray } = useRanchBlockSelection();
  const ranchId: number = selectedObjFromState?.type === 'ranch'
    ? Number(selectedObjFromState?.value) : blockArray?.[0]?.ranchId;
  const blockId: number | undefined = selectedObjFromState?.type === 'block'
    ? Number(selectedObjFromState?.value) : undefined;
  const ranchName: string = useRanchNames([ranchId]);
  const blockName: string = useBlockNames([blockId]);
  const { loadingState } = useEntities()
  const { loading } = loadingState;
  const ranchNameForBlock: string = useRanchNamesForBlocks([Number(blockId)]);
  const allRanchesData: Ranch[] = useSelector(selectAllNewRanches)?.sort(compareByName) || [];
  const allBlocksData: AllBlockData[] = useSelector(selectAllNewBlocks)?.sort(compareByName) || [];

  function onMenuItemClick(targetData: string, blockMode: boolean) {
    const parsedData: Item = JSON.parse(targetData as string);
    const { id, name } = parsedData || {};
    const CONDITION_CHECK = (id === 'block') && (name === 'Select Block');

    dispatch(setUrlLoad(false));
    if (blockMode) {
      dispatch(setBlocks([id]));
      // when user clicking on select block option in block mode, ranch will be selected
      if (CONDITION_CHECK) dispatch(setRanch(ranchId));
    } else {
      dispatch(setRanch(id));
    }
  }

  function generateOptions(data : Ranch[] | AllBlockData[], isBlockMode: boolean) {
    // Show loading state if the data is still being fetched
    if (loading && !data?.length) {
      return [{ key: 'loading', label: <BreadcrumbLoader /> }];
    } else if (!loading && !data?.length) {
    // Show "No ranch / No block" message when there are no items in the data array
      return [{ 
        key: 'no data', 
        label: isBlockMode ? t('No Block') : t('No Ranch'),
        disabled: true, 
      }];
    }
    const filteredData = Number(ranchId)
      ? data.filter((d) => d?.ranch?.id === ranchId) as AllBlockData[] : data as Ranch[];

    const selectItem: Item = {
      id: isBlockMode ? 'block' : 'ranch',
      name: isBlockMode ? t('Select Block') : t('Select Ranch'),
    };

    const menuData: [Item, ...Ranch[]] | [Item, ...AllBlockData[]] = filteredData.length
      ? [selectItem, ...filteredData] : [selectItem, ...data];

    return menuData?.map((d) => ({
      key: JSON.stringify({ id: d?.id, name: d?.name }),
      label: (
        <div className="breadcrumb-select-container add-color-to-even-rows">
          <div>
            <span className="left-align left-options">{d.name}</span>
            {isBlockMode ? (
              <span className="right-align right-options">
                {d?.ranch?.name}
              </span>
            ) : (
              <span className="right-align right-options">{d?.entity?.name}</span>
            )}
          </div>
        </div>
      ),
      onClick: (x: { key: string }) => {
        onMenuItemClick(x?.key, isBlockMode);
      },
    }));
  }
  return (
    <div className="breadcrumb-select-container">
      <Breadcrumb className="breadcrumb-select">
        <Breadcrumb.Item
          menu={{ items: generateOptions(allRanchesData, false) }}
        >
          {ranchName[ranchId] || ranchNameForBlock[blockId as number] || t('Select Ranch')}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          menu={{ items: generateOptions(allBlocksData, true) }}
        >
          {blockName[blockId as number] || t('Select Block')}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}
