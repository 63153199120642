import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { getSoilState, getColorClassForSoilState } from '../components/soilState';
import { isNotNull } from '../../../helper/listHelper';

/**
 * @param {*} props - data, fieldCapacity, onSelect, wiltingPoint, refillPoint
 * @returns The HighCharts scatter chart with different color points
 * Description:
 * data: [{ x: "2022-01-01", y: 0.7 }, { x: "2022-01-02", y: 0.3 }]
 * onSelect: callback function to return value when click on scatter point
 * fieldCapacity, wiltingPoint, refillPoint: 0-1 range. Ex: 0.2, 0.4, 0.6
 */
export function VWCGraph(props) {
  const {
    data, fieldCapacity, onSelect, wiltingPoint, refillPoint, editMode,
    multiplePlotline, draggablePlotLine, chartType, loading, options: extraOptions,
    extraChartOptions,
  } = props;

  const {
    yAxis: yAxisOptions, chart: chartOptions, color, xAxis: xAxisOptions,
  } = extraOptions;
  const { langOptions: extraLangOptions } = extraChartOptions || {};
  const langOptions = extraLangOptions || {};

  const { t } = useTranslation();

  const highchartsComponentRef = React.createRef();

  useEffect(() => {
    if (highchartsComponentRef.current) {
      const { chart } = highchartsComponentRef.current;

      if (editMode && editMode.length && draggablePlotLine) {
        editMode.forEach((id) => {
          draggablePlotLine(chart.yAxis[0], id);
        });
      }
    }
  }, [highchartsComponentRef, editMode, draggablePlotLine]);

  function buildHighChartsData() {
    if (!data) {
      return {
        series: [],
        xAxis: {},
        yAxis: {},
      };
    }

    const seriesData = data.map((d) => {
      const momentDate = moment(d.x);

      return {
        y: d.y * 100,
        x: Date.UTC(momentDate.year(), momentDate.month(), momentDate.date(),
          momentDate.hour(), momentDate.minute(), momentDate.second()),
        className: chartType === 'scatter' ? getColorClassForSoilState(
          getSoilState(d.y, wiltingPoint, fieldCapacity, refillPoint),
        ) : '',
      };
    });

    const plotLines = [{
      color: '#333',
      value: wiltingPoint * 100,
      zIndex: 100,
    }];

    const [min, max] = [fieldCapacity && wiltingPoint && refillPoint].every((d) => (
      isNotNull(d)
    )) ? ([
        (Math.min(fieldCapacity, wiltingPoint, refillPoint) * 100) - 5,
        (Math.max(fieldCapacity, wiltingPoint, refillPoint) * 100) + 5,
      ]) : [null, null];

    const chartData = data.map((d) => d.y);
    const yMinData = Math.min(...chartData, min);
    const yMin = Math.max(0, yMinData);
    const yMax = Math.max(...chartData, max);

    return {
      series: [{
        name: 'VWC',
        showInLegend: 'false',
        data: seriesData,
        color,
      }],
      xAxis: {
        tickInterval: (24 * 3600 * 1000),
        allowDecimals: false,
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%d',
        },
        labels: {
          style: {
            fontSize: '14px',
          },
        },
        ...(xAxisOptions || {}),
      },
      yAxis: {
        plotLines: multiplePlotline || plotLines,
        min: yMin,
        max: yMax,
      },
    };
  }

  const chartData = buildHighChartsData();

  const options = {
    title: null,
    chart: {
      type: chartType,
      marginBottom: 50,
      marginTop: 20,
      ...chartOptions,
    },
    legend: { enabled: false },
    tooltip: {
      formatter: () => false,
    },
    plotOptions: {
      series: {
        borderRadius: 5,
        groupPadding: 0,
        cursor: 'pointer',
        point: {
          events: {
            click(e) {
              if (onSelect) onSelect(e.point.y);
            },
          },
        },
      },
    },
    xAxis: chartData.xAxis,
    yAxis: {
      plotLines: chartData.yAxis.plotLines,
      title: {
        text: null,
      },
      labels: {
        x: 0,
        y: 0,
        format: '{value} %',
        style: {
          fontSize: '14px',
        },
      },
      showFirstLabel: true,
      gridLineWidth: 0,
      max: chartData.yAxis.max,
      min: chartData.yAxis.min,
      tickInterval: 5,
      ...yAxisOptions,
    },
    series: chartData.series,
    credits: {
      enabled: false,
    },
    lang: {
      noData: t('No VWC data'),
      ...langOptions,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#888',
      },
    },
    time: {
      useUTC: true,
    },
  };

  if (!data && loading) {
    return (
      <div className="padding-10">{t('Loading...')}</div>
    );
  }

  if (!data) {
    return (
      <div className="padding-10">{t('No data available')}</div>
    );
  }

  return (
    <HighchartsReact
      constructorType="chart"
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={options}
      ref={highchartsComponentRef}
    />
  );
}

VWCGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
  fieldCapacity: PropTypes.number,
  wiltingPoint: PropTypes.number,
  refillPoint: PropTypes.number,
  editMode: PropTypes.arrayOf(PropTypes.any),
  draggablePlotLine: PropTypes.func,
  multiplePlotline: PropTypes.arrayOf(PropTypes.any),
  chartType: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.shape({
    xAxis: PropTypes.object,
    yAxis: PropTypes.object,
    chart: PropTypes.object,
  }),
  extraChartOptions: PropTypes.shape({
    langOptions: PropTypes.object,
  }),
};

VWCGraph.defaultProps = {
  data: [],
  onSelect: null,
  fieldCapacity: 0,
  wiltingPoint: 0,
  refillPoint: 0,
  editMode: null,
  draggablePlotLine: null,
  multiplePlotline: null,
  chartType: 'scatter',
  loading: false,
  options: {
    xAxis: {},
    yAxis: {},
    chart: {},
  },
  extraChartOptions: {
    langOptions: {},
  },
};
