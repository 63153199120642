import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider } from 'antd';
import './CheckboxGroup.less';

export default function CheckboxGroup(props) {
  const {
    options, onChange, checkedList, checkedFeatures,
  } = props;
  const checkAll = options.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < options.length;
  const { t } = useTranslation();
  const checkedObj = checkedList.reduce((acc, obj) => {
    const temp = acc;
    temp[obj.key] = obj;
    return temp;
  }, {});

  const onCheckSingleChange = (list) => {
    const newList = list.map((d) => {
      let obj = {};
      if (checkedObj[d]) obj = checkedObj[d];
      else obj = { key: d, count: 0, selected: 0 };
      return obj;
    });
    if (onChange) onChange(newList);
  };

  const onCheckAllChange = (e) => {
    const allList = e.target.checked ? options : [];
    const newList = allList.map((d) => {
      let obj = {};
      if (checkedObj[d?.key]) obj = checkedObj[d?.key];
      else obj = { key: d?.key, count: 0, selected: 0 };
      return obj;
    });
    if (onChange) onChange(newList);
  };

  return (
    <div className="plant-selection-options">
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {t('Select All')}
      </Checkbox>
      <Divider />
      <Checkbox.Group
        value={checkedList.map((d) => d?.key)}
        onChange={onCheckSingleChange}
      >
        {options.map((d) => {
          const indeterminateOpt = Object.values(checkedFeatures).includes(d?.key);
          return (
            <Checkbox
              value={d?.key}
              indeterminate={indeterminateOpt}
            >
              {t(d?.key)}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </div>
  );
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  checkedList: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  checkedFeatures: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  options: [],
  checkedList: [],
  onChange: null,
  checkedFeatures: [],
};
