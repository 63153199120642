import React, { useEffect } from 'react';
import { matchPath } from 'react-router';
import { Link, withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Divider } from 'antd';
import { CollapsableSidebar } from 'farmx-web-ui';
import { useSelector, useDispatch } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';
import {
  FaHome,
  FaChartLine,
  FaList,
  FaToggleOn,
  FaMapMarkedAlt,
  FaRegCalendarAlt,
  FaClipboardList,
  FaCog
} from 'react-icons/fa';
import { GEC_ENTITY_ID } from '../../constants';
import { ROUTES } from './routes';
import './Sidebar.css';
import EntitySelect from './EntitySelect';

const {
  selectSelectedBlocks,
} = selectors;

const {
  setBlocks,
} = actions;

const { useRanchBlockSelection, useEntities } = hooks;

const { SubMenu } = Menu;

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route.key));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

function Sidebar(props) {
  const location = useLocation();
  const {
    isMobile,
    onCollapse,
    collapsed,
  } = props;
  const dispatch = useDispatch();
  const { activeEntityId } = useEntities();

  const { selectedObjFromState, blockIds } = useRanchBlockSelection();
  const selectedBlocks = useSelector((state) => selectSelectedBlocks(state));

  const blocksFromState = selectedBlocks?.payload || [];
  useEffect(() => {
    if (blockIds?.length && !blocksFromState?.length && selectedObjFromState?.value) {
      dispatch(setBlocks(blockIds));
    }
  }, [dispatch, blockIds, blocksFromState, selectedObjFromState]);

  function collapseIfMobile() {
    if (isMobile && onCollapse) onCollapse();
    return null;
  }


  return (
    <CollapsableSidebar
      theme="light"
      onCollapse={onCollapse}
      collapsed={collapsed}
    >
      {!collapsed
        && (
          <div className="sidebar-select-margin">
            <EntitySelect />
          </div>
        )}
      <Divider style={{ margin: '0 0 6px 0' }} />
      <Menu
        className="nav-menu"
        theme="light"
        selectedKeys={[getMatchedKey(location)]}
        mode="inline"
        onClick={collapseIfMobile}
      >
        <Menu.Item key={ROUTES.RECOMMENDATION.key}>
          <Link to={ROUTES.RECOMMENDATION.path}>
            <span className="menu-icon"><FaHome /></span>
            <span className="menu-title">HOME</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.MAP.key}>
          <Link to={ROUTES.MAP.path}>
            <span className="menu-icon"><FaMapMarkedAlt /></span>
            <span className="menu-title">MAP</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.LIST.key}>
          <Link to={ROUTES.LIST.path}>
            <span className="menu-icon"><FaList /></span>
            <span className="menu-title">DEVICES</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.GRAPH.key}>
          <Link to={ROUTES.GRAPH.path}>
            <span className="menu-icon"><FaChartLine /></span>
            <span className="menu-title">GRAPH</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.SCHEDULE.key}>
          <Link to={ROUTES.SCHEDULE.path}>
            <span className="menu-icon"><FaRegCalendarAlt /></span>
            <span className="menu-title">SCHEDULE</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.CONTROL.key}>
          <Link to={ROUTES.CONTROL.path}>
            <span className="menu-icon"><FaToggleOn /></span>
            <span className="menu-title">CONTROL</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.EVENTS.key}>
          <Link to={ROUTES.EVENTS.path}>
            <span className="menu-icon"><FaClipboardList /></span>
            <span className="menu-title">EVENTS</span>
          </Link>
        </Menu.Item>
        {activeEntityId === GEC_ENTITY_ID && (
          <SubMenu key="settings" icon={<FaCog />} title="SETTINGS">
            <Menu.Item key={ROUTES.RULE_SETTINGS.key}>
              <Link to={ROUTES.RULE_SETTINGS.path}>
                <span className="menu-title">RULE SETTINGS</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTES.BLOCK_IRRIGATION_SETTINGS.key}>
              <Link to={ROUTES.BLOCK_IRRIGATION_SETTINGS.path}>
                <span className="menu-title">BLOCK IRRIGATIONS</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </CollapsableSidebar>
  );
}

Sidebar.propTypes = {
  isMobile: PropTypes.bool,
  onCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
};

Sidebar.defaultProps = {
  isMobile: false,
  onCollapse: undefined,
  collapsed: false,
};

export default withRouter(Sidebar);
