import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { hooks, helpers } from 'farmx-redux-core';
import { HsvToRgb } from '../../../utils/colors';
import { getColorForCurrentPercent } from '../components/soilState';

const { useUnits } = hooks;
const { getPercentAvailable } = helpers;

function createHiPPICanvas(w, h, canvasId) {
  const ratio = window.devicePixelRatio;
  const cv = document.getElementById(canvasId);
  cv.width = w * ratio;
  cv.height = h * ratio;
  cv.style.width = `${w}px`;
  cv.style.height = `${h}px`;
  cv.getContext('2d').scale(ratio, ratio);
  return cv;
}

export function SoilRefChart(props) {
  const { id, status } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  const { label: depthUnitsLabel } = getUserUnits(0, 'inches', 'depth');
  const depthLabel = depthUnitsLabel === 'in' ? 'in.' : t(depthUnitsLabel);

  const chartWidth = 40;
  const chartHeight = 80;

  const canvasWidth = chartWidth + 90;
  const canvasHeight = 90;

  function getPercentValue(value) {
    if (value == null) return '-';
    return Math.round(Number(value) * 1000) / 10;
  }

  // old method for getting color
  // issues with this algorithm:
  // - colors are too saturated and vibrant
  // - color cutoffs dont match the rest of app
  // (for example above field capacity is green)
  // eslint-disable-next-line no-unused-vars
  function getRgbForPercent(vwc, lowerBound, upperBound) {
    let value = (vwc - lowerBound) / (upperBound - lowerBound);
    let h = 1;
    const s = 1;
    let v = 1;

    if (vwc > upperBound) {
      value = (vwc - upperBound) / (1 - upperBound);
      h = value / 3 + 1 / 3;
    } else if (vwc < lowerBound) {
      h = 0;
      v = 1 - (lowerBound - vwc) / lowerBound;
    } else {
      h = value / 3;
    }

    return HsvToRgb({ h, s, v });
  }

  const getSoilData = useCallback(() => {
    if (!status || !status.depthCount) {
      return [];
    }

    const dataByDepth = [];
    let previousDepth = 0;
    for (let depthIndex = 1; depthIndex <= status.depthCount; depthIndex += 1) {
      const depth = status[`depth${depthIndex}`];
      const depthSize = depth - previousDepth;
      const { value: depthConverted } = getUserUnits(depth, 'inches', 'depth');
      const convertedDepth = `${Math.round(depthConverted)}`;
      const vwc = status[`vwc${depthIndex}`];
      const fieldCapacity = status[`fieldCapacity${depthIndex}`];
      const wiltingPoint = status[`wiltingPoint${depthIndex}`];
      const refillPoint = status[`refillPoint${depthIndex}`];
      const currentPercent = (wiltingPoint !== null && fieldCapacity !== null)
        ? getPercentAvailable(vwc, wiltingPoint, fieldCapacity)
        : null;
      previousDepth = depth;
      dataByDepth.push({
        fieldCapacity,
        wiltingPoint,
        refillPoint,
        vwc,
        soilType: status[`soilType${depthIndex}`],
        currentWater: vwc * depthSize,
        currentPercent,
        depth: convertedDepth,
        depthSize,
      });
    }
    return dataByDepth;
  }, [getUserUnits, status]);

  useEffect(() => {
    const leftLabelsX = 32;
    const rightLabelsX = chartWidth + 42;

    const canvas = createHiPPICanvas(canvasWidth, canvasHeight, id);
    const context = canvas.getContext('2d');
    const grad = context.createLinearGradient(300, 0, 300, chartHeight);

    context.font = '9px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Corbel, Arial, sans-serif';

    const soilData = getSoilData();
    const labelHeightStep = chartHeight / soilData.length;

    for (let i = 0; i < soilData.length; i += 1) {
      const {
        depth,
        vwc,
        wiltingPoint,
        fieldCapacity,
        currentPercent,
        refillPoint,
      } = soilData[i];
      const value = getPercentValue(vwc);
      // NOTE: old color method:
      // const rgb = getRgbForPercent(vwc, wiltingPoint, fieldCapacity);
      // const color = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;
      const refillPercent = (refillPoint - wiltingPoint) / (fieldCapacity - wiltingPoint);
      const color = getColorForCurrentPercent(currentPercent, refillPercent);
      const valueLabel = '% VWC';
      const labelY = labelHeightStep * (i + 1);
      context.textAlign = 'end';
      context.fillText(`${depth} ${depthLabel} -`, leftLabelsX, labelY);
      context.textAlign = 'start';
      context.fillText(`${value}${valueLabel}`, rightLabelsX, labelY);
      grad.addColorStop((i + 1) / soilData.length, color);
    }

    context.fillStyle = grad;
    context.fillRect(35, 5, chartWidth, chartHeight);
  }, [canvasWidth, id, status, getSoilData, depthLabel]);

  return (
    <div className="soil-ref-chart-wrapper text-align-end">
      <canvas
        id={id}
      />
    </div>
  );
}

SoilRefChart.defaultProps = {
  status: {
    depthCount: 1,
    vwc1: 0,
    depth1: 8,
    units: {
      depth: 'inches',
    },
  },
};

SoilRefChart.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.shape({
    depthCount: PropTypes.number,
    vwc1: PropTypes.number,
    depth1: PropTypes.number,
    units: PropTypes.shape({
      depth: PropTypes.string,
    }),
  }),
};
