import { Breadcrumb, Button, Card, Form, Input } from "antd";
import { PageHeader } from "components/mobile/components/PageHeader";
import React from "react";
import { useTranslation } from 'react-i18next';
import './CreateTeam.css';

export interface CreateTeamProps {
  loading: boolean;
  submitCreateTeamForm: (data: {teamName: string}) => void;
}

/**
 * CreateTeam is a functional presentational component used to display a form for creating a team.
 * @param {boolean} props.loading - The loading status of the component.
 * @param {(data: {teamName: string}) => void} props.submitCreateTeamForm - The method to handle the form submission.
 * @returns {JSX.Element}
 */
const CreateTeam: React.FC<CreateTeamProps> = ({loading, submitCreateTeamForm}) => {
  const { t } = useTranslation();

  return (
    <div className="settings-notifications-container div-default white-bg padding-20">
      <PageHeader
        showBack={true}
        title={t('Create Team')}
        toPath="/organization/teams"
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/organization/teams">Teams</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>
      <br/>
      <Card title="Create New Team" bordered={false}>
        <Form
          name="create_team"
          initialValues={{ remember: true }}
          onFinish={submitCreateTeamForm}
          autoComplete="off"
        >
          <Form.Item
            label="Team Name"
            name="teamName"
            rules={[{ required: true, message: 'Please input your team name!' }]}
          >
            <Input placeholder="Team Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className='margin-right-10'>
              Submit
            </Button>
            <Button href="/organization/teams">Cancel</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default CreateTeam;