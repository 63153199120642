/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import SensorControl from 'components/settings/components/SensorControl';
import {
  useRenderReusableControls, generateSensorDepthOptions,
  preparethresholdFormObj, useThresholdHandlers, getThresholdConfig,
} from 'helper/reusableControls';
import { getMainThresholdConfig } from 'helper/settingsHelper';
import { isMobile } from 'utils/detectDevice';
import { capabilities, FORM_RULES } from '../../../constants';
import '../../settings/components/ruleSettingsPage.less';

interface ThresholdSettingsProps {
  selectedBlock: number | undefined;
  selectedRanch: number | undefined;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
};

// This will be changed in the future
const defaultDepthCount = 0;

const { requiredField, requiredPositiveNumberField } = FORM_RULES;

const pressureLabel = 'psi';

const { selectSensorStatus, selectUserDepthFormat } = selectors;

const { loadSensorStatus } = actions;

const { useUnits, useRanchBlockSelection } = hooks;

const ThresholdSettings: React.FC<ThresholdSettingsProps> = (props: any) => {
  const { selectedBlock, selectedRanch } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getUserUnits = useUnits();
  const [form] = Form.useForm();
  const [sprinklerform] = Form.useForm();

  const [initialValues, setInitialValues] = useState<any>({});
  const [selectedSensor, setSelectedSensor] = useState<any>(null);
  const [irrigationSensor, setIrrigationSensor] = useState<any>(null);
  const [sprinklerSensor, setSprinklerSensor] = useState<any>(null);
  const [mainThresholds, setMainThresholds] = useState<any>([]);
  const [irrigationLoading, setIrrigationLoading] = useState(false);
  const [sprinklerLoading, setSprinklerLoading] = useState(false);

  const {
    reference_sensor_type: type,
    reference_sensor_identifier: identifier
  } = selectedSensor || {};

  const {
    renderButton, renderInputFormItem,
    renderSelect,
  } = useRenderReusableControls(t);

  const depthFormat = useSelector(selectUserDepthFormat);

  // Get the sesor status and prepare sensor depth
  const sensorStatus = useSelector((state) => selectSensorStatus(state, type, identifier));
  const { depthCount, loading } = sensorStatus || {};
  const depthOptions = generateSensorDepthOptions(depthCount || defaultDepthCount,
    sensorStatus, getUserUnits, depthFormat);
  const { selectedObjFromState }: any = useRanchBlockSelection();
  const isRanch = selectedObjFromState?.type === 'ranch';
  const ranchId = isRanch ? Number(selectedObjFromState?.value) : selectedRanch;
  const { onFinishIrrigation, onFinishSprinker } = useThresholdHandlers(mainThresholds, ranchId, t);

  const { isEdit: isIrrigationEdit } = getThresholdConfig(mainThresholds, ranchId, 'irrigation');
  const { isEdit: isSprinklerEdit } = getThresholdConfig(mainThresholds, ranchId, 'sprinkler');

  const onFinish = (fieldValues: any) => {
    if (!ranchId) message.error(t('Please select ranch'));
    else onFinishIrrigation(fieldValues, isIrrigationEdit, setIrrigationLoading);
  };

  const onSprinkerFinish = (fieldValues: any) => {
    if (!ranchId) message.error(t('Please select ranch'));
    else onFinishSprinker(fieldValues, isSprinklerEdit, setSprinklerLoading);
  };

  useEffect(() => {
    getMainThresholdConfig((success: boolean, response: any) => {
      if (success) {
        if (response?.data) {
          // Filter main thresholds by selected source ranch id
          const filteredThresholds = response.data
            ?.filter((obj: any) => obj?.ranch === ranchId);
          setMainThresholds(filteredThresholds);
          const formObj = preparethresholdFormObj(filteredThresholds, getUserUnits);
          setSelectedSensor(formObj?.irrigation);
          setIrrigationSensor(formObj?.irrigationPressure);
          setSprinklerSensor(formObj?.sprinklerPressure);
          setInitialValues(formObj);
        }
      } else {
        notification.error({
          message: t('Failed to fetch config'),
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranchId]);

  // Reset form fields on ranch change
  useEffect(() => {
    if (initialValues?.irrigation) {
      setSelectedSensor(null);
      setIrrigationSensor(null);
      setSprinklerSensor(null);
      form.resetFields();
      sprinklerform.resetFields();
      setInitialValues({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, sprinklerform, ranchId]);

  useEffect(() => {
    if (initialValues?.sprinklerPressure) {
      sprinklerform.setFieldsValue(initialValues);
    }
  }, [sprinklerform, initialValues]);

  useEffect(() => {
    if (initialValues?.irrigation) {
      form.setFieldsValue(initialValues);
      form.setFieldsValue({ depth: (initialValues?.depth)?.toString()});
    }
  }, [form, initialValues]);

  // Load the sensor status
  useEffect(() => {
    if (!type || !identifier) return;
    dispatch(loadSensorStatus({ type, identifier }));
  }, [dispatch, identifier, type]);

  return (
    <div className={`rule-setting-form ${isMobile ? 'width-100-percent' : ''}`}>
      <Form
        form={form}
        layout="vertical"
        name="Threshold Settings"
        onFinish={(d) => onFinish(d)}
        validateMessages={validateMessages}
        initialValues={initialValues}
      >
        <div className="flex-space-between">
          <SensorControl
            form={form}
            selectedBlock={selectedBlock}
            selectedRanch={ranchId}
            setSelectedSensor={setSelectedSensor}
            sensor={selectedSensor}
            formLabel="Referenced SMP for Irrigation"
            formName="irrigation"
          />
          <Form.Item
            className="font-bold width-20 margin-left-5"
            label={t('Depth')}
            name="depth"
            rules={requiredField}
          >
            {renderSelect(depthOptions, 'Select Depth', initialValues?.depth,
              undefined, undefined, loading)}
          </Form.Item>
        </div>
        <SensorControl
          form={form}
          selectedBlock={selectedBlock}
          selectedRanch={ranchId}
          setSelectedSensor={setIrrigationSensor}
          sensor={irrigationSensor}
          formLabel="Referenced Pressure Sensor for Irrigation"
          formName="irrigationPressure"
          sensorTypes={capabilities?.water_pressure}
        />
        {renderInputFormItem('irrigation_pressure_high', t('Pressure High Threshold'),
          pressureLabel, null, requiredPositiveNumberField, 'High')}
        {renderInputFormItem('irrigation_pressure_low', t('Pressure Low Threshold'),
          pressureLabel, null, requiredPositiveNumberField, 'Low')}

        <Form.Item className="font-bold flex-row-center justify-center">
          {renderButton(isIrrigationEdit ? 'Update' : 'Save',
            'primary', 'submit', undefined, undefined, true, irrigationLoading)}
        </Form.Item>
      </Form>

      <Form
        form={sprinklerform}
        layout="vertical"
        name="Sprinkler Threshold Settings"
        onFinish={(d) => onSprinkerFinish(d)}
        validateMessages={validateMessages}
        initialValues={initialValues}
      >
        <SensorControl
          form={sprinklerform}
          selectedBlock={selectedBlock}
          selectedRanch={selectedRanch}
          setSelectedSensor={setSprinklerSensor}
          sensor={sprinklerSensor}
          formLabel="Referenced Pressure Sensor for Sprinkler"
          formName="sprinklerPressure"
          sensorTypes={capabilities?.water_pressure}
        />
        {renderInputFormItem('sprinkler_pressure_high', t('Pressure High Threshold'),
          pressureLabel, null, requiredPositiveNumberField, 'High')}
        {renderInputFormItem('sprinkler_pressure_low', t('Pressure Low Threshold'),
          pressureLabel, null, requiredPositiveNumberField, 'Low')}
        <Form.Item className="font-bold flex-row-center justify-center">
          {renderButton(isSprinklerEdit ? 'Update' : 'Save',
            'primary', 'submit', undefined, undefined, true, sprinklerLoading)}
        </Form.Item>
      </Form>
    </div>
  );
};

ThresholdSettings.defaultProps = {
  selectedBlock: 0,
};

export default ThresholdSettings;
