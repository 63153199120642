export const getTextForIrrigationState = (irrigationState) => {
  if (!irrigationState) return 'unknown';
  return irrigationState?.replace('_', ' ');
};

export const getColorClassForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 'color-state-over';
  }
  if (irrigationState === 'well_irrigated') {
    return 'color-state-ok';
  }
  if (irrigationState === 'drying') {
    return 'color-state-warning';
  }
  if (irrigationState === 'dry') {
    return 'color-state-error';
  }
  if (irrigationState === 'very_dry') {
    return 'color-state-critical';
  }
  return undefined;
};

export const getStateForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 'bad';
  }
  if (irrigationState === 'well_irrigated') {
    return 'good';
  }
  if (irrigationState === 'drying') {
    return 'poor';
  }
  if (irrigationState === 'dry') {
    return 'bad';
  }
  if (irrigationState === 'very_dry') {
    return 'critical';
  }
  return undefined;
};

export const getRankForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return 6;
  }
  if (irrigationState === 'well_irrigated') {
    return 2;
  }
  if (irrigationState === 'drying') {
    return 5;
  }
  if (irrigationState === 'dry') {
    return 8;
  }
  if (irrigationState === 'very_dry') {
    return 10;
  }
  return -1;
};

export const getRankForImageryAnomalyState = (state) => {
  if (state === 'low' || state === 'normal') {
    return 3;
  }
  if (state === 'moderate') {
    return 4;
  }
  if (state === 'major') {
    return 7;
  }
  if (state === 'critical') {
    return 9;
  }
  return -1;
};

const getRankForRecommendationState = (recommendationType, recommendationState) => {
  if (recommendationType === 'irrigation') {
    return getRankForIrrigationState(recommendationState);
  }
  if (recommendationType === 'imagery_anomaly') {
    return getRankForImageryAnomalyState(recommendationState);
  }
  // Sort the recommendation by state
  if(!recommendationType || recommendationType === 'ground_mission') {
    return getRankForImageryAnomalyState(recommendationState);
  }
  return -1;
};

export const compareRecommendations = (a, b) => {
  const stateA = getRankForRecommendationState(a.type, a.type !== 'imagery_anomaly'
    ? a.state : a.anomalySeverity);
  const stateB = getRankForRecommendationState(b.type, b.type !== 'imagery_anomaly'
    ? b.state : b.anomalySeverity);
  if (stateA < stateB) {
    return 1;
  }
  if (stateA > stateB) {
    return -1;
  }
  if (a.blockName < b.blockName) {
    return -1;
  }
  if (a.blockName > b.blockName) {
    return 1;
  }
  return 0;
};

export const getActionText = (action) => {
  if (action === 'irrigate') {
    return 'Irrigate';
  }
  if (action === 'no_action') {
    return 'No Action';
  }
  if (action === 'stop_irrigation') {
    return 'Stop Irrigation';
  }
  if (action === 'reduce_irrigation') {
    return 'Reduce Irrigation';
  }
  if (action === 'investigate') {
    return 'Investigate';
  }
  return action || 'No Action';
};

export const filterInvalidAnomalyRecommendations = (recommendations) => {
  if (recommendations?.length) {
    const filteredRecommendations = recommendations.filter((rec) => {
      if (rec?.type === 'imagery_anomaly' && !rec?.anomalySeverity && !rec?.anomalyType) {
        return false;
      }
      return rec;
    });
    return filteredRecommendations;
  }
  return recommendations;
};
