import React from 'react';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'moment/min/locales';
import { isMobile } from 'react-device-detect';

/* eslint-disable no-useless-escape */
export const validateEmail = (email) => !!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

// Ref link: https://docs.sentry.io/platforms/javascript/guides/react/
export const sentryInit = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

/**
 * @param {*} feedBack - FeedBack object with name, event_id, email, comments and score
 * @param {*} callBack - function to return response
 */
export const submitUserFeedback = (feedBack, callBack) => {
  const organizationSlug = process.env.REACT_APP_SENTRY_ORGANIZATION_SLUG;
  const projectSlug = process.env.REACT_APP_SENTRY_PROJECT_SLUG;
  const DSN = process.env.REACT_APP_SENTRY_DSN;
  const feedBackURL = `https://sentry.io/api/0/projects/${organizationSlug}/${projectSlug}/user-feedback/`;

  const headers = {
    Authorization: `DSN ${DSN}`,
    'Content-Type': 'application/json',
  };

  fetch(feedBackURL, {
    method: 'POST',
    headers,
    body: JSON.stringify(feedBack),
  }).then((response) => callBack(response)).catch(() => callBack(false));
};

export const getPercentForFraction = (value) => Math.round(Number(value) * 1000) / 10;
export const roundToNearestPercent = (value) => Math.round(Number(value));

export function setMomentLocale(lang) {
  const browserLang = navigator.language;
  return moment.locale(lang === 'system' ? browserLang : lang);
}

function getTitleCaseString(inputString) {
  const anomalyTypeWords = inputString.replace('_', ' ').split(' ');

  for (let i = 0; i < anomalyTypeWords.length; i += 1) {
    anomalyTypeWords[i] = anomalyTypeWords[i].charAt(0).toUpperCase()
     + anomalyTypeWords[i].slice(1);
  }
  return anomalyTypeWords.join(' ');
}

export function getHumanReadableAnomalyType(anomalyType) {
  if (anomalyType) {
    return getTitleCaseString(anomalyType);
  }
  return null;
}

export function recommendationAnomalyFilter(data) {
  return data?.filter((d) => d?.type !== 'imagery_anomaly' && d?.type !== 'ground_mission')
    .reduce((a, obj) => (obj || a), undefined);
}

export function getRecommendationIdentifier(recommendation) {
  return recommendation.block && recommendation.type
    ? `${recommendation.block}-${recommendation.type}${recommendation.type === 'imagery_anomaly'
      ? `-${recommendation.anomalyType}` : ''}`
    : `${recommendation.block}-ground_mission`;
}

export const compareByName = (a, b) => (a?.name?.toLowerCase()
  ?.localeCompare(b?.name?.toLowerCase()));

// Function to extract the numeric part from the string
export const extractNumber = (str) => {
  if (str?.match(/\d+/)) return parseInt(str.match(/\d+/)[0], 10);
  return null;
};

// Custom sorting function
export const sortArrayOfObjectsByProperty = (arr, property = 'name', isDescending) => {
  let sortedArr = arr?.sort((a, b) => {
    if (extractNumber(a?.[property])) {
      return extractNumber(a?.[property]) - extractNumber(b?.[property]);
    }
    return a?.[property]?.toLowerCase()?.localeCompare(b?.[property]?.toLowerCase());
  });
  if (isDescending) {
    sortedArr = arr?.sort((a, b) => {
      if (extractNumber(b?.[property])) {
        return extractNumber(b?.[property]) - extractNumber(a?.[property]);
      }
      return b?.[property]?.toLowerCase()?.localeCompare(a?.[property]?.toLowerCase());
    });
  }
  return sortedArr;
};

export function renderRecommendationDateDetail(recommendation, t) {
  const { dateCreated } = recommendation || {};
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const recommendationCreatedAt = dateCreated
    ? moment(dateCreated).tz(localTimezone).format('MMMM D, YYYY [at] h:mm a z') : 'unknown';

  return (
    <small className="text-margin">
      <em>
        {`${t('Generated on')} ${recommendationCreatedAt}`}
      </em>
    </small>
  );
}

export function checkIsAllRanch(type) {
  return type === 'entity';
}
/**
 * Function to render table row without headers
 * @param {*} tableData - array of object with keys like ['label', 'value', 'info']
 * @param {*} showInfo - boolean value to show clickable info icon
 * @returns - table without headers with default style
 */
export function renderTableWithoutHeader(tableData, showInfo) {
  return (
    <div className="irrigation-equation-table-border">
      <table className="table table-striped">
        <tbody>
          {tableData.map((d) => (
            <tr>
              <td className={`align-left ${d?.highlight ? 'tr-bold-text' : ''}`}>
                {d?.label}
              </td>
              <td className={`align-right ${d?.highlight ? 'tr-bold-text' : ''} table-tooltip`}>
                {d?.value}
                {d?.title && <span className="tooltiptext">{d?.title}</span>}
              </td>
              {showInfo ? (
                <td className="align-center">{d?.info || null}</td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Function to get the duration in hours format
export function parseDuration(duration, t) {
  if (duration === undefined || duration === null) {
    return t('?');
  }
  // Split the string by space
  const parts = duration.split(' ');
  let days = 0; // Default to 0 days if not present
  let timePart = duration; // Assume the entire string is time part

  // If the string contains a day part
  if (parts.length === 2) {
    const dayPart = parts[0];
    days = parseInt(dayPart, 10); // Extract days
    timePart = parts[1]; // The second part is the time
  }

  // Use moment to parse the time part and convert it to hours
  const hours = moment.duration(timePart).asHours();

  // Calculate total hours
  const totalHours = days * 24 + hours;

  if (Math.abs(totalHours) < 10) {
    return Math.round(totalHours * 10) / 10;
  }

  return Math.round(totalHours);
}

// Function to get the values in depth units by user settings
export function parseWater(water, fromUnit, getUserUnits, toUnitByLength, t) {
  if (water === undefined || water === null) {
    return t('?');
  }
  const { value: depthValue } = getUserUnits(water, fromUnit,
    undefined, { decimalPlaces: 2 }, toUnitByLength);
  if (Math.abs(depthValue) < 10) {
    return Math.round(depthValue * 100) / 100;
  }
  return Math.round(depthValue);
}

// To convert the value from depth "inches" to volume "gallons"
export function convertToGallons(value, fromUnit, blockAreaInM2, getUserUnits) {
  // Convert value in "inches" to "meters"
  const { value: valueInMeters } = getUserUnits(Number(value), fromUnit,
    undefined, { decimalPlaces: 5 }, 'meters');

  // Convert "meters" to cubic meters
  const volumeInCubicMeters = blockAreaInM2 * valueInMeters;

  // Cubic meters to liters
  const { value: valueInLiters } = getUserUnits(volumeInCubicMeters, 'cubic_meters',
    undefined, { decimalPlaces: 5 }, 'liters');

  const gallonsText = isMobile ? 'gal' :'gallons';
  // liters to gallons
  const { value: valueInGallons, label } = getUserUnits(valueInLiters, 'liters',
    undefined, { decimalPlaces: 5 }, gallonsText);
  return { value: valueInGallons, label };
}

// To convert the value in from the units "volume (gallons/liters)",
// "depth (inches)", "duration (hours)"
export function getValueByUserUnit(value, fromUnit, toUnit, userVolumeUnit,
  blockApplicationRate, blockAreaInM2, getUserUnits, toUnitByLength) {
  let convertedValue;
  let convertedLabel;
  // To convert value to "gallons"
  if (toUnit === 'volume' && userVolumeUnit === 'gallons') {
    const { value: valueInGallons, label } = convertToGallons(value, fromUnit,
      blockAreaInM2, getUserUnits);

    convertedValue = valueInGallons;
    convertedLabel = label;
  }
  // To convert value to "liters"
  if (toUnit === 'volume' && userVolumeUnit === 'liters') {
    const { value: valueInGallons } = convertToGallons(value, fromUnit,
      blockAreaInM2, getUserUnits);

    // Then convert gallons to liters
    const { value: valueInLiters, label } = getUserUnits(valueInGallons, 'gallons',
      undefined, { decimalPlaces: 5 }, 'liters');
    convertedValue = valueInLiters;
    convertedLabel = label;
  }
  // To convert value to "hours"
  if (toUnit === 'duration') {
    const { value: valueInGallons } = convertToGallons(value, fromUnit,
      blockAreaInM2, getUserUnits);

    // To get hours: gallons / application rate
    const valueInHours = valueInGallons / Number(blockApplicationRate);
    convertedValue = valueInHours;
    convertedLabel = isMobile ? 'hrs' : 'hours';
  }

  // To convert value from hours to "gallons"
  if (fromUnit ==='hours' && toUnit === 'volume' && userVolumeUnit === 'gallons') {
    // convert hours to gallons by multiply the hours * application rate
    convertedValue = (Number(value) || 0) * blockApplicationRate;
    convertedLabel = 'gallons';
  }

  // To convert value from hours to "liters"
  if (fromUnit ==='hours' && toUnit === 'volume' && userVolumeUnit === 'liters') {
    // convert hours to gallons by multiply the hours * application rate
    const hoursToGallons = (Number(value) || 0) * blockApplicationRate;

    // Then convert gallons to liters
    const { value: valueInLiters, label } = getUserUnits(hoursToGallons, 'gallons',
      undefined, { decimalPlaces: 5 }, 'liters');
    convertedValue = valueInLiters;
    convertedLabel = label;
  }

  // To convert value from hours to "inches" with volume "gallons"
  if (fromUnit ==='hours' && toUnit === 'depth' && userVolumeUnit === 'gallons') {
    // convert hours to gallons by multiply the hours * application rate
    const hoursToGallons = (Number(value) || 0) * Number(blockApplicationRate);

    // First convert gallons to cubic inches
    const { value: valueInCubicInches } = getUserUnits(hoursToGallons, 'gallons',
      undefined, { decimalPlaces: 5 }, 'cubic_inches');

    // 1 gallon = 231 cubic inches
    // 1 m2 = 1550.0031 square inches
    const areaToSquareInches = blockAreaInM2 * 1550.0031;

    // Third convert value to inches
    const valueInInches = valueInCubicInches / areaToSquareInches;

    // Convert value to user legth format from inches
    const { value: depthValue, label } = getUserUnits(valueInInches, 'inches',
      undefined, { decimalPlaces: 5 }, toUnitByLength);
    convertedValue = depthValue;
    convertedLabel = label;
  }

  // To convert value from hours to "inches" with volume "liters"
  if (fromUnit ==='hours' && toUnit === 'depth' && userVolumeUnit === 'liters') {
    // convert hours to gallons by multiply the hours * application rate
    const hoursToGallons = (Number(value) || 0) * Number(blockApplicationRate);

    // First convert gallons to liters
    const { value: valueInLiters } = getUserUnits(hoursToGallons, 'gallons',
      undefined, { decimalPlaces: 5 }, 'liters');

    // Second convert liters to cubic meters
    const { value: valueInCubicInches } = getUserUnits(valueInLiters, 'liters',
      undefined, { decimalPlaces: 5 }, 'cubic_inches');

    // 1 m2 = 1550.0031 square inches
    const areaToSquareInches = blockAreaInM2 * 1550.0031;

    // Third convert value to inches
    const valueInInches = valueInCubicInches / areaToSquareInches;

    // Convert value to user legth format from inches
    const { value: depthValue, label } = getUserUnits(valueInInches, 'inches',
      undefined, { decimalPlaces: 5 }, toUnitByLength);
    convertedValue = depthValue;
    convertedLabel = label;
  }

  return { value: convertedValue, label: convertedLabel };
}

// To check if 2 arrays have same values
export function isSameList(list1, list2){
  if(!list1 || !list2) return false;
  if(list1.length !== list2.length) return false;
  return list1.map((item) => list2.includes(item))
    .reduce((a, p) => a && p, true);
}
