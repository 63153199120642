import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

export function CardLayout({ children, style }) {
  return (
    <Card
      headStyle={{ border: "none" }}
      bordered
      className="mobile-list-item card-layout-style"
      style={style}
    >
      {children}
    </Card>
  );
}

CardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.object,
};
