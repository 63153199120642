import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { renderAreaCalculationTable, renderComparisonTable } from '../../../helper/anomalyHelper';
import './recommendation-detail.css';
import './table.less';
import { isMobile } from '../../../utils/detectDevice';

function AnomalyArea(props) {
  const {
    areaUnit, anomalyView,
    anomalyArea, onSelect,
    anomalyComparison, loading,
  } = props;
  const { t } = useTranslation();
  const [viewDetail, setViewDetail] = useState('anomalyArea');
  const [dataType, setDataType] = useState('areaUnit');
  const isDefaultView = anomalyView === 'default';
  const isAreaView = anomalyView === 'area';
  const isVegetationIndex = viewDetail === 'vegetationIndex';
  const isAnomalyCount = viewDetail === 'anomalyCount';
  const comparisonData = anomalyComparison?.change || [];

  // Prepare object by anomaly severity to get area
  const anomalyAreaForBlockId = anomalyArea?.map((d) => d && d.anomaly_area.reduce((a, b) => {
    const obj = { ...a };
    obj[b.anomaly_severity] = b.area_acre;
    return obj;
  }, {})).reduce((a, b) => ({ ...a, ...b }), {}) || {};
  const totalAnomalyArea = Object.values(anomalyAreaForBlockId).reduce((a, b) => (a + b), 0)

  function onSelectView(d) {
    if (onSelect) onSelect(d);
  }

  return (
    <div>
      <div className="table-controls-div">
        <>
          {/* <div className="anomaly-view-option">{`${t('View')} :`}</div> */}
          <div className="margin-bottom-10">
            <Select
              defaultValue="area"
              value={anomalyView}
              onSelect={onSelectView}
              className="anomaly-view-select-width"
              popupClassName="anomaly-area-calc-table-list-option"
            >
              <Select.Option value="area">{t('Stress By Category')}</Select.Option>
              <Select.Option value="comparison">{t('Change Over Time')}</Select.Option>
            </Select>
          </div>
        </>
        {!isAreaView && !isDefaultView ? (
          <>
            {/* <span className="table-margin-left-10">{`${t('Comparison Type')}:`}</span> */}
            <div>
              <Select
                defaultValue="anomalyArea"
                value={viewDetail}
                onSelect={setViewDetail}
                popupClassName="anomaly-area-calc-table-list-option"
              >
                <Select.Option value="anomalyArea">{t(`Anomaly Area (${areaUnit})`)}</Select.Option>
                <Select.Option value="anomalyCount">{t('Anomaly Count')}</Select.Option>
                <Select.Option value="vegetationIndex">{t('Imagery Indices')}</Select.Option>
              </Select>
            </div>
          </>
        ) : null}
        {!isAreaView && !isDefaultView ? (
          <div>
            <Select
              defaultValue="areaUnit"
              value={dataType}
              onSelect={setDataType}
              className="anomaly-view-select-width"
              popupClassName="anomaly-area-calc-table-list-option"
            >
              <Select.Option value="areaUnit">{t('Index')}</Select.Option>
              <Select.Option value="percentage">{t('Percentage')}</Select.Option>
            </Select>
          </div>
        ) : null}
      </div>
      {!isDefaultView ? (
        <>
          {isAreaView
            ? renderAreaCalculationTable(areaUnit, anomalyAreaForBlockId, totalAnomalyArea, t)
            : renderComparisonTable(comparisonData, isVegetationIndex, isAnomalyCount, dataType,
              t, isMobile, loading)}
        </>
      ) : null}
    </div>
  );
}

AnomalyArea.propTypes = {
  areaUnit: PropTypes.string,
  anomalyView: PropTypes.string,
  onSelect: PropTypes.func,
  anomalyArea: PropTypes.arrayOf(PropTypes.shape()),
  anomalyComparison: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
};

AnomalyArea.defaultProps = {
  areaUnit: 'acre',
  anomalyView: null,
  onSelect: null,
  anomalyArea: null,
  anomalyComparison: null,
  loading: false,
};

export default AnomalyArea;
