import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { Typography, Radio } from 'antd';
import { PageHeader } from '../components/PageHeader';
import { withUserSettings } from './withUserSettings';
import { isMobile } from '../../../utils/detectDevice';

const {
  patchUserSettings,
} = actions;

function Language() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userSettings = useSelector((state) => selectors.selectUserSettings(state));
  const [selectedLanguage, setSelectedLanguage] = useState('system');

  useEffect(() => {
    if (userSettings) {
      setSelectedLanguage(userSettings.language);
    }
  }, [dispatch, userSettings]);

  const updateLanguageSettings = useCallback((e) => {
    const { value } = e.target;

    setSelectedLanguage(value);

    dispatch(patchUserSettings({ language: value }));
  }, [dispatch]);


  return (
    <div className="language-settings-container div-default padding-20 white-bg">
      <PageHeader
        showBack={!!isMobile}
        title={t('Language Settings')}
        toPath="/settings"
      />
      <div className="language-settings">
        <Typography.Title level={4}>{`${t('Language Settings')} (${'set by'}):`}</Typography.Title>
        <div className="language-settings-options">
          <Radio.Group onChange={updateLanguageSettings} value={selectedLanguage}>
            <Radio value="system">{t('System')}</Radio>
            <Radio value="en">English</Radio>
            <Radio value="es">Español</Radio>
            <Radio value="ja">日本語</Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
}

export const LanguageSettings = withUserSettings(Language);
