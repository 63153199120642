import { prepareMarker, prepareRanchMarker, prepareMarkerClusterIconColor } from 'farmx-web-ui';
import MaplibreglSpiderifier from 'maplibregl-spiderfier';
import area from '@turf/area';
import 'maplibregl-spiderfier/index.css';
import Supercluster from 'supercluster';
import { detachEvents } from '../../../helper/mapHelper';

const MAX_CHILDREN_COUNT = 10;
const MAX_ZOOM_FOR_ALL_RANCHES = 7;
const SPIDERFY_FROM_ZOOM = 13;
const RANCH_ZOOM_VALUE = 13;
const maxZoom = 23;
const clusterColorMap = {};
let allSpiderifierRefs = [];
let allSpiderLegs = [];
let selectedId;
let previousPresMode;
let supercluster;

// To remove the previous selection
function removeSelectedClassFromSpiderPins() {
  if (allSpiderLegs?.length) {
    allSpiderLegs.forEach((legs) => {
      if (legs?.elements?.pin?.classList) {
        legs.elements.pin.classList.remove('marker-is-selected');
        const {firstChild} = legs.elements.pin;
        if (firstChild && firstChild.classList) {
          firstChild.classList.remove('marker-is-selected');
        }
      }
    });
  }
}

function unspiderfyAll() {
  allSpiderifierRefs.forEach((d) => {
    d.unspiderfy();
  });
  allSpiderifierRefs = [];
}

function removeMarkers(ranchesPointsGeoJSON) {
  const markerElements = document.querySelectorAll(
    ranchesPointsGeoJSON?.features?.length ? '.marker' : '.vector-marker'
  );
  if (markerElements?.length) {
    markerElements.forEach((d) => d.remove());
  }
}

function getSpiderifier(mapRef, customOnClick, isUpdateStyle, ranchesPointsGeoJSON) {
  const spiderifier = new MaplibreglSpiderifier(mapRef, {
    customPin: true,
    circleSpiralSwitchover: 7,
    initializeLeg: (spiderLeg) => {
      const { feature } = spiderLeg;
      const iconHtml = feature.type.outerHTML;
      allSpiderLegs.push(spiderLeg);
      // Create a temporary container element to hold the HTML string
      const container = document.createElement('div');
      container.className = 'marker';
      container.innerHTML = iconHtml;

      // Extract the first child element, assuming iconHtml contains a single top-level element
      const iconElem = container.firstChild?.firstChild;

      if (isUpdateStyle) {
        spiderLeg.elements.container.classList.add('remove-leg-margin');
        spiderLeg.elements.line.classList.add('remove-line-height');
      }
      // Check if iconElem is a valid Node before appending
      if (iconElem instanceof Node) {
        // Append the icon element to the spider leg's pin container
        spiderLeg.elements.pin.appendChild(iconElem);
      } else {
        console.error('Invalid icon element:', iconElem);
      }
      return spiderLeg;
    },
    onClick(e, spiderLeg) {
      removeSelectedClassFromSpiderPins();
      removeMarkers(ranchesPointsGeoJSON);
      allSpiderLegs.push(spiderLeg);
      selectedId = spiderLeg?.feature?.feature?.properties?.id;
      // To highlight the clicked marker with css class
      spiderLeg.elements.pin.classList.add('marker-is-selected');
      allSpiderLegs.forEach((sLeg) => {
        if (sLeg?.feature?.id === selectedId) {
          sLeg.elements.pin.classList.add('marker-is-selected');
        }
      });
      if (customOnClick) customOnClick(spiderLeg?.feature?.feature);
      e.stopPropagation();
    },
  });
  return spiderifier;
}

function initializeSuperCluster(zoom, isRanch) {
  return new Supercluster({
    radius: !isRanch ? 40 : 25, // Cluster radius
    maxZoom: !isRanch ? zoom : 8, // Maximum zoom to cluster points on
  });
}

function getClusters(mapRef) {
  const mapBounds = mapRef.getBounds();
  const sw = mapBounds.getSouthWest(); // Southwest corner (left-bottom)
  const ne = mapBounds.getNorthEast(); // Northeast corner (right-top)

  // Construct the array with west, south, east, and north values
  const boundsArray = [sw.lng, sw.lat, ne.lng, ne.lat];

  // Generate clusters for the current map view
  const clusters = supercluster.getClusters(
    boundsArray, // Current map bounds
    mapRef.getZoom() // Current map zoom level
  );

  return clusters;
}

function clearAllClusterCircles(mapRef) {
  if (mapRef?.getLayer) {
    Object.keys(clusterColorMap).forEach((id) => {
      const clusterSourceId = `cluster-circle-${id}`;
      const clusterCountLayerId = `cluster-circle-count-${id}`;
      const clusterCountLabelId= `cluster-label-${id}`;
      if (mapRef?.getLayer(clusterCountLayerId)) mapRef.removeLayer(clusterCountLayerId);
      if (mapRef?.getLayer(clusterCountLabelId)) mapRef.removeLayer(clusterCountLabelId);
      if (mapRef?.getSource(clusterSourceId)) mapRef.removeSource(clusterSourceId);
    });
  }
}

function calculateCentroid(data) {
  let totalLatitude = 0;
  let totalLongitude = 0;
  const count = data.length;

  data.forEach((feature) => {
    totalLatitude += feature?.geometry?.coordinates[1];
    totalLongitude += feature?.geometry?.coordinates[0];
  });

  const centroidLatitude = totalLatitude / count;
  const centroidLongitude = totalLongitude / count;

  return [centroidLongitude, centroidLatitude];
}
// This function could be useful in the future
// function getRanchAreaInKm(ranchData, ranchId) {
//   let areaInSquareKilometers = 0;
//   if (ranchData?.length === 1) {
//     try {
//       const [ranch] = ranchData;
//       const ranchFeature = {
//         id: ranch?.properties?.id || ranchId,
//         type: 'Feature',
//         geometry: ranch?.properties?.bounds,
//       };
//       const areaInSquareMeters = area(ranchFeature);
//       // M2 to kilometers (km²), divide the value by 1,000,000
//       areaInSquareKilometers = areaInSquareMeters / 1000000;
//     } catch (err) {
//       console.log('Something went wrong');
//     }
//   }

//   return areaInSquareKilometers;
// }

// To store the attached events to detach later
const eventRef = {};
const eventRefRight = {};
export function renderSensorClusters(mapVar, mapVarRight, presentationMode, selectedFeature,
  customOnClick, getUserUnits, features, ranchId, ranchesPointsGeoJSON, isAllRanch, isRanch,
  showAllMarkers, customOnRanchClick) {
  const featureId = features?.features?.[0]?.id;
  if (!previousPresMode) previousPresMode = presentationMode;
  if (selectedId && (!selectedFeature || (previousPresMode
    && presentationMode !== previousPresMode))) selectedId = null;
  if(isAllRanch && featureId){
    return;
  }

  const zoomVal = mapVar?.getZoom();
  if(!isAllRanch && isRanch && zoomVal > RANCH_ZOOM_VALUE-1 && !featureId){
    unspiderfyAll();
    allSpiderLegs = [];
    clearAllClusterCircles(mapVar);
  }


  // Create a Supercluster instance
  supercluster = initializeSuperCluster(maxZoom, isRanch);

  // Load the data into Supercluster
  supercluster.load(features?.features);

  function renderOnSpiderifier(center, markerData, isSingleMarker, mapRef, onClick) {
    const spiderifierRef = getSpiderifier(mapRef, onClick || customOnClick,
      isSingleMarker, features);
    allSpiderifierRefs.push(spiderifierRef);
    spiderifierRef.spiderfy(center, markerData);
  }

  function renderSingleMarker(d, mapRef, onClick) {
    const latlng = { lat: d.geometry.coordinates[1], lng: d.geometry.coordinates[0] };
    const isSelected = d.id === selectedId;
    let markerObj = {};
    if ((isRanch || showAllMarkers) && d?.properties?.type === 'ranch') {
      markerObj = prepareRanchMarker(d, latlng, mapRef, onClick || customOnClick);
    } else {
      markerObj = prepareMarker(d, latlng, presentationMode, isSelected,
        mapRef, customOnClick, getUserUnits, false);
    }
    const markerData = Object.values(markerObj)?.[0]?.[0];
    const key = d.geometry.coordinates;
    renderOnSpiderifier(key, [markerData], true, mapVar, onClick);
    if (mapVarRight) renderOnSpiderifier(key, [markerData], true, mapVarRight, onClick);
  }

  function renderClusteredMarkers(clusters, mapRef) {
    if (clusters?.length) {
      const key = calculateCentroid(clusters);
      const latlng = { lat: key[1], lng: key[0] };
      const updatedMarkers = clusters.map((fe) => {
        const isSelected = fe.id === selectedId;
        let markerObj = {};
        if ((isRanch || showAllMarkers) && fe?.properties?.type === 'ranch') {
          markerObj = prepareRanchMarker(fe, latlng, mapRef, customOnClick);
        } else {
          markerObj = prepareMarker(fe, latlng, presentationMode, isSelected,
            mapRef, customOnClick, getUserUnits, false);
        }
        const markerData = Object.values(markerObj)?.[0]?.[0];
        return markerData;
      });

      renderOnSpiderifier(key, updatedMarkers, false, mapVar);
      if (mapVarRight) renderOnSpiderifier(key, updatedMarkers, false, mapVarRight);
    }
  }

  function renderMarkers(e, mapRef) {
    const clickedFeatures = mapRef.queryRenderedFeatures(e.point);

    if (!clickedFeatures.length) {
      return;
    }
    clickedFeatures.forEach((feature) => {
      const layerId = feature?.layer?.id;

      if ((layerId.indexOf('cluster-circle-count') !== -1)
       || (layerId.indexOf('cluster-label') !== -1)) {
        const { clusterId, count } = feature?.properties || {};

        if (count < 7) {
          const children = supercluster.getLeaves(clusterId, Infinity);
          const key = calculateCentroid(children);
          const latlng = { lat: key[1], lng: key[0] };
          const updatedMarkers = children.map((d) => {
            const isSelected = d?.properties?.isSelected;
            let markerObj = {};
            if ((isRanch || showAllMarkers) && d?.properties?.type === 'ranch') {
              markerObj = prepareRanchMarker(d, latlng, mapRef, customOnClick);
            } else {
              markerObj = prepareMarker(d, latlng, presentationMode, isSelected,
                mapRef, customOnClick, getUserUnits, false);
            }
            const markerData = Object.values(markerObj)?.[0]?.[0];
            return markerData;
          });
          renderOnSpiderifier(key, updatedMarkers, false, mapVar);
          if (mapVarRight) renderOnSpiderifier(key, updatedMarkers, false, mapVarRight);
        }
      }
    });
  }

  function getClusterCountAndCenter(mapRef, point) {
    let center;
    let childrenCount = 1;
    const clickedFeatures = mapRef.queryRenderedFeatures(point);
    if (clickedFeatures?.length) {
      const { clusterId } = clickedFeatures?.[0]?.properties || {};
      const children = supercluster.getLeaves(clusterId, Infinity);
      childrenCount = children?.length;
      center = calculateCentroid(children);
    }
    return { center, childrenCount };
  }

  function mouseClick(e, mapRef) {
    try {
      const currZoom = mapRef?.getZoom();
      if (currZoom < SPIDERFY_FROM_ZOOM) {
        const { center, childrenCount } = getClusterCountAndCenter(mapRef, e?.point);
        let zoomVal = SPIDERFY_FROM_ZOOM + 2;
        if (isRanch && childrenCount < MAX_CHILDREN_COUNT
          && currZoom < MAX_ZOOM_FOR_ALL_RANCHES) {
          zoomVal = MAX_ZOOM_FOR_ALL_RANCHES;
        } else {
          zoomVal = currZoom + 2;
        }

        if (center) {
          mapRef.easeTo({
            center,
            zoom: zoomVal
          });
          clearAllClusterCircles(mapRef);
          renderAllClusters(mapVar);
        }
      } else {
        renderMarkers(e, mapRef);
      }
    } catch (err) {
      console.error('Something went wrong: ', err);
    }
  }

  function mouseMove(e, mapRef) {
    const features = mapRef?.queryRenderedFeatures(e.point);
    if (features) {
      features.forEach((feature) => {
        const layerId = feature.layer.id;
        if ((layerId.indexOf('cluster-circle-count') !== -1)
         || (layerId.indexOf('cluster-label') !== -1)) {
          // eslint-disable-next-line no-param-reassign
          mapRef.getCanvas().style.cursor = 'pointer';
        } else {
          // eslint-disable-next-line no-param-reassign
          mapRef.getCanvas().style.cursor = '';
        }
      });
    }
  }

  function onClick(e) {
    mouseClick(e, mapVar);
    if (mapVarRight) mouseClick(e, mapVarRight);
  }

  function onMouseMove(e) {
    mouseMove(e, mapVar);
    if (mapVarRight) mouseMove(e, mapVarRight);
  }

  function onZoomStart() {
    unspiderfyAll();
    removeSelectedClassFromSpiderPins();
    allSpiderLegs = [];
    clearAllClusterCircles(mapVar);
    if (mapVarRight) clearAllClusterCircles(mapVarRight);
    renderAllClusters(mapVar);
  }

  // Clear existing event listeners
  detachEvents(mapVar, eventRef);
  if (mapVarRight) detachEvents(mapVarRight, eventRefRight);

  eventRef.mousemove = onMouseMove;
  eventRef.click = onClick;
  eventRef.zoomstart = onZoomStart;
  if (mapVarRight) {
    eventRefRight.mousemove = onMouseMove;
    eventRefRight.click = onClick;
    eventRefRight.zoomstart = onZoomStart;
  }

  if (presentationMode === 'plants' && !isRanch) {
    // Clear existing event listeners
    detachEvents(mapVar, eventRef);
    unspiderfyAll();
    removeSelectedClassFromSpiderPins();
    allSpiderLegs = [];
    clearAllClusterCircles(mapVar);
    if (mapVarRight) clearAllClusterCircles(mapVarRight);
    return;
  }

  function addSourceAndLayer(geojson, clusterId, colorObj, mapRef) {
    const zoom = mapRef?.getZoom();
    const clusterSourceId = `cluster-circle-${clusterId}`;
    const clusterCountLayerId = `cluster-circle-count-${clusterId}`;
    const clusterCountLabelId= `cluster-label-${clusterId}`;
    if (mapRef?.getLayer(clusterCountLayerId)) mapRef.removeLayer(clusterCountLayerId);
    if (mapRef?.getLayer(clusterCountLabelId)) mapRef.removeLayer(clusterCountLabelId);
    if (mapRef?.getSource(clusterSourceId)) mapRef.removeSource(clusterSourceId);

    if (!mapRef?.getSource(clusterSourceId) && mapRef?.style?._loaded) {
      mapRef.addSource(clusterSourceId, {
        type: 'geojson',
        data: geojson,
      });
    }

    if (!mapRef?.getLayer(clusterCountLayerId) && mapRef?.style?._loaded) {
      mapRef.addLayer({
        id: clusterCountLayerId,
        type: 'circle',
        source: clusterSourceId,
        paint: {
          'circle-color': ['match', ['get', 'clusterId'], clusterId,
            colorObj.color, 'white'],
          'circle-opacity': zoom > 13 ? 0.4 : 0.8,
          'circle-radius': 18,
          'circle-stroke-color': ['match', ['get', 'clusterId'], clusterId,
            colorObj.bgColor, 'rgba(255, 255, 255, 0.6)'],
          'circle-stroke-width': 3,
          'circle-stroke-opacity': zoom > 13 ? 0.2 : 0.4,
        },
      });
    }

    if (!mapRef?.getLayer(clusterCountLabelId) && mapRef?.style?._loaded) {
      mapRef.addLayer({
        id: clusterCountLabelId,
        type: 'symbol',
        source: clusterSourceId,
        paint: {
          'text-color': colorObj.color !== 'white' ? 'white' : 'black',
          'text-opacity': zoom > 13 ? 0.6 : 0.8,
        },
        layout: {
          'text-field': '{count}',
          'text-font': [
            'Open Sans Bold',
          ],
          'text-size': 14,
          'text-allow-overlap': true,
          'text-ignore-placement': true,
        },
      });
    }
  }

  function renderAllClusters(mapRef) {
    const zoom = mapRef?.getZoom();
    const clusters = getClusters(mapRef);
    clusters.forEach((cluster) => {
      if (!cluster?.properties?.cluster && (isRanch || showAllMarkers)) {
        if(isAllRanch){
          if (zoom < maxZoom) renderSingleMarker(cluster, mapVar);
        }
        if (zoom < RANCH_ZOOM_VALUE) renderSingleMarker(cluster, mapVar);
        if (zoom < RANCH_ZOOM_VALUE && mapVarRight){
          renderSingleMarker(cluster, mapVarRight);
        }
      }
      if (typeof cluster.id === 'string' && !isRanch) {
        if (zoom > RANCH_ZOOM_VALUE) renderSingleMarker(cluster, mapVar);
        if (zoom > RANCH_ZOOM_VALUE && mapVarRight) renderSingleMarker(cluster, mapVarRight);
      } else if (cluster) {
        const { cluster_id: clusterId } = cluster.properties;
        if (clusterId) {
          const features = supercluster.getLeaves(clusterId, Infinity);
          const count = cluster.properties.point_count; // Get the count of points in the cluster
          const coordinates = calculateCentroid(features); // Get the coordinates for the cluster
          const colorObj = prepareMarkerClusterIconColor(presentationMode,
          features?.length, features);
          clusterColorMap[clusterId] = colorObj;

          // Create a GeoJSON point feature for the cluster
          const geojson = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates
            },
            properties: {
              clusterId,
              count
            }
          };
          // avoid page break when the style is not loaded
          if(mapVar?.style?._loaded) addSourceAndLayer(geojson, clusterId, colorObj, mapVar);
          if (mapVarRight) addSourceAndLayer(geojson, clusterId, colorObj, mapVarRight);
          if (features?.length < 7 && zoom > 13 && !isRanch) {
            renderClusteredMarkers(features, mapVar);
            if (mapVarRight) renderClusteredMarkers(features, mapVarRight);
          }
          if (zoom > 3 && zoom < 13 && features?.length < 4 && (isRanch || showAllMarkers)) {
            renderClusteredMarkers(features, mapVar);
            if (mapVarRight) renderClusteredMarkers(features, mapVarRight);
          }
        } else if (cluster && !isRanch && cluster?.properties?.type === 'ranch') {
          renderSingleMarker(cluster, mapVar, customOnRanchClick);
          if (mapVarRight) renderSingleMarker(cluster, mapVarRight, customOnRanchClick);
        }
      }
    });
  }

  if (previousPresMode && presentationMode !== previousPresMode) {
    previousPresMode = presentationMode;
    unspiderfyAll();
    allSpiderLegs = [];
    clearAllClusterCircles(mapVar);
    if (mapVarRight) clearAllClusterCircles(mapVarRight);
  }

  function defaultCalls() {
    unspiderfyAll();
    allSpiderLegs = [];
    clearAllClusterCircles(mapVar);
    if (mapVarRight) clearAllClusterCircles(mapVarRight);
    renderAllClusters(mapVar);
  }

  if (mapVar?.getZoom() > 13) defaultCalls();
  if (isRanch && mapVar?.getZoom() < 13) defaultCalls();

  eventRef.drag = defaultCalls; // maintain ref to detach old events before attaching new
  if (mapVarRight) eventRefRight.drag = defaultCalls;

  mapVar.on('mousemove', onMouseMove);
  if (mapVarRight) mapVarRight.on('mousemove', onMouseMove);

  mapVar.once('click', onClick);
  if (mapVarRight) mapVarRight.once('click', onClick);
  if (!isRanch) mapVar.on('zoomstart', onZoomStart);

  // Drag event to solve sensor markers are hidden after panning map issue
  if (!isRanch) mapVar.on('drag', defaultCalls);
  if (!isRanch && mapVarRight) mapVarRight.on('drag', defaultCalls);
}
