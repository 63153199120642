import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import ControlValveStatus from './ControlValveStatus';
import ValveOverrideButtons from '../ControlSummary/ValveOverrideButtons';
import PressureSummaryList from '../ControlSummary/PressureSummaryList';
import { useTracking } from '../../../../helper/mixpanel';
import { sortArrayOfObjectsByProperty } from '../../../../helper/common';
import { isMobile } from '../../../../utils/detectDevice';

const {
  selectPressureDetails,
  selectBlockValveControllers,
} = selectors;

const {
  loadBlockIrrigationSchedule,
  loadBlockSchedule, loadSensorStatus:
  loadSensorStatusAction,
} = actions;

const { useBlockNames } = hooks;

const REFRESH_INTERVAL = 1000 * 30;

const getValveDetails = (data, bName) => {
  const valveObj = {
    blockSchedule: [],
    irrigationStartDate: null,
  };

  if (data && data.scheduleStatus) {
    const { scheduleStatus } = data;
    valveObj.irrigationStartDate = scheduleStatus.dateScheduledOpen;
    valveObj.blockSchedule.push({
      blockName: bName,
      scheduledTime: scheduleStatus.dateScheduledOpen,
      endTime: scheduleStatus.dateScheduledClose,
    });
  }
  return valveObj;
};

export function ControlValveDetail(props) {
  const {
    match, valveId, showPressureSummary, valveIdentifier,
  } = props;
  let valveDetails = [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const tracking = useTracking();
  const interval = useRef(null);
  const blockId = match.params ? Number(match.params.id) : valveId;

  const pressureSensors = useSelector((state) => selectPressureDetails(state, blockId));
  const valveObj = useSelector((state) => selectBlockValveControllers(state, blockId)) || {};
  const { state } = location;
  const vIdentifier = (state && state.identifier) || valveIdentifier;

  const valveData = valveObj?.[vIdentifier];
  const valveOverrideState = valveData?.status?.overrideStatus?.state;
  const blockIds = (valveData?.irrigation_blocks?.length && valveData.irrigation_blocks)
   || [blockId];

  const blockName = useBlockNames(blockIds);
  const valveParams = JSON.stringify([
    { type: valveData?.type, identifier: valveData?.identifier }]);

  useEffect(() => {
    if (tracking) tracking.track('Loaded Valve Details', { blockId });
  }, [blockId, tracking]);

  useEffect(() => {
    dispatch(loadBlockIrrigationSchedule(blockId));
    // Loading old API for Actual gallons details
    dispatch(loadBlockSchedule(blockId));
  }, [blockId, dispatch]);

  const loadSensorsStatus = useCallback((sensorParams) => {
    sensorParams.forEach((sensorParam) => {
      dispatch(loadSensorStatusAction(sensorParam));
    });
  }, [dispatch]);

  useEffect(() => {
    const parsedSensorParams = JSON.parse(valveParams);
    loadSensorsStatus(parsedSensorParams);
    interval.current = setInterval(() => {
      loadSensorsStatus(parsedSensorParams);
    }, REFRESH_INTERVAL);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [loadSensorsStatus, valveParams, valveOverrideState]);

  const allValveDetails = Object.keys(valveObj).map((d) => {
    const vCtrl = valveObj[d];
    const statusObj = vCtrl && vCtrl.status;
    if (statusObj) {
      return {
        valveCurrentState: (statusObj?.controlStatus?.state) || '',
        blockName: blockName[blockId],
        alarmCount: statusObj.alarmCount,
        valveName: vCtrl.name ?? vCtrl.identifier,
        lastUpdated: statusObj.lastUpdated,
        latestDate: statusObj.latestDate,
        valveOnline: statusObj.online,
        // These units are expected from valve status api
        units: statusObj.units || {
          pressureCutoff: 'psi',
          actualVolume: 'gallons',
        },
        identifier: vCtrl.identifier,
        type: vCtrl.type,
        irrigationStartDate: getValveDetails(statusObj, blockName[blockId]).irrigationStartDate,
        valveSchedule: getValveDetails(statusObj, blockName[blockId]).valveSchedule,
      };
    }
    return null;
  });

  const filteredValveDetail = allValveDetails.filter((d) => d && d.identifier === vIdentifier);

  if (filteredValveDetail.length) {
    valveDetails = filteredValveDetail;
  } else valveDetails = allValveDetails;

  const sortedValveDetails = sortArrayOfObjectsByProperty(valveDetails, 'valveName');

  const isMultipleValves = valveDetails.length > 1;

  if (!valveDetails[0]) {
    return (
      <div className="block-details-additional-info mobile-list-item">
        {t('Valve details not available')}
      </div>
    );
  }

  function renderDetails() {
    return (
      <div className="block-details-additional-info mobile-list-item">
        {sortedValveDetails.map((valveDetail, index) => valveDetail && (
          <>
            <ControlValveStatus
              valve={valveDetail}
              showHeader={!index}
              showBlockName={isMultipleValves}
            />
            <div className="valve-button">
              <ValveOverrideButtons
                irrigationStartDate={valveDetail.irrigationStartDate}
                valveSchedule={valveDetail.valveSchedule}
                valveName={`${valveDetail.blockName} (${t('Valve')})`}
                valveIdentifier={valveDetail.identifier}
                valveOverrideStatus={valveDetail.overrideStatus}
              />
            </div>
          </>
        ))}
        {showPressureSummary
        && (
        <Link
          to={{
            pathname: `/list/pressure-sensors/${blockId}`,
            state: { prevPath: window.location.pathname },
          }}
        >
          {blockIds.map((blkId) => (
            <PressureSummaryList
              displayTitle={false}
              id={`pressure-${blockName[blkId]}`}
              pressure={pressureSensors.pressure}
              valveList={pressureSensors.sensorList}
              loading={pressureSensors.loading}
              pressureCutoff={pressureSensors.pressureCutoff}
              units={valveDetails?.units}
              blockName={blockName[blkId]}
            />
          ))}
        </Link>
        )}
      </div>
    );
  }

  return renderDetails();
}

ControlValveDetail.defaultProps = {
  history: {},
  match: {},
  valveId: null,
  showPressureSummary: true,
  valveIdentifier: null,
};

ControlValveDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  valveId: PropTypes.number,
  showPressureSummary: PropTypes.bool,
  valveIdentifier: PropTypes.string,
};
