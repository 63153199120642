import React from 'react';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_SOIL_MOISTURE_ROOTZONE_VWC } from './constants';
import { usePrepareVWCForecastGraphConfig } from './graphHooks';

interface ForecastRootZoneVWCChartProps {
  sensor: any | null;
  startDate?: Object | null;
  endDate?: Object | null;
  cached?: String | null | undefined;
  forecastData?: any;
  depthRootzone?: number | undefined;
}

const ForecastRootZoneVWCChart: React.FC<ForecastRootZoneVWCChartProps> = ({
  sensor, startDate, endDate, cached,
  forecastData, depthRootzone,
}) => {
  // Name for series2
  const series2Name = 'Forecasted Vwc (%)';
  const customConfig = usePrepareVWCForecastGraphConfig(forecastData, depthRootzone, series2Name);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_SOIL_MOISTURE_ROOTZONE_VWC}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
      />
    </>
  );
};

export default ForecastRootZoneVWCChart;
