import { useSelector } from 'react-redux';
// @ts-ignore
import { selectors } from 'farmx-redux-core'; // ensure correct import path

function useEntities() {
  const entities = useSelector((state: any) => selectors.selectAllNewEntities(state));
  const activeEntityId = useSelector((state: any) => selectors.selectSelectedEntity(state)).payload;
  const userInfo = useSelector(selectors.selectLoginUserInfo).payload;

  let selectedEntity = useSelector((state) => selectors.selectEntityById(state, activeEntityId));
  /**
   * If no entity selected, select default entity from env for admin user &
   * first entity for non-admin user
   * */
  const defaultEntityId = process.env.REACT_APP_DEFAULT_ENTITY_ID;
  const entity = useSelector((state) => selectors.selectEntityById(state, defaultEntityId));
  if(!selectedEntity) {
    selectedEntity = userInfo?.admin ? entity : entities[0];
  }

  return {
    entities,
    selectedEntity,
  };
}

export default useEntities;
