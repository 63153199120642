import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {message, Radio, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { coordinationApi } from 'farmx-api';
import { LoadingOutlined } from '@ant-design/icons';

const sprinklerOptions = [
  { label: 'Pressure', value: 'pressure_sprinkler' },
  { label: 'Disable', value: 'disable' },
];

const RenderSprinklerBlock = (props) => {
  const { block } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const selected = useRef('');

  const configType = 'sprinkler';

  const sprinklerConfig = useSelector((state) => selectors
    .selectSprinklerConfigByBlockId(state, block.id)) || {};

  const coordinationLoading = useSelector((state) => selectors.selectCoordinationLoading(state));


  const onChange = useCallback(async (e) => {
    setIsLoading(true);
    try {
      // TODO: This will be removed after testing
      console.log('RenderSprinklerBlock :: ', {
        blockId: block.id,
        actionType: configType,
        actionSubType: e.target.value,
        autoMode: e.target.value !== 'disable'
      });

      await coordinationApi.updateSensorCoordination({
        blockId: block.id,
        actionType: configType,
        actionSubType: e.target.value,
        autoMode: e.target.value !== 'disable'
      });

      // Update redux store when api is called successfully
      await dispatch(actions.updateConfigField({
        blockId: block.id,
        configType,
        field: e.target.value,
      }));

      setIsLoading(false);
    } catch {
      message.error(t('Update failed'));
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.id, dispatch]);
  useEffect(() => {
    if(!isEmpty(sprinklerConfig)){
      const isDisabled = Object.keys(sprinklerConfig).every((key) => !sprinklerConfig[key]);

      // when isDisabled is false, set selected to the key of the sprinklerConfig
      // object where the value is true
      selected.current = isDisabled ? 'disable' : Object.keys(sprinklerConfig)
        .filter((key) => sprinklerConfig[key])[0];
    }
  }, [block.id, sprinklerConfig]);

  return (
    <div className="render-block-settings">
      <Typography.Text>
        {block.name}
        {(isLoading || coordinationLoading) && <LoadingOutlined className="margin-left-10" />}
      </Typography.Text>
      <Radio.Group disabled={isLoading} onChange={onChange} value={selected.current}>
        {
         sprinklerOptions.map((v) => (
           <Radio key={v.value} value={v.value}>{v.label}</Radio>
         ))
        }
      </Radio.Group>
    </div>
  );
};

RenderSprinklerBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
};

export default RenderSprinklerBlock;
