import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { SatelliteImagery } from '../../map/components/satelliteimagery/SatelliteImagery';
import {
  useRanchBlockPreference,
  useSatelliteImagery,
  useSatelliteDataDatesAndTypes,
  useSatelliteDateRangePreparation,
  useDefaultSelectedImageryData,
  useRequestSatelliteData,
  useRequestDroneDate,
  useDroneImageryDataForSelectedDate,
} from '../../../helper/satelliteHooks';
import { isMobile } from '../../../utils/detectDevice';
import { isImageryUpdateNeeded } from '../../../helper/mapHelper';

const {
  selectSelectedRanch,
  selectSelectedBlock,
  selectSelectedBlocks,
  selectNewBlockById,
} = selectors;

const { useRanchBlockSelection } = hooks;

export function SatelliteImageryWrapper(props) {
  const {
    ranchId, childComponent, isLeft, onClick,
    dispatchMapLeft, dispatchMapRight, stateArg,
  } = props;

  const satelliteDispatchArg = isLeft ? dispatchMapLeft : dispatchMapRight;
  const selectedRanchIdFromState = useSelector((state) => selectSelectedRanch(state));
  const selectedBlockIdFromState = useSelector((state) => selectSelectedBlock(state));
  const selectedBlockIdsFromState = useSelector((state) => selectSelectedBlocks(state));

  const { blockIds } = useRanchBlockSelection();
  const blkIds = selectedBlockIdsFromState?.payload;
  const {
    selectedRanchId, selectedBlockId, latestReload, satelliteDates, selectedSatelliteDataSource,
    selectedSatelliteDatesFiltered, selectedSatelliteDatesDataRange, availableDroneDates,
    availableDroneDataTypes, selectedDroneDate, selectedDroneData, selectedDroneTilesData,
    selectedSatelliteDataType, selectedDroneDataType, dateRangeWithDisplayData,
    selectedImageryData, selectedSatelliteDate,
  } = stateArg || {};

  // Actions to maintain the default satellite imagery selection for split screen view
  const DISPATCH_MAP_LEFT = !isLeft && !selectedImageryData?.data?.length
    ? dispatchMapLeft : undefined;
  const DISPATCH_MAP_RIGHT = isLeft && !selectedImageryData?.data?.length
    ? dispatchMapRight : undefined;

  // Actions to maintain the default drone imagery selection for split screen view
  const DRONE_DISPATCH_MAP_LEFT = !isLeft && !selectedDroneTilesData
    ? dispatchMapLeft : undefined;
  const DRONE_DISPATCH_MAP_RIGHT = isLeft && !selectedDroneTilesData
    ? dispatchMapRight : undefined;

  const isSatellite = selectedSatelliteDataSource === 'satellite';

  const callApi = isImageryUpdateNeeded(selectedImageryData.data, selectedSatelliteDate,
    selectedSatelliteDatesDataRange, selectedSatelliteDataType, selectedBlockIdFromState?.payload,
    ranchId, isMobile ? ranchId : selectedRanchIdFromState?.payload?.[0], blkIds || blockIds);

  const blockObj = useSelector((state) => selectNewBlockById(state, blockIds?.[0]));
  const blockIdStr = blockObj && blockObj.am_block_id;

  // Set ranch/block preference to local reducer
  useRanchBlockPreference(
    selectedBlockIdFromState?.payload?.length ? undefined : selectedRanchIdFromState,
    selectedBlockIdFromState,
    satelliteDispatchArg,
  );

  // get satellite imagery using local reducer
  useSatelliteImagery(
    selectedRanchId,
    selectedBlockId,
    latestReload,
    callApi,
    satelliteDispatchArg,
  );

  // prepare satellite data dates and types using local reducer
  useSatelliteDataDatesAndTypes(
    satelliteDates,
    selectedSatelliteDataType,
    selectedSatelliteDataSource,
    satelliteDispatchArg,
  );

  // SatelliteDateRange preparation using local reducer
  useSatelliteDateRangePreparation(
    selectedSatelliteDatesFiltered,
    selectedSatelliteDate,
    selectedSatelliteDatesDataRange,
    selectedSatelliteDataType,
    selectedSatelliteDataSource,
    selectedRanchId,
    selectedBlockId,
    satelliteDispatchArg,
    DISPATCH_MAP_LEFT,
    DISPATCH_MAP_RIGHT
  );

  // To maintain the selected imagery data on the map
  useDefaultSelectedImageryData(
    dateRangeWithDisplayData,
    selectedImageryData,
    selectedSatelliteDate,
    satelliteDispatchArg,
    DISPATCH_MAP_LEFT,
    DISPATCH_MAP_RIGHT
  );

  // request satellite imagery data
  useRequestSatelliteData(selectedSatelliteDatesDataRange, callApi,
    isSatellite, satelliteDispatchArg, DISPATCH_MAP_LEFT, DISPATCH_MAP_RIGHT);

  // Get available drone imagery data and set to state
  useRequestDroneDate(ranchId, isSatellite, selectedDroneDate, blockIdStr,
    satelliteDispatchArg, false, DRONE_DISPATCH_MAP_LEFT, DRONE_DISPATCH_MAP_RIGHT);

  // To maintain the selected drone imagery data for the selected date
  useDroneImageryDataForSelectedDate(ranchId, selectedDroneDataType,
    selectedDroneData, satelliteDispatchArg, DRONE_DISPATCH_MAP_LEFT, DRONE_DISPATCH_MAP_RIGHT);

  return (
    <div
      className={isMobile && selectedImageryData
        ? 'padding-20 margin-bottom-50 flex-row '
        + 'satellite-imagery-container-section minus-margin-top-10'
        : 'flex-row satellite-imagery-container-section'}
      id="satellite-imagery-section"
    >
      <div className="flex-column switch-box layout-width">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {!!ranchId && (
          <SatelliteImagery
            dispatchMapPage={satelliteDispatchArg}
            satelliteDates={isSatellite ? satelliteDates : availableDroneDataTypes}
            selectedSatelliteDate={isSatellite ? selectedSatelliteDate : selectedDroneDate}
            selectedSatelliteDataType={isSatellite ? selectedSatelliteDataType
              : selectedDroneDataType}
            selectedSatelliteDataSource={selectedSatelliteDataSource}
            selectedSatelliteDatesFiltered={isSatellite ? selectedSatelliteDatesFiltered
              : availableDroneDates}
            dateRangeWithDisplayData={dateRangeWithDisplayData}
            selectedImageryData={isSatellite ? selectedImageryData : selectedDroneData}
            isMobile={isMobile}
            childComponent={childComponent}
            isSatellite={isSatellite}
            onClick={onClick}
          />
          )}
        </div>
      </div>
    </div>
  );
}

SatelliteImageryWrapper.propTypes = {
  ranchId: PropTypes.number,
  childComponent: PropTypes.func,
  isLeft: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  dispatchMapLeft: PropTypes.func,
  dispatchMapRight: PropTypes.func,
  stateArg: PropTypes.shape({}),
};

SatelliteImageryWrapper.defaultProps = {
  ranchId: null,
  childComponent: null,
  isLeft: true,
  dispatchMapLeft: undefined,
  dispatchMapRight: undefined,
  stateArg: {}
};
