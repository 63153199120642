import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import SprinklerControlSection from './SprinklerControlSection';
import IrrigationControlSection from './IrrigationControlSection';
import ThresholdSettings from './ThresholdSettings';
import Page404 from '../../app/Page404';
import './irrigation-settings.less';
import { isMobile } from '../../../utils/detectDevice';
import {
  GEC_RANCH_WATERMELON_REPL, GEC_ENTITY_ID,
  SOURCE_RANCH_IDS, GEC_RANCH_ID
} from '../../../constants';

const { useRanchBlockSelection, useEntities } = hooks;

const {
  setRanchBlockSelection, setRanch, setBlocks,
} = actions;

const { selectLoginUserInfo } = selectors;

const BlockIrrigationSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeEntityId } = useEntities();

  const ranches = useSelector(selectors.selectAllNewRanches) || [];
  const [selectedRanchId] = useSelector(selectors.selectSelectedRanch)?.payload || [];
  const { selectedObjFromState } = useRanchBlockSelection();
  const isBlock = selectedObjFromState?.type === 'block';
  const selectedBlockId = isBlock ? Number(selectedObjFromState?.value) : undefined;
  const userInfo = useSelector(selectLoginUserInfo).payload;

  /**
 * Special Case: For GEC Project.
 * @description: Filter out GEC ranch through their ranch ID.
 * When to remove/Refactor: Once we start implementing
 * Sensor co-ordination configuration through frontend.
 */
  const gecRanch = ranches.filter((ranch) => ranch.id === GEC_RANCH_ID);
  const gecRanchReplication = ranches.filter((ranch) => ranch.id === GEC_RANCH_WATERMELON_REPL);
  const gecSourceRanches = ranches.filter((ranch) => [...(SOURCE_RANCH_IDS || [])]
    ?.includes(ranch.id));
  const isSourceRanchSelected = [...(SOURCE_RANCH_IDS || [])]?.includes(selectedRanchId);

  useEffect(() => {
    dispatch(actions.loadAllCoordination());
  }, [dispatch]);

  function handleSelect(d: any) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      if (d?.type === 'block' && !isMobile) dispatch(setBlocks([Number(d?.value)]));
      if (d?.type === 'ranch' && !isMobile) dispatch(setRanch(Number(d?.value)));
    }
  }

  // Show 404:Page when GEC not selected
  if (activeEntityId !== GEC_ENTITY_ID) return <Page404 />;

  return (
    <div
      className={`${!isMobile ? 'single-column-page desktop-container  height-max' : ''}
       block-irrigation-settings div-default padding-20 background-white`}
    >
      <PageHeader
        title={t('Replication Controls')}
        level={2}
        customClassName="margin-top-20"
        toPath="/"
      />
      <div className="div-select-container ranch-block-select-container margin-top-20">
        {isMobile && (
        <RanchBlockSelectMobile
          onSelect={handleSelect}
          selected={selectedObjFromState}
          admin={userInfo && userInfo.admin}
        />
        )}
      </div>
      { gecRanch.length > 0
      && (
      <>
        <IrrigationControlSection ranches={gecRanch} season2024 />
        <SprinklerControlSection ranches={gecRanch} season2024 />
      </>
      )}

      {gecRanchReplication.length && <IrrigationControlSection ranches={gecRanchReplication} />}
      {gecRanchReplication.length && <SprinklerControlSection ranches={gecRanchReplication} />}
      <div className="margin-top-10">
        <h2 className="font-bold">{t('Threshold Settings')}</h2>
        {gecSourceRanches?.length > 0 && isSourceRanchSelected ? (
          <ThresholdSettings selectedBlock={selectedBlockId} selectedRanch={selectedRanchId} />
        ) : <Alert message={t('Please select the source ranch')} type="warning" />}
      </div>
    </div>
  );
};

export default BlockIrrigationSettings;
