import { useState, useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { hooks, selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import SoilMoistureAllDepthsChart from 'components/graph/SoilMoistureAllDepthsChart';
import WaterPressureChart from 'components/graph/WaterPressureChart';
import { isWeatherStation } from 'helper/graphHelper';
import PctAvailableWaterChart from 'components/graph/PctAvailableWaterChart';
import ValveStateChart from 'components/graph/ValveStateChart';
import SoilTemperatureChart from 'components/graph/SoilTemperatureChart';
import Et0Chart from 'components/graph/Et0Chart';
import AirTemperatureChart from 'components/graph/AirTemperatureChart';
import RainfallChart from 'components/graph/RainfallChart';
import EtcChart from 'components/graph/EtcChart';
import AirHumidityChart from 'components/graph/AirHumidityChart';
import CropStressChart from 'components/graph/CropStressChart';
import SolarRadiationChart from 'components/graph/SolarRadiationChart';
import WindSpeedChart from 'components/graph/WindSpeedChart';
import WindDirectionChart from 'components/graph/WindDirectionChart';
import RootZoneVWCChart from '../graph/RootZoneVWCChart';

// Chart types constant
export const ROOT_ZONE_VWC = 'root_zone_vwc';
export const SOIL_MOISTURE_ALL_DEPTHS='soil_moisture_all_depths';
export const WATER_PRESSURE = 'water_pressure';
export const PCT_AVAILABLE_WATER = 'pct_available_water';
export const VALVE_STATE = 'valve_state';
export const SOIL_TEMPERATURE = 'soil_temperature';
export const ET0 = 'et0';
export const ETC = 'etc';
export const AIR_TEMPERATURE = 'air_temp';
export const RAINFALL = 'rainfall';
export const AIR_HUMIDITY = 'air_humidity';
export const CROP_STRESS = 'crop_stress';
export const SOLAR_RADIATION = 'solar_radiation';
export const WIND_SPEED = 'wind_speed';
export const WIND_DIRECTION = 'wind_direction';

const WATER_FLOW_ANALOG = 'water_flow_analog';
const VALVE = 'valve';
const VFD = 'vfd';

const {
  useRanchBlockSelection,
  // useUnits
} = hooks;

const {
  selectSensorsForBlockId,
  selectLoadingSensors,
} = selectors;

// return yMaxMin value object for give type
// export function useGlobalYminmaxArray(type, yMin, yMax){
//   const [yMinMaxArray, setYMinMaxArray] = useState({});
//   useEffect(() => {
//     if(type && yMin && yMin){
//       setYMinMaxArray((prevState) => ({
//         ...prevState,
//         [type]: [...prevState[type], yMin, yMax]
//       }));
//     }
//   }, [type, yMin, yMax]);
//   return yMinMaxArray[type];
// }

// TODO remove this if not used
// return sensor type map for selected ranch or block
export function usePrepareSensorMap(){
  // Get selected ranch/blocks,
  const { blockIds } = useRanchBlockSelection();
  const [localBlockIds, setLocalBlockIds] = useState([]);
  const [sensorTypeMap, setSensorTypeMap] = useState(null);

  // To set and use the blockIds from local state to avoid re-rendering
  useEffect(() => {
    if (!isEqual(blockIds, localBlockIds)) {
      setLocalBlockIds(blockIds);
    }
  }, [blockIds, localBlockIds]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('blockIds changed', blockIds);
  // }, [blockIds]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('localBlockIds changed', localBlockIds);
  // }, [localBlockIds]);

  // TODO remove after testing
  // console.log('Selected ranch block blocks', ranchId, blockIds);

  // Get all the sensors for the given blocks,
  const sensors = useGetAllSensorsForBlockIds(blockIds);

  useEffect(() => {
    // TODO remove after testing
    // console.log('sensors changed......', sensors);
    if(sensors.length){
      const sensorTypeMap = sensors.reduce((acc, sensor) => {
        if(acc[sensor.type]){
          acc[sensor.type].push(sensor);
        } else acc[sensor.type]=[sensor];
        return acc;
      }, {});
      setSensorTypeMap(sensorTypeMap);
    }
  }, [sensors]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('sensors localBlockIds changed', sensors, localBlockIds);
  // }, [localBlockIds, sensors]);

  // return after grouping according to sensor type
  return sensorTypeMap;
}

// TODO remove this if not useful. kept for reference
export function usePrepareChartList1(sensorTypeMap, selectedPreset){
  // state to store chartList
  const [chartList, setChartList] = useState([]);
  useEffect(() => {
    function prepareChartList(sensorTypeMap, selectedPreset){
      const chartList = [];
      // for a given preset get all the chart types from presetChartTypeMap
      presetChartTypeMap[selectedPreset].forEach((chartType, chartTypeIndex) => {
        // TODO remove after testing
        // console.log('chartType', chartType);
        // for each chart type get the sensor type from presetChartSensorTypeMap
        presetChartSensorTypeMap[chartType].forEach((sensorType) => {
          // TODO remove after testing
          // console.log('sensorType', sensorType, sensorTypeMap[sensorType]);
          const sensors = sensorTypeMap[sensorType];
          if(sensors){
          // for each sensor type get all the sensors
            sensors.forEach((sensor, sensorIndex) => {
              // for each sensor create chartObject
              chartList.push({
                graph: {
                  key: `${sensor.name}-${sensorIndex}-${chartTypeIndex}`,
                  component: chartTypeComponentMap[chartType],
                  props: {
                    sensor,
                  }
                }
              });
              // handleOptionalCharts(sensor,
              //   sensorIndex,
              //   sensorTypeMap,
              //   selectedPreset,
              //   optionalCharts,
              //   chartList);
            });
          }
        });
      });
      setChartList(chartList);
    }
    if(sensorTypeMap && Object.keys(sensorTypeMap).length){
      prepareChartList(sensorTypeMap, selectedPreset);
    }
  }, [selectedPreset, sensorTypeMap]);
  return chartList;
}

// TODO remove this if not used
export function useHandleChartMap(chartOptions){
  const [chartMap, setChartMap] = useState({
    aquacheck_soil: [],
    pixl_soil: [],
    water_pressure: []
  });

  useEffect(() => {
    console.log('chartMap', chartMap);
    if(chartOptions.includes(SOIL_MOISTURE_ALL_DEPTHS)){
      addChart('aquacheck_soil', SOIL_MOISTURE_ALL_DEPTHS, chartMap, setChartMap, 'first');
      addChart('pixl_soil', SOIL_MOISTURE_ALL_DEPTHS, chartMap, setChartMap, 'first');
    }else {
      removeChart('aquacheck_soil', SOIL_MOISTURE_ALL_DEPTHS, chartMap, setChartMap);
      removeChart('pixl_soil', SOIL_MOISTURE_ALL_DEPTHS, chartMap, setChartMap);
    }

    if(chartOptions.includes(ROOT_ZONE_VWC)){
      addChart('aquacheck_soil', ROOT_ZONE_VWC, chartMap, setChartMap, 'first');
      addChart('pixl_soil', ROOT_ZONE_VWC, chartMap, setChartMap, 'first');
    }else {
      removeChart('aquacheck_soil', ROOT_ZONE_VWC, chartMap, setChartMap);
      removeChart('pixl_soil', ROOT_ZONE_VWC, chartMap, setChartMap);
    }

    if(chartOptions.includes(PCT_AVAILABLE_WATER)){
      addChart('aquacheck_soil', PCT_AVAILABLE_WATER, chartMap, setChartMap, 'first');
      addChart('pixl_soil', PCT_AVAILABLE_WATER, chartMap, setChartMap, 'first');
    }else {
      removeChart('aquacheck_soil', PCT_AVAILABLE_WATER, chartMap, setChartMap);
      removeChart('pixl_soil', PCT_AVAILABLE_WATER, chartMap, setChartMap);
    }

    if(chartOptions.includes(WATER_PRESSURE)){
      addChart('water_pressure', WATER_PRESSURE, chartMap, setChartMap);
    }else {
      removeChart('water_pressure', WATER_PRESSURE, chartMap, setChartMap);
    }

    if(chartOptions.includes(SOIL_TEMPERATURE)){
      addChart('aquacheck_soil', SOIL_TEMPERATURE, chartMap, setChartMap, 'first');
      addChart('pixl_soil', SOIL_TEMPERATURE, chartMap, setChartMap, 'first');
    }else {
      removeChart('aquacheck_soil', SOIL_TEMPERATURE, chartMap, setChartMap);
      removeChart('pixl_soil', SOIL_TEMPERATURE, chartMap, setChartMap);
    }
  }, [chartMap, chartOptions]);

  return chartMap;
}

// TODO remove this if not used
function addChart(sensorType, chartType, chartMap, setChartMap, position){
  if(chartMap[sensorType] && !chartMap[sensorType].includes(chartType)){
    if(position==='first'){
      setChartMap((prev) => ({
        ...prev,
        [sensorType]: [chartType, ...prev[sensorType]]
      }));
    }else{
      setChartMap((prev) => ({
        ...prev,
        [sensorType]: [...prev[sensorType], chartType]
      }));
    }
  }
}

// TODO remove this if not used
function removeChart(sensorType, chartType, chartMap, setChartMap){
  if(chartMap[sensorType] && chartMap[sensorType].includes(chartType)){
    setChartMap((prev) => ({
      ...prev,
      [sensorType]: [...prev[sensorType].filter((d) => d!==chartType)]
    }));
  }
}

// TODO remove this if not used
function handleChartDisplayOrder(selectedPreset, chartType, sensorTypeMap, sensorForChart){
  if(selectedPreset==='soil' && sensorForChart.type==='aquacheck_soil'){
    if(chartType===WATER_PRESSURE){
      const sensors = sensorTypeMap.water_pressure;
      const waterPressureSensors = sensors
        .filter((wpSensor) => wpSensor.block
        && sensorForChart.block && wpSensor.block === sensorForChart.block)
      // .filter((wpSensor) => wpSensor.node &&
      // sensorForChart.node && wpSensor.node === sensorForChart.node)
        .filter((wpSensor) => isRelatedSensor(wpSensor, sensorForChart));
      // TODO remove after testing
      // console.log('waterPressureSensors', waterPressureSensors);
      if(waterPressureSensors.length){
        return waterPressureSensors[0];
      }
      return null;
    }
  }
  return sensorForChart;
}

// TODO remove this if not used
function isRelatedSensor(wpSensor, soilSensor){
  const pattern = /\D*/;
  const result1 = soilSensor.name?.match(pattern);
  const result2 = wpSensor.name?.match(pattern);

  const soilSensorNamePart = soilSensor.name?.split(result1)[1];
  const wpSensorNamePart = wpSensor.name?.split(result2)[1];
  const nameMatch = !isEmpty(soilSensorNamePart)
  && !isEmpty(wpSensorNamePart)
  && !soilSensorNamePart.indexOf(wpSensorNamePart);
  return nameMatch;
}

export function usePrepareChartList(blockIds, selectedPreset, chartOptionsSelected, sensors, sensorsToCompare){
  // state to store chartList
  const [chartSensors, setChartSensors] = useState([]);
  const [chartList, setChartList] = useState([]);
  useEffect(() => {
    setChartSensors(() => [...sensors, ...sensorsToCompare]);
  }, [sensors, sensorsToCompare]);
  // TODO remove after testing
  // console.log('sensors usePrepareChartList', sensors);
  // TODO remove console logs after testing
  useEffect(() => {
    // console.log('sensorTypeChartMap changed...', chartOptionsSelected);
    function prepareChartList(chartOptionsSelected, selectedPreset){
      const chartList = [];
      // console.log('sensorsNewData', sensorsNewData, chartOptionsSelected);
      // loop through sensors
      chartSensors.forEach((sensor, sensorIndex) => {
        // console.log('sensor', sensor);
        // for a given sensor get sensor type
        // check if this sensor type is a part of default sensor type list of preset
        // and there is at least one chart type mapped for that sensor type
        const presetConfig = presetSensorTypeChartMap[selectedPreset];
        // console.log('presetConfig', presetConfig);
        if(presetConfig.defaultSensorTypes.includes(sensor.type)
            && sensorTypeChartsMap[sensor.type]?.length){
          sensorTypeChartsMap[sensor.type].forEach((chartType, chartTypeIndex) => {
            // console.log('sensor chartType', sensor, chartType);
            // if the chart type is in optionalCharts list of preset
            if(presetConfig.optionalCharts?.includes(chartType)){
              // if chartType a part of chartOptionsSelected
              // i.e. if the chart type is optional for a preset
              // then display it only if selected by user in chart options
              if(chartOptionsSelected?.includes(chartType)){
                // push to chart list
                chartList.push({
                  graph: {
                    key: `${sensor.identifier}-${chartType}`,
                    component: chartTypeComponentMap[chartType],
                    props: {
                      sensor,
                    }
                  }
                });
              }
            } else {
              // push to chart list
              chartList.push({
                graph: {
                  key: `${sensor.identifier}-${chartType}`,
                  component: chartTypeComponentMap[chartType],
                  props: {
                    sensor,
                  }
                }
              });
            }
          });
        }
      });
      // console.log('chartList', chartList);
      setChartList(chartList);
    }

    if(chartSensors) prepareChartList(chartOptionsSelected, selectedPreset);
  }, [chartOptionsSelected, selectedPreset, chartSensors]);
  return chartList;
}

// TODO remove this if not useful. kept for reference
export function usePrepareChartList2(sensorTypeMap, selectedPreset, sensorTypeChartMap){
  // state to store chartList
  const [chartList, setChartList] = useState([]);
  useEffect(() => {
    // TODO remove after testing
    // console.log('sensorTypeChartMap changed...', sensorTypeChartMap);
    function prepareChartList(sensorTypeMap, selectedPreset){
      const chartList = [];
      // for a given preset get all the sensor types from presetSensorTypeMap
      presetSensorTypeMap[selectedPreset].forEach((sensorType) => {
        // TODO remove after testing
        // console.log('sensorType', sensorType);


        // TODO remove after testing
        // console.log('sensorType', sensorType, sensorTypeMap[sensorType]);
        // for each sensor type get all the sensors
        const sensors = sensorTypeMap[sensorType];
        if(sensors){
          sensors.forEach((sensor, sensorIndex) => {
            // for each sensor create chartObject for each chart type
            // for each sensorType  get the chart type from sensorTypeChartMap
            if(sensorTypeChartMap[sensorType]) {
              sensorTypeChartMap[sensorType].forEach((chartType, chartTypeIndex) => {
                const sensorForChart = handleChartDisplayOrder(
                  selectedPreset,
                  chartType,
                  sensorTypeMap,
                  sensor
                );
                // TODO remove after testing
                // console.log('chartType', chartType);
                // console.log('sensorForChart', sensorForChart);
                if(sensorForChart){
                  const chartIndex = chartList.filter((chart) => chart.graph.key===`${sensorForChart.name}-${sensorIndex}-${chartTypeIndex}`);
                  if(chartIndex.length === 0){
                    chartList.push({
                      graph: {
                        key: `${sensorForChart.name}-${sensorIndex}-${chartTypeIndex}`,
                        component: chartTypeComponentMap[chartType],
                        props: {
                          sensor: sensorForChart,
                        }
                      }
                    });
                  }
                }
              // handleOptionalCharts(sensor,
              //   sensorIndex,
              //   sensorTypeMap,
              //   selectedPreset,
              //   optionalCharts,
              //   chartList);
              });
            }
          });
        }
      });
      // TODO remove after testing
      // console.log('chartList', chartList);
      setChartList(chartList);
    }
    if(sensorTypeMap && Object.keys(sensorTypeMap).length){
      prepareChartList(sensorTypeMap, selectedPreset);
    }
  }, [selectedPreset, sensorTypeChartMap, sensorTypeChartMap.length, sensorTypeMap]);
  return chartList;
}

export const useGetUniqueSensorData = (sensorsData) => {
  const [sensorDataNew, setSensorDataNew] = useState([]);
  useEffect(() => {
    try {
      const SensorDataNew = [];
      const nodeWiseMapping = {};
      sensorsData.forEach((sensorsDataItem) => {
        if(isWeatherStation(sensorsDataItem)){
          SensorDataNew.push(sensorsDataItem);
        }else if (nodeWiseMapping[sensorsDataItem.node]) {
          nodeWiseMapping[sensorsDataItem.node][sensorsDataItem.identifier] = sensorsDataItem;
        } else {
          nodeWiseMapping[sensorsDataItem.node] = {};
          nodeWiseMapping[sensorsDataItem.node][sensorsDataItem.identifier] = sensorsDataItem;
        }
      });
      // TODO remove after testing
      // console.log('nodeWiseMapping', nodeWiseMapping)
      // SensorDataNew contains all the unique sensors
      Object.keys(nodeWiseMapping).forEach((nodeWiseMappingKey) => {
        Object.keys(nodeWiseMapping[nodeWiseMappingKey]).forEach((key) => {
          SensorDataNew.push(nodeWiseMapping[nodeWiseMappingKey][key]);
        });
      });
      setSensorDataNew(SensorDataNew);
    } catch {
      setSensorDataNew(sensorsData);
    }
  }, [sensorsData]);
  return sensorDataNew;
};


export function useGetAllSensorsForBlockIds(blockIds){
  const [allSensors, setAllSensors] = useState([]);
  const sensors = useSelector((state) => selectAllSensorsForBlockIds(state, blockIds));
  useEffect(() => {
    if(blockIds && sensors.length) {
      setAllSensors(sensors.reduce((acc, obj) => acc.concat(obj), []));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockIds]);
  return allSensors;
}

// remove if not used
export function useGetSoilSensors(sensors){
  const [sSensors, setSSensors] = useState([]);
  useEffect(() => {
    // TODO remove after testing
    // console.log('sensors', sensors);
    if(sensors && Object.keys(sensors).length) {
      const result = Object.keys(sensors).filter((d) => d.indexOf('soil') !== -1)
        .map((key) => sensors[key])
        .reduce((acc, obj) => {
          Object.keys(obj).forEach((key) => {
            if(acc[key]) acc[key]=[...acc[key], ...obj[key]];
            else acc[key]= [...obj[key]];
          });
          return acc;
        }, {});
      setSSensors(result);
    }
  }, [sensors]);

  return sSensors;
}

export function useGetSensorsByCategory(sensors){
  const [sensorTypes, setSensors] = useState({
    sSensors: {},
    pSensors: {},
    vSensors: {},
    wsSensors: {},
    ddSensors: {},
  });
  useEffect(() => {
    // TODO remove after testing
    // console.log('sensors', sensors);
    if(sensors && Object.keys(sensors).length) {
      const sSensors = mapReduceForSensorType(sensors, 'soil');
      const pSensors = mapReduceForSensorType(sensors, 'pressure');
      const vSensors = mapReduceForSensorType(sensors, 'weather');
      const wsSensors = mapReduceForSensorType(sensors, 'valve');
      const ddSensors = mapReduceForSensorType(sensors, 'dendrometer');
      setSensors(() => ({
        sSensors,
        pSensors,
        vSensors,
        wsSensors,
        ddSensors
      }));
    }
  }, [sensors]);
  return sensorTypes;
}

function mapReduceForSensorType(sensors, type){
  return Object.keys(sensors).filter((d) => d.indexOf(type) !== -1)
    .map((key) => sensors[key])
    .reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if(acc[key]) acc[key]=[...acc[key], ...obj[key]];
        else acc[key]= [...obj[key]];
      });
      return acc;
    }, {});
}

export function useGetAllSensorsByBlockIds(blockIds){
  const [allSensors, setAllSensors] = useState(undefined);
  const sensors = useSelector((state) => selectAllSensorsForBlockIds(state, blockIds));
  const loadingSensors = useSelector((state) => selectLoadingSensors(state));

  useEffect(() => {
    if(blockIds && sensors.length && !loadingSensors.loading) {
      const result = sensors
        .reduce((acc, d) => acc.concat(d), [])
        .reduce((acc, obj) => {
          // TODO remove after testing
          // console.log('obj', obj);
          if(acc[obj.type]) {
            if(acc[obj.type][obj.block]) acc[obj.type][obj.block].push(obj);
            else acc[obj.type][obj.block] = [obj];
          } else acc[obj.type] = {[obj.block]: [obj]};
          return acc;
        }, {});
      // TODO remove after testing
      // console.log('allSensors', result);
      setAllSensors(result);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockIds, loadingSensors.loading]);
  return allSensors;
}

// can move this to redux-core package
const selectAllSensorsForBlockIds = (state, blockIds = []) => blockIds.map((blockId) => {
  const sensorsForBlock = selectSensorsForBlockId(state, blockId, null, 'installed');
  return sensorsForBlock;
});

// TODO remove if not used
// map of sensor type vs Charts for each sensor type
const presetChartSensorTypeMap = {
  [ROOT_ZONE_VWC]: ['aquacheck_soil', 'pixl_soil'],
  [SOIL_MOISTURE_ALL_DEPTHS]: ['aquacheck_soil', 'pixl_soil'],
};

// TODO remove if not used
// map of preset vs sensor type
const presetChartTypeMap = {
  soil: [ROOT_ZONE_VWC, SOIL_MOISTURE_ALL_DEPTHS],
  irrigation: [WATER_PRESSURE, WATER_FLOW_ANALOG, VALVE, VFD],
};

// TODO remove if not used
const presetSensorTypeMap = {
  soil: ['aquacheck_soil', 'pixl_soil']
};

const presetSensorTypeChartMap = {
  soil: {
    defaultSensorTypes: ['aquacheck_soil', 'pixl_soil', 'water_pressure', 'weather_station'],
    optionalCharts: [
      ROOT_ZONE_VWC,
      SOIL_MOISTURE_ALL_DEPTHS,
      PCT_AVAILABLE_WATER,
      SOIL_TEMPERATURE,
      WATER_PRESSURE,
      RAINFALL,
      ET0, // hidden ws-chart
      ETC, // hidden ws-chart
      AIR_TEMPERATURE, // hidden ws-chart
      AIR_HUMIDITY, // hidden ws-chart
      SOLAR_RADIATION,
      WIND_SPEED,
      WIND_DIRECTION,
    ],
  },
  moisture_pressure: {
    defaultSensorTypes: ['aquacheck_soil', 'pixl_soil', 'water_pressure'],
    optionalCharts: []
  },
  control: {
    defaultSensorTypes: ['valve'],
  },
  weather: {
    defaultSensorTypes: ['weather_station'],
    optionalCharts: [
      ET0, // hidden ws-chart
      AIR_TEMPERATURE, // hidden ws-chart
      AIR_HUMIDITY, // hidden ws-chart
      ETC,
      RAINFALL,
      SOLAR_RADIATION,
      WIND_SPEED,
      WIND_DIRECTION,
    ]
  },
  irrigation: {
    defaultSensorTypes: ['weather_station'],
    optionalCharts: [
      ET0, // hidden ws-chart
      AIR_TEMPERATURE, // hidden ws-chart
      AIR_HUMIDITY, // hidden ws-chart
      SOLAR_RADIATION, // hidden ws-chart
      WIND_SPEED, // hidden ws-chart
      WIND_DIRECTION,
    ]
  },
  plant: {
    defaultSensorTypes: ['farmx_dendrometer'],
    optionalCharts: [],
  }
};

export const sensorTypeChartsMap = {
  weather_station: [ET0, ETC, AIR_TEMPERATURE, AIR_HUMIDITY, RAINFALL, WIND_SPEED, SOLAR_RADIATION,
    WIND_DIRECTION],
  aquacheck_soil: [
    ROOT_ZONE_VWC,
    PCT_AVAILABLE_WATER,
    SOIL_MOISTURE_ALL_DEPTHS,
    SOIL_TEMPERATURE],
  pixl_soil: [ROOT_ZONE_VWC,
    PCT_AVAILABLE_WATER,
    SOIL_MOISTURE_ALL_DEPTHS,
    SOIL_TEMPERATURE],
  water_pressure: [WATER_PRESSURE],
  valve: [VALVE_STATE],
  farmx_dendrometer: [CROP_STRESS],
};

const chartTypeComponentMap = {
  [ROOT_ZONE_VWC]: RootZoneVWCChart,
  [WATER_PRESSURE]: WaterPressureChart,
  [SOIL_MOISTURE_ALL_DEPTHS]: SoilMoistureAllDepthsChart,
  [PCT_AVAILABLE_WATER]: PctAvailableWaterChart,
  [VALVE_STATE]: ValveStateChart,
  [SOIL_TEMPERATURE]: SoilTemperatureChart,
  [ET0]: Et0Chart,
  [ETC]: EtcChart,
  [AIR_TEMPERATURE]: AirTemperatureChart,
  [RAINFALL]: RainfallChart,
  [AIR_HUMIDITY]: AirHumidityChart,
  [CROP_STRESS]: CropStressChart,
  [SOLAR_RADIATION]: SolarRadiationChart,
  [WIND_SPEED]: WindSpeedChart,
  [WIND_DIRECTION]: WindDirectionChart,
};
