import React, { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table, Button, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './ruleSettingsPage.less';
import { isMobile } from 'utils/detectDevice';

interface DataType {
  key: string,
  id: number;
  name: string;
  autonomousMethod: string;
  lastUpdatedDate: string | any;
}

interface EditableTableProps {
  data: any;
  columns: any;
  onEdit?: any;
  onToggleChange: any;
  loadingId: any;
}

function sortDataSource(data: any[]) {
  return data?.length ? data?.sort((a: any, b: any) => {
    // Extract the type (_IR or _SP) and the number
    const [aType, aNum] = a?.name?.split(/[_-]/)?.slice(-2) || [];
    const [bType, bNum] = b?.name?.split(/[_-]/)?.slice(-2) || [];

    // Sort by the number first
    const numComparison = parseInt(aNum, 10) - parseInt(bNum, 10);
    if (numComparison !== 0) {
      return numComparison;
    }

    // If numbers are the same, prioritize IR over SP
    return aType === 'IR' ? -1 : 1;
  }) : [];
}

const EditableTable: React.FC<EditableTableProps> = (props: any) => {
  const {
    data, columns, onEdit, onToggleChange, loadingId,
  } = props;
  const [isEdit, setIsEdit] = useState(false);

  const dataSource = sortDataSource(data
    ?.map((row: any, index: any) => ({key: String(index), ...row })));

  // Append edit column as default
  const updatedColumns: ColumnsType<DataType> = [
    ...columns,
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
      width: 80
    },
    {
      title: 'Is Active',
      key: 'is_active',
      fixed: 'right',
      render: (_, record) => {
        const { id, is_active: isActive } = record?.schedule || {};
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={isActive}
            onChange={(d) => handleToggleChange(record, d)}
            loading={loadingId === id}
          />
        );
      },
      width: 90
    },
  ];

  const handleEdit = (record: any) => {
    setIsEdit(true);
    if (onEdit) onEdit(record);
  };

  const handleToggleChange = (record: any, isActive: boolean) => {
    const updatedRecord = {
      ...record,
      schedule: { ...(record?.schedule || {}), is_active: isActive }
    };
    onToggleChange(updatedRecord);
  };

  return (
    <div className="rule-settings-table">
      {!isEdit && (
      <Table
        scroll={{ x: (columns?.length * 200) }}
        dataSource={dataSource}
        columns={updatedColumns}
        pagination={{ pageSize: isMobile ? 8 : 10}}
      />
      )}
    </div>
  );
};

EditableTable.defaultProps = {
  data: [],
  columns: [],
};

export default EditableTable;
