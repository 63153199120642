/* eslint-disable no-underscore-dangle */
/* global L */
import {
  prepareMarker,
  prepareRanchMarker,
  prepareMarkerClusterIcon,
  // For now not showing labels
  // getMarkerTooltipByPresentationMode,
  sensorTypeAllowedForMode,
  prepareZeroIslandSensors,
} from 'farmx-web-ui';
import { notification } from 'antd';
import { actions } from 'farmx-redux-core';
import { detachEvents, clearAllClusterCircles, hideMarkersById } from '../../../helper/mapHelper';
import { isMobile } from '../../../utils/detectDevice';
import { renderSensorClusters } from './renderSensorClusters';

const {
  setRanch,
} = actions;

const dataTypeLabel = { NDSM: 'Canopy Height', DSM: 'Canopy Height' };
// To store the attached events to detach
const eventRef = {};
const eventRefRight = {};

const getFeatureType = (presMode) => {
  if (presMode === 'anomaly') return 'anomaly';
  return 'sensor';
};

const marketClusterGroupInit = (presentationMode) => {
  const mcGroup = L.markerClusterGroup({
    maxClusterRadius: 50,
    unspiderfyOnMapClick: false,
    shouldSpiderfy: (c) => c.getChildCount() < 7,
    animate: false,
    iconCreateFunction: prepareMarkerClusterIcon(presentationMode),
    spiderLegPolylineOptions: {
      weight: 1.5, color: '#eee', opacity: 0.8,
    },
    polygonOptions: {
      stroke: false,
      fill: false,
    },
  });

  return mcGroup;
};

const onMarkerClick = (dispatchMapPage, presMode) => (e) => {
  const { feature } = e.target;
  const type = getFeatureType(presMode);
  if (isMobile) {
    dispatchMapPage({
      type: 'setIsImageryPanelVisible',
      payload: false,
    });
  }
  dispatchMapPage({
    type: 'setSelectedFeatureWithType',
    payload: { feature, type, showBottomSheet: true },
  });
};

const onGlMarkerClick = (dispatchMapPage, presMode, feature) => {
  const type = getFeatureType(presMode);
  if (isMobile) {
    dispatchMapPage({
      type: 'setIsImageryPanelVisible',
      payload: false,
    });
  }
  dispatchMapPage({
    type: 'setSelectedFeatureWithType',
    payload: { feature, type, showBottomSheet: true },
  });
};

const onRanchMarkerClick = (dispatch, history, dispatchMapPage,
  ranchGeoJSON, isBottomSheetVisible) => (e) => {
  const { id } = e.target.feature.properties;
  dispatch(setRanch(Number(id)));
  history.push(`/map?ranchId=${Number(id)}`);
  if (ranchGeoJSON && dispatchMapPage) {
    dispatchMapPage({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: ranchGeoJSON,
        bottomSheetType: undefined,
        showBottomSheet: isBottomSheetVisible,
      },
    });
  }
};

const onGlRanchMarkerClick = (dispatch, history, dispatchMapPage,
  ranchGeoJSON, isBottomSheetVisible, feature) => {
  const { id } = feature.properties;
  dispatch(setRanch(Number(id)));
  history.push(`/map?ranchId=${Number(id)}`);
  if (ranchGeoJSON && dispatchMapPage) {
    dispatchMapPage({
      type: 'setSelectedFeatureWithType',
      payload: {
        feature: ranchGeoJSON,
        type: undefined,
        showBottomSheet: true,
      },
    });
  }
};

function getFilteredRanchData(ranchesPointsGeoJSON, ranchId) {
  let filteredRanch;
  if (ranchesPointsGeoJSON?.features?.length) {
    const ranchGeoJSON = ranchesPointsGeoJSON?.features
      ?.filter((d) => d?.properties?.id === ranchId)
      .reduce((a, obj) => ({ ...a, ...obj, id: ranchId }), {});
    filteredRanch = { type: 'FeatureCollection', features: ranchGeoJSON };
  }
  return filteredRanch;
}

const markers = [];
const ranchMarkers = [];

export function reDraw({
  mapFeatureGroupRef,
  dataForRedraw,
  presentationMode,
  selectedFeature,
  dispatchMapPage,
  // For now not showing labels
  // showLabels,
  selShowOnlyPresentionModeSensors,
  ranchesPointsGeoJSON,
  ranchId,
  zoomShowSensors,
  dispatch,
  history,
  isBottomSheetVisible,
  mapFeatureGroupRef1,
  isAnomaly,
  zoomVal,
  isAllRanch,
  getUserUnits,
}) {
  const filteredRanchGeoJSON = getFilteredRanchData(ranchesPointsGeoJSON, ranchId);

  if (!mapFeatureGroupRef.current) {
    return;
  }

  const presMode = !dataForRedraw.length
    || (dataForRedraw.length && dataForRedraw[0].properties.noStatusAvailable)
    ? 'initial'
    : presentationMode;

  const marketClusterRanchesGroup = marketClusterGroupInit(presMode);
  marketClusterRanchesGroup.clearLayers();

  const marketClusterGroup = marketClusterGroupInit(presMode);
  marketClusterGroup.clearLayers();

  // Start clean
  markers.forEach((marker) => {
    marker.on('off', onMarkerClick(dispatchMapPage));
    if (marker.unbindTooltip) {
      marker.unbindTooltip();
    }
  });
  ranchMarkers.forEach((marker) => {
    marker.on('off', onRanchMarkerClick(dispatch, history, dispatchMapPage,
      filteredRanchGeoJSON, isBottomSheetVisible));
    if (marker.unbindTooltip) {
      marker.unbindTooltip();
    }
  });
  if (isAnomaly) {
    mapFeatureGroupRef.current.leafletElement.eachLayer((layer) => {
      if ((!(layer.options.name && layer.options.name === 'soil'))
      && !layer?.options?.attribution) {
        mapFeatureGroupRef.current.leafletElement.removeLayer(layer);
      }
    });
  }
  markers.splice(0, markers.length);
  // End clean

  function removeMarkers() {
    const markerElements = document.querySelectorAll(
      ranchesPointsGeoJSON?.features?.length ? '.marker' : '.vector-marker'
    );
    if (markerElements?.length) {
      markerElements.forEach((d) => d.remove());
    }
  }

  if (!isAnomaly) removeMarkers();
  if (!presentationMode) return;


  function customOnClick(d) {
    onGlMarkerClick(dispatchMapPage, presMode, d);
  }

  function customOnClickRanch(d) {
    const { id } = d?.properties || {};
    const filteredRanch = getFilteredRanchData(ranchesPointsGeoJSON, id);
    onGlRanchMarkerClick(dispatch, history, dispatchMapPage,
      filteredRanch, isBottomSheetVisible, d);
  }

  const leftMapVar = mapFeatureGroupRef?.current;
  const rightMapVar = mapFeatureGroupRef1?.current;

  function renderRanchIconsOnDefaultMap(mapVar) {
    const ranchFeatures = {
      type: 'FeatureCollection',
      features: ranchesPointsGeoJSON.features
        .filter(({ properties: { id } }) => id !== ranchId || !zoomShowSensors),
    };
    renderSensorClusters(leftMapVar, mapVar, presentationMode, selectedFeature,
      customOnClickRanch, getUserUnits, ranchFeatures, ranchId, ranchesPointsGeoJSON,
      isAllRanch, true);
  }

  function onStyleRanchDataRight() { renderRanchIconsOnDefaultMap(rightMapVar); }
  function onStyleRanchDataLeft() { renderRanchIconsOnDefaultMap(leftMapVar); }

  function callMapFunctionForRanches() {
    if (rightMapVar && !rightMapVar?.leafletElement && rightMapVar?.style?._loaded) {
      renderRanchIconsOnDefaultMap(rightMapVar);
    } else if(rightMapVar && !rightMapVar?.leafletElement){
      detachEvents(rightMapVar, eventRefRight);
      eventRefRight.styledata = onStyleRanchDataRight;
      rightMapVar.once('styledata', onStyleRanchDataRight);
    } else if (!leftMapVar?.leafletElement) {
      renderRanchIconsOnDefaultMap();
    }
  }

  // Render all ranches
  if (ranchesPointsGeoJSON?.features?.length && !zoomShowSensors) {
    const mcRanchesLayer = L.geoJSON(
      {
        type: 'FeatureCollection',
        features: ranchesPointsGeoJSON.features
          .filter(({ properties: { id } }) => id !== ranchId || !zoomShowSensors),
      },
      {
        pointToLayer: (feature, latlng) => {
          let ranchMarker;
          if (isAnomaly) {
            ranchMarker = prepareRanchMarker(feature, latlng);
            ranchMarker.on('click', onRanchMarkerClick(dispatch, history, dispatchMapPage,
              filteredRanchGeoJSON, isBottomSheetVisible));
            ranchMarkers.push(ranchMarker);
          }
          return ranchMarker;
        },
      },
    );
    marketClusterRanchesGroup.addLayer(mcRanchesLayer);
    if (isAnomaly) {
      mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterRanchesGroup);
      if (mapFeatureGroupRef.current?.leafletElement?._map) {
        mapFeatureGroupRef.current.leafletElement._map.setZoom(
          mapFeatureGroupRef.current.leafletElement._map.getZoom(),
        );
      }
    }
    if (!isAnomaly) {
      if (leftMapVar && !leftMapVar?.leafletElement && leftMapVar?.style?._loaded) {
        renderRanchIconsOnDefaultMap();
      } else if(leftMapVar && !leftMapVar?.leafletElement){
        detachEvents(leftMapVar, eventRef);
        eventRef.styledata = onStyleRanchDataLeft;
        leftMapVar.once('styledata', onStyleRanchDataLeft);
      }
      callMapFunctionForRanches();
    }
    // When no ranch data is available for selected entity,
    // previous rendered markers and cluster circles will be cleared.
  } else if (!ranchesPointsGeoJSON?.features.length) {
    removeMarkers();
    clearAllClusterCircles(leftMapVar);
  }

  if (!ranchId) {
    return;
  }

  if (!zoomShowSensors) {
    return;
  }

  if (!dataForRedraw.length) {
    hideMarkersById(ranchId, false);
    return;
  }

  // Rendering logic
  if (presMode === 'initial' && getFeatureType(presMode) !== presMode && isAnomaly) { // no status info yet
    let ranchSensorsGeoJSON = {
      type: 'FeatureCollection',
      features: dataForRedraw
        .filter((s) => sensorTypeAllowedForMode(
          presentationMode,
          s.properties,
          selShowOnlyPresentionModeSensors,
        )),
    };

    ranchSensorsGeoJSON = prepareZeroIslandSensors(ranchSensorsGeoJSON);

    const mcLayer = L.geoJSON(ranchSensorsGeoJSON, {
      pointToLayer: (feature, latlng) => {
        const marker = prepareMarker(feature, latlng, presMode, false);
        return marker;
      },
    });
    marketClusterGroup.addLayer(mcLayer);
    mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterGroup);
    mapFeatureGroupRef.current.leafletElement._map.setZoom(
      mapFeatureGroupRef.current.leafletElement._map.getZoom(),
    );

    return;
  }

  const filteredRanch = ranchesPointsGeoJSON?.features
    .filter(({ properties: { id } }) => id !== ranchId);

  const features = {
    type: 'FeatureCollection',
    features: [...getFeatureType(presMode) !== presMode ? dataForRedraw
      .filter((feature) => sensorTypeAllowedForMode(
        presentationMode,
        feature.properties,
        !!selShowOnlyPresentionModeSensors,
      )) : dataForRedraw, ...(zoomShowSensors ? filteredRanch : [])],
  };

  if (isAnomaly) {
    const mcLayer = L.geoJSON(features, {
      pointToLayer: (feature, latlng) => {
        const isSelected = selectedFeature?.properties?.id === feature.properties.id;
        const marker = prepareMarker(feature, latlng, presentationMode, isSelected,
          undefined, undefined, getUserUnits);
        if (!isSelected) {
          marker.on('click', onMarkerClick(dispatchMapPage, presMode));
          markers.push(marker);
        }
        return marker;
      },
    });
    marketClusterGroup.addLayer(mcLayer);
    mapFeatureGroupRef.current.leafletElement.addLayer(marketClusterGroup);
    if (mapFeatureGroupRef.current?.leafletElement?._map) {
      mapFeatureGroupRef.current.leafletElement._map.setZoom(
        mapFeatureGroupRef.current.leafletElement._map.getZoom(),
      );
    }
  }

  const isRanch = !!isAllRanch;

  function renderIconsOnDefaultMap(mapVar) {
    renderSensorClusters(leftMapVar, mapVar, presentationMode, selectedFeature,
      customOnClick, getUserUnits, features, ranchId, ranchesPointsGeoJSON, isAllRanch, isRanch,
      zoomShowSensors, customOnClickRanch);
  }

  function onStyleDataRight() { renderIconsOnDefaultMap(rightMapVar); }
  function onStyleDataLeft() { renderIconsOnDefaultMap(leftMapVar); }

  function callMapFunction() {
    if (rightMapVar && !rightMapVar?.leafletElement && rightMapVar?.style?._loaded) {
      renderIconsOnDefaultMap(rightMapVar);
    } else if (rightMapVar && !rightMapVar?.leafletElement) {
      // Clear existing event listeners
      detachEvents(rightMapVar, eventRefRight);
      eventRefRight.styledata = onStyleDataRight;
      rightMapVar.once('styledata', onStyleDataRight);
    } else if (!leftMapVar?.leafletElement) {
      renderIconsOnDefaultMap();
    }
  }

  if (!isAnomaly) {
    if (leftMapVar && !leftMapVar?.leafletElement && leftMapVar?.style?._loaded) {
      renderIconsOnDefaultMap(leftMapVar);
    } else if (leftMapVar && !leftMapVar?.leafletElement) {
      // Clear existing event listeners
      detachEvents(leftMapVar, eventRef);
      eventRef.styledata = onStyleDataLeft;
      leftMapVar.once('styledata', onStyleDataLeft);
    }
    callMapFunction();
  }
}

export function reDrawSatelliteImagery({
  mapFeatureGroupRef,
  selectedImageryData,
  selectedImageryDataOpacity,
  isAnomaly,
  isSatellite,
  droneDataType,
  t,
}) {
  if ((!mapFeatureGroupRef.current || !mapFeatureGroupRef?.current?.style?._loaded)
  && !isAnomaly) {
    return;
  }
  if (selectedImageryData.visible && selectedImageryData.data && !isAnomaly) {
    selectedImageryData.data.forEach((d, i) => {
      if (mapFeatureGroupRef.current.getLayer(`overlay${i}`)) mapFeatureGroupRef.current.removeLayer(`overlay${i}`);
      if (mapFeatureGroupRef.current.getSource(`satellite-imagery-${i}`)) mapFeatureGroupRef.current.removeSource(`satellite-imagery-${i}`);
      if (mapFeatureGroupRef.current.getLayer(`drone-overlay${i}`)) mapFeatureGroupRef.current.removeLayer(`drone-overlay${i}`);
      if (mapFeatureGroupRef.current.getSource(`drone-imagery-${i}`)) mapFeatureGroupRef.current.removeSource(`drone-imagery-${i}`);
      if (isSatellite && !d?.tiles) {
        // Temporary fix the image not render properly issue
        const imageUrl = `${d.png_url}&temp=${Date.now()}`;
        // eslint-disable-next-line no-unused-vars
        const [c1, c2, c3, c4, c5] = JSON.parse(d.bounds).coordinates[0];
        mapFeatureGroupRef.current.addSource(`satellite-imagery-${i}`, {
          type: 'image',
          url: imageUrl,
          coordinates: [c5, c4, c3, c2],
        });

        mapFeatureGroupRef.current.addLayer({
          id: `overlay${i}`,
          type: 'raster',
          source: `satellite-imagery-${i}`,
          paint: {
            'raster-opacity': selectedImageryDataOpacity / 100,
            'raster-fade-duration': 0,
          },
        });
        mapFeatureGroupRef.current.moveLayer(`overlay${i}`, mapFeatureGroupRef.current.getStyle().layers[1].id);
      } else {
        const { tiles, minZoom, maxZoom } = d || {};

        // There are two possible imagery tiles for the "Canopy Height". Ex: "NDSM" and "DSM"
        // To pick the tile data based on the availability
        const dsm = droneDataType === 'NDSM' ? 'DSM' : null;
        const selectedTileUrl = tiles?.[droneDataType] || tiles?.[dsm];
        if (!mapFeatureGroupRef.current.getSource(`drone-imagery-${i}`) && selectedTileUrl) {
          mapFeatureGroupRef.current.addSource(`drone-imagery-${i}`, {
            type: 'raster',
            tiles: [selectedTileUrl],
            tileSize: 256,
          });
        }

        if (selectedTileUrl) {
          mapFeatureGroupRef.current.addLayer({
            id: `drone-overlay${i}`,
            type: 'raster',
            source: `drone-imagery-${i}`,
            minzoom: minZoom,
            maxzoom: Math.min(24, maxZoom), // MaxZoom Ref: https://maplibre.org/maplibre-style-spec/layers/#maxzoom
            paint: {
              'raster-opacity': selectedImageryDataOpacity / 100,
              'raster-fade-duration': 0,
            },
          });
          mapFeatureGroupRef.current.moveLayer(`drone-overlay${i}`, mapFeatureGroupRef.current.getStyle().layers[1].id);
        } else if (t && !selectedTileUrl) {
          // Notification popup appears only once,
          // when the imagery tile is not available for the selected date.
          notification.config({ maxCount: 1 });
          notification.error({
            message: `${t('The drone imagery for the type')} "${dataTypeLabel[droneDataType]
              || dataTypeLabel[dsm] || droneDataType}" ${t('is not available')}`,
          });
        }
      }
    });
  }

  if (isAnomaly) {
    if (!mapFeatureGroupRef.current || !mapFeatureGroupRef.current.leafletElement) {
      return;
    }
    mapFeatureGroupRef.current.leafletElement.eachLayer((layer) => {
      if (layer.options.attribution === 'Drone Imagery'
      || layer.options.attribution === 'satellite') {
        mapFeatureGroupRef.current.leafletElement.removeLayer(layer);
      }
    });

    if (selectedImageryData.visible && selectedImageryData.data) {
      mapFeatureGroupRef.current.leafletElement.clearLayers();
      selectedImageryData.data.forEach((d) => {
        if (isSatellite) {
          try {
            const boundsCoords = JSON.parse(d.bounds).coordinates;
            const latlngs = L.GeoJSON.coordsToLatLngs(boundsCoords, 1);
            const bounds = new L.LatLngBounds(latlngs);
            const imageUrl = d.png_url;
            const options = {
              opacity: selectedImageryDataOpacity / 100,
              alt: 'satellite imagery',
              attribution: 'satellite',
            };
            // eslint-disable-next-line new-cap
            const imageLayer = new L.imageOverlay(imageUrl, bounds, options);
            mapFeatureGroupRef.current.leafletElement.addLayer(imageLayer);
          } catch (error) {
            console.log('Error adding image layer:', error);
          }
        }
        if (!isSatellite && d && d?.tiles) {
          const { tiles, minZoom, maxZoom } = d;
          // There are two possible imagery tiles for the "Canopy Height". Ex: "NDSM" and "DSM"
          // To pick the tile data based on the availability
          const dsm = droneDataType === 'NDSM' ? 'DSM' : null;
          const selectedTileUrl = tiles?.[droneDataType] || tiles?.[dsm];
          if (selectedTileUrl) {
            const droneTileLayer = L.tileLayer(selectedTileUrl, {
              minZoom,
              maxZoom,
              attribution: 'Drone Imagery',
            });
            mapFeatureGroupRef.current.leafletElement.addLayer(droneTileLayer);
          } else if (t && !selectedTileUrl) {
            // Notification popup appears only once,
            // when the imagery tile is not available for the selected date.
            notification.config({ maxCount: 1 });
            notification.error({
              message: `${t('The drone imagery for the type')} "${dataTypeLabel[droneDataType]
                || dataTypeLabel[dsm] || droneDataType}" ${t('is not available')}`,
            });
          }
        }
      });
    }
  }
}
