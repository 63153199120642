import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Button, InputNumber, notification, Select, Radio, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sensorApi } from 'farmx-api';
import { SensorSelect } from 'farmx-web-ui';
import { selectors, actions, hooks } from 'farmx-redux-core';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { PageHeader } from '../components/PageHeader';
import './SensorSettings.less';
import { VWCMultilineGraph } from '../home/VWCMultilineGraph';
import SensorSettingsUpdateModal from './SensorSettingsUpdateModal';
import { isMobile } from '../../../utils/detectDevice';
import HelpButtonWithZendeskHelpModal from '../components/HelpButtonWithZendeskHelpModal';
import { SOIL_MOISTURE_CUT_OFFS } from '../../../helper/articleIds';

import { calculateRefillPercent, calculateRefillPoint } from './settingsHelper';
import { CutoffPointsInput } from './CutoffPointsInput';

const { loadSensorData } = sensorApi;

export function SensorSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sensor, setSensor] = useState(null);
  const [refillPoints, setRefillPoints] = useState(null);
  const [refillPoint, setRefillPoint] = useState(null);
  const [refillPercent, setRefillPercent] = useState(null);
  const [originalRefillPercent, setOriginalRefillPercent] = useState(null);
  const [wiltingPoint, setWiltingPoint] = useState(null);
  const [originalWiltingPoint, setOriginalWiltingPoint] = useState(null);
  const [fieldCapacity, setFieldCapacity] = useState(null);
  const [originalFieldCapacity, setOriginalFieldCapacity] = useState(null);
  const [editMode, setEditMode] = useState('fieldCapacity');
  const [depthValue, setDepthValue] = useState(8);
  const [depthIndex, setDepthIndex] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSensorSettingsUpdateModal, setShowSensorSettingsUpdateModal] = useState(false);
  const sensorIdentifier = sensor && sensor.identifier;
  const sensorType = sensor && sensor.type;
  const sensorId = sensor && sensor.id;
  /**
   * Store refs for input fields so that we can set focus on them if
   * user selects addonText to edit the input field.
   */
  const refillPercentInputRef = useRef();
  const fieldCapacityInputRef = useRef();
  const wiltingPointInputRef = useRef();

  const userLengthUnits = useSelector((state) => selectors.selectUserLengthFormat(state));
  const adjustedLengthUnits = (userLengthUnits === 'feet') ? 'inches' : 'centimeters';

  const getUserSettings = hooks.useUnits();

  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, sensorType, sensorIdentifier),
  );

  useEffect(() => {
    async function fetchGraphData() {
      const endDate = moment();
      const startDate = moment().subtract(14, 'd');
      const variable = `soil_moisture_${depthValue}`;
      setLoading(true);
      try {
        const res = await loadSensorData(sensorType, sensorId, [variable], startDate,
          endDate, true);
        const { data } = res;
        const soilMoistureData = data[variable];
        const formattedData = soilMoistureData.length
          ? soilMoistureData[0].data.map((d) => ({ x: d[0], y: d[1] })) : [];
        setChartData(formattedData);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }

    if (sensorId && sensorType) {
      fetchGraphData();
    }
  }, [depthValue, sensorId, sensorType]);

  // To set the depthIndex
  useEffect(() => {
    if (sensorStatus && sensorStatus.depthCount) {
      for (let i = 1; i <= sensorStatus.depthCount; i += 1) {
        if (sensorStatus[`depth${i}`] === depthValue) setDepthIndex(i);
      }
    }
  }, [depthValue, sensorStatus]);

  useEffect(() => {
    dispatch(actions.loadSensorStatus({ type: sensorType, identifier: sensorIdentifier }));
  }, [sensorType, sensorIdentifier, dispatch]);

  useEffect(() => {
    if (!sensorType || !sensorId) return;
    sensorApi.getSoilSensorProperties({ type: sensorType, id: sensorId }).then((response) => {
      if (response.status === 200 || response.status === 204) {
        const { data } = response || { data: {} };
        const wp = data[`wiltingPoint${depthIndex}`] || '';
        const fc = data[`fieldCapacity${depthIndex}`] || '';
        const refillPer = data[`refillPercent${depthIndex}`] || '';
        setRefillPoints(data);
        setFieldCapacity(fc);
        setOriginalFieldCapacity(fc);
        setWiltingPoint(wp);
        setOriginalWiltingPoint(wp);
        setRefillPercent(refillPer);
        setOriginalRefillPercent(refillPer);
        setRefillPoint(calculateRefillPoint(Number(refillPer), Number(wp), Number(fc)));
      } else {
        notification.error({
          message: t('Failed to load refill points'),
        });
      }
    }).catch(() => {
      notification.error({
        message: t('Failed to load refill points'),
      });
    });
  }, [sensorType, sensorId, t, depthIndex]);

  function getDepthData() {
    if (!sensorStatus || !sensorStatus.depthCount || !refillPoints) return [];
    const data = [];
    for (let i = 1; i <= sensorStatus.depthCount; i += 1) {
      data.push({
        depthInches: sensorStatus[`depth${i}`],
        refillPercent: refillPoints[`refillPercent${i}`],
      });
    }
    return data;
  }

  const dataByDepth = getDepthData();
  const depthUnit = sensorStatus && sensorStatus.units && sensorStatus.units.depth;

  const displayDepth = (depth) => {
    const { value, label } = getUserSettings(depth, depthUnit, adjustedLengthUnits);

    return `${value} ${label}`;
  };

  /**
   * @param {*} wp - wiltingPoint
   * @param {*} rp - refillPoint
   * @param {*} fc - fiedlCapacity
   * @returns boolean value
   * It shows a message if invalid
   */
  function cutOffPointvalidation(wp, rp, fc) {
    if (wp < rp && wp < fc && rp < fc) {
      return true;
    }
    notification.error({
      message: t('Invalid'),
    });
    return false;
  }

  function onSubmit() {
    setShowSensorSettingsUpdateModal(false);
    const validPoints = cutOffPointvalidation(wiltingPoint, refillPoint, fieldCapacity);
    if (validPoints) {
      setEditMode(null);
      const data = {
        ...refillPoints,
        [`fieldCapacity${depthIndex}`]: Number(fieldCapacity).toFixed(3),
        [`refillPercent${depthIndex}`]: Number(refillPercent).toFixed(3),
        [`wiltingPoint${depthIndex}`]: Number(wiltingPoint).toFixed(3),
      };

      sensorApi.updateSoilSensorProperties({
        data,
        id: sensorId,
        type: sensorType,
      }).then(() => {
        notification.success({
          message: t('Successfully updated soil-properties'),
        });
        // Set original to new values
        setOriginalFieldCapacity(fieldCapacity);
        setOriginalRefillPercent(refillPercent);
        setOriginalWiltingPoint(wiltingPoint);
      }).catch(() => {
        notification.error({
          message: t('Failed to update soil-properties'),
        });
      });
    }
  }

  const onRadioChange = (e) => {
    setEditMode(e.target.value);
  };

  const adjustRefilPoint = (fc, wp) => {
    const midpoint = (fc + wp) / 2;
    setRefillPoint(midpoint);
    setRefillPercent(calculateRefillPercent(Number(midpoint), Number(wp), Number(fc)));
  };

  const onChange = (e) => {
    console.log('onChange triggered with', e);
    setEditMode(e.id);
    const value = Number(e.value) / 100 || 0;
    if (e && e.id === 'fieldCapacity') {
      setFieldCapacity(value);
      adjustRefilPoint(value, Number(wiltingPoint));
    }
    if (e && e.id === 'refillPoint') {
      setRefillPoint(value);
      setRefillPercent(
        calculateRefillPercent(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'refillPercent') {
      setRefillPercent(value);
      setRefillPoint(
        calculateRefillPoint(Number(value), Number(wiltingPoint), Number(fieldCapacity)),
      );
    }
    if (e && e.id === 'wiltingPoint') {
      setWiltingPoint(value);
      adjustRefilPoint(Number(fieldCapacity), value);
    }
  };

  // To render the input fileds
  const cutOffPointEditOptions = [
    {
      id: 'fieldCapacity',
      inputLabel: 'Field Capacity',
      inputValue: fieldCapacity,
      addonText: `(${t('vwc')})`,
      ref: fieldCapacityInputRef,
    },
    {
      id: 'refillPercent',
      inputLabel: 'Refill Percent',
      inputValue: refillPercent,
      addonText: `(${(refillPoint * 100).toFixed(2)}% ${t('vwc')})`,
      ref: refillPercentInputRef,
    },
    {
      id: 'wiltingPoint',
      inputLabel: 'Wilting Point',
      inputValue: wiltingPoint,
      addonText: `(${t('vwc')})`,
      ref: wiltingPointInputRef,
    },
  ];

  /**
   * If user cancels the update operation:
   *   1. Hide the update modal
   *   2. Reset the fields to original values
   */
  const cancelSettingsUpdate = () => {
    setShowSensorSettingsUpdateModal(false);
    setRefillPercent(originalRefillPercent);
    setWiltingPoint(originalWiltingPoint);
    setFieldCapacity(originalFieldCapacity);
  };

  // Set focus on input field
  const setFocus = useCallback((ref) => {
    if (ref && ref.current && ref.current.focus) {
      ref.current.focus();
    }
  }, []);

  return (
    <div className="page-content margin-10 sensor-settings-page">
      <PageHeader
        showBack={!!isMobile}
        title={t('Soil Sensor Cutoffs')}
        toPath="/settings"
      />
      <div className="div-select-container ranch-block-select-container">
        <SensorSelect
          sensorType={sensorType}
          sensorIdentifier={sensorIdentifier}
          onChange={setSensor}
          placeholder={t('Select Sensor')}
          sensorTypeConstraint={['aquacheck_soil', 'pixl_soil']}
          applyFilter
        />
      </div>
      {dataByDepth.length > 0 && (
        <div className="flex-column padding-10 refill-point-form">
          <div>
            <h2 className="moisture-cut-off-title">
              {t('Set Moisture Cut Offs')}
              <HelpButtonWithZendeskHelpModal
                buttonClassName="soil-sensor-help-button"
                modalClassName="moisture-cut-off-modal"
                articleId={SOIL_MOISTURE_CUT_OFFS}
              />
            </h2>
          </div>

          <div className="flex-row justify-content-space-between" key="0">
            <div className="row-label">
              {t('Depth')}
            </div>
            <Select
              defaultValue={depthValue}
              onSelect={setDepthValue}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {dataByDepth.map((depthData) => (
                <Select.Option value={depthData.depthInches}>
                  {displayDepth(depthData.depthInches)}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Radio.Group className="sensor-settings-radio" onChange={onRadioChange} value={editMode} defaultValue="fieldCapacity">
            <Radio.Button value="fieldCapacity">{t('Field Capacity')}</Radio.Button>
            <Radio.Button value="refillPoint">{t('Refill Point')}</Radio.Button>
            <Radio.Button value="wiltingPoint">{t('Wilting Point')}</Radio.Button>
          </Radio.Group>
          <div style={{ backgroundColor: '#aaa', height: '200px', marginTop: '10px' }}>
            <VWCMultilineGraph
              fieldCapacity={fieldCapacity}
              wiltingPoint={wiltingPoint}
              refillPoint={refillPoint}
              editMode={[editMode]}
              onChange={onChange}
              data={chartData}
              chartType="line"
              options={{
                yAxis: {
                  tickInterval: 20,
                },
                chart: {
                  marginTop: 40,
                },
              }}
            />
          </div>
          {loading && <LoadingOutlined className="absolute-center" />}
          {cutOffPointEditOptions.map((inputData) => (
            <CutoffPointsInput
              ref={inputData.ref}
              id={inputData.id}
              label={inputData.inputLabel}
              addonText={inputData.addonText}
              onChange={onChange}
              setFocus={setFocus}
              value={inputData.inputValue}
            />
          ))}
          <Button type="primary" onClick={() => setShowSensorSettingsUpdateModal(true)}>{t('Update')}</Button>
        </div>
      )}
      <SensorSettingsUpdateModal
        visible={showSensorSettingsUpdateModal}
        onCancel={cancelSettingsUpdate}
        onOk={onSubmit}
        originalFieldCapacity={originalFieldCapacity}
        newFieldCapacity={fieldCapacity}
        originalRefillPercent={originalRefillPercent}
        newRefillPercent={refillPercent}
        originalWiltingPoint={originalWiltingPoint}
        newWiltingPoint={wiltingPoint}
      />
    </div>
  );
}
