import React from 'react';
import { Tag } from 'antd';
import { stateTagColorCodes } from 'farmx-web-ui';
import { blockApi } from 'farmx-api';
import AnomalyTableLoader from '../components/contentLoaders/AnomalyTableLoader';

const keys = {
  critical: 'Critical',
  major: 'Major',
  moderate: 'Moderate',
  low: 'Low',
  no_stress: 'No Stress',
};

const comparisonKeys = {
  last_image: '3 days ago',
  last_month: 'Last Month',
  last_year: 'Last Year',
  history: 'Historical Mean',
};

const severityKeys = {
  no_stress: 'No Stress',
  normal: 'Normal',
  low: 'Low',
  moderate: 'Moderate',
  major: 'High',
  critical: 'Severe',
  total: 'Total',
};

const vegetationHeaders = ['NDVI', 'NDWI', 'NDRE', 'Crop Index'];

export function requestImagingServicesForBlock(callback) {
  blockApi.requestImagingServices().then(() => callback(true)).catch(() => callback(false));
}

export function getStateForAnomalySeverity(severity) {
  if (severity === 'low' || severity === 'normal') {
    return 'good';
  }
  if (severity === 'moderate') {
    return 'poor';
  }
  if (severity === 'major') {
    return 'bad';
  }
  if (severity === 'critical') {
    return 'critical';
  }
  return undefined;
}

export function blockAnomalyErrorReport(errorObj, callback) {
  blockApi.anomalyErrorReport(errorObj).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}

/**
 * Below Function will do data preparation for the anomaly detail page
 */
export function useAnomalyDataForRecommendation(anomaly, recommendations,
  anomalyForBlock, propAnomaly) {
  const anomalyIds = recommendations.map((d) => d?.imageryAnomaly);
  const filteredAnomaly = anomalyForBlock.filter((d) => anomalyIds.includes(d?.properties?.id));

  let anomalyData = {};
  if (anomaly) anomalyData = anomaly;
  else if (propAnomaly) anomalyData = propAnomaly;
  else if (!anomaly && filteredAnomaly?.length) [anomalyData] = filteredAnomaly;

  let anomalyDataForRender = recommendations;
  if (anomaly) anomalyDataForRender = [anomaly];
  else if (propAnomaly) anomalyDataForRender = [propAnomaly];
  else if (!anomaly && filteredAnomaly?.length) {
    anomalyDataForRender = filteredAnomaly;
  }
  const recommendation = recommendations?.filter((d) => (
    d?.block === anomaly?.properties?.block)
    && (d?.imageryAnomaly === anomaly?.properties?.id));
  anomalyDataForRender = anomalyDataForRender.map((anomalyDataIndividual) => {
    const equivalentRecommendation = recommendation.find((rec) => (
      rec.block === anomalyDataIndividual?.properties.block
      && rec.imageryAnomaly === anomalyDataIndividual?.properties.id
    ));
    return {
      ...anomalyDataIndividual,
      properties: {
        ...(anomalyDataIndividual?.properties || {}),
        ...(equivalentRecommendation || {}),
      },
    };
  });

  return { anomalyData, anomalyDataForRender, recommendation };
}

function roundValue(value, decimalPlaces) {
  // eslint-disable-next-line no-restricted-properties
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
}

export function roundToDecimalPlaces(num) {
  // Default decimal places
  let decimalPlaces = 1;
  const value = Number(num);
  if (!value) return num;

  if (value < 1) {
    const absValueStr = Math.abs(value)?.toString();
    const decimalPart = absValueStr?.split('.')?.length > 1
      ? absValueStr?.split('.')[1] : absValueStr;
    const firstNonZeroIndex = decimalPart?.search(/[1-9]/);

    // Set decimal places to the index of the first non-zero digit + 1
    // So it includes that first non-zero digit
    decimalPlaces = firstNonZeroIndex + 1;
  }
  if (Math.abs(value) > 500) decimalPlaces = 0;
  return roundValue(value, decimalPlaces);
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getAnomalyAreaForAnomalyId(aoiIds, callBack) {
  const allRequests = [];
  const finalData = [];

  aoiIds.forEach((id) => {
    allRequests.push(blockApi.getAnomalyArea({ id }));
  });

  Promise.allSettled(allRequests).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        if (r?.value?.data) {
          const { data } = r.value || {};
          if (data) {
            const parsedMultiBounds = data.anomaly_area.map((d) => {
              const obj = { ...d };
              if (isJsonString(d.multi_bounds)) {
                obj.multi_bounds = JSON.parse(d.multi_bounds);
              }
              return obj;
            });
            const updatedAreaData = { ...data };
            updatedAreaData.anomaly_area = parsedMultiBounds;
            finalData.push(updatedAreaData);
          }
        }
      }
    });
    callBack(finalData);
  });
}

function getTotalValue(data, objKeys) {
  const updatedObj = { ...data };

  objKeys.forEach((key) => {
    if (updatedObj[key]) {
      const total = Object.values(updatedObj[key])
        .reduce((acc, value) => {
          const areaAcre = value.area_acre || 0;
          const areaRatio = value.area_ratio || 0;
          const count = value.count || 0;
          const countRatio = value.count_ratio || 0;

          return {
            area_acre: acc.area_acre + areaAcre,
            area_ratio: acc.area_ratio + areaRatio,
            count: acc.count + count,
            count_ratio: acc.count_ratio + countRatio
          };
        }, {
          area_acre: 0, area_ratio: 0, count: 0, count_ratio: 0
        });

      updatedObj[key]['total'] = total;
    }
  });

  return updatedObj;
}

export function getImageryAnomalyComparisonForAnomalyId(aoiIds, callBack) {
  const allRequests = [];
  const finalData = [];
  let isError = false;
  aoiIds.forEach((id) => {
    allRequests.push(blockApi.getImageryAnomalyComparison(id));
  });

  Promise.allSettled(allRequests).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        if (r?.value?.data) {
          const { data } = r.value || {};
          if (data) {
            const updatedChangeData = data.change.map((d) => getTotalValue(d,
              ['area_change', 'count_change']))?.filter((d) => Object.keys(d)?.length);
            const updatedData = { ...data, change: updatedChangeData };
            finalData.push(updatedData);
          }
        }
      } else if (r.status === 'rejected') {
        isError = true;
      }
    });
    if(!isError)callBack(finalData);
    else callBack(false);
  });
}

// To calculate the percentage of area out of total block area
function getAreaPercentage(areaStress, areaInSquareMeeters) {
  return roundToDecimalPlaces((areaStress / areaInSquareMeeters) * 100, 1);
}

function fillCell(value, isPercentage, isTotal, isVegetationIndex) {
  const isPositive = value > 0;
  const isZero = value === 0;
  const isNull = !value;
  const sign = isPositive ? '+' : '';
  const percentSign = isPercentage ? '%' : '';

  let className = '';
  // Table value color code: +ve ==> red, -ve ==> green
  if (!isVegetationIndex) {
    if (isPositive) className = `label-red ${isTotal ? 'label-bold' : ''}`;
    else className = `label-green ${isTotal ? 'label-bold' : ''}`;
  }

  // Table value color code: +ve ==> green, -ve ==> red
  if (isVegetationIndex) {
    if (isPositive) className = `label-green ${isTotal ? 'label-bold' : ''}`;
    else className = `label-red ${isTotal ? 'label-bold' : ''}`;
  }

  return (
    <td align="center">
      {/* Table value color code: 0 ==> black */}
      {isZero || isNull ? (
        <span className={isTotal ? 'label-bold' : ''}>
          {`${roundToDecimalPlaces(value || 0, 2)}${percentSign}`}
        </span>
      )
        : (
          <span className={className}>
            {`${sign}${isPositive ? roundToDecimalPlaces(Math.abs(value || 0), 2)
              : roundToDecimalPlaces(value || 0, 2)}${percentSign}`}
          </span>
        )}
    </td>
  );
}

function generateCells(data, isVegetationIndex, isAnomalyCount, dataType) {
  const isAcre = dataType === 'areaUnit';
  if (!isVegetationIndex) {
    // Return cells for the anomaly area and anomaly count
    return Object.keys(severityKeys).map((s) => {
      let value;
      if (!isAnomalyCount) {
        value = data?.['area_change']?.[s]?.[isAcre ? 'area_acre' : 'area_ratio'];
      } else value = data?.['count_change']?.[s]?.[isAcre ? 'count' : 'count_ratio'];

      return fillCell(value, !isAcre, s === 'total');
    });
  }

  // Return cells for the vegetation index
  return (
    <>
      {fillCell(isAcre ? data.ndvi : data.ndvi_ratio, !isAcre, false, isVegetationIndex)}
      {fillCell(isAcre ? data.ndwi : data.ndwi_ratio, !isAcre, false, isVegetationIndex)}
      {fillCell(isAcre ? data.ndre : data.ndre_ratio, !isAcre, false, isVegetationIndex)}
      {fillCell(isAcre
        ? data.crop_index : data.crop_index_ratio, !isAcre, false, isVegetationIndex)}
    </>
  );
}

// This will be moved to separate component in future
export function renderAreaCalculationTable(areaUnit, anomalyAreaForBlockId, areaSquareMeters, t) {
  return (
    <div className="anomaly-table">
      <table className="table table-striped">
        <thead>
          <tr>
            <th align="left">{t('Stress Level')}</th>
            <th align="left">{`Area (${areaUnit})`}</th>
            <th align="left">Cover (%)</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(keys).map((d) => (
            <tr>
              <td>
                <div className="flex-row">
                  <Tag
                    className="anomaly-view-tag"
                    color={stateTagColorCodes[
                      getStateForAnomalySeverity(d)]}
                  />
                  <div>{keys[d]}</div>
                </div>
              </td>
              <td>{roundToDecimalPlaces(anomalyAreaForBlockId?.[d], 2) || 'None'}</td>
              <td>
                {anomalyAreaForBlockId?.[d]
                  ? `${getAreaPercentage(anomalyAreaForBlockId?.[d], areaSquareMeters)} %`
                  : 'None'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Below function will render the table for the comparison view
export function renderComparisonTable(comparisonData, isVegetationIndex,
  isAnomalyCount, dataType, t, isMobile, loading) {
  const tableText = (d) => (
    <th align="center">{d}</th>
  );
  const renderReactLoader = () => {
    if(loading && !comparisonData?.length) {
      return(<AnomalyTableLoader />);
    } if(comparisonData?.length >= 0) {
      return(<tr><td align="center">None</td></tr>);
    }
  };


  return (
    <div className="anomaly-table">
      <table className="table table-striped">
        <thead>
          <tr>
            <th align="left" className={isMobile && 'anomaly-table-header-width'}>{t('Time')}</th>
            {(!isVegetationIndex ? Object.values(severityKeys) : vegetationHeaders)
              .map((d) => (tableText(d)))}
          </tr>
        </thead>
        <tbody>
          {comparisonData?.length ? comparisonData.map((d) => (
            <tr>
              <td align="left">{comparisonKeys[d.change_duration]}</td>
              {generateCells(d, isVegetationIndex, isAnomalyCount, dataType)}
            </tr>
          ))
            : (
              <>
                {comparisonData?.length >= 0
            && (
            <tr>
              <td align="left">{renderReactLoader()}</td>
              {(!isVegetationIndex ? Object.values(severityKeys) : vegetationHeaders)
                .map((d) => <td align="center">{renderReactLoader()}</td>)}
            </tr>
)}

                {loading && !comparisonData?.length
            && (
            <>
              <tr>
                <td align="left">{renderReactLoader()}</td>
                {(!isVegetationIndex ? Object.values(severityKeys) : vegetationHeaders)
                  .map((d) => <td align="center">{renderReactLoader()}</td>)}
              </tr>
              <tr>
                <td align="left">{renderReactLoader()}</td>
                {(!isVegetationIndex ? Object.values(severityKeys) : vegetationHeaders)
                  .map((d) => <td align="center">{renderReactLoader()}</td>)}
              </tr>
              <tr>
                <td align="left">{renderReactLoader()}</td>
                {(!isVegetationIndex ? Object.values(severityKeys) : vegetationHeaders)
                  .map((d) => <td align="center">{renderReactLoader()}</td>)}
              </tr>
            </>
            )}
              </>
)}
        </tbody>
      </table>
    </div>
  );
}
