import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { hooks } from 'farmx-redux-core';
import PressureGauge from './PressureGauge';

const {
  useUnits,
} = hooks;

export default function PressureSummary(props) {
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  const {
    id, displayTitle, pressure, valveList, loading, pressureCutoff, units,
  } = props;

  const userUnits = getUserUnits(pressure, 'pounds_per_square_inch', 'pressure', { decimalPlaces: 4 });
  const displayPressure = `${userUnits.value} ${userUnits.label}`;

  return (
    <div className="pressure-summary-container">
      {displayTitle && (
        <Typography.Title className="pressure-summary-title" level={4}>{t('Pressure Summary')}</Typography.Title>
      )}
      {loading ? <LoadingOutlined className="pressure-loader" /> : null}
      <div className="pressure-summary-details">
        <div className="pressure-summary-graph">
          <PressureGauge
            id={id}
            pressure={pressure}
            pressureCutoff={pressureCutoff.toString()}
          />
        </div>
        <div className="pressure-summary-value">
          <Typography.Title level={3}>{displayPressure}</Typography.Title>
          <Typography.Title level={4}>{t('Average Across')}</Typography.Title>
          <Typography.Link>{valveList.join(', ')}</Typography.Link>
        </div>
      </div>
    </div>
  );
}

PressureSummary.defaultProps = {
  id: '',
  displayTitle: true,
  pressure: '',
  valveList: [],
  loading: false,
  pressureCutoff: [],
  units: {
    pressureCutoff: 'psi',
  },
};

PressureSummary.propTypes = {
  id: PropTypes.string,
  displayTitle: PropTypes.bool,
  pressure: PropTypes.string,
  valveList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  pressureCutoff: PropTypes.arrayOf(PropTypes.number),
  units: PropTypes.shape({
    pressureCutoff: PropTypes.string,
  }),
};
