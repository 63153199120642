import React from 'react';
import { Table, Button, Space, Skeleton, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../mobile/components/PageHeader';
import { isMobile } from 'react-device-detect';
import { FarmxWebApiTypes } from 'types/api_types';
import '../../organization.css';

export interface UsersListProps {
  loading: boolean;
  pagedResult: FarmxWebApiTypes.PaginatedResult<any>;
  tableSpec: FarmxWebApiTypes.ListQuery;
  onPageChange: (page: number, pageSize: number) => void;
  onUserSelect: (user: any) => void;
  onCreateUser: () => void; 
  currentUserRoles: string[] | undefined;
}

/**
 * UsersList is a functional presentational component used to display a table of Users.
 * @param {boolean} props.loading - The loading status of the component.
 * @param {FarmxWebApiTypes.PaginatedResult<any>} props.pagedResult - The paged result of the users query.
 * @param {FarmxWebApiTypes.ListQuery} props.tableSpec - The current spec for fetching users.
 * @param {(page: number, pageSize: number) => void} props.onPageChange - The method to handle the page change.
 * @param {(user: any) => void} props.onUserSelect - The method to handle the user selection.
 * @param {() => void} props.onCreateUser - The method to handle the user creation.
 * @param {string[] | undefined} props.currentUserRoles - The roles of the current user.
 * @returns {JSX.Element} The UsersList component. 
 */
const UsersList: React.FC<UsersListProps> = ({ 
  loading, 
  pagedResult, 
  tableSpec,
  onPageChange, 
  onUserSelect,
  onCreateUser, 
  currentUserRoles
}) => {
  const { t } = useTranslation();

  // Define the columns for the users table
  const usersTableColumns = [
    {
      title: t('Username'),
      dataIndex: 'username',
      key: 'name',
      render: (_: any, record: { user: { username: any; }; }) => record.user?.username,
    },
    {
      title: t('Organization Admin?'),
      dataIndex: 'members',
      key: 'members',
      render: (_: any, record: { isAdmin: boolean | undefined; role: {name: string} }) => <Checkbox checked={record.role.name == "Entity Admin"} disabled={true} />,
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: { user: { id: any; }; }) => (
        <Space size="middle">
          <Button onClick={() => onUserSelect(record)}>{t('View')}</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="white-bg padding-20 flex-column-container organization-table">
      <div className="flex-title-container">
        <div className="flex-title-content">
          <PageHeader showBack={!!isMobile} title={t('Users')} toPath="/organization" />
        </div>
        <div className="flex-action-button-content">
          <Button 
            disabled={!currentUserRoles?.includes('Entity Admin')}
            type="primary" onClick={onCreateUser}>
            {'+ Invite'}
          </Button>
        </div>
      </div>
      <div className="scrollable-content">
        {loading ? ( <Skeleton active /> ) : (
          <Table
            sticky
            dataSource={pagedResult?.data}
            columns={usersTableColumns as []}
            rowKey="id"
            pagination={{
              current: tableSpec?.page_number,
              pageSize: tableSpec?.page_size,
              total: pagedResult?.count,
              onChange: onPageChange,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
          />
        )}
      </div>
  </div>
  );
};

export default UsersList;
