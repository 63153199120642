import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';
import { isNotNull } from 'helper/listHelper';

interface LastReportedDateProps {
  latestDate: string;
}

const LastReportedDate = ({ latestDate }: LastReportedDateProps) => {
  const { t } = useTranslation();
  const localTime = moment(latestDate).local().format('Do MMM, YYYY, h:mma');
  const text = `${t('Last reported on')} ${localTime}`;

  if(!isNotNull(latestDate)){
    return null;
  }

  return (
    <Tooltip title={text} placement="topLeft">
      <Typography.Text type="secondary">
        {`${t('Last reported')} `}
        {moment(latestDate).fromNow()}
      </Typography.Text>
    </Tooltip>
  );
};

export default LastReportedDate;
