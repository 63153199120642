import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import RenderSprinklerBlock from './RenderSprinklerBlock';
import RenderAutonomousSprinklerBlock from './RenderAutonomousSprinklerBlock';
import { SPRINKLER_BLOCK_ID, SPRINKLER_BLOCK_IDS_REPL, SPRINKLER_BLOCK_IDS_AUTO } from '../../../constants';

const {
  selectNewBlocksForRanchIdAlphabetically,
} = selectors;
const RenderSprinklerRanch = (props) => {
  // previous = to support 2024 season blocks temporarily
  const { ranch, isAutonomous, previous } = props;
  const blocks = useSelector((state) => (
    selectNewBlocksForRanchIdAlphabetically(state, ranch.id))) || [];

  /**
 * Special Case: For GEC Project.
 * @description: Filtering out only Sprinkler block from GEC ranch as it is the only block
 * supporting Auto sprinkler automation functionality.
 * When to remove/Refactor: Once we start implementing
 * Sensor co-ordination configuration through frontend.
 */
  const filteredBlock = !isAutonomous
    ? blocks.filter((blk) => [...SPRINKLER_BLOCK_IDS_REPL].includes(blk.id))
    :blocks.filter((blk) => [...SPRINKLER_BLOCK_IDS_AUTO].includes(blk.id));
  const prevSeasonSprinklerBlocks = blocks.filter((blk) => blk.id === SPRINKLER_BLOCK_ID);

  return blocks.length > 0 ? (
    <div className="render-ranch-data">
      <Typography.Text className="ranch-name">{ranch.name}</Typography.Text>
      <div className="padding-10">
        {previous && prevSeasonSprinklerBlocks.map((block) => (
          <RenderSprinklerBlock
            key={block.id}
            block={block}
          />
        ))}
        {!previous && !isAutonomous && filteredBlock.map((block) => (
          <RenderSprinklerBlock
            key={block.id}
            block={block}
          />
        ))}
        {!previous && isAutonomous && filteredBlock.map((block) => (
          <RenderAutonomousSprinklerBlock
            key={block.id}
            block={block}
          />
        ))}
      </div>
    </div>
  ) : null;
};

RenderSprinklerRanch.propTypes = {
  ranch: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  isAutonomous: PropTypes.bool,
  previous: PropTypes.bool,
};

RenderSprinklerRanch.defaultProps = {
  isAutonomous: false,
  previous: false
};

export default RenderSprinklerRanch;
