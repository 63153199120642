/**
 * @param {*} pumpController - pump details with controlstatus
 * @param {*} valveController - valve details with controlstatus
 * @param {*} statusObj - block control status object
 * @returns - object with pump and valve counts with its current override status
 */
export function getPumpAndValveCounts(pumpController, valveController, statusObj) {
  const pumpObj = {
    isMultiple: (statusObj.pumpIdentifiers && statusObj.pumpIdentifiers.length > 1),
    bothStates: 'unknown',
    totalCount: statusObj.pumpIdentifiers && statusObj.pumpIdentifiers.length,
    onCount: 0,
    offCount: 0,
    onState: '',
    offState: '',
  };

  const valveObj = {
    isMultiple: (statusObj.valveIdentifiers && statusObj.valveIdentifiers.length > 1),
    totalCount: statusObj.valveIdentifiers && statusObj.valveIdentifiers.length,
    bothStates: 'unknown',
    openCount: 0,
    closedCount: 0,
    unknownCount: 0,
    openState: '',
    closedState: '',
    unknownState: '',
  };

  if (statusObj && statusObj.blockId) {
    statusObj.pumpIdentifiers.forEach((param) => {
      const pump = pumpController && pumpController[param.identifier];
      if (pump) {
        if (pump.status && pump.status.controlStatus && pump.status.controlStatus.state) {
          if (pump.status.controlStatus.state.toLowerCase() === 'on') {
            pumpObj.onCount += 1;
            pumpObj.onState = pump.status.controlStatus.state;
            if (pumpObj.totalCount === pumpObj.onCount) {
              pumpObj.bothStates = 'on';
            }
          } if (pump.status.controlStatus.state.toLowerCase() === 'off') {
            pumpObj.offCount += 1;
            pumpObj.offState = pump.status.controlStatus.state;
            if (pumpObj.totalCount === pumpObj.offCount) {
              pumpObj.bothStates = 'off';
            }
          }
        }
      }
    });

    statusObj.valveIdentifiers.forEach((param) => {
      const valve = valveController && valveController[param.identifier];
      if (valve) {
        if (valve?.status?.controlStatus?.state) {
          if (valve.status.controlStatus.state.toLowerCase() === 'open') {
            valveObj.openCount += 1;
            valveObj.openState = valve.status.controlStatus.state;
          }
          if (valve.status.controlStatus.state.toLowerCase() === 'closed') {
            valveObj.closedCount += 1;
            valveObj.closedState = valve.status.controlStatus.state;
          }
          if (valve.status.controlStatus.state.toLowerCase() === 'unknown') {
            valveObj.unknownCount += 1;
            valveObj.unknownState = valve.status.controlStatus.state;
          }
        }
      }
    });
  }
  return { multiplePumps: pumpObj, multipleValves: valveObj };
}
