import { satelliteApi } from 'farmx-api';

export const getAvailableSatelliteData = async (type, id) => {
  const { data } = await satelliteApi.getAvailableSatelliteData(type, id);
  return data;
};

export const getSatelliteDataByDataTypeAndDate = (type, id, dataType, time) => (
  satelliteApi.getSatelliteDataByDataTypeAndDate(type, id, dataType, time)
);

export const getAvailableDroneData = (ranchId) => (
  satelliteApi.getAvailableDroneDataForRanchId(ranchId)
);

export const getDroneImageryTilesData = (assetType, ranchId, imageryTaskId, imageryProjectId) => (
  satelliteApi.getDroneImageryTiles(assetType, ranchId, imageryTaskId, imageryProjectId)
);
