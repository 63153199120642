import React from 'react';
import { Tag } from 'antd';

type RanchBlockTagProps = {
  ranchName: string | undefined;
  blockName: string | undefined;
};

const RanchBlockTag = ({ ranchName, blockName }: RanchBlockTagProps) => {
  if (!ranchName) return null;
  return (
    <Tag color="default">
      {`${ranchName} / ${blockName}`}
    </Tag>
  );
};

export default RanchBlockTag;
