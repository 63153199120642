import React, { useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Space,
  Button,
  Card,
  Typography,
  Slider,
  Spin,
  notification,
} from 'antd';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import {
  SettingOutlined,
  CloseOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { blockApi } from 'farmx-api';
import './map3d.css';
import { mapHTMLScripts } from './mapHTMLScripts';
import { reducerMap3D, initReducerMap3D } from './reducerMap3D';
import {
  showGround,
  loadTiles,
  loadRanch,
} from './map3DHelpers';

import { THREE, Geometry, wNumb } from './deps';

const dateFormat = 'YYYY/MM/DD';

const antLoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

/*
https://codepen.io/WebSeed/pen/ZmXxKz
https://bitbucket.org/farmx/farmmap_web/raw/da64752f68847b8fb7f0bc4a544da0af1c07acda/farmmap/static/js/app/3dtest/threedDirective.js
*/
export function Map3D({ selectedBlockId, dispatchMapPage }) {
  const [stateMap3D, dispatchMap3D] = useReducer(
    reducerMap3D,
    initReducerMap3D(),
    initReducerMap3D,
  );

  const mount = useRef(null);
  const mountKeySlider = useRef(null);

  const renderer = useRef(undefined);
  const raycaster = useRef(undefined);
  const camera = useRef(undefined);
  const scene = useRef(undefined);
  const sceneRTT = useRef(undefined);
  const tileScene = useRef(undefined);
  const sensorScene = useRef(undefined);
  const texturesRTT = useRef(undefined);
  const controls = useRef(undefined);
  const cam = useRef(undefined);
  const cameraRTT = useRef(undefined);
  const font = useRef(undefined);
  const ranchObject = useRef(undefined);
  const ground = useRef(undefined);
  const renderedTexture = useRef(undefined);
  const tiles = useRef([]);
  const frameId = useRef(null);
  const paused = useRef(true);
  const depthValue = useRef(0);
  const colorSlider = useRef([0.0, 0.5]);
  const startDate = useRef(moment().subtract(7, 'days'));
  const endDate = useRef(moment());
  const time = useRef(0);
  const prevTime = useRef(Date.now());
  const numTimeSteps = 24 * 7;
  const isAnimate = useRef(true);

  // Update state to show the loader on the map page same position
  useEffect(() => {
    if(dispatchMapPage) {
      dispatchMapPage({ type: 'setLoadingData', payload: stateMap3D.loadingData });
    }
  }, [dispatchMapPage, stateMap3D.loadingData]);

  const handleResize = () => {
    renderer.current.setSize(mount.current.clientWidth, mount.current.clientHeight);
    camera.current.aspect = mount.current.clientWidth / mount.current.clientHeight;
    camera.current.updateProjectionMatrix();
  };

  const cleanSceneRanchData = () => {
    if (tileScene.current
      && Object.keys(ranchObject.current ? ranchObject.current.objects : {}).length) {
      tileScene.current.remove(ranchObject.current.objects);
    }
  };

  const animate = () => {
    cam.current.update();

    if (ranchObject.current) {
      if (ranchObject.current.selectedDepth != null) {
        showGround(false, tiles, ground, ranchObject);
      } else {
        showGround(true, tiles, ground, ranchObject);
      }
    }

    const curTime = Date.now();
    const delta = curTime - prevTime.current;

    if (!paused.current) {
      time.current += delta / 1000;
      const hour = time.current % numTimeSteps;
      setTimeout(() => {
        dispatchMap3D({
          type: 'setTimePositionValue',
          payload: startDate.current.valueOf() + hour * 60 * 60 * 1000,
        });
      }, 0);
    }

    setTimeout(() => {
      const vector = camera.current.getWorldDirection();
      dispatchMap3D({
        type: 'setCompassDegrees',
        payload: (Math.atan2(vector.y, vector.x) - Math.atan2(1, 0)) * (180 / Math.PI),
      });
    }, 0);

    if (ranchObject.current) {
      ranchObject.current.update(time.current % numTimeSteps);
    }

    renderer.current.clear();

    if (renderedTexture.current) {
      renderer.current.render(sceneRTT.current, cameraRTT.current, renderedTexture.current, true);
      renderer.current.render(sensorScene.current, camera.current);
      renderer.current.render(tileScene.current, camera.current);
    }

    prevTime.current = curTime;

    const vector = camera.current.getWorldDirection();
    let angleDeg = (Math.atan2(vector.y, vector.x) - Math.atan2(1, 0)) * (180 / Math.PI);
    angleDeg += 45;
    if (angleDeg < 0) angleDeg += 360;

    let ruler = 0;
    if (angleDeg >= 90) {
      ruler = 1;
    }
    if (angleDeg >= 180) {
      ruler = 2;
    }
    if (angleDeg >= 270) {
      ruler = 3;
    }

    if (ranchObject.current) {
      ranchObject.current.showRuler(ruler);
      ranchObject.current.setSelectedDepth((depthValue.current / 8) - 1);
      ranchObject.current.setDataRange(colorSlider.current[0], colorSlider.current[1]);
    }

    if (isAnimate.current) {
      frameId.current = requestAnimationFrame(animate);
      return;
    }

    if (frameId.current) {
      cancelAnimationFrame(frameId.current);
    }
  };

  useEffect(() => {
    dispatchMap3D({ type: 'reset' });
    renderer.current = new THREE.WebGLRenderer({
      antialias: true,
      logarithmicDepthBuffer: false,
    });
    renderer.current.setSize(mount.current.clientWidth, mount.current.clientHeight);

    renderer.current.setClearColor(0x000000);
    renderer.current.autoClear = false;
    renderer.current.gammaInput = true;
    renderer.current.gammaOutput = true;

    // TODO
    renderer.current.shadowMap.enabled = true;

    mount.current.appendChild(renderer.current.domElement);
    window.addEventListener('resize', handleResize);

    // END initRenderer

    // START initScene
    raycaster.current = new THREE.Raycaster();
    scene.current = new THREE.Scene();
    sceneRTT.current = new THREE.Scene();
    tileScene.current = new THREE.Scene();
    sensorScene.current = new THREE.Scene();
    texturesRTT.current = [];
    scene.current.fog = new THREE.Fog(0xffffff, 0.0135, 100);
    // END initScene

    // START initCamera
    camera.current = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      50000 * Geometry.SCALING_FACTOR,
    );
    controls.current = new THREE.TrackballControls(camera.current, renderer.current.domElement);
    controls.current.minDistance = 1;
    controls.current.maxDistance = 10;
    controls.current.keys = [65, 83, 68];
    controls.current.staticMoving = true;

    cam.current = new THREE.AnimatedCamera(camera.current, controls.current);

    cameraRTT.current = new THREE.OrthographicCamera(
      window.innerWidth / (-2),
      window.innerWidth / 2,
      window.innerHeight / 2,
      window.innerHeight / (-2),
      (-10000),
      10000,
    );
    cameraRTT.current.position.x = 0;
    cameraRTT.current.position.y = 0;
    cameraRTT.current.position.z = 10;
    cameraRTT.current.up = new THREE.Vector3(0, 1, 0);
    cameraRTT.current.lookAt(new THREE.Vector3(0, 0, 0));
    // END initCamera

    // START initLights
    tileScene.current.add((new THREE.AmbientLight(0xFFFFFF, 0.1)));
    // END initLights

    // START initGround
    ground.current = new THREE.Ground();
    ground.current.mesh.position.z = -0.001;
    // END initGround

    // Start initData partial


    // End initData partial

    return () => {
      cancelAnimationFrame(frameId.current);
      frameId.current = null;
      window.removeEventListener('resize', handleResize);
      if (mount.current) mount.current.removeChild(renderer.current.domElement);
      // scene.remove(something);
      // geometry.dispose();
      // material.dispose();
    };
  }, []);


  useEffect(() => { // get intial 3D data
    cleanSceneRanchData();
    if (selectedBlockId) {
      (async () => {
        dispatchMap3D({ type: 'setLoadingData', payload: true });
        try {
          const { data } = await blockApi.get3DBlock(selectedBlockId);

          if (!data.ranches || !data.ranches.length || !data.ranches[0].sensors.length) {
            notification.info({
              message: 'No data',
              description: '3D not available for this block',
            });
            dispatchMap3D({ type: 'setLoadingData', payload: false });
            // notificationText.innerHTML = "3D not available for this block";
            return;
          }

          data.ranches[0].sensors = data.ranches[0].sensors
            .filter((item) => item.data[0] !== undefined);
          data.id = selectedBlockId;

          dispatchMap3D({ type: 'setBlockData', payload: data });
        } catch (error) {
          console.log('req block Data', error);
          notification.error({
            message: 'Problem',
            description: 'Requesting data failed',
          });
        }
        dispatchMap3D({ type: 'setLoadingData', payload: false });
      })().catch();
    }
  }, [selectedBlockId]);

  // try to check dates in those two datasets
  // changes dates can only happen for existing block data

  useEffect(() => {
    if (!stateMap3D.blockData) {
      return;
    }

    const start = stateMap3D.dateRange.start.toISOString();
    const end = stateMap3D.dateRange.end.toISOString();

    if (moment(stateMap3D.blockData?.ranches?.[0]?.sensors?.[0]?.dates?.[0]).toISOString() === start
      && moment(stateMap3D.blockData?.ranches?.[0]?.sensors?.[0]?.dates?.[1])
        .toISOString() === end) {
      return;
    }

    cleanSceneRanchData();
    (async () => {
      dispatchMap3D({ type: 'setLoadingData', payload: true });
      try {
        const { data } = await blockApi.get3DBlockDateRange(stateMap3D.blockData.id, start, end);
        if (stateMap3D?.blockData?.ranches) {
          const newBlockData = cloneDeep(stateMap3D.blockData);
          newBlockData.ranches[0].sensors = data;
          dispatchMap3D({ type: 'setBlockData', payload: newBlockData });

          time.current = 0;

          loadRanch(
            newBlockData,
            ranchObject,
            font,
            tileScene,
            cam,
            camera,
            ground,
            cameraRTT,
            sceneRTT,
            renderer,
            renderedTexture,
          );
        } else {
          notification.info({
            message: 'No data',
            description: '3D not available for this block',
          });
          dispatchMap3D({ type: 'setLoadingData', payload: false });
        }
      } catch (error) {
        notification.error({
          message: 'Problem',
          description: 'Requesting data failed',
        });
        console.log('req block Data w daterange', error);
      }
      dispatchMap3D({ type: 'setLoadingData', payload: false });
    })().catch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMap3D.blockData, stateMap3D.dateRange]);

  useEffect(() => { // initGeometry
    if (stateMap3D.initedBlockId && stateMap3D.initedBlockId === stateMap3D.blockData.id) {
      return;
    }

    cleanSceneRanchData();
    if (stateMap3D.blockData) {
      // new THREE.AxisHelper(5);
      font.current = new THREE.Font(global.THREEDFONT);

      const center = loadRanch(
        stateMap3D.blockData,
        ranchObject,
        font,
        tileScene,
        cam,
        camera,
        ground,
        cameraRTT,
        sceneRTT,
        renderer,
        renderedTexture,
      );

      loadTiles(15, -0.001, center, renderer, tileScene, tiles);

      dispatchMap3D({ type: 'setInitedBlockId', payload: stateMap3D.blockData.id });

      setTimeout(() => {
        animate();
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMap3D.blockData]);

  useEffect(() => {
    if (stateMap3D.isMenuOpen && mountKeySlider.current) {
      noUiSlider.create(mountKeySlider.current, {
        start: stateMap3D.colorSliderStart,
        connect: [true, true, true],
        orientation: 'horizontal',
        range: {
          min: 0.0,
          max: 0.5,
        },
        pips: {
          mode: 'count',
          values: 4,
          density: 8,
          format: wNumb({
            decimals: 1,
          }),
        },
      });

      const classes = ['c-1-color', 'c-2-color', 'c-3-color'];
      Array.from(document.querySelectorAll('#key-slider .noUi-connect')).forEach((el, idx) => {
        el.classList.add(classes[idx]);
      });

      mountKeySlider.current.noUiSlider.on('update', (values) => {
        const minValue = parseFloat(values[0]);
        const maxValue = parseFloat(values[1]);
        dispatchMap3D({ type: 'setColorSliderStart', payload: [minValue, maxValue] });
        if (ranchObject.current) {
          ranchObject.current.setDataRange(minValue, maxValue);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateMap3D.isMenuOpen]);

  useEffect(() => {
    depthValue.current = stateMap3D.depthValue;
    if (ranchObject.current) {
      ranchObject.current.setSelectedDepth((stateMap3D.depthValue / 8) - 1);
    }
  }, [stateMap3D.depthValue]);

  useEffect(() => {
    colorSlider.current = stateMap3D.colorSliderStart;
    if (ranchObject.current) {
      ranchObject.current.setDataRange(
        stateMap3D.colorSliderStart[0],
        stateMap3D.colorSliderStart[1],
      );
    }
  }, [stateMap3D.colorSliderStart]);

  useEffect(() => {
    paused.current = stateMap3D.paused;
  }, [stateMap3D.paused]);

  useEffect(() => {
    startDate.current = stateMap3D.dateRange.start;
    endDate.current = stateMap3D.dateRange.end;
    time.current = 0;
    prevTime.current = Date.now();
    dispatchMap3D({ type: 'setTimePositionValue', payload: stateMap3D.dateRange.start.valueOf() });
  }, [stateMap3D.dateRange]);

  const disabledDate = (side) => (current) => {
    if (side === 'start') {
      return current && current > moment().endOf('day');
    }
    if (side === 'end') {
      return current && current > moment().subtract(1, 'days');
    }
    return current;
  };

  return (
    <div className="layout-fill">
      {
        !dispatchMapPage && stateMap3D.loadingData && (
        <div
          style={{
            position: 'relative',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Spin indicator={antLoadingIcon} />
        </div>
        )
      }
      <div
        id="threed-container"
        className="vis"
        ref={mount}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: mapHTMLScripts }}
      />

      {stateMap3D.isMenuOpen && (
        <div
          style={{
            position: 'absolute',
            right: '54px',
            top: '52px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card style={{ paddingBottom: '26px' }}>
            <Space direction="vertical" size={12}>
              <Typography.Text>
                Select Start Date
              </Typography.Text>
              <DatePicker
                allowClear={false}
                value={stateMap3D.dateRange.start}
                disabledDate={disabledDate('start')}
                onChange={(start) => {
                  if (start) {
                    const startOrig = cloneDeep(start);
                    let end = cloneDeep(stateMap3D.dateRange.end);
                    if (end.diff(start, 'days') > 30) {
                      end = start.add(29, 'days');
                    }
                    dispatchMap3D({
                      type: 'setDateRange',
                      payload: {
                        start: startOrig,
                        end,
                      },
                    });
                  }
                }}
                format={dateFormat}
              />
              <Typography.Text>
                Select End Date
              </Typography.Text>
              <DatePicker
                allowClear={false}
                value={stateMap3D.dateRange.end}
                disabledDate={disabledDate('end')}
                onChange={(end) => {
                  if (end) {
                    const endOrig = cloneDeep(end);
                    let start = cloneDeep(stateMap3D.dateRange.start);
                    if (end.diff(start, 'days') > 30) {
                      start = end.subtract(29, 'days');
                    }
                    dispatchMap3D({
                      type: 'setDateRange',
                      payload: {
                        end: endOrig,
                        start,
                      },
                    });
                  }
                }}
                format={dateFormat}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ minWidth: '120px' }}>
                  <div><Typography.Text>{stateMap3D.timePositionValue && moment(stateMap3D.timePositionValue).format('MMMM Do, YYYY')}</Typography.Text></div>
                  <div><Typography.Text>{stateMap3D.timePositionValue && moment(stateMap3D.timePositionValue).format('h:mm:ss a')}</Typography.Text></div>
                </div>


                <Button
                  style={{ marginLeft: '10px' }}
                  icon={stateMap3D.paused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
                  type="primary"
                  size="large"
                  ghost={false}
                  onClick={() => {
                    dispatchMap3D({ type: 'setPaused', payload: !stateMap3D.paused });
                  }}
                />

              </div>

              <Slider
                step={60000}
                tooltipVisible={false}
                value={stateMap3D.timePositionValue}
                onChange={(value) => {
                  paused.current = true;
                  dispatchMap3D({ type: 'setPaused', payload: true });
                  dispatchMap3D({
                    type: 'setTimePositionValue',
                    payload: value,
                  });
                  time.current = (value - startDate.current.valueOf()) / (1000 * 60 * 60);
                }}
                min={startDate.current.valueOf()}
                max={endDate.current.valueOf() - 1}
              />

              <Typography.Text>Depth</Typography.Text>

              <Slider
                step={8}
                tooltipVisible={false}
                min={0}
                max={48}
                dots
                marks={{
                  0: '0',
                  8: '8',
                  16: '16',
                  24: '24',
                  32: '32',
                  40: '40',
                  48: '48',
                }}
                value={stateMap3D.depthValue}
                onChange={(value) => {
                  dispatchMap3D({ type: 'setDepthValue', payload: value });
                }}
              />
              <Typography.Text>Adjust Color Range</Typography.Text>
              <div id="key-slider" ref={mountKeySlider} />
            </Space>
          </Card>

        </div>
      )}

      <div
        style={{
          position: 'absolute',
          right: '0',
          top: '0px',
          bottom: '0',
          background: 'rgba(0,0,0,0.2)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            margin: '1px',
            height: '50px',
            width: '50px',
            paddingLeft: '5px',
          }}
        >
          <Button
            size="large"
            type="primary"
            ghost={false}
            style={{ width: '40px', marginTop: '10px' }}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatchMapPage({ type: 'setShowMap3D', payload: false });
            }}
          />
        </div>
        <div
          style={{
            margin: '1px',
            height: '50px',
            width: '50px',
            paddingLeft: '5px',
          }}
        >
          <Button
            icon={<SettingOutlined />}
            type="primary"
            size="large"
            title="Settings"
            style={{ width: '40px', marginTop: '10px' }}
            ghost={stateMap3D.isMenuOpen}
            onClick={() => {
              dispatchMap3D({ type: 'toggleIsMenuOpen' });
            }}
          />
        </div>
        <div
          style={{
            margin: '1px',
            height: '50px',
            width: '50px',
            marginTop: 'auto',
          }}
        >
          <div
            id="compass"
            layout="column"
            layout-align="center center"
            style={{
              marginBottom: '10px',
              height: '30px',
              transform: `rotate(${stateMap3D.compassDegrees}deg)`,
              transformOrigin: '50% 50%',
            }}
            className="layout-align-center-center layout-column"
          >

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path id="svg_1" d="m8,0.5l-2.5,7l2.5,-1.55664l2.5,1.55664l-2.5,-7zm-2.5293,2.62109c-1.62195,0.84364 -2.76157,2.4726 -2.93554,4.37891l-2.03516,0l0,1l2.02539,0c0.23843,2.63491 2.3397,4.73618 4.97461,4.97461l0,2.02539l1,0l0,-2.02539c2.63491,-0.23843 4.73618,-2.3397 4.97461,-4.97461l2.02539,0l0,-1l-2.03516,0c-0.17397,-1.90631 -1.31359,-3.53527 -2.93554,-4.37891l0.58789,1.64649c0.849,0.819 1.38281,1.96142 1.38281,3.23242c0,2.481 -2.019,4.5 -4.5,4.5c-2.481,0 -4.5,-2.019 -4.5,-4.5c0,-1.271 0.53381,-2.41342 1.38281,-3.23242l0.58789,-1.64649z" fill="#FFF" />
              <text xmlSpace="preserve" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="4" id="svg_2" y="10.83984" x="6.5625" strokeWidth="0" stroke="#000" fill="#FFF">N</text>
            </svg>

          </div>
        </div>
      </div>

    </div>
  );
}

Map3D.propTypes = {
  dispatchMapPage: PropTypes.func.isRequired,
  selectedBlockId: PropTypes.number,
};

Map3D.defaultProps = {
  selectedBlockId: undefined,
};
