import React, {
  useCallback, useContext, useEffect, useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { hooks } from 'farmx-redux-core';
import { ROOT_ZONE_VWC } from 'components/hooks/graph';
import { FaEdit } from 'react-icons/fa';
import {
  cutoffLineLabelStyle,
  getMultiplierAndData, getRootzoneYminMax, preparePlotLines
} from '../../helper/graphHelper';
import {
  colorCritical,
  colorWarning,
  colorOver,
  colorOk,
} from '../../utils/colors';
import NewSensorDataChart from './NewSensorDataChart';
// import GraphContext from './GraphContext';
import { VAR_SOIL_MOISTURE_ROOTZONE_VWC } from './constants';
import EditSoilSensorProperties from './EditSoilSensorProperties';
import GraphContext from './GraphContext';

const { useUnits } = hooks;

export default function RootZoneVWCChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap, refreshSensorData
  } = props;
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const { sensorDataRefresh } = useContext(GraphContext);
  const refreshCount = sensorDataRefresh[`${sensor.type}/${sensor.identifier}`];

  // TODO remove after testing
  // console.log('Render RootZoneVWCChart');

  // const {setYAxisMinMax} = useContext(GraphContext);
  const globalYMinMax = yMinMaxMap[ROOT_ZONE_VWC]?yMinMaxMap[ROOT_ZONE_VWC]:{min: null, max: null};

  // TODO remove after testing
  // console.log('yMinMaxMap RootZoneVWCChart', yMinMaxMap);

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0) && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  // TODO remove after testing
  // console.log('yMinMax RootZoneVWCChart', yMinMax);

  // useEffect(() => {
  //   console.log('Mount RootZoneVWCChart');
  // }, []);

  useEffect(() => {
    if(refreshCount > 0) setRefresh((prev) => prev+1);
  }, [refreshCount]);

  const customConfig = useCallback((configObj, seriesData) => {
    // TODO remove after testing
    // console.log('calling customConfig');
    let plotLines = [];
    let plotBands = [];
    const rootZoneVwcData = seriesData?.soil_moisture_rootzone_vwc;
    if(configObj?.series?.[0]){
      const yMinMax = getRootzoneYminMax(seriesData);
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(ROOT_ZONE_VWC, yMinMax.min * multiplier, yMinMax.max * multiplier);
      // TODO remove after testing
      // console.log('local y min max multiplier', yMinMax.min, yMinMax.max, multiplier);
    }
    if (configObj && rootZoneVwcData) {
      const updatedConfig = Object.entries(seriesData).map(([key, value]) => {
        if (!value.length) return undefined;
        const chartData = value[0];
        if (!chartData.data.length) return undefined;

        const { data: variableData } = chartData;
        const { multiplier } = getMultiplierAndData(variableData, useUnits, chartData.units);

        const linesConfig = [{
          color: colorOver,
          value: chartData.moisture_zone_upper * multiplier,
          type: 'upper',
          from: chartData.moisture_zone_upper * multiplier,
          to: (chartData.moisture_zone_upper * multiplier || 0) + 1 * multiplier,
          className: 'upper-band-line',
          label: {
            x: -110,
            y: 12,
            align: 'right',
            text: `FC  (${Number(chartData.moisture_zone_upper * multiplier).toFixed(1)})`,
            style: cutoffLineLabelStyle
          }
        },
        {
          color: colorWarning,
          value: chartData.moisture_zone_middle * multiplier,
          type: 'middle',
          label: {
            x: -55,
            y: 12,
            align: 'right',
            text: `RP (${Number(chartData.moisture_zone_middle * multiplier).toFixed(1)})`,
            style: cutoffLineLabelStyle
          }
        },
        {
          color: colorCritical,
          value: chartData.moisture_zone_lower * multiplier,
          type: 'lower',
          from: 0,
          to: chartData.moisture_zone_lower * multiplier,
          className: 'lower-band-line',
          label: {
            x: 0,
            y: 12,
            align: 'right',
            text: `WP (${Number(chartData.moisture_zone_lower * multiplier).toFixed(1)})`,
            style: cutoffLineLabelStyle
          }
        }];
        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;
        const yAxis = configObj.yAxis.map((d) => {
          const obj = { ...d };
          obj.plotLines =plotLines;
          obj.plotBands = plotBands;
          return obj;
        });
        return { ...configObj, yAxis, plotOptions: { series: { color: colorOk } } };
      }).reduce((acc, obj) => {
        const config = { ...acc, ...obj };
        return config;
      }, {});
      return updatedConfig;
    }
    return configObj;
  }, [updateYminmax]);

  const onClickEdit = useCallback(() => {
    setEdit(true);
  });

  const onCancel = useCallback(() => {
    setEdit(false);
  });

  const onSave = useCallback(() => {
    setEdit(false);
    // setRefresh((prev) => prev+1);
    refreshSensorData(sensor.type, sensor.identifier);
  });

  const EditLink = useMemo(() => (
    <div
      key={`edit-link-${sensor.id}`}
      className="chart-header-edit-link"
      onClick={onClickEdit}
      onKeyDown={onClickEdit}
      role="button"
      tabIndex={0}
    >
      <FaEdit />
      <span style={{marginLeft: '13px'}}>
        Edit
      </span>
    </div>
  ), [onClickEdit, sensor.id]);

  const renderHeader = useMemo(() => ({
    title: t('Rootzone Soil Moisture VWC'),
    units: t('percent'),
    right: [EditLink]
  }), [EditLink, t]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_SOIL_MOISTURE_ROOTZONE_VWC}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={renderHeader}
        // To be removed after testing
        // updateYminmax={updateYminmaxForRootZoneVWC}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
        refresh={refresh}
      />
      {edit?(
        <EditSoilSensorProperties
          sensor={sensor}
          startDate={startDate}
          endDate={endDate}
          onCancel={onCancel}
          onSave={onSave}
        />
      ):null}
    </>
  );
}

RootZoneVWCChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
  refreshSensorData: PropTypes.func,
};

RootZoneVWCChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
  refreshSensorData: () => null,
};
