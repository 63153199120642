import { helpers } from 'farmx-api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  message,
} from 'antd';
import axios from 'axios';
import { CALL_CUSTOM_CONFIG_AND_RENDER, NO_RENDER, ONLY_RENDER }
  from 'components/graph/constants';
import {
  colorOk,
  soilDepthPalette,
} from '../utils/colors';

const { fieldToHeader } = helpers;

function sortByName(sensors) {
  return sensors.sort((a, b) => a?.name?.localeCompare(b?.name));
}

/**
 * @param {*} sSensors - array of soil sensors objects
 * @param {*} pSensors - array of pressure sensors objects
 * @param {*} blockIds - array of blockIds
 * @returns - The array of objects with grouped sensors by blockIds
 */
export function useGroupSensors({
  sSensors, pSensors, wsSensors, vSensors, ddSensors
}, blockIds) {
  const [sensors, setSensors] = useState([]);
  useEffect(() => {
    // remove after testing
    // console.log('sSensors', sSensors, 'pSensors', pSensors, 'wsSensors', wsSensors);
    const allSensors = [];
    const remainingSensors = [];
    if(sSensors && pSensors && wsSensors && vSensors) {
      blockIds.forEach((blockId) => {
        const sortedSoilSensors = sSensors[blockId] ? sortByName(sSensors[blockId]) : [];
        const filteredSoilSensors = sortedSoilSensors.filter(
          (d) => d?.name || d?.identifier
        );

        const sortedPressureSensors = pSensors[blockId] ? sortByName(pSensors[blockId]) : [];
        const filteredPressureSensors = sortedPressureSensors.filter(
          (d) => d?.name || d?.identifier
        );

        const sortedWeatherStationSensors = wsSensors[blockId] ? sortByName(wsSensors[blockId]) : [];
        const filteredWeatherStationSensors = sortedWeatherStationSensors.filter(
          (d) => d?.name || d?.identifier
        );

        const sortedValveSensors = vSensors[blockId] ? sortByName(vSensors[blockId]) : [];
        const sortedDendrometers = ddSensors[blockId] ? sortByName(ddSensors[blockId]) : [];

        if(filteredWeatherStationSensors){
          filteredWeatherStationSensors.forEach((sensor) => {
            allSensors.push(sensor);
          });
        }
        if (filteredSoilSensors?.length) {
        // Grouping pressure senors and soil sensors by blockId
          filteredSoilSensors.forEach((sensor) => {
            allSensors.push(sensor, ...filteredPressureSensors);
          });
        } else if (filteredPressureSensors?.length) {
        // Collecting pressure senors that are not included with soil sensors
          remainingSensors.push(...filteredPressureSensors);
        }
        if(sortedValveSensors?.length){
          remainingSensors.push(...sortedValveSensors);
        }
        if(sortedDendrometers?.length){
          remainingSensors.push(...sortedDendrometers);
        }
      });
    }
    setSensors([...allSensors, ...remainingSensors]);
  }, [blockIds, pSensors, sSensors, wsSensors, vSensors, ddSensors]);
  // TODO remove after testing
  // console.log('Grouped sensors', sensors);
  return sensors;
}

/**
 * Need to group data node wise and then push it to an
 * array. That array will be returned as the final output.
 * This helps in removing duplicate entries of pressure sensor from the list.
 * Method works in two steps:
 * STEP 1:
 * Create nodeWiseMapping in the following format:
 * {
 *  [node]: {
 *    [identifier]: {},
 *    [identifier]: {}
 *  },
 *  [node]: {
 *    [identifier]: {},
 *    [identifier]: {}
 *  },
 * }
 *
 * Step 2:
 * Form the final array. This array contains every object mapped to identifier
 * in the above nodeWiseMapping.
 */
export const getUniqueSensorData = (sensorsData) => {
  try {
    const nodeWiseMapping = {};
    const SensorDataNew = [];
    sensorsData.forEach((sensorsDataItem) => {
      if(isWeatherStation(sensorsDataItem)){
        SensorDataNew.push(sensorsDataItem);
      }else if (nodeWiseMapping[sensorsDataItem.node]) {
        nodeWiseMapping[sensorsDataItem.node][sensorsDataItem.identifier] = sensorsDataItem;
      } else {
        nodeWiseMapping[sensorsDataItem.node] = {};
        nodeWiseMapping[sensorsDataItem.node][sensorsDataItem.identifier] = sensorsDataItem;
      }
    });
    // TODO remove after testing
    // console.log('nodeWiseMapping', nodeWiseMapping)
    // SensorDataNew contains all the unique sensors
    Object.keys(nodeWiseMapping).forEach((nodeWiseMappingKey) => {
      Object.keys(nodeWiseMapping[nodeWiseMappingKey]).forEach((key) => {
        SensorDataNew.push(nodeWiseMapping[nodeWiseMappingKey][key]);
      });
    });
    return SensorDataNew;
  } catch {
    return sensorsData;
  }
};

export function isWeatherStation(sensor){
  return sensor.type==='weather_station';
}

/**
 * @param {*} graphConfig - config string to set key for config objects array
 * @param {*} sensorsData - sensors data array for the selected ranch/block
 * @param {*} SensorDataChart - component to render the graph
 * @param {*} variable - object that contains graphConfig as key with variables array
 * @returns - The object with graphConfig as key and values as array of
 * graph config objects for the selected ranch/block
 */
export function prepareGraphConfig(graphConfig, sensorsData = [], SensorDataChart, variableObj) {
  const SensorDataNew = getUniqueSensorData(sensorsData);
  const graphsObj = {};
  const configArr = [];
  SensorDataNew.forEach((sensor, sKey) => {
    let variables = [];
    if (sensor?.type === 'aquacheck_soil'
      || sensor?.type === 'pixl_soil') variables = variableObj.soilVariables;
    if (sensor?.type === 'water_pressure') variables = variableObj.pressureVariables;

    // Hack for ranch soil caliberation
    // Add comma separated ranch ids in env variable REACT_APP_RANCH_IDS_TO_CALIBERATE
    const ranchIds = process.env.REACT_APP_RANCH_IDS_TO_CALIBERATE;
    if (ranchIds.includes(sensor.ranch) && sensor?.type === 'pixl_soil'){
      variables = [
        'measure_1', 'measure_2', 'measure_3', 'measure_4',
        'soil_temp_6', 'soil_temp_12', 'soil_temp_18', 'soil_temp_24',
      ];
    }
    const {
      identifier, id, type, name,
    } = sensor;
    variables.forEach((variable, vKey) => {
      const sObj = {
        component: SensorDataChart,
        key: `${name}_${sKey}_${vKey}`,
        variable,
        name,
        identifier,
        sensor: { identifier, id, type },
      };
      configArr.push(sObj);
    });
  });
  graphsObj[graphConfig] = configArr;
  return graphsObj;
}

const units = {
  soil_moisture_rootzone_vwc: 'percent',
  water_pressure: 'psi',
};

export function getChartHeader(key) {
  const unitsStr = units[key] ? ` (${units[key]})` : '';
  const variableName = fieldToHeader(key);
  const legendTitle = variableName + unitsStr;
  return legendTitle;
}

export function concatSoilSensors(soilSensor, pixlSensor) {
  const soilSensorKeys = Object.keys(soilSensor);
  const concatenateSoilSensors = soilSensorKeys.map((key) => {
    const obj = {};
    obj[key] = [...soilSensor[key], ...pixlSensor[key]];

    return obj;
  }).reduce((a, obj) => ({ ...a, ...obj }), {});

  return concatenateSoilSensors;
}

export function filterSensorByType(sensors, type) {
  let filteredSensors = [];
  if (sensors?.length) {
    filteredSensors = sensors?.filter((d) => d?.type !== type);
  }
  return filteredSensors;
}

/**
 * We have instances where we get big data sizes to render.
 * The graph line comprises data with intervals in seconds.
 * And it causes graph to sometimes not render the graph line,
 * as happening in EditSensorSettings component.
 *
 * This function limits the data set in a given interval. Currently
 * limiting data to the interval of 1 hour. Which means the minimum gap in
 * between two data is 1 hour.
 * This function can be extended in future to configure gaps.
 *
 * This function also memoize the results. Since the data sets are large,
 * it's good to cache the results to avoid same calculation multiple times.
 */
const generateLimitedData = (unit='hours', value=1) => {
  const cache = {};

  return (data) => {
    const key = JSON.stringify(data);
    if (`${key}-${value}-${unit}}` in cache) {
      return cache[`${key}-${value}-${unit}}`];
    }
    const lData = [];
    let previousDate = '';
    data.forEach((d, i) => {
      if (i === 0) {
        [previousDate] = d;
        lData.push(d);
      } else {
        const start = moment(previousDate);
        const end = moment(d[0]);
        const duration = end.diff(start, unit);
        if (duration > value) {
          lData.push(d);
          [previousDate] = d;
        }
      }
    });
    cache[`${key}-${value}-${unit}}`] = lData;
    return lData;
  };
};

export const getLimitedData = generateLimitedData();

/**
 * Get average value of data for given time period
 * Note: Current implementation returns for daily average
 * params:
 * periodValue: eg: 1, for future use
 * periodUnit: eg: 'day', for future use
 */
const getAverageValueFor = (data, periodValue, periodUnit, multiplier=1) => {
  const output = [];
  if (data?.length && data?.[0]) {
    const prevDatapoint = data[0][0];
    let sum = 0;
    let count = 1;
    let prevDate = moment(prevDatapoint).format('MM-DD-YYYY');
    // loop for data
    data.forEach((datapoint) => {
    // extract date from timestamp of each data point
      const datapointDate = moment(datapoint[0]).format('MM-DD-YYYY');
      // TODO remove after testing multiple cases
      // console.log(prevDate, datapointDate);
      if(prevDate === datapointDate){
      // add data value to sum,
        if(datapoint[1] > 0){
          sum += datapoint[1];
          // add 1 to count
          count += 1;
        }
      }else{
      // find average for the date MM-DD-YYYY
        const avgValue = count
          ?Number((sum/count) * multiplier)
          :0;
        // push to array
        output.push([moment(prevDate).valueOf(), avgValue]);
        // reset variables
        [,sum] = datapoint;
        count = sum?1:0;
        prevDate = datapointDate;
      }
    });
  }
  // TODO remove after testing multiple cases
  // console.log('output', output);
  return output;
};

export const getDailyAverage = (data, multiplier) => getAverageValueFor(data, 1, 'day', multiplier);

/**
 * Get sum value of data for given time period
 * Note: Current implementation returns for daily sum
 * params:
 * periodValue: eg: 1, for future use
 * periodUnit: eg: 'day', for future use
 */
const getSumValueFor = (data, periodValue, periodUnit, multiplier=1) => {
  const output = [];
  if (data?.length && data?.[0]) {
    const prevDatapoint = data[0][0];
    let sum = 0;
    let prevDate = moment(prevDatapoint).format('MM-DD-YYYY');
    // loop for data
    data.forEach((datapoint) => {
      // extract date from timestamp of each data point
      const datapointDate = moment(datapoint[0]).format('MM-DD-YYYY');
      if(prevDate === datapointDate){
        // add data value to sum,
        if(datapoint[1] > 0) sum += datapoint[1];
      } else {
        // Update data by given multiplier
        const sumValue = sum ? (Number(sum) * multiplier) : 0;
        // push to array
        output.push([moment(prevDate).valueOf(), sumValue]);
        // reset variables
        [,sum] = datapoint;
        prevDate = datapointDate;
      }
    });
  }
  return output;
};

export const getDailySum = (data, multiplier) => getSumValueFor(data, 1, 'day', multiplier);

export const convertLengthUnits = (data, getUserUnits, units='inches') => data.map((d) => {
  const {value} = getUserUnits(d[1], units, 'depth', {decimalPlaces: 2});
  return [d[0], value];
});

export const convertToCelsius = (data, getUserUnits) => {
  const {value, label} = getUserUnits(data, 'degrees_fahrenheit', 'temperature', {decimalPlaces: 2});
  return {value, label};
};

export const convertToFahrenheit = (data, getUserUnits) => {
  const {value, label} = getUserUnits(data, 'degrees_celsius', 'temperature', {decimalPlaces: 2});
  return {value, label};
};

export function psiToUserUnit(value, getUserUnits, decimalPlaces = 1) {
  if (value === null || value === undefined || value === '') return 0;
  const convertedValue = getUserUnits(value, 'pounds_per_square_inch', 'pressure', { decimalPlaces }).value;
  return convertedValue;
}

export function convertWindDirectionData(data) {
  const directionMap={
    N: 0,
    NNE: 1,
    NE: 2,
    ENE: 3,
    E: 4,
    ESE: 5,
    SE: 6,
    SSE: 7,
    S: 8,
    SSW: 9,
    SW: 10,
    WSW: 11,
    W: 12,
    WNW: 13,
    NW: 14,
    NNW: 15
  };
  return data.map(([timestamp, direction]) => ({
    x: timestamp,
    y: directionMap[direction],
    label: direction
  }));
}


export function preparePlotLines(linesConfig) {
  const defaultStyle = {
    zIndex: 5,
    dashStyle: 'dash',
    width: 2,
  };
  const plotLinesArr = linesConfig.map((configObj) => ({
    ...configObj, ...defaultStyle, from: undefined, to: undefined
  }));
  const filteredConfig = linesConfig.filter((d) => d?.type !== 'middle');
  const plotBandConfigArr = filteredConfig.map((d) => ({
    from: d.value ? d.from : null,
    to: d.value ? d.to : null,
    color: d.color,
  }));
  return { plotLines: plotLinesArr, plotBands: plotBandConfigArr };
}

/**
 * Compute a common min and max value for the graph. (Includes cut off points)
 * These common values can be then used in yAxis obj as opposed to
 * individual min and max values.
 * It eliminates the chance of overlapping of yAxis labels.
 */
export const computeMinAndMax = (data, sensorStatus) => {
  let newMin = Infinity;
  let newMax = -Infinity;

  try {
    let arr = [];
    // old logic, TODO remove after testing is complete
    // const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    // Object.keys(data).forEach((dataKey) => {
    //   const dataValue = data[dataKey][0];
    //   const depthIndex = (dataKey.split('soil_moisture_')[1]) / depthSize;
    //   const fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
    //   const refillPoint = sensorStatus[`refillPoint${depthIndex}`];
    //   const wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
    //   const dataValueMin = dataValue?.min;
    //   const dataValueMax = dataValue?.max;
    //   const zoneLower = dataValue?.moisture_zone_lower;
    //   arr = [
    //     ...arr,
    //     fieldCapacity,
    //     refillPoint,
    //     wiltingPoint,
    //     dataValueMin,
    //     dataValueMax,
    //     zoneLower,
    //   ];
    // });

    // New logic
    if(sensorStatus.depthCount > 0){
      for(let depthIndex = 1; depthIndex <= sensorStatus.depthCount; depthIndex++){
        const depth = sensorStatus[`depth${depthIndex}`];
        const dataValue = data[`soil_moisture_${depth}`][0];
        const fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
        const refillPoint = sensorStatus[`refillPoint${depthIndex}`];
        const wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
        const dataValueMin = dataValue?.min;
        const dataValueMax = dataValue?.max;
        const zoneLower = dataValue?.moisture_zone_lower;
        const zoneUpper = dataValue?.moisture_zone_upper;
        arr = [
          ...arr,
          fieldCapacity,
          refillPoint,
          wiltingPoint,
          dataValueMin,
          dataValueMax,
          zoneLower,
          zoneUpper,
        ];
      }
    }
    newMin = getMin(arr);
    newMax = getMax(arr);
    // TODO remove after testing
    // console.log('minmaxarr', arr);
  } catch {
    return { min: undefined, max: undefined };
  }
  return { min: newMin, max: newMax };
};

export const getYminMaxForInvidividualDepth = (data, sensorStatus) => {
  // TODO remove after testing
  // console.log('getYminMaxForInvidividualDepth', data, sensorStatus);
  let newMin = Infinity;
  let newMax = -Infinity;
  try {
    let arr = [];
    const dataKey = data.actualChartKey;
    const depthNum = dataKey.split('soil_moisture_')[1];
    const dataValue = data;
    const depthIndex = getDepthIndex(sensorStatus, depthNum);
    const fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
    const refillPoint = sensorStatus[`refillPoint${depthIndex}`];
    const wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
    const dataValueMin = dataValue?.min;
    const dataValueMax = dataValue?.max;
    const zoneLower = dataValue?.moisture_zone_lower;
    const zoneUpper = dataValue?.moisture_zone_upper;
    arr = [
      fieldCapacity,
      refillPoint,
      wiltingPoint,
      dataValueMin,
      dataValueMax,
      zoneLower,
      zoneUpper,
    ];
    newMin = getMin(arr);
    newMax = getMax(arr);
    // TODO remove after testing
    // console.log('yAxisMinMax', arr, { min: newMin, max: newMax });
    return { min: newMin, max: newMax };
  } catch {
    return null;
  }
};

/**
 * Compute a common min and max value for the graph.(no cut off points included)
 * These common values can be then used in yAxis obj as opposed to
 * individual min and max values.
 * It eliminates the chance of overlapping of yAxis labels.
 * params:
 * data: graph data response from api
 * sensorStatus: sensor status response from api
 * keyType : default value = moisture.
 */
export const computeYMinAndMaxForAllDepths = (data, sensorStatus, keyType='moisture') => {
  let newMin = Infinity;
  let newMax = -Infinity;
  try {
    let arr = [];
    if(sensorStatus.depthCount > 0){
      for(let depthIndex = 1; depthIndex <= sensorStatus.depthCount; depthIndex+=1){
        const depth = sensorStatus[`depth${depthIndex}`];
        const dataValue = data[`soil_${keyType}_${depth}`][0];
        const dataValueMin = dataValue?.min;
        const dataValueMax = dataValue?.max;
        arr = [
          ...arr,
          dataValueMin,
          dataValueMax,
        ];
      }
    }
    newMin = getMin(arr);
    newMax = getMax(arr);
    // TODO remove after testing
    // console.log('minmaxarr', arr);
  } catch {
    return { min: undefined, max: undefined };
  }
  return { min: newMin, max: newMax };
};

// For data with single key
export const getYminMax = (data) => {
  const keys = Object.keys(data);
  console.log('data', data);
  if(keys.length){
    const dataValue = data[keys[0]][0];
    const dataValueMin = dataValue?.min;
    const dataValueMax = dataValue?.max;
    return { min: dataValueMin, max: dataValueMax };
  }
  return null;
};

export const getYminMaxForSum = (data) => {
  if(data?.length) {
    const dataPoints = data?.map((d) => d?.[1]);
    const dataValueMin = Math.min(...dataPoints) || 0;
    const dataValueMax = Math.max(...dataPoints) || 0;
    return { min: dataValueMin, max: dataValueMax };
  }
  return { min: 0, max: 0 };
};

export const getRootzoneYminMax = (data) => {
  let newMin = Infinity;
  let newMax = -Infinity;
  const keys = Object.keys(data);
  if(keys.length){
    const dataValue = data[keys[0]][0];
    const zoneUpper = dataValue?.moisture_zone_upper;
    const zoneMiddle = dataValue?.moisture_zone_middle;
    const zoneLower = dataValue?.moisture_zone_lower;
    const dataValueMin = dataValue?.min;
    const dataValueMax = dataValue?.max;
    const arr = [
      zoneUpper,
      zoneMiddle,
      zoneLower,
      dataValueMin,
      dataValueMax,
      zoneLower,
    ];
    newMin = getMin(arr);
    newMax = getMax(arr);
    // TODO remove after testing
    // console.log('minmaxarr', arr);
    return { min: newMin, max: newMax };
  }
};

export const getWaterPressureYminMax = (data) => {
  const keys = Object.keys(data);
  if(keys.length){
    const dataValue = data[keys[0]][0];
    const dataValueMin = dataValue?.min;
    const dataValueMax = dataValue?.max;
    return { min: dataValueMin, max: dataValueMax };
  }
};

const getMin = (args) => {
  const params = args.filter((arg) => !isNaN(arg));
  return Math.min(...params);
};
const getMax = (args) => {
  const params = args.filter((arg) => !isNaN(arg));
  return Math.max(...params);
};


const computeMinForLocalBuildConfig = (sensorPropsType, chartData) => (Math.min(
  chartData.moisture_zone_lower || parseFloat(chartData.min), parseFloat(chartData.min),
));

const computeMaxForLocalBuildConfig = (sensorPropsType, chartData) => (Math.max(
  chartData.moisture_zone_upper || parseFloat(chartData.max), parseFloat(chartData.max),
));

// method to update soil sensor cutoff points in graph data
// using sensor status data
export const updateSoilCutoff = (responseData, sensorStatus) => {
  const data = { ...responseData };
  try {
    const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    Object.keys(data).forEach((dataKey) => {
      const depthIndex = dataKey.split('soil_moisture_')[1];
      const depthNum = depthIndex / depthSize;
      data[dataKey][0].fieldCapacity = sensorStatus[`fieldCapacity${depthNum}`];
      data[dataKey][0].refillPoint = sensorStatus[`refillPoint${depthNum}`];
      data[dataKey][0].wiltingPoint = sensorStatus[`wiltingPoint${depthNum}`];
      data[dataKey][0].isRootzone = sensorStatus[`isRootzone${depthNum}`];
      data[dataKey][0].depthIndex = depthNum;
    });
  } catch {
    return data;
  }
  return data;
};

// get soil moisture cut off points for give soil moisture depth
export const getSoilCutoffPoints = (data, sensorStatus) => {
  const dataKey = data.actualChartKey;
  try {
    // old logic, TODO remove after testing is complete
    // const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    // const depthIndex = depthNum / depthSize;
    const depthNum = dataKey.split('soil_moisture_')[1];
    const depthIndex = getDepthIndex(sensorStatus, depthNum);
    return {
      fieldCapacity: sensorStatus[`fieldCapacity${depthIndex}`],
      refillPoint: sensorStatus[`refillPoint${depthIndex}`],
      wiltingPoint: sensorStatus[`wiltingPoint${depthIndex}`],
      isRootzone: sensorStatus[`isRootzone${depthIndex}`],
      depth: depthNum,
      depthIndex,
    };
  } catch {
    return {};
  }
};

const getDepthIndex = (sensorStatus, depthNum) => {
  // get all depth keys in sensorStatus
  // loop for the keys, check the depth for the key
  // if match found return the key, extract the number
  const depthKeys = Object.keys(sensorStatus)
    .filter((key) => {
      const result = key.match(/depth[0-9]/);
      return result;
    })
    .filter((key) => sensorStatus[key]===Number(depthNum));
  const depthKey = depthKeys?.[0];
  // console.log('depthKeys', depthKey.match(/[0-9]/)[0]);
  const depthIndex = Number(depthKey?.match(/[0-9]/)[0]);
  return depthIndex;
};

export function chartRuleEngine(renderOption, config){
  if(renderOption===NO_RENDER) {
    return {
      isGraphDataApiCallNeeded: true,
      prepareTheConfig: true,
      callCustomConfig: true,
      renderThisChart: false
    };
  }
  if(renderOption===CALL_CUSTOM_CONFIG_AND_RENDER && config) {
    return {
      isGraphDataApiCallNeeded: false,
      prepareTheConfig: false,
      callCustomConfig: true,
      renderThisChart: true
    };
  }
  if (renderOption===ONLY_RENDER && config) {
    return {
      isGraphDataApiCallNeeded: false,
      prepareTheConfig: false,
      callCustomConfig: false,
      renderThisChart: true
    };
  }
  return {
    isGraphDataApiCallNeeded: true,
    prepareTheConfig: true,
    callCustomConfig: true,
    renderThisChart: true
  };
}

/**
 * This method uses soilDepthPalette to create
 * mapping based on the sensor status.
 * Example color mapping looks like:
 * {'soil_moisture_8': '#00429d'}
 *
 * It returns a function to get a color. This function accepts a string
 * and returns related color.
 */
export const getColors = (sensorStatus, varType='moisture') => {
  const { depthCount } = sensorStatus || {};
  const colorMapping = {};
  if (depthCount) {
    for (let depthNum = 1; depthNum <= depthCount; depthNum += 1) {
      const currentDepth = sensorStatus[`depth${depthNum}`];
      const key = `soil_${varType}_${currentDepth}`;
      const depthRounded = 2 * Math.ceil(currentDepth / 2);
      const paletteIndex = (depthRounded - 4) / 2;
      colorMapping[key] = soilDepthPalette[paletteIndex];
    }
  }
  // TODO remove after testing
  // console.log('colorMapping', colorMapping);
  return (key) => colorMapping[key] || colorOk;
};

export function getMultiplierAndData(chartData, getUserUnits, units) {
  let multiplier = 1;
  let variableData = chartData;
  // detect values as strings and convert
  if (variableData && typeof variableData[0][1] === 'string') {
    variableData = variableData.map((datum) => [datum[0], parseFloat(datum[1])]);
  }

  // fix units of %
  if (units === '%') {
    if (variableData?.length) {
      variableData = variableData.map((datum) => [datum[0], 100 * datum[1]]);
    }
    multiplier = 100;
  }
  // for psi units
  if (chartData?.units === 'psi' && variableData?.length) {
    variableData = variableData.map((datum) => {
      const convertedPressure = psiToUserUnit(datum[1], getUserUnits, 4);
      return [datum[0], convertedPressure];
    });
  }

  // for temperature units
  const regex = /&deg;{1}|°F{1}|°C{1}/;
  const found = units?.match(regex);
  const celsius = found?.length && (units?.indexOf('C') > 0);
  const fahrenheit = found?.length && (units?.indexOf('F') > 0);
  if ((celsius || fahrenheit) && variableData?.length) {
    variableData = celsius? variableData.map((datum) => {
      const convertedTemperature = convertToFahrenheit(datum[1], getUserUnits, 4);
      return [datum[0], convertedTemperature.value];
    }): variableData.map((datum) => {
      const convertedTemperature = convertToCelsius(datum[1], getUserUnits, 4);
      return [datum[0], convertedTemperature.value];
    });
    multiplier = 1;
  }
  return { multiplier, data: variableData };
}

export function getComputedYminmax(graphData, getUserUnits){
  const chartData = Object.values(graphData)[0][0];
  // TODO remove after testing
  // console.log('getComputedYminmax', chartData);
  if(chartData && chartData.data && chartData.data.length){
    const { multiplier } = getMultiplierAndData(chartData.data, getUserUnits, chartData.units);
    return {
      min: computeMinForLocalBuildConfig('', chartData) * multiplier,
      max: computeMaxForLocalBuildConfig('', chartData) * multiplier,
    };
  }
  return null;
}

export function buildGraphConfig(seriesData, getUserUnits) {
  // TODO remove after testing
  // console.log('calling buildGraphConfig');
  if (!seriesData) return {};

  const yAxisByUnit = {};
  const allSeries = Object.entries(seriesData)
    .map(([key, value]) => {
      if (!value.length) return undefined;
      const chartData = value[0];
      if (!chartData.data.length) return undefined;
      const { data: variableData } = chartData;
      const { multiplier, data } = getMultiplierAndData(
        variableData,
        getUserUnits,
        chartData.units
      );
      const decimals = 1;
      const fourDecimals = 4;

      const chartUnits = chartData.units || '';
      const axisKey = chartUnits + key;
      // const axisKey = mergeAxes ? chartUnits : chartUnits + key;
      const regex = /&deg;/g;
      const units = chartUnits.replace(regex, 'º');
      const newUnits = units === 'psi' ? getUserUnits(0, 'pounds_per_square_inch', 'pressure').label : units;
      const unitsStr = ` (${newUnits})`;
      const variableName = fieldToHeader(key);
      const legendTitle = variableName + unitsStr;
      let yAxis = yAxisByUnit[axisKey];
      if (!yAxis) {
        yAxis = {
          title: null,
          id: axisKey,
          opposite: false,
          min: computeMinForLocalBuildConfig('', chartData) * multiplier,
          max: computeMaxForLocalBuildConfig('', chartData) * multiplier,
          labels: {
            y: -1,
            x: 0,
            align: 'left',
          },
        };
        yAxisByUnit[axisKey] = yAxis;
      } else {
        yAxis.title = null;
        yAxis.min = Math.min(yAxis.min, chartData.min);
        yAxis.max = Math.max(yAxis.max, chartData.max);
      }

      yAxis.title = null;
      const seriesOptions = {
        multiplier,
        name: legendTitle,
        originalUnit: chartData.units,
        sensorName: chartData.sensor_name,
        isRootzone: chartData.isRootzone,
        actualChartKey: key,
        min: chartData.min,
        max: chartData.max,
        variable: chartData.variable,
        type: 'line',
        data: processDataGap(data),
        yAxis: axisKey,
        tooltip: {
          valueDecimals: newUnits === 'MPa' ? fourDecimals : decimals,
        },
      };
      return seriesOptions;
    });
  // TODO remove after testing
  // console.log('allSeries', allSeries);
  // console.log('yAxisByUnit', yAxisByUnit);
  return {
    yAxis: Object.values(yAxisByUnit),
    series: allSeries.filter(Boolean),
  };
}

export function getSoilMoistureDepthVariable(depthIndex, sensorStatus){
  return `soil_moisture_${sensorStatus[`depth${depthIndex}`]}`;
}

/**
 * Dynamically generates the variables required to get api response.
 *
 * Given if the sensorStatus has following data:
 * { depth1: 8, depth2: 16, ... depthCount: 6 }
 * and varType is 'moisture' as default
 *
 * It will return
 * ['soil_moisture_8', 'soil_moisture_16', ... 'soil_moisture_48']
 */
export const getDepthVariables = (sensorStatus, varType='moisture') => {
  const { depth1, depthCount } = sensorStatus || {};
  if (depth1 && depthCount) {
    const vars = [];
    for (let i = 1; i <= depthCount; i += 1) {
      const depth = sensorStatus[`depth${i}`];
      vars.push(`soil_${varType}_${depth}`);
    }
    return vars;
  }
  return [];
};

/**
 * This is a duplicate code same as in SensorDataChart in admin-web
 * and needs optimization after new graph design is used.
 *
 * Processes an array of data points to include gaps where data are missing.
 * If the time difference between consecutive timestamps is more than 1 hour,
 * inserts a data point with null value to indicate a gap.
 *
 * @param {Array} d - Array of data points where each point is [timestamp, value].
 * @returns {Array} Processed array of data points including data gaps [timestamp, value].
 */
export const processDataGap = (d) => {
  const newData = [];
  d.forEach((v, i) => {
    if (i !== 0) {
      const prevTimestamp = d[i - 1][0];
      const currentTimestamp = v[0];
      const timeDifference = currentTimestamp - prevTimestamp;
      // Insert null value if time difference is more than 1 hour (3600 * 1000 milliseconds)
      if (timeDifference > 3600 * 1000) {
        const averageTimestamp = (prevTimestamp + currentTimestamp) / 2;
        newData.push([averageTimestamp, null]);
      }
    }
    newData.push(v);
  });
  return newData;
};

export const cutoffLineLabelStyle = {
  color: '#666666',
  fontSize: '11px',
  opacity: 0.6
};

export const getDownloadUrl = async (
  startDate,
  endDate,
  sensor,
  capabilities,
  setLoading) => {
  console.log(startDate,
    endDate,
    sensor,
    capabilities);
  if (!capabilities || !capabilities.length
    || !sensor || (!sensor.id && !sensor.identifier)
    || !startDate || !endDate) {
    message.warn('Missing parameters');
    return null;
  }
  setLoading(true);
  const baseUrl = 'https://map.farmx.co/api/sensors/data/csv/';
  const sensorIdParameter = sensor.identifier ? `sensorIdentifier=${sensor.identifier}` : `sensorId=${sensor.id}`;
  const sensorsParameter = `sensorType=${sensor.type}&${sensorIdParameter}`;
  const variablesArr = capabilities.join(',');
  const variablesParameter = `variables=${variablesArr}`;
  const datesParameters = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  const url = `${baseUrl}?${sensorsParameter}&${variablesParameter}&${datesParameters}&cached=true/`;

  await axios.get(
    url,
  ).then((response) => {
    const hrefUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = hrefUrl;
    link.setAttribute('download', 'FarmXDataExport.csv');
    link.click();
  }).catch(() => {
    message.error('Failed to download');
  });
  setLoading(false);
};
