import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { hooks, selectors } from 'farmx-redux-core';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
} = hooks;

const {
  selectSensorLoadingStatus,
  selectValveSummary,
} = selectors;

export function ValveSummary(props) {
  const { t } = useTranslation();
  const { list } = props;
  const summary = useSelector((state) => selectValveSummary(state, list)) || {};
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  useLoadStatus(list);

  const {
    openValveCount,
    openValvesName,
    closedValvesName,
    allValvesName,
    totalOnline,
  } = summary;

  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);

  const showNoDataInfo = (!loading && !list.length);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="pipe-valve"
        title={t('Valves')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="pipe-valve"
          title={t('Valves')}
        />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              ellipsis
              value={openValveCount}
              unit={t('Open_status')}
              info={openValvesName?.join()}
            />
            <ValueUnitInfo
              ellipsis
              value={list.length - openValveCount}
              unit={t('Closed')}
              info={closedValvesName?.join()}
              type="danger"
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(allValvesName?.join())}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

ValveSummary.defaultProps = {
  list: [],
};

ValveSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
