import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';

interface TeamDetailAdminFormProps {
  team: { name: string, id: number };
  onTeamUpdate: (updateTeamFormData: { name: string, id: number }) => void;
  onTeamDelete: (team: { id: number }) => void;
  disabled: boolean;
}

/**
 * TeamDetailAdminForm is a functional presentational component used to display a form for updating and deleting a team.
 * @param {TeamDetailAdminFormProps.team} team - The team object to display in the form.
 * @param {TeamDetailAdminFormProps.onTeamUpdate} onTeamUpdate - The method to handle updating the team.
 * @param {TeamDetailAdminFormProps.onTeamDelete} onTeamDelete - The method to handle deleting the team.
 * @param {TeamDetailAdminFormProps.disabled} disabled - The disabled status of the form.
 * @returns {JSX.Element}
 */
export const TeamDetailAdminForm: React.FC<TeamDetailAdminFormProps> = ({
  team,
  onTeamUpdate,
  onTeamDelete,
  disabled
}) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    onTeamUpdate({ name: form.getFieldValue('name'), id: team.id });
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ name: team?.name }}
      >
        <Row gutter={16} align="middle">
          <Col xs={24} sm={12} md={16} lg={16}>
            <Form.Item
              label="Team Name"
              name="name"
              rules={[{ required: true, message: 'Please input the team name!' }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Row gutter={8}>
              <Col span={12}>
                <Button disabled={disabled} type="ghost" htmlType="submit" block>
                  Update
                </Button>
              </Col>
              <Col span={12}>
                <Button 
                  disabled={disabled}
                  onClick={() => onTeamDelete({ id: team.id })}
                  type="dashed"
                  block
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
