/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { selectors, hooks, actions } from 'farmx-redux-core';
import { InfoCircleFilled } from '@ant-design/icons';
import { farmApi } from 'farmx-api';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import {
  clearAllLayersInSource, renderZoomToFixView, getBlockCenterCoordinates,
  getSourceAndFeatures, setUpdatedDataToSource,
} from './mapHelper';
import {
  compareByName, getPercentForFraction, parseDuration,
  convertToGallons
} from './common';
import { roundToDecimalPlaces } from './anomalyHelper';
import { getBlockArea } from './block';
import { installState } from '../constants';
import { TOOLTIP_TITLES } from './tooltipHelper';

const {
  selectUserAreaFormat,
  selectAllSensorsForBlockIdsByType,
  selectSensorStatus,
  selectNewBlockById,
  selectUserLengthFormat,
} = selectors;

const { loadSensorStatus } = actions;

// The default options for the plants health classification
const options = ['normal', 'undeveloped', 'dead', 'missing'].sort();

export const useNavigateHome = () => {
  const { useRanchBlockSelection } = hooks;
  const { selectedObjFromState } = useRanchBlockSelection();
  const { getState } = useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newRanchId, setNewRanchId] = useState(0);
  const [newBlockId, setNewBlockId] = useState(0);

  useEffect(() => {
    // if block is changed navigate to home page
    if (selectedObjFromState?.type === 'block' && selectedObjFromState?.value) {
      const block = selectNewBlockById(getState(), Number(selectedObjFromState.value));
      if (block.id && block.ranch && (block.id !== newBlockId || block.ranch !== newRanchId)) {
        setNewBlockId(Number(block.id));
        setNewRanchId(Number(block.ranch));
        if(newBlockId) history.push('/recommendation');
      }
    }
    // if ranch is changed or selected navigate to home page
    if (selectedObjFromState?.type === 'ranch' && selectedObjFromState?.value) {
      // Avoid navigating to home page issue, when user clicks on anomaly at ranch level
      if ((Number(selectedObjFromState?.value) !== newRanchId) || newBlockId) {
        setNewRanchId(Number(selectedObjFromState?.value));
        if(newRanchId) history.push('/recommendation');
      }
    }
    if (selectedObjFromState?.type === 'entity' && selectedObjFromState?.value) {
      if (Number(selectedObjFromState?.value) !== newRanchId) {
        setNewRanchId(Number(selectedObjFromState?.value));
        if(newRanchId) history.push('/recommendation');
      }
    }
  }, [history, dispatch, selectedObjFromState, newRanchId, newBlockId, getState]);
  return null;
};

export const useClearClusterPolygonLayers = (mapVar, showPlants, selectedIds) => {
  // To clear the polygon layers when switching between plants and cluster mode
  useEffect(() => {
    if (!mapVar || !mapVar?.style?._loaded) return;
    if (showPlants) {
      if (selectedIds?.length) {
        const arr = [...Array(selectedIds?.length + 1)].fill(undefined);
        arr.forEach((_, i) => {
          const prevPolygonSourceId = `cluster-source-${i}`;
          const prevSinglePolygonSourceId = `cluster-zoom-source-${i}`;
          if (mapVar?.getSource(prevPolygonSourceId)) {
            clearAllLayersInSource(mapVar, prevPolygonSourceId);
          }
          if (mapVar?.getSource(prevSinglePolygonSourceId)) {
            clearAllLayersInSource(mapVar, prevSinglePolygonSourceId);
          }
        });
      }
    }
  }, [selectedIds, mapVar, showPlants]);
};

const useRenderPolygonLayer = (mapVar, sourceData, selectedIds, onPolygonClick,
  sourceIds, layerIds, layerFillIds, showPlants) => {
  useEffect(() => {
    if (!mapVar || showPlants) return;

    function addLayer() {
      sourceIds.forEach((d, i) => {
        const sourceId = d;
        const layerId = layerIds[i];
        const layerIdFill = layerFillIds[i];

        if (mapVar.getLayer(layerIdFill)) mapVar.removeLayer(layerIdFill);
        if (mapVar.getLayer(layerId)) mapVar.removeLayer(layerId);
        if (mapVar.getSource(sourceId)) mapVar.removeSource(sourceId);

        const sourceGeoJson = { type: 'FeatureCollection', features: [sourceData.features[i]] };
        const data = sourceGeoJson?.features?.[0];
        const featureId = data?.properties?.id;

        if (!mapVar.getSource(sourceId) && mapVar?.style?._loaded) {
          mapVar.addSource(sourceId, {
            type: 'geojson',
            data: sourceGeoJson,
          });
        }

        mapVar.addLayer({
          id: layerId,
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': 'blue',
            'line-width': 2,
          },
        });

        mapVar.addLayer({
          id: layerIdFill,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': 'blue',
            'fill-opacity': selectedIds?.includes(featureId) ? 0.2 : 0,
          },
        });
      });
    }
    mapVar.once('styledata', addLayer());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, sourceIds]);

  const onClick = useCallback((d) => {
    const layerIdFill = d?.features?.[0]?.layer?.id;
    const isBlock = layerIdFill?.indexOf('block') >= 0;
    if (!isBlock) {
      d.originalEvent.stopPropagation();
      const prevVal = mapVar.getPaintProperty(layerIdFill, 'fill-opacity');
      const feature = d?.features?.[0];
      const polygonFeatureId = feature?.properties?.id;
      if (prevVal === 0) {
        mapVar.setPaintProperty(layerIdFill, 'fill-opacity', 0.2);
      } else {
        mapVar.setPaintProperty(layerIdFill, 'fill-opacity', 0);
      }
      if (onPolygonClick) onPolygonClick({ featureId: polygonFeatureId, feature });
    }
  }, [mapVar, onPolygonClick]);

  const onMouseEnter = useCallback(() => {
    mapVar.getCanvas().style.cursor = 'pointer';
  }, [mapVar]);

  const onMouseLeave = useCallback(() => {
    mapVar.getCanvas().style.cursor = '';
  }, [mapVar]);

  // Attach events for the layers
  useEffect(() => {
    if (!mapVar) return;
    layerFillIds.forEach((layerIdFill) => {
      if (mapVar?.getLayer(layerIdFill)) {
        mapVar.on('mouseenter', layerIdFill, onMouseEnter);
        mapVar.on('mouseleave', layerIdFill, onMouseLeave);
        mapVar.on('click', layerIdFill, onClick);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerFillIds, mapVar]);

  // Remove attached events
  useEffect(() => () => {
    if (!mapVar) return;
    layerFillIds.forEach((layerIdFill) => {
      if (mapVar?.getLayer(layerIdFill)) {
        mapVar.off('mouseenter', layerIdFill, onMouseEnter);
        mapVar.off('mouseleave', layerIdFill, onMouseLeave);
        mapVar.off('click', layerIdFill, onClick);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerFillIds, mapVar]);
};

// To render polygon layer for the plants cluster GeoJSON
export const useRenderClusterForGeoJson = (mapVar, clusterGeoJSON, toggleZoom, selectedIds,
  polygonIds, items, count, prevCount, onPolygonClick, showPlants) => {
  const [allFeatures, setAllFeatures] = useState([]);
  const [sourceIds, setSourceIds] = useState([]);
  const [layerIds, setLayerIds] = useState([]);
  const [layerFillIds, setLayerFillIds] = useState([]);

  useRenderPolygonLayer(mapVar, {
    type: 'FeatureCollection',
    features: allFeatures,
  }, polygonIds, onPolygonClick, sourceIds, layerIds, layerFillIds, showPlants);

  // To clear all the previously rendered layer
  if (clusterGeoJSON?.features?.length && toggleZoom) {
    clusterGeoJSON.features.forEach((_, i) => {
      const prevPolygonSourceId = `cluster-source-${i}`;
      if (mapVar?.getSource(prevPolygonSourceId)) {
        clearAllLayersInSource(mapVar, prevPolygonSourceId);
      }
    });
  }

  if (!toggleZoom && clusterGeoJSON?.features?.length) {
    [...Array(clusterGeoJSON.features?.length)].fill(undefined).forEach((_, i) => {
      const prevPolygonSourceId = `cluster-zoom-source-${i}`;
      if (mapVar?.getSource(prevPolygonSourceId)) {
        clearAllLayersInSource(mapVar, prevPolygonSourceId);
      }
    });
  }

  useEffect(() => {
    if (!mapVar || !mapVar?.style?._loaded || showPlants) return;
    if (clusterGeoJSON?.features?.length) {
      if (isEqual(polygonIds, selectedIds)) {
        if (toggleZoom && items) {
          // Source and layer ids to draw layer
          const polygonSourceId = `cluster-zoom-source-${count}`;
          const polygonLayerId = `cluster-zoom-layer-${count}`;
          const polygonLayerFillId = `cluster-zoom-layer-fill-${count}`;
          let polygonSourceIdPrev = `cluster-zoom-source-${prevCount.current}`;
          if (clusterGeoJSON?.features?.length === 1) polygonSourceIdPrev = 'cluster-zoom-source-0';

          if (prevCount.current !== null) {
            clearAllLayersInSource(mapVar, polygonSourceIdPrev);
          }

          // Render the zoomed cluster to window
          renderZoomToFixView(mapVar, items, toggleZoom);

          setAllFeatures([items]);
          setSourceIds([polygonSourceId]);
          setLayerIds([polygonLayerId]);
          setLayerFillIds([polygonLayerFillId]);
        } else if (!toggleZoom) {
          renderZoomToFixView(mapVar, clusterGeoJSON, toggleZoom);
          if (clusterGeoJSON?.features) {
            const sourceIdsLocal = [];
            const layerIdsLocal = [];
            const layerFillIdsLocal = [];
            const sourceData = [];
            clusterGeoJSON.features.forEach((data, i) => {
              const polygonSourceId = `cluster-source-${i}`;
              const polygonLayerId = `cluster-layer-${i}`;
              const polygonLayerFillId = `cluster-layer-fill-${i}`;
              sourceIdsLocal.push(polygonSourceId);
              layerIdsLocal.push(polygonLayerId);
              layerFillIdsLocal.push(polygonLayerFillId);
              sourceData.push(data);
            });
            if (!isEqual(layerFillIds, layerFillIdsLocal)) setLayerFillIds(layerFillIdsLocal);
            if (!isEqual(layerIds, layerIdsLocal)) setLayerIds(layerIdsLocal);
            if (!isEqual(sourceIds, sourceIdsLocal)) setSourceIds(sourceIdsLocal);
            if (!isEqual(sourceData, allFeatures)) setAllFeatures(sourceData);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleZoom, count, polygonIds, clusterGeoJSON]);
};

// To prepare the cluster data based on its id to render the single cluster polygon
export const usePrepareClusterDataToRender = (clusterGeoJSON, polygons, setPolygons,
  setPolygonObj, setItems) => {
  useEffect(() => {
    if (clusterGeoJSON?.features?.length) {
      const featureObjectsById = clusterGeoJSON?.features?.reduce((a, d) => {
        const tempObj = { ...a };
        tempObj[d?.properties?.id] = d;
        return tempObj;
      }, {}) || {};
      if (!isEqual(featureObjectsById, polygons)) {
        setPolygons(featureObjectsById);
        const changedPolygonObjects = Object.keys(featureObjectsById).reduce((acc, key, i) => {
          const obj = { ...acc };
          obj[i] = featureObjectsById[key];
          return obj;
        }, {});

        setPolygonObj(changedPolygonObjects);
        setItems(changedPolygonObjects[0]);
      }
    }
  }, [clusterGeoJSON, polygons, setItems, setPolygonObj, setPolygons]);
};

export const useZoomToFitBlock = (isAnomaly, mapVar, blockObj, toggleZoom) => {
  useEffect(() => {
    if (!isAnomaly && mapVar?.style?._loaded) {
      const { zoom } = getBlockCenterCoordinates({
        type: 'Feature',
        geometry: { ...blockObj?.bounds },
      });
      let zoomToSet = mapVar?.getZoom();
      if (toggleZoom) zoomToSet = zoom + 0.5;
      else zoomToSet = zoom - 0.5;
      mapVar.setZoom(zoomToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleZoom, isAnomaly]);
};

export const useRenderPlantsWithSelectAndDeSelectFeature = (mapVar, checkedList, checkedFeatures,
  featureIds, setFeatureIds, onChange, isAnomaly) => {
  const onClick = useCallback((d) => {
    const isBlock = d?.features?.[0]?.layer?.id?.indexOf('block') >= 0;
    // To get the already rendered plant circles from source
    const { features, source } = getSourceAndFeatures(mapVar, 'point');

    if (!isBlock) {
      const feature = d?.features?.[0];
      const { id, healthClassification } = feature?.properties;

      // To maintain the checkbox selected state like "all" OR "intermediate"
      if (!checkedFeatures[id]) {
        if (onChange) onChange({ ...checkedFeatures, [id]: healthClassification });
      } else if (onChange) {
        const obj = { ...checkedFeatures };
        delete obj[id];
        onChange(obj);
      }

      let newFeatures = [];
      if (!featureIds.includes(id)) {
        // set the selected feature ids to state
        setFeatureIds([...featureIds, id]);

        // Update layer source data to highlight the selected plants
        newFeatures = features.map((f) => {
          const { id: featureId } = f.properties;
          const obj = { ...f };
          let isSelected = false;
          if (featureIds.includes(featureId) || featureId === id) isSelected = true;
          obj.properties.selected = isSelected ? 'true' : 'false';
          if (featureId === id) obj.properties.clickedAndSelected = 'true';
          return obj;
        });
      } else {
        // Remove the already selected plant by clicking on it again and set the state
        const filteredIds = featureIds.filter((featureId) => featureId !== id);
        setFeatureIds(filteredIds);

        // Update layer source data to highlight the selected plants
        newFeatures = features.map((f) => {
          const { id: featureId } = f.properties;
          const obj = { ...f };
          let isSelected = false;
          if (featureIds.includes(featureId) && featureId !== id) isSelected = true;
          obj.properties.selected = isSelected ? 'true' : 'false';
          if (featureId === id) obj.properties.clickedAndSelected = 'false';
          return obj;
        });
      }

      // Set the latest data to source to render the plant circles
      setUpdatedDataToSource(source, newFeatures);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, featureIds]);

  useEffect(() => {
    if (isAnomaly || !mapVar || !mapVar?.style?._loaded) return;
    mapVar.on('click', 'points-fill', onClick);
  }, [isAnomaly, mapVar, onClick]);

  useEffect(() => () => {
    if (isAnomaly || !mapVar || !mapVar?.style?._loaded) return;
    mapVar.off('click', 'points-fill', onClick);
  }, [isAnomaly, mapVar, onClick]);
};

export const useHandlePlantsSelection = (isAnomaly, mapVar, showPlants,
  checkedList, setFeatureIds) => {
  useEffect(() => {
    if (isAnomaly || !mapVar || !showPlants || !mapVar?.style?._loaded) return;
    const { features, source } = getSourceAndFeatures(mapVar, 'point');
    const list = checkedList.map((d) => d?.key);
    if (!features?.length) return;

    const newFeatures = features?.map((f) => {
      const { healthClassification, selected, clickedAndSelected } = f.properties;
      const obj = { ...f };
      let isSelected = false;

      // Clear all the selection when all checked/unchecked
      if (!list.length || options.length === list.length) {
        delete obj.properties.clickedAndSelected;
      }
      // Ex: ['undeveloped', 'dead', 'missing'] but not selected already
      if (list.includes(healthClassification) && !selected) isSelected = true;

      // Ex: ['undeveloped', 'dead', 'missing'] and selected= true
      else if (list.includes(healthClassification) && selected === 'true') isSelected = true;

      // Ex: ['undeveloped', 'dead', 'missing'] and selected= false
      // and clickedAndSelected = undefined OR clickedAndSelected= false
      else if (list.includes(healthClassification) && selected !== 'true'
        && clickedAndSelected !== 'true') isSelected = true;

      // Ex: ['undeveloped', 'missing'] and selected= true [for 'dead']
      if (!list.includes(healthClassification) && selected === 'true') isSelected = false;

      // Ex: clickedAndSelected = true [by individual selection]
      if (clickedAndSelected === 'true') isSelected = true;

      // Ex: clickedAndSelected = false [by individual selection]
      if (clickedAndSelected === 'false') isSelected = false;

      // Update all the selection to false for the selected items when uncheck all checkbox list
      if (!list.length) isSelected = false;

      obj.properties.selected = isSelected ? 'true' : 'false';
      return obj;
    });

    const newIds = newFeatures?.filter((f) => f.properties.selected === 'true')
      .map((f) => f.properties.id);

    setFeatureIds(newIds);
    setUpdatedDataToSource(source, newFeatures);
  }, [checkedList, isAnomaly, mapVar, setFeatureIds, showPlants]);
};

// This hook will be refactored in future
export const usePrepareCheckedList = (plantsData, checkedList, setCheckedList) => {
  useEffect(() => {
    if (!plantsData?.features?.length) return;
    const selectedList = checkedList.map((d) => d.key);
    const checkedObj = checkedList.reduce((acc, obj) => {
      const temp = acc;
      temp[obj.key] = obj;
      return temp;
    }, {});

    const classificationCounts = plantsData.features.reduce((counts, item) => {
      const { healthClassification } = item.properties;
      const obj = counts;
      const isSelected = selectedList.includes(healthClassification);
      // If the classification is not in the counts object, initialize it to 1
      if (!counts[healthClassification]) {
        obj[healthClassification] = {
          count: 1,
          selected: isSelected
            ? checkedObj[healthClassification]?.selected || 1 : 0,
        };
      } else {
        // If the classification is already in the counts object, increment its count
        obj[healthClassification] = {
          count: obj[healthClassification].count + 1,
          selected: checkedObj[healthClassification]?.selected || 0,
        };
      }

      return counts;
    }, {});
    const updatedCheckedList = checkedList.map((d) => {
      const obj = { ...d, ...classificationCounts[d?.key] };
      return obj;
    });

    if (!isEqual(checkedList, updatedCheckedList)) {
      setCheckedList(updatedCheckedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantsData]);
};

// Hook to prepare the ETc irrigation equation data
export const usePrepareETcEquationData = (deficit, etcForecast, irrigationDuration,
  scheduledDuration, wiltingPointDuration, fieldCapacityDuration, refillPointDuration,
  precipForecastSum, units, toUnitByLength, t) => {
  const getUserUnits = hooks.useUnits();

  function parseWater(water, fromUnit) {
    if (water === undefined || water === null) {
      return t('?');
    }
    const { value: depthValue } = getUserUnits(water,
      fromUnit, undefined, { decimalPlaces: 2 }, toUnitByLength);
    if (Math.abs(depthValue) < 10) {
      return Math.round(depthValue * 100) / 100;
    }
    return Math.round(depthValue);
  }
  const { label: depthLabel } = getUserUnits(0, units.deficit,
    undefined, { decimalPlaces: 2 }, toUnitByLength);
  const waterUnits = t(depthLabel);

  const durationString = parseDuration(irrigationDuration, t);
  const scheduledDurationString = parseDuration(scheduledDuration, t);
  const etcForecastString = parseWater(etcForecast, units.etcForecast);
  const deficitString = parseWater(deficit, units.deficit);
  const fieldCapacityDurationString = parseDuration(fieldCapacityDuration, t);
  const wiltingPointDurationString = parseDuration(wiltingPointDuration, t);
  const refillPointDurationString = parseDuration(refillPointDuration, t);
  const precipForecastString = parseWater(precipForecastSum, units.deficit);

  return {
    deficitString,
    etcForecastString,
    durationString,
    waterUnits,
    scheduledDurationString,
    fieldCapacityDurationString,
    wiltingPointDurationString,
    refillPointDurationString,
    precipForecastString,
    depthLabel,
  };
};

export const useGetRanchSummaryData = (ranchId) => {
  const [ranchSummary, setRanchSummary] = useState(null);
  useEffect(() => {
    async function getRanchSummary(idForRanchSummary){
      const result = await farmApi.getRanchSummary(idForRanchSummary);
      return result.data;
    }
    getRanchSummary(ranchId)
      .then((data) => {
        if(data){
          setRanchSummary(data);
        }
      });
  }, [ranchId]);
  return ranchSummary;
};

export function getAverageAndSum(values) {
  // Check if the array is empty to avoid division by zero
  if (values?.length === 0) {
    return { average: 0, sum: 0 }; // or return null, or handle as needed
  }

  // Calculate the sum of the array using reduce
  const sum = values?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  // Calculate the average by dividing the sum by the number of elements
  const average = sum / values?.length;

  return { average, sum };
}

export const useGetForecastDetailsFormData = (data) => {
  if (!data) return {};
  const {
    et0ForecastDaily,
    etcForecastDaily,
    kcDaily,
  } = data;
  const sumEt0 = getAverageAndSum(et0ForecastDaily)?.sum;
  const sumEtc = getAverageAndSum(etcForecastDaily)?.sum;
  const avgKc = getAverageAndSum(kcDaily)?.average;

  return { et0: sumEt0, etc: sumEtc, kc: avgKc };
};

function renderInfoButton(setActiveTabKey, key) {
  return (
    <InfoCircleFilled
      style={{ fontSize: '20px', color: '#3c61b4' }}
      onClick={() => {
        setActiveTabKey(key);
      }}
    />
  );
}

export const usePrepareIrrigationEquationDetailsTableData = (recommendation,
  units, ranchId, setActiveTabKey, t) => {
  const getUserUnits = hooks.useUnits();
  const dispatch = useDispatch();
  const hoursLabel = 'hours';
  const metersLabel = 'm';
  const userUnitLength = useSelector((state) => selectUserLengthFormat(state));

  const {
    deficit, etcForecast, irrigationDuration, block,
    totalWater, scheduledDuration, wiltingPointDuration,
    fieldCapacityDuration, refillPointDuration,
    evapotranspiration, data,
  } = recommendation || {};

  const { et0, etc, kc } = useGetForecastDetailsFormData(data);

  // This will be removed in the future
  // const [weatherSensorForBlock] = useSelector((state) => selectSensorsForBlockId(state,
  //   recommendation?.block, 'weather_station', 'installed'));
  // const { weatherData } = useSelector((store) => (
  //   selectors.selectWeatherDataAndList(store, [recommendation.block])
  // )) || {};

  // const {
  //   type: weatherSensorType,
  //   identifier: weatherSensorIdentifier
  // } = weatherSensorForBlock || {};
  // const {weatherForecast} = weatherData || [];
  // const weatherForecastData = weatherForecast?.map((item) => {
  //   const { et0 } = item;
  //   // calculate etc value by multiplying et0 and kc
  //   return { etc: Number(et0) * (Number(evapotranspiration?.kc) || 0), et0};
  // })?.slice(0, 5);

  // const weatherForecastDataSum = weatherForecastData?.reduce((acc, item) => {
  //   const { et0, etc } = item;
  //   acc.totalEtc += (Number(etc) || 0);
  //   acc.totalEt0 += (Number(et0) || 0);
  //   return acc;
  // }, { totalEtc: 0, totalEt0: 0});

  const areaFormat = useSelector(selectUserAreaFormat);
  const blockObj = useSelector((state) => selectNewBlockById(state, block));

  const {
    emitter_discharge_gph: emitterApplicationRateGph,
    row_spacing: rowSpacing,
    emitter_spacing_ft2: emitterSpacingInFt2,
    irrigation_type: emitterType,
    emitter_count: emitterCount,
    rootzone_area: rootzoneArea,
    application_rate: systemApplicationRate,
    tree_spacing_ft2: plantArea,
    irrigation_line_multiplier: irrigationLines,
  } = blockObj || {};

  const sensors = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds: [block],
    installState: installState.INSTALLED,
  }));

  const [sensor] = [
    ...(sensors.aquacheck_soil || []),
    ...(sensors.pixl_soil || []),
  ]?.sort(compareByName);

  const { type, identifier } = sensor || {};
  const sensorStatus = useSelector((state) => selectSensorStatus(state, type, identifier)) || {};
  const { vwcRootzone, depthRootzone } = sensorStatus;
  const rootzoneValue = getPercentForFraction(vwcRootzone);

  // The result is the area of the geometry in square meters (m²)
  const blockAreaInM2 = getBlockArea(blockObj);
  const { value: plantAreaInM2 } = getUserUnits(plantArea, 'square_feet', undefined, { decimalPlaces: 2 }, 'square_meters');

  // Convert the values by user settings
  const { value: blockArea, label: areaLabel } = getUserUnits(blockAreaInM2, 'square_meters',
    undefined, { decimalPlaces: 2 }, areaFormat);
  const { value: depthRootzoneInM3 } = getUserUnits(depthRootzone, 'inches',
    undefined, { decimalPlaces: 2 }, 'cubic_meters');

  // vwcRootzone unit is cubic meters(m3)
  const rootzoneWaterInM3 = depthRootzoneInM3 * vwcRootzone;

  const toUnitByLength = userUnitLength === 'feet' ? 'inches' : 'millimeters';

  const {
    value: convertedEt0,
    label: et0Label
  } = getUserUnits(et0, 'millimeters',
    undefined, { decimalPlaces: 2 }, toUnitByLength);

  const {
    value: convertedEtc,
    label: etcLabel
  } = getUserUnits(etc, 'millimeters',
    undefined, { decimalPlaces: 2 }, toUnitByLength);

  const {
    value: convertedRootzoneDepth,
    label: rootzoneLabel
  } = getUserUnits(depthRootzone, 'inches',
    undefined, { decimalPlaces: 2 }, toUnitByLength);

  const deficitInMM = Number(deficit) || 0;
  const rootzoneWater = convertedRootzoneDepth * vwcRootzone;

  // Get the precipitation forecast sum
  const precipForecastSum = getAverageAndSum(data?.precipForecastDaily || [])?.sum;

  const {
    deficitString, etcForecastString, durationString, waterUnits, scheduledDurationString,
    wiltingPointDurationString, fieldCapacityDurationString, refillPointDurationString,
    depthLabel, precipForecastString,
  } = usePrepareETcEquationData(Number(deficit) || 0, Number(etcForecast) || 0,
    irrigationDuration, scheduledDuration, wiltingPointDuration, fieldCapacityDuration,
    refillPointDuration, precipForecastSum, units, toUnitByLength, t);

  const totalAvailableWater = rootzoneWater + deficitString;

  // To convert the volumeDeficit, deficitString(inches) + etcForecastString(inches)
  // and convert to cubicMeters. Then multiply totalDeficit(m3) * blockArea(m2) = volumeDefict(m3)
  const totalDeficit = deficitInMM + (Number(etcForecast) || 0);
  const totalDeficitMeters = totalDeficit / 1000;

  let rootzoneAreaRatio = 1;
  if (rootzoneArea) {
    rootzoneAreaRatio = rootzoneArea / plantAreaInM2;
  }
  const volumeDeficitInM3 = totalDeficitMeters * blockAreaInM2 * rootzoneAreaRatio;
  // Convert valumeDefitInM3 to user voulme settings "gallons"
  const { value: volumeDeficit, label: volumeDeficitLabel } = getUserUnits(volumeDeficitInM3, 'cubic_meters',
    'volume', { decimalPlaces: 2 });

  // To calculate emitteres count from "emitter_spacing_ft2" with blockArea
  // As we know blockArea unit is m2, convert the "emitter_spacing_ft2" into m2
  const { value: emitterSpacingInM2 } = getUserUnits(Number(emitterSpacingInFt2) || 0, 'square_feet',
    undefined, { decimalPlaces: 2 }, 'square_meters');

  // To find the emitters count: blockArea(m2) / emitterSpacing(m2)
  const emitterCountFromEmitterSpacing = emitterSpacingInM2
    ? Math.round(blockAreaInM2 / emitterSpacingInM2) : 0;

  const systemRateGph = roundToDecimalPlaces(Number(systemApplicationRate) || (emitterApplicationRateGph || 0)
    * (Number(emitterCount) || Number(emitterCountFromEmitterSpacing) || 0));
  const { value: systemRate, label: systemRateLabel } = getUserUnits(systemRateGph, 'gallons_per_hour', 'flow_rate', { decimalPlaces: 2 });
  const { value: emitterApplicationRate, label: emitterApplicationRateLabel } = getUserUnits(emitterApplicationRateGph, 'gallons_per_hour', 'flow_rate', { decimalPlaces: 2 });

  let emitterSpacing = 0;
  if (Number(emitterSpacingInM2) && Number(rowSpacing)) {
    emitterSpacing = ((Number(emitterSpacingInM2) || 0) / (Number(rowSpacing) || 0)) * Number(irrigationLines);
  }

  // To calculate irrigation area
  const irrigationArea = (blockArea * rootzoneArea) / plantArea;

  const { value: precipValueInGallons } = convertToGallons((precipForecastSum / 1000), 'meters',
    blockAreaInM2, getUserUnits);

  // To get hours: gallons / application rate
  const precipValueInHours = precipValueInGallons / Number(systemApplicationRate);

  // To load soil sensor status
  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorStatus({
        type,
        identifier,
      }));
    }
  }, [dispatch, identifier, type]);

  // This will be removed in the future
  // // To load weather sensor status
  // useEffect(() => {
  //   if (weatherSensorType && weatherSensorIdentifier) {
  //     dispatch(loadSensorStatus({
  //       type: weatherSensorType,
  //       identifier: weatherSensorIdentifier,
  //     }));
  //   }
  // }, [dispatch, weatherSensorType, weatherSensorIdentifier]);
  const {
    summary: summaryTooltip,
    availableWater: availableWaterTooltip,
    etc: etcTooltip,
    irrigationSystem: irrigationSystemTooltip,
    runtimeDetails: runtimeDetailsTooltip
  } = TOOLTIP_TITLES || {};

  const summary =[
    {
      label: 'Current deficit',
      value: `${roundToDecimalPlaces(deficitString)} ${waterUnits}`,
      info: renderInfoButton(setActiveTabKey, '2'),
      title: summaryTooltip?.currentDeficit?.title
    },
    {
      label: 'ETc (5 day total)',
      value: `${roundToDecimalPlaces(etcForecastString)} ${waterUnits}`,
      info: renderInfoButton(setActiveTabKey, '3'),
      title: summaryTooltip?.etc?.title
    },
    {
      label: 'Scheduled',
      value: `${scheduledDurationString} ${hoursLabel}`,
      info: renderInfoButton(setActiveTabKey, '5'),
      title: summaryTooltip?.scheduled?.title
    },
    {
      label: 'Total deficit',
      value: `${roundToDecimalPlaces(deficitString + etcForecastString)} ${waterUnits}`,
      title: summaryTooltip?.totalDeficit?.title
    },
    {
      label: 'Volume deficit',
      value: `${roundToDecimalPlaces(volumeDeficit)} ${volumeDeficitLabel}`,
      title: summaryTooltip?.volumeDeficit?.title
    },
    {
      label: 'System Application Rate',
      value: `${systemRate} ${systemRateLabel}`,
      info: renderInfoButton(setActiveTabKey, '4'),
      title: summaryTooltip?.applicationRate?.title
    },
    {
      label: 'Precipitation Forecast ',
      value: `${roundToDecimalPlaces(precipForecastString)} ${waterUnits}`,
      title: summaryTooltip?.precipForecast?.title
    },
    {
      label: 'Total Runtime',
      value: `${durationString} ${hoursLabel}`,
      info: renderInfoButton(setActiveTabKey, '5'),
      highlight: true,
      title: summaryTooltip?.toatlRuntime?.title
    }];

  // These emitter spacing, system rate calculations will be replaced with API data soon
  const irrigationSystem = [
    {
      label: 'Block Area',
      value: `${roundToDecimalPlaces(blockArea)} ${areaLabel}`,
      title: irrigationSystemTooltip?.blockArea?.title
    },
    {
      label: 'Plant area',
      value: `${plantAreaInM2} sq. m`,
      title: irrigationSystemTooltip?.plantArea?.title
    },
    {
      label: 'Rootzone area',
      value: rootzoneArea
        ? `${Number(rootzoneArea)} sq. m` : `${plantAreaInM2} sq. m`,
    },
    {
      label: 'Irrigation Area',
      value: `${roundToDecimalPlaces(irrigationArea || 0)} ${areaLabel}`,
      title: irrigationSystemTooltip?.irrigationArea?.title
    },
    {
      label: 'Emitter Application Rate',
      value: `${emitterApplicationRate || 0} ${emitterApplicationRateLabel}`,
    },
    { // The value in the database is ft2. This is emitter spacing within row * row spacing
      // So divide the emitterSpacingInFt2 by rowSpacing.
      // Ex: emitterSpacingInFt2 = 80, rowSpacing: 8, then Emitter spacing will be 10
      label: 'Emitter spacing (within row)',
      value: `${roundToDecimalPlaces(emitterSpacing)} ${metersLabel}`,
    },
    {label: 'Row spacing', value: `${Number(rowSpacing) || 0} ${metersLabel}` },
    {label: 'Emitter type', value: emitterType || 'Unknown' },
    {label: 'Irrigation Lines', value: irrigationLines },
    // Emitters count is same as (blockArea(m2) / emitterSpacing(m2))
    {label: 'Emitters', value: emitterCount || emitterCountFromEmitterSpacing || 0 },
    {
      label: 'System Rate',
      value: `${systemRate} ${systemRateLabel}`,
      highlight: true,
      title: irrigationSystemTooltip?.systemRate?.title
    },
  ];

  const predictedDeficit = [
    {
      label: 'Et0 (5 day total)',
      value: `${roundToDecimalPlaces(convertedEt0) || 0} ${et0Label}`,
      title: etcTooltip?.et0Total?.title
    },
    {label: 'Kc', value: `${roundToDecimalPlaces(kc) || 0}`, title: etcTooltip?.kc?.title},
    {
      label: 'ETc (5 day total)',
      value: `${roundToDecimalPlaces(convertedEtc) || 0} ${etcLabel}`,
      highlight: true,
      title: etcTooltip?.etcTotal?.title
    }
  ];

  // The below mock values will be replaced with actual value
  const availableWater = [
    {
      label: 'Rootzone depth',
      value: `${roundToDecimalPlaces(convertedRootzoneDepth) || 0} ${rootzoneLabel}`,
      title: availableWaterTooltip?.rootzoneDepth?.title
    },
    {label: 'VWC', value: `${rootzoneValue || 0} %` },
    {
      label: 'Rootzone water',
      value: `${roundToDecimalPlaces(rootzoneWater) || 0} ${rootzoneLabel}`,
      title: availableWaterTooltip?.rootzoneWater?.title
    },
    {
      label: 'Total Available water',
      value: `${roundToDecimalPlaces(totalAvailableWater) || 0} ${rootzoneLabel}`,
      title: availableWaterTooltip?.totalAvailableWater?.title
    },
    {
      label: 'Deficit',
      value: `${roundToDecimalPlaces(deficitString) || 0} ${depthLabel}`,
      title: availableWaterTooltip?.deficit?.title
    },
    {
      label: 'Target deficit',
      value: `${roundToDecimalPlaces(deficitString) || 0} ${depthLabel}`,
      highlight: true,
      title: availableWaterTooltip?.targetDeficit?.title
    },
  ];

  // The below mock values will be replaced with actual value
  const recommendedRuntimeDetails = [
    {
      label: 'Total recommended',
      value: `${durationString + scheduledDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.totalRecommended?.title
    },
    {
      label: 'Hours scheduled',
      value: `${scheduledDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.hoursScheduled?.title
    },
    {
      label: 'Precipitation Duration ',
      value: `${roundToDecimalPlaces(precipValueInHours) || 0} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.precipDuration?.title
    },
    {
      label: 'Hours needed',
      value: `${durationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.hoursNeeded?.title,
    }
  ];
  const totalHoursToReachTarget = [
    {
      label: 'Field Capacity',
      value: `${fieldCapacityDurationString + scheduledDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.totalFieldCapacity?.title
    },
    {
      label: 'Refill Point',
      value: `${refillPointDurationString + scheduledDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.totalRefilPoint?.title
    },
    {
      label: 'Wilting Point',
      value: `${wiltingPointDurationString + scheduledDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.totalWiltingPoint?.title
    },
  ];
  const additionalHoursToReachTarget = [
    {
      label: 'Field Capacity',
      value: `${fieldCapacityDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.fieldCapacity?.title
    },
    {
      label: 'Refill Point',
      value: `${refillPointDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.refilPoint?.title
    },
    {
      label: 'Wilting Point',
      value: `${wiltingPointDurationString} ${hoursLabel}`,
      title: runtimeDetailsTooltip?.wiltingPoint?.title
    },
  ];

  return {
    availableWater,
    recommendedRuntimeDetails,
    totalHoursToReachTarget,
    additionalHoursToReachTarget,
    predictedDeficit,
    irrigationSystem,
    summary
  };
};
