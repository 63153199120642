import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { selectors } from 'farmx-redux-core';
import RenderRanchData from './RenderRanchData';
import AutoControlButtons from './AutoControlButtons';
import { RECOMMENDATION_OPTIONS } from '../../../constants';

const RecommendationSection = ({ ranches }) => {
  const { t } = useTranslation();
  const [globalAutoSchedule, setGlobalAutoSchedule] = useState({
    autoSchedule: false,
    source: '', // if user performs click, then source is user.
  });

  const isAutoScheduled = useSelector(selectors.selectNewIsAutoScheduled);

  useEffect(() => {
    setGlobalAutoSchedule({
      autoSchedule: isAutoScheduled,
      source: '',
    });
  }, [isAutoScheduled]);

  const updateGlobalSchedule = useCallback(() => {
    setGlobalAutoSchedule(({ autoSchedule }) => ({
      autoSchedule: !autoSchedule,
      source: 'user',
    }));
  }, []);

  return (
    <section>
      <Typography.Title className="margin-top-20" level={3}>{`${t('Recommendation Irrigation Automation')}:`}</Typography.Title>
      <Typography.Text>
        {t('Recommendations can be implemented automatically such that each recommendation performs the irrigation action without user intervention.')}
      </Typography.Text>
      <Typography.Title className="margin-top-20" level={5}>{`${t('Would you like to automate recommendations, or just choose which ones to act on yourself')}?`}</Typography.Title>
      <div className="auto-schedule-buttons-wrapper padding-10">
        <span />
        <AutoControlButtons
          options={RECOMMENDATION_OPTIONS}
          autoControl={globalAutoSchedule.autoSchedule}
          onClick={updateGlobalSchedule}
        />
      </div>
      {ranches.map((ranch) => (
        <RenderRanchData key={ranch.id} ranch={ranch} globalAutoSchedule={globalAutoSchedule} />
      ))}
    </section>
  );
};

RecommendationSection.propTypes = {
  ranches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default RecommendationSection;
