import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { PressureSensorSummary } from './ListCards/PressureSensorSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { PressureSensorItem } from '../control/ControlValves/PressureSensorItem';
import { installState } from '../../../constants';
import { isMobile } from '../../../utils/detectDevice';
import { compareByName } from '../../../helper/common';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function PressureSensorList(props) {
  const { match } = props;
  const blockId = Number(match?.params?.id);
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const blockIds = blockId ? [blockId] : hooks?.useRanchBlockSelection()?.blockIds;
  const sensors = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: installState.INSTALLED,
  }));
  const list = (sensors.water_pressure || [])?.sort(compareByName);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Pressure Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <PressureSensorSummary
            list={list}
          />

          {list.map((ps) => (
            <Link key={ps.identifier} to={`/list/pressure-sensor/${ps.type}/${ps.identifier}`}>
              <PressureSensorItem
                key={ps.id}
                sensorDetail={ps}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

PressureSensorList.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

PressureSensorList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};
