import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { EventCategoryTagProps } from './types';

const EventCategoryTag = ({ value } : EventCategoryTagProps) => {
  const { t } = useTranslation();

  if(!value) return null;

  return (
    <Tag color="default">
      { t(value) }
    </Tag>
  );
};

export default EventCategoryTag;
