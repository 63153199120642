import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
//@ts-ignore
import { selectors } from 'farmx-redux-core';
//@ts-ignore
import { teamUserAccessApi, roleApi } from 'farmx-api';
import useEntities from './useEntities';


/**
 * This hook is used to get the roles for the user in a given context
 * - either for the current team or for the current entity.
 * 
 * @param {string} teamId - Optionally pass in a team ID to get the roles for a specific team
 * @returns {string[] | undefined} - The roles for the user
 */
const useUserRoles = (teamId?: string) => {  

  // State of the currently selected entity id from the redux store 
  const { selectedEntity } = useEntities();

  // Get the user info payload
  const userInfo = useSelector(state => selectors.selectLoginUserInfo(state)).payload;

  // State to hold roles
  const [roles, setRoles] = useState<string[] | undefined>(undefined);

  // Ref to track the previous value of selectedEntity.id
  const prevEntityId = useRef(selectedEntity?.id);

  /**
   * Fetch the data forroles for the user in the current context
   * @returns {Promise<void>}
   */
  const fetchRoles = async () => {
    if (!selectedEntity?.id || !userInfo?.user_id) return;

    if(userInfo.admin == true){
      // Admin marks a "Staff" user - a user with full permissions, we can set the admin role and return
      // This might need to be revisited if we need to prevent "Staff" users from having the "Entity Admin" role
      setRoles(['Entity Admin']);
      return;
    }

    // Fetch roles - to get the entity admin role
    const roles = await roleApi.getRoles();
    const entityAdminRole = roles.data.data.find((role: any) => role.name === 'Entity Admin');

    // first we need to check if a user has an entity admin role for the current entity, as this will supercede any relevant roles
    const userEntityAdminRoleFound = await teamUserAccessApi.listTeamUserAccess({
      user_id: userInfo.user_id,
      role_id: entityAdminRole.id,
      entity_id: selectedEntity.id,
      includes: ['role'], 
      distinct: true
    });

    // if an entity admin role is found, we can set the roles and return
    if(userEntityAdminRoleFound.data.data.length > 0){
      setRoles(['Entity Admin']);
      return;
    }

    // when the teamId is provided, we want to search for team-specific roles
    if(teamId != undefined) {
      const teamUserAccess = await teamUserAccessApi.listTeamUserAccess({
        team_id: teamId == undefined ? null : teamId,
        user_id: userInfo.user_id,
        includes: ['role'],
        distinct: true
      });
      // Set the roles for the team
      setRoles(teamUserAccess.data.data.map((teamUserAccess:any) => teamUserAccess.role.name));
      return;
    }

    // we did not find any entity admin roles or team-specific roles, so we can set the roles to an empty array
    setRoles([]);
  }
  
  useEffect(() => {
  // Reset roles when selectedEntity changes
  if (selectedEntity?.id !== prevEntityId.current) {
    setRoles(undefined);
    prevEntityId.current = selectedEntity?.id;
  }

  // Fetch roles if they are undefined
  if (roles === undefined) {
    fetchRoles();
  }
  }, [selectedEntity?.id, userInfo, teamId, roles]); 

  return roles;
}

export default useUserRoles;