// variables used for calling graph data api
export const VAR_SOIL_MOISTURE_ROOTZONE_VWC = ['soil_moisture_rootzone_vwc'];
export const VAR_WATER_PRESSURE = ['water_pressure'];
export const VAR_SOIL_MOISTURE_ROOTZONE_PCT = ['soil_moisture_rootzone_percent'];
export const VAR_STATE = ['state'];
export const VAR_ET0 = ['et0'];
export const VAR_AIR_TEMPERATURE = ['air_temp'];
export const VAR_AIR_HUMIDITY = ['air_humidity'];
export const VAR_PRECIPITATION = ['precip'];
export const VAR_CROP_STRESS = ['twd,crop_stress'];
export const VAR_SOLAR_RADIATION = ['solar_radiation'];
export const VAR_WIND_SPEED = ['wind_speed'];
export const VAR_WIND_DIRECTION = ['wind_direction'];

// rule engine options
export const NO_RENDER='no_render';
export const CALL_CUSTOM_CONFIG_AND_RENDER='call_custom_config_and_render';
export const ONLY_RENDER='only_render';

// sensor type short forms
export const getSensorTypeShortForm = (type) => {
  switch(type){
    case 'aquacheck_soil': return 'sma';
    case 'pixl_soil': return 'smo';
    case 'water_pressure': return 'pr';
    case 'valve': return 'vlv';
    case 'weather_station': return 'ws';
    default: return 'sensor';
  }
};
