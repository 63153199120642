import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ET0 } from 'components/hooks/graph';
import Moment from 'moment';
import {hooks} from 'farmx-redux-core';
import {
  convertLengthUnits,
  getDailySum,
  getYminMaxForSum
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_ET0 } from './constants';

const { useUnits } = hooks;

export default function Et0Chart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const getUserUnits = useUnits();

  const globalYMinMax = yMinMaxMap && yMinMaxMap[ET0]?yMinMaxMap[ET0]:{min: null, max: null};

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0) && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const customConfig = useCallback((configObj, seriesData) => {
    if(configObj?.series?.[0]){
      // Get daily sum data
      const dailySumData = getDailySum(configObj?.series?.[0].data);
      const yMinMax = getYminMaxForSum(dailySumData);

      const { multiplier } = configObj?.series?.[0];
      updateYminmax(ET0, yMinMax.min * multiplier, yMinMax.max * multiplier);
      const { units } = seriesData?.et0?.[0] || {};
      const dataUnit = units || 'inches';
      const data = convertLengthUnits(dailySumData, getUserUnits, dataUnit);
      const {value: yMin, label} = getUserUnits(yMinMax.min, dataUnit, 'depth', {decimalPlaces: 3});
      const {value: yMax} = getUserUnits(yMinMax.max, dataUnit, 'depth', {decimalPlaces: 3});
      setLabel(label);
      return {
        ...configObj,
        series: [{
          ...configObj?.series?.[0],
          data,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: label
          },
          name: 'ET0'
        }],
        yAxis: [{
          ...configObj.yAxis[0],
          min: yMin,
          max: yMax
        }]
      };
    }
    return configObj;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_ET0}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('ET0'), units: t(label), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

Et0Chart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
};

Et0Chart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
