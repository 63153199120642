import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export default function AnomalyModalButtons(props) {
  const {
    btnText, isDelete, isConfirmDelete, anomalyDispatch,
    modal, setModal, deleteAnomaly, isDisabled,
  } = props;
  const { t } = useTranslation();

  return [
    <div className="flex-row" style={{ padding: '4px' }}>
      <Button
        onClick={() => {
          setModal({ ...modal, visible: false });
          if (isDelete) {
            anomalyDispatch({
              type: 'setDeleteAnomaly',
              payload: { ...deleteAnomaly, delete: false },
            });
          }
        }}
        block
      >
        {t('Cancel')}

      </Button>
      <Button
        type="primary"
        onClick={() => {
          if (!isDelete && !isConfirmDelete) setModal({ ...modal, visible: false });
          if (isConfirmDelete) {
            setModal({ ...modal, visible: false });
            anomalyDispatch({
              type: 'setDeleteAnomaly',
              payload: { ...deleteAnomaly, save: true, delete: false },
            });
          } else setModal({ type: 'delete', visible: true });
        }}
        disabled={isDelete ? isDisabled : undefined}
        block
      >
        {t(btnText || 'Save')}
      </Button>
    </div>,
  ];
}

AnomalyModalButtons.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    visible: PropTypes.bool,
  }),
  deleteAnomaly: PropTypes.bool,
  setModal: PropTypes.func,
  anomalyDispatch: PropTypes.func,
  isDisabled: PropTypes.bool,
  btnText: PropTypes.string,
  isDelete: PropTypes.bool,
  isConfirmDelete: PropTypes.bool,
};

AnomalyModalButtons.defaultProps = {
  modal: null,
  deleteAnomaly: false,
  setModal: null,
  anomalyDispatch: null,
  isDisabled: true,
  btnText: null,
  isDelete: false,
  isConfirmDelete: false,
};
