import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { IconType } from 'react-icons';
import { FaChevronRight, FaPeopleCarry, FaUserAlt } from 'react-icons/fa';
import { ROUTES } from '../../organization/routes';
import { PageHeader } from '../components/PageHeader';
import '../settings/preferences.css';

interface OrganizationItem {
  label: string;
  key: string;
  route: string;
  icon: IconType;
  adminRequired?: boolean;
}

const OrganizationItems: OrganizationItem[] = [
  {
    label: 'Teams',
    key: ROUTES.TEAMS_LIST.key,
    route: ROUTES.TEAMS_LIST.path,
    icon: FaPeopleCarry,
  },
  {
    label: 'Users',
    key: ROUTES.USERS_LIST.key,
    route: ROUTES.USERS_LIST.path,
    icon: FaUserAlt,
  },
];

function renderIcon(IconComponent: IconType) {
  return <IconComponent size={20} />;
}

export function Organization() {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => selectors.selectLoginUserInfo).payload;
  const isAdmin = userInfo && userInfo.admin;

  return (
    <div className="settings-container page-content margin-10">
      <PageHeader showBack title={t('Organization')} toPath="/" />
      <div className="settings-items">
        {OrganizationItems.map((item) => (
          (isAdmin || !item.adminRequired) && (
            <div key={item.key}>
              <Link to={item.route}>
                {renderIcon(item.icon)}
                <Typography.Title level={4}>{t(item.label)}</Typography.Title>
                <FaChevronRight size={16} className="icon-right" />
              </Link>
            </div>
          )
        ))}
      </div>
    </div>
  );
}
