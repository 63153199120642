import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RWebShare } from 'react-web-share';
import { FaShare } from 'react-icons/fa';
import { selectors, hooks } from 'farmx-redux-core';

import {
  Button,
} from 'antd';
import { useTracking } from '../../../helper/mixpanel';
import './NotificationShareButton.less';

const timeFormat12Hr = 'YYYY-M-DD h:mm a';
const timeFormat24Hr = 'YYYY-M-DD HH:mm';

export function NotificationShareButton({ notification, showTitle }) {
  const { t } = useTranslation();
  const tracking = useTracking();

  const userTimeFormat = useSelector((state) => selectors.selectUserTimeFormat(state));
  const timeFormat = userTimeFormat === '24_hour' ? timeFormat24Hr : timeFormat12Hr;
  hooks.useLoadUserSettings();

  const {
    description,
    createdAt,
  } = notification;

  const title = t('FarmX Notification');
  const text = `${title} \n${description} \n${moment(createdAt).format(timeFormat)}`;

  return (
    <RWebShare
      className="schedule-share"
      data={{
        text,
        url: 'https://web.farmx.co/events',
        title,
      }}
      onClick={() => {
        if (tracking) tracking.track('Shared Notification', { notification });
      }}
    >
      {showTitle ? (
        <Button className="share-button share-button-title" type="primary" icon={<FaShare />}>{t('Share')}</Button>
      ) : (
        <Button className="share-button" type="default" icon={<FaShare />} />
      )}
    </RWebShare>
  );
}

NotificationShareButton.propTypes = {
  notification: PropTypes.shape({
    description: PropTypes.string,
    time: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  showTitle: PropTypes.bool,
};

NotificationShareButton.defaultProps = {
  showTitle: false,
};
