import React from 'react';
import ContentLoader from 'react-content-loader';
import './CommonLoader.less';

export default function BreadcrumbLoader() {
  return (
    <ContentLoader
      height={115}
      width={150}
      speed={4}
    >
      <rect x="0" y="0" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="20" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="40" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="60" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="80" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="100" rx="3" ry="3" width="150" height="15" />
    </ContentLoader>
  );
}
