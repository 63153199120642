import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import AnomalyCreateForm from './Anomaly/AnomalyCreateForm';

export default function AnomalyCreateEditModal(props) {
  const {
    modal, setModal, setIsAnomalyControlEnabled, isLoading, setCreateAnomaly, formValues,
    blockId, selectedFeature, dispatchMap,
  } = props;
  const { t } = useTranslation();
  let modalTitle = 'Create Anomaly';
  if (modal.type === 'edit-severity') modalTitle = 'Edit Severity';

  let createFormType;
  if (modal.type === 'edit-severity') createFormType = 'edit';
  return (
    <Modal
      visible={modal.visible}
      centered
      closable
      onCancel={() => {
        setModal({ ...modal, visible: false });
        setIsAnomalyControlEnabled(false);
      }}
      footer={null}
      title={t(modalTitle)}
    >
      <AnomalyCreateForm
        type={createFormType}
        setModal={setModal}
        modal={modal}
        setCreateAnomaly={setCreateAnomaly}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        isLoading={isLoading}
        formValues={formValues}
        blockId={blockId}
        selectedFeature={selectedFeature}
        dispatchMap={dispatchMap}
      />
    </Modal>
  );
}

AnomalyCreateEditModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    visible: PropTypes.bool,
  }),
  setModal: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  isLoading: PropTypes.bool,
  formValues: PropTypes.shape(),
  setCreateAnomaly: PropTypes.func,
  blockId: PropTypes.number,
  selectedFeature: PropTypes.shape(),
  dispatchMap: PropTypes.func,
};

AnomalyCreateEditModal.defaultProps = {
  modal: null,
  setModal: null,
  setIsAnomalyControlEnabled: null,
  isLoading: false,
  formValues: {},
  setCreateAnomaly: null,
  blockId: null,
  selectedFeature: null,
  dispatchMap: false,
};
