import React, {
  useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WATER_PRESSURE } from 'components/hooks/graph';
import {
  getWaterPressureYminMax
} from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_WATER_PRESSURE } from './constants';

export default function WaterPressureChart(props) {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();

  // TODO remove after testing
  // console.log('Render WaterpressureChart');

  const globalYMinMax = yMinMaxMap[WATER_PRESSURE]?yMinMaxMap[WATER_PRESSURE]:{min: null, max: null};

  // TODO remove after testing
  // console.log(' globalYMinMax', globalYMinMax);
  // console.log('yMinMaxMap WaterpressureChart', yMinMaxMap);

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0) && globalYMinMax.max) return { min: globalYMinMax.min, max: globalYMinMax.max};
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  // TODO remove after testing
  // console.log('yMinMax WaterpressureChart', yMinMax);

  /** ******** Custom Config Section Start*************** */
  const customConfig = useCallback((configObj, seriesData) => {
    // TODO remove after testing
    // console.log('calling customConfig');
    if(configObj?.series?.[0]){
      const yMinMax = getWaterPressureYminMax(seriesData);
      const { multiplier } = configObj?.series?.[0];
      updateYminmax(WATER_PRESSURE, yMinMax.min * multiplier, yMinMax.max * multiplier);
      // TODO remove after testing
      // console.log('local y min max multiplier', yMinMax.min, yMinMax.max, multiplier);
    }
    return configObj;
  }, [updateYminmax]);
    /** ******** Custom Config Section End*************** */

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_WATER_PRESSURE}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Water Pressure'), units: t('psi'), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
}

WaterPressureChart.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    identifier: PropTypes.string,
  }),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  cached: PropTypes.string,
  updateYminmax: PropTypes.func,
  uniformYAxis: PropTypes.bool,
  yMinMaxMap: PropTypes.shape({}),
};

WaterPressureChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};
