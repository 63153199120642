import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { WIND_DIRECTION } from 'components/hooks/graph';
import { getYminMax, convertWindDirectionData } from '../../helper/graphHelper';
import NewSensorDataChart from './NewSensorDataChart';
import { VAR_WIND_DIRECTION } from './constants';

interface Sensor {
  name?: string;
  id?: number;
  type?: string;
  identifier?: string;
}
interface WindDirectionChartProps {
  sensor?: Sensor | any;
  startDate?: any;
  endDate?: any;
  cached?: string | any;
  updateYminmax?: () => void;
  uniformYAxis?: boolean;
  yMinMaxMap?: any;
}

const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
// TODO: This will be removed once the API is ready
const today = new Date();
today.setHours(0, 0, 0, 0);
const oneDayMs = 86400000;
const initialTimestamp = today.getTime() - 14 * oneDayMs;
// One day in milliseconds
const originalData = [
  'N', 'S', 'W', 'E', 'WNW', 'NE', 'SSE', 'W', 'N', 'SSE',
  'WNW', 'SSE', 'NNW', 'S', 'SSE'
];

const configData = originalData.map((direction, index) => [initialTimestamp + index * oneDayMs, direction]);

function generateWindDirectionData(count: number) {
  return {
    wind_direction: [
      {
        name: 'Precipitation',
        variable: 'wind_direction',
        sensor_name: 'aj-ranch-weather',
        units: '',
        sensor_type: 'weather_station',
        sensor_id: 'REM_20241218_JyUNao9si2',
        sensor_uid: 19436,
        block_name: 'R&R East',
        ranch_name: 'AJ Ranch',
        block_id: 739740,
        data: configData.reverse(),
        min: 0,
        max: 15,
      }
    ]
  };
}

const WindDirectionChart: React.FC<WindDirectionChartProps> = (props: any) => {
  const {
    sensor, startDate, endDate, cached, updateYminmax, uniformYAxis, yMinMaxMap
  } = props;
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const globalYMinMax = yMinMaxMap && yMinMaxMap[WIND_DIRECTION]
    ?yMinMaxMap[WIND_DIRECTION]:{min: null, max: null};

  const yMinMax = useMemo(() => {
    if((globalYMinMax.min || globalYMinMax.min===0)
          && globalYMinMax.max) { return { min: globalYMinMax.min, max: globalYMinMax.max}; }
    return null;
  }, [globalYMinMax.min, globalYMinMax.max]);

  const customConfig = useCallback((configObj, seriesData) => {
    // TODO: This will be removed
    const daysDifference = endDate.diff(startDate, 'days');
    const windDIrectionData = generateWindDirectionData(daysDifference);
    // TODO: This condition check will be updated
    if (configObj?.series?.[0] || windDIrectionData?.wind_direction) {
      // TODO: This will be uncommented
      // const yMinMax = getYminMax(seriesData) || { min: 0, max: 0};
      // TODO: This will be removed
      const yMinMax = getYminMax(windDIrectionData) || { min: 0, max: 0};
      const { multiplier } = configObj?.series?.[0] || { multiplier: 1 };
      updateYminmax(WIND_DIRECTION, yMinMax.min * multiplier, yMinMax.max * multiplier);
      // TODO: This will be updated in future
      // const data = convertWindDirectionData(configObj?.series?.[0].data);
      const data = convertWindDirectionData(windDIrectionData?.wind_direction[0].data);
      const yMin = yMinMax?.min;
      const yMax = yMinMax?.max;
      // const { units } = seriesData?.wind_direction?.[0];
      const { units } = windDIrectionData?.wind_direction?.[0];

      const dataUnit = units;
      setLabel(dataUnit);
      return {
        ...configObj,
        series: [{
          ...configObj?.series?.[0],
          data,
          tooltip: {
            valueDecimals: 2,
            pointFormat: 'Wind Direction : <b>{point.label}</b>'
          },
          name: 'Wind Direction',
        }],
        yAxis: [{
          ...configObj.yAxis[0],
          min: yMin,
          max: yMax,
          title: {
            text: null
          },
          tickPositions: [...Array(16).keys()],
          labels: {
            reserveSpace: true,
            formatter(): string {
              // eslint-disable-next-line react/no-this-in-sfc
              return directions[this.value] || `${this.value}`;
            }
          },
        }],
      };
    }
    return configObj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateYminmax]);

  return (
    <>
      <NewSensorDataChart
        sensorName={sensor.name}
        sensorId={sensor.id}
        sensorType={sensor.type}
        sensorIdentifier={sensor.identifier}
        variables={VAR_WIND_DIRECTION}
        startDate={startDate}
        endDate={endDate}
        cached={cached}
        customConfig={customConfig}
        header={{title: t('Wind Direction'), units: t(label), right: []}}
        uniformYAxis={uniformYAxis}
        yMinMax={yMinMax}
      />
    </>
  );
};

WindDirectionChart.defaultProps = {
  sensor: null,
  startDate: null,
  endDate: null,
  cached: null,
  updateYminmax: () => null,
  uniformYAxis: false,
  yMinMaxMap: null,
};

export default WindDirectionChart;
