/* eslint-disable no-prototype-builtins */
import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, has } from 'lodash';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import './list.css';
import { SelectedObjectFromState } from 'types/farm';
import { SensorTypes, ValveStatus } from 'types/sensor';
import { installState, ALL_SENSORS_LIST, SENSORS_VISIBLE_TO_CLIENT } from '../../../constants';
import ListCards from './ListCards';
import { NoSummaryData } from './ListCards/NoSummaryData';
import SensorSummaryLoader from './SensorSummaryLoader';

const {
  selectAllSensorsForBlockIdsByType,
  selectAllSensorStatusForBlockIdsByType,
  selectPumpControllersForBlockIds,
  selectLoadingSensors,
} = selectors;

export interface SensorSummaryProps {
  selectedObj?: SelectedObjectFromState;
  blocks?: number[];
}

export function SensorSummaryList(props: SensorSummaryProps) {
  const { blockIds } = hooks.useRanchBlockSelection() || {};
  const [localBlockIds, setLocalBlockIds] = useState(blockIds);
  const list = useSelector((state) => selectPumpControllersForBlockIds(state, localBlockIds, true))
   || [];
  const sensorsForBlockIds = useSelector((state) => selectAllSensorsForBlockIdsByType(state, {
    blockIds: localBlockIds,
    installState: installState.INSTALLED,
  }));
  const status = useSelector((state) => selectAllSensorStatusForBlockIdsByType(state,
    localBlockIds));
  const loadingSensors = useSelector((state) => selectLoadingSensors(state));
  const { selectedObj } = props;
  const [sensorsData, setSensorsData] = useState(sensorsForBlockIds);
  // TODO: Always returning valve status instead of other status
  const [sensorStatusData, setSensorStatusData] = useState<ValveStatus[]>(status);
  const inactiveSensorsRef = useRef({});
  const activeSensorsRef = useRef({});
  const showEmptySections = false;

  // To update local block state when there is change
  useEffect(() => {
    if (!isEqual(blockIds, localBlockIds)) {
      setLocalBlockIds(blockIds);
    }
  }, [blockIds, localBlockIds]);

  // Delete one of common sensors from sensors list to render it once.
  const deleteCommonSensors = useCallback((sensorsList) => {
    const sensors = { ...sensorsList };
    const isDeleteSoilSensors = has(sensors, 'aquacheck_soil')
      && has(sensors, 'pixl_soil');
    if (isDeleteSoilSensors) delete sensors.pixl_soil;

    const isDeleteWaterFlowSensors = has(sensors, 'water_flow_analog')
      && has(sensors, 'water_flow');
    if (isDeleteWaterFlowSensors) delete sensors.water_flow;

    return { ...sensors };
  }, []);

  function showActiveSensors(sensors: SensorTypes) {
    // returns false for empty activeSensors object
    if (isEmpty(sensors)) return false;

    // returns true if any one of the sensor is present in
    // both SENSORS_VISIBLE_TO_CLIENT object and activeSensors object
    if (Object.keys(SENSORS_VISIBLE_TO_CLIENT).some(
      (key) => key in sensors,
    )) return true;

    return false;
  }

  const createInactiveList = useCallback(() => {
    const result = { ...ALL_SENSORS_LIST } as SensorTypes;
    const sensorKeys = Object.keys(sensorsData);
    for (let i = 0; i < sensorKeys.length; i += 1) {
      const key = sensorKeys[i];
      if (has(sensorsData, key)) {
        // check if the key exist in inactive list
        if (has(result, key)) {
          // If any one sensor exist in installed list,
          // delete all sensors related to that sensor summary
          if (key === 'aquacheck_soil' || key === 'pixl_soil') {
            delete result.aquacheck_soil;
            delete result.pixl_soil;
          } else if (key === 'water_flow_analog' || key === 'water_flow') {
            delete result.water_flow_analog;
            delete result.water_flow;
          } else {
            delete result[key];
          }
        }
      }
    }

    const main = deleteCommonSensors(result);
    if (!isEqual(inactiveSensorsRef.current, main)) {
      inactiveSensorsRef.current = main;
    }
  }, [deleteCommonSensors, inactiveSensorsRef, sensorsData]);

  const sortActiveSensors = (sensors: SensorTypes) => {
    const sortedActiveSensors = {};
    Object.keys(ALL_SENSORS_LIST).forEach((key) => {
      if (sensors.hasOwnProperty(key)) {
        sortedActiveSensors[key] = sensors[key];
      }
    });

    return sortedActiveSensors;
  };

  useEffect(() => {
    if (!isEqual(status, sensorStatusData)) {
      setSensorStatusData(status);
    }
  }, [sensorStatusData, status]);

  useEffect(() => {
    let sensors = sensorsForBlockIds;
    // Passing vfd in sensors list
    if (list.length > 0) {
      sensors = { ...sensors, vfd: list };
    }
    if (!isEqual(sensors, sensorsData)) {
      setSensorsData(sortActiveSensors(sensors));
    }
  }, [list, sensorsData, sensorsForBlockIds]);

  useEffect(() => {
    createInactiveList();
  }, [createInactiveList]);

  const sensorsAfterDelete = deleteCommonSensors(sensorsData);

  if (!isEqual(activeSensorsRef.current, sensorsAfterDelete)) {
    activeSensorsRef.current = sensorsAfterDelete;
  }

  if (!loadingSensors.loading && !showActiveSensors(activeSensorsRef.current)) {
    return <NoSummaryData message="No Devices / Sensors installed" />;
  }

  return (
    <div className="sensor-summary-list-container item-list">
      {showActiveSensors(activeSensorsRef.current)
        ? Object.keys(activeSensorsRef.current).map((key) => (
          <ListCards
            key={key}
            objKey={key}
            sensorsData={sensorsData}
            selectedObj={selectedObj}
          />
        )) : <SensorSummaryLoader />}
      {/* Adding showEmptySections flag for
          current implementation might remove it later on. Refer: FF-1450 */}
      {showEmptySections
        && Object.keys(inactiveSensorsRef.current).map((key) => (
          <ListCards
            key={key}
            objKey={key}
            sensorsData={sensorsData}
            selectedObj={selectedObj}
          />
        ))}
    </div>
  );
}

SensorSummaryList.defaultProps = {
  selectedObj: null,
};

SensorSummaryList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedObj: PropTypes.object,
};
