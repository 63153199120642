import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UsersList from './UsersList';
import { RouteComponentProps } from 'react-router-dom';
import { FarmxWebApiTypes } from 'types/api_types';
import { message } from 'antd';
//@ts-ignore
import { selectors } from 'farmx-redux-core';
//@ts-ignore
import { teamUserAccessApi } from 'farmx-api';
import useUserRoles from 'components/organization/hooks/useUserRoles';
import useEntities from 'components/organization/hooks/useEntities';

/**
 * UserListContainer is a container component that fetches the users data from the api and passes it to the UsersList component.
 * @param {RouteComponentProps} props - The standard react-router-dom props.
 * @returns {JSX.Element}
 */
const UsersListContainer: React.FC<RouteComponentProps> = (props:RouteComponentProps) => {
  // Destructure history from props
  const { history } = props;

  // State of the currently selected entity id from the redux store 
  const { selectedEntity } = useEntities();
  
  // State of the loading status
  const [loading, setLoading] = useState(true);

  // State of the pagedresult of the users query
  const [pagedResult, setPagedResult] = useState<FarmxWebApiTypes.PaginatedResult<any>>({
    data: [],
    page: 0,
    page_size: 0,
    count: 0,
  });

  // State of the current spec for fetching users
  const [spec, setSpec] = useState<FarmxWebApiTypes.TeamUserAccessListQuery>({
    includes: ['user', 'role'],
    page_number: 1,
    page_size: 10,
    distinct: true,
  });

  /**
   * Side effect to fetch the users data from the api when the spec is changed or initialized
   * @returns {void}
   */
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        setLoading(true);
        var usersResult = await teamUserAccessApi.listTeamUserAccess({...spec, entity_id: selectedEntity?.id});
        setPagedResult(usersResult.data);
        setLoading(false);  
      } catch (error) {
        message.error('Failed to fetch data.');
      }
      setLoading(false);
    };

    loadData();
  }, [spec, selectedEntity?.id]);

  /**
   * Method to handle the page change - updates the query spec based on the new page number and page size
   * @param page - The new page number
   * @param pageSize - The new page size
   * @returns {void}
   */
  const handlePageChange = (page: number, pageSize:number) => {
    setSpec(prev => ({
      ...prev,
      page_number: page,
      page_size: pageSize || prev.page_size,
    }));
  };

  /**
   * Method to handle the user selection - navigates to the user detail page
   * @param user - The selected user
   * @returns {void}
   */
  const handleUserSelect = (user: any) => {
    history.push({
      pathname: `/organization/users/${user.user.id}`,
      state: { user },
    });
  } 

  /** 
   * Method to handle the user creation - navigates to the user creation page
   * @returns {void}
  */
  const handleCreateUser = () => {  
    history.push('/organization/users/invite-user');
  }

  const roles = useUserRoles();

  return (
    <UsersList
      loading={loading}
      pagedResult={pagedResult}
      tableSpec={spec}
      onPageChange={handlePageChange}
      onUserSelect={handleUserSelect}
      onCreateUser={handleCreateUser}
      currentUserRoles={roles}
    />
  );
};

export default UsersListContainer;
