import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors, actions } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import RecommendationSection from './RecommendationSection';
import './irrigation-settings.less';
import { isMobile } from '../../../utils/detectDevice';

const IrrigationSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ranches = useSelector(selectors.selectAllNewRanches) || [];

  useEffect(() => {
    dispatch(actions.loadAllCoordination());
  }, [dispatch]);

  return (
    <div className="block-irrigation-settings div-default padding-20 background-white">
      <PageHeader
        showBack={!!isMobile}
        title={t('Automate Irrigations')}
        toPath="/settings"
      />
      <RecommendationSection ranches={ranches} />
    </div>
  );
};

export default IrrigationSettings;
