import React, { useEffect, useMemo } from 'react';
import { Typography, Tooltip } from 'antd';
import { SensorAlarmButton } from 'farmx-web-ui';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { LoadingOutlined } from '@ant-design/icons';
import { CardLayout } from '../../components/CardLayout';
import PumpStateText from '../../components/PumpStateText';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import LastReportedDate from '../../list/LastReportedDate';

export default function ControlPumpListItem(props) {
  const { t } = useTranslation();
  const {
    pump, loadStatus, showHeader, showBlockName,
  } = props;
  const { blockId, identifier } = pump || {};
  const blockDetails = useSelector((state) => selectors.selectNewBlockById(state, blockId));
  const sensorDetails = useSelector((state) => selectors.selectSensor(state, 'vfd', identifier));
  const sensorStatus = useSelector((state) => selectors.selectSensorStatus(state, 'vfd', identifier)) || {};

  const { name: blockName } = blockDetails ?? { name: '' };
  const { name: pumpName } = sensorDetails ?? { name: '' };
  const title = useMemo(() => (showBlockName ? `${blockName} Pumps` : pumpName), [blockName, pumpName, showBlockName]);
  const { Text, Title } = Typography;
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * When the status is already loaded,
     * below check helps to prevent additional network call
     */
    if (loadStatus) {
      dispatch(actions.loadSensorStatus({ type: 'vfd', identifier }));
    }
  }, [dispatch, loadStatus, identifier]);


  useEffect(() => {
    dispatch(actions.loadBlockControlStatus(blockId));
  }, [blockId, dispatch]);

  function renderTitleAndAlarmSection() {
    return (
      <>
        <div className="title-and-alarm-container">
          <div className="flex-row">
            <Tooltip
              title={title}
              trigger="click"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Title level={4} className="text-ellipsis">
                {title}
              </Title>
            </Tooltip>
          </div>
          <SensorAlarmButton
            type={sensorStatus.type}
            identifier={identifier}
            isMobileView
            alarmCount={sensorStatus.alarmCount}
          />
        </div>
        <div className="pump-list-item-last-updated">
          <Text type="secondary">
            {`${t('Updated')} `}
            <Moment fromNow>{sensorStatus.lastUpdated}</Moment>
          </Text>
          {sensorStatus.loading ? <LoadingOutlined className="pump-valve-loader" /> : null}
        </div>
      </>
    );
  }
  return (
    <CardLayout>
      {showHeader && renderTitleAndAlarmSection()}
      <div className="pump-status-container">
        <PumpStateText
          state={sensorStatus.controlStatus?.status || ''}
          pumpName={showBlockName ? pumpName : null}
        />
        <ConnectivityStateTag
          isOnline={sensorStatus.online}
        />
      </div>
      {!sensorStatus.online && <LastReportedDate latestDate={sensorStatus?.latestDate} />}
    </CardLayout>
  );
}

ControlPumpListItem.defaultProps = {
  loadStatus: true,
  showHeader: true,
  showBlockName: false,
};

ControlPumpListItem.propTypes = {
  pump: PropTypes.objectOf(Object).isRequired,
  loadStatus: PropTypes.bool,
  showHeader: PropTypes.bool,
  showBlockName: PropTypes.bool,
};
