import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';
import InfiniteScroll from 'react-infinite-scroller';
import ControlPumpList from './ControlPump/ControlPumpList';
import ControlValveList from './ControlValves/ControlValveList';
import ControlSummary from './ControlSummary/ControlSummary';
import { PageHeader } from '../components/PageHeader';
import './control.css';
import './CommonStyle.css';
import { useTracking } from '../../../helper/mixpanel';
import ReactContentLoader from '../components/ContentLoader';

// This will be removed after testing
// const ControlSummary = lazy(() => import('./ControlSummary/ControlSummary'));
// const ControlValveList = lazy(() => import('./ControlValves/ControlValveList'));
// const ControlPumpList = lazy(() => import('./ControlPump/ControlPumpList'));

const {
  selectNewBlocksLoadingState,
  selectNewBlocksLoaded,
} = selectors;

const {
  loadAllSensors,
  setUrlLoad,
  setRanchBlockSelection,
  loadBlockControlStatus,
} = actions;

const { selectLoginUserInfo } = selectors;

const { useRanchBlockSelection } = hooks;

const tabsContent = (blocks) => ({
  summary: <ControlSummary blocks={blocks} />,
  pumps: <ControlPumpList blocks={blocks} />,
  valves: <ControlValveList blocks={blocks} />,
});

const REFRESH_INTERVAL = 1000 * 30;

export function ControlPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const tracking = useTracking();
  const refreshStatusInterval = useRef(null);

  const userInfo = useSelector(selectLoginUserInfo).payload;

  useEffect(() => {
    if (tracking) tracking.track('Loaded Control Page');
  }, [tracking]);

  const controlOptions = [
    { label: t('Summary'), value: 'summary' },
    { label: t('Pumps'), value: 'pumps' },
    { label: t('Valves'), value: 'valves' },
  ];

  const param = new URLSearchParams(search);
  const paramTab = param.get('tab');

  const [selectedTab, setSelectedTab] = useState(paramTab || 'summary');
  const dispatch = useDispatch();

  const {
    block, ranch, selectedObjFromState, blockArray, blockIds,
  } = useRanchBlockSelection();

  const loadingBlocks = useSelector((state) => selectNewBlocksLoadingState(state));
  const blocksLoaded = useSelector(selectNewBlocksLoaded);

  const [localBlocks, setLocalBlocks] = useState([]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 10;

  function renderChildren() {
    if (!blocksLoaded && loadingBlocks.loading) return <ReactContentLoader />;

    return selectedObjFromState.type ? tabsContent(items)[selectedTab] : null;
  }

  const fetchMoreData = useCallback(() => {
    if (localBlocks[count + 1]) {
      setItems((prevItems) => [...prevItems, ...localBlocks[count + 1]]);
      setCount((prevCount) => prevCount + 1);
      setHasMoreItems((prevCount) => prevCount + 1 < localBlocks.length - 1);
    } else {
      setHasMoreItems(false);
    }
  }, [localBlocks, count]);

  function handleSelect(d) {
    if (d?.value !== undefined) {
      dispatch(setRanchBlockSelection(d, tracking));
      setItems([]);
      setLocalBlocks([]);
      setCount(0);
      setHasMoreItems(false);
    }
  }

  function handleURLParam(e) {
    const params = {};
    if (location.search.length) {
      if (ranch && ranch[0]) {
        const ranchId = ranch[0];
        params.ranchId = ranchId;
      } if (block && block[0]) {
        const blockId = block[0];
        params.blockId = blockId;
      }
    }
    params.tab = e.target.value;
    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;

    return paramStr;
  }

  function handleChange(e) {
    setSelectedTab(e.target.value);
    history.push(handleURLParam(e));
    dispatch(setUrlLoad(false));
    setHasMoreItems(false);
    if (tracking) tracking.track(`Selected Tab=${e.target.value}`);
  }

  useEffect(() => {
    if (blockArray && blockArray.length && selectedObjFromState.type) {
      const chunks = [];
      for (let i = 0; i < blockArray.length; i += chunkSize) {
        chunks.push(blockArray.slice(i, i + chunkSize));
      }

      setLocalBlocks((prevBlocks) => {
        if (JSON.stringify(prevBlocks) !== JSON.stringify(chunks)) {
          setItems(chunks[0] || []);
          setHasMoreItems(chunks.length > 1);
          return chunks;
        }
        return prevBlocks;
      });
    }
  }, [blockArray, selectedObjFromState.type]);


  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch]);

  // To show the latest sensor status
  function loadAllBlockControlStatus(blockIdArr) {
    blockIdArr.forEach((blockId) => {
      dispatch(loadBlockControlStatus(blockId));
    });
  }

  // Auto refresh the sensor status in every 30 seconds
  function refreshUntilUnmount() {
    loadAllBlockControlStatus(blockIds);
    refreshStatusInterval.current = setInterval(() => {
      loadAllBlockControlStatus(blockIds);
    }, REFRESH_INTERVAL);
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  // Trigger the refresh interval when component loads
  useEffect(() => {
    refreshUntilUnmount();
    // Clear the interval when component unmounts
    return () => {
      clearRefreshInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!location.search.length) {
      if (ranch && ranch[0]) history.push(`?ranchId=${ranch[0]}`);
      if (block && block[0]) history.push(`?blockId=${block[0]}`);
    }
  }, [location.search, ranch, block, history]);

  /**
   * Why is this useEffect needed?
   *
   * Data passed to <InfiniteScroll /> is filtered in child component.
   * In case filtered data length is zero, InfininteScroll doesn't fire fetchMoreData() to
   * get next batch of data.
   * It causes list showing no data, and the below code fixes by calling fetchMoreData()
   * if the items are changed and filtered data has length less than 5
   */
  useEffect(() => {
    if (items && items.length) {
      // Filter and deduplicate VFDs
      const vfds = items.filter((d) => d && d.vfd);
      const filteredVfd = vfds.filter((value, index) => (
        index === vfds.findIndex((obj) => obj.vfd.identifier === value.vfd.identifier)
      ));

      // Only fetch more data if the filtered vfd length < 5 and if more items are available
      if (filteredVfd.length < 5 && hasMoreItems) {
        fetchMoreData();
      }
    }
  }, [items, hasMoreItems, fetchMoreData]);

  return (
    <div className="div-default control-container page-content">
      <div className="inline-page-loader-div">
        <PageHeader
          data-testid="control-title"
          title={t('Control')}
        />
        {blocksLoaded && loadingBlocks.loading ? (
          <div className="loader-common">
            <LoadingOutlined />
          </div>
        ) : null}
      </div>
      <div className="div-select-container ranch-block-select-container">
        <RanchBlockSelectMobile
          onSelect={handleSelect}
          selected={selectedObjFromState}
          admin={userInfo && userInfo.admin}
        />
      </div>
      {selectedObjFromState.type ? (
        <div className="control-tab-container">
          <Radio.Group
            buttonStyle="solid"
            onChange={handleChange}
            options={controlOptions}
            optionType="button"
            size="large"
            value={selectedTab}
          />
        </div>
      ) : null}
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoreData}
        hasMore={hasMoreItems}
        loader={<div className="loader" key="0"> Loading... </div>}
        useWindow={false}
      >
        {renderChildren()}
      </InfiniteScroll>
    </div>
  );
}
