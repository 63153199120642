/* eslint-disable no-param-reassign */
import React from 'react';
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import { Button } from 'antd';
import { FaLocationArrow } from 'react-icons/fa';
import { blockApi } from 'farmx-api';
import moment from 'moment';
import { interpolateRdYlGn, interpolateSpectral } from 'd3-scale-chromatic';
import { isEqual } from 'lodash';
import { isMobile } from '../utils/detectDevice';
import { IconTitle } from '../components/mobile/list/IconTitle';

const keys = ['ndre', 'ndvi', 'canopySize', 'cropStress', 'standingWater',
  'irrigation', 'evapotranspirationActual'];

const canopySizeUnitForArea = {
  acres: 'square_feet',
  hectares: 'square_meters',
  square_miles: 'square_meters',
  square_kilometers: 'square_meters',
};

const canopySizeUnitForLength = {
  meters: 'square_meters',
  feet: 'square_feet',
};

/**
 * @param {*} blockIds - array of blockIds
 * @param {*} callBack - callback function to return anomaly data
 */
export const loadBlockAnomalies = async (blockIds, callBack) => {
  const allRequests = [];
  const finalData = [];
  blockIds.forEach((blockId) => {
    allRequests.push(blockApi.getBlockAnomaly(blockId));
  });
  Promise.allSettled(allRequests).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        if (r?.value?.data?.length) {
          const anomalies = r.value.data;
          if (anomalies) {
            anomalies.forEach((anomaly) => {
              const anomalyObj = {
                id: `${anomaly.name}/${anomaly.anomaly_type}`,
                type: 'Feature',
                geometry: anomaly.center,
                name: anomaly.name,
                properties: anomaly,
              };
              finalData.push(anomalyObj);
            });
          }
        }
      }
    });
    callBack(finalData);
  });
};

export function navigateMap(selectedAnomaly) {
  if (selectedAnomaly === null) return null;
  const baseURL = 'https://www.google.com/maps/dir/?api=1&destination=';
  const lat = selectedAnomaly.properties.center.coordinates[1];
  const lng = selectedAnomaly.properties.center.coordinates[0];
  window.open(`${baseURL + lat},${lng}`, '_blank');
  return true;
}

/**
   * @param {*} selectedFeature - selected anomaly feature
   * @returns - the modified feature object to draw polygon
   */
export function getLayerBounds(selectedFeature) {
  if (selectedFeature) {
    const obj = selectedFeature;
    obj.geometry = selectedFeature?.properties?.bounds;
    return obj;
  }
  return selectedFeature;
}

export const getDataForReDraw = (ranchSensors, presMode,
  anomalyGeoJSON, selectedFeature) => {
  let data = ranchSensors;
  if (presMode === 'anomaly' && !selectedFeature) data = anomalyGeoJSON;
  else if (presMode === 'anomaly' && selectedFeature) data = selectedFeature;

  return data;
};

export function renderAnomalyInfo(obj, t) {
  return (
    <div className="margin-top-10 map-mobile-anomaly-button">
      <Button
        type="ghost"
        onClick={() => navigateMap(obj)}
        icon={<FaLocationArrow className="anomaly-icon" />}
      >
        {t('Navigate')}
      </Button>
    </div>
  );
}

export function softDeleteBlockAnomaly(anomalyDeleteData, callBack) {
  blockApi.softDeleteAnomaly(anomalyDeleteData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function deleteBlockAnomaly(anomalyId, callBack) {
  blockApi.deleteAnomaly(anomalyId).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function createAnomalyData(formObj, layer) {
  const anomaly = {
    name: formObj.name,
    anomaly_type: formObj.type,
    description: formObj.description,
    ...layer,
    block: formObj.blockId,
  };
  return anomaly;
}

export function createBlockAnomaly(anomalyData, callBack) {
  blockApi.createAnomaly(anomalyData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function editBlockAnomaly(anomalyData, callBack) {
  blockApi.updateAnomaly(anomalyData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

/**
 * Change nested structure to simple key value pair for csv generation
 */
export function flattenAnomalyArrayForCsv(anomalyArr) {
  return anomalyArr.map((anomalySingle = {}) => {
    const obj = {};
    obj.Id = anomalySingle.id;
    obj.Name = anomalySingle.name;
    obj.Type = anomalySingle.type;

    if (anomalySingle.geometry) {
      obj['Geometry Type'] = anomalySingle.geometry.type ?? '';
      obj['Geometry Coordinates'] = anomalySingle?.geometry.coordinates?.join(', ') ?? '';
    }

    if (anomalySingle.properties) {
      obj['Properties Id'] = anomalySingle.properties.id || '';
      obj['Properties Name'] = anomalySingle.properties.name || '';
      obj['Properties Block'] = anomalySingle.properties.block || '';
      obj['Properties Anomaly Type'] = anomalySingle.properties.anomaly_type || '';
      obj['Properties Center Type'] = anomalySingle.properties.center?.type || '';

      const { bounds } = anomalySingle.properties;
      if (bounds) {
        obj['Properties Bounds Type'] = anomalySingle.properties.bounds.type || '';
        /**
         * anomalySingle.properties.bounds.coordinates is in the format:
         * [
         *  [
         *    [x, y],
         *    [x1, y1]
         *  ]
         * ]
         *
         * Flattening it to look like:
         * (x, y)(x1, y1)
         */
        const propertiesBoundsCoordinates = anomalySingle.properties.bounds.coordinates || [];

        let output = '';
        propertiesBoundsCoordinates.forEach((multipleCoordinates = []) => {
          multipleCoordinates.forEach((singleCoordinate) => {
            output += '(';
            output += singleCoordinate?.join(', ') || '';
            output += ')';
          });
        });
        obj['Properties Bounds Coordinates'] = output;
      }
    }

    return obj;
  });
}

export function isImageryUpdateNeeded(imageryData, selectedSatelliteDate,
  selectedSatelliteDatesDataRange, selectedSatelliteDataType, blockId, ranchId,
  ranchIdFromState, blockIds) {
  let CONDITION_CHECK_1 = false;
  if (imageryData) {
    const dateStr = moment(selectedSatelliteDate).format('YYYY-MM-DD');
    const dateStrFromData = moment(Number(imageryData?.[0]?.date) * 1000).format('YYYY-MM-DD');
    const dateStrings = selectedSatelliteDatesDataRange?.map((d) => d?.dateString);
    CONDITION_CHECK_1 = ((imageryData?.[0]?.data_key_url
      ?.indexOf(`/${selectedSatelliteDataType}`) === -1)
          || !moment(dateStr).isSame(dateStrFromData) || !dateStrings?.includes(dateStr));
  }

  const CONDITION_CHECK_2 = !(imageryData?.length
        === blockId?.length);
  const CONDITION_CHECK_3 = !(imageryData?.[0]?.block
          === blockId?.[0]);
  const CONDITION_CHECK_4 = ranchId === ranchIdFromState && blockIds
       && blockIds.includes(imageryData?.[0]?.block);

  let callApi = !imageryData;
  if (CONDITION_CHECK_4) {
    if (imageryData && blockId?.length) {
      callApi = CONDITION_CHECK_1;
      if (blockId?.length && !callApi) {
        callApi = CONDITION_CHECK_2 || CONDITION_CHECK_3;
      }
    } else if (!callApi && imageryData?.length > 1) {
      callApi = CONDITION_CHECK_1;
    } else callApi = true;
  } else callApi = true;

  return callApi;
}

/**
 * @param {*} selMapPresentationModes - available presentationModes from the redux state
 * @returns - The presentation mode object if available, otherwise returns default one.
 */
export function getPresentationModes(selMapPresentationModes) {
  const defaultPresMode = {
    irrigationRecommendation: false,
    soilstatus: true,
    connectivity: false,
    waterpressure: false,
    control: false,
    provision: false,
    anomaly: false,
    cropHealth: false,
    plants: false,
  };

  return Object.keys(selMapPresentationModes || {})?.length
    ? selMapPresentationModes : defaultPresMode;
}

/**
 * @param {*} selMapPresentationModes - available presentationModes from the redux state
 * @returns - The selected presentation mode
 */
export function getSelectedPresentationMode(selMapPresentationModes) {
  const presMode = Object.keys(getPresentationModes(selMapPresentationModes))
    ?.find((k) => getPresentationModes(selMapPresentationModes)[k]);
  return presMode;
}

export function isValidText(value) {
  return !!(value && value !== 'null' && value !== undefined);
}

export function getRange(value) {
  let range = { min: 0, max: 1 };
  if (value > 1 && value <= 10) range = { min: 1, max: 10 };
  if (value > 10 && value <= 50) range = { min: 10, max: 50 };
  if (value > 50 && value <= 100) range = { min: 50, max: 100 };
  if (value > 100) range = { min: 100, max: 1000 };
  return range;
}

export function normalizeValue(value, minValue, maxValue) {
  let tempValue = value;
  if (value < minValue) {
    tempValue = minValue;
  } else if (value > maxValue) {
    tempValue = maxValue;
  }

  // Calculate the normalized value in the range [0, 1].
  const normalizedValue = (tempValue - minValue) / (maxValue - minValue);
  return normalizedValue;
}

// Function to calculate canopy area per single plant
function calculatePlantCanopySizePercent(cordonArea, canopyArea) {
  // Calculate canopy area per single plant
  const plantCanopySizePercent = canopyArea && cordonArea ? (canopyArea / cordonArea) : 0;
  return plantCanopySizePercent;
}

export function getPlantsDataForBlockId(blockId, plantAreaInM2, areaUnit, callBack) {
  let plantsData;
  const request = [blockApi.getPlantsBlockAnalyticsData(blockId)];

  Promise.allSettled(request).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        const { features } = r?.value?.data || {};
        const changedFeatures = features?.map((d) => {
          const { cordonArea, canopyArea } = d?.properties || {};
          const obj = { ...d };
          obj.properties.plantsCount = features.length;
          obj.properties.plantAreaInM2 = plantAreaInM2;
          const plantCanopySizePercent = calculatePlantCanopySizePercent(Number(cordonArea) || 0,
            Number(canopyArea) || 0);
          keys.forEach((key) => {
            const value = Number(d?.properties[key]);
            const rangeVal = getRange(value);

            obj.properties[`${key}_color`] = isValidText(value)
              ? interpolateRdYlGn(normalizeValue(value, rangeVal.min, rangeVal.max))
              : '#000'; // set black as the default color which has no data for the plant circle
            if (key === 'canopySize') {
              obj.properties.plantCanopyArea = d.properties?.canopyArea;
              obj.properties.canopySizeInPercent = plantCanopySizePercent * 100;
              obj.properties[`${key}_color`] = isValidText(value)
                ? interpolateSpectral(plantCanopySizePercent) : interpolateSpectral(0);
            }
          });
          return obj;
        });

        const tempObj = { type: 'FeatureCollection', features: changedFeatures };
        plantsData = tempObj;
      }
    });
    callBack(plantsData);
  });
}

// This will be refactor in future
export function plantsNavigateMap(selectedFeature) {
  if (selectedFeature === null) return null;
  const baseURL = 'https://www.google.com/maps/dir/?api=1&destination=';
  const { lat, lng } = selectedFeature?.data?.latLng;
  window.open(`${baseURL + lat},${lng}`, '_blank');
  return true;
}

export function renderSoilLayer(mapFeatureGroupRef) {
  if (mapFeatureGroupRef.current && mapFeatureGroupRef.current?.style?._loaded) {
    if (!mapFeatureGroupRef.current?.getSource('wms-soil-source')) {
      mapFeatureGroupRef.current.addSource('wms-soil-source', {
        type: 'raster',
        tiles: [
          'https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}',
        ],
        tileSize: 256,
      });
    }

    if (!mapFeatureGroupRef.current?.getLayer('wms-soil-layer')) {
      mapFeatureGroupRef.current.addLayer(
        {
          id: 'wms-soil-layer',
          type: 'raster',
          source: 'wms-soil-source',
          paint: {},
        },
      );
    }
  }
}

export function removeSoilLayer(mapFeatureGroupRef) {
  if (mapFeatureGroupRef?.current?.getLayer('wms-soil-layer')) mapFeatureGroupRef.current.removeLayer('wms-soil-layer');
  if (mapFeatureGroupRef?.current?.getSource('wms-soil-source')) mapFeatureGroupRef.current.removeSource('wms-soil-source');
}

export function clearImageryLayers(mapFeatureGroupRef, isSatellite) {
  for (let i = 0; i < 50; i += 1) {
    if (mapFeatureGroupRef?.current?.getLayer(`overlay${i}`)) mapFeatureGroupRef.current.removeLayer(`overlay${i}`);
    if (mapFeatureGroupRef?.current?.getSource(`satellite-imagery-${i}`)) mapFeatureGroupRef.current.removeSource(`satellite-imagery-${i}`);
    if (mapFeatureGroupRef?.current?.getLayer(`overlay-right-${i}`)) mapFeatureGroupRef.current.removeLayer(`overlay-right-${i}`);
    if (mapFeatureGroupRef?.current?.getSource(`satellite-imagery-right-${i}`)) mapFeatureGroupRef.current.removeSource(`satellite-imagery-right-${i}`);
    if (mapFeatureGroupRef?.current?.getLayer(`drone-overlay${i}`)) mapFeatureGroupRef.current.removeLayer(`drone-overlay${i}`);
    if (mapFeatureGroupRef?.current?.getSource(`drone-imagery-${i}`)) mapFeatureGroupRef.current.removeSource(`drone-imagery-${i}`);
    if (mapFeatureGroupRef?.current?.getLayer(`drone-overlay-${i}`)) mapFeatureGroupRef.current.removeLayer(`drone-overlay-${i}`);
    if (mapFeatureGroupRef?.current?.getLayer(`drone-overlay-right-${i}`)) mapFeatureGroupRef.current.removeLayer(`drone-overlay-right-${i}`);
    if (mapFeatureGroupRef?.current?.getSource(`drone-imagery-right-${i}`)) mapFeatureGroupRef.current.removeSource(`drone-imagery-right-${i}`);
  }
}

export function getBrowserZoomLevel() {
  // Get the screen width and height using window.innerWidth and window.innerHeight
  const screenWidth = window.innerWidth;

  // Get the actual device pixel ratio
  const { devicePixelRatio } = window;

  // Calculate the zoom level by dividing the screen width by the device pixel ratio
  const zoomLevel = Math.round((screenWidth / (screenWidth / devicePixelRatio)) * 100);

  return zoomLevel;
}

export function renderZoomToFixView(mapVar, items, isZoomed) {
  if (!items?.geometry) return;
  try {
    const [long, lat] = items?.geometry?.coordinates?.[0]?.[0] || [];

    const viewBox = {
      latitude: lat,
      longitude: long,
      zoom: 17,
    };
    const mapWidth = isMobile ? 350 : 360;
    const mapHeight = isMobile ? 250 : 780;
    const [minLng, minLat, maxLng, maxLat] = bbox(items);
    const webMercatorViewport = new WebMercatorViewport({
      ...viewBox,
      width: mapWidth,
      height: mapHeight,
    });
    const { longitude, latitude, zoom } = webMercatorViewport
      .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: {
          top: isMobile ? 80 : 30,
          bottom: isMobile ? 80 : 30,
          left: isMobile ? 100 : 70,
          right: isMobile ? 100 : 70,
        }
      });
    mapVar.setZoom(!isZoomed ? zoom - 1 : zoom);
    mapVar.setCenter([longitude, latitude]);
  } catch (error) {
    console.error('Error in fitBounds:', error);
  }
}

export function clearAllLayersInSource(mapVar, sourceId) {
  if (!mapVar) return;

  const style = mapVar?.getStyle();

  if (style && style.layers) {
    style.layers.forEach((layer, index) => {
      if (layer.source === sourceId) {
        mapVar.removeLayer(layer.id);
      }
      if (style.layers.length === (index + 1)) {
        if (mapVar.getSource(sourceId)) mapVar.removeSource(sourceId);
      }
    });
  }
}

export function generateCircleCoordinates(center, radius, numPoints) {
  const coordinates = [];
  const [centerLng, centerLat] = center;
  const angleIncrement = (2 * Math.PI) / numPoints;

  for (let i = 0; i < numPoints; i += 1) {
    const angle = angleIncrement * i;
    const x = Number(centerLng) + radius * Math.cos(angle);
    const y = Number(centerLat) + radius * Math.sin(angle);
    coordinates.push([x, y]);
  }

  return coordinates;
}

export function getBlockCenterCoordinates(block) {
  if (block?.geometry?.coordinates?.length) {
    try {
      const [long, lat] = block?.geometry?.coordinates?.[0]?.[0] || [];
      const viewBox = {
        latitude: lat,
        longitude: long,
        zoom: 17,
      };
      const mapWidth = isMobile ? 350 : 360;
      const mapHeight = isMobile ? 250 : 780;
      const [minLng, minLat, maxLng, maxLat] = bbox(block);
      const webMercatorViewport = new WebMercatorViewport({
        ...viewBox,
        width: mapWidth,
        height: mapHeight,
      });

      const { longitude, latitude, zoom } = webMercatorViewport
        .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: {
            top: isMobile ? 80 : 30,
            bottom: isMobile ? 80 : 30,
            left: isMobile ? 100 : 70,
            right: isMobile ? 100 : 70,
          }
        });
      return { coordinates: [latitude, longitude], zoom };
    } catch(error){
      console.error('Error in fitBounds:', error);
    }
  }
  return { coordinates: [0, 0] };
}

// To get updated anomaly data for the Imagery anomaly detail page
export function getUpdatedAnomalyGeoJson(anomalyData, isAnomaly, blocksGeoJSON,
  toggleZoom, blockId, tracking) {
  let anomalyGeoJSON;
  if (anomalyData && isAnomaly && blocksGeoJSON?.features) {
    anomalyGeoJSON = getLayerBounds(anomalyData);
    anomalyGeoJSON.customZoom = true;
    if (!toggleZoom) {
      tracking.track('Zoom Out Anomaly');
      const blockFeatures = blocksGeoJSON?.features?.filter((d) => d?.id === Number(blockId));
      const blockGEOJSON = {
        type: 'FeatureCollection',
        features: [...blockFeatures, anomalyGeoJSON],
      };
      anomalyGeoJSON = { ...anomalyGeoJSON, ...blockGEOJSON };
    }
  }
  return anomalyGeoJSON;
}

export function getSourceAndFeatures(mapVar, sourceId) {
  const source = mapVar.getSource(sourceId); // Replace with the actual source ID

  // Get the features from the source
  const { features } = source?._data || {};
  return { source, features };
}

export function setUpdatedDataToSource(source, newFeatures) {
  // Update the map source with the modified features
  source.setData({
    type: 'FeatureCollection',
    features: newFeatures,
  });
}

export function selectPlantsByClassifications(mapVar, selectedIds) {
  const { features, source } = getSourceAndFeatures(mapVar, 'point');
  const newFeatures = features.map((feature) => {
    const { id } = feature.properties;
    const obj = { ...feature };
    let isSelected = false;
    if (selectedIds.includes(id)) isSelected = true;
    obj.properties.selected = isSelected ? 'true' : 'false';
    return obj;
  });

  setUpdatedDataToSource(source, newFeatures);
}

// Function to remove property from object by value. Not the key.
export function deletePropertyByValue(obj, targetValue) {
  const tempObj = { ...obj };
  Object.keys(tempObj).forEach((key) => {
    if (tempObj[key] === targetValue) {
      delete tempObj[key];
    }
  });
  return tempObj;
}

let currBlockId;
export function getPlantsData(blockId, plantAreaInM2, areaUnit, dispatchMap) {
  dispatchMap({ type: 'setIsPlantLoading', payload: true });
  if (!isEqual(currBlockId, blockId) && currBlockId) {
    dispatchMap({
      type: 'setPlantsData',
      payload: null,
    });
  }
  currBlockId = blockId;
  getPlantsDataForBlockId(blockId, plantAreaInM2, areaUnit, (response) => {
    // To avoid set the previous block response to current block state
    if (response && isEqual(blockId, currBlockId)) {
      dispatchMap({
        type: 'setPlantsData',
        payload: response,
      });
    }
    dispatchMap({ type: 'setIsPlantLoading', payload: false });
  });
}

export function setBlockCropType(blockObj, callback) {
  blockApi.setCropType(blockObj).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}

export function toTitleCase(textStr) {
  if (textStr && typeof textStr === 'string') {
    return textStr.toLowerCase().split(' ').map((word) => word.charAt(0)
      ?.toUpperCase() + word.slice(1)).join(' ');
  }
  return textStr;
}

export function getCanopyAreaUnit(lengthFormat) {
  const areaUnitLabel = canopySizeUnitForLength[lengthFormat];
  return areaUnitLabel;
}

export function roundToDecimalPlaces(value, decimalPlaces = 1) {
  if (isValidText(value)) return (Math.abs(Number(value)))?.toFixed(decimalPlaces);
  return value;
}

export function summaryButton(dispatchMap, type) {
  return (
    <Button
      size="large"
      type="primary"
      title="Summary"
      icon={<IconTitle iconName={type === 'ranch' ? 'ranch' : 'block'} />}
      className="margin-bottom-10 summary-button"
      onClick={() => {
        dispatchMap({
          type: 'setIsBottomSheetVisible',
          payload: true,
        });
      }}
    />
  );
}

export function detachEvents(mapVar, eventObj) {
  if (mapVar && !mapVar?.leafletElement) {
    Object.keys(eventObj).forEach((eventName) => {
      if (eventObj[eventName] && mapVar) {
        mapVar.off(eventName, eventObj[eventName]);
      }
    });
  }
}

export function clearAllClusterCircles(mapRef) {
  if (mapRef?.getLayer) {
    // Iterate through up to 500 cluster circles to remove them
    const numbersArr = Array.from({ length: 500 }, (_, i) => i + 1);
    numbersArr.forEach((id) => {
      const clusterSourceId = `cluster-circle-${id}`;
      const clusterCountLayerId = `cluster-circle-count-${id}`;
      const clusterCountLabelId= `cluster-label-${id}`;
      if (mapRef?.getLayer(clusterCountLayerId)) mapRef.removeLayer(clusterCountLayerId);
      if (mapRef?.getLayer(clusterCountLabelId)) mapRef.removeLayer(clusterCountLabelId);
      if (mapRef?.getSource(clusterSourceId)) mapRef.removeSource(clusterSourceId);
    });
  }
}

export function hideMarkersById(ranchId, removeAll) {
  if (!ranchId) return;

  try {
    if (removeAll) {
      const elements = document.querySelectorAll(`[data-test-id="${ranchId}"]`);
      if (elements?.length) {
        elements.forEach((e) => e.remove());
      }
    } else {
      const element = document.querySelector(`[data-test-id="${ranchId}"]`);
      if (element) {
        element.remove();
      }
    }
  } catch (error) {
    console.error('Error in hideMarkersById', error);
  }
}
