import moment from 'moment';


/**
 * Map 0, 1 & -1 to Unknown, Open and Closed respectively.
 */
export const getValveState = (value) => {
  switch (value) {
    case 0: return 'Unknown';
    case 1: return 'Open';
    case -1: return 'Closed';
    default: return '';
  }
};

export const getSeriesData = (data) => {
  if (data && data.length) {
    try {
      return data.map((d) => {
        let yValue = d[1];
        /**
         * The data from api has 0 for closed and -1 for unknown.
         * Chart thus keeps the unknown below closed on y axis.
         * We want unknown between closed and open and therefore we are swapping their values.
         */
        if (yValue !== 1) {
          yValue = yValue === -1 ? 0 : -1;
        }
        return {
          x: d[0],
          y: yValue,
        };
      });
    } catch {
      return [];
    }
  }

  return [];
};

export const generateValveOption = (response) => {
  const { state } = response || {};
  const stateData = state && state[0];
  const { data } = stateData || {};
  const seriesData = getSeriesData(data);
  const options = {
    title: {
      text: 'Valve state',
    },
    xAxis: {
      title: {
        text: '',
      },
      type: 'datetime',
      tickInterval: 86400000, // 1 day
    },
    yAxis: {
      title: {
        text: '',
      },
      lineWidth: 1,
      tickInterval: 1,
      labels: {
        formatter() {
          return getValveState(this.value);
        },
      },
      min: -1,
      max: 1,
    },
    series: [{
      showInLegend: false,
      data: seriesData,
      step: 'right',
    }],
    tooltip: {
      formatter() {
        return `The valve state on <b>${moment(this.x).format('Do MMM, YY, h:mma')}</b> is <b>${getValveState(this.y)}</b>`;
      },
    },
    plotOptions: {
      line: {
        turboThreshold: 0,
      },
    },
  };
  return options;
};
