/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import { Button, notification } from 'antd';
import { FaMap, FaCopy } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  plantsNavigateMap, isValidText, getCanopyAreaUnit, roundToDecimalPlaces,
} from '../../../../helper/mapHelper';

const {
  selectUserAreaFormat,
  selectUserDepthFormat,
  selectUserLengthFormat,
} = selectors;

const { useUnits } = hooks;

const labelText = {
  square_meters: 'Square meters',
  square_feet: 'Square feet',
};

function renderText(value, unit) {
  let changedText = 'Not Available';
  if (isValidText(value) && unit) changedText = `${value} ${unit}`;
  else if (isValidText(value) && !unit) changedText = value;
  return changedText;
}

const PlantsDetail = ({ plantsData }) => {
  const {
    plantIndex, plantRow, date,
    ndvi, ndre, cropStress, canopyArea, irrigation, standingWater,
    evaporationspirationActual, healthClassification,
    canopySizeInPercent,
  } = plantsData?.data || {};
  const { t } = useTranslation();
  const getUserUnits = useUnits();

  const areaFormat = useSelector(selectUserAreaFormat);
  const lengthFormat = useSelector(selectUserLengthFormat);
  const depthFormat = useSelector(selectUserDepthFormat);
  const rowAndNumber = t(`Row ${plantRow} Plant ${plantIndex}`);
  const { lat, lng } = plantsData?.latLng || {};

  const convertTo = getCanopyAreaUnit(lengthFormat);

  // Canopy Area value unit that from API response in 'square_meters'.
  const { value: convertedCanopySize } = getUserUnits(Number(canopyArea),
    'square_meters', undefined, {}, convertTo);

  function getCustomStyle(type) {
    let color = '#000';
    let textColor = 'white';
    if (type === 'missing') { color = 'white'; textColor = 'black'; }
    if (type === 'dead') color = 'red';
    if (type === 'undeveloped') { color = 'yellow'; textColor = 'black'; }
    if (type === 'normal') { color = '#3887be'; textColor = 'white'; }
    return {
      background: color,
      color: textColor,
      borderRadius: '5px',
      border: '1px solid black',
      textAlign: 'center',
      textTransform: 'capitalize',
      fontWeight: 600,
      width: 'fit-content',
      padding: '5px',
    };
  }

  return (
    <div className="plant-details-section">
      <div className="sensor-detail-header map-desktop-sensor-header-detail">
        <div className="flex-column">
          <span className="sensor-header-type">{t('Row and Number')}</span>
          <span className="plants-header-text">{rowAndNumber}</span>
          <br />
          <span className="sensor-header-type">{t('Location')}</span>
          <div className="flex-row lat-lng-button">
            <Button
              className="plants-coordinates flex-center"
              icon={<FaMap />}
              onClick={() => {
                const latLngObj = {
                  data: {
                    latLng: {
                      lat,
                      lng,
                    },
                  },
                };
                plantsNavigateMap(latLngObj);
              }}
              block
            >
              {`Lat ${lat?.toFixed(5)} Long ${lng?.toFixed(5)}`}
            </Button>
            <Button
              className="plants-coordinates"
              icon={<FaCopy className="clip-copy-button" />}
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify({ lat, lng }));
                notification.success({
                  message: t('Copied to clipboard!'),
                });
              }}
            />
          </div>

          <br />
          <span className="sensor-header-type">{t('Date measured')}</span>
          <span className="plants-header-text">{moment(date).format('MMMM, DD, YYYY')}</span>
          <br />
          <span className="sensor-header-type">{t('Plants Health Classification')}</span>
          <span
            className="plants-header-text"
            style={getCustomStyle(healthClassification)}
          >
            {healthClassification}
          </span>
          <br />
          <span className="sensor-header-type">{t('NDVI')}</span>
          <span className="plants-header-text">{renderText(ndvi)}</span>
          <br />
          <span className="sensor-header-type">{t('NDRE')}</span>
          <span className="plants-header-text">{renderText(ndre)}</span>
          <br />
          <span className="sensor-header-type">{t('Canopy Area')}</span>
          <span className="plants-header-text">
            {renderText(roundToDecimalPlaces(convertedCanopySize),
              labelText[getCanopyAreaUnit(lengthFormat, areaFormat)])}
          </span>
          <br />
          <span className="sensor-header-type">{t('Canopy Cover (%)')}</span>
          <span className="plants-header-text">
            {`${(canopySizeInPercent || 0)?.toFixed(1)}%`}
          </span>
          <br />
          {/* This will be uncommented in future once the values are available */}
          {/* <span className="sensor-header-type">{t('Irrigation Metric')}</span>
          <span className="plants-header-text">{renderText(irrigation)}</span>
          <br />
          <span className="sensor-header-type">{t('Standing Water')}</span>
          <span className="plants-header-text">
            {renderText(standingWater, '% standing water')}
          </span>
          <br />
          <span className="sensor-header-type">{t('ETA')}</span>
          <span className="plants-header-text">
            {renderText(evaporationspirationActual, depthFormat)}
          </span> */}
        </div>
      </div>
    </div>
  );
};

PlantsDetail.propTypes = {
  plantsData: PropTypes.shape({
    data: PropTypes.shape({
      row: PropTypes.number,
      index: PropTypes.number,
    }),
    latLng: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
};

export default PlantsDetail;
