import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Space, Alert, Button } from 'antd';

export default function FallbackRender(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { error } = props || {};
  useEffect(() => {
    // sentry error track here.
    Sentry.captureException(error);
  }, [error]);

  const customMessage = 'Something unexpected occurred. Our developers are working on it';
  function onButtonClick() {
    history.push('/recommendation');
    window.location.reload();
  }

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      <Alert
        message={t('Error')}
        showIcon
        description={t(customMessage)}
        type="error"
        closeText={(
          <Button size="large" type="primary">
            {t('Reload')}
          </Button>
        )}
        onClose={() => onButtonClick()}
        closable
      />
    </Space>
  );
}

FallbackRender.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};
