import React, { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import { AdminRoute, PrivateRoute } from 'farmx-web-ui';
import { authService } from 'farmx-api';
import { hooks, actions } from 'farmx-redux-core';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import './helpers.css';
import './MobileApp.less';
import { useDispatch } from 'react-redux';
import { AppTemplate } from './components/AppTemplate';
import { UserNotificationDropdown } from '../stream';
import Page404 from './Mobile404';
import { ROUTES, FARMHAND_ROUTES } from '../app/routes';
import { BottomTabBar } from './components/BottomTabBar';
import { isMobile } from '../../utils/detectDevice';
import { useTracking } from '../../helper/mixpanel';
import { useLanguageSetting } from '../../useLanguageSetting';
import FallbackRender from './components/FallbackRender';

const {
  useSetURLFromState,
  useSetStateFromURL,
} = hooks;

const { setLoginUserInfo, loadNewFarmData } = actions;

const pathBelongingToTab = {
  recommendation: 'recommendation',
  map: 'map',
  list: 'list',
  schedule: 'schedule',
  control: 'control',
};

const myFallback = (props) => <FallbackRender {...props} />;

export default function MobileApp() {
  const [userInfo, setUserInfo] = useState();
  const [routes, setRoutes] = useState(ROUTES);
  const location = useLocation();
  const { search, pathname, state } = location;
  const urlSearch = useRef(search);
  const tracking = useTracking();
  const dispatch = useDispatch();

  // possible values "default" and "farmhand"
  const appMode = userInfo?.appMode;

  useLanguageSetting();
  useSetStateFromURL(urlSearch);
  useSetURLFromState(urlSearch, search, pathname, null, state);

  useEffect(() => {
    authService.getUserInfo().then(setUserInfo);
  }, [setUserInfo]);

  // To store the user info to redux-state
  useEffect(() => {
    dispatch(setLoginUserInfo(userInfo));
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(loadNewFarmData());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (appMode === 'farmhand') {
      setRoutes(FARMHAND_ROUTES);
    } else {
      setRoutes(ROUTES);
    }
  }, [appMode]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const [selectedTabPath, setSelectedTabPath] = useState('');
  useEffect(() => {
    if (pathname === '/') {
      return;
    }
    const pathnameParts = pathname.split('/');
    if (!pathBelongingToTab[pathnameParts[1]]) {
      setSelectedTabPath('');
      return;
    }
    setSelectedTabPath(pathnameParts[1]);
  }, [pathname, selectedTabPath]);

  // To set the user details
  useEffect(() => {
    if (tracking && userInfo) tracking.setUserInfo(userInfo);
  }, [tracking, userInfo]);

  return (
    <AppTemplate
      alarms={<UserNotificationDropdown userInfo={userInfo} />}
      theme="light"
      appMode={appMode}
    >
      <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <div className="main-content">
          <Switch>
            {appMode === 'farmhand' && (
            <Route exact path="/">
              <Redirect to={{ pathname: '/farmhand/ranches' }} />
            </Route>
            )}
            {appMode === 'default' && (
            <Route exact path="/">
              <Redirect to={{ pathname: '/recommendation' }} />
            </Route>
            )}
            {[...Object.values(routes)]
              .map(({
                component,
                exact,
                key,
                path,
                adminRequired
              }) => (
                adminRequired ? (
                  <AdminRoute
                    authService={authService}
                    component={component}
                    path={path}
                    key={key}
                  />
                )
                  : (
                    <PrivateRoute
                      adminRequired={false}
                      authService={authService}
                      component={component}
                      exact={exact}
                      key={key}
                      path={path}
                    />
                  )
              ))}
            <PrivateRoute
              adminRequired={false}
              authService={authService}
              component={Page404}
            />
          </Switch>
        </div>
        {isMobile && (appMode === 'default') && (
        <BottomTabBar
          pathname={pathname}
          selectedTabPath={selectedTabPath}
          setSelectedTabPath={setSelectedTabPath}
        />
        )}
        <AddToHomeScreen appId="farmx-web-mobile" />
      </Sentry.ErrorBoundary>
    </AppTemplate>
  );
}
