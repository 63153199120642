import { useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { notifyError, getSelectedDateRange } from '../components/map/components/utils';
import {
  getAvailableSatelliteData, getSatelliteDataByDataTypeAndDate,
  getAvailableDroneData, getDroneImageryTilesData,
} from './satelliteImageryHelper';
import { droneDataType } from '../components/map/components/satelliteimagery/SatelliteImagery';

export const useRanchBlockPreference = (selectedRanchId, selectedBlockId, dispatchSatellite) => {
  useEffect(() => {
    // Set ranch/block preference to local reducer
    if (selectedBlockId?.payload?.length) {
      const [bId] = selectedBlockId.payload;
      dispatchSatellite({ type: 'setSelectedBlockId', payload: bId });
    } else {
      dispatchSatellite({ type: 'setShowMap3D', payload: false });
      dispatchSatellite({ type: 'setSelectedBlockId', payload: undefined });
    }
    if (selectedRanchId?.payload?.length) {
      const [rId] = selectedRanchId.payload;
      dispatchSatellite({ type: 'setSelectedRanchId', payload: rId });
    } else {
      dispatchSatellite({ type: 'setSelectedRanchId', payload: undefined });
    }
  }, [selectedRanchId, selectedBlockId, dispatchSatellite]);
};

export const useSatelliteImagery = (selectedRanchId,
  selectedBlockId, latestReload, callApi, dispatchSatellite) => {
  useEffect(() => {
    if (!callApi) return;

    // get satellite imagery using local reducer
    let type;
    let id;

    if (selectedBlockId) {
      type = 'block';
      id = selectedBlockId;
    } else if (selectedRanchId) {
      type = 'ranch';
      id = selectedRanchId;
    }

    if (type && id) {
      (async () => {
        try {
          const data = await getAvailableSatelliteData(type, id);
          const dataObj = {};
          const dataArr = [];
          const uniqueDataTypes = {};
          data.forEach((d) => {
            const dateString = moment(d.date * 1000).format('YYYY-MM-DD');
            const momentDate = moment(dateString, 'YYYY-MM-DD');
            if (!dataObj[dateString]) {
              dataObj[dateString] = { dateString, momentDate };
              d.datatypes.forEach((dt) => {
                dataObj[dateString][dt] = d.date;
                uniqueDataTypes[dt] = dt;
              });
            } else {
              d.datatypes.forEach((dt) => {
                uniqueDataTypes[dt] = dt;
                if (!dataObj[dateString][dt]) {
                  dataObj[dateString][dt] = d.date;
                } else if (Number(dataObj[dateString][dt]) < Number(d.date)) {
                  dataObj[dateString][dt] = d.date;
                }
              });
            }
          });

          Object.keys(dataObj).forEach((k) => {
            dataArr.push(dataObj[k]);
          });

          const availableSatelliteTypes = Object.keys(uniqueDataTypes)
            .filter((dt) => dt.startsWith('satellite:'))
            .map((k) => k.split(':')[1]);

          const availableAerialTypes = Object.keys(uniqueDataTypes)
            .filter((dt) => dt.startsWith('aerial:'))
            .map((k) => k.split(':')[1]);

          const availableDataSources = [];
          if (availableSatelliteTypes.length) {
            availableDataSources.push('satellite');
          }

          if (availableAerialTypes.length) {
            availableDataSources.push('aerial');
          }

          dispatchSatellite({
            type: 'setSatelliteDates',
            payload: {
              type,
              id: type === 'ranch'
                ? selectedRanchId
                : selectedBlockId,
              datesObj: dataObj,
              datesArray: dataArr,
              uniqueDataTypes,
              availableSatelliteTypes,
              availableAerialTypes,
              availableDataSources,
            },
          });
        } catch (error) {
          notifyError(error.message);
        }
      })().catch();
    }
  }, [selectedRanchId, selectedBlockId, latestReload, dispatchSatellite, callApi]);
};

export const useSatelliteDataDatesAndTypes = (satelliteDates,
  selectedSatelliteDataType, selectedSatelliteDataSource, dispatchSatellite) => {
  const dataSourceRef = useRef(undefined);
  useEffect(() => {
    // prepare satellite data dates and types using local reducer
    dataSourceRef.current = selectedSatelliteDataSource;
    dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
    if (satelliteDates) { // this is initial or there are no dates
      const selectedSatelliteDatesFilteredSkeleton = {
        id: satelliteDates.id,
        type: satelliteDates.type,
        datesObj: {},
        datesArray: [],
      };

      if (!satelliteDates.availableDataSources.length) {
        notifyError('No data sources for satellite imagery available');
        dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
        return;
      }


      if (
        selectedSatelliteDataSource === 'satellite'
          && !satelliteDates.availableSatelliteTypes
            .includes(selectedSatelliteDataType)
      ) {
        if (!satelliteDates.availableSatelliteTypes.length) {
          notifyError('No available satellite imagery types');
          dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
          return;
        }
        dispatchSatellite({
          type: 'setSelectedSatelliteDataType',
          payload: 'NDVI', // To set the default option - SWB-1345
        });
        return;
      }

      if (
        selectedSatelliteDataSource === 'aerial'
          && !satelliteDates.availableAerialTypes
            .includes(selectedSatelliteDataType)
      ) {
        if (!satelliteDates.availableAerialTypes.length) {
          notifyError('No available satellite imagery types');
          dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });
          return;
        }
        dispatchSatellite({
          type: 'setSelectedSatelliteDataType',
          payload: 'NDVI', // To set the default option - SWB-1345
        });
        return;
      }

      const selectedSatelliteDatesFiltered = satelliteDates.datesArray
        .reduce((acc, curr) => {
          const dataSourceAndType = `${selectedSatelliteDataSource}:${selectedSatelliteDataType}`;
          if (!curr[dataSourceAndType]) {
            return acc;
          }

          const dateObj = {};
          dateObj[dataSourceAndType] = curr[dataSourceAndType];
          dateObj.time = curr[dataSourceAndType];
          dateObj.dateString = curr.dateString;
          dateObj.momentDate = curr.momentDate;

          dateObj.dataSource = selectedSatelliteDataSource;
          dateObj.dataType = selectedSatelliteDataType;

          dateObj.id = satelliteDates.id;
          dateObj.type = satelliteDates.type;

          acc.datesArray.push(cloneDeep(dateObj));
          acc.datesObj[curr.dateString] = cloneDeep(dateObj);
          return acc;
        }, selectedSatelliteDatesFilteredSkeleton);

      if (!selectedSatelliteDatesFiltered.datesArray.length) {
        dispatchSatellite({ type: 'setSelectedSatelliteDatesFiltered', payload: undefined });

        return;
      }

      dispatchSatellite({
        type: 'setSelectedSatelliteDatesFiltered',
        payload: selectedSatelliteDatesFiltered,
      });
    }
  }, [satelliteDates, selectedSatelliteDataType, selectedSatelliteDataSource, dispatchSatellite]);
};

export const useSatelliteDateRangePreparation = (selectedSatelliteDatesFiltered,
  selectedSatelliteDate, selectedSatelliteDatesDataRange, selectedSatelliteDataType,
  selectedSatelliteDataSource, ranchId, blockId, dispatchSatellite, dispatchSatelliteLeft,
  dispatchSatelliteRight) => {
  const dispatchSatelliteArg = dispatchSatelliteLeft || dispatchSatelliteRight;
  const dateRange = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dateString) : [];
  const dataSource = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dataSource) : [];
  const dataType = selectedSatelliteDatesDataRange
    ? selectedSatelliteDatesDataRange.map((d) => d.dataType) : [];

  const updateDateRangeWithData = ((!dateRange.includes(moment(selectedSatelliteDate)
    .format('YYYY-MM-DD')) || !dataSource.includes(selectedSatelliteDataSource)
    || !dataType.includes(selectedSatelliteDataType))
    || ((selectedSatelliteDatesDataRange && selectedSatelliteDatesDataRange[0].id !== blockId)
    && (selectedSatelliteDatesDataRange && selectedSatelliteDatesDataRange[0].id !== ranchId)));

  useEffect(() => {
    if (!selectedSatelliteDatesFiltered) {
      return;
    }

    if (updateDateRangeWithData) {
      const idxMax = selectedSatelliteDatesFiltered.datesArray.length - 1;
      if (!selectedSatelliteDate) {
        const selectedSatelliteDateLocal = selectedSatelliteDatesFiltered
          .datesArray[idxMax].momentDate;
        dispatchSatellite({
          type: 'setSelectedSatelliteDate',
          payload: selectedSatelliteDateLocal,
        });
        if (dispatchSatelliteArg) {
          dispatchSatelliteArg({
            type: 'setSelectedSatelliteDate',
            payload: selectedSatelliteDateLocal,
          });
        }
        return;
      }

      let idxSelected = -1;

      for (let i = idxMax; i > -1; i -= 1) {
        const selectedDateString = selectedSatelliteDate.format('YYYY-MM-DD');
        const currDateString = selectedSatelliteDatesFiltered.datesArray[i].dateString;
        if (selectedDateString === currDateString) {
          idxSelected = i;

          break;
        }
      }

      if (idxSelected < 0) {
        const selectedSatelliteDateLocal = selectedSatelliteDatesFiltered
          .datesArray[idxMax].momentDate;
        dispatchSatellite({
          type: 'setSelectedSatelliteDate',
          payload: selectedSatelliteDateLocal,
        });
        if (dispatchSatelliteArg) {
          dispatchSatelliteArg({
            type: 'setSelectedSatelliteDate',
            payload: selectedSatelliteDateLocal,
          });
        }
        return;
      }

      const selectedSatelliteDatesDataRangeLocal = getSelectedDateRange(
        selectedSatelliteDatesFiltered.datesArray,
        idxSelected,
      );

      dispatchSatellite({
        type: 'setSelectedSatelliteDatesDataRange',
        payload: selectedSatelliteDatesDataRangeLocal,
      });
      if (dispatchSatelliteArg) {
        dispatchSatelliteArg({
          type: 'setSelectedSatelliteDatesDataRange',
          payload: selectedSatelliteDatesDataRangeLocal,
        });
      }
    }
  }, [updateDateRangeWithData, dispatchSatellite, selectedSatelliteDate,
    selectedSatelliteDatesFiltered, dispatchSatelliteArg]);
};

export const useDefaultSelectedImageryData = (dateRangeWithDisplayData, selectedImageryData,
  selectedSatelliteDate, dispatchSatellite, dispatchSatelliteLeft, dispatchSatelliteRight) => {
  const dispatchSatelliteArg = dispatchSatelliteLeft || dispatchSatelliteRight;
  useEffect(() => {
    const displayData = dateRangeWithDisplayData;
    const dateString = moment(selectedSatelliteDate).format('YYYY-MM-DD');

    const imageryData = selectedImageryData;
    if (displayData && displayData.length) {
      const filteredData = displayData.filter((d) => d && d.dateString === dateString);
      if (filteredData.length && !isEqual(filteredData[0].data, imageryData.data)
             && imageryData.visible) {
        dispatchSatellite({
          type: 'setSelectedImageryData',
          payload: {
            visible: true,
            data: filteredData[0].data,
          },
        });
        if (dispatchSatelliteArg) {
          dispatchSatelliteArg({
            type: 'setSelectedImageryData',
            payload: {
              visible: true,
              data: filteredData[0].data,
            },
          });
        }
      }
    }
  }, [dateRangeWithDisplayData, dispatchSatellite, selectedImageryData,
    selectedSatelliteDate, dispatchSatelliteArg]);
};

// Time conversion logic to render imagery data
function unixOffset(date) {
  let result;
  if (date) {
    // 1000: Number to add milliseconds to Epoch by multiplying Epoch with 1000
    const momentDate = moment(Number(date) * 1000);
    result = momentDate.unix() + (momentDate.utcOffset() * 60);
  }

  return result;
}

export const useRequestSatelliteData = (selectedSatelliteDatesDataRange,
  callApi, isSatellite, dispatchSatellite, dispatchSatelliteLeft, dispatchSatelliteRight) => {
  const dispatchSatelliteArg = dispatchSatelliteLeft || dispatchSatelliteRight;
  useEffect(() => {
    if (!callApi || !isSatellite) return;

    // request satellite data
    if (!selectedSatelliteDatesDataRange) {
      dispatchSatellite({ type: 'setDateRangeWithDisplayData', payload: undefined });

      return;
    }

    if (!selectedSatelliteDatesDataRange.length) {
      dispatchSatellite({ type: 'setDateRangeWithDisplayData', payload: undefined });

      return;
    }

    const dateRangeWithDisplayData = cloneDeep(selectedSatelliteDatesDataRange);
    const satelliteRequests = [];
    const satelliteRequestDates = [];
    selectedSatelliteDatesDataRange.forEach((dr) => {
      const {
        id,
        type,
        dataType,
        time,
      } = dr;
      satelliteRequestDates.push(unixOffset(time));
      satelliteRequests.push(getSatelliteDataByDataTypeAndDate(type, id, dataType, time));
    });

    Promise.allSettled(satelliteRequests)
      .then((results) => {
        results.forEach((r, index) => {
          if (r.status === 'fulfilled') {
            if (r?.value?.data?.length) {
              const dateString = moment(Number(satelliteRequestDates[index]) * 1000)
                .format('YYYY-MM-DD');
              for (let i = 0; i < dateRangeWithDisplayData.length; i += 1) {
                if (dateRangeWithDisplayData[i].dateString === dateString) {
                  dateRangeWithDisplayData[i].data = r.value.data;

                  break;
                }
              }
            }
          }
        });

        dispatchSatellite({
          type: 'setDateRangeWithDisplayData',
          payload: dateRangeWithDisplayData,
        });
        if (dispatchSatelliteArg) {
          dispatchSatelliteArg({
            type: 'setDateRangeWithDisplayData',
            payload: dateRangeWithDisplayData,
          });
        }
      });
  }, [callApi, dispatchSatellite, selectedSatelliteDatesDataRange,
    isSatellite, dispatchSatelliteArg]);
};

export const useRequestDroneDate = (ranchId, isSatellite, selectedDroneDate, blockIdStr,
  dispatchSatellite, isRecommendation, dispatchSatelliteLeft, dispatchSatelliteRight) => {
  const dispatchSatelliteArg = dispatchSatelliteLeft || dispatchSatelliteRight;
  useEffect(() => {
    if (isSatellite || selectedDroneDate) return;

    // To show spinner when drone imagery is loading
    dispatchSatellite({
      type: 'setIsLoading',
      payload: true,
    });
    const data = [getAvailableDroneData(ranchId)];
    Promise.allSettled(data).then((results) => {
      results.forEach((r) => {
        const availableDroneData = r?.value?.data;
        if (!availableDroneData) return;
        if (!isRecommendation) {
          dispatchSatellite({
            type: 'setAvailableDroneData',
            payload: availableDroneData,
          });
          if (dispatchSatelliteArg) {
            dispatchSatelliteArg({
              type: 'setAvailableDroneData',
              payload: availableDroneData,
            });
          }
        }
        const availableDates = availableDroneData?.map((d) => {
          const obj = {
            ...d,
            momentDate: moment(d?.imageryCaptureDateTime),
            dateString: d?.imageryCaptureDateTime,
            dataSource: 'drone',
          };
          return obj;
        });

        const availableDateObj = availableDates.reduce((a, b) => {
          const obj = { ...a };
          const dateStr = moment(b.dateString).format('YYYY-MM-DD');
          obj[dateStr] = b;
          return obj;
        }, {});

        const availableDroneDates = { datesObj: availableDateObj, datesArray: availableDates, type: 'drone' };

        let dateKey;
        if (blockIdStr) {
          const filteredDates = availableDates.filter((d) => d?.blockId === blockIdStr);
          if (filteredDates?.length) {
            dateKey = moment(filteredDates[0]?.momentDate).format('YYYY-MM-DD');
          }
        }
        const selectedDroneDateLocal = availableDroneDates.datesObj[dateKey]?.momentDate;

        if (dateKey) {
          dispatchSatellite({
            type: 'setAvailableDroneDates',
            payload: availableDroneDates,
          });

          // To hide spinner when drone imagery is loaded
          dispatchSatellite({
            type: 'setIsLoading',
            payload: false,
          });

          if (!isRecommendation) {
            dispatchSatellite({
              type: 'setAvailableDroneDataTypes',
              payload: Object.keys(droneDataType),
            });
            if (dispatchSatelliteArg) {
              dispatchSatelliteArg({
                type: 'setAvailableDroneDataTypes',
                payload: Object.keys(droneDataType),
              });
            }
          }

          dispatchSatellite({
            type: 'setSelectedDroneDate',
            payload: selectedDroneDateLocal,
          });

          dispatchSatellite({
            type: 'setSelectedDroneData',
            payload: { visible: true, data: availableDroneDates.datesObj[dateKey] },
          });
          if (dispatchSatelliteArg) {
            dispatchSatelliteArg({
              type: 'setAvailableDroneDates',
              payload: availableDroneDates,
            });

            dispatchSatelliteArg({
              type: 'setSelectedDroneDate',
              payload: selectedDroneDateLocal,
            });

            dispatchSatelliteArg({
              type: 'setSelectedDroneData',
              payload: { visible: true, data: availableDroneDates.datesObj[dateKey] },
            });
          }
        }
      });
    }).then(() => {
      // To hide spinner when drone imagery is not available
      dispatchSatellite({
        type: 'setIsLoading',
        payload: false,
      });
    }).catch(() => {
      // To hide spinner if any error
      dispatchSatellite({
        type: 'setIsLoading',
        payload: false,
      });
    });
  }, [blockIdStr, dispatchSatellite, isRecommendation, isSatellite,
    ranchId, selectedDroneDate, dispatchSatelliteArg]);
};

export const useDroneImageryDataForSelectedDate = (ranchId, selectedDroneDataType,
  selectedDroneData, dispatchSatellite, dispatchSatelliteLeft, dispatchSatelliteRight) => {
  const dispatchSatelliteArg = dispatchSatelliteLeft || dispatchSatelliteRight;
  const { imageryTaskId, imageryProjectId, imageryAssets } = selectedDroneData?.data || {};

  useEffect(() => {
    if (!selectedDroneData?.data) return;
    if (!imageryTaskId || !imageryProjectId) return;

    const dsm = selectedDroneDataType === 'NDSM' ? 'dsm' : null;
    const defaultAssetType = 'orthophoto';
    const availableAssetTypes = imageryAssets.map((d) => d?.imageryType);

    // To get the asset type based on the drone imagery type selection
    let assetType;
    if (availableAssetTypes?.includes('dsm') && availableAssetTypes?.includes('chm')
       && selectedDroneDataType === 'NDSM') {
      assetType = 'chm';
    } else {
      const [filteredAssetType] = imageryAssets.filter((d) => (d?.downloadURL?.indexOf(`/${dsm}.tif`) !== -1
      || d?.downloadURL?.indexOf(`/${selectedDroneDataType?.toLowerCase()}.tif`) !== -1))
        ?.map((d) => d?.imageryType);
      assetType = filteredAssetType;
    }

    // To show spinner when drone imagery is loading
    dispatchSatellite({
      type: 'setIsLoading',
      payload: true,
    });
    getDroneImageryTilesData(assetType || defaultAssetType, ranchId,
      imageryTaskId, imageryProjectId).then((result) => {
      dispatchSatellite({
        type: 'setSelectedDroneTilesData',
        payload: result?.data,
      });
      dispatchSatellite({
        type: 'setSelectedDroneImagery',
        payload: { visible: true, data: [result?.data] },
      });

      // To hide spinner when drone imagery is loaded
      dispatchSatellite({
        type: 'setIsLoading',
        payload: false,
      });
      if (dispatchSatelliteArg) {
        dispatchSatelliteArg({
          type: 'setSelectedDroneTilesData',
          payload: result?.data,
        });
        dispatchSatelliteArg({
          type: 'setSelectedDroneImagery',
          payload: { visible: true, data: [result?.data] },
        });
      }
    });
  }, [dispatchSatellite, imageryProjectId, imageryTaskId, ranchId,
    selectedDroneData, selectedDroneDataType, imageryAssets, dispatchSatelliteArg]);
};
