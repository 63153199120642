import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  Select,
  DatePicker as DatePickerDate,
  Input,
  Switch,
  notification,
  Alert,
} from 'antd';
import {
  DatePicker as DatePickerTime,
} from 'antd-mobile-v2';
import { useSelector, useDispatch } from 'react-redux';
import { hooks, selectors, actions } from 'farmx-redux-core';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import enUs from 'antd-mobile-v2/lib/date-picker/locale/en_US';
import { BlockSelect, Slider as CircularSlider } from 'farmx-web-ui';
import { scheduleApi } from 'farmx-api';
import { WeekDays } from './components/WeekDays';
import { CardLayout } from '../components/CardLayout';
import { blockTagRender, useColorsByBlock } from './blockTagRender';
import { ScheduleEditOptionsModal } from './ScheduleEditOptionsModal';
import { useScheduleUpdate } from './useScheduleUpdate';
import './Schedule.less';
import {
  getGallonsValue,
  getClosestMinute, showWarningCard, getIrrigationStateCutoffs,
  getIrrigationStateColors, parseDurationStr,
  useScheduledValveForIrrigationRatio,
} from '../../../helper/scheduleHelper';
import WarningCard from '../components/WarningCard';
import { getBlockArea } from '../../../helper/block';
import HelpButtonWithZendeskHelpModal from '../components/HelpButtonWithZendeskHelpModal';
import { SEQUENTIAL_IRRIGATION } from '../../../helper/articleIds';
import { recommendationAnomalyFilter } from '../../../helper/common';
import { useUserTimeFormat } from '../../../helper/commonHooks';

const minutesAllowedFromNow = 0;
const minDurationMinutes = 15;
const minStep = 1;
const HOURS_PER_DAY = 24;

const initializeStartDate = () => moment().startOf('day').add(moment().hour() + 1, 'hours');
const startDateTimeAllowed = (min) => (new Date((new Date()).getTime() + (min * 60000)));
const disabledDate = (current) => false;
// current.clone().startOf('day').clone().toDate()
//  .getTime() < moment().startOf('day').toDate().getTime();

const futureEndDate = moment().add(1, 'years');

const {
  selectRecommendationForBlock,
  selectNewBlockById,
} = selectors;

const {
  loadRecommendationForBlock,
} = actions;

export function ScheduleForm({
  isEditMode, initialValues, scheduledEvent, blockIds,
}) {
  const allowBlockSelect = true; // previously !isEditMode

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const { state: { from } = {} } = location;
  // handle go back, dismiss
  const schedulePath = from || '/schedule';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { start_date, stop_date } = scheduledEvent || {};

  const currentWeekday = moment().isoWeekday();
  const defaultStartDate = start_date ? moment(start_date) : initializeStartDate();
  const defaultDuration = (start_date && stop_date)
    ? moment(stop_date).clone().diff(moment(start_date), 'minutes', true) : 6 * 60;
  const defaultValues = {
    blockIds,
    startDate: defaultStartDate,
    endDate: stop_date ? moment(stop_date)
      : defaultStartDate.clone().add(defaultDuration, 'minutes'),
    duration: defaultDuration,
    isRecurring: false,
    repeatFrequency: 'weekly',
    repeatInterval: 1,
    repeatEndCount: 3,
    repeatEndDate: futureEndDate,
    repeatEndType: 'never',
    repeatWeekdays: {
      Su: currentWeekday === 7,
      Mo: currentWeekday === 1,
      Tu: currentWeekday === 2,
      We: currentWeekday === 3,
      Th: currentWeekday === 4,
      Fr: currentWeekday === 5,
      Sa: currentWeekday === 6,
    },
    ...initialValues,
  };

  const [blocks, setBlocks] = useState(defaultValues.blockIds);
  const blockNames = hooks.useBlockNames(blocks);
  const [createScheduleForBlocks] = useScheduleUpdate(blockNames);
  const newBlockIds = blocks.filter((blockId) => !defaultValues.blockIds.includes(blockId));

  const colorsByBlock = useColorsByBlock(blocks);
  const repeatDayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [startDate, setStartDate] = useState(defaultValues.startDate);
  const [duration, setDuration] = useState(defaultValues.duration);
  const [endDate, setEndDate] = useState(defaultValues.endDate);
  const [isRecurring, setIsRecurring] = useState(defaultValues.isRecurring);
  const [repeatInterval, setRepeatInterval] = useState(defaultValues.repeatInterval);
  const [repeatFrequency, setRepeatFrequency] = useState(defaultValues.repeatFrequency);
  const [weekDays, setWeekDays] = useState(defaultValues.repeatWeekdays);
  const [repeatEndType, setRepeatEndType] = useState(defaultValues.repeatEndType);
  const [repeatEndDate, setRepeatEndDate] = useState(defaultValues.repeatEndDate || futureEndDate);
  const [repeatEndCount, setRepeatEndCount] = useState(defaultValues.repeatEndCount);

  const [isEndDateCalendarOpen, setEndDateIsCalendarOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editEventModalIsVisible, setEditEventModalIsVisible] = useState(false);
  const [parsedEvent, setParsedEvent] = useState(null);

  const block = useSelector((state) => selectNewBlockById(state, blocks[0]));
  const getUserUnits = hooks.useUnits();

  // Changing time format based on the user settings
  const userTimeFormat = useUserTimeFormat();
  const dateFormat = 'dddd, D MMMM YYYY';

  // For sequential changes
  const [isSequential, setIsSequential] = useState(false);
  const [overlapMin, setOverlapMin] = useState(0);

  const blockId = blocks?.length === 1 ? blocks[0] : undefined;
  const recommendation = useSelector((state) => recommendationAnomalyFilter(
    selectRecommendationForBlock(state, blockId),
  )) || {};

  const {
    irrigationDuration: irrigationDurationStr,
    wiltingPointDuration: wiltingPointDurationStr,
    refillPointDuration: refillPointDurationStr,
    fieldCapacityDuration: fieldCapacityDurationStr,
  } = recommendation || {};

  const irrigationDuration = parseDurationStr(irrigationDurationStr);
  const wiltingPointDuration = parseDurationStr(wiltingPointDurationStr);
  const refillPointDuration = parseDurationStr(refillPointDurationStr);
  const fieldCapacityDuration = parseDurationStr(fieldCapacityDurationStr);

  let allowedMaximumDuration = 72; // default maximum duration
  let maxDurationHours = (defaultDuration / 60); // defaut duration on schedule edit event

  // Update maxDurationHours if fieldCapacityDuration available
  if (fieldCapacityDuration && fieldCapacityDuration > 0) {
    maxDurationHours = fieldCapacityDuration.asHours() * 2;
    allowedMaximumDuration = maxDurationHours;
  } else if (!start_date || !stop_date) {
    // Set default maxDuration on create mode when fieldCapacityDuration not available
    maxDurationHours = 72;
  }

  const areaSquareMeters = getBlockArea(block);
  const totalDuration = moment.duration(endDate.diff(startDate));
  const totalHours = totalDuration.asHours();
  const applicationRate = (block && block.application_rate) || 0;
  const gallonsValue = getGallonsValue(applicationRate || 0, totalHours);
  const currentDate = new Date().toISOString();

  const cutOffValues = useScheduledValveForIrrigationRatio(recommendation.etcHistoric,
    recommendation.etcForecast, recommendation.irrigationApplied,
    applicationRate, maxDurationHours, areaSquareMeters);

  const irrigationStateCutoffs = getIrrigationStateCutoffs(irrigationDuration, wiltingPointDuration,
    refillPointDuration, fieldCapacityDuration, recommendation.irrigationModel,
    maxDurationHours, cutOffValues);

  const irrigationStateColors = getIrrigationStateColors(irrigationDuration, wiltingPointDuration,
    refillPointDuration, fieldCapacityDuration, recommendation.irrigationModel);

  const maxDurationMinutes = maxDurationHours * 60;
  const maxDurationMinutesAllowed = Math.floor(maxDurationMinutes) - minStep;
  const diffHours = endDate.clone().diff(startDate, 'hours', true);
  const isValidDuration = diffHours <= allowedMaximumDuration; // To show warning banner

  // To avoid the irrigation dial set automatically half of its maximum input duration
  const setDurationOnChangeDate = useCallback((newDuration) => {
    if (newDuration < minDurationMinutes) return;
    if (newDuration < Math.round(maxDurationMinutes)) {
      setDuration(newDuration);
    } else {
      setDuration(getClosestMinute(maxDurationMinutesAllowed, minStep));
    }
  }, [maxDurationMinutes, maxDurationMinutesAllowed]);

  // To update the start date, end date, and duration onEdit schedule
  useEffect(() => {
    if (!start_date || !stop_date) return;
    const newDuration = moment(stop_date).clone().diff(moment(start_date), 'minutes', true);
    setDurationOnChangeDate(newDuration);
    if (moment(start_date).isValid()) setStartDate(moment(start_date));
    if (moment(stop_date).isValid()) setEndDate(moment(stop_date));
  }, [setDurationOnChangeDate, start_date, stop_date]);

  useEffect(() => {
    if (blockId) {
      dispatch(loadRecommendationForBlock(blockId));
    }
  }, [dispatch, blockId]);

  const datesAreModified = !(
    initialValues.startDate === startDate
    && initialValues.endDate === endDate
    && initialValues.duration === duration
  );

  function datesEqual(date1, date2) {
    if (!date1 || !date2) {
      return date1 === date2;
    }
    return date1.format(dateFormat) === date2.format(dateFormat);
  }

  // if recurring rules have been modified, user can't update single recurrence
  const repeatRulesAreModified = !(
    initialValues.isRecurring === isRecurring
    && initialValues.repeatInterval === repeatInterval
    && initialValues.repeatEndType === repeatEndType
    && initialValues.repeatFrequency === repeatFrequency
    && (
      initialValues.repeatFrequency !== 'weekly'
      || (
        initialValues.repeatWeekdays.Su === weekDays.Su
        && initialValues.repeatWeekdays.Mo === weekDays.Mo
        && initialValues.repeatWeekdays.Tu === weekDays.Tu
        && initialValues.repeatWeekdays.We === weekDays.We
        && initialValues.repeatWeekdays.Th === weekDays.Th
        && initialValues.repeatWeekdays.Fr === weekDays.Fr
        && initialValues.repeatWeekdays.Sa === weekDays.Sa
      )
    )
    && (
      initialValues.repeatEndType !== 'count'
      || initialValues.repeatEndCount === repeatEndCount
    )
    && (
      initialValues.repeatEndType !== 'date'
      || datesEqual(initialValues.repeatEndDate, repeatEndDate)
    )
  );

  const startDateIsModified = !datesEqual(startDate, initialValues.startDate);

  function parseScheduledEvent() {
    const stopDate = endDate;
    const timezone = moment.tz.guess();

    let schedule = {
      start_date: startDate.toISOString(),
      stop_date: stopDate.toISOString(),
      // flow: null,
      date_start_local: startDate.toISOString(true).slice(0, -6),
      timezone,
      is_recurring: isRecurring,
    };

    if (isRecurring) {
      schedule = {
        ...schedule,
        repeat_interval: repeatInterval,
        repeat_frequency: repeatFrequency,
        // repeat_monthly_type: null,
        repeat_end_type: repeatEndType,
      };

      if (repeatFrequency === 'weekly') {
        schedule = {
          ...schedule,
          repeat_sunday: weekDays.Su,
          repeat_monday: weekDays.Mo,
          repeat_tuesday: weekDays.Tu,
          repeat_wednesday: weekDays.We,
          repeat_thursday: weekDays.Th,
          repeat_friday: weekDays.Fr,
          repeat_saturday: weekDays.Sa,
        };
      }

      if (repeatEndType === 'date') {
        schedule.repeat_end_date = repeatEndDate.toISOString();
      }

      if (repeatEndType === 'count') {
        schedule.repeat_end_count = repeatEndCount;
      }
    }
    return schedule;
  }

  function onChangeStartDate(sDate) {
    const sanitizedStartDate = sDate.clone();
    const sanitizedNewEndDate = endDate.clone();
    // Avoid auto date selection logic
    // if (sanitizedNewEndDate <= sanitizedStartDate) {
    //   sanitizedNewEndDate = sanitizedStartDate.clone().add(duration, 'minutes');
    // }
    // const maxEndDate = sanitizedStartDate.clone().add(maxDurationHours, 'hours');
    // if (sanitizedNewEndDate > maxEndDate) {
    //   sanitizedNewEndDate = sanitizedStartDate.clone().add(duration, 'minutes');
    // }
    const newDuration = sanitizedNewEndDate.diff(sanitizedStartDate, 'minutes', true);
    setStartDate(sanitizedStartDate);
    setEndDate(sanitizedNewEndDate);
    setDurationOnChangeDate(newDuration);
  }

  function onChangeStartTime(newTime) {
    return onChangeStartDate(moment(newTime));
  }

  function onChangeEndDate(newEndDate) {
    const sanitizedNewEndDate = newEndDate.clone();
    setEndDate(sanitizedNewEndDate);
    const newDuration = sanitizedNewEndDate.diff(startDate, 'minutes', true);
    setDurationOnChangeDate(newDuration);
  }

  function onChangeEndTime(newTime) {
    return onChangeEndDate(moment(newTime));
  }

  function onChangeDurationSlider(newDuration) {
    const calculatedDuration = getClosestMinute(newDuration, minStep);
    if(endDate < startDate) return;
    const diffDuration = endDate.clone().diff(startDate, 'minutes', true);
    const newEndDate = startDate.clone().add(calculatedDuration, 'minutes');
    const isMinimumMinutes = newDuration > minDurationMinutes;

    // To avoid updating wrong end date when selected duration exceeds maximum duration
    if ((diffDuration <= calculatedDuration && isMinimumMinutes)
    || (calculatedDuration < maxDurationMinutesAllowed && isMinimumMinutes)) {
      setDurationOnChangeDate(calculatedDuration);
      setEndDate(newEndDate);
    } else if (!isMinimumMinutes) {
      const newDefaultEndDate = startDate.clone().add(minDurationMinutes, 'minutes');
      setDurationOnChangeDate(minDurationMinutes);
      setEndDate(newDefaultEndDate);
    }
  }

  function formIsValid() {
    if (!blockIds || !blocks.length) return false;
    if (!startDate || !duration) return false;
    return true;
  }

  function validateEventDates(event, setLoading) {
    const eventEndDate = moment(event?.stop_date);
    const eventStartDate = moment(event?.start_date);

    if (eventEndDate === eventStartDate) {
      notification.error({ message: t('End date cannot be same as start date.') });
      setLoading(false);
      return false;
    }
    if (eventEndDate < eventStartDate) {
      notification.error({ message: t('End date cannot be before the start date.') });
      setLoading(false);
      return false;
    }

    return true;
  }

  function handleEditSingleEvent(eventData) {
    setIsSaving(true);
    if (!validateEventDates(eventData, setIsSaving)) {
      return;
    }
    scheduleApi.updateScheduledIrrigationEvent(eventData).then(() => {
      notification.success({
        message: t('Schedule modified'),
      });
      setIsSaving(false);
      history.push(schedulePath);
    }).catch(() => {
      notification.error({
        message: t('Failed to edit schedule'),
      });
      setIsSaving(false);
    });
  }

  async function editSchedule() {
    const eventData = parseScheduledEvent();
    eventData.id = initialValues.id;
    [eventData.block] = initialValues.blockIds;
    if (!initialValues.isRecurring) {
      // edit directly
      handleEditSingleEvent(eventData);
      if (newBlockIds.length) {
        createScheduleForBlocks(eventData, newBlockIds);
      }
    } else {
      setParsedEvent(eventData);
      setEditEventModalIsVisible(true);
    }
  }

  async function createSchedule() {
    setIsSaving(true);
    const schedule = parseScheduledEvent();
    const success = await createScheduleForBlocks(schedule, blocks, isSequential, overlapMin);
    setIsSaving(false);
    if (success) {
      history.push(schedulePath);
    }
  }

  function onChangeBlocks(newBlocks) {
    if (isEditMode) {
      if (!newBlocks.includes(defaultValues.blockIds[0])) {
        setBlocks([
          defaultValues.blockIds[0],
          ...newBlocks,
        ]);
        return;
      }
    }
    setBlocks(newBlocks);
  }

  function submitClicked() {
    const sanitizedEndDate = endDate.clone();
    const sanitizedStartDate = startDate.clone();

    // Display error message and restrict the submit
    if (moment(sanitizedEndDate).isBefore(sanitizedStartDate)
       && !moment(sanitizedEndDate).isSame(sanitizedStartDate)) {
      notification.error({message: t('End date cannot be before the start date.') });
      return;
    }

    // Display error message and restrict the submit
    if ((diffHours * 60) < minDurationMinutes) {
      notification.error({
        message: t(`Irrigation duration should be minumum ${minDurationMinutes} minutes`)
      });
      return;
    }

    if (isEditMode) {
      editSchedule();
    } else {
      createSchedule();
    }
  }

  const gallonsDetails = getUserUnits(gallonsValue, 'gallons', 'volume');
  const waterDetails = {
    value: (Math.round(gallonsDetails?.value / 1000)).toFixed(0),
    label: gallonsDetails?.value > 0 ? `thousand ${gallonsDetails?.label}` : gallonsDetails?.label,
  };

  const waterLimit = ((block && block.application_rate) || 0) * Math.min(totalHours, HOURS_PER_DAY);
  const showWarning = showWarningCard(waterLimit, block);

  // For sequential changes
  const overlapOptions = [
    { value: 0, label: '0 Minutes' },
    { value: 5, label: '5 Minutes' },
    { value: 10, label: '10 Minutes' },
    { value: 15, label: '15 Minutes' },
  ];

  function renderSequentialControls() {
    return (
      <CardLayout>
        <div className="margin-bottom-10 flex-row justify-content-space-between">
          <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {`${t('Sequential')}`}
          </Typography.Text>
          <Switch
            className="form-"
            checkedChildren={t('Yes')}
            unCheckedChildren={t('No')}
            onChange={setIsSequential}
            defaultChecked={false}
            checked={isSequential}
          />
        </div>
        {isSequential && (
          <div className="margin-top-10 repeat-end-form-item flex-row">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${t('Overlap')}`}
            </Typography.Text>
            <Select
              style={{ marginLeft: '10px', width: '125px', fontSize: '16px' }}
              value={overlapMin}
              onChange={setOverlapMin}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              listHeight={90}
            >
              {overlapOptions.map((d) => (
                <Select.Option value={d.value}>{t(d.label)}</Select.Option>
              ))}
            </Select>
            <HelpButtonWithZendeskHelpModal
              buttonClassName="help-button"
              articleId={SEQUENTIAL_IRRIGATION}
            />
          </div>
        )}
      </CardLayout>
    );
  }

  return (
    <>
      {!isValidDuration
      && (
        <Alert
          message={t(`Irrigation hours exceeds maximum field
            capacity duration ${Math.floor(allowedMaximumDuration)} hours`)}
          type="warning"
        />
      )}
      <CardLayout>
        <div className="margin-bottom-10">
          <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {`${t('Start Time and Duration')}: `}
          </Typography.Text>
        </div>
        <div className="flex-column date-picker-container">
          <div className="flex-row justify-content-space-between date-time-picker">
            <DatePickerDate
              className="flex-grow-1"
              format={dateFormat}
              size="large"
              allowClear={false}
              inputReadOnly
              value={startDate}
              suffixIcon={null}
              disabledDate={disabledDate}
              onChange={onChangeStartDate}
            />
            <DatePickerTime
              mode="time"
              minuteStep={1}
              use12Hours={userTimeFormat?.unitsTime}
              value={startDate.toDate()}
              onChange={onChangeStartTime}
              locale={enUs}
            >
              <Button size="large" className="time-picker-btn">
                {`${startDate.format(userTimeFormat?.timeFormat)}`}
              </Button>
            </DatePickerTime>
          </div>
          <div className="flex-row justify-content-space-between date-time-picker">
            <DatePickerDate
              className="flex-grow-1"
              format={dateFormat}
              size="large"
              allowClear={false}
              inputReadOnly
              value={endDate}
              suffixIcon={null}
              disabledDate={disabledDate}
              onChange={onChangeEndDate}
            />
            <DatePickerTime
              mode="time"
              minuteStep={1}
              use12Hours={userTimeFormat?.unitsTime}
              value={endDate.toDate()}
              onChange={onChangeEndTime}
              locale={enUs}
            >
              <Button size="large" className="time-picker-btn">
                {`${endDate.format(userTimeFormat?.timeFormat)}`}
              </Button>
            </DatePickerTime>
          </div>
          <div className="flex-col">
            <div className="duration-slider-container">
              <CircularSlider
                className="circular-slider"
                defaultValue={duration}
                value={duration}
                onChange={onChangeDurationSlider}
                strokeDashArray="0,19.4"
                sliderWidth={220}
                trackSize={16}
                hourMax={maxDurationHours}
                hourStep={1}
                minMax={60}
                minStep={minStep}
                knobSize={40}
                colors={irrigationStateColors}
                cutoffValues={irrigationStateCutoffs || []}
                waterUnit={blocks.length === 1 ? waterDetails.label : null}
                waterValue={blocks.length === 1 ? waterDetails.value : null}
              />
            </div>
          </div>
        </div>
      </CardLayout>
      {showWarning && <WarningCard waterValue={block.maximum_daily_water} />}
      {allowBlockSelect ? (
        <CardLayout>
          <div className="margin-bottom-10">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${t('Select blocks to irrigate')}: `}
            </Typography.Text>
          </div>
          <div className="block-select-container">
            <BlockSelect
              placeholder={t('Select Blocks To Irrigate')}
              mode="multiple"
              onChange={onChangeBlocks}
              tagRender={(d) => blockTagRender(d, colorsByBlock)}
              value={blocks}
            />
          </div>
        </CardLayout>
      ) : (
        <CardLayout>
          <div className="margin-bottom-10">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${t('Block')}:`}
            </Typography.Text>
          </div>
          <div className="block-no-edit block-select-container">
            {blockTagRender(
              {
                onClose: () => { },
                closable: false,
                value: blockIds[0],
                label: { props: { block: { name: blockNames[blockIds[0]] } } },
              },
              colorsByBlock,
            )}
          </div>
        </CardLayout>
      )}
      {(blocks.length > 1) && renderSequentialControls()}
      <CardLayout>
        <div className="margin-bottom-10 flex-row justify-content-space-between">
          <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {`${t('Repeat Schedule')}`}
          </Typography.Text>
          <Switch
            className="form-"
            checkedChildren={t('Yes')}
            unCheckedChildren={t('No')}
            onChange={setIsRecurring}
            defaultChecked={isRecurring}
            checked={isRecurring}
          />
        </div>
        {isRecurring && (
          <>
            <div className="margin-top-10">

              <Typography.Text>
                {`${t('Repeat every')}`}
              </Typography.Text>

              <Select
                style={{ marginLeft: '10px', width: 60 }}
                value={repeatInterval}
                onChange={setRepeatInterval}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                listHeight={200}
              >
                {repeatDayOptions.map((optionValue) => (
                  <Select.Option value={optionValue} key={optionValue}>{optionValue}</Select.Option>
                ))}
              </Select>

              <Select
                style={{ marginLeft: '10px', width: 80 }}
                value={repeatFrequency}
                onChange={setRepeatFrequency}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="daily">{repeatInterval === 1 ? t('day') : t('days')}</Select.Option>
                <Select.Option value="weekly">{repeatInterval === 1 ? t('week') : t('weeks')}</Select.Option>
              </Select>

            </div>

            {repeatFrequency === 'weekly' && <WeekDays weekDays={weekDays} setWeekDays={setWeekDays} t={t} />}

            <div className="margin-top-10 repeat-end-form-items">
              <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {t('Ends')}
              </Typography.Text>
              <Select
                style={{ marginLeft: '10px', width: 90, fontSize: '16px' }}
                value={repeatEndType}
                onChange={setRepeatEndType}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="never">
                  <Typography.Text>
                    {t('Never')}
                  </Typography.Text>
                </Select.Option>
                <Select.Option value="date">
                  <Typography.Text>
                    {t('On_date')}
                  </Typography.Text>
                </Select.Option>
                <Select.Option value="count">
                  <Typography.Text>
                    {t('After')}
                  </Typography.Text>
                </Select.Option>
              </Select>

              {repeatEndType === 'date' && (
                <DatePickerDate
                  format="ddd, MMM D YYYY"
                  style={{ flexGrow: 1, marginTop: 10 }}
                  size="large"
                  allowClear={false}
                  inputReadOnly
                  value={repeatEndDate}
                  open={isEndDateCalendarOpen}
                  onClick={() => {
                    setEndDateIsCalendarOpen(!isEndDateCalendarOpen);
                  }}
                  suffixIcon={null}
                  disabledDate={disabledDate}
                  onChange={(sDate) => {
                    if (sDate.toDate().getTime() < startDateTimeAllowed(minutesAllowedFromNow)) {
                      setRepeatEndDate(initializeStartDate());
                    } else {
                      setRepeatEndDate(sDate);
                    }
                    setEndDateIsCalendarOpen(false);
                  }}
                />
              )}

              {repeatEndType === 'count' && (
                <Input
                  style={{ marginLeft: 10 }}
                  type="number"
                  suffix={t('Occurrences')}
                  value={repeatEndCount}
                  onChange={({ target: { value } }) => { setRepeatEndCount(Number(value)); }}
                />
              )}
            </div>
          </>
        )}
      </CardLayout>
      <div className="flex-row margin-bottom-10">
        <Button style={{ flexGrow: '1' }} size="large" onClick={() => { history.push(schedulePath); }}>{t('Cancel')}</Button>
        <Button
          data-testid="saveBtn"
          type="primary"
          style={{ flexGrow: '1', marginLeft: '10px' }}
          size="large"
          onClick={() => submitClicked()}
          disabled={!formIsValid()}
          loading={isSaving}
        >
          {t('Save')}
        </Button>
      </div>
      <ScheduleEditOptionsModal
        visible={editEventModalIsVisible}
        scheduledEvent={parsedEvent}
        originalEvent={scheduledEvent}
        initialValues={initialValues}
        allowModifySingle={!repeatRulesAreModified}
        allowModifyAll={!startDateIsModified}
        newBlockIds={newBlockIds}
        handleOk={(success) => {
          setEditEventModalIsVisible(false);
          if (success) {
            history.push(schedulePath);
          }
        }}
        handleCancel={() => setEditEventModalIsVisible(false)}
        validateEventDates={validateEventDates}
      />
    </>
  );
}

ScheduleForm.propTypes = {
  isEditMode: PropTypes.bool,
  initialValues: PropTypes.object,
  scheduledEvent: PropTypes.object,
  blockIds: PropTypes.arrayOf(PropTypes.any),
};

ScheduleForm.defaultProps = {
  isEditMode: false,
  initialValues: {},
  blockIds: [],
};
