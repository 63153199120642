export const getConnectivityLabel = (count, listLength, translate) => {
  const offline = translate('offline').toUpperCase();
  const online = translate('online').toUpperCase();

  if (count === 0) {
    return {
      label: offline,
      type: 'offline',
    };
  }

  return (listLength > 0 && count === listLength)
    ? { label: online, color: 'success', type: 'online' }
    : { label: `${count} ${translate('of')} ${listLength} ${online}`, color: 'success', type: 'online' };
};

export const getLoadingStatusForListOfSensors = (list, sensorStatus) => list.some((i) => {
  const status = sensorStatus[`${i.type}/${i.identifier}`];

  if (status) {
    return status.loading;
  }
  return false;
});


/**
 * Check for if a value is not null and not undefined.
 * Useful where values like Zero or empty string ('') shouldn't be ignored.
 */
export const isNotNull = (value) => (value !== null && value !== undefined && value !== '');

/**
 * Returns "(quotes) if label is inches(in) or else will return the label itself
 */
export const displayInchesInQuotes = (label) => (label === 'in' ? '"' : label);


/**
 * @param {*} precipitation - input precipitation value from the API
 * @param {*} units - user input units
 * @param {*} getUserUnits - callback function to convert value based on user settings
 * @returns the converted value with the label based on the user settings
 */
export const displayPrecipitation = (precipitation, units, getUserUnits) => {
  const getLabel = (label) => ((label !== undefined && label !== null) ? label : '');

  const convertedPrecipitation = getUserUnits(getLabel(precipitation),
    units.precipitation, 'depth');
  if (convertedPrecipitation.label !== 'in') {
    convertedPrecipitation.label = ` ${convertedPrecipitation.label}`;
  }
  return `${convertedPrecipitation.value}${displayInchesInQuotes(convertedPrecipitation.label)}`;
};
