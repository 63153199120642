import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import moment from 'moment';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PageHeader } from '../components/PageHeader';
import { CardLayout } from '../components/CardLayout';
import IrrigationStatus from '../components/IrrigationStatus';
import DateSelection from './DateSelection';
import ValveItem from './ValveItem';
import './farmhand.less';
import '../helpers.css';

const {
  selectNewRanchById,
  selectNewBlockById,
  selectDetailsForValveIds,
  selectPressureDetails,
  selectLatestBlockRenderedSchedule,
  selectFarmDataLoadingState,
} = selectors;

const {
  loadFarmData,
  loadAllSensors,
} = actions;

// Load schedule for a time period of 24 hours
const dateStart = moment();
const dateEnd = moment().add(1, 'day');

const BlockControl = (props) => {
  const dispatch = useDispatch();
  const { match } = props;
  const { id, blockId } = match.params;
  const ranch = useSelector((state) => selectNewRanchById(state, Number(id)));
  const block = useSelector((state) => selectNewBlockById(state, Number(blockId))) || {};
  const scheduledEvent = useSelector(
    (state) => selectLatestBlockRenderedSchedule(state, {
      blockId: Number(blockId),
      dateStart,
      dateEnd,
    }),
  );
  const { loading } = useSelector(selectFarmDataLoadingState);

  const { start_date: scheduledStart, stop_date: scheduledEnd } = scheduledEvent ?? {};

  /**
   * isIrrigating determines if the scheduled event is happening.
   * Based on isIrrigating, we are just setting irrigationStatus props.
   * <IrrigationStatus /> shows irrigating only when pump is on and valve is open,
   * so based on isIrrigating we're setting these values.
   */
  const isIrrigating = useMemo(() => {
    /**
     * Adding one minute to current date ensures that it doesn't give us incorrect comparison as
     * it may compare even seconds.
     */
    if (scheduledStart && scheduledEnd) {
      const isStartTimeInRange = moment().add(1, 'minutes').isSameOrAfter(scheduledStart);
      const isStopTimeInRange = moment().subtract(1, 'minutes').isSameOrBefore(scheduledEnd);
      if (isStartTimeInRange && isStopTimeInRange) {
        return true;
      }
    }
    return false;
  }, [scheduledStart, scheduledEnd]);
  const irrigationStatus = {
    pumpCurrentState: isIrrigating ? 'on' : 'off',
    valveCurrentState: isIrrigating ? 'open' : 'close',
    irrigationStartDate: scheduledStart,
    irrigationEndDate: scheduledEnd,
  };

  const valveIds = block?.valves || [];
  const pressureSensors = useSelector((state) => selectPressureDetails(state, Number(blockId)));
  const valvesArr = useSelector((state) => (
    selectDetailsForValveIds(state, valveIds, pressureSensors, true)
  ));
  const ranchName = ranch?.name || '';
  const blockName = block?.name || '';
  const title = `${ranchName} > ${blockName}`;

  /**
   * Load the data so as it can display correct page title
   */
  useEffect(() => {
    dispatch(loadFarmData());
    dispatch(loadAllSensors());
  }, [dispatch]);

  return (
    <div className="div-default farmhand-content padding-10">
      <PageHeader
        showBack
        title={title}
        toPath={`/farmhand/ranches/${id}`}
      />
      <CardLayout>
        <DateSelection
          blockId={blockId}
          scheduledEvent={scheduledEvent}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
        <IrrigationStatus block={irrigationStatus} />
        {loading ? (
          <Typography.Title level={3}>
            <LoadingOutlined className="margin-top-20" />
          </Typography.Title>
        ) : (
          <div className="farmhand-valve-list">
            {valvesArr.map((valve) => (
              <ValveItem
                key={valve.id}
                valve={valve}
              />
            ))}
          </div>
        )}
      </CardLayout>
    </div>
  );
};

BlockControl.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

BlockControl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default BlockControl;
