import React from 'react';
import {
  matchPath, useLocation, Link, Switch, Route, Redirect, withRouter, RouteComponentProps
} from 'react-router-dom';
import {
  Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaUser, FaPeopleCarry } from 'react-icons/fa';
// @ts-ignore
import { authService } from 'farmx-api';
// @ts-ignore
import { useWindowSize, AdminRoute } from 'farmx-web-ui';
import { ROUTES } from '../routes';
import './OrganizationPage.css';


// Helper function to determine the selected menu key
const getMatchedKey = (location: ReturnType<typeof useLocation>): string | undefined => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route));
  return matchedKey?.key;
};

const OrganizationPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const size = useWindowSize();

  function renderMenu() {
    return (
      <Menu
        className="settings-menu"
        theme="light"
        selectedKeys={[getMatchedKey(location) ?? '']}
        mode="inline"
        inlineCollapsed={size.width < 800}
      >
        <Menu.Item key={ROUTES.TEAMS_LIST.key}>
          <Link to={ROUTES.TEAMS_LIST.path}>
            <FaPeopleCarry className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Teams')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.USERS_LIST.key}>
          <Link to={ROUTES.USERS_LIST.path}>
            <FaUser className="settings-icon" />
            <span className="menu-title margin-left-10">{t('Users')}</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Layout className="single-column-page padded">
      <div className="settings-page">
        {renderMenu()}
        <div className="settings-body">
          <Switch>
            <Route exact path="/organization">
              <Redirect to={ROUTES.TEAMS_LIST.path} />
            </Route>
            {Object.values(ROUTES).map(({
              component, exact, key, path, adminRequired
            }: any) => (
              adminRequired ? (
                <AdminRoute
                  authService={authService}
                  component={component}
                  path={path}
                  key={key}
                />
              ) : (
                <Route
                  component={component}
                  exact={exact}
                  key={key}
                  path={path}
                />
              )
            ))}
            <Redirect to="/organization" />
          </Switch>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(OrganizationPage);
