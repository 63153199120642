import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getAnomalyIcon } from 'farmx-web-ui';
import { hooks, selectors, actions } from 'farmx-redux-core';
import {
  getTextForIrrigationState,
  getStateForIrrigationState,
  getActionText,
} from './recommendation';
import { StatusTag } from '../components/StatusTag';
import { useTracking } from '../../../helper/mixpanel';
import HelpButton from '../components/HelpButton';
import SoilStateLegendModal from './SoilStateLegendModal';
import { getStateForAnomalySeverity } from '../../../helper/anomalyHelper';
import { getHumanReadableAnomalyType, getRecommendationIdentifier } from '../../../helper/common';

const { useBlockNames } = hooks;

const { selectNewBlockById } = selectors;

const {
  setBlocks,
} = actions;

export function RecommendationListItem({
  selectedTab, recommendation, loadInitialTab, anomalies,
}) {
  const history = useHistory();
  const location = useLocation();
  const { push } = history;
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const blockName = useBlockNames([Number(recommendation?.block)]);
  const block = useSelector((state) => selectNewBlockById(state, Number(recommendation?.block))) || {};
  const ranchId = block?.ranch;

  const translatedDescription = t(recommendation.description);
  const onDetailsClick = useCallback(() => {
    push({
      pathname: `/recommendation/detail/${getRecommendationIdentifier(recommendation)}`,
      state: { selectedTab },
    });
    tracking.track('Clicked recommendation details', recommendation);
  }, [selectedTab, push, recommendation, tracking]);

  const openModal = useCallback((e) => {
    e.stopPropagation();
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onActionButtonClick = useCallback((event) => {
    event.stopPropagation();
    push({
      pathname: `/recommendation/action/${recommendation.block}`,
      state: {
        from: location.pathname,
        selectedTab,
      },
    });
    tracking.track('Clicked recommendation action', recommendation);
  }, [selectedTab, location.pathname, push, recommendation, tracking]);

  const onAnomalyButtonClick = useCallback((event, anomalyId) => {
    event.stopPropagation();
    const anomalyData = anomalies.filter((d) => (d?.anomaly?.properties?.id === anomalyId));

    push({
      pathname: `/recommendation/anomaly/detail/${getRecommendationIdentifier(recommendation)}`,
      state: { selectedTab, anomalyId },
    });
    tracking.track('Clicked anomaly details', anomalyData);
  }, [anomalies, push, recommendation, selectedTab, tracking]);

  const onAnomalyActionButtonClick = useCallback((event, anomalyId) => {
    event.stopPropagation();
    const anomalyData = anomalies.filter((d) => (d?.anomaly?.properties?.id === anomalyId));

    push({
      pathname: `/recommendation/anomaly/investigate/${recommendation.block}`,
      state: { from: location.pathname, selectedTab, anomalyId },
    });
    tracking.track('Clicked anomaly action', anomalyData);
  }, [anomalies, location.pathname, push, recommendation.block, selectedTab, tracking]);

  const onGroundMissionButtonClick = useCallback((event, droneId) => {
    event.stopPropagation();
    push({
      pathname: `/recommendation/ground_mission/detail/${getRecommendationIdentifier(recommendation)}`,
      state: { selectedTab, drone: recommendation },
    });
    tracking.track('Clicked drone details', recommendation);
  }, [push, recommendation, selectedTab, tracking]);

  const { selectedTab: initialTab } = location.state ?? {};

  const onGroundMissionActionButtonClick = useCallback((event) => {
    event.stopPropagation();
    push({
      pathname: `/recommendation/ground_mission/action/${recommendation.block}`,
      state: {
        from: location.pathname,
        selectedTab,
        ranchId,
      },
    });
    tracking.track('Clicked recommendation action', recommendation);
  }, [selectedTab, location.pathname, push, recommendation, tracking, ranchId]);

  useEffect(() => {
    loadInitialTab(initialTab);
  }, [initialTab, loadInitialTab]);

  const onViewScheduledActionButton = (event) => {
    event.stopPropagation();
    history.push({
      pathname: '/schedule',
      state: {
        from: location.pathname,
        blockId: recommendation?.block,
      },
    });
    // Set block to state when click on view schedule button
    dispatch(setBlocks([recommendation?.block]));
    tracking.track('Clicked view schedule', recommendation);
  };

  function renderAnomalyRecommendationListItem() {
    if (recommendation.type !== 'imagery_anomaly') return null;

    return (
      <Card
        className="recommendation-list"
        key={recommendation.id}
        title={recommendation.blockName || blockName[Number(recommendation.block)]}
        extra={(
          <div className="flex-center column-gap-10">
            <StatusTag
              text={t(getHumanReadableAnomalyType(recommendation?.anomalyType))}
              state={getStateForAnomalySeverity(recommendation?.anomalySeverity)}
              icon={getAnomalyIcon(recommendation?.anomalyType)}
            />
          </div>
        )}
        actions={[
          <div className="recommendation-list-item-footer">
            {recommendation.action !== 'no_action' ? (
              <div className="action-container-primary">
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onAnomalyActionButtonClick}
                >
                  {t(getActionText(recommendation?.action))}
                </Button>
              </div>
            ) : null}
          </div>]}
        bordered
        onClick={(e) => onAnomalyButtonClick(e, recommendation?.imageryAnomaly)}
      >
        <div className="recommendation-list-item-content">
          <Typography.Text
            className="recommendation-description"
          >
            {t(recommendation?.description)}
          </Typography.Text>
          <Typography.Text
            className="recommendation-description"
          >
            <span className="ant-btn-link">{` ${t('More...')}`}</span>
          </Typography.Text>
        </div>
      </Card>
    );
  }

  function renderIrrigationRecommendationListItem() {
    return (
      <Card
        className="recommendation-list"
        key={recommendation.id}
        title={recommendation.blockName || blockName[Number(recommendation.block)]}
        extra={(
          <div className="flex-center column-gap-10">
            <StatusTag
              text={getTextForIrrigationState(recommendation.state)}
              state={getStateForIrrigationState(recommendation.state)}
            />
            <HelpButton onClick={openModal} />
          </div>
        )}
        actions={[
          <div className="recommendation-list-item-footer">
            {recommendation.action !== 'no_action' ? (
              <div className="action-container-primary">
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onActionButtonClick}
                >
                  {t(getActionText(recommendation.action))}
                </Button>
              </div>
            ) : (
              <div className="action-container-primary">
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onViewScheduledActionButton}
                >
                  {t('View Schedule')}
                </Button>
              </div>
            )}
          </div>]}
        bordered
        onClick={onDetailsClick}
      >
        <div className="recommendation-list-item-content">
          <Typography.Text
            className="recommendation-description"
          >
            {translatedDescription}
          </Typography.Text>
          <Typography.Text
            className="recommendation-description"
          >
            <span className="ant-btn-link">{` ${t('More...')}`}</span>
          </Typography.Text>
        </div>
      </Card>
    );
  }

  function renderGroundMissionRecommendationListItem() {
    return (
      <Card
        className="recommendation-list"
        key={recommendation.id}
        title={recommendation.blockName || blockName[Number(recommendation.block)]}
        extra={(
          <div className="flex-center column-gap-10">
            <StatusTag
              text={t(getHumanReadableAnomalyType(recommendation.state))}
              state={getStateForAnomalySeverity(recommendation.state)}
              icon={getAnomalyIcon(recommendation.type)}
            />
          </div>
        )}
        actions={[
          <div className="recommendation-list-item-footer">
            {recommendation.action !== 'no_action' ? (
              <div className="action-container-primary">
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={onGroundMissionActionButtonClick}
                >
                  {t(getActionText(recommendation.action))}
                </Button>
              </div>
            ) : null}
          </div>]}
        bordered
        onClick={(e) => onGroundMissionButtonClick(e, recommendation.id)}
      >
        <div className="recommendation-list-item-content">
          <Typography.Text
            className="recommendation-description"
          >
            {t(recommendation?.description)}
          </Typography.Text>
          <Typography.Text
            className="recommendation-description"
          >
            <span className="ant-btn-link">{` ${t('More...')}`}</span>
          </Typography.Text>
        </div>
      </Card>
    );
  }

  return (
    <>
      {recommendation.type === 'irrigation' && renderIrrigationRecommendationListItem()}
      {recommendation.type === 'imagery_anomaly' && renderAnomalyRecommendationListItem()}
      {(!recommendation.type || recommendation.type === 'ground_mission')
        && renderGroundMissionRecommendationListItem()}
      <SoilStateLegendModal
        visible={showModal}
        onCancel={closeModal}
      />
    </>
  );
}

RecommendationListItem.defaultProps = {
  selectedTab: 'new',
  loadInitialTab: () => { },
  anomalies: [],
};

RecommendationListItem.propTypes = {
  recommendation: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedTab: PropTypes.string,
  loadInitialTab: PropTypes.func,
  anomalies: PropTypes.arrayOf(PropTypes.any),
};
