import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SensorListItem } from './SensorListItem';
import { isNotNull } from '../../../helper/listHelper';


export function IRSensorListItem(props) {
  const { sensorDetail } = props;
  const { online, lastUpdated, latestDate } = sensorDetail;

  const units = sensorDetail?.units ? sensorDetail.units.cwsi : 'cwsi';

  const displayIRValue = isNotNull(sensorDetail?.cwsi)
    ? `${sensorDetail.cwsi.toFixed(1)} ${units}` : '';

  const date = useMemo(() => (
    online ? lastUpdated : latestDate
  ), [lastUpdated, latestDate, online]);

  return (
    <SensorListItem
      alarmCount={sensorDetail.alarmCount}
      content={displayIRValue}
      date={date}
      icon="radio-waves"
      online={online}
      title={sensorDetail.name}
      identifier={sensorDetail.identifier}
      type={sensorDetail.type}
    />
  );
}

IRSensorListItem.defaultProps = {
  sensorDetail: {},
};

IRSensorListItem.propTypes = {
  sensorDetail: PropTypes.shape({
    alarmCount: PropTypes.number,
    latestDate: PropTypes.string,
    lastUpdated: PropTypes.string,
    name: PropTypes.string,
    online: PropTypes.bool,
    cwsi: PropTypes.string,
    units: PropTypes.object,
    identifier: PropTypes.string,
    type: PropTypes.string,
  }),
};
