import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Select } from 'antd';
import { selectors, hooks, actions } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import NewChartComponent from '../../graph/NewChartComponent';
import { TabsContent } from '../components/TabsContent';
import { PercentAvailableWaterGraph } from './PercentAvailableWaterGraph';
import { prepareSummaryBarGraphConfig, prepareETcLineGraphConfig, prepareBarGraphConfig } from './recommendationGraphConfig';
import './RecommendationGraphs.less';
import { getBlockArea } from '../../../helper/block';
import { useGetForecastDetailsFormData } from '../../../helper/recommendationHooks';
import ForecastRootZoneVWCChart from '../../graph/ForecastRootZoneVWCChart';

const {
  selectNewBlockById,
  selectUserVolumeFormat,
  selectUserDepthFormat,
  selectUserLengthFormat,
  selectSensorStatus,
} = selectors;

const { loadSensorStatus } = actions;

const etcGraphConfig = [
  { key: 'et0', label: 'ET0'},
  { key: 'kc', label: 'Kc'},
  { key: 'etc', label: 'ETc'}
];

const dataKeyConfig = {
  etc: 'etcForecastDaily',
  kc: 'kcDaily',
  et0: 'et0ForecastDaily'
};

const noonConfig = {
  hour: 12,
  minute: 0,
  second: 0,
  millisecond: 0
};

function summaryGraphs(recommendation, applicationRate, unitType, setUnitType,
  userVolumeUnit, toUnitByLength, blockAreaInM2, t){
  return(
    <div>
      <div className="flex-row justify-content-end">
        <Select
          defaultValue={unitType}
          onChange={setUnitType}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Select.Option value="depth">{t('Depth')}</Select.Option>
          <Select.Option value="volume">{t('Volume')}</Select.Option>
          <Select.Option value="duration">{t('Duration')}</Select.Option>
        </Select>
      </div>
      <div className="summaryDiv">
        <NewChartComponent
          config={prepareSummaryBarGraphConfig(recommendation, applicationRate, unitType,
            userVolumeUnit, toUnitByLength, blockAreaInM2, t)}
        />
      </div>
    </div>
  );
}

function waterDeficitGraphs(recommendation){
  const { block } = recommendation?.recommendation;
  return(
    <div className="graphDiv">
      <PercentAvailableWaterGraph blockId={Number(block)} />
    </div>
  );
}

function renderEtcGraphs(etData, etLabel, kc, graphType, setGraphType, t) {
  return (
    <>
      <div className="flex-row justify-content-end ">
        <Select
          defaultValue="etc"
          value={graphType}
          onSelect={setGraphType}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {etcGraphConfig.map(({ key, label}) => (
            <Select.Option value={key}>
              {t(label)}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="recommendation-detail-card2-container margin-top-10">
        <div className="graphDiv">
          <NewChartComponent
            config={prepareETcLineGraphConfig(etData, etLabel, dataKeyConfig[graphType], kc)}
          />
        </div>
      </div>
    </>
  );
}

function renderPrecipitationGraphs(precipData, label) {
  // Convert date into proper format to prepare graph config
  const precipDataWithDates = precipData?.dateDaily?.map((date, index) => {
    const dateValue = new Date(date);
    const dateString = dateValue?.toDateString();
    return [Date.parse(dateString), precipData?.precipForecastDaily[index]];
  });

  // Prepare bar graph config
  const config = prepareBarGraphConfig(precipDataWithDates, label);

  return (
    <div className="recommendation-detail-card2-container">
      <div className="graphDiv">
        <NewChartComponent config={config} />
      </div>
    </div>
  );
}

function renderVWCGraphs(forecastRootzoneVWCChart, days, setDays, t){
  return(
    <>
      <div className="flex-row justify-content-end ">
        <Select
          defaultValue={10}
          value={days}
          onSelect={setDays}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Select.Option value={10}>{`10 ${t('Days')}`}</Select.Option>
          <Select.Option value={20}>{`20 ${t('Days')}`}</Select.Option>
        </Select>
      </div>
      <div className="vwc-chart-container">
        {forecastRootzoneVWCChart}
      </div>
    </>
  );
}

function convertValueByUnit(value, toUnitByLength, getUserUnits) {
  const {
    value: convertedValue,
    label,
  } = getUserUnits(value, 'millimeters',
    undefined, { decimalPlaces: 2 }, toUnitByLength);
  return { value: convertedValue, label };
}

export function RecommendationGraphs(recommendation){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [unitType, setUnitType] = useState('depth');
  const [days, setDays] = useState(10);
  const [graphType, setGraphType] = useState('etc');
  const { block, data } = recommendation?.recommendation || {};
  const blockObj = useSelector((state) => selectNewBlockById(state, block));
  const { application_rate: applicationRate } = blockObj || {};
  const getUserUnits = hooks.useUnits();
  const userUnitVolume = useSelector((state) => selectUserVolumeFormat(state));
  const userUnitDepth = useSelector((state) => selectUserDepthFormat(state));
  // The result is the area of the geometry in square meters (m²)
  let blockAreaInM2 = getBlockArea(blockObj);
  // account for rootzone ratio
  let rootzoneAreaRatio = 1;
  if (blockObj?.rootzone_area && blockObj?.tree_spacing_ft2) {
    const { value: plantAreaInM2 } = getUserUnits(blockObj.tree_spacing_ft2, 'square_feet', undefined, { decimalPlaces: 2 }, 'square_meters');
    rootzoneAreaRatio = blockObj.rootzone_area / plantAreaInM2;
  }
  blockAreaInM2 *= rootzoneAreaRatio;
  const userUnitLength = useSelector((state) => selectUserLengthFormat(state));

  const toUnitByLength = userUnitLength === 'feet' ? 'inches' : 'millimeters';
  const convertedData = graphType !== 'kc' ? data?.[dataKeyConfig[graphType]]
    ?.map((d) => convertValueByUnit(d, toUnitByLength, getUserUnits)?.value)
    : data?.[dataKeyConfig[graphType]];
  const etLabel = graphType !== 'kc' ? convertValueByUnit(0, toUnitByLength, getUserUnits)?.label : '';
  const etData = { ...data, [dataKeyConfig[graphType]]: convertedData || [] };
  const { kc } = useGetForecastDetailsFormData(data);

  // Convert Precipitation forecast data by user unit
  const convertedPrecipDaily = data?.precipForecastDaily
    ?.map((d) => convertValueByUnit(d, toUnitByLength, getUserUnits)?.value);
  const precipLabel = convertValueByUnit(0, toUnitByLength, getUserUnits)?.label;
  const precipData = { ...data, precipForecastDaily: convertedPrecipDaily || [] };

  const { type, identifier, id } = recommendation?.sensors?.[0] || {};
  const sensorStatus = useSelector((state) => selectSensorStatus(state, type, identifier)) || {};
  const { depthRootzone } = sensorStatus;
  const sensor = { type, identifier, id };

  const { dateDaily } = data || {};

  // Set today's date to noon
  const currentNoon = moment().set(noonConfig);

  // Filter dates to include only today at noon or later
  const upcomingDates = dateDaily?.filter((fDate) => {
    const dateNoon = moment(fDate).set(noonConfig);
    return dateNoon.isSameOrAfter(currentNoon);
  });
  const dateValue = upcomingDates?.[0] ? new Date(upcomingDates?.[0])
    : new Date(moment().format('YYYY-MM-DD')); // Else case to show VWC graph for previous days
  const dateString = dateValue?.toDateString();
  // Get previous forecast date from the forecasted startDate
  const forecastPrevDate = moment(Date.parse(dateString)).subtract(1, 'day');

  // Decide startDate and endDate to get the graph data
  const startDate= moment(forecastPrevDate).subtract(days, 'days');
  const endDate= moment(Date.parse(dateString));

  // To avoid re-rendering chart with same data
  const forecastRootZoneVWCChart = useMemo(() => (
    <ForecastRootZoneVWCChart
      sensor={sensor}
      startDate={startDate}
      endDate={endDate}
      forecastData={data}
      depthRootzone={depthRootzone}
    />
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [depthRootzone, startDate?.format(), endDate?.format()]);

  // To load the sensor status
  useEffect(() => {
    dispatch(loadSensorStatus({ type, identifier }));
  }, [type, identifier, dispatch]);

  const items = [
    {
      key: '1',
      label: 'Summary',
      children: summaryGraphs(recommendation, applicationRate, unitType, setUnitType,
        userUnitVolume, toUnitByLength, blockAreaInM2, t)
    },
    {
      key: '2',
      label: 'Available Water',
      children: waterDeficitGraphs(recommendation)
    },
    {
      key: '3',
      label: 'ETc',
      children: renderEtcGraphs(etData, etLabel, kc, graphType, setGraphType, t)
    },
    {
      key: '4',
      label: 'VWC',
      children: renderVWCGraphs(forecastRootZoneVWCChart, days, setDays, t)
    },
    {
      key: '5',
      label: 'Precipitation',
      children: renderPrecipitationGraphs(precipData, precipLabel)
    },
  ];

  const onTabsChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <div>
      <TabsContent
        activeKey={activeTabKey}
        renderContent={items}
        onChange={onTabsChange}
      />
    </div>
  );
}

RecommendationGraphs.propTypes = {
  recommendation: PropTypes.shape({
    irrigationApplied: PropTypes.number,
    irrigationScheduled: PropTypes.number,
    etcHistoric: PropTypes.number,
    etcForecast: PropTypes.number,
    sensors: PropTypes.shape({
      type: PropTypes.string,
      identifier: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
};

RecommendationGraphs.defaultProps = {
  recommendation: null,
};
